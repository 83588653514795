import { MuiThemeProvider, Typography } from '@material-ui/core';
import { FC, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { API } from '../../../../apiconfig';

import { canDo } from '../../../../utils/permissionCheck';

import Joyride, { EVENTS, STATUS } from 'react-joyride';

import 'cropperjs/dist/cropper.css';
import Fuse from 'fuse.js';

import { checkRefreshToken } from '../../../../utils/utils';

import ErrorSnack from '../../../../utils/ErrorSnack';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';
import WarningSnack from '../../../../utils/WarningSnack';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
// import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
// import NativeSelect from "@material-ui/core/NativeSelect";
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloseButton from '@material-ui/icons/Clear';
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/HelpOutline';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import {
  IBadge,
  IInstrument,
  TAdminState,
} from '../../../../interfaces/interfaces';

//@ts-ignore
import Resizer from 'react-image-file-resizer';
//@ts-ignore
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useInstruments } from '../../../../hooks/useInstruments';
import { useUsers } from '../../../../hooks/useUsers';
import { EQUIPMENT_TEMPLATE, STEPS } from './constants';
import { theme, useStyles } from './styles';

const _ = require('lodash');

type TProps = {};
const Equipments: FC<TProps> = () => {
  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const cropperRef = useRef<ReactCropperElement>(null);

  const classes = useStyles();

  const [beacon1, setBeacon1] = useState<string | number>('');
  const [beacon2, setBeacon2] = useState<string | number>('');
  const [beacon3, setBeacon3] = useState<string | number>('');
  const [beacon4, setBeacon4] = useState<string | number>('');
  // const [nextFocusId, setNextFocusId] = useState<string | null>(null);
  // const [componentToView, setComponentToView] = useState('');
  // const [stepIndex, setStepIndex] = useState(null);
  const [instrumentHasBeenCreated, setInstrumentHasBeenCreated] =
    useState(false);
  const [npiError, setNpiError] = useState(false);
  const [badgeWarning, setBadgeWarning] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  // const [newBadge, setNewBadge] = useState('');
  const [fieldError, setFieldError] = useState(false);
  const [newBadgeExpiration, setNewBadgeExpiration] = useState(null);
  const [imageEditMode, setImageEditMode] = useState(false);
  const [instrumentToEdit, setInstrumentToEdit] = useState(null);

  const [newInstrumentModalOpen, setNewInstrumentModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [hideInactiveInstruments, setHideInactiveInstruments] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('firstName');
  const [sortDescending, setSortDescending] = useState(false);
  const [filterString, setFilterString] = useState('');
  const [badgeString, setBadgeString] = useState('');
  const [hasModified, setHasModified] = useState(false);
  const [right, setRight] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [newInstrument, setNewInstrument] = useState(false);
  const [duplicateBadgeError, setDuplicateBadgeError] = useState(false);
  const [userImageError, setUserImageError] = useState(false);
  const [displayBadgeExpiration, setDisplayBadgeExpiration] = useState(false);
  const [hideEditImageButton, setHideEditImageButton] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);

  const [run, setRun] = useState(false);
  const [showDuplicateUserBadgeError, setShowDuplicateUserBadgeError] =
    useState(false);
  const [userWithDuplicateBadge, setUserWithDuplicateBadge] = useState(null);
  const [beginSaveProcess, setBeginSaveProcess] = useState(false);

  const { users } = useUsers(loggedInUserData.mongoOrganizationId);
  const { instruments, refetch: refetchInstruments } = useInstruments(
    loggedInUserData.mongoOrganizationId
  );

  // componentDidMount() {
  //   this.props.fetchInstrumentsByOrgId(
  //     this.props.loggedInUserData.mongoOrganizationId
  //   );
  //   this.props.fetchAppUsersByOrgId(
  //     this.props.loggedInUserData.mongoOrganizationId
  //   );
  // }

  const onHandleFilter = (event: any) => {
    setFilterString(event.target.value);
  };

  const onHandleBadgeSearch = (event: any) => {
    setBadgeString(event.target.value);
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const onRotateImage = () => {
    cropperRef.current.cropper.rotate(90);
  };

  const onToggleDrawer = (open: boolean, instrument: IInstrument) => {
    console.log('onToggleDrawer', { open, instrument });
    if (canDo(['Update Equipment'], loggedInUserData)) {
      console.log('onToggleDrawer', { open, instrument });

      if (hasModified) {
        setShowSaveAlert(true);
      } else {
        // console.log('selected instrument', instrument);

        setLoading(false);
        setRight(open);
        setNewInstrument(false);
        setInstrumentToEdit(instrument);
        setUploadedFile(null);
        setImageEditMode(false);
        setNewBadgeExpiration(null);
        setFieldError(false);
        setDuplicateBadgeError(false);
        setUserImageError(false);
        setHasModified(false);
        setPasswordReset(false);
        setDisplayBadgeExpiration(false);
        setFirstNameError(false);
        setLastNameError(false);
        setHideEditImageButton(false);
      }

      refetchInstruments();
    }

    if (!open) {
      setInstrumentToEdit(null);
      setBeacon1('');
      setBeacon2('');
      setBeacon3('');
      setBeacon4('');
    }
  };

  //   toggleDrawer = (side, open, instrument) => () => {
  //     console.log("Selected instrument", instrument);

  //     this.setState({
  //       [side]: open,
  //       instrumentToEdit: instrument,
  //     });
  //   };

  const onHandleChange = (input: { name: string; value: string }) => {
    const { name, value } = input;
    let newValue: string | number = value;
    if (name === 'userTypeId') {
      newValue = parseInt(value);
    }

    setHasModified(true);
    setInstrumentToEdit({
      ...instrumentToEdit,
      [name]: newValue,
    });
  };

  // const onHandleBadgeInput = (event: any) => {
  //   setNewBadge(event.target.value);
  // };

  const onHandleBadgeExpiration = (event: any) => {
    setNewBadgeExpiration(event.target.value);
  };

  const onNewInstrumentModal = () => {
    setActiveStep(0);
    setNewInstrumentModalOpen(true);
    setNewInstrument(true);
    setUploadedFile(null);
    setImageEditMode(false);
    setNewBadgeExpiration(null);
    setInstrumentToEdit({
      ...EQUIPMENT_TEMPLATE,
      appUserBadges: [],
    });
  };

  const onCloseNewInstrumentModal = () => {
    setNewInstrumentModalOpen(false);
    setLoading(false);
    setNewInstrument(false);
    setUploadedFile(null);
    setImageEditMode(false);
    setNewBadgeExpiration(null);
    setFieldError(false);
    setDuplicateBadgeError(false);
    setUserImageError(false);
    setHasModified(false);
    setDisplayBadgeExpiration(false);
    setFirstNameError(false);
    setLastNameError(false);
    setBadgeWarning(false);
  };

  const onDeleteBadge = (badgeToDelete: string) => {
    let badges = instrumentToEdit.appUserBadges;

    badges = badges.filter((obj: IBadge) => {
      return obj.badgeString !== badgeToDelete;
    });

    // userToEdit.appUserBadges = badges;

    setBadgeWarning(false);
    setHasModified(true);
    setInstrumentToEdit({
      ...instrumentToEdit,
      appUserBadges: badges,
    });
  };

  const onHandleSubmit = () => {
    // console.log("Unsaved badge, automatically adding", newBadge);
    // if (newBadge) {
    //   // if (this.state.newBadge.length > 0) {
    //   //   this.handleNewBadge();
    //   // }
    // }

    setUserImageError(false);
    setFieldError(false);
    setDuplicateBadgeError(false);
    setNpiError(false);
    setBadgeWarning(false);

    // console.log('Submit modified user object', this.state.userToEdit);

    let token = localStorage.getItem('token');

    let fieldError = false;
    let firstNameError = false;
    let lastNameError = false;
    let userImageError = false;
    let npiError = false;

    if (instrumentToEdit.firstName.length < 1) {
      fieldError = true;
      firstNameError = true;
    }
    if (instrumentToEdit.lastName.length < 1) {
      fieldError = true;
      lastNameError = true;
    }

    if (!instrumentToEdit.userImage && instrumentToEdit.userImageUrl < 1) {
      fieldError = true;
      userImageError = true;
    }

    if (instrumentToEdit.userTypeId === 4) {
      if (instrumentToEdit.npi !== null) {
        if (instrumentToEdit.npi.length > 0) {
          if (instrumentToEdit.npi.length !== 10) {
            npiError = true;
          }
        }
      }
    }

    if (fieldError || npiError) {
      setFieldError(true);
      setFirstNameError(firstNameError);
      setLastNameError(lastNameError);
      setUserImageError(userImageError);
      setNpiError(npiError);
    } else {
      // If its a new user
      if (newInstrument) {
        //   console.log("creating new instrument");
        let modifiedInstrument = {
          ...instrumentToEdit,
          mongoOrganizationId: loggedInUserData.mongoOrganizationId,
          // AdminUserId: this.props.loggedInUserData.userId,
        };

        // if (modifiedInstrument.AdminUserId === null) {
        //   modifiedInstrument.AdminUserId = 0;
        // }

        let newInstrument = JSON.stringify(modifiedInstrument);

        //   console.log("creating new instrument", modifiedInstrument);

        setLoading(true);

        fetch(API.REACT_APP_API_UPDATEINSTRUMENT, {
          method: 'POST',
          body: newInstrument,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(data => {
            console.log('DATA', data);

            setLoading(false);

            // if (data.data.isDuplicate) {
            //   console.log("error", data.data.response);
            //   this.setState({
            //     newInstrumentErrorMessage: data.data.response,
            //   });
            // } else {

            refetchInstruments();

            setHasModified(false);
            setRight(false);
            setFieldError(false);
            setUserImageError(false);
            setInstrumentHasBeenCreated(true);

            onCloseNewInstrumentModal();
            setTimeout(() => setInstrumentHasBeenCreated(false), 3000);
            // }
          })
          .catch(err => {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
      // If its an existing user thats being modified
      else {
        let modifiedInstrument = {
          ...instrumentToEdit,
          // AdminUserId: this.props.loggedInUserData.userId,
        };

        // if (modifiedInstrument.AdminUserId === null) {
        //   modifiedInstrument.AdminUserId = 0;
        // }

        let payload = JSON.stringify(modifiedInstrument);

        // console.log('OK', userToEdit);
        // console.log('sending..', modifiedUser)

        setLoading(true);

        //   console.log("Sending", modifiedInstrument);
        fetch(API.REACT_APP_API_UPDATEINSTRUMENT, {
          method: 'PUT',
          body: payload,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(data => {
            // console.log("Good", data);
            setShowSaveAlert(false);
            setLoading(false);

            if (data.data.isDuplicate) {
              console.log('Error: Duplicate Badge');

              // setNewInstrumentErrorMessage(data.data.response);

              refetchInstruments();
            } else {
              refetchInstruments();

              setHasModified(false);
              setRight(false);
              setFieldError(false);
              setUserImageError(false);
            }
          })
          .catch(err => {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    }
  };

  const onFileSelectedHandler = (event: any) => {
    // var canvas = document.getElementById("imageCanvas");
    // var ctx = canvas.getContext("2d");

    let mountImage = (smallImage: any) => {
      console.log('small image', smallImage);
      setUploadedFile(smallImage);
      setImageEditMode(true);
    };

    //===============
    let fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }

    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        'JPEG',
        100,
        0,
        (uri: any) => {
          // console.log("uri and file size", uri);
          // Resized it, now set state
          mountImage(uri);
        },
        'base64'
      );
    }
  };

  const onEditImage = () => {
    setImageEditMode(!imageEditMode);
    setHideEditImageButton(true);
  };

  const onCloseEditImage = () => {
    setImageEditMode(false);
  };

  const onCrop = () => {
    // console.log(this.refs.cropper.getCroppedCanvas().toDataURL().replace(/data:image\/[a-zA-Z]+;base64,/g, ''));
    // this.setState({ newImage: this.refs.cropper.getCroppedCanvas().toDataURL().replace(/data:image\/[a-zA-Z]+;base64,/g, '')})
    // console.log(this.refs.cropper.getCroppedCanvas().toDataURL().replace(/data:image\/[a-zA-Z]+;base64,/g, ''));
    // console.log(this.refs)

    setInstrumentToEdit({
      ...instrumentToEdit,
      userImage: cropperRef.current.cropper
        //@ts-ignore
        .getCroppedCanvas()
        .toDataURL()
        .replace(/data:image\/[a-zA-Z]+;base64,/g, ''),
    });
  };

  const onHandleNext = () => {
    console.log('Current step', activeStep);

    let nextStep = () => {
      setActiveStep(activeStep + 1);
    };

    // Check User Image Step
    if (activeStep === 0) {
      if (!instrumentToEdit.userImage && instrumentToEdit.userImageUrl < 1) {
        setFieldError(true);
        setUserImageError(true);
      } else {
        setFieldError(false);
        setUserImageError(false);

        nextStep();
      }
    }

    // Check User Info Step
    if (activeStep === 1) {
      let fieldError = false;
      let firstNameError = false;
      let lastNameError = false;

      if (instrumentToEdit.firstName.length < 1) {
        fieldError = true;
        firstNameError = true;
      }
      if (instrumentToEdit.lastName.length < 1) {
        fieldError = true;
        lastNameError = true;
      }

      if (fieldError) {
        setFieldError(true);
        setFirstNameError(firstNameError);
        setLastNameError(lastNameError);
      } else {
        setFieldError(false);
        setFirstNameError(firstNameError);
        setLastNameError(lastNameError);

        nextStep();
      }
    }
  };

  const onHandleNewBadge = (overwrite: boolean) => {
    console.log('handleNewBadge');
    // console.log('NEW BADGE!', this.state.newBadge);
    // console.log("Badge Expiration", this.state.newBadgeExpiration);

    // console.log("New badge", this.state.newBadge);

    if (beacon1 && beacon2 && beacon3 && beacon4) {
      let newBadge = 'IR' + beacon1 + beacon2 + beacon3 + beacon4;

      let badgeExpiration = null;
      if (newBadgeExpiration === '' || newBadgeExpiration === null) {
        badgeExpiration = null;
      } else {
        badgeExpiration = new Date(newBadgeExpiration).toISOString();
      }

      // console.log("IT IS NOW", badgeExpiration);

      let error = null;

      // console.log(this.state.userToEdit.appUserBadges);
      // console.log("New badge", newBadge);

      // Trim badge to remove any whitespace
      let trimmedBadge = newBadge.replace(/\s/g, '');

      for (let i = 0; i < instrumentToEdit.appUserBadges.length; i++) {
        if (instrumentToEdit.appUserBadges[i].badgeString === trimmedBadge) {
          console.log('Error: Duplicate Badge THIS ONE');
          error = true;
        }
      }

      if (error) {
        console.log('Error');
        setDuplicateBadgeError(true);
      } else {
        if (trimmedBadge === '' || trimmedBadge === undefined) {
          console.log('Please input a badge');
          // document.getElementById("badgeInputField").focus();
        } else {
          if (!trimmedBadge.toLowerCase().startsWith('ir')) {
            console.log('Give warning');

            setBadgeWarning(true);
          }

          // Check if duplicate exists with other users/equipment
          let userWithDuplicateBadge = null;

          users.forEach(user => {
            user.appUserBadges.forEach(badge => {
              if (badge.badgeString === trimmedBadge) {
                // console.log("found duplicate badge!", user, trimmedBadge);
                userWithDuplicateBadge = user;
              }
            });
          });

          instruments.forEach(instrument => {
            instrument.appUserBadges.forEach(badge => {
              if (badge.badgeString === trimmedBadge) {
                // console.log("duplicate badge with equipment", badge);
                userWithDuplicateBadge = instrument;
              }
            });
          });

          if (overwrite) {
            // console.log("MAKE IT NULL");
            userWithDuplicateBadge = null;
          }

          if (!userWithDuplicateBadge) {
            let newBadgeDetails = {
              userId: instrumentToEdit.userId,
              badgeString: trimmedBadge,
              expirationDate: badgeExpiration,
            };
            instrumentToEdit.appUserBadges.push(newBadgeDetails);
            setDuplicateBadgeError(false);
            setHasModified(true);
            setNewBadgeExpiration('');
            setBeacon1('');
            setBeacon2('');
            setBeacon3('');
            setBeacon4('');
            // setOverwriteBadge(false);
            setShowDuplicateUserBadgeError(false);

            console.log('begin save?', beginSaveProcess);
            if (beginSaveProcess) {
              onHandleSubmit();
              setBeginSaveProcess(false);
            }
          } else {
            console.log('Show badge err popup');
            setUserWithDuplicateBadge(userWithDuplicateBadge);
            setShowDuplicateUserBadgeError(true);
          }
        }
      }
    } else {
      onHandleSubmit();
    }
  };

  const onHandleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onHandleJoyrideCallback = (data: any) => {
    const { status, type } = data;
    console.log('type', type);

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      // console.log("INDEX", index);
      // if (index === 0) {
      //   setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      //   setComponentToView('users');
      // } else if (index === 3) {
      //   setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      //   setComponentToView('equipment');
      // } else {
      //   setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      // }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log('finish');
      // Need to set our running state to false, so we can restart if we click start again.

      setRun(false);
    }

    if (type === 'beacon') {
      // console.log("KILL");
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };

  const onBadgeInputs = (
    beaconNumber: string,
    value: any,
    nextFocusId: string | null
  ) => {
    if (value) {
      var lastChar = 0;
      if (value.length > 1) {
        lastChar = value[value.length - 1];
      } else {
        lastChar = value;
      }

      function isNumeric(str: number) {
        if (typeof str != 'string') return false; // we only process strings!
        return (
          !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str))
        ); // ...and ensure strings of whitespace fail
      }

      if (isNumeric(lastChar)) {
        switch (beaconNumber) {
          case 'beacon1':
            setBeacon1(lastChar);
            break;
          case 'beacon2':
            setBeacon2(lastChar);
            break;
          case 'beacon3':
            setBeacon3(lastChar);
            break;
          case 'beacon4':
            setBeacon4(lastChar);
            break;
        }

        if (nextFocusId) {
          // setNextFocusId('nextFocusId');

          document.getElementById(nextFocusId).focus();
        } else {
          //@ts-ignore
          document.getElementById('outsideAddBeaconButton').focus();
        }
      }
    }
  };

  const onNewUserBadgeInputs = (input: {
    beaconNumber: string;
    value: any;
    nextFocusId: string | null;
  }) => {
    const { beaconNumber, value, nextFocusId: currentNextFocusId } = input;

    if (value) {
      var lastChar = value.length > 1 ? value[value.length - 1] : value;

      if (isNumeric(lastChar)) {
        switch (beaconNumber) {
          case 'beacon1':
            setBeacon1(lastChar);
            break;
          case 'beacon2':
            setBeacon2(lastChar);
            break;
          case 'beacon3':
            setBeacon3(lastChar);
            break;
          case 'beacon4':
            setBeacon4(lastChar);
            break;
        }

        if (currentNextFocusId) {
          // setNextFocusId(currentNextFocusId);
          // Use setTimeout to ensure the DOM has updated before trying to focus
          setTimeout(() => {
            const nextElement = document.getElementById(currentNextFocusId);
            if (nextElement) {
              nextElement.focus();
            }
          }, 0);
        } else {
          // Use setTimeout here as well
          setTimeout(() => {
            const addBeaconButton = document.getElementById(
              'insideAddBeaconButton'
            );
            if (addBeaconButton) {
              addBeaconButton.focus();
            }
          }, 0);
        }
      }
    }
  };

  // Helper function to check if a string is numeric
  const isNumeric = (str: string) => {
    if (typeof str != 'string') return false;
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  };

  const onOverwriteBadge = () => {
    console.log('overwrite badge!');
    onHandleNewBadge(true);
  };

  const onSaveChanges = () => {
    onHandleNewBadge(false);
    setBeginSaveProcess(true);
  };

  const onCloseWithoutSaving = (instrument: IInstrument) => {
    console.log('trigger it');

    setShowSaveAlert(false);
    setHasModified(false);
    setRight(false);
    setFieldError(false);
    // setNewInstrumentErrorMessage(false);
    setInstrumentToEdit(instrument);
  };

  let filteredInstruments: IInstrument[] = [];

  if (instruments) {
    let filter = sortBy;
    // Sort By Select
    filteredInstruments = instruments.sort((a: IInstrument, b: IInstrument) => {
      if (sortDescending) {
        // DESCENDING
        if (a[filter as keyof IInstrument] > b[filter as keyof IInstrument]) {
          return -1;
        }
        if (a[filter as keyof IInstrument] < b[filter as keyof IInstrument]) {
          return 1;
        }
        return 0;
      } else {
        // ASCENDING
        if (a[filter as keyof IInstrument] < b[filter as keyof IInstrument]) {
          return -1;
        }
        if (a[filter as keyof IInstrument] > b[filter as keyof IInstrument]) {
          return 1;
        }
        return 0;
      }
    });

    var fuseOptions = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'fullName',
        // 'appUserBadges.badgeString'
      ],
    };

    var fuse = new Fuse(filteredInstruments, fuseOptions);
    var result = fuse.search(filterString);
    // console.log("Fuse Result", result);

    if (filterString.length > 0) {
      filteredInstruments = result.map(fuseResult => fuseResult.item) as any;
    }

    // Search for Badge
    if (badgeString.length >= 1) {
      let filteredBadgeUsers = [];
      for (let i = 0; i < instruments.length; i++) {
        // console.log(this.props.usersData[i])
        for (let o = 0; o < instruments[i].appUserBadges.length; o++) {
          if (
            instruments[i].appUserBadges[o].badgeString
              .toUpperCase()
              .includes(badgeString.toUpperCase())
          ) {
            filteredBadgeUsers.push(instruments[i]);
          }
        }
      }
      filteredInstruments = _.uniqBy(filteredBadgeUsers, 'userId');
    }

    // Hide inactive users
    if (hideInactiveInstruments) {
      let activeInstruments: IInstrument[] = [];
      filteredInstruments.filter((item: IInstrument) => {
        if (item.isActive) {
          activeInstruments.push(item);
        }
        return null;
      });
      filteredInstruments = activeInstruments;
    }
  }

  const steps = ['Equipment Image', 'Equipment Info', 'Badge Details'];

  let alphabetizedAppUserBadges: any[] = [];
  if (instrumentToEdit) {
    alphabetizedAppUserBadges = instrumentToEdit.appUserBadges.sort(
      (a: any, b: any) => {
        return a.badgeString < b.badgeString
          ? -1
          : a.badgeString > b.badgeString
          ? 1
          : 0;
      }
    );
  }

  return (
    <div>
      {canDo(['View Equipment'], loggedInUserData) ? (
        <MuiThemeProvider theme={theme}>
          <Dialog open={showSaveAlert}>
            <DialogTitle>Unsaved changes!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Would you like to save your changes before exiting?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => onSaveChanges()}>
                Yes
              </Button>
              <Button
                color="primary"
                autoFocus={true}
                onClick={() => onCloseWithoutSaving(instrumentToEdit)}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={showDuplicateUserBadgeError}>
            <DialogTitle id="alert-dialog-title">
              This badge is already assigned to{' '}
              {userWithDuplicateBadge ? userWithDuplicateBadge.fullName : null}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Would you like to continue? This will remove the existing badge
                from{' '}
                {userWithDuplicateBadge
                  ? userWithDuplicateBadge.fullName
                  : null}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onOverwriteBadge}>
                Yes
              </Button>
              <Button
                color="primary"
                autoFocus={true}
                onClick={() => {
                  setShowDuplicateUserBadgeError(false);
                  setUserWithDuplicateBadge(null);
                  setBeacon1('');
                  setBeacon2('');
                  setBeacon3('');
                  setBeacon4('');
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

          <Typography variant="h4" gutterBottom component="h2">
            Equipment
            <Tooltip title="Equipment Tutorial" placement="right">
              <IconButton
                onClick={() => {
                  setRun(true);
                }}
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
            {canDo(['Create Equipment'], loggedInUserData) ? (
              <>
                <Tooltip
                  title="Click here to create new equipment."
                  aria-label="Add"
                  onClick={() => onNewInstrumentModal()}
                >
                  <Fab
                    className={
                      classes.newInstrumentButton +
                      ' seventh-step equipment-second-step'
                    }
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </>
            ) : null}
          </Typography>

          <TextField
            label="Search"
            className={classes.sortField}
            onChange={event => onHandleFilter(event)}
          />

          <TextField
            label="Badges"
            className={classes.sortField}
            onChange={event => onHandleBadgeSearch(event)}
          />

          <br />

          <div
            onClick={event => {
              setHideInactiveInstruments(!hideInactiveInstruments);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={hideInactiveInstruments}
            />
            Hide Inactive Equipment
          </div>

          <Paper className={classes.root}>
            <Table
              className={classes.table + ' sixth-step equipment-first-step'}
            >
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell>
                    <div>Picture</div>
                  </TableCell>
                  <TableCell
                    className={classes.sortSelect}
                    onClick={() => onSortBy('firstName')}
                  >
                    {sortBy === 'firstName' ? (
                      <div>Equipment {sortDescending ? <>▼</> : <>▲</>}</div>
                    ) : (
                      <div>Equipment</div>
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.sortSelect}
                    onClick={() => onSortBy('lastName')}
                  >
                    {sortBy === 'lastName' ? (
                      <div>Serial # {sortDescending ? <>▼</> : <>▲</>}</div>
                    ) : (
                      <div>Serial #</div>
                    )}
                  </TableCell>
                  <TableCell className={classes.sortSelect}>
                    <div>Low Battery</div>
                  </TableCell>
                  <TableCell
                    className={classes.sortSelect}
                    onClick={() => onSortBy('isActive')}
                  >
                    {sortBy === 'isActive' ? (
                      <div>Is Active {sortDescending ? <>▼</> : <>▲</>}</div>
                    ) : (
                      <div>Is Active</div>
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.sortSelect}
                    // onClick={() => this.sortBy("isActive")}
                  >
                    <div>Badge ID(s)</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredInstruments.map((instrument: IInstrument) => (
                  <TableRow
                    key={instrument.id}
                    onClick={() => onToggleDrawer(true, instrument)}
                    className={classes.tableRow}
                  >
                    <TableCell className={classes.tableCell}>
                      <Avatar alt="" src={instrument.userImageUrl} />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {instrument.firstName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {instrument.lastName}
                    </TableCell>

                    {instrument.hasLowBatteryBadge ? (
                      <TableCell className={classes.tableCell}>
                        <CheckIcon />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell}></TableCell>
                    )}

                    {instrument.isActive ? (
                      <TableCell className={classes.tableCell}>
                        <CheckIcon />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell}></TableCell>
                    )}
                    <TableCell className={classes.tableCell}>
                      {instrument.appUserBadges.map((badge, index) => (
                        <span key={index}>
                          {index === 0 ? null : <span>, </span>}{' '}
                          {badge.badgeString}
                        </span>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          {/* New User Modal */}
          <Modal
            open={newInstrumentModalOpen}
            onClose={onCloseNewInstrumentModal}
          >
            <div className={classes.newInstrumentModal}>
              <Typography variant="h4" gutterBottom component="h2">
                New Equipment
              </Typography>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {instrumentToEdit ? (
                        <div>
                          {index === 0 ? (
                            <div>
                              {!uploadedFile ? (
                                <div>
                                  <div className="imageUploadContainer">
                                    <Tooltip
                                      title="Click here to select a picture from your computer for the equipment."
                                      placement="right"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <label htmlFor="contained-button-file">
                                        <Avatar
                                          className={classes.bigAvatar}
                                          alt=""
                                          src={instrumentToEdit.userImageUrl}
                                        />
                                        <div
                                          className={
                                            userImageError
                                              ? 'imageUploadOverlay imageUploadError'
                                              : 'imageUploadOverlay'
                                          }
                                        >
                                          <AddIcon
                                            className="imageUploadIcon"
                                            style={{ fontSize: 35 }}
                                          />
                                        </div>
                                      </label>
                                    </Tooltip>
                                  </div>
                                  <label htmlFor="contained-button-file">
                                    {newInstrument ? (
                                      <div
                                        style={{
                                          paddingLeft: 22,
                                          cursor: 'pointer',
                                        }}
                                      >
                                        Add Image
                                      </div>
                                    ) : (
                                      <div />
                                    )}
                                  </label>
                                </div>
                              ) : (
                                <div />
                              )}

                              <input
                                id="contained-button-file"
                                style={{ display: 'none' }}
                                type="file"
                                onChange={onFileSelectedHandler}
                                onClick={onCloseEditImage}
                              />
                              {/* Cropper, checks if their is an uploaded file to edit, otherwise allow edit of existing image */}
                              {imageEditMode ? (
                                <div>
                                  {uploadedFile ? (
                                    <div>
                                      <Cropper
                                        ref={cropperRef}
                                        src={uploadedFile}
                                        style={{ height: 200, width: 200 }}
                                        checkCrossOrigin={false}
                                        aspectRatio={1}
                                        autoCropArea={1}
                                        guides={true}
                                        crop={() => onCrop()}
                                      />
                                      <div
                                        style={{
                                          textAlign: 'center',
                                          width: 200,
                                          padding: 10,
                                        }}
                                      >
                                        <RotateRightIcon
                                          className={classes.rotateImageIcon}
                                          onClick={() => onRotateImage()}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <Cropper
                                        ref={cropperRef}
                                        src={instrumentToEdit.userImageUrl}
                                        style={{ height: 200, width: 200 }}
                                        aspectRatio={1}
                                        autoCropArea={1}
                                        guides={true}
                                        crop={() => onCrop()}
                                      />
                                      <div
                                        style={{
                                          textAlign: 'center',
                                          width: 200,
                                          padding: 10,
                                        }}
                                      >
                                        <RotateRightIcon
                                          className={classes.rotateImageIcon}
                                          onClick={() => onRotateImage()}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div />
                              )}
                              <canvas
                                style={{ display: 'none' }}
                                id="imageCanvas"
                              />
                            </div>
                          ) : (
                            <div />
                          )}
                          {index === 1 ? (
                            <div>
                              <TextField
                                error={firstNameError}
                                required
                                label="Equipment Name"
                                className={classes.textField}
                                value={instrumentToEdit.firstName}
                                onChange={event =>
                                  onHandleChange({
                                    name: 'firstName',
                                    value: event?.target.value,
                                  })
                                }
                                margin="normal"
                              />
                              <br />
                              <TextField
                                error={lastNameError}
                                label="Serial #"
                                className={classes.textField}
                                value={instrumentToEdit.lastName}
                                onChange={event =>
                                  onHandleChange({
                                    name: 'lastName',
                                    value: event?.target.value,
                                  })
                                }
                                margin="normal"
                              />
                              <br />
                              <div
                                onClick={() => {
                                  setInstrumentToEdit({
                                    ...instrumentToEdit,
                                    isActive: !instrumentToEdit.isActive,
                                  });
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <Checkbox
                                  classes={{
                                    root: classes.checkBox,
                                    checked: classes.checked,
                                  }}
                                  checked={instrumentToEdit.isActive}
                                />
                                Is Active
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                          {index === 2 ? (
                            <div>
                              <FormControl>
                                {instrumentToEdit.appUserBadges.length > 0 ? (
                                  <div className={classes.badgesTitle}>
                                    Badges:
                                  </div>
                                ) : (
                                  <div />
                                )}

                                {instrumentToEdit.appUserBadges ? (
                                  <div>
                                    {alphabetizedAppUserBadges.map(
                                      (badge: IBadge) => (
                                        <div
                                          key={badge.badgeString}
                                          className={classes.badgeNumber}
                                        >
                                          {/* {badge.badgeString} <button onClick={() => this.deleteBadge(badge.badgeString)}>x</button> */}
                                          <Button
                                            className={classes.deleteButton}
                                            onClick={() => {
                                              onDeleteBadge(badge.badgeString);
                                            }}
                                          >
                                            x
                                          </Button>{' '}
                                          {badge.badgeString}{' '}
                                          {badge.expirationDate ? (
                                            <span
                                              className={
                                                classes.expirationDateText
                                              }
                                            >
                                              (exp.{' '}
                                              {badge.expirationDate.substring(
                                                0,
                                                10
                                              )}
                                              )
                                            </span>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div />
                                )}
                              </FormControl>

                              <div
                                style={{
                                  color: '#757575',
                                  marginBottom: 5,
                                  fontSize: 14,
                                }}
                              >
                                New Badge:
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    color: '#757575',
                                    fontSize: 24,
                                    marginBottom: 'auto',
                                    marginTop: 'auto',
                                  }}
                                >
                                  IR
                                </div>
                                <TextField
                                  type="tel"
                                  id="beacon4"
                                  style={{ width: 50, height: 50 }}
                                  inputProps={{
                                    min: 0,
                                    style: {
                                      textAlign: 'center',
                                      fontSize: 24,
                                      padding: 10,
                                    },
                                  }}
                                  value={beacon1}
                                  onClick={() => setBeacon1('')}
                                  onChange={e => {
                                    onNewUserBadgeInputs({
                                      beaconNumber: 'beacon1',
                                      value: e.target.value,
                                      nextFocusId: 'beacon5',
                                    });
                                  }}
                                  variant="outlined"
                                />
                                <TextField
                                  type="tel"
                                  id="beacon5"
                                  style={{ width: 50, height: 50 }}
                                  inputProps={{
                                    min: 0,
                                    style: {
                                      textAlign: 'center',
                                      fontSize: 24,
                                      padding: 10,
                                    },
                                  }}
                                  value={beacon2}
                                  onClick={() => setBeacon2('')}
                                  onChange={e => {
                                    onNewUserBadgeInputs({
                                      beaconNumber: 'beacon2',
                                      value: e.target.value,
                                      nextFocusId: 'beacon6',
                                    });
                                  }}
                                  variant="outlined"
                                />
                                <TextField
                                  type="tel"
                                  id="beacon6"
                                  style={{ width: 50, height: 50 }}
                                  inputProps={{
                                    min: 0,
                                    style: {
                                      textAlign: 'center',
                                      fontSize: 24,
                                      padding: 10,
                                    },
                                  }}
                                  value={beacon3}
                                  onClick={() => setBeacon3('')}
                                  onChange={e => {
                                    onNewUserBadgeInputs({
                                      beaconNumber: 'beacon3',
                                      value: e.target.value,
                                      nextFocusId: 'beacon7',
                                    });
                                  }}
                                  variant="outlined"
                                />
                                <TextField
                                  type="tel"
                                  id="beacon7"
                                  style={{ width: 50, height: 50 }}
                                  inputProps={{
                                    min: 0,
                                    style: {
                                      textAlign: 'center',
                                      fontSize: 24,
                                      padding: 10,
                                    },
                                  }}
                                  value={beacon4}
                                  onClick={() => setBeacon4('')}
                                  onChange={e => {
                                    onNewUserBadgeInputs({
                                      beaconNumber: 'beacon4',
                                      value: e.target.value,
                                      nextFocusId: null,
                                    });
                                  }}
                                  variant="outlined"
                                />
                              </div>

                              {displayBadgeExpiration ? (
                                <div>
                                  <div className={classes.expireTitle}>
                                    Badge Expiration: (00/00/0000)
                                  </div>
                                  <Tooltip
                                    title="Leave blank if no expiration."
                                    placement="right"
                                  >
                                    <TextField
                                      type="date"
                                      value={
                                        newBadgeExpiration
                                          ? newBadgeExpiration
                                          : ''
                                      }
                                      className={classes.textBadgeExpireField}
                                      onChange={event =>
                                        onHandleBadgeExpiration(event)
                                      }
                                      margin="normal"
                                      placeholder={'Never'}
                                    />
                                  </Tooltip>
                                </div>
                              ) : (
                                <div />
                              )}
                              <Button
                                id="insideAddBeaconButton"
                                disabled={
                                  !beacon1 || !beacon2 || !beacon3 || !beacon4
                                }
                                onClick={() => onHandleNewBadge(false)}
                                component="label"
                                color="primary"
                              >
                                Add Badge
                              </Button>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      ) : (
                        <div />
                      )}

                      <div>
                        <br />
                        <div
                          className={loading ? classes.loading : classes.gone}
                        >
                          <CircularProgress
                            className={classes.progress}
                            color="secondary"
                          />
                        </div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={onHandleBack}
                        >
                          Back
                        </Button>
                        {activeStep === steps.length - 1 ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onHandleNewBadge(false)}
                          >
                            Finish
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onHandleNext()}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </Modal>

          {/* Drawer to edit selected user */}
          <Drawer
            anchor="right"
            open={right}
            onClose={() => onToggleDrawer(false, instrumentToEdit)}
          >
            {instrumentToEdit ? (
              <div className={classes.drawer}>
                <div
                  className={classes.closeButton}
                  onClick={() => onToggleDrawer(false, instrumentToEdit)}
                >
                  <CloseButton />
                </div>
                {newInstrument ? (
                  <div />
                ) : (
                  <h2>Editing {instrumentToEdit.firstName}</h2>
                )}
                <br />

                <div className="imageUploadContainer">
                  <Tooltip
                    title="Click here to select a picture from your computer for the equipment."
                    placement="right"
                    style={{ cursor: 'pointer' }}
                  >
                    <label htmlFor="contained-button-file">
                      <Avatar
                        className={classes.bigAvatar}
                        alt=""
                        src={instrumentToEdit.userImageUrl}
                      />
                      <div
                        className={
                          userImageError
                            ? 'imageUploadOverlay imageUploadError'
                            : 'imageUploadOverlay'
                        }
                      >
                        <AddIcon
                          className="imageUploadIcon"
                          style={{ fontSize: 35 }}
                        />
                      </div>
                    </label>
                  </Tooltip>
                </div>
                <label htmlFor="contained-button-file">
                  {newInstrument ? (
                    <div style={{ paddingLeft: 22, cursor: 'pointer' }}>
                      Add Image
                    </div>
                  ) : (
                    <div />
                  )}
                </label>
                <input
                  id="contained-button-file"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={onFileSelectedHandler}
                  onClick={onCloseEditImage}
                />

                {/* Cropper, checks if their is an uploaded file to edit, otherwise allow edit of existing image */}
                {imageEditMode ? (
                  <div>
                    {uploadedFile ? (
                      <div>
                        <Cropper
                          ref={cropperRef}
                          src={uploadedFile}
                          style={{ height: 200, width: 200 }}
                          checkCrossOrigin={false}
                          aspectRatio={1}
                          autoCropArea={1}
                          guides={true}
                          crop={() => onCrop()}
                        />
                        <div
                          style={{
                            textAlign: 'center',
                            width: 200,
                            padding: 10,
                          }}
                        >
                          <RotateRightIcon
                            className={classes.rotateImageIcon}
                            onClick={() => onRotateImage()}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Cropper
                          ref={cropperRef}
                          src={instrumentToEdit.userImageUrl}
                          style={{ height: 200, width: 200 }}
                          aspectRatio={1}
                          autoCropArea={1}
                          guides={true}
                          crop={() => onCrop()}
                        />
                        <div
                          style={{
                            textAlign: 'center',
                            width: 200,
                            padding: 10,
                          }}
                        >
                          <RotateRightIcon
                            className={classes.rotateImageIcon}
                            onClick={() => onRotateImage()}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div />
                )}
                {newInstrument || hideEditImageButton ? (
                  <div />
                ) : (
                  <Button onClick={() => onEditImage()} color="primary">
                    Edit Image
                  </Button>
                )}
                <canvas style={{ display: 'none' }} id="imageCanvas" />

                <br />
                <TextField
                  error={firstNameError}
                  required
                  label="Equipment Name"
                  className={classes.textField}
                  value={instrumentToEdit.firstName}
                  onChange={event =>
                    onHandleChange({
                      name: 'firstName',
                      value: event?.target.value,
                    })
                  }
                  margin="normal"
                />
                <br />
                <TextField
                  error={lastNameError}
                  label="Serial #"
                  className={classes.textField}
                  value={instrumentToEdit.lastName}
                  onChange={event =>
                    onHandleChange({
                      name: 'lastName',
                      value: event?.target.value,
                    })
                  }
                  margin="normal"
                />

                <br />
                <div
                  onClick={() => {
                    setInstrumentToEdit({
                      ...instrumentToEdit,
                      isActive: !instrumentToEdit.isActive,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={instrumentToEdit.isActive}
                  />
                  Is Active
                </div>

                <FormControl className={classes.textField} margin="normal">
                  <div className={classes.badgesTitle}>Badges:</div>
                  {instrumentToEdit.appUserBadges ? (
                    <div>
                      {instrumentToEdit.appUserBadges.map((badge: IBadge) => (
                        <div
                          key={badge.badgeString}
                          className={classes.badgeNumber}
                        >
                          {/* {badge.badgeString} <button onClick={() => this.deleteBadge(badge.badgeString)}>x</button> */}
                          <Button
                            className={classes.deleteButton}
                            onClick={() => onDeleteBadge(badge.badgeString)}
                          >
                            x
                          </Button>{' '}
                          {badge.badgeString}{' '}
                          {badge.expirationDate ? (
                            <span className={classes.expirationDateText}>
                              (exp. {badge.expirationDate.substring(0, 10)})
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div />
                  )}
                </FormControl>
                <br />

                <div>
                  <div
                    style={{
                      color: '#757575',
                      marginBottom: 5,
                      fontSize: 14,
                    }}
                  >
                    New Badge:
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        color: '#757575',
                        fontSize: 24,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                      }}
                    >
                      IR
                    </div>
                    <TextField
                      type="tel"
                      id="beacon1"
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 5,
                        marginLeft: 5,
                      }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: 'center',
                          fontSize: 24,
                          padding: 10,
                        },
                      }}
                      value={beacon1}
                      // onChange={(e) => this.setState({ beacon1: e.target.value }) }
                      onClick={() => {
                        setBeacon1('');
                      }}
                      onChange={e => {
                        onBadgeInputs('beacon1', e.target.value, 'beacon2');
                      }}
                      variant="outlined"
                    />
                    <TextField
                      type="tel"
                      id="beacon2"
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 5,
                        marginLeft: 5,
                      }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: 'center',
                          fontSize: 24,
                          padding: 10,
                        },
                      }}
                      value={beacon2}
                      onClick={() => setBeacon2('')}
                      onChange={e => {
                        onBadgeInputs('beacon2', e.target.value, 'beacon3');
                      }}
                      variant="outlined"
                    />
                    <TextField
                      type="tel"
                      id="beacon3"
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 5,
                        marginLeft: 5,
                      }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: 'center',
                          fontSize: 24,
                          padding: 10,
                        },
                      }}
                      value={beacon3}
                      // onChange={(e) => this.setState({ beacon1: e.target.value })}
                      onClick={() => setBeacon3('')}
                      onChange={e => {
                        onBadgeInputs('beacon3', e.target.value, 'beacon4');
                      }}
                      variant="outlined"
                    />
                    <TextField
                      type="tel"
                      id="beacon4"
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 5,
                        marginLeft: 5,
                      }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: 'center',
                          fontSize: 24,
                          padding: 10,
                        },
                      }}
                      value={beacon4}
                      // onChange={(e) => this.setState({ beacon1: e.target.value })}
                      onClick={() => setBeacon4('')}
                      onChange={e => {
                        onBadgeInputs('beacon4', e.target.value, null);
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>

                <br />
                {displayBadgeExpiration ? (
                  <div>
                    <div className={classes.expireTitle}>
                      Badge Expiration: (00/00/0000)
                    </div>
                    <Tooltip
                      title="Leave blank if no expiration."
                      placement="right"
                    >
                      <TextField
                        type="date"
                        value={newBadgeExpiration ? newBadgeExpiration : ''}
                        className={classes.textBadgeExpireField}
                        onChange={event => onHandleBadgeExpiration(event)}
                        margin="normal"
                        placeholder={'Never'}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div />
                )}
                <br />
                <Button
                  id="outsideAddBeaconButton"
                  disabled={!beacon1 || !beacon2 || !beacon3 || !beacon4}
                  onClick={() => onHandleNewBadge(false)}
                  component="label"
                  color="primary"
                >
                  Add Badge
                </Button>
                <br />
                <div className={classes.saveArea}>
                  {hasModified ? (
                    <div className={classes.saveWarning}>Unsaved Changes!</div>
                  ) : (
                    <div />
                  )}

                  <div className={classes.container}>
                    <Button
                      className={classes.submitButton}
                      onClick={() => onSaveChanges()}
                      component="label"
                      color="primary"
                    >
                      {newInstrument ? (
                        <div>Add User</div>
                      ) : (
                        <div>Save Changes</div>
                      )}
                    </Button>

                    <div className={loading ? classes.loading : classes.gone}>
                      <CircularProgress
                        className={classes.progress}
                        color="secondary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
          </Drawer>
          {badgeWarning ? (
            <WarningSnack
              warningSnack={true}
              warningMessage="Note: Added Badge ID Doesn't Start With IR"
            />
          ) : (
            <div />
          )}

          {duplicateBadgeError ? (
            <ErrorSnack errorSnack={true} errorMessage="Duplicate Badge" />
          ) : (
            <div />
          )}
          {userImageError ? (
            <ErrorSnack
              errorSnack={true}
              errorMessage="Please Provide a User Image"
            />
          ) : (
            <div />
          )}

          {fieldError ? (
            <ErrorSnack
              errorSnack={true}
              errorMessage="Please Fill Out All Required Fields"
            />
          ) : (
            <div />
          )}
          {npiError ? (
            <ErrorSnack
              errorSnack={true}
              errorMessage="Please Provide a Valid NPI Number"
            />
          ) : (
            <div />
          )}
          {passwordReset ? (
            <SuccessSnack
              autoHideDuration={2000}
              successSnack={true}
              successMessage="Password has been reset!"
            />
          ) : (
            <div />
          )}
          {instrumentHasBeenCreated ? (
            <SuccessSnack
              successSnack={true}
              successMessage="User has been created! Have new user check their email!"
            />
          ) : (
            <div />
          )}
          <Joyride
            run={run}
            steps={STEPS}
            callback={onHandleJoyrideCallback}
            disableScrolling
            disableOverlayClose
            showSkipButton
            hideBackButton
            continuous
            spotlightClicks
            disableScrollParentFix
            styles={{
              options: {
                // arrowColor: "#e3ffeb",
                // backgroundColor: "#e3ffeb",
                // overlayColor: "rgba(79, 26, 0, 0.4)",
                primaryColor: '#50B848',
                // primaryColor: "#0595DA",
                // textColor: "#004a14",
                // width: 900,
                // zIndex: 1000,
              },
            }}
          />
        </MuiThemeProvider>
      ) : null}
    </div>
  );
};

export default Equipments;
