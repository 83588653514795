import
  {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    InputLabel,
    Typography,
  } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import
  {
    Control,
    useFieldArray,
    UseFormSetValue,
    UseFormWatch,
  } from 'react-hook-form';
import { ExpandMoreIcon, SelectField } from '../../../../common';

import
  {
    IPatientContent,
    ISelectOption,
  } from '../../../../interfaces/interfaces';
import { TFormSchema } from './DepartmentForm';
import PatientContentSubform from './PatientContentSubform';
import { useStyles } from './style';

type TProps = {
  control: Control<TFormSchema>;
  errors: any;
  watch: UseFormWatch<TFormSchema>;
  setValue: UseFormSetValue<TFormSchema>;
  patientContentList: IPatientContent[];
};

const PatientContentListSubform: FC<TProps> = props => {
  const { control, errors, patientContentList, setValue } = props;

  const classes = useStyles();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'patientContentIds',
  });

  const patientContentOptions: ISelectOption[] = patientContentList?.map(
    patientContent => {
      return {
        value: patientContent?.id,
        label: patientContent?.patientContentName,
        model: patientContent,
      };
    }
  );

  const onAdd = (patientContentId: string) => {
    const patientContent = patientContentList.find(
      patientContent => patientContent.id === patientContentId
    );

    /* 
    
    "id": "c4f56c92-c34a-4a23-b16e-f85fc0a40ea0",
    "name": null,
    "showBeforeProvider": false,
    "showAfterProvider": false,
    "showInResources": false,
    "showFullSurvey": false,
    "hideQrCode": false,
    "patientContentType": "Survey",
    "secondsToShow": 0,
    "onlyPlayOnce": false,
    "index": 0
    */

    const patientContentOption = patientContentOptions.find(
      option => option.value === patientContentId
    );

    console.log('onAdd', { patientContentOption, patientContent });

    if (patientContent) {
      const newPatientContent = {
        id: patientContent?.id,
        name: patientContent?.patientContentName,
        showBeforeProvider: false,
        showAfterProvider: false,
        showInResources: false,
        showFullSurvey: false,
        hideQrCode: false,
        patientContentType: 'Survey',
        secondsToShow: 0,
        onlyPlayOnce: false,
        index: 0,
      };

      append(newPatientContent);
    }

    setValue(`selectedPatientContentId`, '');
  };

  const onMoveUp = (index: number) => {
    if (index > 0) {
      move(index, index - 1);
    }
  };
  const onMoveDown = (index: number) => {
    if (index < fields.length - 1) {
      move(index, index + 1);
    }
  };

  return (
    <div>
      <Typography variant="h4" style={{ marginTop: 25 }}>
        Patient Content
      </Typography>

      {!isEmpty(patientContentList) && (
        <div>
          <div className={classes.expansionsContainer}>
            <FormControl className={classes.textField} style={{ width: 200 }}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Available Patient Content
              </InputLabel>

              <SelectField
                name={'selectedPatientContentId'}
                control={control}
                options={[
                  { label: '', value: undefined },
                  ...patientContentOptions,
                ]}
                inputId="age-native-label-placeholder"
                onSelect={onAdd}
                errors={errors}
              />
            </FormControl>
            <br />
            <br />

            {fields?.length === 0 ? null : (
              <Accordion>
                <AccordionSummary expandIcon={ExpandMoreIcon}>
                  <Typography>Displayed Content ({fields?.length})</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'inline-block' }}>
                  {fields?.map((patientContent: any, index: number) => (
                    <PatientContentSubform
                      key={patientContent.id}
                      fields={fields}
                      patientContent={patientContent}
                      onDelete={() => remove(index)}
                      onMoveDown={() => onMoveDown(index)}
                      onMoveUp={() => onMoveUp(index)}
                      control={control}
                      index={index}
                      errors={errors}
                      totalNumberOfFields={fields.length}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientContentListSubform;
