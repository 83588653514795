import React, { Component } from 'react';
import { getQueryVariable } from '../utils/utils';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// @ts-ignore
import synctimesLogo from '../images/synctimes.png';
import { API } from '../apiconfig';

// const QRCode = require("qrcode.react");
import { QRCodeSVG } from 'qrcode.react';

const styles: {} = () => ({
  splashContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  splashContent: {
    margin: 'auto',
    textAlign: 'center',
  },
  splashImage: {
    width: 300,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  splashMessage: {
    marginTop: 50,
    fontSize: 42,
    padding: 10,
  },
  // Video
  videoContainer: {
    textAlign: 'center',
    fontSize: 36,
    backgroundColor: 'black',
  },
  videoStyle: {
    width: '100%',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  // Qr Code
  reviewContainer: {
    textAlign: 'center',
    backgroundColor: '#0095DA',
    height: '100vh',
    justifyContent: 'center',
    fontSize: 30,
  },
  qrCodeColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid white',
    margin: 'auto',
  },
  qrMessageColumn: {
    margin: 'auto',
  },
  reviewMessage: {
    maxWidth: 350,
    margin: 'auto',
    color: 'white',
  },
  // New device
  newDeviceContainer: {
    textAlign: 'center',
    backgroundColor: '#4FAC48',
    height: '100vh',
    justifyContent: 'center',
    fontSize: 30,
  },
});

interface SplashProps {
  classes: any;
}

interface SplashState {
  image: any;
  videoUrl: string;
  qrCodeUrl: string;
  standardSplash: boolean;
  isVideo: boolean;
}

class Splash extends Component<SplashProps, SplashState> {
  constructor(props: SplashProps) {
    super(props);
    this.state = {
      image: synctimesLogo,
      videoUrl: '',
      qrCodeUrl: '',
      standardSplash: false,
      isVideo: false,
    };

    if (getQueryVariable('newDeviceId')) {
      console.log('got it');
      // this.state.qrCodeUrl =
      this.state = {
        ...this.state,
        qrCodeUrl:
          window.location.host +
          '/admin?devices&newDeviceId=' +
          getQueryVariable('newDeviceId'),
      };
      // this.setState({
      //   qrCodeUrl: window.location.host + "/admin?devices&newDeviceId=" + getQueryVariable("newDeviceId"),
      // });
    }
  }
  componentDidMount() {
    let videoUrl = getQueryVariable('videoUrl');
    let qrCodeUrl = getQueryVariable('qrCodeUrl');
    let orgId = getQueryVariable('orgId');

    if (orgId) {
      let URL = API.REACT_APP_API_ORGANIZATIONSPLASH + orgId;

      fetch(URL, { headers: { 'Org-UID': 'UID1', Pragma: 'no-cache' } })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({
            image: data.data.orgImageUrl,
          });
        })
        .catch(err => {
          console.error(err);
        });
    }

    if (videoUrl) {
      this.setState({
        isVideo: true,
        videoUrl: videoUrl,
      });
    }

    if (qrCodeUrl) {
      this.setState({
        qrCodeUrl: qrCodeUrl,
      });
    }

    if (!videoUrl && !qrCodeUrl) {
      this.setState({
        standardSplash: true,
      });
    }
  }

  render() {
    let qrBgColor = '#0095DA';
    // console.log("check", window.location.host);

    if (getQueryVariable('newDeviceId')) {
      qrBgColor = '#4FAC48';
    }

    console.log('code url', this.state.qrCodeUrl);
    const { classes } = this.props;
    return (
      <div>
        {/* VIDEO */}
        {this.state.isVideo ? (
          <div className={classes.videoContainer}>
            <div
              // Have to use dangerouslySetInnerHTML due to video randomly playing in the background
              dangerouslySetInnerHTML={{
                __html: `
              <video
                style="width: 100%; height: 100vh; max-width: 100vw; max-height: 100vh"
                loop
                muted
                autoplay
                playsinline
                src="${this.state.videoUrl}"
              />,
            `,
              }}
            ></div>
          </div>
        ) : null}

        {/* REVIEW/QRCODE */}
        {this.state.qrCodeUrl && (
          <Grid
            container
            className={
              getQueryVariable('newDeviceId')
                ? classes.newDeviceContainer
                : classes.reviewContainer
            }
          >
            <Grid item xs={6} className={classes.qrCodeColumn}>
              <QRCodeSVG
                fgColor="#FFFFFF"
                bgColor={qrBgColor}
                size={400}
                value={this.state.qrCodeUrl}
              />
            </Grid>
            <Grid item xs={6} className={classes.qrMessageColumn}>
              {getQueryVariable('newDeviceId') ? (
                <div className={classes.reviewMessage}>
                  <div>This device is not registered.</div>
                  <br />
                  <div>Serial number: {getQueryVariable('newDeviceId')}</div>
                </div>
              ) : (
                <div className={classes.reviewMessage}>
                  <div>
                    Thank you for your feedback! Leave us a Google review to let
                    others know how much we value our patients.
                  </div>
                  <br />
                  <div>
                    Simply open your camera and point it at this screen. You'll
                    receive a prompt to leave us a Google review.
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        )}

        {/* STANDARD SPLASH */}
        {this.state.standardSplash && (
          <div className={classes.splashContainer}>
            <div className={classes.splashContent}>
              <img
                className={classes.splashImage}
                src={this.state.image}
                alt="SyncTimes"
              />
              <div className={classes.splashMessage}>
                Please feel free to use this screen to request a nurse or
                respond to a survey.
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Splash);
