import { detect } from 'detect-browser';
import { FC, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { fetchLoggedInUser } from '../../../../actions/adminActions';
import { API } from '../../../../apiconfig';
import {
  IDepartment,
  IProvider,
  ISite,
  IUser,
} from '../../../../interfaces/interfaces';
import ErrorSnack from '../../../../utils/ErrorSnack';
import { canDo } from '../../../../utils/permissionCheck';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';
import { checkRefreshToken } from '../../../../utils/utils';

// Material
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DesktopIcon from '@material-ui/icons/DesktopMac';
import GroupIcon from '@material-ui/icons/Group';
import PhoneIcon from '@material-ui/icons/Phone';
import MobileIcon from '@material-ui/icons/Smartphone';
import SMSIcon from '@material-ui/icons/Textsms';

//TODO
// what should orgId be set to?

// @ts-ignore
import notificationSMS from '../../../../images/notificationSMS.PNG';
// @ts-ignore
import notificationAppImage from '../../../../images/notificationApp.PNG';
// @ts-ignore
import notificationDesktopImage from '../../../../images/notificationDesktop.PNG';
// @ts-ignore
import { useNotify } from '../../../../common/notify/useNotify';
import { useDistinctOrgActions } from '../../../../hooks/useDistinctOrgActions/useDistinctOrgActions';
import { useFetchAllActions } from '../../../../hooks/useFetchAllActions';
import { useIntegrations } from '../../../../hooks/useIntegrations';
import { useProviderAssignmentUsers } from '../../../../hooks/useProviderAssignmentUsers/useProviderAssignmentUsers';
import { useSiblingDepartments } from '../../../../hooks/useSiblingDepartments';
import { useSites } from '../../../../hooks/useSites';
import notificationPhone from '../../../../images/notificationPhone.PNG';
import NotificationNewModal from './new/NotificationNewModal';
import { useStyles } from './styles';

const _ = require('lodash');

// const notificationSMS = require("../../../images/notificationSMS.PNG");
// const notificationAppImage = require("../../../images/notificationApp.PNG");
// const notificationDesktopImage = require("../../../images/notificationDesktop.PNG");
// const notificationPhone = require("../../../images/notificationPhone.PNG");

let orgId: any = null;
const Browser = detect();

type TProps = {
  modifyingOtherUsers: boolean;
  reFetchUserData?: () => void;
  loggedInUserData: IUser;
  applyUserStyles?: boolean;
};

const Notifications: FC<TProps> = props => {
  const {
    modifyingOtherUsers,
    reFetchUserData = () => {},
    loggedInUserData,
    applyUserStyles = false,
  } = props;
  const classes = useStyles();

  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [userToEdit, setUserToEdit] = useState(null);
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [verifyPhoneModalOpen, setVerifyPhoneModalOpen] = useState(false);
  const [showConfirmationInput, setShowConfirmationInput] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [confirmationCodeText, setConfirmationCodeText] = useState('');
  const [validatedPhoneSuccess, setValidatedPhoneSuccess] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [sentTestNotificationSnack, setSentTestNotificationSnack] =
    useState(false);
  const [newAlertModalOpen, setNewAlertModalOpen] = useState(false);

  const { notify } = useNotify();

  if (loggedInUserData?.mongoOrganizationId) {
    orgId = loggedInUserData.mongoOrganizationId;
  }

  if (
    localStorage.getItem('lastMockedOrganizationId') &&
    canDo(['SyncAdmin'], loggedInUserData)
  ) {
    orgId = localStorage.getItem('lastMockedOrganizationId');
  }

  const { integrations } = useIntegrations(orgId);
  const { siblingDepartments } = useSiblingDepartments(orgId);
  const { actions: allActions } = useFetchAllActions();
  const { providerAssignmentUsers } = useProviderAssignmentUsers(orgId);
  const { actions: distinctOrgActions = [] } = useDistinctOrgActions(orgId);

  const { sites } = useSites(orgId);

  const onHandleConfirmationCodeText = (e: any) => {
    setConfirmationCodeText(e.target.value);
  };

  const onOpenVerifyPhoneNumber = () => {
    console.log('OPEN VERIFY PHONE NUMBER DIALOG BOX');
    setVerifyPhoneModalOpen(true);
  };

  const onVerifyBySMS = () => {
    console.log('Verify by SMS');

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_VERIFYBYSMS + loggedInUserData.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log("RES", data);
      })
      .catch(function (err) {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    if (modifyingOtherUsers) {
      reFetchUserData();
    }

    setShowConfirmationInput(true);
  };

  const onVerifyByCall = () => {
    console.log('Verify by call');

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_VERIFYBYCALL + loggedInUserData.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        console.log('RES', data);
      })
      .catch(function (err) {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    if (modifyingOtherUsers) {
      reFetchUserData();
    }

    setShowConfirmationInput(true);
  };

  const onVerifyConfirmationCode = () => {
    let token = localStorage.getItem('token');
    let payload = {
      id: loggedInUserData.id,
      phoneNumberVerificationCode: confirmationCodeText,
    };

    // console.log("PAYLOAD", payload);
    fetch(API.REACT_APP_API_VERIFYPHONE, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(data => {
        // console.log("RES", data.data);
        if (data.data === 'success') {
          setShowConfirmationInput(false);
          setConfirmationError(false);
          setVerifyPhoneModalOpen(false);
          setValidatedPhoneSuccess(true);
          setConfirmationCodeText('');

          let userEmail = localStorage.getItem('email');
          let token = localStorage.getItem('token');
          fetchLoggedInUser(userEmail, token, orgId);
          if (modifyingOtherUsers) {
            reFetchUserData();
          }

          setTimeout(() => {
            setValidatedPhoneSuccess(false);
          }, 5000);
        } else {
          setConfirmationError(true);
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    // this.setState({
    //   showConfirmationInput: true,
    // });
  };

  const onUpdateUser = () => {
    // console.log("formatted phone", formattedPhoneNumber[0]);

    let SMSexists = false;

    // if (_.some(this.props.loggedInUserData.subscribedActions, { messageType: "SMS" })) {
    //   console.log("EXISTS");
    //   SMSexists = true;
    // } else {
    //   SMSexists = false;
    // }

    // if (this.state.userToEdit.phoneNumber){

    // }

    if (validPhoneNumber) {
      if (userToEdit.phoneNumber && validPhoneNumber) {
        // console.log("userToEdit.phoneNumber", userToEdit.phoneNumber);
        SMSexists = true;
      }

      let sendRequest = () => {
        let user = {
          ...loggedInUserData,
          phoneNumber: userToEdit.phoneNumber,
          phoneNumberExtension: userToEdit.phoneNumberExtension,
          // phoneNumber: formattedPhoneNumber[0],
        };

        // console.log("payload", user);

        let token = localStorage.getItem('token');
        fetch(API.REACT_APP_API_SUBSCRIPTIONS, {
          method: 'PUT',
          body: JSON.stringify(user),
          // body: JSON.stringify(deleted),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(data => {
            // console.log("res", data);

            console.log('User updated', user);

            if (data.data === 'success') {
              notify({ message: `Changes have been saved!` });

              setValidPhoneNumber(true);
              setPhoneChanged(false);

              if (user.phoneNumber !== '') {
                console.log('Open modal1', user.phoneNumber);

                setVerifyPhoneModalOpen(true);
              }

              let userEmail = localStorage.getItem('email');
              fetchLoggedInUser(userEmail, token, orgId);
              if (modifyingOtherUsers) {
                reFetchUserData();
              }
            }
          })
          .catch(function (err) {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      };

      if (SMSexists) {
        if (validPhoneNumber) {
          sendRequest();
        } else {
          console.log('Bad number');
          setPhoneNumberError(true);
          setValidPhoneNumber(false);

          setTimeout(() => {
            setPhoneNumberError(false);
          }, 5000);
        }
      } else {
        sendRequest();
      }
    }
  };

  const onTryMobile = () => {
    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_TESTMOBILE + loggedInUserData.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        console.log(data);
      })
      .catch(function (err) {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    onSentTestNotification();
  };

  const onTrySMS = () => {
    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_TESTSMS + loggedInUserData.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data);
      })
      .catch(function (err) {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
    onSentTestNotification();
  };

  const onTryCall = () => {
    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_TESTCALL + loggedInUserData.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data);
      })
      .catch(function (err) {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
    onSentTestNotification();
  };

  const onTryDesktop = () => {
    let mobile =
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1;

    // Only display on desktop
    if (!mobile) {
      if (Notification.permission !== 'granted') {
        // console.log("DENIED");
      } else {
        let token = localStorage.getItem('token');
        fetch(API.REACT_APP_API_TESTNOTIFICATION + loggedInUserData.id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(function (data) {
            // console.log(data);
          })
          .catch(function (err) {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    }
    onSentTestNotification();
  };

  const onSentTestNotification = () => {
    setSentTestNotificationSnack(true);

    setTimeout(() => {
      setSentTestNotificationSnack(false);
    }, 5000);
  };

  const onTryMicrosoftTeams = () => {
    console.log('try microsoft teams');

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_TESTTEAMSCHAT + loggedInUserData.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data);
      })
      .catch(function (err) {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
    onSentTestNotification();
  };

  const onCheckBrowserCompatibility = () => {
    let mobile =
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1;

    // Only display on desktop
    if (!mobile) {
      if (Notification.permission !== 'granted') {
        // console.log("DENIED");
      }
    }
  };

  const onToggleAll = (alertTypeString: string, checkAll: boolean) => {
    let user = loggedInUserData;
    let actionsListToModify = user.subscribedActions;

    actionsListToModify.forEach((action: any) => {
      if (checkAll) {
        if (!action.messageType.includes(alertTypeString)) {
          action.messageType = action.messageType + ' ' + alertTypeString;
          action.messageType = action.messageType.trim();
        }
      } else {
        if (action.messageType.includes(alertTypeString)) {
          action.messageType = action.messageType.replace(alertTypeString, '');
          action.messageType = action.messageType.trim();
        }
      }
    });

    user.subscribedActions = actionsListToModify;
    // console.log("check", user)

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SUBSCRIPTIONS, {
      method: 'PUT',
      body: JSON.stringify(user),
      // body: JSON.stringify(deleted),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(data => {
        if (data.data === 'success') {
          notify({ message: `Changes have been saved!` });
          setValidPhoneNumber(true);
          setPhoneChanged(false);
        }
      });
  };

  const onHandleAlertCheck = (
    alertTypeString: string,
    alertToModify: any,
    newSubscribedActions: any
  ) => {
    // console.log("TOGGLE", alertToModify);

    let cleanedActions: any[] = [];
    newSubscribedActions.forEach((action: any) => {
      cleanedActions.push({
        actionId: action.id,
        deptId: action.subbedDeptId,
        providerId: action.providerId,
        messageType: action.messageType,
      });
    });
    // console.log("CLEAN", cleanedActions);

    let user = loggedInUserData;

    let modifiedAlertString = alertToModify.messageType;

    if (modifiedAlertString.includes(alertTypeString)) {
      // Remove alert type
      modifiedAlertString = modifiedAlertString.replace(alertTypeString, '');
    } else {
      // Add alert type
      modifiedAlertString = modifiedAlertString + ' ' + alertTypeString;

      // @ts-ignore
      if (Browser.name !== 'ie') {
        onCheckBrowserCompatibility();
      }
    }

    // alertToModify.messageType = modifiedAlertString.trim();

    // console.log("UPDATED", alertToModify);

    cleanedActions.forEach((action, i) => {
      // console.log("check 1", action);
      // console.log("check 2", alertToModify);
      if (
        action.actionId === alertToModify.id &&
        action.providerId === alertToModify.providerId &&
        action.deptId === alertToModify.subbedDeptId
        // &&
        // action.messageType === alertToModify.messageType
      ) {
        // console.log("CHANGE THIS", user.subscribedActions[i]);
        cleanedActions[i].messageType = modifiedAlertString.trim();
      }
    });

    user.subscribedActions = cleanedActions;

    if (userToEdit) {
      if (userToEdit.phoneNumber) {
        user.phoneNumber = userToEdit.phoneNumber;
      }
    }

    console.log('DONE', user);

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SUBSCRIPTIONS, {
      method: 'PUT',
      body: JSON.stringify(user),
      // body: JSON.stringify(deleted),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(data => {
        // console.log("res", data);

        // ReactGA.event({
        //   category: "Home",
        //   action: "Created Action Alert",
        //   label: "Alert",
        // });

        let userEmail = localStorage.getItem('email');
        let token = localStorage.getItem('token');

        if (data.data === 'success') {
          setValidPhoneNumber(true);
          setPhoneChanged(false);

          // console.log("User2", user);
          // if (user.phoneNumber !== "") {
          //   console.log("Open modal2", user.phoneNumber);
          //   this.setState({
          //     verifyPhoneModalOpen: true,
          //   });
          // }

          notify({ message: `Changes have been saved!` });
        }

        fetchLoggedInUser(userEmail, token, orgId);
        if (modifyingOtherUsers) {
          reFetchUserData();
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onDeleteSubbedAction = (subbedAction: any) => {
    // console.log("SubbedAction", subbedAction);

    let userSubscribedActions = loggedInUserData.subscribedActions;
    // console.log("userSubscribedActions", userSubscribedActions);
    _.remove(userSubscribedActions, {
      actionId: subbedAction.id,
      deptId: subbedAction.subbedDeptId,
      messageType: subbedAction.messageType,
      providerId: subbedAction.providerId,
    });

    // console.log("NEW", userSubscribedActions);

    let token = localStorage.getItem('token');

    let updatedUser: any = null;

    if (userToEdit) {
      updatedUser = {
        ...loggedInUserData,
        subscribedActions: userSubscribedActions,
      };
    } else {
      updatedUser = {
        ...loggedInUserData,
        subscribedActions: userSubscribedActions,
      };
    }

    fetch(API.REACT_APP_API_SUBSCRIPTIONS, {
      method: 'PUT',
      body: JSON.stringify(updatedUser),
      // body: JSON.stringify(deleted),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(() => {
        // console.log("deleted subbed action", data);

        // this.props.reFetchUserData();

        if (updatedUser) {
          setUserToEdit(updatedUser);
          notify({ message: `Changes have been saved!` });
        }

        let userEmail = localStorage.getItem('email');
        let token = localStorage.getItem('token');
        if (userEmail && token) {
          fetchLoggedInUser(userEmail, token, orgId);
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onCloseNewAlertModal = () => {
    setUserToEdit({ ...loggedInUserData });
    setNewAlertModalOpen(false);
  };

  const onNewAlertModal = () => {
    setNewAlertModalOpen(true);
  };

  let newSubscribedActions: any[] = [];
  // console.log('all', this.props.allActions)
  // console.log('subscribed', this.props.loggedInUserData.subscribedActions)
  loggedInUserData.subscribedActions.forEach((subscribedAction: any) => {
    allActions.forEach((action: any) => {
      if (subscribedAction.actionId === action.id) {
        // console.log("SUBSCRIBED ACTION", subscribedAction);
        let actionData = {
          ...action,
          subbedDeptId: subscribedAction.deptId,
          messageType: subscribedAction.messageType,
          providerId: subscribedAction.providerId,
        };
        newSubscribedActions.push(actionData);
      } else {
        // console.log('missing', action)
      }
      // newSubscribedActions.push(action)
    });
  });

  // console.log("All subscribed actions", newSubscribedActions);
  let uniqActions = _.uniqBy(newSubscribedActions, (v: any) =>
    [v.id, v.providerId, v.subbedDeptId].join()
  );

  let differenceActions = _.difference(newSubscribedActions, uniqActions);

  uniqActions.forEach((uniqAction: any, index: any) => {
    differenceActions.forEach((diffAction: any) => {
      if (
        uniqAction.actionName === diffAction.actionName &&
        uniqAction.providerId === diffAction.providerId &&
        uniqAction.subbedDeptId === diffAction.subbedDeptId
      ) {
        // console.log("GOT IT", diffAction);
        uniqActions[index].messageType =
          uniqActions[index].messageType + ' ' + diffAction.messageType;
      }
    });
  });

  newSubscribedActions = uniqActions;

  let activeDepartments: IDepartment[] = [];
  siblingDepartments.forEach(siblingDepartment => {
    if (siblingDepartment.isActive) {
      activeDepartments.push(siblingDepartment);
    }
  });

  let activeSites: any[] = [];
  sites.forEach((site: ISite) => {
    if (site.isActive) {
      activeSites.push(site);
    }
  });

  let checkedAllSMS = true;
  let checkedAllCall = true;
  let checkedAllMobile = true;
  let checkedAllDesktop = true;
  let checkedAllTeamsChat = true;

  loggedInUserData.subscribedActions.forEach((action: any) => {
    if (!action.messageType.includes('SMS')) {
      checkedAllSMS = false;
    }
    if (!action.messageType.includes('Call')) {
      checkedAllCall = false;
    }
    if (!action.messageType.includes('Mobile')) {
      checkedAllMobile = false;
    }
    if (!action.messageType.includes('Desktop')) {
      checkedAllDesktop = false;
    }
    if (!action.messageType.includes('TeamsChat')) {
      checkedAllTeamsChat = false;
    }
  });

  return (
    <div>
      <NotificationNewModal
        open={newAlertModalOpen}
        onClose={onCloseNewAlertModal}
        orgId={orgId}
        distinctOrgActions={distinctOrgActions}
        onSuccess={() => {
          reFetchUserData();
        }}
        loggedInUser={loggedInUserData}
      />
      <Modal
        open={verifyPhoneModalOpen && !loggedInUserData.isPhoneNumberVerified}
        onClose={() => {
          setVerifyPhoneModalOpen(false);
          setShowConfirmationInput(false);
          setConfirmationError(false);
          setConfirmationCodeText('');
        }}
      >
        <div className={classes.newAlertModal}>
          <Typography variant="h5" gutterBottom component="h2">
            Verify Phone Number
          </Typography>

          {!showConfirmationInput ? (
            <div>
              <div>How would you like to receive your confirmation code?</div>
              <div style={{ display: 'flex', marginTop: 20 }}>
                <Button
                  onClick={() => onVerifyBySMS()}
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 10 }}
                >
                  SMS
                </Button>
                <Button
                  onClick={() => onVerifyByCall()}
                  variant="contained"
                  color="primary"
                >
                  Call
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: 20 }}>
              A confirmation code is being sent to{' '}
              <strong>{loggedInUserData.phoneNumber}.</strong>
              <br />
              {confirmationError ? (
                <div style={{ color: 'red', marginTop: 10 }}>
                  Oops! That code seems to be invalid!
                  <br />
                </div>
              ) : null}
              <TextField
                error={confirmationError}
                style={{ marginTop: 10 }}
                variant="outlined"
                value={confirmationCodeText}
                onChange={onHandleConfirmationCodeText}
                label="Confirmation Code"
                // variant="outlined"
              />
              <br />
              <Button
                onClick={() => onVerifyConfirmationCode()}
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <div>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 w-full max-w-[500px]">
          <div className="flex-grow min-w-0 sm:mr-2">
            {validPhoneNumber === false ? (
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'red',
                  fontSize: 12,
                }}
              >
                Invalid Phone Number
              </div>
            ) : (
              <div style={{ fontSize: 12, color: 'gray' }}>Phone Number</div>
            )}

            <div className="h-[26px] w-full">
              <PhoneInput
                // disabled={this.state.phoneNumberLocked}
                country="US"
                className={
                  applyUserStyles
                    ? 'user-custom-phone-input w-full'
                    : 'flowstation-custom-phone-input w-full'
                }
                // className="w-[154px] h-[16px]"
                defaultCountry="US"
                style={{ resize: 'none', flex: 1, padding: 0 }}
                placeholder="Enter phone number"
                value={
                  userToEdit
                    ? userToEdit.phoneNumber
                    : loggedInUserData.phoneNumber
                }
                onChange={newValue => {
                  if (newValue) {
                    setUserToEdit({
                      ...loggedInUserData,
                      phoneNumber: newValue,
                      phoneNumberExtension: userToEdit
                        ? userToEdit.phoneNumberExtension
                        : loggedInUserData.phoneNumberExtension,
                    });

                    setValidPhoneNumber(isValidPhoneNumber(newValue));

                    setPhoneChanged(true);
                  } else {
                    setUserToEdit({
                      ...loggedInUserData,
                      phoneNumber: '',
                      phoneNumberExtension: userToEdit
                        ? userToEdit.phoneNumberExtension
                        : loggedInUserData.phoneNumberExtension,
                    });

                    setValidPhoneNumber(true);

                    setPhoneChanged(true);
                  }
                }}
              />
            </div>
          </div>

          <div className="w-full sm:w-[130px] flex-shrink-0">
            <div style={{ fontSize: 12, color: 'gray' }}>
              Ext.
              <span className="hidden sm:inline-block sm:ml-1">
                (comma for pause)
              </span>
            </div>
            <div className="h-[26px]">
              <input
                className="h-[16px] w-full"
                onChange={e => {
                  if (e.target.value) {
                    let ext = e.target.value.replace(/[^\d,]+/g, '');
                    setUserToEdit({
                      ...loggedInUserData,
                      phoneNumberExtension: ext,
                      phoneNumber: userToEdit
                        ? userToEdit.phoneNumber
                        : loggedInUserData.phoneNumber,
                    });

                    setPhoneChanged(true);
                  } else {
                    setUserToEdit({
                      ...loggedInUserData,
                      phoneNumberExtension: '',
                      phoneNumber: userToEdit
                        ? userToEdit.phoneNumber
                        : loggedInUserData.phoneNumber,
                    });
                  }
                }}
                value={
                  userToEdit
                    ? userToEdit.phoneNumberExtension
                    : loggedInUserData.phoneNumberExtension ?? ''
                }
              />
            </div>
          </div>
        </div>

        <div
          style={{
            fontSize: 12,
            color: 'gray',
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          By giving SyncTimes your phone number, you consent to receive text
          messsages from SyncTimes (+1 (844) 230-4527)
        </div>

        {phoneChanged ? (
          // <div onClick={this.updateUser}>Save</div>
          <Button
            style={{ marginTop: 10 }}
            size="small"
            color="primary"
            variant="contained"
            onClick={onUpdateUser}
          >
            Save
          </Button>
        ) : null}

        {/* {console.log("user data", this.props.loggedInUserData)} */}
        {loggedInUserData.phoneNumber && phoneChanged === false ? (
          <div>
            {loggedInUserData.isPhoneNumberVerified ? null : (
              <Button
                size="small"
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  backgroundColor: 'red',
                  color: 'white',
                }}
                variant="contained"
                onClick={() => onOpenVerifyPhoneNumber()}
              >
                Verify phone number
              </Button>
              // <button className={classes.verifyButton} onClick={() => this.openVerifyPhoneNumber()}>
              //   YOUR NUMBER MUST BE VERIFIED
              // </button>
            )}
          </div>
        ) : null}
      </div>

      <div>
        {/* @ts-ignore */}
        {Browser.name === 'ie' ? (
          <div style={{ color: 'red' }}>
            Desktop notifications are not compatible with Internet Explorer
          </div>
        ) : null}
      </div>

      <TableContainer style={{ maxHeight: '250px', paddingRight: 10 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                className="align-bottom"
                padding="none"
                style={{ fontSize: 12 }}
              >
                <div>Dept/</div>
                <div style={{ marginTop: '-10px' }}>Staff</div>
              </TableCell>
              <TableCell
                padding="none"
                align="center"
                style={{ paddingLeft: 5, paddingRight: 0, fontSize: 12 }}
              >
                Action
              </TableCell>
              <TableCell padding="none" align="center" style={{ fontSize: 12 }}>
                <div className="flex justify-between flex-col">
                  <div>
                    <Tooltip
                      title={
                        <div style={{ padding: 5 }}>
                          <div>
                            <img
                              src={notificationSMS}
                              alt=""
                              style={{ maxWidth: '100%' }}
                            />
                          </div>
                          Receive notifications through text messages
                        </div>
                      }
                      placement="bottom"
                    >
                      <SMSIcon
                        style={{ cursor: 'pointer' }}
                        onClick={onTrySMS}
                      />
                    </Tooltip>
                  </div>
                  {loggedInUserData.phoneNumber &&
                  loggedInUserData.isPhoneNumberVerified ? (
                    <div
                      className="pt-1"
                      onClick={onTrySMS}
                      style={{
                        color: 'gray',
                        marginTop: '-10px',
                        cursor: 'pointer',
                      }}
                    >
                      Test
                    </div>
                  ) : (
                    <div
                      className="pt-1"
                      onClick={onTrySMS}
                      style={{
                        color: 'gray',
                        marginTop: '-10px',
                        cursor: 'pointer',
                      }}
                    >
                      Test
                    </div>
                  )}
                  {newSubscribedActions.length > 0 ? (
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      checked={checkedAllSMS}
                      onClick={() => onToggleAll('SMS', !checkedAllSMS)}
                    />
                  ) : null}
                </div>
              </TableCell>
              <TableCell padding="none" align="center" style={{ fontSize: 12 }}>
                <div className="flex justify-between flex-col">
                  <div>
                    {/* Call */}
                    <Tooltip
                      title={
                        <div style={{ padding: 5 }}>
                          <div>
                            <img
                              src={notificationPhone}
                              alt=""
                              style={{ maxWidth: '100%' }}
                            />
                          </div>
                          Receive notifications through phone call (This is
                          popular solution for landline phones)
                        </div>
                      }
                      placement="bottom"
                    >
                      <PhoneIcon
                        style={{ cursor: 'pointer' }}
                        onClick={onTryCall}
                      />
                    </Tooltip>
                  </div>
                  {loggedInUserData.phoneNumber &&
                  loggedInUserData.isPhoneNumberVerified ? (
                    <div
                      className="pt-1"
                      onClick={onTryCall}
                      style={{
                        color: 'gray',
                        marginTop: '-10px',
                        cursor: 'pointer',
                      }}
                    >
                      Test
                    </div>
                  ) : (
                    <div
                      className="pt-1"
                      style={{
                        color: 'gray',
                        marginTop: '-10px',
                        cursor: 'pointer',
                      }}
                    >
                      &nbsp;
                    </div>
                  )}
                  {newSubscribedActions.length > 0 ? (
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      checked={checkedAllCall}
                      onClick={() => onToggleAll('Call', !checkedAllCall)}
                    />
                  ) : null}
                </div>
              </TableCell>
              <TableCell padding="none" align="center" style={{ fontSize: 12 }}>
                <div className="flex justify-between flex-col">
                  <div>
                    {/* Desktop */}
                    <Tooltip
                      title={
                        <div style={{ padding: 5 }}>
                          <div>
                            <img
                              src={notificationDesktopImage}
                              alt=""
                              style={{ maxWidth: '100%' }}
                            />
                          </div>
                          Receive notifications through your Browser
                        </div>
                      }
                      placement="bottom"
                    >
                      <DesktopIcon
                        style={{ cursor: 'pointer' }}
                        onClick={onTryDesktop}
                      />
                    </Tooltip>
                  </div>
                  {typeof window.orientation !== 'undefined' ||
                  navigator.userAgent.indexOf('IEMobile') !== -1 ? (
                    <div />
                  ) : (
                    <div>
                      {/* @ts-ignore */}
                      {Browser.name !== 'ie' ? (
                        <div
                          className="pt-1"
                          onClick={onTryDesktop}
                          style={{
                            color: 'gray',
                            marginTop: '-10px',
                            cursor: 'pointer',
                          }}
                        >
                          Test
                        </div>
                      ) : (
                        <div
                          className="pt-1"
                          style={{
                            color: 'gray',
                            marginTop: '-10px',
                            cursor: 'pointer',
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                      {newSubscribedActions.length > 0 ? (
                        <Checkbox
                          className={classes.checkbox}
                          color="primary"
                          checked={checkedAllDesktop}
                          onClick={() =>
                            onToggleAll('Desktop', !checkedAllDesktop)
                          }
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              </TableCell>

              <TableCell padding="none" align="center" style={{ fontSize: 12 }}>
                <div className="flex justify-between flex-col">
                  <div>
                    {/* Mobile */}
                    <Tooltip
                      title={
                        <div style={{ padding: 5 }}>
                          <div>
                            <img
                              src={notificationAppImage}
                              alt=""
                              style={{ width: '100%' }}
                            />
                          </div>
                          Receive notifications through push notifications that
                          show on your phones lock screen. Note: You must have
                          the SyncTimes mobile app installed and logged in.
                        </div>
                      }
                      placement="bottom"
                    >
                      <MobileIcon
                        style={{ cursor: 'pointer' }}
                        onClick={onTryMobile}
                      />
                    </Tooltip>
                  </div>
                  {loggedInUserData.fcmDeviceTokens.length > 0 ? (
                    <div
                      className="pt-1"
                      onClick={onTryMobile}
                      style={{
                        color: 'gray',
                        marginTop: '-10px',
                        cursor: 'pointer',
                      }}
                    >
                      <span>Test</span>
                    </div>
                  ) : (
                    <div
                      className="pt-1"
                      onClick={onTryMobile}
                      style={{
                        color: 'gray',
                        marginTop: '-10px',
                        cursor: 'pointer',
                      }}
                    >
                      <span>&nbsp;</span>
                    </div>
                  )}
                  {newSubscribedActions.length > 0 ? (
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      checked={checkedAllMobile}
                      onClick={() => onToggleAll('Mobile', !checkedAllMobile)}
                    />
                  ) : null}
                </div>
              </TableCell>

              {_.some(integrations, {
                integrationName: 'Microsoft Teams',
              }) ? (
                <TableCell
                  padding="none"
                  align="center"
                  style={{ fontSize: 12 }}
                >
                  <div className="flex justify-between flex-col">
                    <div>
                      <Tooltip
                        title={
                          <div style={{ padding: 5 }}>
                            {/* <div>
                            <img src={notificationAppImage} alt="" style={{ width: "100%" }} />
                          </div> */}
                            Receive notifications through Microsoft Teams
                          </div>
                        }
                        placement="bottom"
                      >
                        <GroupIcon
                          style={{ cursor: 'pointer' }}
                          onClick={onTryMicrosoftTeams}
                        />
                      </Tooltip>
                      <div
                        className="pt-1"
                        onClick={onTryMicrosoftTeams}
                        style={{
                          color: 'gray',
                          marginTop: '-10px',
                          cursor: 'pointer',
                        }}
                      >
                        Test
                      </div>
                      {newSubscribedActions.length > 0 && (
                        <Checkbox
                          className={classes.checkbox}
                          color="primary"
                          checked={checkedAllTeamsChat}
                          onClick={() =>
                            onToggleAll('TeamsChat', !checkedAllTeamsChat)
                          }
                        />
                      )}
                    </div>
                  </div>
                </TableCell>
              ) : null}

              <TableCell padding="none"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newSubscribedActions.map((subbedAction, index) => (
              <TableRow key={index}>
                <TableCell padding="none" style={{ fontSize: 12 }}>
                  {siblingDepartments.map(dept =>
                    dept.id === subbedAction.subbedDeptId
                      ? sites.map(site =>
                          site.id === dept.mongoSiteId ? site.siteName : null
                        )
                      : null
                  )}
                  {siblingDepartments.map(dept =>
                    dept.id === subbedAction.subbedDeptId
                      ? ' ' + dept.deptName
                      : null
                  )}
                  <span>
                    {providerAssignmentUsers
                      ? providerAssignmentUsers.allProviders?.map(
                          (provider: IProvider) =>
                            provider.id === subbedAction.providerId
                              ? provider.fullName
                              : null
                        )
                      : null}
                  </span>
                </TableCell>
                <TableCell padding="none" align="center">
                  <div className="p-1">
                    <img
                      src={subbedAction.actionImageUrl}
                      style={{ width: 30, paddingTop: 5 }}
                      alt=""
                    />
                  </div>
                  {/* {subbedAction.actionName} */}
                </TableCell>
                <TableCell padding="none" align="center">
                  <Tooltip
                    // @ts-ignore
                    disableHoverListener={
                      loggedInUserData.phoneNumber &&
                      loggedInUserData.isPhoneNumberVerified
                        ? true
                        : false
                    }
                    title="A phone number must be added and validated."
                    placement="right"
                  >
                    <div>
                      <Checkbox
                        className={classes.checkbox}
                        // disabled={!this.props.loggedInUserData.phoneNumber || !this.props.loggedInUserData.isPhoneNumberVerified}
                        disabled={
                          userToEdit
                            ? !loggedInUserData.isPhoneNumberVerified ||
                              loggedInUserData.phoneNumber === null
                            : !loggedInUserData.phoneNumber ||
                              !loggedInUserData.isPhoneNumberVerified
                        }
                        // disabled={validPhoneNumber === false || this.state.userToEdit.phoneNumber.length <= 1}
                        onClick={() =>
                          onHandleAlertCheck(
                            'SMS',
                            subbedAction,
                            newSubscribedActions
                          )
                        }
                        color="primary"
                        checked={subbedAction.messageType.includes('SMS')}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell padding="none" align="center">
                  <Tooltip
                    // @ts-ignore
                    disableHoverListener={
                      loggedInUserData.phoneNumber &&
                      loggedInUserData.isPhoneNumberVerified
                        ? true
                        : false
                    }
                    title="A phone number must be added and validated."
                    placement="right"
                  >
                    <div>
                      <Checkbox
                        className={classes.checkbox}
                        disabled={
                          userToEdit
                            ? !loggedInUserData.isPhoneNumberVerified ||
                              loggedInUserData.phoneNumber === null
                            : !loggedInUserData.phoneNumber ||
                              !loggedInUserData.isPhoneNumberVerified
                        }
                        onClick={() =>
                          onHandleAlertCheck(
                            'Call',
                            subbedAction,
                            newSubscribedActions
                          )
                        }
                        color="primary"
                        checked={subbedAction.messageType.includes('Call')}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell padding="none" align="center">
                  <Checkbox
                    className={classes.checkbox}
                    onClick={() =>
                      onHandleAlertCheck(
                        'Desktop',
                        subbedAction,
                        newSubscribedActions
                      )
                    }
                    color="primary"
                    checked={subbedAction.messageType.includes('Desktop')}
                  />
                </TableCell>
                <TableCell padding="none" align="center">
                  <Tooltip
                    disableHoverListener={
                      loggedInUserData.fcmDeviceTokens.length > 0 ? true : false
                    }
                    title="You must have the SyncTimes mobile app installed on your phone and logged in for mobile alerts"
                    placement="right"
                  >
                    <div>
                      <Checkbox
                        // @ts-ignore
                        disabled={
                          !(loggedInUserData.fcmDeviceTokens.length > 0)
                        }
                        className={classes.checkbox}
                        onClick={() =>
                          onHandleAlertCheck(
                            'Mobile',
                            subbedAction,
                            newSubscribedActions
                          )
                        }
                        color="primary"
                        checked={subbedAction.messageType.includes('Mobile')}
                      />
                    </div>
                  </Tooltip>
                </TableCell>

                {_.some(integrations, {
                  integrationName: 'Microsoft Teams',
                }) ? (
                  <TableCell padding="none" align="center">
                    <Tooltip
                      disableHoverListener={
                        loggedInUserData.fcmDeviceTokens.length > 0
                          ? true
                          : false
                      }
                      title="You must have the SyncTimes mobile app installed on your phone and logged in for mobile alerts"
                      placement="right"
                    >
                      <div>
                        <Checkbox
                          disabled={loggedInUserData.hasTeams ? false : true}
                          className={classes.checkbox}
                          onClick={() =>
                            onHandleAlertCheck(
                              'TeamsChat',
                              subbedAction,
                              newSubscribedActions
                            )
                          }
                          color="primary"
                          checked={subbedAction.messageType.includes(
                            'TeamsChat'
                          )}
                        />
                      </div>
                    </Tooltip>
                  </TableCell>
                ) : null}

                <TableCell padding="none" align="center">
                  <strong
                    // @ts-ignore
                    style={{
                      float: 'right',
                      color: 'red',
                      cursor: 'pointer',
                      fontSize: 17,
                    }}
                    onClick={() => onDeleteSubbedAction(subbedAction)}
                  >
                    X
                  </strong>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        size="small"
        variant="contained"
        className={classes.reportingButton}
        onClick={() => onNewAlertModal()}
      >
        New Notification
      </Button>

      {phoneNumberError && (
        <ErrorSnack errorSnack={true} errorMessage="Invalid phone format" />
      )}

      {validatedPhoneSuccess && (
        <SuccessSnack
          successSnack={true}
          successMessage="Phone successfully validated!"
        />
      )}
      {sentTestNotificationSnack && (
        <SuccessSnack
          successSnack={true}
          successMessage="Test notification sent!"
        />
      )}
    </div>
  );
};

export default Notifications;

// const mapStateToProps = (state: any) => ({
//   //   loggedInUserData: state.adminData.loggedInUserData,
//   allActions: state.adminData.allActions,
//   sites: state.adminData.sites,
//   distinctOrgActions: state.adminData.distinctOrgActions,
//   providerAssignmentUsers: state.groupData.providerAssignmentUsers,
//   organization: state.adminData.organization,
// });

// export default connect(mapStateToProps, {
//   fetchLoggedInUser,
//   fetchProviderAssignmentUsers,
//   fetchAllActions,
//   fetchSites,
//   fetchDistinctOrgActions,
// })(withStyles(styles)(Notifications));
