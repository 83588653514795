import { useState, useCallback } from "react";
import axios from "axios";
import { API } from "../apiconfig";
import { IOrganization } from "../interfaces/interfaces";
import { checkRefreshToken } from "../utils/utils";

interface UseOrganizationSubmissionResult {
  isLoading: boolean;
  error: string | null;
  fieldError: boolean;
  submitOrganization: (organization: IOrganization, isNew: boolean) => Promise<IOrganization>;
}

export const useOrganization = (): UseOrganizationSubmissionResult => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [fieldError, setFieldError] = useState<boolean>(false);

  const submitOrganization = useCallback((organization: IOrganization, isNew: boolean): Promise<IOrganization> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);
      setFieldError(false);

      if (organization.orgName.length < 1) {
        setFieldError(true);
        setIsLoading(false);
        reject(new Error("Organization name is required"));
        return;
      }

      const token = localStorage.getItem("token");
      const method = isNew ? "POST" : "PUT";

      axios({
        method,
        url: API.REACT_APP_API_ORGANIZATIONS,
        data: organization,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(({ data }) => {
          setIsLoading(false);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error:", err);
          setError("An error occurred while submitting the organization. Please try again.");
          setIsLoading(false);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
          reject(err);
        });
    });
  }, []);

  return {
    isLoading,
    error,
    fieldError,
    submitOrganization,
  };
};
