import { isEmpty } from 'lodash';
import { z } from 'zod';

const LobbySequenceLanguage = z.enum(['English', 'Spanish']);

export const schema = z
  .object({
    lobbySequences: z.array(
      z.object({
        patientEducationToShow: z.string(),
        patientEducationTime: z.number().nullish(),
        language: LobbySequenceLanguage.nullish(),
      })
    ),

    newPatientEducationToShow: z.string().min(1, 'Required'),
    newPatientEducationToShowOption: z
      .object({
        value: z.string().nullish(),
        label: z.string().nullish(),
        patientContent: z
          .object({
            id: z.string().nullish(),
            patientContentName: z.string().nullish(),
            patientEducationUrl: z.string().nullish(),
          })
          .nullish(),
      })
      .nullish(),
    newPatientEducationTime: z.number().min(1, 'Required').nullish(),
  })
  .refine(
    data => {
      console.log({ data });

      const isVideo =
        data.newPatientEducationToShowOption?.patientContent?.patientEducationUrl?.includes(
          '.mp4'
        );

      if (isVideo) {
        return true;
      }

      console.log({ data, isVideo, isEmpty: isEmpty(data.newPatientEducationTime), });
      if (!data.newPatientEducationTime) {
        return false;
      }
      console.log({
        data,
        isVideo,
        isEmpty: isEmpty(data.newPatientEducationTime),
      });

      return true;
    },
    {
      message: 'Required',
      path: ['newPatientEducationTime'],
    }
  );
