import { Step } from "react-joyride";

export const JOYRIDE_STEPS = [
  {
    disableBeacon: true,
    target: ".first-step",
    content: "Welcome to the Admin Console! This is the place where you can manage your Departments, Users, and Equipment.",
    placement: "center",
    // target: "body",
    // placement: "top",
  },
  {
    target: ".second-step",
    content: "This is where you can add/manage Users within your organization.",
  },
  {
    target: ".third-step",
    content: "To make changes to any existing user, just select them here.",
  },
  {
    target: ".fourth-step",
    content: "To create a new user, click here.",
  },
  {
    target: ".fifth-step",
    content: "To view your organizations equipment, click here.",
  },
  {
    target: ".sixth-step",
    content: "Similar to users, to modify any already created equipment, select them here.",
  },
  {
    target: ".seventh-step",
    content: "To add new equipment, click here.",
  },
] as Array<Step>