import { zodResolver } from '@hookform/resolvers/zod';
import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { IAction } from '../../../../../interfaces/interfaces';
import { STEPS } from './constants';
import NotificationNewSelectTypeSubform from './selectNotificationType/NotificationNewSelectTypeSubform';
import NotificationNewSelectNotificationsSubform from './selectNotifications/NotificationNewSelectNotificationsSubform';

export const schema = z.object({
  orgId: z.string(),
  activeStep: z.number().default(0),
  selectedSite: z
    .object({
      id: z.string(),
      siteName: z.string(),
    })
    .default(null)
    .nullish(),
  selectedDepartments: z
    .array(
      z.object({
        id: z.string(),
        deptName: z.string(),
      })
    )
    .default([])
    .nullish(),
  selectedProviders: z
    .array(
      z.object({
        id: z.string(),
        fullName: z.string(),
      })
    )
    .default([])
    .nullish(),
  selectedActions: z
    .array(
      z.object({
        id: z.string(),
        actionName: z.string(),
      })
    )
    .default([]),
  notificationFor: z.enum(['provider', 'department']).default('provider'),
});
export type TFormSchema = z.infer<typeof schema>;

type TProps = {
  initialValues?: Partial<TFormSchema>;
  onSubmit: (values: TFormSchema) => Promise<void>;
  distinctOrgActions: IAction[];
  submitting: boolean;
};

const NotificationNewForm: FC<TProps> = props => {
  const {
    initialValues,
    onSubmit,
    distinctOrgActions = [],
    submitting = false,
  } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<TFormSchema>({
    resolver: zodResolver(schema),
    defaultValues: initialValues,
  });

  const activeStep = watch('activeStep');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {STEPS.map(step => {
          return (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
              <StepContent>
                {activeStep === 0 && (
                  <div>
                    <NotificationNewSelectTypeSubform
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      errors={errors}
                    />
                  </div>
                )}

                {activeStep === 1 && (
                  <div>
                    <NotificationNewSelectNotificationsSubform
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      errors={errors}
                      distinctOrgActions={distinctOrgActions}
                      submitting={submitting}
                    />
                  </div>
                )}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </form>
  );
};

export default NotificationNewForm;
