import axios from 'axios';

import { API } from '../../apiconfig';

export const processLogin = (input: {
  username: string;
  password: string;
  onSuccess: ({
    token,
    refresh_token,
  }: {
    token: string;
    refresh_token: string;
  }) => void;
  onFail: (error: any) => void;
}) => {
  const { username, password, onSuccess = () => {}, onFail = () => {} } = input;

  let reqData: {
    grant_type: string;
    username: string;
    password: string;
  } = {
    grant_type: 'password',
    username: username,
    password: password,
  };

  console.log('processLogin', { reqData });

  const loginData = Object.entries(reqData)
    .map(([key, value]) => {
      const loginString = `${encodeURIComponent(key)}=${encodeURIComponent(
        value
      )}`;

      console.log({ loginString });
      return loginString;
    })
    .join('&');

  console.log('processLogin', { loginData });

  axios({
    method: 'post',
    url: API.REACT_APP_API_LOGIN,
    data: loginData,
  })
    .then(response => {
      console.log({ response });
      return onSuccessResponse({ response, onSuccess });
    })
    .catch(err => {
      console.log(err);
      onFail(err);
    });
};

export const processLoginByToken = (input: {
  flowStationToken: string;
  onSuccess: ({
    token,
    refresh_token,
  }: {
    token: string;
    refresh_token: string;
  }) => void;
  onFail: (error: any) => void;
}) => {
  const { flowStationToken, onSuccess = () => {}, onFail = () => {} } = input;
  let reqData: {
    flowStationToken: string;
  } = {
    flowStationToken,
  };

  const loginData = Object.entries(reqData)
    .map(([key, value]) => {
      const loginString = `${encodeURIComponent(key)}=${encodeURIComponent(
        value
      )}`;

      console.log({ loginString });
      return loginString;
    })
    .join('&');

  console.log('processLoginByToken', { loginData });

  // const loginString = queryString.stringify(reqData);

  axios({
    method: 'post',
    url: API.REACT_APP_API_LOGIN_BY_FLOWSTATION_TOKEN,
    data: {
      ...reqData
    }
    // data: {
    //   token: flowStationToken,
    // },
  })
    .then(response => {
      console.log('processLoginByToken', { response });

      return onSuccessResponse({ response, onSuccess });
    })
    .catch(err => {
      console.log(err);
      onFail(err);
    });
};

const onSuccessResponse = (input: {
  response: any;
  onSuccess: (tokens: { token: string; refresh_token: string }) => any;
}) => {
  const { response, onSuccess = () => {} } = input;

  if (response.data.access_token && response.data.refresh_token) {
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('token', response.data.access_token);
    localStorage.setItem('email', response.data.user_email);
    localStorage.setItem("token_expire", response.data.utc_expiry)


    onSuccess({
      token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    });
  }
};
