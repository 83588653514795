import { Button, CircularProgress } from '@material-ui/core';
import { difference, isEmpty, uniqBy } from 'lodash';
import { FC } from 'react';
import { IAction } from '../../../../../../interfaces/interfaces';
import { useStyles } from '../../styles';

type TProps = {
  watch: any;
  setValue: any;
  control: any;
  errors: any;
  distinctOrgActions: IAction[];
  submitting: boolean;
};

const NotificationNewSelectNotificationsSubform: FC<TProps> = props => {
  const {
    watch,
    setValue,
    distinctOrgActions = [],
    submitting = false,
  } = props;

  const selectedDeptForSMS = {} as any;
  const classes = useStyles();
  const selectedActions = (watch('selectedActions') ?? []) as IAction[];

  const selectedActionsIds = selectedActions.map(action => action.id);

  const onBack = () => {
    setValue('activeStep', 0);
  };

  const onSelectAction = (action: IAction) => {
    const index = selectedActionsIds.indexOf(action.id);

    console.log('onSelectAction', { index });

    if (index === -1) {
      setValue('selectedActions', [...selectedActions, action]);
    } else {
      setValue(
        'selectedActions',
        selectedActions.filter((a: IAction) => a.id !== action.id)
      );
    }
  };

  // console.log({
  //   allActions,
  //   subscribedActions: loggedInUserData.subscribedActions,
  //   loggedInUserData,
  // });
  let newSubscribedActions: any[] = [];
  // // console.log('all', this.props.allActions)
  // // console.log('subscribed', this.props.loggedInUserData.subscribedActions)
  // loggedInUserData.subscribedActions.forEach((subscribedAction: any) => {
  //   allActions.forEach((action: any) => {
  //     if (subscribedAction.actionId === action.id) {
  //       // console.log("SUBSCRIBED ACTION", subscribedAction);
  //       let actionData = {
  //         ...action,
  //         subbedDeptId: subscribedAction.deptId,
  //         messageType: subscribedAction.messageType,
  //         providerId: subscribedAction.providerId,
  //       };
  //       newSubscribedActions.push(actionData);
  //     } else {
  //       // console.log('missing', action)
  //     }
  //     // newSubscribedActions.push(action)
  //   });
  // });

  // console.log("All subscribed actions", newSubscribedActions);
  let uniqActions = uniqBy(newSubscribedActions, (v: any) =>
    [v.id, v.providerId, v.subbedDeptId].join()
  );

  let differenceActions = difference(newSubscribedActions, uniqActions);

  uniqActions.forEach((uniqAction: any, index: any) => {
    differenceActions.forEach((diffAction: any) => {
      if (
        uniqAction.actionName === diffAction.actionName &&
        uniqAction.providerId === diffAction.providerId &&
        uniqAction.subbedDeptId === diffAction.subbedDeptId
      ) {
        // console.log("GOT IT", diffAction);
        uniqActions[index].messageType =
          uniqActions[index].messageType + ' ' + diffAction.messageType;
      }
    });
  });

  newSubscribedActions = uniqActions;

  return (
    <div>
      <div
        className={classes.actionPopover}
        style={{
          maxHeight: '50vh',
          overflow: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {!isEmpty(selectedDeptForSMS)
          ? selectedDeptForSMS.actionsList.map((action: IAction) => (
              <div key={action.id}>
                {action.id !== '5f6fb35be0a5f22bf0d6a122' ? (
                  <img
                    className={
                      selectedActions.indexOf(action) !== -1
                        ? classes.selectedPopOverActions
                        : classes.popOverActions
                    }
                    src={action.actionImageUrl}
                    onClick={() => onSelectAction(action)}
                    alt=""
                  />
                ) : null}
              </div>
            ))
          : distinctOrgActions.map((action: IAction) => {
              const isActionSelected =
                selectedActionsIds.indexOf(action.id) !== -1;

              console.log({ isActionSelected });

              return (
                <div key={action.id}>
                  <img
                    className={
                      isActionSelected
                        ? classes.selectedPopOverActions
                        : classes.popOverActions
                    }
                    src={action.actionImageUrl}
                    onClick={() => onSelectAction(action)}
                    alt=""
                  />
                </div>
              );
            })}
        {/* ))} */}
      </div>

      <div>
        <Button
          variant="contained"
          onClick={onBack}
          style={{ marginRight: 20 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={submitting || selectedActions.length <= 0}
          startIcon={submitting && <CircularProgress size={`1rem`} />}
        >
          {submitting ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default NotificationNewSelectNotificationsSubform;
