export const USERS_TUTORIAL_STEPS = [
  {
    disableBeacon: true,
    target: ".users-first-step",
    content: "This is where you can add/manage Users within your organization.",
  },
  {
    target: ".users-second-step",
    content: "To create a new user, click here.",
  },
]

export const userTemplate: any = {
  firstName: "",
  lastName: "",
  userEmail: "",
  userTypeId: 1,
  isActive: true,
  userPermissions: [],
  userImageUrl: "",
  appUserBadges: [],
  fieldError: false,
  newUserErrorMessage: "",
  isViewOnlyFlowstation: false,
  ehrProviderIds: [],
}