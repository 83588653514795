import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-google-charts";

interface ConnectivityChartProps {
  devices: any;
}

interface ConnectivityChartState {}

const styles: {} = (theme: any) => ({});

class ConnectivityChart extends Component<ConnectivityChartProps, ConnectivityChartState> {
  constructor(props: ConnectivityChartProps) {
    super(props);
    this.state = {};
  }

  render() {
    let connectedDevices = 0;
    let disconnectedDevices = 0;
    let loaded = false;

    this.props.devices.forEach((device: any) => {
      if (device.isConnectedToHub) {
        connectedDevices++;
      } else {
        disconnectedDevices++;
      }
    });

    if (this.props.devices.length > 0) {
      loaded = true;
    }

    // testData.forEach((device: any) => {
    //   if (device.isConnectedToHub) {
    //     connectedDevices++;
    //   } else {
    //     disconnectedDevices++;
    //   }
    // });

    const pieOptions = {
      title: "Device Status",
      pieHole: 0.6,
      pieSliceText: "value",
      slices: [
        {
          color: "#4FAC48",
        },
        {
          color: "#FF0000",
        },
      ],
      legend: "none",
      //   legend: {
      //     // position: "none",
      //     position: "bottom",
      //     alignment: "center",
      //     textStyle: {
      //       color: "233238",
      //       fontSize: 14,
      //     },
      //   },
      tooltip: {
        showColorCode: true,
      },
      chartArea: {
        left: 0,
        top: 10,
        width: "100%",
        height: "85%",
      },
      fontName: "Roboto",
    };

    return (
      <div style={{ marginTop: 15, width: 200 }}>
        <div style={{ textAlign: "center", display: "flex", alignItems: "baseline", justifyContent: "center" }}>
          <div style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: "red", marginRight: 10 }} />
          Disconnected: {disconnectedDevices}
        </div>
        {loaded ? (
          <Chart
            chartType="PieChart"
            options={pieOptions}
            width="200px"
            height="200px"
            data={[
              ["Connected", "Disconnected"],
              ["Connected", connectedDevices],
              ["Disconnected", disconnectedDevices],
            ]}
            // chartEvents={[
            //   {
            //     eventName: "select",
            //     callback: ({ chartWrapper, google }) => {
            //       let selection = chartWrapper.getChart().getSelection();
            //       console.log(selection);
            //       if (selection?.length > 0) {
            //         console.log("selection", selection);
            //         console.log("google?", google);
            //         if (selection[0].row === 0) {
            //           console.log("Show connected");
            //         }
            //         if (selection[0].row === 1) {
            //           console.log("Show disconnected");
            //         }
            //       }
            //     },
            //   },
            // ]}
          />
        ) : (
          <div>Loading...</div>
        )}

        <div style={{ textAlign: "center", display: "flex", alignItems: "baseline", justifyContent: "center" }}>
          <div style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: "#4FAC48", marginRight: 10 }} />
          Connected: {connectedDevices}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ConnectivityChart);
