import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IDeviceLocation } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useDeviceLocations = (
  options?: {
    shouldFetch?: boolean;
  }
): {
  deviceLocations: IDeviceLocation[] | null;
  loading: boolean;
  error: string | null;
  refetch: (deviceId: string) => Promise<IDeviceLocation[]>;
} => {
  const [deviceLocations, setDeviceLocations] = useState<
    IDeviceLocation[] | []
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const shouldFetch = options?.shouldFetch ?? true;

  const fetchDeviceLocations = useCallback(
    (): Promise<IDeviceLocation[]> => {
      return new Promise((resolve, reject) => {
        setLoading(true);
        setError(null);

        const token = localStorage.getItem('token');

        let URL = API.REACT_APP_API_GETDEVICELOCATIONS;

        let headers = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Org-UID': 'UID1',
            Pragma: 'no-cache',
          },
        };

        axios
          .get(URL, headers)
          .then(response => {
            setLoading(false);

            const deviceLocations: IDeviceLocation[] =
              response?.data?.data ?? [];
            setDeviceLocations(deviceLocations);

            resolve(deviceLocations);
          })
          .catch(err => {
            handleError(err);
            setLoading(false);
            reject(err);
          });
      });
    },
    []
  );

  // const takeScreenshot = useCallback((deviceId: string): Promise<IDeviceLocation> => {

  // }, []);

  useEffect(() => {
    if (shouldFetch) {
      fetchDeviceLocations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (err: any) => {
    console.error('Error:', err);
    setError(
      'An error occurred while fetching device locations. Please try again.'
    );
    setLoading(false);
    if (err.response?.status === 401) {
      checkRefreshToken();
    }
  };

  return {
    refetch: fetchDeviceLocations,
    deviceLocations,
    loading,
    error,
  };
};
