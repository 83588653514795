export const STEPS = [
  {
    disableBeacon: true,
    target: '.equipment-first-step',
    content:
      'Similar to users, to modify any already created equipment, select them here.',
    // placement: "center",
    // target: "body",
    // placement: "top",
  },
  {
    target: '.equipment-second-step',
    content: 'To add new equipment, click here.',
  },
]


export const EQUIPMENT_TEMPLATE: any = {
  firstName: '',
  lastName: '',
  userTypeId: 1,
  isActive: true,
  isAdmin: false,
  userImageUrl: '',
  appUserBadges: [],
  organizationId: null,
  fieldError: false,
  newInstrumentErrorMessage: false,
  npi: null,
}