import { ChangeEvent, FC, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { checkRefreshToken } from '../../../../utils/utils';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseButton from '@material-ui/icons/Clear';

import { API } from '../../../../apiconfig';

import { useFetchAllActions } from '../../../../hooks/useFetchAllActions';
import { IAction } from '../../../../interfaces/interfaces';
import { useStyles } from './styles';

type TProps = {};
const Actions: FC<TProps> = () => {
  const [selectedFileDetails, setSelectedFileDetails] = useState(null);
  const [actionName, setActionName] = useState('');
  const [fileHasBeenSelected, setFileHasBeenSelected] = useState(false);
  const [right, setRight] = useState(false);
  const [actionToEdit, setActionToEdit] = useState(null);
  const [hideInactiveActions, setHideInactiveActions] = useState(true);
  const [croppedImage, setCroppedImage] = useState(null);
  const [searchActionValue, setSearchActionValue] = useState('');
  // const [iconSearchQuery, setIconSearchQuery] = useState('');

  const { actions: allActions = [], refetch: refetchAllActions } =
    useFetchAllActions();

  const cropperRef = useRef<ReactCropperElement>(null);

  const classes = useStyles();

  const onHandleChange = (input: { name: string; value: string }) => {
    const { name, value } = input;

    // console.log("Action to edit", actionToEdit);

    setActionToEdit({
      ...actionToEdit,
      [name]: value,
    });

    // this.setState((prevState) => ({
    //   actionToEdit: {
    //     ...prevState.actionToEdit,
    //     [name]: newValue,
    //   },
    // }));
  };

  const onFileSelectedHandler = (event: any) => {
    if (event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        var img = new Image();
        img.src = event.target.result;

        let imageDetails = {
          actionImage: event.target.result.replace(
            /data:image\/[a-zA-Z]+;base64,/g,
            ''
          ),
          previewImage: reader.result,
        };

        setSelectedFileDetails(imageDetails);
        setFileHasBeenSelected(true);
      };
    }
  };

  const onToggleDrawer = (open: boolean, action: IAction | null) => {
    console.log('toggle drawer');
    setRight(open);
    setActionToEdit(action);
  };

  const onUpdateExistingAction = () => {
    console.log('CLICKED');
    let token = localStorage.getItem('token');

    if (actionToEdit.actionName.length === 0) {
    } else {
      let modifiedAction = {
        ...actionToEdit,
      };
      console.log('Sending', modifiedAction);

      fetch(API.REACT_APP_API_ACTION, {
        method: 'PUT',
        body: JSON.stringify(modifiedAction),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          console.log('Done', data);

          refetchAllActions();

          setRight(false);
        })
        .catch(err => {
          console.log('Error:', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const onSubmitAction = () => {
    console.log('Submit file!');
    let token = localStorage.getItem('token');

    let payload = {
      actionName: actionName,
      // actionImage: selectedFileDetails.actionImage,
      actionImage: croppedImage,
    };

    console.log('send this', payload);

    fetch(API.REACT_APP_API_CREATEACTION, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        console.log('res', res);
        refetchAllActions();

        setSelectedFileDetails(null);
        setActionName('');
        setFileHasBeenSelected(false);
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onHandleNewActionNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setActionName(event.target.value);
  };

  const onCrop = () => {
    // croppedImage

    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    setCroppedImage(
      cropper
        .getCroppedCanvas()
        .toDataURL()
        .replace(/data:image\/[a-zA-Z]+;base64,/g, '')
    );
  };

  const onHandleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchActionValue(event.target.value);
  };

  // const onSearchActions = (e: any) => {
  //   setIconSearchQuery(e.target.value);
  // };

  // const onSearchActionsButton = () => {
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       Authorization:
  //         'Bearer X0vjEUN6KRlxbp2DoUkyHeM0VOmxY91rA6BbU5j3Xu6wDodwS0McmilLPBWDUcJ1',
  //     },
  //   };

  //   fetch(
  //     'https://api.iconfinder.com/v4/icons/search?query=' +
  //       iconSearchQuery +
  //       '&count=100',
  //     options
  //   )
  //     .then(response => response.json())
  //     .then(response => console.log('iconfinder res', response))
  //     .catch(err => console.error(err));
  // };

  // selectIcon = (icon: any) => {
  //   console.log("selected", icon);

  // };

  // console.log("icon serach data", iconSearchData);

  let filteredActions = allActions.filter(action => {
    return (
      action.actionName
        .toLowerCase()
        .indexOf(searchActionValue.toLowerCase()) !== -1
    );
  });

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Actions{' '}
            <a
              href="https://www.iconfinder.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              (Find more actions here)
            </a>
          </Typography>
          <TextField
            label="Search"
            className={classes.textField}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onHandleSearch(event)
            }
          />
          <br />
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={!hideInactiveActions}
            onClick={() => setHideInactiveActions(!hideInactiveActions)}
          />
          Show Inactive Actions
          <div className={classes.flexContainer}>
            {filteredActions.map((action: IAction) => (
              <div key={action.id}>
                {action.isActive === hideInactiveActions ? (
                  <Tooltip title={action.actionName} placement="top">
                    <img
                      className={classes.actionImage}
                      style={{ cursor: 'pointer' }}
                      src={action.actionImageUrl}
                      alt="#"
                      onClick={() => onToggleDrawer(true, action)}
                    />
                  </Tooltip>
                ) : null}
              </div>
            ))}
          </div>
          <br />
          <Typography color="textSecondary" gutterBottom>
            New Action
          </Typography>
          {selectedFileDetails ? (
            <Cropper
              ref={cropperRef}
              className="test"
              src={selectedFileDetails.previewImage}
              style={{ height: 200, width: '30%' }}
              checkCrossOrigin={false}
              aspectRatio={1}
              autoCropArea={1}
              guides={true}
              crop={() => onCrop()}
            />
          ) : null}
          <div style={{ color: 'red', fontSize: 12 }}>
            NOTE: The cropper will not actually make the image square.
          </div>
          <div style={{ color: '#757575', fontSize: 12 }}>
            (The name will be used in SMS)
          </div>
          <div style={{ display: 'flex' }}>
            <label htmlFor="upload-action-file">
              {selectedFileDetails ? null : (
                // <div>
                //   <img className={classes.actionImage} style={{ cursor: "pointer" }} src={selectedFileDetails.previewImage} alt="#" />
                // </div>
                <div
                  className={classes.addIconSquare}
                  // htmlFor="upload-action-file"
                >
                  <AddIcon style={{ fontSize: 35, paddingTop: 12 }} />
                </div>
              )}
            </label>

            <input
              id="upload-action-file"
              style={{ display: 'none' }}
              type="file"
              onChange={onFileSelectedHandler}
            />

            <TextField
              id="standard-name"
              label="Name"
              style={{ marginTop: 0, marginRight: 10 }}
              className={classes.textField}
              value={actionName}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onHandleNewActionNameChange(event)
              }
              margin="normal"
            />
            <br />
          </div>
          <Button
            size="small"
            disabled={actionName.length === 0 || fileHasBeenSelected === false}
            color="primary"
            variant="contained"
            onClick={() => onSubmitAction()}
          >
            Submit
          </Button>
        </CardContent>
      </Card>
      <Drawer
        anchor="right"
        open={right}
        onClose={() => onToggleDrawer(false, null)}
      >
        {actionToEdit ? (
          <div className={classes.drawer}>
            <div
              className={classes.closeButton}
              onClick={() => onToggleDrawer(false, null)}
            >
              <CloseButton />
            </div>
            <h2>Editing {actionToEdit.actionName}</h2>
            <br />
            <TextField
              required
              label="Action Name"
              className={classes.textField}
              value={actionToEdit.actionName}
              onChange={event =>
                onHandleChange({
                  name: 'actionName',
                  value: event.target.value,
                })
              }
              margin="normal"
            />
            <br />
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={actionToEdit.isActive}
              onClick={() =>
                setActionToEdit({
                  ...actionToEdit,
                  isActive: !actionToEdit.isActive,
                })
              }
            />
            Is Active
            <br />
            <TextField
              required
              variant="outlined"
              label="Action Description"
              className={classes.textField}
              value={actionToEdit.actionDescription}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onHandleChange({
                  name: 'actionDescription',
                  value: event.target.value,
                })
              }
              margin="normal"
              multiline
              rows={4}
            />
            <br />
            {/* {hasModified ? <div className={classes.saveWarning}>Unsaved Changes!</div> : <div />} */}
            <Button
              className={classes.submitButton}
              onClick={onUpdateExistingAction}
              color="primary"
            >
              Save
            </Button>
          </div>
        ) : null}
      </Drawer>
    </div>
  );
};

export default Actions;
