import { createContext, useContext } from 'react';

import { IOrganization } from '../../interfaces/interfaces';

type TProps = {
  organization: IOrganization;
};

export const LobbyContext = createContext<TProps>({
  organization: null,
});

export const LobbyProvider = LobbyContext.Provider;
export const LobbyConsumer = LobbyContext.Consumer;

export default LobbyContext;

export const useLobbyContext = () => {
  const context = useContext(LobbyContext);

  return context;
};
