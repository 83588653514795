import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { API } from '../apiconfig';
import { ICampus } from '../interfaces/interfaces';

export const useCampuses = (orgId: string) => {
  const [campuses, setCampuses] = useState<ICampus[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCampuses = useCallback(() => {
    if (!orgId) return Promise.resolve();

    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    const URL = API.REACT_APP_API_CAMPUSES + orgId;
    const headers = {
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache',
    };

    return axios
      .get(URL, { headers })
      .then(response => {
        setCampuses(response.data.data ?? []);
      })
      .catch(err => {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          console.log('UNAUTHORIZED, KICK EM OUT');
          setError('Unauthorized. Please log in again.');
        } else {
          console.error('Error fetching campuses:', err);
          setError('Failed to fetch campuses. Please try again.');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [orgId]);

  useEffect(() => {
    fetchCampuses();
  }, [fetchCampuses]);

  return { campuses, isLoading, error, refreshCampuses: fetchCampuses };
};
