import {
  Button,
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FC, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { remove } from 'lodash';
import { useDepartment } from '../../../../../../hooks/department/useDepartment';
import { IAction, IDepartment } from '../../../../../../interfaces/interfaces';
import { DeleteButton } from '../../DeleteButton';

type TProps = {
  department: IDepartment;
  classes: any;
};

const DepartmentScheduledActions: FC<TProps> = props => {
  const { department, classes } = props;

  const { update } = useDepartment({
    departmentId: department.id,
  });
  const [minutesToWait, setMinutesToWait] = useState<number>(15);
  const [isFlashing, setIsFlashing] = useState<boolean>(false);
  const [scheduledTouchStatus, setScheduledTouchStatus] = useState<number>(1);

  const [newScheduledActionError, setNewScheduledActionError] =
    useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  const [selectedScheduleAction, selectScheduleAction] =
    useState<IAction | null>(null);

  const handleNewScheduledMinutesToWait = (event: any) => {
    setMinutesToWait(event.target.value);
  };

  const handleScheduledTouchStatus = (event: any) => {
    // console.log("Assign", event.target.value);
    setScheduledTouchStatus(event.target.value);
  };

  const closeSchedulePopper = () => {
    setAnchorEl(null);
  };

  const assignSelectedActionIcon = (action: IAction) => {
    closeSchedulePopper();
    selectScheduleAction(action);
    setNewScheduledActionError(false);

    // console.log("Assigning selecting action", action);
  };

  const deleteScheduledAction = (actionId: string) => {
    // console.log("Delete scheduled action", actionId);
    let scheduledActionsToModify = department?.scheduledActions ?? [];

    // if (scheduledActionsToModify) {
    remove(
      scheduledActionsToModify,
      (action: IAction) => action.id === actionId
    );

    let modifiedDepartment = {
      ...department,
      scheduledActions: scheduledActionsToModify,
    };
    // console.log("modfied dept", modifiedDepartment);

    update({
      departmentId: department.id,
      data: modifiedDepartment,
    });
    // }
  };

  const addScheduledAction = () => {
    if (selectedScheduleAction) {
      let scheduledActionsToModify: IAction[] = [];
      if (department.scheduledActions) {
        scheduledActionsToModify = department.scheduledActions;
      }
      // console.log("Department To Edit", selectedDepartment);
      // console.log("Dept Props", department);

      let statusName = '';

      switch (scheduledTouchStatus) {
        case 1:
          statusName = 'Alone';
          break;
        case 2:
          statusName = 'Provider';
          break;
        case 3:
          statusName = 'Staff';
          break;
        case 4:
          statusName = 'Hold';
          break;
        default:
          statusName = 'Empty';
      }

      let newScheduledAction: any = {
        mongoActionId: selectedScheduleAction.id,
        isFlashing: isFlashing,
        // minutesToWait: parseInt(minutesToWait),
        minutesToWait: minutesToWait,
        touchStatus: statusName,
        touchStatusId: scheduledTouchStatus,
      };

      scheduledActionsToModify.push(newScheduledAction);

      let modifiedDepartment = {
        ...department,
        scheduledActions: scheduledActionsToModify,
      };

      return update({
        departmentId: department.id,
        data: modifiedDepartment,
      }).then(() => {
        setMinutesToWait(15);
        setIsFlashing(false);
        setAnchorEl(null);
        selectScheduleAction(null);
        setScheduledTouchStatus(1);
      });
    } else {
      console.log('setting err');
      setNewScheduledActionError(true);
    }
  };

  const handleSchedulePopper = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      {department?.scheduledActions ? (
        <div className={classes.scheduledActionsContainer}>
          {department.scheduledActions.map((action: IAction, index: number) => (
            <div key={index} className={classes.scheduledActionsContainer}>
              <Grid container>
                <Grid item xs={3}>
                  {department.actionsList.map(
                    (imageAction: IAction, imageIndex: number) => (
                      <div key={imageIndex}>
                        {action.mongoActionId === imageAction.id ? (
                          <img
                            className={classes.scheduledActionImage}
                            src={imageAction.actionImageUrl}
                            alt="#"
                          />
                        ) : (
                          <div />
                        )}
                      </div>
                    )
                  )}
                </Grid>
                <Grid item xs={9}>
                  <div>
                    <strong>Minutes to Wait: </strong>
                    {action.minutesToWait}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <strong>Is Flashing: </strong>
                    {action.isFlashing ? <div>True</div> : <div>False</div>}
                  </div>
                  <div>
                    <strong>Touch Status: </strong>
                    {action.touchStatus}
                  </div>
                </Grid>
              </Grid>
              <div>
                <DeleteButton onClick={() => deleteScheduledAction(action.id)}>
                  Delete Scheduled Action
                </DeleteButton>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div />
      )}
      <br />

      <div>
        <Typography variant="h5">New Scheduled Action</Typography>

        <div className={classes.imageSelectContainer}>
          <br />
          <Tooltip title="Choose an icon to schedule" placement="right">
            <div>
              {selectedScheduleAction ? (
                <img
                  onClick={handleSchedulePopper}
                  src={selectedScheduleAction?.actionImageUrl}
                  className={classes.selectedScheduledActionImage}
                  alt="#"
                />
              ) : (
                <div>
                  <div
                    className={
                      newScheduledActionError
                        ? classes.errorAddIconSquare
                        : classes.addIconSquare
                    }
                    onClick={handleSchedulePopper}
                  >
                    <AddIcon
                      className={classes.addIconStyle}
                      style={{ fontSize: 35 }}
                    />
                  </div>
                  {newScheduledActionError ? (
                    <div className={classes.scheduledActionErrorMessage}>
                      You must select an action first!
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              )}
            </div>
          </Tooltip>

          <Popover
            id="simple-popper"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closeSchedulePopper}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.scheduleActionImageContainer}>
              {department.actionsList ? (
                <div>
                  {department.actionsList.map((imageAction: IAction) => (
                    <img
                      key={imageAction.id}
                      className={classes.selectedScheduledActionImage}
                      src={imageAction.actionImageUrl}
                      alt="#"
                      onClick={() => assignSelectedActionIcon(imageAction)}
                    />
                  ))}
                </div>
              ) : (
                <div />
              )}
            </div>
          </Popover>
        </div>
        <Tooltip
          title="How many minutes will pass before the icon will be added (depending on touch status)"
          placement="right"
        >
          <TextField
            label="Minutes To Wait"
            value={minutesToWait}
            onChange={handleNewScheduledMinutesToWait}
            margin="none"
          />
        </Tooltip>
        <br />
        <Tooltip
          title="This will make the icon flash to help stand out over the others"
          placement="right"
        >
          <div
            onClick={() => {
              setIsFlashing(!isFlashing);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={isFlashing}
            />
            Flashing Icon
          </div>
        </Tooltip>

        <br />
        <Tooltip
          title="Status of the room when this icon should be scheduled"
          placement="right"
        >
          <div>
            <InputLabel>Touch Status</InputLabel>
            <br />
            <Select
              value={scheduledTouchStatus}
              onChange={handleScheduledTouchStatus}
            >
              <MenuItem value={1}>Alone</MenuItem>
              <MenuItem value={2}>Provider</MenuItem>
              <MenuItem value={3}>Staff</MenuItem>
              <MenuItem value={4}>Hold</MenuItem>
            </Select>
          </div>
        </Tooltip>

        <br />
        <Button
          className={classes.addDeviceButton}
          // align="right"
          onClick={() => addScheduledAction()}
          component="label"
          color="primary"
        >
          Add Scheduled Action
        </Button>
      </div>
    </div>
  );
};

export default DepartmentScheduledActions;
