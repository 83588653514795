import { Component } from 'react';
import { API } from '../../../apiconfig';
import axios from 'axios';
import { connect } from 'react-redux';
import { logAction } from '../../../actions/adminActions';

import { canDo } from '../../../utils/permissionCheck';

import { checkRefreshToken } from '../../../utils/utils';

import SuccessSnack from '../../../utils/snack/SuccessSnack';

import moment from 'moment-timezone';
import { getQueryVariable } from '../../../utils/utils';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import addpersonblack from '../../../images/addpersonblack.png';
import addpersonwhite from '../../../images/addpersonwhite.png';
import filterIcon from '../../../images/filter.png';
import filterIconHollow from '../../../images/filterhollow.png';

import NewPatientDialog from './newPatientDialog/NewPatientDialog';

import { allowSignalR } from '../../../socket-manager';

import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import Carousel from 'react-material-ui-carousel';
import {
  IDepartment,
  IPatient,
  IRoom,
  IUser,
  IProvider,
} from '../../../interfaces/interfaces';

const theme = createTheme({});

const styles: {} = (theme: any) => ({
  dialogPaper: { maxWidth: '350px' },
  cardHead: {
    fontSize: 32,
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: 'left',
    display: 'flex',
  },
  cardContent: {
    height: '100%',
    backgroundColor: 'white',
    paddingTop: 0,
  },
  deletePatient: {
    cursor: 'pointer',
    color: 'red',
    marginRight: 5,
  },
});

interface PatientsCardProps {
  department: IDepartment;
  // mute: boolean;
  selectedHistoryTime: any;
  timeZone: any;
  refetchFsData: () => void;
  classes: any;
  loggedInUserData: IUser;
  roomColor: any;
  allDepts: any[];
  logAction: (logName: string, deptId: string, userId: string) => void;
  serverTimeOffset: any;
  toggleWaitingRoomProvider: (
    providerId: string,
    visibleWaitingRoomProviders: any[],
    deptId: string
  ) => void;
  viewDeptSettings: any[];
  // allProviders: any;
}

interface PatientsCardState {
  showNewPatientDialog: boolean;
  patientHover: boolean;
  allProviders: any[];
  showPatientOptions: boolean;
  patientStep: number;
  tabValue: number;
  availableRooms: any[];
  selectedPatient: any;
  patientTransferredSuccess: any;
  showWaitingRoomFilterDialog: boolean;
  providerSearchKey: number;
  // waitingRoomProvidersToShow: string[];
}

class PatientsCard extends Component<PatientsCardProps, PatientsCardState> {
  constructor(props: PatientsCardProps) {
    super(props);
    this.state = {
      showNewPatientDialog: false,
      patientHover: false,
      allProviders: [],
      showPatientOptions: false,
      patientStep: 0,
      tabValue: 0,
      availableRooms: [],
      selectedPatient: null,
      patientTransferredSuccess: null,
      showWaitingRoomFilterDialog: false,
      providerSearchKey: 0,
      // waitingRoomProvidersToShow: []
    };
  }

  componentDidMount() {
    let token = localStorage.getItem('token');
    let URL = API.REACT_APP_API_GETPROVIDERS + this.props.department.id;
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("RESPONSE GET PROVIDERS", response);
        this.setState({
          allProviders: response.data.data.allProviders,
        });

        // console.log('new state', this.state);
      })
      .catch(err => {
        console.log('Error fetching providers: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  }

  getPatientWaitTime(patientWaitTime: any) {
    let currentTime;

    if (this.props.selectedHistoryTime) {
      currentTime = moment
        .utc(this.props.selectedHistoryTime)
        .tz(this.props.timeZone)
        .local();
      let waitTime = currentTime.diff(patientWaitTime, 'minutes');

      if (waitTime > 99) {
        return '99+';
      } else {
        return waitTime;
      }
    } else {
      if (
        moment()
          .add('milliseconds', this.props.serverTimeOffset)
          .local()
          .diff(moment.utc(patientWaitTime).local(), 'minutes') > 99
      ) {
        return '99+';
      } else {
        return moment()
          .add('milliseconds', this.props.serverTimeOffset)
          .local()
          .diff(moment.utc(patientWaitTime).local(), 'minutes');
      }
    }
  }

  getPatientAddedTime(patientWaitTime: any) {
    if (!this.props.selectedHistoryTime) {
      return moment()
        .local()
        .diff(moment.utc(patientWaitTime).local(), 'seconds');
    } else {
      return 10;
    }
  }

  deletePatient = (patientId: string) => {
    let data = {
      id: patientId,
      deptId: this.props.department.id,
    };

    let token = localStorage.getItem('token');
    axios({
      method: 'post',
      url: API.REACT_APP_API_DELETEPATIENT,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (!allowSignalR) {
          this.props.refetchFsData();
        }
        this.setState({
          showPatientOptions: false,
          selectedPatient: null,
        });
        this.props.logAction(
          'Deleted Patient',
          this.props.department.id,
          this.props.loggedInUserData.id
        );
      })
      .catch(err => {
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
        console.log('Error deleting patient');
      });
  };

  openWaitingRoomFilterDialog = () => {
    this.setState({
      showWaitingRoomFilterDialog: true,
    });
  };

  openPatientDialog = () => {
    console.log('open patient dialog');
    this.setState({
      showNewPatientDialog: true,
    });
  };

  openPatientOptions = (patient: any) => {
    this.setState({
      selectedPatient: patient,
      showPatientOptions: true,
      patientHover: false,
    });
  };

  handleTabChange = (event: any, newValue: number) => {
    this.setState({
      tabValue: newValue,
    });
  };

  fetchAvailableRooms = () => {
    let URL =
      API.REACT_APP_API_TRANSFERPATIENTSAVAILABLEROOMS +
      this.props.department.mongoSiteId;
    console.log('FETCh available rooms', URL);
    // console.log("url", URL);
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("response!", response.data.data);

        let res = response.data.data;

        // Bring department room was selected to up front
        if (res) {
          res.forEach((dept: any, i: any) => {
            if (dept.deptId === this.props.department.id) {
              res.splice(i, 1);
              res.unshift(dept);
            }
          });
        }

        this.setState({
          availableRooms: res,
        });
      })
      .catch(err => {
        console.log('Error fetching available rooms: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  openPatientTransferOptions = () => {
    let URL =
      API.REACT_APP_API_TRANSFERPATIENTSAVAILABLEROOMS +
      this.props.department.mongoSiteId;
    console.log('URL', URL);
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        console.log('response!', response.data.data);

        let res = response.data.data;

        // Bring department room was selected to up front
        if (res) {
          res.forEach((dept: any, i: any) => {
            if (dept.deptId === this.props.department.id) {
              res.splice(i, 1);
              res.unshift(dept);
            }
          });
        }

        this.setState({
          availableRooms: res,
        });
      })
      .catch(err => {
        console.log('Error fetching available rooms: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      patientStep: 1,
    });
  };

  transferPatientToWaitingRoom = (dept: IDepartment) => {
    let currentRoomId = null; // make null
    let currentDeptId = this.props.department.id;
    let newDeptId = dept.id;
    let patientIds: any[] = [this.state.selectedPatient];
    let patient = this.state.selectedPatient;
    let currentRoomNewStatus = null; // make null

    let payload: any = {
      currentRoomId,
      currentDeptId,
      newDeptId,
      patientIds,
      currentRoomNewStatus,
    };
    console.log('Payload to waiting room', payload);
    let token = localStorage.getItem('token');
    axios({
      method: 'POST',
      url: API.REACT_APP_API_TRANSFERPATIENTSTOWAITINGROOM,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        this.props.logAction(
          'Patient Transferred',
          patient.id,
          this.props.loggedInUserData.id
        );
        this.setState({
          patientTransferredSuccess: dept.deptName + ' Waiting Room',
        });
        setTimeout(() => {
          this.setState({
            patientTransferredSuccess: null,
          });
        }, 5000);
      })
      .catch(err => {
        console.log('Error sending action', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
    this.setState({
      showPatientOptions: false,
      selectedPatient: null,
      patientStep: 0,
    });
  };

  transferPatientToRoom = (room: IRoom) => {
    let currentDeptId = this.props.department.id;
    let currentRoomId = null;
    let newRoomId = room.id;
    let patientIds: any[] = [this.state.selectedPatient];
    let patient = this.state.selectedPatient;
    let currentRoomNewStatus = null;

    let payload: any = {
      currentDeptId,
      currentRoomId,
      newRoomId,
      patientIds,
      currentRoomNewStatus,
    };

    let token = localStorage.getItem('token');

    console.log('to exam payload', payload);

    axios({
      method: 'POST',
      url: API.REACT_APP_API_TRANSFERPATIENTSTOEXAMROOM,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        this.props.logAction(
          'Patient Transferred',
          patient.id,
          this.props.loggedInUserData.id
        );
        this.setState({
          patientTransferredSuccess:
            this.props.department.deptName + ' ' + room.roomName,
        });
        setTimeout(() => {
          this.setState({
            patientTransferredSuccess: null,
          });
        }, 5000);
      })
      .catch(err => {
        console.log('Error sending action', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      showPatientOptions: false,
      selectedPatient: null,
      patientStep: 0,
    });
  };

  startPatientTransfer = (selectedDept: any, selectedRoom: any) => {
    let deptData;
    let roomData;
    this.props.allDepts.forEach((dept: IDepartment) => {
      if (selectedDept.deptId === dept.id) {
        deptData = dept;
        dept.rooms.forEach(room => {
          if (selectedRoom.roomId === room.id) {
            roomData = room;
          }
        });
      }
    });

    if (selectedRoom.isWaitingRoom) {
      if (deptData) {
        this.transferPatientToWaitingRoom(deptData);
      }
    } else {
      if (roomData) {
        this.transferPatientToRoom(roomData);
      }
    }
  };

  render() {
    const { classes } = this.props;

    let darkMode = false;
    if (localStorage.getItem('darkMode')) {
      let isDark = localStorage.getItem('darkMode');

      if (isDark) {
        if (JSON.parse(isDark)) {
          darkMode = true;
        } else {
          darkMode = false;
        }
      }
    }

    let waitingRoomProvidersToShow: any[] = [];

    let viewId = getQueryVariable('viewId');
    if (viewId) {
      // In View
      this.props.viewDeptSettings.forEach(deptViewSetting => {
        if (deptViewSetting.deptId === this.props.department.id) {
          // console.log("Found the VIEW dept setting", deptViewSetting);
          waitingRoomProvidersToShow =
            deptViewSetting.waitingRoomProvidersToShow;
        }
      });
    } else {
      if (getQueryVariable('groupId')) {
        // In Standalone FS
        if (localStorage.getItem(this.props.department.id)) {
          let departmentSettings = JSON.parse(
            localStorage.getItem(this.props.department.id)!
          );
          if (departmentSettings) {
            waitingRoomProvidersToShow =
              departmentSettings.waitingRoomProvidersToShow;
          }
        }
      }
    }

    let arrayOfArrays: any[] = [];
    let chunk: any[] = [];

    const patients = [];

    this.props.department.providerPatientsWaiting.forEach((provider, index) => {
      provider.patients.forEach((patient: IPatient, pIndex: any) => {
        patients.push(patient);
        // console.log("Chunk Length", chunk.length);

        let showAll: boolean = false;
        if (waitingRoomProvidersToShow?.length === 0) {
          // filter out patients that are not in waitingRoomProvidersToShow
          showAll = true;
        }

        if (!waitingRoomProvidersToShow) {
          showAll = true;
        }

        if (
          showAll ||
          waitingRoomProvidersToShow?.includes(patient.providerId)
        ) {
          if (pIndex === 0) {
            // console.log("its 0", provider.providerName);

            if (chunk.length > 5) {
              // console.log("Break it 1");
              arrayOfArrays.push(chunk);
              chunk = [];
              chunk.push(provider.providerName);
              chunk.push(patient);
            } else {
              chunk.push(provider.providerName);
              chunk.push(patient);
            }
          } else {
            if (chunk.length > 5) {
              // console.log("Break it 2");
              arrayOfArrays.push(chunk);
              chunk = [];
              chunk.push(patient);
            } else {
              chunk.push(patient);
            }
          }
        }
      });
    });

    if (chunk.length > 0) {
      arrayOfArrays.push(chunk);
    }

    let Item = (props: any) => {
      if (typeof props.item === 'string') {
        return (
          <div
            style={{
              borderBottom: '1px solid gray',
              fontWeight: 'bold',
            }}
          >
            {props.item}
          </div>
        );
      } else {
        let patient = props.item;

        let patientInfoString = patient.fsPatientInfoString;

        return (
          <div
            style={{ fontSize: 12, maxHeight: 46, overflow: "hidden" }}
            className={
              this.getPatientAddedTime(patient.startVisitTime) < 10 &&
                !this.props.selectedHistoryTime
                ? 'blink'
                : ''
            }
            onMouseOver={() =>
              !this.state.patientHover
                ? this.setState({ patientHover: patient.id })
                : null
            }
            onMouseOut={() =>
              this.state.patientHover
                ? this.setState({ patientHover: false })
                : null
            }
          >
            <strong
              onClick={() =>
                canDo(['Update Flowstation'], this.props.loggedInUserData)
                  ? this.deletePatient(patient.id)
                  : null
              }
              style={{
                cursor: 'pointer',
                color: 'red',
                marginRight: 5,
                marginLeft: 2,
              }}
            >
              X
            </strong>
            <span
              onClick={() => this.openPatientOptions(patient)}
              className="boldHover"
              style={{ cursor: 'pointer', fontSize: 14 }}
            >
              {patientInfoString}
            </span>
            <strong style={{ fontSize: 16, paddingLeft: 2 }}>
              {this.getPatientWaitTime(patient.startVisitTime)}min
            </strong>
            {this.state.patientHover === patient.id ? (
              <span
                style={{
                  borderRadius: 4,
                  color: 'white',
                  padding: 10,
                  position: 'fixed',
                  backgroundColor: '#696969',
                  zIndex: 10,
                  boxShadow:
                    '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                }}
              >
                Patient ID: {patient.mrnNumber}
              </span>
            ) : null}
          </div>
        );
      }
    };

    let constructedCarousel = (
      <div>
        <Carousel
          key={patients.length}
          indicatorContainerProps={{
            style: {
              marginTop: '0px'
            }
          }}
          interval={6000}
          fullHeightHover={false}
          navButtonsAlwaysInvisible={true}
          cycleNavigation={true}
          // @ts-ignore
          onMouseOut={() => this.setState({ patientHover: false })}
        >
          {arrayOfArrays.map((a, i) => (
            <div key={i}>
              {arrayOfArrays[i].map((item: any, index: any) => (
                <Item key={index} item={item} />
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    );

    return (
      <MuiThemeProvider theme={theme}>
        <div data-private style={{ maxWidth: 257 }}>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            hideBackdrop={true}
            open={this.state.showPatientOptions}
            style={{ textAlign: 'center' }}
            transitionDuration={{ enter: 0, exit: 0 }}
            onClose={() => {
              this.setState({
                selectedPatient: null,
                showPatientOptions: false,
              });
            }}
          >
            <div
              style={
                darkMode ? { backgroundColor: '#333333', color: 'white' } : {}
              }
            >
              <div
                onClick={() =>
                  this.setState({
                    showPatientOptions: false,
                    selectedPatient: null,
                  })
                }
                style={{
                  color: 'red',
                  textAlign: 'right',
                  fontSize: 32,
                  cursor: 'pointer',
                  padding: '10px 10px 0px 0px',
                }}
              >
                X
              </div>
              <div style={{ fontSize: 32 }}>Patient Options</div>
              <DialogContent>
                <div style={{ textAlign: 'center' }}>
                  {this.state.patientStep === 0 ? (
                    <div>
                      <div>
                        <Button
                          onClick={this.openPatientTransferOptions}
                          color="primary"
                          style={darkMode ? { color: 'white' } : {}}
                        >
                          Transfer Patient
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() =>
                            this.deletePatient(this.state.selectedPatient.id)
                          }
                          color="primary"
                          style={darkMode ? { color: 'white' } : {}}
                        >
                          Delete Patient
                        </Button>
                      </div>
                      <br />
                    </div>
                  ) : null}

                  {this.state.patientStep === 1 ? (
                    <div style={{ display: 'flex', marginTop: 25 }}>
                      <Tabs
                        style={
                          darkMode
                            ? { backgroundColor: '#333333', display: 'flex' }
                            : {
                              backgroundColor: theme.palette.background.paper,
                              display: 'flex',
                            }
                        }
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.tabValue}
                        TabIndicatorProps={{
                          style: darkMode
                            ? {
                              backgroundColor: '#4CB946',
                            }
                            : {
                              backgroundColor: '#3f51b5',
                            },
                        }}
                        onChange={this.handleTabChange}
                        aria-label="Vertical tabs example"
                        textColor="primary"
                      >
                        {this.state.availableRooms.map(
                          (dept: IDepartment, index: any) => (
                            <Tab
                              key={index}
                              label={dept.deptName}
                              style={
                                darkMode
                                  ? {
                                    backgroundColor: '#333333',
                                    color: 'white',
                                  }
                                  : {}
                              }
                            />
                          )
                        )}
                      </Tabs>
                      <div style={{ margin: '5px auto 0px auto' }}>
                        {this.state.availableRooms.map(
                          (dept: any, index: any) => (
                            <div key={index}>
                              {this.state.tabValue === index && (
                                <div
                                  style={{
                                    padding: '6px 12px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {dept.roomsInDept.map(
                                    (room: any, roomIndex: any) => (
                                      <div key={roomIndex}>
                                        {dept.deptId ===
                                          this.props.department.id &&
                                          room.isWaitingRoom ? null : (
                                          <div
                                            style={{ cursor: 'pointer' }}
                                            className="boldHover"
                                            onClick={() =>
                                              this.startPatientTransfer(
                                                dept,
                                                room
                                              )
                                            }
                                          >
                                            {room.roomName}
                                            {!room.isWaitingRoom && (
                                              <span
                                                style={{ fontStyle: 'italic' }}
                                              >
                                                - {room.roomStatus}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </DialogContent>
            </div>
          </Dialog>
          <div
            style={{
              backgroundColor: this.props.roomColor,
              height: 10,
              width: '100%',
              marginBottom: 5,
            }}
          ></div>
          <div style={{ margin: 4 }}>
            <Card
              className={'noselect'}
              style={
                darkMode
                  ? {
                    border: '2px solid #666666',
                    backgroundColor: '#666666',
                    width: 250,
                    height: 330,
                  }
                  : {
                    border: '2px solid rgb(255, 255, 255)',
                    backgroundColor: 'white',
                    width: 250,
                    height: 330,
                  }
              }
            >
              <div
                className={`${classes.cardHead} mt-1 flex h-[46px] items-center`}
                style={darkMode ? { color: 'white' } : {}}
              >
                <div className="w-full">
                  <span style={{ fontSize: 30 }}>Waiting</span>
                </div>

                <span style={{ float: 'right', margin: 'auto' }}>
                  <img
                    src={
                      waitingRoomProvidersToShow?.length > 0
                        ? filterIcon
                        : filterIconHollow
                    }
                    onClick={this.openWaitingRoomFilterDialog}
                    alt="="
                    style={{
                      cursor: 'pointer',
                      marginRight: 10,
                      filter: 'invert(1)',
                      width: 20,
                      height: 20,
                      paddingBottom: 2,
                    }}
                  />
                </span>
                {getQueryVariable('username') ? null : (
                  <span style={{ float: 'right', margin: 'auto' }}>
                    {canDo(
                      ['Update Flowstation'],
                      this.props.loggedInUserData
                    ) ? (
                      <img
                        src={darkMode ? addpersonwhite : addpersonblack}
                        style={{ width: 25, cursor: 'pointer' }}
                        alt=""
                        onClick={this.openPatientDialog}
                      />
                    ) : null}
                  </span>
                )}
              </div>
              <div
                className={classes.cardContent}
                style={
                  darkMode
                    ? { backgroundColor: '#333333', color: 'white' }
                    : { backgroundColor: 'white' }
                }
              >
                <div style={{ flex: 1 }}>{constructedCarousel}</div>
              </div>

              <Dialog
                open={this.state.showWaitingRoomFilterDialog}
                onClose={() =>
                  this.setState({ showWaitingRoomFilterDialog: false })
                }
                classes={{ paper: classes.dialogPaper }}
                BackdropProps={{
                  classes: {
                    root: classes.root,
                  },
                }}
                PaperProps={{
                  classes: {
                    root: classes.paper,
                  },
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    border: '2px solid lightgray',
                  }}
                >
                  <DialogTitle
                    style={{ paddingBottom: 0, textAlign: 'center' }}
                  >
                    Filter Patients by Provider
                  </DialogTitle>
                  <DialogContent>
                    <form data-private style={{ textAlign: 'center' }}>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {this.state.allProviders.map(
                          (provider: IProvider, key: number) => (
                            <div key={key}>
                              {waitingRoomProvidersToShow?.includes(
                                provider.id
                              ) && (
                                  <div>
                                    <Chip
                                      label={provider.fullName}
                                      style={{ margin: '5px 5px 5px 0px' }}
                                      onDelete={() =>
                                        this.props.toggleWaitingRoomProvider(
                                          provider.id,
                                          waitingRoomProvidersToShow,
                                          this.props.department.id
                                        )
                                      }
                                      avatar={
                                        <Avatar src={provider.userImageUrl} />
                                      }
                                    />
                                  </div>
                                )}
                            </div>
                          )
                        )}
                      </div>

                      <Autocomplete
                        id="provider-search"
                        key={this.state.providerSearchKey}
                        autoHighlight
                        // options={this.state.allProviders}
                        options={this.state.allProviders.filter(
                          (provider: IProvider) => {
                            return !waitingRoomProvidersToShow?.includes(
                              provider.id
                            );
                          }
                        )}
                        getOptionLabel={(provider: IProvider) =>
                          provider.fullName
                        }
                        onChange={(
                          event: any,
                          provider: IProvider | string
                        ) => {
                          if (provider && typeof provider !== 'string') {
                            console.log('Handle', provider);
                            // this.toggleWaitingRoomProvider(provider, this.state.deptSettingsToModify.id, allDepts);
                            this.props.toggleWaitingRoomProvider(
                              provider.id,
                              waitingRoomProvidersToShow,
                              this.props.department.id
                            );
                            this.setState({
                              providerSearchKey:
                                this.state.providerSearchKey + 1,
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            autoFocus
                            label="Search Provider"
                          />
                        )}
                      />
                    </form>
                  </DialogContent>
                </div>
              </Dialog>

              {/* Wrapping NewPatientDialog in a conditional to prevent unnecessary render */}
              {this.state.showNewPatientDialog ? (
                <NewPatientDialog
                  open={this.state.showNewPatientDialog}
                  cardData={null}
                  department={this.props.department}
                  loggedInUserData={this.props.loggedInUserData}
                  closeDialog={() =>
                    this.setState({ showNewPatientDialog: false })
                  }
                  refetchFsData={() => this.props.refetchFsData()}
                  autoAddPatientToRoom={false}
                />
              ) : null}
            </Card>
          </div>
        </div>
        {this.state.patientTransferredSuccess && (
          <SuccessSnack
            successSnack={true}
            successMessage={
              'Patient Successfully Transferred to ' +
              this.state.patientTransferredSuccess
            }
          />
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  logAction,
})(withStyles(styles)(PatientsCard));
