import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import flowstationReducer from "./flowstationReducer";
import lobbyReducer from "./lobbyReducer";
// import { loadingBarReducer } from "react-redux-loading-bar";

export default combineReducers({
  adminData: adminReducer,
  groupData: flowstationReducer,
  lobbyData: lobbyReducer,
  // loadingBar: loadingBarReducer,
});
