import { FC } from 'react';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { CheckboxField, SelectField } from '../../../../../common';
import { TFormSchema } from '../DepartmentForm';
import { patientInfoTypeOptions } from '../constants';
import { useStyles } from '../style';
import { getLiveExample } from './utils';

type TProps = {
  control: Control<TFormSchema>;
  errors: FieldErrors<TFormSchema>;
  watch: UseFormWatch<TFormSchema>;
  namePrefix: 'fsPatientInfo' | 'inRoomPatientInfo' | 'outsideRoomPatientInfo';
  patientInfo: any;
};

const DepartmentPatientIdentifierSubform: FC<TProps> = props => {
  const { namePrefix, control, errors, patientInfo } = props;

  const patientIdentifierExample = getLiveExample({
    patientInfo,
  });

  const classes = useStyles();

  return (
    <div>
      <div className={classes.patientIdentifierExampleText}>
        *Examples for patient John Smith
      </div>
      <div className={classes.patientIdentifierExampleText}>
        Ex: {patientIdentifierExample}
      </div>
      <div className={`${classes.patientIdentifierExampleText} mt-2`}>
        <strong>Identifier 1 (choose 1)</strong>
      </div>
      <div className={`${classes.patientIdentifierExampleText} mt-2`}>
        <SelectField
          name={`${namePrefix}.patientInfoTypeId`}
          control={control}
          errors={errors}
          options={patientInfoTypeOptions}
        />
      </div>

      <br />
      <div className={classes.patientIdentifierExampleText}>
        <strong>Identifier 2</strong>
      </div>

      <div className="mt-2 ml-1 pb-2">
        <CheckboxField
          classes={{
            root: classes.identifierCheckbox,
            checked: classes.checked,
          }}
          name={`${namePrefix}.appendAge`}
          control={control}
          errors={errors}
          label="Show Age"
        />

        <CheckboxField
          classes={{
            root: classes.identifierCheckbox,
            checked: classes.checked,
          }}
          name={`${namePrefix}.appendMrn`}
          control={control}
          errors={errors}
          label="Show EHR Patient ID"
        />

        <CheckboxField
          classes={{
            root: classes.identifierCheckbox,
            checked: classes.checked,
          }}
          name={`${namePrefix}.appendApptTime`}
          control={control}
          errors={errors}
          label="Show Appointment Time"
        />

        <CheckboxField
          classes={{
            root: classes.identifierCheckbox,
            checked: classes.checked,
          }}
          name={`${namePrefix}.appendApptType`}
          control={control}
          errors={errors}
          label="Show Appointment Type"
        />

        <CheckboxField
          classes={{
            root: classes.identifierCheckbox,
            checked: classes.checked,
          }}
          name={`${namePrefix}.appendDob`}
          control={control}
          errors={errors}
          label="Show Date of Birth"
        />

        <CheckboxField
          classes={{
            root: classes.identifierCheckbox,
            checked: classes.checked,
          }}
          name={`${namePrefix}.appendLanguage`}
          control={control}
          errors={errors}
          label="Show Language"
        />

        <CheckboxField
          classes={{
            root: classes.identifierCheckbox,
            checked: classes.checked,
          }}
          name={`${namePrefix}.appendGender`}
          control={control}
          errors={errors}
          label="Age 21 Max"
        />
      </div>
    </div>
  );
};

export default DepartmentPatientIdentifierSubform;
