import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { includes } from 'lodash';
import { FC, useRef, useState } from 'react';
import useProviders from '../../../hooks/useProvider/UseProviders';
import { IProvider } from '../../../interfaces/interfaces';

export type IProviderOption = {
  label: string;
  value: string;
  provider: IProvider;
};

type TProps = {
  orgId: string;
  onSelect?: (selectedProviders: IProvider[]) => void;
  selectedProviders: IProvider[];
};

const ProviderMultiSelectField: FC<TProps> = props => {
  const {
    orgId,
    onSelect = null,
    selectedProviders: initialSelectedProviders = [],
  } = props;

  const { providers = [] } = useProviders({ orgId });

  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedProviders, setSelectedProviders] = useState<IProvider[]>(
    initialSelectedProviders
  );
  const [providerSearchKey, setProviderSearchKey] = useState(0);

  const onAdd = (providerOption: IProviderOption) => {
    const provider = providerOption.provider;

    if (provider) {
      console.log('provider', provider);
      const updatedSelectedProviders = [...selectedProviders, provider];
      setSelectedProviders(updatedSelectedProviders);
      onSelect && onSelect(updatedSelectedProviders);
    }
  };

  const onRemove = (provider: IProvider) => {
    const updatedSelectedProviders = selectedProviders.filter(
      (p: IProvider) => p.id !== provider.id
    );
    setSelectedProviders(updatedSelectedProviders);
    onSelect && onSelect(updatedSelectedProviders);
  };

  const selectedProviderIds = selectedProviders.map(provider => provider.id);

  const providerOptions: IProviderOption[] = providers
    .filter((provider: IProvider) => {
      return provider?.isActive && !includes(selectedProviderIds, provider.id);
    })
    .map(provider => {
      return {
        label: provider.fullName,
        value: provider.id,
        provider,
      };
    });

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedProviders.map(provider => (
          <div key={provider.id}>
            <Chip
              style={{ margin: '5px 5px 5px 0px' }}
              label={provider.fullName}
              onDelete={() => onRemove(provider)}
            />
          </div>
        ))}
      </div>

      <Autocomplete
        id="providerInput"
        key={providerSearchKey}
        autoHighlight
        options={providerOptions}
        getOptionLabel={(provider: IProviderOption) => provider.label}
        onChange={(event: any, provider: any) => {
          onAdd(provider as IProviderOption);

          new Promise(resolve => {
            setProviderSearchKey(providerSearchKey + 1);

            resolve(null);
          }).then(() => {
            // providerInputRef.focus();
            inputRef.current?.focus();
          });
        }}
        renderInput={params => (
          <TextField {...params} autoFocus ref={inputRef} label="Select User" />
        )}
      />
    </div>
  );
};

export default ProviderMultiSelectField;
