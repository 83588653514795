import { z } from "zod";

export const schema = z.object({
  fsName: z.string().nullish(),
  fsUrl: z.string().nullish(),
  flowStationToken: z.string().nullish(),
  departmentId: z.string().nullish(),
  organizationId: z.string().nullish(),
});

export type TFormSchema = z.infer<typeof schema>;