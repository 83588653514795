import { createTheme, makeStyles, Theme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#A7D6A4',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiStepIcon: {
      root: {
        color: '#4FAC48',
        '&$active': {
          color: '#4FAC48',
        },
        '&$completed': {
          color: '#4FAC48',
        },
      },
    },
  },
});

const styles: any = (theme: Theme) => ({
  deleteButton: {
    color: 'red',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  head: {
    backgroundColor: '#fafafa',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  dropDown: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 100,
  },
  drawer: {
    padding: 50,
  },
  bigAvatar: {
    margin: 10,
    width: 100,
    height: 100,
    cursor: 'pointer',
  },
  submitButton: {
    backgroundColor: '#F0F1F9',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  sortField: {
    marginLeft: theme.spacing(),
    width: 140,
  },
  textBadgeField: {
    marginLeft: theme.spacing(),
    width: 140,
    marginTop: 0,
  },
  duplicateBadgeError: {
    color: 'red',
    width: '100%',
    marginLeft: theme.spacing(),
  },
  textBadgeExpireField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: 0,
    width: 200,
  },
  badgesTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  expireTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    marginLeft: 8,
    marginRight: 8,
  },
  badgeNumber: {
    fontWeight: 'bold',
  },
  saveWarning: {
    color: 'red',
    paddingBottom: 10,
  },
  saveArea: {
    marginTop: 20,
  },
  newInstrumentButton: {
    zIndex: 10,
    backgroundColor: '#4FAC48',
    position: 'absolute',
    right: theme.spacing(3),
  },
  resetPasswordButton: {
    marginLeft: 32,
    marginBottom: 20,
  },
  sortSelect: {
    cursor: 'pointer',
  },
  closeButton: {
    paddingRight: 10,
    cursor: 'pointer',
    color: 'red',
    textAlign: 'right',
  },
  loading: {
    paddingLeft: 20,
  },
  progress: {
    color: '#4FAC48',
  },
  gone: {
    display: 'none',
  },
  colorSwitchBase: {
    color: '#4FAC48',
    '&$colorChecked': {
      color: '#4FAC48',
      '& + $colorBar': {
        backgroundColor: '#4FAC48',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  expirationDateText: {
    color: '#757575',
    fontWeight: 400,
  },
  advancedSettingsExpansionPanel: {
    marginTop: 18,
  },
  rotateImageIcon: {
    fontSize: 30,
    cursor: 'pointer',
  },
  expirationCheckbox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
  },
  temporaryCheckboxContainer: {
    marginTop: 24,
  },
  newInstrumentModal: {
    position: 'absolute',
    width: '450px',
    backgroundColor: '#FFFFFF',
    color: 'black',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  primaryDept: {
    color: '#9e9e9e',
    paddingLeft: 24,
    paddingRight: 24,
  },
  checkBox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const useStyles = makeStyles(styles);
