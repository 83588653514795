import axios from 'axios';
import LogRocket from 'logrocket';
import { useCallback } from 'react';
import { API } from '../apiconfig';
import { checkRefreshToken } from '../utils/utils';

export const useLog = () => {
  const logAction = useCallback(
    (
      actionName: string,
      actionDetail: string,
      userId: string
    ): Promise<void> => {
      return new Promise((resolve, reject) => {
        LogRocket.track(actionName);

        let token = localStorage.getItem('token');
        let URL = API.REACT_APP_API_LOGACTION;

        if (actionName !== null && actionDetail !== null && userId !== null) {
          let data = {
            actionName: actionName,
            actionDetail: actionDetail,
            userId: userId,
          };
          // console.log("PAYLOAD", data);
          // Dont Log if in development/localhost
          // if (window.location.hostname !== "localhost") {
          axios({
            method: 'post',
            url: URL,
            data,
            headers: {
              Authorization: 'Bearer ' + token,
              Pragma: 'no-cache',
            },
          })
            .then(response => {
              // console.log("RES", response);

              resolve(null);
            })
            .catch(err => {
              console.log('Error: ', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }

              reject(err);
            });
          // } else {
          //   console.log("LOCALHOST: NOT LOGGING");
          // }
        } else {
          console.log('missing data for log');
        }
      });
    },
    // eslint-disable-next-line
    []
  );

  return {
    useLog,
    logAction,
  };
};
