import {
  FETCH_GROUP,
  UPDATE_GROUP,
  FETCH_SIBLINGDEPTS,
  UPDATE_SIBLINGDEPTS,
  FETCH_AND_UPDATE_SIBLINGDEPTS,
  FETCH_STAFF_LOCATIONS,
  FETCH_ORGANIZATION,
  SET_DEPT_HISTORY,
  FETCH_ORGANIZATION_DEPTS,
  FETCH_PROVIDER_ASSIGNMENT_USERS,
} from "../actions/types";
import { TGroupState } from "../interfaces/interfaces";

const initalState: TGroupState = {
  groupData: {},
  siblingDepts: [],
  staffLocations: [],
  organization: {},
  organizationDepts: [],
  providerAssignmentUsers: [],
};

export default function flowstationReducer(state = initalState, action: { type: string; payload: any }) {
  switch (action.type) {
    case FETCH_GROUP:
      return {
        ...state,
        groupData: action.payload,
      };
    case UPDATE_GROUP:
      return {
        ...state,
        groupData: action.payload,
      };
    case FETCH_SIBLINGDEPTS:
      return {
        ...state,
        // siblingDepts: [...state.siblingDepts, action.payload],
        siblingDepts: action.payload,
      };
    case UPDATE_SIBLINGDEPTS:
      return {
        ...state,
        siblingDepts: state.siblingDepts.map((dept: { id: string }) => {
          if (dept.id === action.payload.id) {
            return action.payload;
          }
          return dept;
        }),
      };
    case FETCH_AND_UPDATE_SIBLINGDEPTS:
      return {
        ...state,
        siblingDepts: state.siblingDepts.map((dept: { id: string }) => {
          if (dept.id === action.payload.id) {
            return action.payload;
          }
          return dept;
        }),
      };
    case FETCH_STAFF_LOCATIONS:
      return {
        ...state,
        staffLocations: action.payload,
      };
    case FETCH_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case SET_DEPT_HISTORY:
      return {
        ...state,
        siblingDepts: [],
        groupData: action.payload,
      };
    case FETCH_ORGANIZATION_DEPTS:
      return {
        ...state,
        organizationDepts: action.payload,
      };
    case FETCH_PROVIDER_ASSIGNMENT_USERS:
      return {
        ...state,
        providerAssignmentUsers: action.payload,
      };
    default:
      return state;
  }
}
