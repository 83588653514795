import { FC, useRef } from 'react';
import { useSignalR } from '../../hooks/useSignalR';

type TProps = {
  deviceId: string;
};

const PING_INTERVAL = 9 * 60 * 1000; // 9 minutes in milliseconds

const DeviceSubscription: FC<TProps> = props => {
  const { deviceId } = props;

  const intervalIdRef = useRef(null);

  useSignalR({
    onConnected: hub => {
      console.log('[SignalR] onConnected devicePing', deviceId);

      if (deviceId) {
        const sendDevicePing = () => {
          console.log('[SignalR] onConnected sendDevicePing devicePing', deviceId);
          hub.invoke('devicePing', deviceId).catch(error => {
            console.error('[SignalR] Error with devicePing invocation', error);
          });
        };

        sendDevicePing();

        intervalIdRef.current = setInterval(sendDevicePing, PING_INTERVAL);
      }
    },
    onUnmount: hub => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    },
  });

  return null;
};

export default DeviceSubscription;
