import { FC } from 'react';
import { IOrganization } from '../../../../../interfaces/interfaces';
import { TLobbySequenceLanguage } from '../../../types';

type TTextSize = 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
type TImageSize = 'small' | 'medium' | 'large';

type TProps = {
  language?: TLobbySequenceLanguage;
  organization: IOrganization;
  size?: TTextSize;
  imageSize?: TImageSize;
};

const WaitingRoomWelcome: FC<TProps> = props => {
  const {
    language = 'English',
    organization,
    size = 'xl',
    imageSize = 'small',
  } = props;

  const textSizes: {
    [key: string]: string;
  } = {
    sm: 'text-sm',
    base: 'text-base',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
  };

  const imageSizes: {
    [key: string]: string;
  } = {
    small: 'max-w-xs max-h-48',
    medium: 'max-w-sm max-h-64',
    large: 'max-w-md max-h-96',
  };

  const textSizeClass = textSizes[size] || 'text-xl';
  const imageSizeClass = imageSizes[imageSize] || 'max-w-sm max-h-64';

  if (!organization) {
    return null;
  }


  const showSpanish = language === 'Spanish';

  return (
    <div className="h-[calc(80vh-85px)] flex items-center justify-center gap-2">
      {organization.orgImageUrl && (
        <div>
          <img
            className={`mb-4 object-contain ${imageSizeClass}`}
            src={organization.orgImageUrl}
            alt={organization?.orgName ?? 'Company logo'}
          />
        </div>
      )}
      <div>
        <p className={`text-center text-gray-700 p-0 m-0 ${textSizeClass}`}>
          {showSpanish ? 'Bienvenido a' : 'Welcome to'}{' '}
          {`${organization?.orgName}`}
        </p>
      </div>
    </div>
  );
};

export default WaitingRoomWelcome;
