import axios from 'axios';
import { useState, useCallback } from 'react';
import { API } from '../apiconfig';
import { ICampus } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useCampus = (orgId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createCampus = useCallback(
    (campus: ICampus): Promise<ICampus> => {
      return new Promise((resolve, reject) => {
        if (campus.campusName.length < 1) {
          setError('Campus name is required');
          reject('Campus name is required');
          return;
        }

        setIsLoading(true);
        setError(null);

        const token = localStorage.getItem('token');
        const modifiedCampus = {
          ...campus,
          mongoOrgId: orgId,
        };

        axios({
          method: 'POST',
          url: API.REACT_APP_API_UPDATECAMPUS,
          data: modifiedCampus,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => {
            setIsLoading(false);
            resolve(response.data);
          })
          .catch(err => {
            handleError(err);
            setIsLoading(false);
            reject(err);
          });
      });
    },
    [orgId]
  );

  const updateCampus = useCallback((campus: ICampus): Promise<ICampus> => {
    return new Promise((resolve, reject) => {
      if (campus.campusName.length < 1) {
        setError('Campus name is required');
        reject('Campus name is required');
        return;
      }

      setIsLoading(true);
      setError(null);

      const token = localStorage.getItem('token');

      axios({
        method: 'PUT',
        url: API.REACT_APP_API_UPDATECAMPUS,
        data: campus,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          setIsLoading(false);
          resolve(response.data);
        })
        .catch(err => {
          handleError(err);
          setIsLoading(false);
          reject(err);
        });
    });
  }, []);

  const handleError = (err: any) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      console.log('UNAUTHORIZED, KICK EM OUT');
      setError('Unauthorized. Please log in again.');
      checkRefreshToken();
    } else {
      console.error('Error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return {
    createCampus,
    updateCampus,
    isLoading,
    error,
  };
};
