import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { FC, Fragment } from 'react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckboxField,
  TextField,
} from '../../../../common';
import { IPatientContent } from '../../../../interfaces/interfaces';
import { useStyles } from './style';
import { Control, Path } from 'react-hook-form';
import { TFormSchema } from './DepartmentForm';

type TProps = {
  patientContent: IPatientContent;
  fields: Record<'id', string>[];
  onDelete: (patientContent: IPatientContent) => void;
  onMoveUp: (patientContent: IPatientContent) => void;
  onMoveDown: (patientContent: IPatientContent) => void;
  control: Control<TFormSchema>;
  index: number;
  errors: any;
  totalNumberOfFields: number;
};

const PatientContentSubform: FC<TProps> = props => {
  const {
    patientContent,
    onDelete,
    onMoveUp,
    onMoveDown,
    control,
    index,
    errors,
    totalNumberOfFields = 0,
  } = props;

  const classes = useStyles();

  return (
    <div key={patientContent.id} className="mb-7">
      <div className="pl-2">
        <span
          className=""
          style={{
            cursor: 'pointer',
            color: 'red',
            fontWeight: 'bold',
            marginRight: 5,
          }}
          onClick={() => onDelete(patientContent as IPatientContent)}
        >
          X
        </span>

        {patientContent?.name}

        <IconButton
          onClick={() => onMoveUp(patientContent)}
          size="small"
          style={{ marginLeft: 5, padding: 0 }}
          disabled={index === 0}
        >
          {/* <ArrowUpIcon fontSize="inherit" /> */}
          {ArrowUpIcon}
        </IconButton>
        <IconButton
          onClick={() => onMoveDown(patientContent)}
          size="small"
          style={{ padding: 0 }}
          disabled={index === totalNumberOfFields - 1}
        >
          {/* <ArrowDownIcon fontSize="inherit" /> */}
          {ArrowDownIcon}
        </IconButton>
        <div style={{ color: 'gray', fontSize: 12 }}>
          <div>({patientContent.patientContentType})</div>
        </div>
      </div>

      <br />

      <div>
        <CheckboxField
          control={control}
          errors={errors}
          className={clsx(classes.checkBox, classes.checked)}
          name={`patientContentIds[${index}].onlyPlayOnce`}
          label={'Only Play Once'}
        />
      </div>

      <div>
        <CheckboxField
          control={control}
          errors={errors}
          className={clsx(classes.checkBox, classes.checked)}
          name={`patientContentIds[${index}].showBeforeProvider`}
          label={'Show Before Provider'}
        />
      </div>

      <div>
        <CheckboxField
          control={control}
          errors={errors}
          className={clsx(classes.checkBox, classes.checked)}
          name={`patientContentIds[${index}].showAfterProvider`}
          label={'Show After Provider'}
        />
      </div>

      <div>
        <CheckboxField
          control={control}
          errors={errors}
          className={clsx(classes.checkBox, classes.checked)}
          name={`patientContentIds[${index}].showInResources`}
          label={'Show In Resources'}
        />
      </div>

      {patientContent.patientContentType === 'Survey' && (
        <Fragment>
          <div>
            <CheckboxField
              control={control}
              errors={errors}
              className={clsx(classes.checkBox, classes.checked)}
              name={`patientContentIds[${index}].showFullSurvey`}
              label={'Activate Survey on Tablets'}
            />
          </div>

          <div>
            <CheckboxField
              control={control}
              errors={errors}
              className={clsx(classes.checkBox, classes.checked)}
              name={`patientContentIds[${index}].hideQrCode`}
              label={'Hide QR Code'}
            />
          </div>
        </Fragment>
      )}

      {patientContent.patientContentType === 'Education Video' && (
        <Fragment>
          <CheckboxField
            control={control}
            errors={errors}
            className={clsx(classes.checkBox, classes.checked)}
            name={`patientContentIds[${index}].isUnmuted`}
            label={'Is Muted'}
          />
        </Fragment>
      )}

      {patientContent.patientContentType !== 'Education Video' && (
        <div className="pl-3">
          <TextField<TFormSchema>
            control={control}
            label="Seconds to Show"
            type="number"
            name={
              `patientContentIds[${index}].secondsToShow` as Path<TFormSchema>
            }
            errors={errors}
          />
        </div>
      )}

      <br />
    </div>
  );
};

export default PatientContentSubform;
