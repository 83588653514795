import { makeStyles } from "@material-ui/core";

export const styles = (theme: any) => ({
  snackbar: {
    backgroundColor: '#4FAC48',
  },
  icon: {
    fontSize: 22,
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});


export const useStyles = makeStyles(styles);