import { makeStyles, Theme } from "@material-ui/core";
const drawerWidth = 170;

const styles: any = (theme: Theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // padding: "0 8px",
    paddingLeft: 8,
    paddingRight: 0,
    ...theme.mixins.toolbar,
  },
  appBarTitle: {
    fontSize: 25,
    paddingLeft: 20,
    color: "#7e7e7e",
  },
  appBar: {
    // backgroundColor: "#4FAC48",
    backgroundColor: "#FFFFFF",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 4,
    marginRight: 10,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    color: "#7E7E7E",
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    // display: "none",
    width: 1,
    overflowX: "hidden !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: theme.spacing(0),

    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(0),
    // },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100vh",
    overflow: "auto",
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  logoutButton: {
    color: "black",
  },
  listItem: {
    paddingLeft: 10,
    paddingTop: 4,
    paddingBottom: 4,
  },
  listItemIcon: {
    minWidth: 30,
  },
  orgItem: {
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    // fontWeight: theme.typography.fontWeightBold,
  },
});


export const useStyles = makeStyles(styles);