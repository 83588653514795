import
  {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FC } from 'react';
import { useAthenaPatientLocations } from '../../../../../hooks/useAthenaPatientLocations';
import { IAthenaConfig } from '../../../../../interfaces/interfaces';

type TProps = {
  athenaConfig: IAthenaConfig;
};

const AthenaPatientLocations: FC<TProps> = props => {
  const { athenaConfig } = props;

  const { athenaPatientLocations = [], isLoading } = useAthenaPatientLocations({
    athenaConfig,
  });

  const sortedAthenaPatientLocations = athenaPatientLocations.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Accordion style={{ width: 270 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          Patient Locations ({sortedAthenaPatientLocations?.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer
          component={Paper}
          style={{
            maxHeight: '50vh',
            maxWidth: 240,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location ID</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {sortedAthenaPatientLocations?.map(
                  (athenaPatientLocation, i: number) => (
                    <TableRow key={i}>
                      <TableCell>{athenaPatientLocation.name}</TableCell>
                      <TableCell>
                        {athenaPatientLocation.patientlocationid}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default AthenaPatientLocations;
