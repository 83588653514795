import
  {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
  } from '@material-ui/core';
import { FC } from 'react';

import { TextField } from '../../../../../common';
import { useFetchAthenaConfigList } from '../../../../../hooks/useFetchAthenaConfigList';
import { useStyles } from '../style';
import AthenaPatientLocations from './AthenaPatientLocations';

type TProps = {
  control: any;
  errors: any;
  organization: any;
  department: any;
  watch: any;
};

const AthenaPatientConfig: FC<TProps> = props => {
  const { organization, department, control, errors, watch } = props;

  const { athenaConfigList } = useFetchAthenaConfigList({
    organization,
    department,
    onSelectAthenaConfig: config => {
      console.log('DepartmentDrawer', { config });
    },
  });

  const selectAthenaConfig = (config: any) => {
    console.log('SELECT', config);
  };

  const selectAthenaPracticeId = (event: any) => {
    console.log('SELECT', event.target.value);
    let selectedConfig = athenaConfigList.find(
      (config: any) => config.athenaPracticeId === event.target.value
    );
    selectAthenaConfig(selectedConfig);
    // setValue('athenaPracticeId', event.target.value);
  };

  const athenaPracticeId = watch('athenaPracticeId');

  const classes = useStyles();

  const selectedAthenaConfig = athenaConfigList?.find(athenaConfig => {
    return athenaConfig?.athenaPracticeId === athenaPracticeId;
  });

  return (
    <div>
      <FormControl className={classes.textField}>
        <InputLabel id="demo-simple-select-label">Athena ID</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={athenaPracticeId}
          // onChange={() => handleChange("athenaPracticeId")}
          onChange={selectAthenaPracticeId}
        >
          {athenaConfigList.map((athenaConfig: any, index: number) => (
            <MenuItem key={index} value={athenaConfig.athenaPracticeId}>
              {athenaConfig.athenaPracticeId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <br />

      <Tooltip
        title="This is the Athena ID for the default waiting room. You can see all available locations by selecting the button to the right"
        placement="left"
      >
        <TextField
          label="Athena Waiting Room ID"
          className={classes.textField}
          name={'athenaWaitingRoomId'}
          margin="normal"
          control={control as any}
          errors={errors}
        />
      </Tooltip>
      <br />

      {selectedAthenaConfig && (
        <AthenaPatientLocations athenaConfig={selectedAthenaConfig} />
      )}
    </div>
  );
};

export default AthenaPatientConfig;
