import { makeStyles } from '@material-ui/core';

const styles = {
  mainContainer: {
    margin: 20,
    padding: 20,
  },
  actionIcon: {
    margin: 'auto',
    marginTop: 20,
    height: 50,
    width: 50,
  },
  saveButton: {
    backgroundColor: '#F0F1F9',
    marginTop: 8,
  },
};

export const useStyles = makeStyles(styles);
