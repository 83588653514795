import { makeStyles, Theme } from "@material-ui/core";

const styles: any = (theme: Theme) => ({
  action: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
  },
  addIconSquare: {
    margin: ".2rem",
    textAlign: "center",
    cursor: "pointer",
    color: "#bdbdbd",
    border: "1px dashed #bdbdbd",
    height: 75,
    width: 75,
  },
  addIconStyle: {
    fontSize: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  actionPopover: {
    padding: 10,
    marginTop: 30,
    backgroundColor: "white",
    zIndex: 10,
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    position: "fixed",
    top: 0,
    width: 420,
    // right: "10%",
  },
  popOverActions: {
    height: 50,
    width: 50,
    padding: 5,
    cursor: "pointer",
  },
  grayPopOverActions: {
    height: 50,
    width: 50,
    padding: 5,
    filter: "grayscale(100%)",
    opacity: "0.3",
  },
  selectedSound: {
    paddingLeft: 10,
    fontWeight: "bold",
    color: "#4FAC48",
    cursor: "cursor",
  },
  nonSelectedSound: {
    paddingLeft: 10,
    cursor: "pointer",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  playArrow: {
    cursor: "pointer",
  },
  textField: {
    width: "100%",
  },
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const useStyles = makeStyles(styles);