import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../../apiconfig';
import { IDepartment } from '../../interfaces/interfaces';

export const useDepartments = (input: {
  orgId: string;
  onCompleted?: (departments: IDepartment[]) => void;
}): {
  departments: IDepartment[];
  isLoading: boolean;
  refetch: (orgId: string) => void;
  error: string | null;
} => {
  const { orgId, onCompleted = () => {} } = input;
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchDepartments = useCallback(async () => {
    if (!orgId) return Promise.resolve(null);

    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    const URL = API.REACT_APP_API_GETORGDEPARTMENT + orgId;
    const headers = {
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache',
    };

    try {
      const response = await axios.get(URL, { headers });
      setDepartments(response.data.data);
      onCompleted(response.data.data);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        console.log('Unauthorized, attempting to refresh token');
        // You might want to implement the checkRefreshToken function
        // and call it here, or handle the unauthorized case differently
        setError('Unauthorized. Please log in again.');
      } else {
        console.error('Error fetching departments:', err);
        setError('Failed to fetch departments. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    fetchDepartments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]); // This effect runs when orgId changes

  const refetch = useCallback(
    (newOrgId: string) => {
      if (newOrgId) {
        const token = localStorage.getItem('token');
        const URL = API.REACT_APP_API_GETORGDEPARTMENT + newOrgId;
        const headers = {
          Authorization: `Bearer ${token}`,
          Pragma: 'no-cache',
        };

        setIsLoading(true);
        setError(null);

        axios
          .get(URL, { headers })
          .then(response => {
            setDepartments(response.data.data);
            onCompleted(response.data.data);
          })
          .catch(err => {
            if (axios.isAxiosError(err) && err.response?.status === 401) {
              console.log('Unauthorized, attempting to refresh token');
              setError('Unauthorized. Please log in again.');
            } else {
              console.error('Error fetching departments:', err);
              setError('Failed to fetch departments. Please try again.');
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [onCompleted]
  );

  return { departments, isLoading, refetch, error };
};
