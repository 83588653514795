import React, { FC } from "react";
import { IAction } from "../../interfaces/interfaces";
import PlayIcon from "@material-ui/icons/VolumeUp";
import NoSoundIcon from "@material-ui/icons/VolumeOff";

import { Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";

type TProps = {
  action: IAction;
  autoClearActions: IAction[];
  scheduledActions: IAction[];
};

const ActionCard: FC<TProps> = (props) => {
  const { action, autoClearActions = [], scheduledActions = [] } = props;

  const playSound = (soundUrl: string) => {
    // console.log('play sound')
    let audio = new Audio(soundUrl);
    audio.play();
  };

  return (
    <Grid item md={4} xs={4} key={action.id}>
      <Card style={{ height: "100%" }}>
        <CardHeader
          style={{ paddingBottom: 10 }}
          className="printCardHeader"
          avatar={
            <img
              src={action.actionImageUrl}
              alt=""
              style={{
                width: 60,
                height: 60,
              }}
              className="printImage"
            />
          }
          title={
            <div>
              {action.soundUrl && action.soundUrl !== "https://cesiumstorage.blob.core.windows.net/actionsounds/NoSound.mp3" ? (
                <div style={{ fontSize: 18, display: "inline-flex", margin: "auto" }} className="printText">
                  <span style={{ paddingRight: 5 }}>
                    <Tooltip title="Play Sound" placement="top">
                      <IconButton aria-label="Play Sound" onClick={() => playSound(action.soundUrl)} style={{ padding: "0px 0px 4px 0px" }}>
                        <PlayIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </span>
                  <span className="printText printTextActionName" style={{ margin: "auto" }}>
                    {" " + action.actionName}
                  </span>
                </div>
              ) : (
                <div style={{ fontSize: 18 }}>
                  <span className="noprint" style={{ paddingRight: 5 }}>
                    <IconButton aria-label="Play Sound" disabled style={{ padding: "0px 0px 4px 0px" }}>
                      <NoSoundIcon fontSize="small" />
                    </IconButton>
                  </span>
                  <span className="printText printTextActionName" style={{ margin: "auto" }}>
                    {" " + action.actionName}
                  </span>
                </div>
              )}
            </div>
          }
          // subheader={action.isVisible ? 'Visible' : 'Not Visible'}
          // subheader={'Order has been entered and can be prepped to be drawn by MA once Provider finishes with the patient.'}
          subheader={
            action.actionDescription ? (
              <div className="printActionDesc">{action.actionDescription}</div>
            ) : (
              <div className="printActionDesc">No description.</div>
            )
          }
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
            {/* {action.actionDescription ? action.actionDescription : "No description provided."} */}
          </Typography>

          <div>
            {autoClearActions.map((autoClearAction: IAction, index: any) => (
              <div key={index}>
                {autoClearAction.mongoActionId === action.id ? (
                  <div>
                    {autoClearAction.clearOnEnter ? (
                      // Clear when enters
                      <Typography variant="body2" color="textSecondary">
                        <li className="printText">
                          Clears when
                          {autoClearAction.userTypeId === 1 ? " staff enters the room" : null}
                          {autoClearAction.userTypeId === 4 ? " provider enters the room" : null}
                        </li>
                      </Typography>
                    ) : (
                      // Clear when leaves
                      <Typography variant="body2" color="textSecondary">
                        <li className="printText">
                          Clear when
                          {autoClearAction.userTypeId === 1 ? " staff leaves the room" : null}
                          {autoClearAction.userTypeId === 4 ? " provider leaves the room" : null}
                        </li>
                      </Typography>
                    )}
                  </div>
                ) : null}
              </div>
            ))}

            {scheduledActions.map((scheduledAction: IAction) => (
              <div key={scheduledAction.id}>
                {scheduledAction.mongoActionId === action.id ? (
                  <Typography variant="body2" color="textSecondary">
                    <li className="printText">
                      Automatically adds after {scheduledAction.minutesToWait} minutes
                      {scheduledAction.touchStatus === "Alone" ? " alone" : null}
                      {scheduledAction.touchStatus === "Provider" ? " with Provider" : null}
                      {scheduledAction.touchStatus === "Staff" ? " with Staff" : null}
                      {scheduledAction.touchStatus === "Staff" ? " on Hold" : null}
                    </li>
                  </Typography>
                ) : null}
              </div>
            ))}

            <Typography variant="body2" color="textSecondary">
              {action.notifyInRooms ? <li className="printText">Notifies users inside rooms</li> : null}
              {action.addForFinishedVisit ? <li className="printText">Automatically adds when the visit is finished</li> : null}
              {action.addForNewVisit ? <li className="printText">Automatically adds when the patient is roomed</li> : null}
              {action.canAddWithoutPatient ? <li className="printText">Can be added without a patient</li> : null}
              {action.notifyAllTablets ? <li className="printText">Notifies all tablets in the site</li> : null}
              {action.verifyAction ? <li className="printText">Two step confirmation</li> : null}
            </Typography>
          </div>
        </CardContent>
        {/* <CardActions disableSpacing>
                </CardActions> */}
      </Card>
    </Grid>
  );
};

export default ActionCard;
