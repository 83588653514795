import { useContext } from 'react';
import { NotifyContext } from './NotifyContext';

export const useNotify = () => {
  const context = useContext(NotifyContext);
  if (context === undefined) {
    throw new Error('useNotify must be used within a NotifyProvider');
  }
  return context;
};
