import { FC, useEffect, useState } from 'react';

import { getQueryVariable } from '../../utils/utils';
import axios from 'axios';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import AppSearch from '../flowstation/components/AppSearch';

import LogRocket from 'logrocket';

// Redux
import { useSelector } from 'react-redux';
import {
  fetchLoggedInUser,
  fetchOrganizations,
  logout,
} from '../../actions/adminActions';

import { allowSignalR, connection } from '../../socket-manager';

import { canDo } from '../../utils/permissionCheck';

// Componenents
import Applications from './components/application/Applications';
import Campuses from './components/campus/Campuses';
import ClinicMapBuilder from './components/clinicMap/ClinicMapBuilder';
import SyncAdminOptions from './components/syncAdminOptions/SyncAdminOptions';
import Departments from './components/department/Departments';
import Devices from './components/device/Devices';
import Displays from './components/display/Displays';
import EHRMapping from './components/EHRMapping/EHRMapping';
import Instruments from './components/equipment/Equipments';
import Media from './components/Media';
import Organizations from './components/organizations/Organizations';
import PatientContentListContainer from './components/patientContent/PatientContentListContainer';
import Rooms from './components/room/Rooms';
import ServiceLines from './components/serviceLine/ServiceLines';
import Sites from './components/sites/Sites';
import Users from './components/user/Users';

// Material
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LinkIcon from '@material-ui/icons/Link';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import PermMediaIcon from '@material-ui/icons/PermMedia';
// import { ListSubheader } from "@material-ui/core";

// Sidebar
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import AndroidIcon from '@material-ui/icons/Android';
import CampusIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DevicesIcon from '@material-ui/icons/Devices';
import ExitToApp from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import SiteIcon from '@material-ui/icons/LocationCity';
import RoomsIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/People';
import ScannerIcon from '@material-ui/icons/Scanner';
import DepartmentIcon from '@material-ui/icons/Store';

import HelpIcon from '@material-ui/icons/HelpOutline';

import binodark from '../../images/binodark.png';
import synctimesLogoDev from '../../images/synctimes-development.png';
import synctimeslogo from '../../images/synctimes.png';
import loadingIcon from '../../images/synctimesicon.png';

import { API } from '../../apiconfig';

// import ReactGA from "react-ga";

import { orderBy } from 'lodash';
import { shallowEqual, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useOrganizations } from '../../hooks/useOrganizations';
import { IOrganization, TAdminState } from '../../interfaces/interfaces';
import { RootState } from '../../store';
import { lockScreen } from '../../utils/LockScreen';
import Integrations from './components/integration/Integrations';
import { JOYRIDE_STEPS } from './constants';
import JoyrideTooltip from './JoyRide';
import { useStyles } from './styles';
import UserSubscription from './UserSubscription';
import { getCurrentPage } from './utils';

const { Redirect } = require('react-router');
const classNames = require('classnames');

let gaInitialized = false;
let metaData = require('../../metadata.json');

type TProps = {};

const returnToLogin = false;

const Admin: FC<TProps> = () => {
  const classes = useStyles();

  const currentPage = getCurrentPage();

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, UnknownAction>>();
  const { loggedInUserData, errorLoggedInUser, organization, permissions } =
    useSelector((state: { adminData: TAdminState }) => {
      return {
        loggedInUserData: state.adminData.loggedInUserData,
        errorLoggedInUser: state.adminData.errorLoggedInUser,
        organization: state.adminData.organization,
        permissions: state.adminData.permissions,
      };
    }, shallowEqual);

  const { organizations } = useOrganizations({canFetch: canDo(['SyncAdmin'], loggedInUserData)});

  const [orgFilterText, setOrgFilterText] = useState('');
  const [showAppSearch, setShowAppSearch] = useState(false);
  // const [returnToLogin, setReturnToLogin] = useState(false);

  const [open, setOpen] = useState(true);
  const [componentToView, setComponentToView] = useState('');
  const [run, setRun] = useState(false);
  // const [passwordDialog, setPasswordDialog] = useState(false);
  // @ts-ignore
  const [stepIndex, setStepIndex] = useState(0);

  const [showOrganizationList, setShowOrganizationList] = useState(false);

  useEffect(() => {
    let userEmail = localStorage.getItem('email');
    let token = localStorage.getItem('token');

    let lastMockedOrganizationId = null;
    lastMockedOrganizationId = localStorage.getItem('lastMockedOrganizationId');

    if (userEmail && token) {
      dispatch(fetchLoggedInUser(userEmail, token, lastMockedOrganizationId));
    }

    if (!userEmail || !token) {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      console.log('FIRE IT');
      window.location.replace('/login');
    }

    if (getQueryVariable('startTour')) {
      setRun(true);
    }

    if (currentPage !== 'admin') {
      showComponent(currentPage);
      setComponentToView(currentPage);
    } else {
      setComponentToView('organizations');
    }

    // Check if mobile device to determine if sidebar should be open or not
    let isMobileDevice = () => {
      if (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
      ) {
        setOpen(false);
      }
    };
    isMobileDevice();

    window.parent.postMessage(
      {
        event_id: 'update_notification_count',
        data: {
          badgeCount: 0,
        },
      },
      '*'
    );

    if (allowSignalR) {
      connection.on('forceWebAppRefresh', (data: { build: string }) => {
        if (metaData.build !== data.build) {
          window.location.reload();
        }
      });

      connection.on('notifyUser', (data: { showPopup: boolean }) => {
        console.log('%c Notify User', 'background: #4CB946; color: #ffffff');
        // console.log("DATA", data);

        if (data.showPopup) {
          userNotification(data);
        }
      });
      connection.on('lockUser', () => {
        lockScreen();
      });
      connection.on('unlockUser', () => {
        console.log('%c Unlock User', 'background: #4CB946; color: #ffffff');
        window.location.reload(); // Refresh the page
      });
    }

    let incrementTime = () => {
      // console.log("force update");
      forceUpdate();
    };
    setInterval(incrementTime, 10000);
  }, [dispatch, currentPage]);

  const forceUpdate = () => {};

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const showComponent = (comp: string) => {
    setComponentToView(comp);

    // // Update url query param so when user refreshes, it stays on viewed component
    if (window.history.pushState) {
      if (getQueryVariable('newDeviceId')) {
      } else if (getQueryVariable('tenant')) {
      } else {
        let deviceId = getQueryVariable('deviceId');
        let param = '';
        if (deviceId) {
          param = '&deviceId=' + deviceId;
        }

        var newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          '?' +
          comp +
          param;
        window.history.pushState({ path: newurl }, '', newurl);
      }
    }

    if (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    ) {
      setOpen(false);
    }
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const userNotification = (data: any) => {
    // Check if mobile.
    let mobile =
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1;

    // Only display on desktop
    if (!mobile) {
      // Function to fire notification
      let fireNotification = () => {
        // https://developer.mozilla.org/en-US/docs/Web/API/notification
        let myNotification = new Notification('SyncTimes', {
          body: data.message,
          silent: true,
          icon: data.imageUrl,
        });

        myNotification.onclick = function (event) {
          if (data.roomId) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(
              'https://app.synctimes.com/?groupId=' +
                data.deptId +
                '&roomId=' +
                data.roomId,
              '_blank'
            );
          } else {
            if (data.deptId) {
              window.open(
                'https://app.synctimes.com/?groupId=' + data.deptId,
                '_blank'
              );
            } else {
              myNotification.close();
            }
          }
        };
      };

      if (!('Notification' in window)) {
        alert('This browser does not support desktop notification');
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        fireNotification();
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            fireNotification();
          }
        });
      }
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const returnHome = () => {
    localStorage.removeItem('lastVisitedFs');
    localStorage.removeItem('lastVisitedView');
    window.location.replace('/');
  };

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    console.log('type', type);

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      // console.log("INDEX", index);

      if (index === 0) {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        setComponentToView('users');
        setOpen(true);
      } else if (index === 3) {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        setComponentToView('equipment');
        setOpen(true);
      } else {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        setOpen(true);
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log('finish');
      // Need to set our running state to false, so we can restart if we click start again.

      if (status === 'finished') {
        if (getQueryVariable('startTour')) {
          // setState({ run: false });
          window.location.replace('/?finishTour=true');
        }
      }

      setRun(false);
    }

    if (type === 'beacon') {
      setRun(false);
    }
  };

  const mockOrganization = (orgId: string) => {
    localStorage.setItem('lastMockedOrganizationId', orgId);
    window.location.reload();
  };

  const showOrganizations = () => {
    dispatch(fetchOrganizations());

    setShowOrganizationList(true);
  };

  const openPowerReport = () => {};

  if (loggedInUserData.id && gaInitialized === false) {
    console.log('%c Setting GA userId ', 'background: #F9AB00; color: #ffffff');

    // LOG ROCKET
    if (
      window.location.hostname === 'app.synctimes.com' ||
      window.location.hostname === 'cesium-dev.azurewebsites.net' ||
      window.location.hostname === 'cesium.azurewebsites.net'
    ) {
      if (loggedInUserData.mongoOrganizationId) {
        let URL =
          API.REACT_APP_API_ORGANIZATION + loggedInUserData.mongoOrganizationId;

        let token = localStorage.getItem('token');
        let headers = {
          headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
        };

        axios
          .get(URL, headers)
          .then(response => {
            if (response.data.data) {
              console.log('LogRocket Identified');
              LogRocket.identify(loggedInUserData.id, {
                name: loggedInUserData.fullName,
                email: loggedInUserData.userEmail,
                orgName: response.data.data.orgName,
                userType: loggedInUserData.userTypeId,
              });
            }
          })
          .catch(err => {
            console.log('Error: ', err);
          });
      }
    }

    gaInitialized = true;
  }

  let isDev = false;
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'dev.synctimes.com'
  ) {
    isDev = true;
  }

  let isTeamsApp: boolean = false;

  if (
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  ) {
    isTeamsApp = true;
  }

  let alphabetizedOrganizations = orderBy(organizations, 'orgName', 'asc');

  let filteredAlphabetizedOrganizations = alphabetizedOrganizations.filter(
    (org: IOrganization) => {
      // check if orgName includes orgFilterText
      return org.orgName.toLowerCase().includes(orgFilterText.toLowerCase());
    }
  );

  return (
    <div>
      <AppSearch
        showSearch={showAppSearch}
        searchData={[]}
        closeSearch={() => () => {
          setShowAppSearch(false);
        }}
        openPowerReport={() => openPowerReport()}
      />

      {canDo(['SyncAdmin'], loggedInUserData) && (
        <div
          onClick={showOrganizations}
          style={{
            position: 'fixed',
            marginBottom: 10,
            marginLeft: 10,
            color: 'gray',
            bottom: 0,
            zIndex: 10000,
            fontSize: 11,
            cursor: 'pointer',
          }}
        >
          {organization.orgName}
        </div>
      )}

      {showOrganizationList && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginLeft: 10,
            marginBottom: 25,
            zIndex: 10000,
            backgroundColor: 'white',
            border: '1px solid lightgray',
            padding: 10,
            fontSize: 14,
            borderRadius: 5,
            overflow: 'overlay',
            maxHeight: '70vh',
          }}
        >
          {alphabetizedOrganizations.length > 0 ? (
            <div>
              <ClickAwayListener
                onClickAway={() => {
                  setShowOrganizationList(false);
                }}
              >
                <div>
                  {filteredAlphabetizedOrganizations.map(
                    (org: IOrganization, i: number) => {
                      if (org.isActive) {
                        return (
                          <div
                            style={{ cursor: 'pointer' }}
                            key={org.id}
                            className={classes.orgItem}
                            onClick={() => mockOrganization(org.id)}
                          >
                            {org.orgName}
                          </div>
                        );
                      }
                      return null;
                    }
                  )}
                </div>
              </ClickAwayListener>
              <input
                style={{ width: '97%' }}
                autoFocus
                value={orgFilterText}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    mockOrganization(filteredAlphabetizedOrganizations[0].id);
                  }
                }}
                onChange={e => {
                  setOrgFilterText(e.target.value);
                }}
              />
            </div>
          ) : (
            <div>Loading Organizations...</div>
          )}
        </div>
      )}

      {returnToLogin ? <Redirect to="/login" /> : <div />}
      {errorLoggedInUser === false ? (
        <div>
          {loggedInUserData.id && permissions && (
            <div>
              <div className={classes.root}>
                {allowSignalR && (
                  <>
                    {loggedInUserData.id &&
                      connection.state === 'Connected' && (
                        <UserSubscription userInfo={loggedInUserData} />
                      )}
                  </>
                )}
                {canDo(
                  [
                    'View Sites',
                    'View Equipment',
                    'View Displays',
                    'View Users',
                    'View Service Lines',
                    'View Departments',
                    'View Rooms',
                  ],
                  loggedInUserData
                ) ? (
                  <div />
                ) : (
                  <Redirect to="/" />
                )}

                <CssBaseline />

                <AppBar
                  position="absolute"
                  className={classNames(
                    classes.appBar,
                    open && classes.appBarShift
                  )}
                  style={{ zIndex: 1 }}
                >
                  <Toolbar disableGutters={!open} className={classes.toolbar}>
                    <IconButton
                      // color="inherit"
                      aria-label="Open drawer"
                      onClick={handleDrawerOpen}
                      className={classNames(
                        classes.menuButton,
                        open && classes.menuButtonHidden
                      )}
                    >
                      <MenuIcon />
                    </IconButton>

                    <Typography
                      component="h1"
                      variant="h6"
                      noWrap
                      className={classes.title}
                    >
                      <div>Welcome, {loggedInUserData.firstName}</div>
                    </Typography>

                    <Tooltip title="Help me find..." placement="bottom">
                      <IconButton
                        onClick={() => {
                          setShowAppSearch(true);
                        }}
                      >
                        <img alt="" style={{ width: 25 }} src={binodark} />
                      </IconButton>
                    </Tooltip>

                    {window.innerWidth < 1920 ? null : (
                      <Tooltip title="Admin Console Tutorial" placement="left">
                        <IconButton
                          className={'first-step'}
                          onClick={() => setRun(true)}
                        >
                          <HelpIcon style={{ color: 'black' }} />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Tooltip title="Return home" placement="left">
                      <IconButton onClick={returnHome}>
                        <HomeIcon style={{ color: 'black' }} />
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                </AppBar>
                <Drawer
                  variant="permanent"
                  classes={{
                    paper: open
                      ? classes.drawerPaper
                      : classes.drawerPaperClose,
                  }}
                  open={open}
                >
                  <div className={classes.toolbarIcon}>
                    {isTeamsApp ? null : (
                      <img
                        src={isDev ? synctimesLogoDev : synctimeslogo}
                        alt="SyncTimes"
                        style={{ width: '70%', cursor: 'pointer' }}
                        onClick={() => window.location.replace('/')}
                      />
                    )}

                    <IconButton onClick={handleDrawerClose}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </div>
                  <Divider />
                  <List>
                    <div>
                      {canDo(['SyncAdmin'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('organizations')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon />
                          </ListItemIcon>
                          <ListItemText primary="Organizations" />
                        </ListItem>
                      )}

                      {canDo(['SyncAdmin'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('campuses')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <CampusIcon />
                          </ListItemIcon>
                          <ListItemText primary="Campuses" />
                        </ListItem>
                      )}

                      {canDo(['View Sites'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('sites')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <SiteIcon />
                          </ListItemIcon>
                          <ListItemText primary="Sites" />
                        </ListItem>
                      )}

                      {canDo(['View Departments'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('departments')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <DepartmentIcon />
                          </ListItemIcon>
                          <ListItemText primary="Departments" />
                        </ListItem>
                      )}

                      {canDo(['View Rooms'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('rooms')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <RoomsIcon />
                          </ListItemIcon>
                          <ListItemText primary="Rooms" />
                        </ListItem>
                      )}

                      {canDo(['View Users'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem + ' second-step'}
                          button
                          onClick={() => showComponent('users')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon />
                          </ListItemIcon>
                          <ListItemText primary="Users" />
                        </ListItem>
                      )}

                      <Divider />

                      {canDo(['View EHR Mapping'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('ehrmapping')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <ListIcon />
                          </ListItemIcon>
                          <ListItemText primary={'EHR Mapping'} />
                        </ListItem>
                      )}

                      {canDo(['View Equipment'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem + ' fifth-step'}
                          button
                          onClick={() => showComponent('equipment')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <ScannerIcon />
                          </ListItemIcon>
                          <ListItemText primary="Equipment" />
                        </ListItem>
                      )}

                      {canDo(['Tablets'], loggedInUserData) && (
                        <div>
                          <ListItem
                            className={classes.listItem}
                            button
                            onClick={() => showComponent('devices')}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <DevicesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Devices" />
                          </ListItem>
                          <ListItem
                            className={classes.listItem}
                            button
                            onClick={() => showComponent('applications')}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <AndroidIcon />
                            </ListItemIcon>
                            <ListItemText primary="Applications" />
                          </ListItem>
                        </div>
                      )}

                      {canDo(['SyncAdmin'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('media')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <PermMediaIcon />
                          </ListItemIcon>
                          <ListItemText primary="Media" />
                        </ListItem>
                      )}

                      {canDo(['View Displays'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('displays')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <DevicesIcon />
                          </ListItemIcon>
                          <ListItemText primary="Displays" />
                        </ListItem>
                      )}

                      {canDo(['View Service Lines'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('servicelines')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <AssignmentIndIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              organization.serviceLineLabel
                                ? organization.serviceLineLabel
                                : 'Service Lines'
                            }
                          />
                        </ListItem>
                      )}

                      {canDo(['SyncAdmin'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('surveys')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <AssignmentIcon />
                          </ListItemIcon>
                          <ListItemText primary="Patient Content" />
                        </ListItem>
                      )}

                      {canDo(['View Integrations'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('integrations')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <LinkIcon />
                          </ListItemIcon>
                          <ListItemText primary="Integrations" />
                        </ListItem>
                      )}

                      {canDo(['SyncAdmin'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('clinicmapbuilder')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <BuildIcon />
                          </ListItemIcon>
                          <ListItemText primary="Clinic Maps" />
                        </ListItem>
                      )}

                      {canDo(['SyncAdmin'], loggedInUserData) && (
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={() => showComponent('syncadminoptions')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <SettingsIcon />
                          </ListItemIcon>
                          <ListItemText primary="SyncAdmin" />
                        </ListItem>
                      )}
                    </div>
                  </List>

                  <Divider />

                  <List>
                    <ListItem
                      className={classes.listItem}
                      button
                      onClick={() => {
                        localStorage.removeItem('lastVisitedFs');
                        localStorage.removeItem('lastVisitedView');
                        window.location.replace('/');
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItem>

                    <ListItem
                      className={classes.listItem}
                      button
                      onClick={() => onLogout()}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <ExitToApp />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </Drawer>

                <div className={classes.content}>
                  <div className={classes.appBarSpacer} />

                  {componentToView === 'users' ? <Users /> : <div />}
                  {componentToView === 'organizations' &&
                  canDo(['SyncAdmin'], loggedInUserData) ? (
                    <Organizations />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'campuses' &&
                  canDo(['SyncAdmin'], loggedInUserData) ? (
                    <Campuses />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'sites' &&
                  canDo(['View Sites'], loggedInUserData) ? (
                    <Sites />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'devices' &&
                  canDo(['Tablets'], loggedInUserData) ? (
                    <Devices />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'departments' &&
                  canDo(['View Departments'], loggedInUserData) ? (
                    <Departments />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'rooms' &&
                  canDo(['View Rooms'], loggedInUserData) ? (
                    <Rooms />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'applications' &&
                  canDo(['SyncAdmin'], loggedInUserData) ? (
                    <Applications />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'media' &&
                  canDo(['SyncAdmin'], loggedInUserData) ? (
                    <Media />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'displays' &&
                  canDo(['View Displays'], loggedInUserData) ? (
                    <Displays />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'equipment' &&
                  canDo(['View Equipment'], loggedInUserData) ? (
                    <Instruments />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'servicelines' &&
                  canDo(['View Service Lines'], loggedInUserData) ? (
                    <ServiceLines />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'ehrmapping' &&
                  canDo(['View EHR Mapping'], loggedInUserData) ? (
                    <EHRMapping />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'surveys' &&
                  canDo(['SyncAdmin'], loggedInUserData) ? (
                    <PatientContentListContainer />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'integrations' &&
                  canDo(['View Integrations'], loggedInUserData) ? (
                    <Integrations />
                  ) : (
                    <div />
                  )}
                  {componentToView === 'clinicmapbuilder' &&
                  canDo(['SyncAdmin'], loggedInUserData) ? (
                    <ClinicMapBuilder />
                  ) : (
                    <div />
                  )}

                  {componentToView === 'syncadminoptions' &&
                  canDo(['SyncAdmin'], loggedInUserData) ? (
                    <SyncAdminOptions />
                  ) : (
                    <div />
                  )}

                  <div
                    style={{ width: '100%', fontSize: 12, textAlign: 'right' }}
                  >
                    Version: {metaData.build}
                  </div>

                  <Joyride
                    run={run}
                    steps={JOYRIDE_STEPS}
                    callback={handleJoyrideCallback}
                    tooltipComponent={JoyrideTooltip}
                    disableScrolling
                    disableOverlayClose
                    stepIndex={stepIndex} // TODO: VERIFY THIS WORKS
                    showSkipButton
                    hideBackButton
                    continuous
                    spotlightClicks
                    disableScrollParentFix
                    styles={{
                      options: {
                        primaryColor: '#50B848',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '45%',
            left: '50%',
            textAlign: 'center',
            transform: 'translate(-50%, -50%)',
            WebkitTransform: 'translate(-50%, -50%)',
          }}
        >
          <img src={loadingIcon} style={{ height: 300 }} alt="#" />
          <br />
          <div style={{ marginBottom: 10, fontSize: '1.5em' }}>
            Oops! An error has occured in the middle of fetching important data,
            please check your internet connection and refresh your page!
          </div>
          <br />
          <Button variant="contained" color="primary" onClick={reloadPage}>
            Refresh
          </Button>
        </div>
      )}
    </div>
  );
};

export default Admin;
