import { createTheme, makeStyles, Theme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    secondary: {
      main: "#A7D6A4",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
});

const styles: any= (theme: Theme) => ({
  newOrganizationButton: {
    zIndex: 10,
    backgroundColor: "#4FAC48",
    position: "absolute",
    right: theme.spacing(3),
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  head: {
    backgroundColor: "#fafafa",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableCell: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  sortSelect: {
    cursor: "pointer",
  },
  colorSwitchBase: {
    color: "#4FAC48",
    "&$colorChecked": {
      color: "#4FAC48",
      "& + $colorBar": {
        backgroundColor: "#4FAC48",
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const useStyles = makeStyles(styles);