import { Button, Dialog, DialogContent, TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import useLobby from '../../../../hooks/useLobby';
import { useStyles } from '../../styles';
import { TProviderToOverride } from '../../types';

type TProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  providerToOverride: TProviderToOverride;
};

const ProviderOverrideTimeModal: FC<TProps> = props => {
  const { open, onClose, onSuccess = () => {}, providerToOverride } = props;

  const [hoursInEffect, setHoursInEffect] = useState('1');
  const [overrideTime, setOverrideTime] = useState('');

  const { onOverrideTime: processOverrideTime } = useLobby();

  const classes = useStyles();

  const onOverrideTime = async () => {
    await processOverrideTime({
      hoursInEffect: parseInt(hoursInEffect),
      providerToOverride: providerToOverride,
      overrideTime: overrideTime,
    })
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch(error => {
        console.log('Error processing override time', error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogContent>
        <div
          style={{ textAlign: 'center', fontSize: 32, padding: '16px 24px' }}
        >
          Status override for{' '}
          <div style={{ fontWeight: 'bold' }}>
            {providerToOverride?.providerName}
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            maxWidth: 130,
            margin: 'auto',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <TextField
            // inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={hoursInEffect}
            variant="outlined"
            label="Hours in effect"
            type="number"
            onChange={e => {
              setHoursInEffect(e.target.value);
            }}
          />
        </div>

        <div style={{ textAlign: 'center', cursor: 'pointer', fontSize: 24 }}>
          <div
            onClick={() => {
              setOverrideTime('0');
            }}
            style={overrideTime === '0' ? { backgroundColor: 'lightgray' } : {}}
            className="boldHover"
          >
            On Time
          </div>
          <div
            onClick={() => setOverrideTime('5-15')}
            style={
              overrideTime === '5-15' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            5-15
          </div>
          <div
            onClick={() => setOverrideTime('15-25')}
            style={
              overrideTime === '15-25' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            15-25
          </div>
          <div
            onClick={() => setOverrideTime('25-35')}
            style={
              overrideTime === '25-35' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            25-35
          </div>
          <div
            onClick={() => setOverrideTime('35-45')}
            style={
              overrideTime === '35-45' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            35-45
          </div>
          <div
            onClick={() => setOverrideTime('45-55')}
            style={
              overrideTime === '45-55' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            45-55
          </div>
          <div
            onClick={() => setOverrideTime('55+')}
            style={
              overrideTime === '55+' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            55+
          </div>
        </div>

        <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
          <Button
            onClick={onOverrideTime}
            variant="contained"
            color="primary"
            disabled={!hoursInEffect || !overrideTime || hoursInEffect === '0'}
          >
            Update time
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProviderOverrideTimeModal;
