import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton, Chip, Box, Typography, Grid, Paper } from "@material-ui/core";
import { deleteScheduledReport, unsubscribeScheduledReport } from "../emailReportsService";
import { ScheduledEmailReport } from "../emailReportingTypes";
import Tooltip from "@material-ui/core/Tooltip";

interface ScheduledEmailsListCellProps {
  report: ScheduledEmailReport;
  onEditReport: (report: ScheduledEmailReport) => void;
  onRemoveReport: () => void;
  userId: string;
}

export const ScheduledEmailsListCell: React.FC<ScheduledEmailsListCellProps> = ({ report, onEditReport, userId, onRemoveReport }) => {
  const [recurrenceSchedule, setRecurrenceSchedule] = useState<any>({
    recurrencePattern: "Day",
    interval: 1,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    scheduledTime: "9:00",
  });
  const [recurrencePattern, setRecurrencePattern] = useState<string>("Day");
  const [recurrenceInterval, setRecurrenceInterval] = useState<number>(1);
  const [daysOfWeek, setDaysOfWeek] = useState<number[]>([0, 1, 2, 3, 4, 5, 6]);
  const [scheduledTime, setScheduledTime] = useState<string | null>("9:00");
  const [localTimeString, setLocalTimeString] = useState<string | null>(null);
  const [nextScheduledTimeLocal, setNextScheduledTimeLocal] = useState<string | null>(null);
  const [timeZoneAbbreviation, setTimeZoneAbbreviation] = useState<string>("MST");
  const [description, setDescription] = useState<string>("");

  // Utilities
  const dayAbbreviations: { [key: number]: string } = {
    0: "Su",
    1: "Mo",
    2: "Tu",
    3: "We",
    4: "Th",
    5: "Fr",
    6: "Sa",
  };

  useEffect(() => {
    if (report.recurrenceSchedule) {
      setRecurrenceSchedule(report.recurrenceSchedule);
      setRecurrencePattern(report.recurrenceSchedule.recurrencePattern);
      setRecurrenceInterval(report.recurrenceSchedule.interval || 1);
      setDaysOfWeek(report.recurrenceSchedule.daysOfWeek || []);
      setScheduledTime(report.nextScheduledTimeUTC || null);
      setDescription(report.description || "");
  
      const localTime = report.recurrenceSchedule.scheduledTime ? moment.utc(report.nextScheduledTimeUTC).local() : null;
      setLocalTimeString(localTime ? localTime.format("h:mm A") : "");
  
      const currentTimeZone = moment.tz.guess();
      setNextScheduledTimeLocal(
        report.nextScheduledTimeUTC ? moment.utc(report.nextScheduledTimeUTC).local().format("M/D/YY") : "No future occurrences"
      );
      setTimeZoneAbbreviation(moment.tz(currentTimeZone).zoneAbbr());
    }
  }, [report]);

  // Event Handlers
  const handleDeleteTapped = async (scheduledReport: ScheduledEmailReport) => {
    if (!scheduledReport.id) {
      console.error("No scheduled report ID found");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      await deleteScheduledReport(token, scheduledReport.id);
      onRemoveReport();
      console.log("Scheduled report deleted successfully");
    } catch (err) {
      console.error("Error deleting scheduled report:", err);
    }
  };

  const handleUnsubTapped = async (scheduledReport: any) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      await unsubscribeScheduledReport(token, scheduledReport.id, userId);
      onRemoveReport();
      console.log("Unsubscribed from scheduled report successfully");
    } catch (err) {
      console.error("Error unsubscribing from scheduled report:", err);
    }
  };

  const handleEditTapped = () => {
    onEditReport(report);
  };

  return (
    <Paper elevation={2} style={{ padding: "16px", marginBottom: "16px" }}>
      {/* Top Row */}
      <div style={{ marginBottom: "16px" }}>
        {/* Description or Report Title */}
        <Typography variant="h6" gutterBottom>
          {description || report.reportName}
        </Typography>
        {description && (
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Report: {report.reportName}
          </Typography>
        )}
        {/* Recipients */}
        <Box display="flex" flexWrap="wrap">
          {[...(report.emailSettings?.to || []), ...(report.emailSettings?.cc || [])].slice(0, 20).map((email, index) => (
            <Chip key={index} label={email} size="small" style={{ marginRight: "4px", marginBottom: "4px" }} />
          ))}
          {[...(report.emailSettings?.to || []), ...(report.emailSettings?.cc || [])].length > 20 && (
            <Typography variant="body2" color="textSecondary">
              ...
            </Typography>
          )}
        </Box>
      </div>
      {/* Bottom Row */}
      <Grid container justify="space-between" alignItems="flex-end">
        {/* Recurrence Info */}
        <Grid item xs={8}>
          {recurrenceSchedule ? (
            <div>
              <Typography>
                {recurrencePattern === "Day" && recurrenceInterval === 1
                  ? "Occurs daily"
                  : recurrencePattern === "Week" && recurrenceInterval === 1
                  ? "Occurs every week on:"
                  : recurrencePattern === "Month" && recurrenceInterval === 1
                  ? "Occurs monthly"
                  : `Occurs every ${recurrenceInterval} ${recurrencePattern.toLowerCase()}(s)`}
              </Typography>
              {recurrencePattern !== "Day" && (
                <Box display="flex" paddingTop="4px">
                  {daysOfWeek.map((day: number) => (
                    <Chip
                      key={day}
                      label={dayAbbreviations[day]}
                      size="small"
                      style={{
                        backgroundColor: "#50b848",
                        color: "white",
                        marginRight: "4px",
                      }}
                    />
                  ))}
                </Box>
              )}
              {scheduledTime && (
                <Typography variant="body2" style={{ fontStyle: "italic", paddingTop: "8px" }}>
                  Next Scheduled: {nextScheduledTimeLocal}
                </Typography>
              )}
              <Typography>
                at {localTimeString} ({timeZoneAbbreviation})
              </Typography>
            </div>
          ) : null}
        </Grid>
        {/* Button Cluster */}
        <Grid item>
          <Grid container alignItems="center" justify="flex-end">
            <Tooltip title="Edit">
              <IconButton color="primary" onClick={handleEditTapped} style={{ marginRight: 10 }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            {report.isOwner ? (
              <Tooltip title="Delete">
                <IconButton onClick={() => handleDeleteTapped(report)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Unsubscribe">
                <IconButton onClick={() => handleUnsubTapped(report)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ScheduledEmailsListCell;
