type BloodType = 'A+' | 'A-' | 'B+' | 'B-' | 'AB+' | 'AB-' | 'O+' | 'O-';

type MedicalCondition = string;

type VisitReason = string;

interface Patient {
  name: string;
  email: string;
  phone: string;
  address: string;
  dateOfBirth: string;
  age: number;
  bloodType: BloodType;
  height: number;
  weight: number;
  condition: MedicalCondition;
  patientId: number;
  visitReason: VisitReason;
}

class FakePatientGenerator {
  private firstNames: string[];
  private lastNames: string[];
  private cities: string[];
  private states: string[];
  private streetNames: string[];
  private bloodTypes: BloodType[];
  private conditions: MedicalCondition[];
  private visitReasons: VisitReason[];

  constructor() {
    this.firstNames = [
      'John', 'Jane', 'Mike', 'Emily', 'David', 'Sarah', 'James', 'Emma', 'Daniel', 'Olivia',
      'William', 'Sophia', 'Joseph', 'Isabella', 'Charles', 'Mia', 'Thomas', 'Charlotte', 'Henry',
      'Amelia', 'George', 'Harper', 'Alexander', 'Evelyn', 'Benjamin', 'Abigail', 'Michael',
      'Elizabeth', 'Christopher', 'Sofia', 'Matthew', 'Avery', 'Andrew', 'Ella', 'Ethan',
      'Scarlett', 'Daniel', 'Grace', 'Anthony', 'Chloe', 'Joshua', 'Victoria', 'Christopher',
      'Aria', 'Ryan', 'Madison', 'Nicholas', 'Lily', 'Tyler', 'Hannah'
    ];
    this.lastNames = [
      'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez',
      'Martinez', 'Hernandez', 'Lopez', 'Gonzalez', 'Wilson', 'Anderson', 'Thomas', 'Taylor', 'Moore',
      'Jackson', 'Martin', 'Lee', 'Perez', 'Thompson', 'White', 'Harris', 'Sanchez', 'Clark',
      'Ramirez', 'Lewis', 'Robinson', 'Walker', 'Young', 'Allen', 'King', 'Wright', 'Scott',
      'Torres', 'Nguyen', 'Hill', 'Flores', 'Green', 'Adams', 'Nelson', 'Baker', 'Hall', 'Rivera',
      'Campbell', 'Mitchell', 'Carter', 'Roberts'
    ];
    this.cities = [
      'New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix', 'Philadelphia', 'San Antonio',
      'San Diego', 'Dallas', 'San Jose', 'Austin', 'Jacksonville', 'Fort Worth', 'Columbus', 'San Francisco',
      'Charlotte', 'Indianapolis', 'Seattle', 'Denver', 'Washington', 'Boston', 'El Paso', 'Detroit',
      'Nashville', 'Portland', 'Memphis', 'Oklahoma City', 'Las Vegas', 'Louisville', 'Baltimore',
      'Milwaukee', 'Albuquerque', 'Tucson', 'Fresno', 'Sacramento', 'Mesa', 'Kansas City', 'Atlanta',
      'Long Beach', 'Omaha', 'Raleigh', 'Miami', 'Oakland', 'Minneapolis', 'Tulsa', 'Cleveland',
      'Wichita', 'Arlington', 'New Orleans', 'Bakersfield'
    ];
    this.states = [
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
      'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
      'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT',
      'VA', 'WA', 'WV', 'WI', 'WY'
    ];
    this.streetNames = [
      'Main', 'Oak', 'Pine', 'Maple', 'Cedar', 'Elm', 'Washington', 'Lake', 'Hill', 'Walnut',
      'Park', 'Cherry', 'Spruce', 'Market', 'Broad', 'Center', 'River', 'Sunset', 'Valley',
      'Creek', 'Ridge', 'Spring', 'Willow', 'Forest', 'Meadow', 'Birch', 'Ash', 'Beach', 'Church',
      'Dogwood', 'Hickory', 'Highland', 'Lakeview', 'Magnolia', 'Orchard', 'Poplar', 'Rose',
      'Sycamore', 'Woodland', 'Chestnut', 'Cypress', 'Evergreen', 'Laurel', 'Mulberry', 'Myrtle',
      'Peachtree', 'Pinecrest', 'Redwood', 'Sequoia', 'Willow'
    ];
    this.bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
    this.conditions = [
      'Hypertension', 'Diabetes', 'Asthma', 'Arthritis', 'Depression', 'Anxiety', 'Obesity',
      'Allergies', 'Hypothyroidism', 'GERD', 'Migraine', 'Osteoporosis', 'Chronic Pain',
      'Sleep Apnea', 'COPD', 'Eczema', 'Psoriasis', 'Acne', 'Rosacea', 'Celiac Disease',
      'Crohn\'s Disease', 'Ulcerative Colitis', 'Fibromyalgia', 'Multiple Sclerosis', 
      'Parkinson\'s Disease', 'Alzheimer\'s Disease', 'Epilepsy', 'Glaucoma', 'Cataracts', 'Tinnitus'
    ];

    this.visitReasons = [
      'Annual physical exam',
      'Flu-like symptoms',
      'Persistent cough',
      'Skin rash',
      'Back pain',
      'Headache',
      'Abdominal pain',
      'High blood pressure check',
      'Diabetes management',
      'Vaccination',
      'Allergies',
      'Eye exam',
      'Dental cleaning',
      'Toothache',
      'Cavity filling',
      'Sore throat',
      'Ear pain',
      'Routine blood work',
      'Pregnancy check-up',
      'Depression or anxiety',
      'Sports injury',
      'Chest pain',
      'Difficulty sleeping',
      'Urinary tract infection',
      'Routine cancer screening',
    ];
  }

  private randomElement<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
  }

  randomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  firstName(): string {
    return this.randomElement(this.firstNames);
  }

  lastName(): string {
    return this.randomElement(this.lastNames);
  }

  name(): string {
    return `${this.randomElement(this.firstNames)} ${this.randomElement(
      this.lastNames
    )}`;
  }

  email(name: string): string {
    const [firstName, lastName] = name.toLowerCase().split(' ');
    return `${firstName}.${lastName}@example.com`;
  }

  phone(): string {
    return `(${this.randomNumber(100, 999)}) ${this.randomNumber(
      100,
      999
    )}-${this.randomNumber(1000, 9999)}`;
  }

  address(): string {
    const number = this.randomNumber(100, 9999);
    const street = `${this.randomElement(this.streetNames)} St`;
    const city = this.randomElement(this.cities);
    const state = this.randomElement(this.states);
    const zip = this.randomNumber(10000, 99999);
    return `${number} ${street}, ${city}, ${state} ${zip}`;
  }

  dateOfBirth(): Date {
    const start = new Date(1940, 0, 1).getTime();
    const end = new Date(2005, 11, 31).getTime();
    return new Date(start + Math.random() * (end - start));
  }

  bloodType(): BloodType {
    return this.randomElement(this.bloodTypes);
  }

  height(): number {
    return this.randomNumber(150, 200); // in cm
  }

  weight(): number {
    return this.randomNumber(45, 120); // in kg
  }

  condition(): MedicalCondition {
    return this.randomElement(this.conditions);
  }

  generatePatient(): Patient {
    const name = this.name();
    const dob = this.dateOfBirth();
    return {
      name: name,
      email: this.email(name),
      phone: this.phone(),
      address: this.address(),
      dateOfBirth: dob.toISOString().split('T')[0],
      age: new Date().getFullYear() - dob.getFullYear(),
      bloodType: this.bloodType(),
      height: this.height(),
      weight: this.weight(),
      condition: this.condition(),
      patientId: this.randomNumber(10000, 9999999),
      visitReason: this.visitReason() 
    };
  }

  visitReason(): VisitReason {
    return this.randomElement(this.visitReasons);
  }
}

export default FakePatientGenerator;
