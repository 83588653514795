import Fuse from 'fuse.js';
import { IUser } from '../../../../interfaces/interfaces';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['fullName', 'userEmail', 'badgeString'],
};

export const filterUsers = (input: {
  users: IUser[];
  filterString: string;
}): IUser[] => {
  const { users, filterString } = input;
  const fuse = new Fuse(users, fuseOptions);
  const filteredUsers: any[] = (fuse.search(filterString) ?? []).map(
    result => result.item
  );

  return filteredUsers;
};
