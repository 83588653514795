import React, { Component } from "react";
import { connect } from "react-redux";
import { API } from "../../../apiconfig";
import { fetchSounds } from "../../../actions/adminActions";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { ISound } from "../../../interfaces/interfaces";

import { checkRefreshToken } from "../../../utils/utils";

const styles: {} = {
  card: {
    maxWidth: 1000,
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  playArrow: {
    cursor: "pointer",
  },
  soundName: {
    paddingTop: 4,
  },
};

interface SoundsProps {
  classes: any;
  allSounds: ISound[];
  fetchSounds: () => void;
}

class Sounds extends Component<SoundsProps> {
  componentDidMount() {
    this.props.fetchSounds();
  }

  selectedSoundFile = (event: any) => {
    let fileName = event.target.files[0].name;

    if (event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        let sound = new Audio();
        sound.src = event.target.result;

        console.log("check", sound.src.split("base64,").pop());

        let payload = {
          soundName: fileName,
          soundByte: sound.src.split("base64,").pop(),
        };

        console.log("payload", payload);

        let token = localStorage.getItem("token");

        fetch(API.REACT_APP_API_CREATESOUND, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => {
            console.log("res", res);
            this.props.fetchSounds();
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      };
    }
  };

  previewSound = (url: string) => {
    let audio = new Audio(url);
    audio.play();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Card className={classes.card}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Sounds
            </Typography>
            {this.props.allSounds.map((sound) => (
              <div key={sound.id}>
                <div className={classes.flexContainer}>
                  <div onClick={() => this.previewSound(sound.soundUrl)} className={classes.playArrow}>
                    <PlayArrow />
                  </div>
                  <div className={classes.soundName}>{sound.soundName}</div>
                </div>
              </div>
            ))}

            <Typography style={{ marginTop: 25 }}>Upload Sound</Typography>

            <input id="upload-sound-file" type="file" onChange={this.selectedSoundFile} />
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: { adminData: any }) => ({
  allSounds: state.adminData.allSounds,
});

export default connect(mapStateToProps, { fetchSounds })(withStyles(styles)(Sounds));
