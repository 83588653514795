export const PATIENT_CONTENT_TEMPLATE: any = {
  patientContentName: '',
  patientContentDescription: '',
  patientContentType: 'Education',
  patientEducationUrl: '',
  showBeforeProvider: false,
  showAfterProvider: false,
  showAsQrCode: false,
  showInResources: false,
  isActive: true,
  surveyQuestions: [],
};
