import { Button, Dialog } from '@material-ui/core';
import { detect } from 'detect-browser';
import { FC, useState } from 'react';
import { useDepartment } from '../../../../../../hooks/department/useDepartment';
import { IAction, IDepartment } from '../../../../../../interfaces/interfaces';
import DndActionContainer from '../DndActionContainer';
import { useStyles } from './styles';

const Browser = detect();

type TProps = {
  department: IDepartment;
  open: boolean;
  showVisibleActions: boolean;
  onClose: () => void;
  modifyingMultipleDepts?: boolean;
  updateLocalDeptActions: (dept: IDepartment) => void;
  onSuccess: (updatedDepartment: IDepartment) => void;
};

const ActionModifyDialog: FC<TProps> = props => {
  const {
    open,
    department,
    onClose = () => { },
    showVisibleActions,
    modifyingMultipleDepts,
    updateLocalDeptActions,
    onSuccess,
  } = props;

  const classes = useStyles();

  const { update } = useDepartment({
    departmentId: department?.id,
  });

  const [departmentToEdit, setDepartmentToEdit] =
    useState<IDepartment>(department);

  const closeDialog = () => {
    onClose();
  };

  const onSetActions = (actions: IAction[]): Promise<IDepartment> => {
    const updatedActions = actions.map(action => ({
      ...action,
      playSoundOnFlowstation: action.playSoundOnFlowstation || false,
    }));

    return new Promise(resolve => {
      setDepartmentToEdit({
        ...department,
        actionsList: updatedActions,
      });
      resolve({
        ...department,
        actionsList: updatedActions,
      });
    });
  };

  const onSubmit = () => {
    let payload: IDepartment = {
      ...department,
      ...departmentToEdit,
    };

    if (modifyingMultipleDepts) {
      updateLocalDeptActions(payload);
      closeDialog();
    } else {
      update({
        departmentId: department.id,
        data: payload,
      }).then(updatedDepartment => {
        console.log('ActionModifyDialog onSubmit', { updatedDepartment });

        onSuccess(updatedDepartment);
        closeDialog();
      });
    }
  };

  let fullWidth = false;
  let browser = Browser;
  if (browser) {
    if (browser.name === 'ie') {
      fullWidth = true;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      // maxWidth={browser ? (browser.name === "ie" ? "md" : "lg") : "lg"}
      maxWidth={false}
      // style={{ maxWidth: "" }}
      fullWidth={fullWidth}
    >
      {department.actionsList ? (
        <div className={classes.mainContainer}>
          {showVisibleActions ? (
            <div>
              <h2>Modify Actions</h2>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)', paddingBottom: 8 }}>
                Tip: Drag actions to arrange position
              </div>
            </div>
          ) : (
            <div>
              <h2>Modify Hidden Actions</h2>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)', paddingBottom: 8 }}>
                Tip: Drag actions to arrange position
              </div>
            </div>
          )}
          <div>
            <DndActionContainer
              department={departmentToEdit}
              onSetActions={value => onSetActions(value)}
              showVisibleActions={showVisibleActions}
            // updateLocalDeptActions={(dept: IDepartment) =>
            //   updateLocalDeptActions(dept)
            // }
            />
            <Button
              className={classes.saveButton}
              color="primary"
              onClick={() => onSubmit()}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div />
      )}
    </Dialog>
  );
};

export default ActionModifyDialog;
