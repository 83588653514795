import axios from 'axios';
import moment from 'moment-timezone';
import { createRef, FC, useState } from 'react';
import { API } from '../../../../apiconfig';

import { useDispatch } from 'react-redux';
import { logAction } from '../../../../actions/adminActions';

// import { detect } from "detect-browser";

import { canDo } from '../../../../utils/permissionCheck';

import { checkRefreshToken } from '../../../../utils/utils';

// import greencheck10 from "../../../../images/green10.png";
import greencheck10 from '../../../../images/green10.png';
import greencheck15 from '../../../../images/green15.png';
import greencheck5 from '../../../../images/green5.png';
import greencheck from '../../../../images/greencheckmark.png';
import redcheck from '../../../../images/redxmark.png';

// Material
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import ReactGA from 'react-ga';

import { allowSignalR } from '../../../../socket-manager';

// @ts-ignore
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UnknownAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  IAction,
  IDepartment,
  IRoom,
  ISound,
  IUser,
} from '../../../../interfaces/interfaces';
import { RootState } from '../../../../store';

// @ts-ignore
import noSound from '../../../../sounds/static.mp3';
import { theme, useStyles } from './styles';
import ActionResponse from './response/ActionResponse';

const { Howl } = require('howler');

const _ = require('lodash');
// let mouseIsDown = false;
// let clearAction = true;
// let timeOut: any;

let playedSound = false;
// let reachedZero = false;
let lastPlayedSound: any = null;

// const Browser = detect();

interface ActionsListProps {
  actionData: IAction;
  loggedInUserData: IUser;
  cardData: IRoom;
  authenticated: boolean;
  disableCards: boolean;
  groupData: IDepartment;
  timeZone: any;
  flashActionBySound: any;
  loadedSoundObjects: any;
  selectedHistoryTime: any;

  refetchFsData: () => void;
  serverTimeOffset: any;
  mute: boolean;
  mutedActions: string[];
}

const ActionsList: FC<ActionsListProps> = props => {
  const {
    actionData,
    loggedInUserData,
    cardData,
    disableCards,
    groupData,
    timeZone,
    flashActionBySound,
    loadedSoundObjects,
    selectedHistoryTime,
    refetchFsData,
    serverTimeOffset,
    mute,
    mutedActions,
  } = props;

  const classes = useStyles();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, UnknownAction>>();
  // const [showAlert, setShowAlert] = useState(false);
  const [hover, setHover] = useState(false);
  const [showActionOptions, setShowActionOptions] = useState(false);
  // const [enteredHoverArea, setEnteredHoverArea] = useState(false);
  const [newChecklistItemIsCleared, setNewChecklistItemIsCleared] =
    useState(false);
  const [newChecklistItemName, setNewChecklistItemName] = useState('');
  const [updatedCheckListItemName, setUpdatedCheckListItemName] = useState('');
  const [updatedCheckListItemIndex, setUpdatedCheckListItemIndex] =
    useState(-1);
  // const [actionRef, setActionRef] = useState();
  const [checklistKey, setChecklistKey] = useState(0);

  const actionRef = createRef<any>();

  const showHover = () => {
    setHover(true);
  };

  const hideHover = () => {
    console.log('hide hover');
    // clickAwaySaveNewChecklistItem();
    // handleCloseCheckListItem();
    setHover(false);
  };

  const hideHoverWithoutSave = () => {
    setHover(false);
  };

  const clearIcon = () => {
    console.log('Remove action,', actionData.actionName);
    if (!disableCards) {
      if (
        groupData.orgId === loggedInUserData.mongoOrganizationId ||
        canDo(['SyncAdmin'], loggedInUserData)
      ) {
        ReactGA.event({
          category: 'Flowstation',
          action: 'Removed action',
          label: actionData.actionName,
        });

        let token = localStorage.getItem('token');

        let data = {
          roomId: cardData.id,
          actionId: actionData.id,
        };

        console.log('data', data);

        // console.log("clear icon", data);
        // console.log("card data", actionData);

        axios({
          method: 'post',
          url: API.REACT_APP_API_CLEARICON,
          data,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
          .then(response => {
            // console.log("Success", response);
            if (!allowSignalR) {
              refetchFsData();
            }
            setShowActionOptions(false);
          })
          .catch(err => {
            if (err.response) {
              console.log('check it', err.response?.status);
              if (err.response?.status === 401) {
                checkRefreshToken();
                // localStorage.removeItem("token");
                // localStorage.removeItem("email");
                // window.location.replace("/login");
              }
            }
            console.log('Error removing action', err);
          });
      } else {
        // setShowAlert(true);
      }
    }
  };

  const flashAction = () => {
    // ReactGA.event({
    //   category: "Flowstation",
    //   action: "Added action",
    //   label: "Added action",
    // });

    let data = {
      roomId: cardData.id,
      actionId: actionData.id,
      isFlashing: true,
    };

    if (data.roomId && data.actionId) {
      let token = localStorage.getItem('token');
      // console.log("Payload", data);

      axios({
        method: 'post',
        url: API.REACT_APP_API_FLASHACTION,
        data,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          if (!allowSignalR) {
            refetchFsData();
          }

          setShowActionOptions(false);

          // console.log("GOOD!", response);
        })
        .catch(err => {
          console.log('Error sending action', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  // mouseDown = (actionData: IAction) => {
  //   if (canDo(["Update Flowstation"], loggedInUserData)) {
  //     mouseIsDown = true;
  //     clearAction = true;
  //     timeOut = setTimeout(() => {
  //       if (mouseIsDown) {
  //         // Mouse held down for > 2 seconds
  //         clearAction = false;
  //         // console.log("FLASH Action", actionData);
  //         flashAction(actionData);
  //       }
  //     }, 1000);
  //   }
  // };

  // mouseUp = () => {
  //   if (canDo(["Update Flowstation"], loggedInUserData)) {
  //     // console.log("clear action?", clearAction);
  //     clearTimeout(timeOut); // Clear timeout interval for mouseDown event.
  //     mouseIsDown = false;
  //     if (clearAction) {
  //       // console.log("REMOVE Action", actionData);
  //       clearIcon();
  //     }
  //   }
  // };

  const playSound = (soundUrl: string) => {
    // console.log("Play sound", soundUrl);
    // console.log("All loaded sounds", loadedSoundObjects);

    // let isIos: boolean = false;
    // if (Browser?.name === "ios" || Browser?.name === "crios" || Browser?.name === "ios-webview") {
    //   isIos = true;
    // }

    if (!mute) {
      console.log('Playing static');
      let audio = new Howl({
        src: [noSound],
      });

      audio.play();

      setTimeout(() => {
        if (soundUrl) {
          let actionSound = new Howl({
            src: [soundUrl],
          });

          actionSound.play();
        }
      }, 800);
    }
  };

  const openActionOptions = () => {
    // console.log("CLICK");
    if (canDo(['Update Flowstation'], loggedInUserData)) {
      setShowActionOptions(true);
    }
  };

  const respondToAction = (responseType: number) => {
    dispatch(
      logAction('Action Response', responseType.toString(), loggedInUserData.id)
    );

    // let payload = {
    //   roomId: cardData.id,
    //   actionId: actionData.id,
    //   responseType: responseType,
    // };

    let token = localStorage.getItem('token');

    if (
      _.find(actionData.actionResponses, {
        userId: loggedInUserData.id,
        responseType: responseType,
      })
    ) {
      let payload = {
        roomId: cardData.id,
        actionId: actionData.id,
      };

      axios({
        method: 'POST',
        url: API.REACT_APP_API_CLEARACTIONRESPONSE,
        data: payload,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          console.log('response!', response);
          setShowActionOptions(false);
        })
        .catch(err => {
          if (err.response) {
            console.log('check it', err.response?.status);
            if (err.response?.status === 401) {
              checkRefreshToken();
              // localStorage.removeItem("token");
              // localStorage.removeItem("email");
              // window.location.replace("/login");
            }
          }
          console.log('Err', err);
        });
    } else {
      // ADD ACTION RESPONSE
      let payload = {
        roomId: cardData.id,
        actionId: actionData.id,
        responseType: responseType,
      };

      axios({
        method: 'POST',
        url: API.REACT_APP_API_ADDACTIONRESPONSE,
        data: payload,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          console.log('response!', response);
          setShowActionOptions(false);
        })
        .catch(err => {
          console.log('Err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const handleCloseCheckListItem = () => {
    if (updatedCheckListItemName || updatedCheckListItemIndex !== -1) {
      console.log('SUBMIT!', updatedCheckListItemIndex);

      let payload = {
        ...cardData,
      };
      payload.roomActions.forEach(action => {
        if (action.id === actionData.id) {
          // action.actionChecklist[index] = item;
          console.log('FOUND IT', action);
          // action.isCleared = !action.isCleared;
          action.actionChecklist[updatedCheckListItemIndex].itemName =
            updatedCheckListItemName;
        }
      });

      console.log('updated payload', payload);
      let token = localStorage.getItem('token');
      axios({
        method: 'POST',
        url: API.REACT_APP_API_UPDATEACTIONCHECKLIST,
        data: payload,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          console.log('response!', response);
          setUpdatedCheckListItemName('');
          setUpdatedCheckListItemIndex(-1);
        })
        .catch(err => {
          console.log('Err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const handleClickAwayCheckListItem = (i: any) => {
    if (
      (updatedCheckListItemName || updatedCheckListItemIndex !== -1) &&
      updatedCheckListItemIndex === i
    ) {
      console.log('SUBMIT!', i);

      let payload = {
        ...cardData,
      };
      payload.roomActions.forEach(action => {
        if (action.id === actionData.id) {
          // action.actionChecklist[index] = item;
          console.log('FOUND IT', action);
          // action.isCleared = !action.isCleared;
          action.actionChecklist[i].itemName = updatedCheckListItemName;
        }
      });

      console.log('updated payload', payload);
      let token = localStorage.getItem('token');
      axios({
        method: 'POST',
        url: API.REACT_APP_API_UPDATEACTIONCHECKLIST,
        data: payload,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          console.log('response!', response);
          setUpdatedCheckListItemName('');
          setUpdatedCheckListItemIndex(-1);
        })
        .catch(err => {
          console.log('Err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const keyPressCheckListItem = (e: any, i: any) => {
    if (e.keyCode === 13) {
      // update checklist item
      handleClickAwayCheckListItem(i);
    }
  };

  const clickAwaySaveNewChecklistItem = () => {
    if (newChecklistItemName.length > 0) {
      console.log('ENTER', newChecklistItemName, newChecklistItemIsCleared);
      // REACT_APP_API_UPDATEACTIONCHECKLIST
      console.log('Room data', cardData);
      let payload = {
        ...cardData,
      };
      payload.roomActions.forEach(action => {
        if (action.id === actionData.id) {
          action.actionChecklist.push({
            itemName: newChecklistItemName,
            isCleared: newChecklistItemIsCleared,
          });
        }
      });

      let token = localStorage.getItem('token');

      setNewChecklistItemName('');
      setChecklistKey(checklistKey + 1);

      axios({
        method: 'POST',
        url: API.REACT_APP_API_UPDATEACTIONCHECKLIST,
        data: payload,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          console.log('response!', response);

          setNewChecklistItemName('');
          setNewChecklistItemIsCleared(false);
        })
        .catch(err => {
          console.log('Err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const keyPress = (e: any) => {
    // Handle enter key to create new checklist item
    // console.log("E", e);
    if (e.keyCode === 13) {
      console.log('ENTER', newChecklistItemName, newChecklistItemIsCleared);
      // REACT_APP_API_UPDATEACTIONCHECKLIST
      console.log('Room data', cardData);
      let payload = {
        ...cardData,
      };
      payload.roomActions.forEach(action => {
        if (action.id === actionData.id) {
          action.actionChecklist.push({
            itemName: newChecklistItemName,
            isCleared: newChecklistItemIsCleared,
          });
        }
      });

      let token = localStorage.getItem('token');

      setNewChecklistItemName('');
      setChecklistKey(checklistKey + 1);

      axios({
        method: 'POST',
        url: API.REACT_APP_API_UPDATEACTIONCHECKLIST,
        data: payload,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          logAction('Created Checklist Item', '', loggedInUserData.id);
          console.log('response!', response);
          setNewChecklistItemName('');
          setNewChecklistItemIsCleared(false);
        })
        .catch(err => {
          console.log('Err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const deleteChecklistItem = (i: any) => {
    logAction('Deleted Checklist Item', '', loggedInUserData.id);

    setUpdatedCheckListItemName('');
    setUpdatedCheckListItemIndex(-1);

    // console.log("DELETE", i);
    let payload = {
      ...cardData,
    };
    payload.roomActions.forEach(action => {
      if (action.id === actionData.id) {
        action.actionChecklist.splice(i, 1);
      }
    });

    let token = localStorage.getItem('token');
    axios({
      method: 'POST',
      url: API.REACT_APP_API_UPDATEACTIONCHECKLIST,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('response!', response);
      })
      .catch(err => {
        console.log('Err', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const updateChecklistItem = (item: any, index: number) => {
    logAction('Updated Checklist Item', '', loggedInUserData.id);
    console.log('Update checklist item', item, index);
    let payload = {
      ...cardData,
    };
    payload.roomActions.forEach(action => {
      if (action.id === actionData.id) {
        // action.actionChecklist[index] = item;
        console.log('FOUND IT', action);
        // action.isCleared = !action.isCleared;
        action.actionChecklist[index].isCleared =
          !action.actionChecklist[index].isCleared;
      }
    });

    console.log('updated payload', payload);

    let token = localStorage.getItem('token');
    axios({
      method: 'POST',
      url: API.REACT_APP_API_UPDATEACTIONCHECKLIST,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('response!', response);
      })
      .catch(err => {
        console.log('Err', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const updateChecklistItemName = (e: any, index: number) => {
    setUpdatedCheckListItemName(e.target.value);
    setUpdatedCheckListItemIndex(index);

    // console.log("Update checklist item name", item, index);
    // let payload = {
    //   ...cardData,
    // };
    // payload.roomActions.forEach((action) => {
    //   if (action.id === actionData.id) {
    //     // action.actionChecklist[index] = item;
    //     console.log("FOUND IT", action);
    //     // action.isCleared = !action.isCleared;
    //     action.actionChecklist[index].itemName = e.target.value;
    //   }
    // });

    // console.log("updated payload", payload);

    // let token = localStorage.getItem("token");
    // axios({
    //   method: "POST",
    //   url: API.REACT_APP_API_UPDATEACTIONCHECKLIST,
    //   data: payload,
    //   headers: {
    //     Authorization: "Bearer " + token,
    //   },
    // })
    //   .then((response) => {
    //     console.log("response!", response);
    //   })
    //   .catch((err) => {
    //     console.log("Err", err);
    //   });
  };

  // console.log("action data", actionData);
  let timerValue: any = '00:00';
  let flashTimer = false;

  let futureTimeForCountdown = moment
    .utc(actionData.startDateTime)
    .add(actionData.timerMinutes, 'minutes')
    .format();
  let currentTime = moment
    .utc(moment.utc(moment().tz(timeZone).local()).format())
    .add('milliseconds', serverTimeOffset);
  let minutesLeft = moment
    .duration(moment.utc(futureTimeForCountdown).diff(moment.utc(currentTime)))
    .asMinutes();
  let secondsLeft = moment
    .duration(moment.utc(futureTimeForCountdown).diff(moment.utc(currentTime)))
    .asSeconds();

  if (actionData.timerMinutes) {
    console.log('actionData >>>', actionData);
    // console.log('secondsLeft', secondsLeft)
    // console.log('reachedZero', reachedZero)

    let minTommss = (minutes: any) => {
      // let checkMin = Math.floor(Math.abs(minutes));
      // let checkSec = Math.floor((Math.abs(minutes) * 60) % 60);
      // console.log('secondsLeft', secondsLeft, 'reachedZero', reachedZero)

      // console.log('seconds left', secondsLeft)
      // console.log('reached zero', reachedZero)

      // if (checkMin === 0 && checkSec === 0) {

      if (secondsLeft <= 0 && secondsLeft > -2) {
        // Timer reached zero
        console.log('played sound', playedSound);
        // reachedZero = true;
        if (playedSound === false) {
          playedSound = true;
          flashTimer = true;

          // Check if the timer action is muted
          const isTimerMuted = mutedActions.includes(
            '6018823c060fd59ddb764e0f'
          );

          // Make sure sound URL exists
          if (actionData.soundUrl && !isTimerMuted) {
            let foundSound = false;

            loadedSoundObjects.forEach((loadedSound: ISound) => {
              if (
                actionData.soundUrl === loadedSound.soundUrl &&
                foundSound === false
              ) {
                foundSound = true;
                console.log('Play loaded timer sound', loadedSound);
                playSound(loadedSound.soundUrl);
                alert('Playing loaded sound');
              }
            });
            if (foundSound === false) {
              foundSound = true;
              console.log('playing non loaded timer sound');

              playSound(actionData.soundUrl);
            }
          } else {
            console.log(isTimerMuted ? 'Timer is muted' : 'No sound URL found');
          }

          // Allow stopwatches to play again after 3 seconds (Primarly giving leeway to prevent risk of playing the same sound twice from the 500 ms tick of each card).
          setTimeout(() => {
            playedSound = false;
            // reachedZero = false;
          }, 3000);
        }
      } else if (minutes > 0) {
        var sign = minutes < 0 ? '-' : '';
        var min = Math.floor(Math.abs(minutes));
        var sec = Math.floor((Math.abs(minutes) * 60) % 60);
        return (
          sign + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
        );
      } else {
        return '00:00';
      }
    };

    console.log('Time Left', minTommss(minutesLeft));

    if (minTommss(minutesLeft) === '00:00') {
      flashTimer = true;
    } else {
      flashTimer = false;
    }

    if (minutesLeft) {
      // console.log(minTommss(minutesLeft));
      timerValue = minTommss(minutesLeft);
    }
  }

  let actionTime = moment()
    .add('milliseconds', serverTimeOffset)
    .tz(timeZone)
    .local()
    .diff(moment.utc(actionData.startDateTime).local(), 'minutes');

  let actionDescription = '';
  groupData.actionsList.forEach(action => {
    if (action.id === actionData.id) {
      actionDescription = action.actionDescription;
    }
  });

  let darkMode = false;
  if (localStorage.getItem('darkMode')) {
    let isDark = localStorage.getItem('darkMode');
    //@ts-ignore
    if (JSON.parse(isDark)) {
      darkMode = true;
    } else {
      darkMode = false;
    }
  }

  let checkListsCompletedString = '';
  if (actionData.actionChecklist?.length > 0) {
    let completedChecklists = 0;
    actionData.actionChecklist.forEach((checklist: any) => {
      if (checklist.isCleared) {
        completedChecklists++;
      }
    });
    checkListsCompletedString =
      completedChecklists + '/' + actionData.actionChecklist.length;
  }

  let leftRenderTooltip = false;
  let renderTooltipTop = false;
  if (actionRef?.current) {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    //@ts-ignore
    let offsetLeft = actionRef?.current.offsetLeft;
    //@ts-ignore
    let offsetTop = actionRef?.current.offsetTop;

    if (windowWidth / 2 < offsetLeft) {
      leftRenderTooltip = true;
    }

    if (windowHeight / 2 < offsetTop) {
      renderTooltipTop = true;
    }
  }

  // check actionData.soundReplaySeconds to see if sound should be replayed every x seconds
  if (
    actionData.soundReplaySeconds > 0 &&
    actionData.playSoundOnFlowstation &&
    !mute
  ) {
    if (!lastPlayedSound) {
      lastPlayedSound = moment().tz(timeZone).local();
    }

    // Replay action sound if applicable
    if (
      moment()
        .tz(timeZone)
        .local()
        .diff(moment.utc(lastPlayedSound).local(), 'seconds') >=
      actionData.soundReplaySeconds
    ) {
      if (secondsLeft <= 0) {
        // replay sound
        console.log('replaying sound');
        console.log('check action details', actionData);
        playSound(actionData.soundUrl);
        lastPlayedSound = moment().tz(timeZone).local();
      }
    }
  }

  let checkListOptions: any = [];
  groupData.actionsList.forEach(action => {
    if (action.id === actionData.id) {
      // filter out items that are already in the checklist
      checkListOptions = action.availableChecklistItems?.filter((item: any) => {
        let found = false;
        actionData?.actionChecklist.forEach((checklistItem: any) => {
          if (checklistItem.itemName === item.name) {
            found = true;
          }
        });
        return !found;
      });
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      {/* {console.log("props", actionData)} */}

      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        hideBackdrop={true}
        open={showActionOptions}
        style={{ textAlign: 'center' }}
        transitionDuration={{ enter: 0, exit: 0 }}
        onClose={() => {
          clickAwaySaveNewChecklistItem();
          handleCloseCheckListItem();
          setShowActionOptions(false);
        }}
      >
        <div
          style={darkMode ? { backgroundColor: '#333333', color: 'white' } : {}}
        >
          <div
            onClick={() => setShowActionOptions(false)}
            style={{
              color: 'red',
              textAlign: 'right',
              fontSize: 32,
              cursor: 'pointer',
              padding: '10px 10px 0px 0px',
            }}
          >
            X
          </div>
          <div style={{ fontSize: 32 }}>Action Options</div>
          <DialogContent>
            {/* <br /> */}

            {/* {console.log("check", showActionOptions)} */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div onClick={clearIcon}>
                <Button
                  style={{
                    marginRight: 10,
                    backgroundColor: '#50b848',
                    color: '#FFFFFF',
                    textDecoration: 'none',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  variant="contained"
                >
                  Remove Action
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={flashAction}
                  variant={darkMode ? 'contained' : 'text'}
                >
                  {actionData.isFlashing ? 'Stop Flashing' : 'Flash Action'}
                </Button>
              </div>
            </div>

            <br />

            <ActionResponse
              user={loggedInUserData}
              actionData={actionData}
              darkMode={darkMode}
              greencheck5={greencheck5}
              greencheck10={greencheck10}
              greencheck15={greencheck15}
              greencheck={greencheck}
              redcheck={redcheck}
              respondToAction={respondToAction}
            />

            <div>
              <div>
                <div style={{ marginBottom: 10 }}>
                  <div style={{ textAlign: 'center', color: 'gray' }}>
                    Check List
                  </div>
                  {/* {console.log("Action Data", actionData)} */}
                  {/* loop  actionData.actionChecklist */}

                  <div>
                    <List>
                      {actionData.actionChecklist.map(
                        (checklistItem: any, i: number) => {
                          return (
                            <ListItem
                              alignItems="center"
                              key={i}
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                              <Checkbox
                                classes={{
                                  root: classes.checkBox,
                                  checked: classes.checked,
                                }}
                                style={{ marginRight: 10 }}
                                checked={checklistItem.isCleared}
                                onClick={() =>
                                  updateChecklistItem(checklistItem, i)
                                }
                              />
                              {/* {checklistItem.itemName} */}
                              <ClickAwayListener
                                onClickAway={() =>
                                  handleClickAwayCheckListItem(i)
                                }
                              >
                                <TextField
                                  style={{ width: '350px' }}
                                  inputProps={
                                    checklistItem.isCleared
                                      ? {
                                          style: darkMode
                                            ? {
                                                textDecoration: 'line-through',
                                                color: 'white',
                                              }
                                            : {
                                                textDecoration: 'line-through',
                                              },
                                        }
                                      : {
                                          style: darkMode
                                            ? { color: 'white' }
                                            : {},
                                        }
                                  }
                                  // InputLabelProps={{ style: darkMode ? { color: "white" } : {} }}
                                  margin="none"
                                  // label="Add an item"
                                  // variant="filled"
                                  // value={listItem.itemName}
                                  value={
                                    updatedCheckListItemName &&
                                    updatedCheckListItemIndex === i
                                      ? updatedCheckListItemName
                                      : checklistItem.itemName
                                  }
                                  onChange={e => updateChecklistItemName(e, i)}
                                  // onChange={(e) => setState({ newChecklistItemName: e.target.value })}
                                  onKeyDown={e => keyPressCheckListItem(e, i)}
                                />
                              </ClickAwayListener>
                              <DeleteIcon
                                style={{ cursor: 'pointer', color: 'gray' }}
                                onClick={() => deleteChecklistItem(i)}
                              />
                            </ListItem>
                          );
                        }
                      )}

                      <ListItem
                        alignItems="center"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <ClickAwayListener
                          onClickAway={clickAwaySaveNewChecklistItem}
                        >
                          <>
                            <Checkbox
                              classes={{
                                root: classes.checkBox,
                                checked: classes.checked,
                              }}
                              style={{ marginRight: 10 }}
                              checked={newChecklistItemIsCleared}
                              onClick={() =>
                                setNewChecklistItemIsCleared(
                                  !newChecklistItemIsCleared
                                )
                              }
                            />

                            <Autocomplete
                              id="checklist-search"
                              key={checklistKey}
                              options={checkListOptions ? checkListOptions : []}
                              getOptionLabel={(option: any) => option.name}
                              onChange={(e, value, r) => {
                                console.log('r', r);
                                if (value) {
                                  console.log('selected', value);
                                  new Promise(() => {
                                    setNewChecklistItemName(value.name);
                                  }).then(() => {
                                    clickAwaySaveNewChecklistItem();
                                  });
                                }
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  style={{ width: '290px' }}
                                  label="Add an item"
                                  value={
                                    newChecklistItemName
                                      ? newChecklistItemName
                                      : ''
                                  }
                                  onChange={e =>
                                    setNewChecklistItemName(e.target.value)
                                  }
                                />
                              )}
                              // value={newChecklistItemName ? newChecklistItemName : ""}

                              // onChange={(e) => setState({ newChecklistItemName: e.target.value })}
                              onKeyDown={keyPress}
                            />

                            {/* <TextField
                                style={{ width: "350px" }}
                                inputProps={{ style: darkMode ? { color: "white" } : {} }}
                                margin="none"
                                placeholder="Add an item"
                                value={newChecklistItemName ? newChecklistItemName : ""}
                                onChange={(e) => setState({ newChecklistItemName: e.target.value })}
                                onKeyDown={keyPress}
                              /> */}
                          </>
                        </ClickAwayListener>
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* @ts-ignore */}
      <div
        className={classes.actionsList}
        ref={actionRef}
        style={leftRenderTooltip ? { direction: 'rtl' } : {}}
      >
        <div>
          <div
            className={
              _.some(flashActionBySound, actionData) && !selectedHistoryTime
                ? 'blink'
                : ''
            }
            onMouseOver={showHover}
            onMouseLeave={hideHover}
            // onClick={openActionOptions}
          >
            <Badge
              badgeContent={
                <div style={{ display: 'flex' }}>
                  {actionData.actionResponses?.map(
                    (response: any, i: number) => {
                      let imgSrc = '';

                      if (response.responseType === 1) imgSrc = greencheck5;
                      if (response.responseType === 2) imgSrc = greencheck10;
                      if (response.responseType === 3) imgSrc = greencheck15;
                      if (response.responseType === 4) imgSrc = greencheck;
                      if (response.responseType === 5) imgSrc = redcheck;

                      return (
                        <img
                          key={i}
                          src={imgSrc}
                          alt=""
                          style={
                            i === 0
                              ? {
                                  width: 20,
                                  height: 20,
                                  maxHeight: 20,
                                  zIndex: 2,
                                  border: '1px solid white',
                                  borderRadius: 10,
                                  backgroundColor: 'white',
                                }
                              : {
                                  width: 20,
                                  height: 20,
                                  maxHeight: 20,
                                  transform: 'translateX(-15px)',
                                  border: '1px solid white',
                                  borderRadius: 10,
                                  backgroundColor: 'white',
                                }
                          }
                        />
                      );
                    }
                  )}
                  {/* <div style={{ margin: "auto", fontSize: 12 }}>{checkListsCompletedString ? checkListsCompletedString : null}</div> */}
                </div>
              }
            >
              <div onClick={openActionOptions}>
                {actionData.isFlashing ? (
                  <div
                  // onMouseDown={() => mouseDown(actionData)}
                  // onMouseUp={() => mouseUp()}
                  >
                    {/* <Badge badgeContent={<CancelIcon style={{ color: "red", backgroundColor: "white", borderRadius: 10 }} />}> */}
                    <Avatar
                      className={classes.actionIcon + ' flashing'}
                      src={actionData.actionImageUrl}
                    />
                    {mutedActions.indexOf(actionData.id) === -1 ? null : (
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: 'white',
                          top: '60%',
                          right: 0,
                          cursor: 'pointer',
                          fontWeight: 'bold',
                          borderRadius: 5,
                        }}
                      >
                        <VolumeOffIcon style={{ fontSize: 20 }} />
                      </div>
                    )}
                    {/* </Badge> */}
                  </div>
                ) : (
                  <div>
                    <div
                      // onMouseDown={() => mouseDown(actionData)}
                      // onMouseUp={() => mouseUp()}
                      style={{ position: 'relative', textAlign: 'center' }}
                      className={flashTimer ? 'flashing' : ''}
                    >
                      {/* <Badge badgeContent={<CancelIcon style={{ color: "red", backgroundColor: "white", borderRadius: 10 }} />}> */}
                      <Avatar
                        className={
                          flashTimer
                            ? classes.actionIcon + ' flashing'
                            : classes.actionIcon
                        }
                        src={actionData.actionImageUrl}
                      />
                      {/* </Badge> */}
                      {actionData.id === '6018823c060fd59ddb764e0f' ? (
                        <div
                          style={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            top: '37%',
                            width: '100%',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        >
                          {timerValue}
                        </div>
                      ) : null}
                      {/* {console.log("action data", actionData)} */}
                      {mutedActions.indexOf(actionData.id) === -1 ? null : (
                        <div
                          style={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            top: '60%',
                            right: 0,
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            borderRadius: 5,
                          }}
                        >
                          <VolumeOffIcon style={{ fontSize: 20 }} />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {checkListsCompletedString ? (
                  <div
                    style={{
                      cursor: 'pointer',
                      margin: 'auto',
                      fontSize: 12,
                      position: 'absolute',
                      bottom: '0px',
                      paddingLeft: '5px',
                      width: '100%',
                      textAlign: 'left',
                      zIndex: 10,
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        padding: '2px',
                      }}
                    >
                      {checkListsCompletedString}
                    </span>
                  </div>
                ) : null}
              </div>
            </Badge>

            {hover ? (
              // {console.log("data", actionData)}
              <div
                className={classes.tooltiptext}
                onMouseLeave={hideHoverWithoutSave}
                // style={renderTooltipTop ? { marginTop: "-200px" } : { marginLeft: 50 }}
                style={
                  renderTooltipTop
                    ? leftRenderTooltip
                      ? { marginTop: '-200px', marginRight: 60 }
                      : { marginTop: '-200px', marginLeft: 60 }
                    : leftRenderTooltip
                    ? {}
                    : { marginLeft: 60 }
                }
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    <Avatar
                      variant="square"
                      className={classes.avatarLarge}
                      src={actionData.actionImageUrl}
                    />
                  </div>
                  <div className={classes.actionDetails}>
                    <div style={{ fontSize: 24 }}>
                      <strong>{actionData.actionName}</strong>
                    </div>
                    <div style={{ maxWidth: 250 }}>{actionDescription}</div>
                    {/* <hr /> */}
                    <div style={{ marginTop: 15 }}>
                      Added by: {actionData.addedBy}
                    </div>
                    {actionTime > 99 ? '99+' : actionTime} minutes ago
                  </div>
                </div>

                {/* Action Responses */}
                {actionData.actionResponses?.length > 0 ? (
                  <div>
                    <hr />
                    <div>
                      <div style={{ fontSize: 18, marginBottom: 10 }}>
                        <strong>Responses</strong>
                      </div>
                      {actionData.actionResponses?.map(
                        (response: any, i: number) => {
                          let resText = '';

                          if (response.responseType === 1)
                            resText = 'Confirmed 5 Minutes';
                          if (response.responseType === 2)
                            resText = 'Confirmed 10 Minutes';
                          if (response.responseType === 3)
                            resText = 'Confirmed 15 Minutes';
                          if (response.responseType === 4)
                            resText = 'Confirmed';
                          if (response.responseType === 5) resText = 'Declined';

                          return (
                            <div key={i} style={{ marginBottom: 10 }}>
                              <div>
                                {response.userFullName} - {resText}
                              </div>
                              <div>
                                (
                                {moment()
                                  .tz(timeZone)
                                  .local()
                                  .diff(
                                    moment.utc(response.startDateTime).local(),
                                    'minutes'
                                  )}{' '}
                                minutes ago)
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : null}

                {actionData.actionChecklist?.length > 0 ? (
                  <div style={{ fontSize: 18, marginTop: 20 }}>
                    <strong>Check List</strong>
                  </div>
                ) : null}

                <List>
                  {actionData.actionChecklist.map(
                    (checklistItem: any, i: number) => {
                      return (
                        <ListItem
                          alignItems="center"
                          key={i}
                          style={{ padding: 0 }}
                        >
                          <Checkbox
                            disabled
                            classes={{
                              root: classes.disabledCheckBox,
                              checked: classes.disabledChecked,
                            }}
                            style={{
                              marginRight: 10,
                              marginTop: 0,
                              marginBottom: 0,
                              padding: 0,
                            }}
                            checked={checklistItem.isCleared}
                          />
                          <div
                            style={
                              checklistItem.isCleared
                                ? { textDecoration: 'line-through' }
                                : {}
                            }
                          >
                            {checklistItem.itemName}
                          </div>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default ActionsList;
