import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { uniqBy } from 'lodash';
import React, { FC } from 'react';
import DepartmentField, {
  TDepartmentOption,
} from '../../../../../../common/fields/department/DepartmentField';
import ProviderMultiSelectField from '../../../../../../common/fields/provider/ProviderMultiSelectField';
import SiteField from '../../../../../../common/fields/site/SiteField';
import {
  IDepartment,
  IProvider,
  ISite,
} from '../../../../../../interfaces/interfaces';
import { TNotificationFor } from '../../types';
import { TFormSchema } from '../NotificationNewForm';

type TProps = {
  watch: UseFormWatch<TFormSchema>;
  setValue: UseFormSetValue<TFormSchema>;
  control: Control<TFormSchema>;
  errors: FieldErrors<TFormSchema>;
};

const NotificationNewSelectTypeSubform: FC<TProps> = props => {
  const { watch, setValue, control, errors } = props;

  const notificationFor = watch('notificationFor');
  const orgId = watch('orgId');

  const onHandleRadioCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('notificationFor', event.target.value as TNotificationFor);
  };

  const selectedSite = (watch('selectedSite') ?? []) as ISite;
  const selectedDepartments = (watch('selectedDepartments') ??
    []) as IDepartment[];
  const selectedProviders = (watch('selectedProviders') ?? []) as IProvider[];
  const activeStep = watch('activeStep');

  const onNext = () => {
    setValue('activeStep', activeStep + 1);
  };

  const onRemove = (department: IDepartment) => {
    console.log({ department });

    const newDepartments = selectedDepartments.filter(
      d => d.id !== department.id
    );

    setValue('selectedDepartments', newDepartments);
  };

  const onSelectDepartments = (departmentOptions: TDepartmentOption[]) => {
    const departments = departmentOptions?.map(
      (departmentOption: TDepartmentOption) => {
        return departmentOption.department;
      }
    );

    const updatedDepartments = uniqBy(
      [...selectedDepartments, ...departments],
      'id'
    );

    setValue('selectedDepartments', updatedDepartments);
  };

  return (
    <div className="">
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Notification for Rooms"
          name="Notification for Rooms"
          value={notificationFor}
          onChange={onHandleRadioCheck}
        >
          <FormControlLabel
            value={`provider`}
            control={<Radio color="primary" />}
            label="User"
          />
          <FormControlLabel
            value={`department`}
            control={<Radio color="primary" />}
            label="Departments"
          />
        </RadioGroup>
      </FormControl>

      {notificationFor === 'provider' && (
        <div className="mt-3">
          <ProviderMultiSelectField
            orgId={orgId}
            onSelect={(providers: IProvider[]) => {
              setValue('selectedProviders', providers);
            }}
            selectedProviders={selectedProviders}
          />
        </div>
      )}

      {notificationFor === 'department' && (
        <div className="mt-3">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedDepartments.map(department => (
              <div key={department.id}>
                <Chip
                  style={{ margin: '5px 5px 5px 0px' }}
                  label={`${department.siteName} ${department.deptName} `}
                  onDelete={() => onRemove(department)}
                />
              </div>
            ))}
          </div>

          <div className="mt-3">
            <SiteField
              control={control}
              errors={errors}
              name="selectedSite"
              orgId={orgId}
              onSelect={siteOption => {
                if (siteOption?.site) {
                  setValue('selectedSite', siteOption?.site);
                } else {
                  setValue('selectedSite', null);
                }
              }}
              selectedSite={selectedSite}
            />
          </div>

          {selectedSite && (
            <div className="mt-2">
              <DepartmentField
                control={control}
                errors={errors}
                clearOnSelect={true}
                name="selectedDepartments"
                onSelect={departmentOption => {
                  const department = departmentOption?.department;

                  if (department) {
                    onSelectDepartments([departmentOption]);
                  }
                }}
                site={selectedSite}
                orgId={orgId}
                onSelectAllDepartments={departmentOptions => {
                  onSelectDepartments(departmentOptions);
                }}
              />
            </div>
          )}

          {/* <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              maxHeight: '25vh',
            }}
          >
            {preppedDepartments.map((department: IDepartment) => (
              <div key={department.id}>
                <Chip
                  style={{ margin: '5px 5px 5px 0px' }}
                  label={department.siteName + ' ' + department.deptName}
                  onDelete={() => onRemovePreppedDepartment(department)}
                />
              </div>
            ))}
          </div>

          <Autocomplete
            id="site-search"
            options={activeSites}
            getOptionLabel={(site: any) => site.siteName}
            onChange={(event: any, site: any) => {
              if (site) {
                onSelectSite(site);
              }
            }}
            renderInput={params => (
              <TextField {...params} autoFocus label="Search Site" />
            )}
          />

          {selectedSiteFilter ? (
            <Autocomplete
              id="dept-search"
              inputref={this.departmentInput}
              key={deptSearchKey}
              options={activeDepartments.filter((department: IDepartment) => {
                let found = false;
                preppedDepartments.forEach((preppedDepartment: IDepartment) => {
                  if (department.id === preppedDepartment.id) {
                    found = true;
                  }
                });
                return !found;
              })}
              getOptionLabel={(department: any) => {
                return department.siteName + ' ' + department.deptName;
              }}
              onChange={(event: any, dept: any) => {
                if (dept) {
                  onSelectDepartment(dept);

                  new Promise(resolve => {
                    setDeptSearchKey(deptSearchKey + 1);

                    resolve(null);
                  }).then(() => {
                    departmentInputRef.focus();
                  });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={input => {
                    departmentInputRef = input;
                  }}
                  label="Search Department"
                />
              )}
            />
          ) : null}

          <Button
            disabled={!selectedSiteFilter}
            onClick={() => onSelectAllDepartments(activeDepartments)}
            color="primary"
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            Select All
          </Button> */}
        </div>
      )}

      <div className="mt-3">
        <Button
          disabled={
            selectedDepartments.length === 0 && selectedProviders.length === 0
          }
          variant="contained"
          color="primary"
          onClick={onNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default NotificationNewSelectTypeSubform;
