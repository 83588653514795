import { createContext, useContext } from 'react';
import { IDepartment } from '../../../../interfaces/interfaces';

type TProps = {
  department: IDepartment;
  isLoading: boolean;
  refetch: (departmentId: string) => void;
  error: string | null;
};

const DepartmentContext = createContext<TProps>({} as TProps);

export const DepartmentProvider = DepartmentContext.Provider;

export const useDepartmentContext = () => {
  const { department, isLoading, refetch, error } =
    useContext(DepartmentContext);

  return { department, isLoading, refetch, error };
};
