import React from 'react';
import { Box, Typography, Button, Container, ThemeProvider, createTheme } from '@mui/material';
import { ErrorOutline as ErrorIcon } from '@mui/icons-material';

// Create a custom theme with the specified button color
const theme: any = createTheme({
  palette: {
    // customGreen: {
    //   main: '#4cb946',
    //   contrastText: '#ffffff',
    // },
  },
});

const NotFoundPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ textAlign: 'center' }}>
            <ErrorIcon sx={{ fontSize: 64, color: 'warning.main', mb: 2 }} />
            <Typography variant="h2" component="h1" gutterBottom>
              404
            </Typography>
            <Typography variant="h4" gutterBottom>
              Page Not Found
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Oops! The page you're looking for doesn't exist.
            </Typography>
            <Button 
              variant="contained" 
              // color="customGreen" 
              href="/"
              sx={{ 
                backgroundColor: "#4cb946",
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#3da338', // Slightly darker shade for hover effect
                },
              }}
            >
              Go Home
            </Button>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default NotFoundPage;