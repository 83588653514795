import React, { Component } from "react";
import { connect } from "react-redux";
import { API } from "../../../apiconfig";
import { checkRefreshToken } from "../../../utils/utils";

import { withStyles, Theme } from "@material-ui/core/styles";

// import clinicimage2 from "../../../images/clinicimage2.jpg";


import { fetchRoomsByOrgId, fetchClinicMapsByOrgId, fetchAppUsersByOrgId } from "../../../actions/adminActions";
import { IOrganization, IRoom, IUser } from "../../../interfaces/interfaces";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import moment from "moment-timezone";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from "@material-ui/core/Checkbox";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Grid from '@material-ui/core/Grid';

// import { API } from "../apiconfig";

// import Typography from "@material-ui/core";

const _ = require("lodash");

const styles: {} = (theme: Theme) => ({
    checkBox: {
        color: "#50b848",
        "&$checked": {
            color: "#50b848",
        },
        "&:hover": {
            background: "#fff",
        },
    },
    checked: {
        "&:hover": {
            background: "#fff",
        },
        "&&:hover": {
            backgroundColor: "transparent",
        },
    },
});


interface ClinicMapLoaderProps {
    classes: any;
    loggedInUserData: IUser;
    fetchClinicMapsByOrgId: (orgId: string) => void;
    fetchRoomsByOrgId: (orgId: string) => void;
    fetchAppUsersByOrgId: (orgId: string) => void;
    clinicMaps: any;
    organization: IOrganization;
    rooms: IRoom[];
    usersData: IUser[];
}

interface ClinicMapLoaderState {
    coordArray: any;

    selectedClinicMap: any;
    imageLoaded: boolean;

    pathwayData: any;


    filter: {
        clinicMapId: any;
        date: any;
        roomId: any;
        userId: any;
        role: any;
    },

    hoveredPathId: any;
    loading: boolean;
    error: boolean;
    hideSvgCircleData: boolean;
    tabValue: number;
    roomSortBy: string;
    userSortBy: string;
    roomSortDescending: boolean;
    userSortDescending: boolean;
}

class ClinicMapLoader extends Component<ClinicMapLoaderProps, ClinicMapLoaderState> {
    constructor(props: ClinicMapLoaderProps) {
        super(props);
        this.state = {
            selectedClinicMap: null,
            imageLoaded: false,
            coordArray: [],
            pathwayData: [],
            filter: {
                clinicMapId: null,
                date: moment().local().format("YYYY-MM-DD"),
                roomId: null,
                userId: null,
                role: null,
            },
            hoveredPathId: null,
            loading: false,
            error: false,
            hideSvgCircleData: false,
            tabValue: 0,
            roomSortBy: "roomName",
            userSortBy: "name",
            roomSortDescending: false,
            userSortDescending: false,
        };

    }

    componentDidMount() {
        this.props.fetchClinicMapsByOrgId(this.props.loggedInUserData.mongoOrganizationId);
        this.props.fetchRoomsByOrgId(this.props.loggedInUserData.mongoOrganizationId);
        this.props.fetchAppUsersByOrgId(this.props.loggedInUserData.mongoOrganizationId);
    }


    selectClinicMap = (map: any) => {
        // console.log("Selected", map)
        this.setState({
            selectedClinicMap: map,
            loading: true
        })
        const URL = API.REACT_APP_API_PATHWAYS + map.id;
        let token = localStorage.getItem("token");

        let headers = {
            headers: {
                Authorization: "Bearer " + token,
                "Org-UID": "UID1",
                Pragma: "no-cache",
            },
        };

        axios.get(URL, headers).then((response) => {
            console.log("Fetched pathways data", response.data.data);
            this.setState({
                pathwayData: response.data.data,
                filter: {
                    ...this.state.filter,
                    clinicMapId: map.id,
                },
                loading: false

            })
        }).catch((err) => {
            console.log("Error fetching pathways", err);
            this.setState({
                loading: false
            })
            if (err.response?.status === 401) {
                checkRefreshToken();
            }
        });
    }

    selectSubGroup = (subGroup: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                role: subGroup.id
            }
        }, this.applyFilter)
    }

    handleDateChange = (event: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                date: event.target.value
            }
        }, this.applyFilter)
    }


    selectRoom = (roomId: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                roomId: roomId
            }
        }, this.applyFilter)
    }

    selectUser = (userId: any) => {
        console.log("Selected user", userId)
        this.setState({
            filter: {
                ...this.state.filter,
                userId: userId
            }
        }, this.applyFilter)
    }

    applyFilter = () => {
        let payload = {
            ...this.state.filter
        };

        console.log("payload", payload);

        if (payload.clinicMapId) {
            let token = localStorage.getItem("token");

            this.setState({ loading: true })
            fetch(API.REACT_APP_API_PATHWAYSFILTER, {
                method: "POST",
                body: JSON.stringify(payload),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    // console.log("RES!", res.json());
                    return res.json();
                }).then(data => {
                    console.log('data', data.data)
                    this.setState({
                        pathwayData: data.data,
                        loading: false,
                        error: false,
                    })
                }).catch((err) => {
                    console.log("Error fetching pathways", err);
                    if (err.response?.status === 401) {
                        checkRefreshToken();
                    }
                    this.setState({
                        loading: false,
                        error: true
                    })
                })
        }


    }


    selectImageCoords = (e: any) => {
        // console.log("e", e.target.id)

        if (e.target.id.includes("path-")) {
            if (this.state.hoveredPathId !== parseInt(e.target.id.split("-")[1])) {
                this.setState({
                    hoveredPathId: parseInt(e.target.id.split("-")[1])
                })
            }
        } else if (e.target.id === "tooltip-span") {
            console.log("Tooltip span")

        } else {
            this.setState({
                hoveredPathId: null
            })
        }

        // if (e.target.id.includes("circle-")) {
        //     this.setState({
        //         coordArray: this.state.coordArray.filter((item: any, i: number) => i !== parseInt(e.target.id.split("-")[1]))
        //     })
        // }

        // // make sure clinic-viewbox is the target.
        // if (e.target.id === "clinic-viewbox") {
        //     var rect = e.target.getBoundingClientRect();
        //     var x = e.clientX - rect.left; //x position within the element.
        //     var y = e.clientY - rect.top;  //y position within the element.

        //     if (x) {
        //         // round up to whole number
        //         x = Math.round(x);
        //     }
        //     if (y) {
        //         // round up to whole number
        //         y = Math.round(y);
        //     }

        //     console.log("Image Clicked", e.clientX, e.clientY);

        //     // let x = e.clientX;
        //     // let y = e.clientY;

        //     this.setState({
        //         coordArray: [...this.state.coordArray, { x, y, roomId: "" }]
        //     })
        // }

    }


    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue });
    }


    roomSortBy = (sortValue: string) => {
        console.log("clicked")
        if (sortValue === this.state.roomSortBy) {
            this.setState({
                roomSortDescending: !this.state.roomSortDescending,
            });
        } else {
            this.setState({
                roomSortBy: sortValue,
                roomSortDescending: false,
            });
        }
    };

    userSortBy = (sortValue: string) => {
        if (sortValue === this.state.userSortBy) {
            this.setState({
                userSortDescending: !this.state.userSortDescending,
            });
        } else {
            this.setState({
                userSortBy: sortValue,
                userSortDescending: false,
            });
        }
    };



    render() {
        const { classes } = this.props;


        // console.log("selectedClinicMap", this.state.selectedClinicMap)
        // console.log("FILTER", this.state.filter)

        // get width and height of clinicimage
        let image = new Image();
        image.src = this.state.selectedClinicMap ? this.state.selectedClinicMap.clinicMapUrl : "";
        image.onload = () => {
            // console.log("image width", image.width, "image height", image.height);
        }

        function getColor(value: number) {
            //value from 0 to 1
            var hue = ((1 - value) * 120).toString(10);
            return ["hsl(", hue, ",100%,50%)"].join("");
        }


        let svgsLoaded = false;
        let connectionData: any = [];
        let circleData: any = [];
        let userData: any = [];

        if (this.state.pathwayData) {
            if (this.state.pathwayData.circleData) {
                circleData = this.state.pathwayData.circleData;
            }
            if (this.state.pathwayData.userData) {
                userData = this.state.pathwayData.userData;
            }

            connectionData = this.state.pathwayData.connectionData;
        }

        let sortedUserData: any = [];
        if (userData) {
            let filter = this.state.userSortBy;
            if (this.state.userSortDescending) {
                sortedUserData = _.orderBy(userData, [filter], ["desc"]);
            } else {
                sortedUserData = _.orderBy(userData, [filter], ["asc"]);
            }
        }

        let sortedCircleData: any = [];
        if (circleData) {
            let filter = this.state.roomSortBy;
            if (this.state.roomSortDescending) {
                sortedCircleData = _.orderBy(circleData, [filter], ["desc"]);
            } else {
                sortedCircleData = _.orderBy(circleData, [filter], ["asc"]);
            }
        }






        let linePathways: any = [];
        if (connectionData) {
            linePathways = connectionData;
        }

        // console.log("Connection data", connectionData)

        let svgLinePathways: any = [];
        let lineHoverPathways: any = [];
        linePathways.forEach((coord: any, index: number) => {

            // Mid Point
            var mpx = (coord.x1 + coord.x2) * 0.5;
            var mpy = (coord.y1 + coord.y2) * 0.5;

            // Angle of perpendicular to line
            var theta = Math.atan2(coord.y2 - coord.y1, coord.x2 - coord.x1) - Math.PI / 2;

            // Offset from mid point
            var offset = 50;

            // location of control point
            var c1x = mpx + offset * Math.cos(theta);
            var c1y = mpy + offset * Math.sin(theta);

            svgLinePathways.push(
                <g key={index}>
                    <defs>
                        {/* <marker id='head' orient="auto"
                            markerWidth='3' markerHeight='4'
                            refX='0.1' refY='2'>
                            <path d='M0,0 V4 L2,2 Z' fill="black" />
                        </marker> */}
                        {/* <marker id="head" markerWidth="15" markerHeight="15" refX="8.7" refY="3" orient="auto" markerUnits="strokeWidth">
                            <path d="M0,0 L0,6 L9,3 z" />
                        </marker> */}
                    </defs>
                    <path
                        id={"path-" + index}
                        d={`M${coord.x1} ${coord.y1} Q ${c1x} ${c1y} ${coord.x2} ${coord.y2}`}
                        fill="none"
                        // stroke={coord.color}
                        stroke={this.state.hoveredPathId === index ? "gray" : "black"}
                        className="tooltip"
                        // strokeWidth="2"
                        // strokeWidth={coord.count}
                        strokeWidth={coord.busyScale * this.state.selectedClinicMap.roomCircleSize}
                        markerEnd='url(#head)'
                    />
                    {/* {this.state.hoveredPathId === index ? (
                        <foreignObject
                            // make x and y coordinates relative to client x and y
                            x={mpx}
                            y={mpy}

                            height="25" width="120">
                            <span id="tooltip-span" style={{ background: "white", fontSize: 18, border: "1px solid black", borderRadius: 5 }}>
                                Walked: {coord.count}
                            </span>
                        </foreignObject>) : null} */}
                </g>
            )

            if (this.state.hoveredPathId === index) {

                let imageWidth = image.width;
                let imageHeight = image.height;

                //check if mpx and mpy are close to the edge of the image
                if (mpx > imageWidth - 100) {
                    mpx = mpx - 100;
                }
                if (mpy > imageHeight - 100) {
                    mpy = mpy - 100;
                }


                lineHoverPathways.push(
                    <foreignObject
                        // make x and y coordinates relative to client x and y
                        x={mpx}
                        y={mpy}
                        id="tooltip-span"
                        height="55" width="120">
                        <div id="tooltip-span">
                            <div id="tooltip-span" style={{ background: "white", fontSize: 18, border: "1px solid black", borderRadius: 5, padding: 5 }}>
                                Walked: {coord.count}
                                <div>
                                    {coord.realWorldDistance ? Math.round(coord.realWorldDistance) : ""} {this.state.selectedClinicMap?.realRoomDistance?.units}
                                </div>
                            </div>
                        </div>
                    </foreignObject>
                )
            }



            if (index === linePathways.length - 1) {
                svgsLoaded = true;
            }

        })
        // console.log("line pathways", linePathways)



        let circleDataPathways: any = [];

        circleData.forEach((room: any, index: number) => {

            let imageWidth = image.width;
            let imageHeight = image.height;
            let adjustedX = room.x;
            let adjustedY = room.y;

            // shift the x and y coordinates if they are close to the edge of the image
            // checking right hand side
            if (room.x > imageWidth - 50) {
                adjustedX = room.x - 10;
            }

            // checking left hand side
            if (room.x < 50) {
                adjustedX = room.x + 10;
            }

            // checking bottom
            if (room.y > imageHeight - 60) {
                adjustedY = room.y - 50;
            }

            circleDataPathways.push(
                <g key={index}>
                    <circle cx={room.x} cy={room.y}
                        // r={room.busyScale} 
                        // r={10}
                        r={this.state.selectedClinicMap.roomCircleSize}
                        stroke="black" strokeWidth="3"
                        fill={getColor(room.busyScale / 100)}
                        shapeRendering="geometricPrecision" />
                    {/* <text x={room.x - 5} y={room.y} fontSize="12" fill="black">{room.visitCount} visits</text> */}
                    {/* <text x={room.x - 5} y={room.y + 10} fontSize="12" fill="black">{Math.round(room.visitPercent * 100)}%</text> */}

                    {this.state.hideSvgCircleData ? null : (
                        <foreignObject className="node" x={adjustedX - 25} y={adjustedY} height="56" width="50">
                            <div id="foreignObject">
                                <div style={{ backgroundColor: "white", border: "1px solid black", textAlign: "center", borderRadius: "5px", fontSize: 12 }}>
                                    <div>{room.roomName}</div>
                                    <div>{room.visitCount} visits ({Math.round(room.visitPercent * 100)}%)</div>
                                    {/* <div>Busy {room.busyScale}</div> */}
                                </div>
                            </div>
                        </foreignObject>
                    )}


                </g>
            )
        })





        // Filtering data
        let userRoleSubGroups: any = [];
        this.props.organization?.serviceLines.forEach((serviceLine: any, index: number) => {
            serviceLine.serviceLineSubGroups.forEach((subGroup: any, index: number) => {
                userRoleSubGroups.push({ ...subGroup, serviceLineName: serviceLine.name })
            })
        })
        // alphabetizeUserRoleSubGroups by this.props.organization.serviceLine.siteDeptName
        let alphabetizedUserRoleSubGroups = _.orderBy(userRoleSubGroups, "serviceLineName", "asc");

        let availableRooms: IRoom[] = [];
        this.state.selectedClinicMap?.roomCoordinates.forEach((room: any) => {
            let roomDetails = this.props.rooms.find((r: any) => r.id === room.roomId);
            if (roomDetails) {
                availableRooms.push(roomDetails);
            }
        })
        let alphabetizedRooms = _.orderBy(availableRooms, "siteDeptName", "asc")

        // console.log("check rooms", this.props.rooms)

        let availableUsers: IUser[] = [];
        this.props.usersData.forEach((user: IUser) => {
            if (user.isActive) {
                availableUsers.push(user);
            }
        })
        let alphabetizedUsers = _.orderBy(availableUsers, "fullName", "asc")


        return (
            <div>

                {/* 
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid> */}

                <div style={{ display: "flex" }}>
                    {this.state.selectedClinicMap ? (
                        <div className="img-overlay-wrap" onMouseOver={(e) => this.selectImageCoords(e)}>
                            <img src={this.state.selectedClinicMap.clinicMapUrl} alt="clinic"
                                // style={{ maxWidth: "70vw", maxHeight: "90vh", width: "70vw" }}
                                style={{ maxHeight: "90vh", maxWidth: "70vw" }}
                            />

                            {svgsLoaded ?
                                <svg
                                    // viewBox={`0 0 1000 1000`} // SINCE CREATOR USED 1000, WE USE 1000
                                    viewBox={`0 0 ${image.width} ${image.height}`}
                                    xmlns="http://www.w3.org/2000/svg"
                                >


                                    {svgLinePathways}

                                    {circleDataPathways}

                                    {lineHoverPathways}

                                </svg>
                                : null}


                        </div>
                    ) : null}


                    <div style={{ margin: "auto", padding: "0px 10px 0px 10px" }}>
                        <Autocomplete
                            id="clinicmap-search"
                            options={this.props.clinicMaps}
                            getOptionLabel={(map: any) => map.clinicMapName}
                            onChange={(event: any, map: any | null) => {
                                if (map) {
                                    this.selectClinicMap(map)
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                    margin="none"
                                    label="Clinic Map"
                                    style={{ width: 300 }}
                                />
                            }
                        />
                        <br />

                        {this.state.selectedClinicMap ? (
                            <div>
                                <div>
                                    {/* <strong>Date/Time Filter</strong> */}
                                    <TextField
                                        // style={{ width: "100%" }}
                                        style={{ width: 300 }}
                                        id="date"
                                        label="Date"
                                        type="date"
                                        defaultValue={this.state.filter.date}
                                        onChange={this.handleDateChange}
                                        //   className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>

                                <br />

                                <div>
                                    {/* <strong>User Role Selection</strong> */}
                                    <Autocomplete
                                        id="clinicmap-search"
                                        options={alphabetizedUserRoleSubGroups}
                                        getOptionLabel={(subGroup: any) => subGroup.serviceLineName + ' ' + subGroup.name}
                                        onChange={(event: any, subGroup: any | null) => {
                                            if (subGroup) {
                                                this.selectSubGroup(subGroup)
                                            } else {
                                                this.setState({
                                                    filter: {
                                                        ...this.state.filter,
                                                        role: null
                                                    }
                                                }, this.applyFilter)
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                margin="none"
                                                label="User Role"
                                                style={{ width: 300 }}
                                            />
                                        }
                                    />
                                </div>

                                <br />

                                <div>
                                    {/* <strong>Room Selection</strong> */}
                                    <Autocomplete
                                        id="room-search"
                                        options={alphabetizedRooms}
                                        getOptionLabel={(room: IRoom) => room.siteDeptName + ' ' + room.roomName}
                                        onChange={(event: any, room: IRoom | string) => {

                                            if (room && typeof room !== "string") {
                                                this.selectRoom(room.id)
                                            } else {
                                                this.setState({
                                                    filter: {
                                                        ...this.state.filter,
                                                        roomId: null
                                                    }
                                                }, this.applyFilter)


                                            }
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                margin="none"
                                                label="Room"
                                                style={{ width: 300 }}
                                            />
                                        }
                                    />
                                </div>
                                <br />
                                <div>
                                    {/* <strong>User Selection</strong> */}
                                    <Autocomplete
                                        id="user-search"
                                        options={alphabetizedUsers}
                                        getOptionLabel={(user: any) => user.fullName}
                                        onChange={(event: any, user: any | null) => {
                                            if (user) {
                                                this.selectUser(user.id)
                                            } else {
                                                this.setState({
                                                    filter: {
                                                        ...this.state.filter,
                                                        userId: null
                                                    }
                                                }, this.applyFilter)

                                            }
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                margin="none"
                                                label="User"
                                                style={{ width: 300 }}
                                            />
                                        }
                                    />
                                </div>
                                <br />
                                <Checkbox
                                    classes={{
                                        root: classes.checkBox,
                                        checked: classes.checked,
                                    }} checked={this.state.hideSvgCircleData} onClick={() => this.setState({ hideSvgCircleData: !this.state.hideSvgCircleData })} />Hide Room Details
                                <br />
                                <div style={{ display: "flex" }}>
                                    <Button onClick={this.applyFilter} variant="contained" color="primary">Apply Filter</Button>
                                    {this.state.loading ? <CircularProgress style={{ marginLeft: 25 }} /> : null}
                                    {this.state.error ? <div style={{ color: "red" }}>Error Occured Fetching Data...</div> : null}

                                </div>
                                <br />

                                <TableContainer component={Paper} style={{ maxHeight: "40vh" }}>
                                    <Tabs
                                        value={this.state.tabValue} onChange={this.handleTabChange}
                                        aria-label="simple tabs example">
                                        <Tab label="Rooms" />
                                        <Tab label="Users" />
                                    </Tabs>

                                    {this.state.tabValue === 0 ? (
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell onClick={() => this.roomSortBy("roomName")} style={{ cursor: "pointer" }}>
                                                        {this.state.roomSortBy === "roomName" ? <div>Room {this.state.roomSortDescending ? <>▼</> : <>▲</>}</div> : <div>Room</div>}
                                                    </TableCell>

                                                    <TableCell onClick={() => this.roomSortBy("visitCount")} style={{ cursor: "pointer" }}>
                                                        {this.state.roomSortBy === "visitCount" ? <div>Visits {this.state.roomSortDescending ? <>▼</> : <>▲</>}</div> : <div>Visits</div>}
                                                    </TableCell>

                                                    <TableCell onClick={() => this.roomSortBy("visitPercent")} style={{ cursor: "pointer" }}>
                                                        {this.state.roomSortBy === "visitPercent" ? <div>% {this.state.roomSortDescending ? <>▼</> : <>▲</>}</div> : <div>%</div>}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sortedCircleData.map((room: any, index: number) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{room.roomName}</TableCell>
                                                        <TableCell>{room.visitCount}</TableCell>
                                                        <TableCell>{Math.round(room.visitPercent * 100)}%</TableCell>
                                                    </TableRow>
                                                ))
                                                }
                                            </TableBody>
                                        </Table>
                                    ) : null}

                                    {this.state.tabValue === 1 ? (
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell onClick={() => this.userSortBy("name")} style={{ cursor: "pointer" }}>
                                                        {this.state.userSortBy === "name" ? <div>User {this.state.userSortDescending ? <>▼</> : <>▲</>}</div> : <div>User</div>}
                                                    </TableCell>

                                                    <TableCell onClick={() => this.userSortBy("role")} style={{ cursor: "pointer" }}>
                                                        {this.state.userSortBy === "role" ? <div>Role {this.state.userSortDescending ? <>▼</> : <>▲</>}</div> : <div>Role</div>}
                                                    </TableCell>

                                                    <TableCell onClick={() => this.userSortBy("distanceTraveled")} style={{ cursor: "pointer" }}>
                                                        {this.state.userSortBy === "distanceTraveled" ? <div>Traveled {this.state.userSortDescending ? <>▼</> : <>▲</>}</div> : <div>Traveled</div>}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {console.log('users data', userData)}
                                                {sortedUserData.map((user: any, index: number) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{user.name}</TableCell>
                                                        <TableCell>{user.role}</TableCell>
                                                        <TableCell>{user.distanceTraveled} {this.state.selectedClinicMap?.realRoomDistance?.units}</TableCell>
                                                    </TableRow>
                                                ))}
                                                {/* <TableRow>
                                                    <TableCell>John Doe</TableCell>
                                                    <TableCell>100</TableCell>
                                                    <TableCell>MA</TableCell>
                                                </TableRow> */}
                                            </TableBody>
                                        </Table>
                                    ) : null}








                                </TableContainer>
                            </div>
                        ) : null}



                    </div>
                </div>



            </div>
        );
    }
}


const mapStateToProps = (state: any) => ({
    loggedInUserData: state.adminData.loggedInUserData,
    rooms: state.adminData.rooms,
    usersData: state.adminData.usersData,
    clinicMaps: state.adminData.clinicMaps,
    organization: state.adminData.organization,
})

export default connect(mapStateToProps, { fetchRoomsByOrgId, fetchClinicMapsByOrgId, fetchAppUsersByOrgId })(withStyles(styles)(ClinicMapLoader));