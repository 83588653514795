import { FC, Fragment, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import Container from '@material-ui/core/Container';

import { useOrg } from '../../hooks/useOrg';

import { usePatientContentList } from '../../hooks/partientContent/usePatientContentList';
import { IPatientContent, TAdminState } from '../../interfaces/interfaces';
import { getQueryVariable } from '../../utils/utils';
import TimedIframe from './content/TimedIframe';
import VideoPlayer from './content/VideoPlayer';
import WaitingRoom from './content/waitingRoom/WaitingRoom';
import DeviceSubscription from './DeviceSubscription';
import { LobbyProvider } from './LobbyContext';
import { getLobbySequences } from './lobbyDB';
import LobbyHeader from './header/LobbyHeader';
import StatusOverrideModal from './StatusOverrideModal';
import { TLobbySequence, TProviderToOverride } from './types';
import { buildFrames } from './utils';
import WaitingRoomWelcome from './content/waitingRoom/welcome/WaitingRoomWelcome';

type TProps = {
  authenticated: boolean;
};
const Lobby: FC<TProps> = () => {
  let deviceId = null;
  if (getQueryVariable('deviceId')) {
    deviceId = getQueryVariable('deviceId');
  }

  const [showTimeOverride, setShowTimeOverride] = useState(false);

  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(0);
  const [currentSequenceItem, setCurrentSequenceItem] =
    useState<TLobbySequence>(null);

  const [providerToOverride, setProviderToOverride] =
    useState<TProviderToOverride>(null);

  const [frames, setFrames] = useState<TLobbySequence[]>([]);

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state.adminData ?? ({} as TAdminState);

      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const orgId = loggedInUserData?.mongoOrganizationId;

  const { organization } = useOrg(orgId);
  const { isLoading, refetch: refetchPatientContentList } =
    usePatientContentList({
      orgId,
      onCompleted: patientContentList => {
        const lobbySequences = getLobbySequences(orgId);

        onSetupLobby({ lobbySequences, patientContentList });
      },
    });

  useEffect(() => {
    const refreshTime = 1000 * 60 * 60; // 1 hour

    const interval = setInterval(() => {
      window.location.reload();
    }, refreshTime);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onSetupLobby = (input: {
    lobbySequences: TLobbySequence[];
    patientContentList: IPatientContent[];
  }) => {
    const { lobbySequences, patientContentList } = input;
    const frames = buildFrames({ lobbySequences, patientContentList, orgId });

    setFrames(frames);
    setCurrentSequenceItem(frames[0]);
    setCurrentSequenceIndex(0);
  };

  const onRefresh = async () => {
    const patientContentList = await refetchPatientContentList();
    onSetupLobby({
      lobbySequences: getLobbySequences(orgId),
      patientContentList,
    });
  };

  const onContentEnded = () => {
    if (currentSequenceIndex === frames.length - 1) {
      // Restart sequence

      setCurrentSequenceIndex(0);
      setCurrentSequenceItem(frames[0]);
    } else {
      // Move to next sequence item

      setCurrentSequenceIndex(currentSequenceIndex + 1);
      setCurrentSequenceItem(frames[currentSequenceIndex + 1]);
    }
  };

  console.log('Lobby', { deviceId, frames });

  if (isLoading) return <div>Loading...</div>;

  if (isEmpty(organization)) return null;

  return (
    <LobbyProvider
      value={{
        organization,
      }}
    >
      <div style={{ height: '100vh', backgroundSize: 'cover' }} className="">
        <div>
          <Container maxWidth="xl" id="lobbynavbar">
            <LobbyHeader
              orgId={orgId}
              organization={organization}
              onRefresh={onRefresh}
            />

            <DeviceSubscription key={deviceId} deviceId={deviceId} />

            <StatusOverrideModal
              setShowTimeOverride={setShowTimeOverride}
              setProviderToOverride={setProviderToOverride}
              showTimeOverride={showTimeOverride}
              providerToOverride={providerToOverride}
            />
          </Container>

          {!isLoading && isEmpty(currentSequenceItem) && (
            <div
              style={{
                height: '90vh',
                width: '100%',
              }}
            >
              <WaitingRoomWelcome organization={organization} size="5xl" />
            </div>
          )}

          {!isLoading && !isEmpty(currentSequenceItem) && (
            <div
              style={{
                height: '90vh',
                width: '100%',
              }}
            >
              <div style={{ height: '100%' }}>
                {currentSequenceItem?.isWaitingRoom ? (
                  <WaitingRoom
                    key={`${currentSequenceItem?.patientEducationToShow}-${currentSequenceIndex}`}
                    departmentIds={currentSequenceItem?.departmentIds}
                    language={currentSequenceItem?.language}
                    onEnd={onContentEnded}
                    duration={currentSequenceItem.patientEducationTime}
                    id={`${currentSequenceItem?.patientEducationToShow}-${currentSequenceIndex}`}
                  />
                ) : (
                  <Fragment>
                    {currentSequenceItem?.isVideo ? (
                      <VideoPlayer
                        key={currentSequenceItem?.patientContentUrl}
                        onEnd={onContentEnded}
                        url={currentSequenceItem?.patientContentUrl}
                        duration={currentSequenceItem.patientEducationTime}
                      />
                    ) : (
                      <div style={{ height: '90vh' }}>
                        <TimedIframe
                          url={currentSequenceItem?.patientContentUrl}
                          duration={Number(
                            currentSequenceItem.patientEducationTime
                          )}
                          onEnd={() => {
                            console.log(`content ended top`);
                            onContentEnded();
                          }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </LobbyProvider>
  );
};

export default Lobby;
