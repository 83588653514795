import axios from 'axios';
import { useEffect, useState } from 'react';

import { API } from '../apiconfig';
import { IAction } from '../interfaces/interfaces';

export const useFetchAllActions = (): {
  actions: IAction[];
  isLoading: boolean;
  error: string | null;
  refetch: () => void;
} => {
  const [actions, setActions] = useState<IAction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchAllActions = async () => {
    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    const URL = API.REACT_APP_API_GETALLACTIONS;
    const headers = {
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache',
    };

    try {
      const response = await axios.get(URL, { headers });
      setActions(response.data.data);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        console.log('Unauthorized, attempting to refresh token');
        // You might want to implement the checkRefreshToken function
        // and call it here, or handle the unauthorized case differently
        setError('Unauthorized. Please log in again.');
      } else {
        console.error('Error fetching actions:', err);
        setError('Failed to fetch actions. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllActions();
  }, []); // Empty dependency array means this effect runs once on mount

  const refetch = () => {
    setActions([]);
    setIsLoading(true);
    setError(null);
    fetchAllActions();
  };

  return { actions, refetch, isLoading, error };
};
