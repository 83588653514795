import { createTheme, makeStyles } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#A7D6A4',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
});

const styles: any = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  head: {
    backgroundColor: '#fafafa',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  sortSelect: {
    cursor: 'pointer',
  },
  tableRow: {
    cursor: 'pointer',
    height: 40,
  },
  drawer: {
    padding: 50,
  },
  closeButton: {
    cursor: 'pointer',
    color: 'red',
    textAlign: 'right',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    // width: 180,
    width: '100%',
  },
  autoCompleteTextField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 250,
  },
  saveWarning: {
    color: 'red',
    paddingBottom: 10,
  },
  newEHRMappingModal: {
    position: 'absolute',
    width: '70%',
    backgroundColor: '#FFFFFF',
    color: 'black',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  refreshButton: {
    zIndex: 10,
    backgroundColor: '#4FAC48',
    position: 'absolute',
    right: theme.spacing(3),
  },
  athenaLogo: {
    zIndex: 10,
    position: 'absolute',
    right: theme.spacing(20),
  },
  newEHRMappingImportButton: {
    zIndex: 10,
    backgroundColor: '#4FAC48',
    position: 'absolute',
    right: theme.spacing(12),
  },
  deleteButton: {
    border: '1px solid red',
    color: 'red',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  checkBox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const useStyles = makeStyles(styles);
