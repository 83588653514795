import { ChangeEvent, FC, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { API } from '../../../../apiconfig';

import { canDo } from '../../../../utils/permissionCheck';

import { checkRefreshToken } from '../../../../utils/utils';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseButton from '@material-ui/icons/Clear';
import ImportIcon from '@material-ui/icons/Publish';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import ErrorSnack from '../../../../utils/ErrorSnack';

import { filter } from 'lodash';
import { useDepartments } from '../../../../hooks/department/useDepartments';
import { useRooms } from '../../../../hooks/useRooms';
import { useSites } from '../../../../hooks/useSites';
import { IRoom, TAdminState } from '../../../../interfaces/interfaces';
import { NEW_ROOM_TEMPLATE } from './constants';
import { theme, useStyles } from './styles';

var uniqid: any = require('uniqid');

let importedRooms: any[] = [];

type TProps = {};

const Rooms: FC<TProps> = () => {
  const [completedCSVImport, setCompletedCSVImport] = useState(false);
  const [hideInactiveRooms, setHideInactiveRooms] = useState(true);
  const [filterString, setFilterString] = useState('');
  const [sortDescending, setSortDescending] = useState(false);
  const [sortBy, setSortBy] = useState('roomName');
  const [right, setRight] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [hasModified, setHasModified] = useState(false);
  const [roomToEdit, setRoomToEdit] = useState<IRoom | null>(null);
  const [newRoom, setNewRoom] = useState<boolean>(false);
  const [newRoomModalOpen, setNewRoomModalOpen] = useState(false);
  const [newRooms, setNewRooms] = useState<IRoom[]>([]);
  const [latestSuccesses, setLatestSuccesses] = useState(0);
  const [latestFails, setLatestFails] = useState(0);
  const [importReportDialog, setImportReportDialog] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);

  const roomTemplate = NEW_ROOM_TEMPLATE;

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const classes = useStyles();
  const { departments } = useDepartments({orgId: loggedInUserData?.mongoOrganizationId});
  const { sites } = useSites(loggedInUserData?.mongoOrganizationId);

  const { rooms, refetch: refetchRooms } = useRooms(
    loggedInUserData?.mongoOrganizationId
  );

  const handleChange = (input: { name: string; value: any }) => {
    const { name, value } = input;

    // console.log("Room To edit", this.state.roomToEdit);

    setRoomToEdit({
      ...roomToEdit,
      [name]: value,
    } as IRoom);

    setHasModified(true);
  };

  const closeWithoutSaving = (room: IRoom | null) => {
    setRight(false);
    setRoomToEdit(room);
    setShowSaveAlert(false);
    setHasModified(false);

    refetchRooms();
  };

  const toggleDrawer = (open: boolean, room: IRoom | null) => {
    if (canDo(['Update Rooms'], loggedInUserData)) {
      if (hasModified) {
        setShowSaveAlert(true);
      } else {
        setRight(open);
        setHasModified(false);
        setRoomToEdit(room);
        setFieldError(false);
        setNewRoom(false);
      }
    }
  };

  const onNewRoom = (room: any) => {
    // console.log("New Room", window.screen.width);

    if (window.screen.width > 1000) {
      setNewRoomModalOpen(true);
      setNewRooms([{ ...room, tempId: uniqid() }]);
      setCompletedCSVImport(true);
    } else {
      setRight(true);
      setNewRoom(true);
      setRoomToEdit(room);
      setCompletedCSVImport(true);
    }
  };

  const handleSubmit = () => {
    let token = localStorage.getItem('token');

    const roomName = roomToEdit?.roomName ?? '';

    if (roomName?.length < 1) {
      setFieldError(true);
    } else {
      // If new room
      if (newRoom) {
        console.log('New Room', roomToEdit);
        let newRoom = {
          ...roomToEdit,
          // AdminUserId: loggedInUserData.userId,
        };

        setHasModified(false);
        setRight(false);
        setFieldError(false);

        fetch(API.REACT_APP_API_ROOM, {
          method: 'POST',
          body: JSON.stringify(newRoom),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(data => {
            console.log(data);

            refetchRooms();

            setHasModified(false);
            setRight(false);
            setFieldError(false);
          })
          .catch(function (err) {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }

      // If modifying room
      else {
        // console.log('MODIFIED ROOM', this.state.roomToEdit)
        let modifiedRoom = {
          ...roomToEdit,
          // AdminUserId: loggedInUserData.userId,
        };
        console.log('Sending', modifiedRoom);
        fetch(API.REACT_APP_API_ROOM, {
          method: 'PUT',
          body: JSON.stringify(modifiedRoom),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            console.log(data);
            refetchRooms();

            setHasModified(false);
            setRight(false);
            setFieldError(false);
            setShowSaveAlert(false);
          })
          .catch(function (err) {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    }
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  const onAddRow = () => {
    // eslint-disable-next-line
    // @ts-ignore
    const newRoom = { ...roomTemplate, tempId: uniqid() } as IRoom;
    setNewRooms([...newRooms, newRoom]);
  };

  const handleNewRoomValue = (input: {
    name: string;
    tempId: string;
    value: any;
  }) => {
    const { name, tempId, value } = input;

    let rooms = [...newRooms];

    let roomIndex = rooms.findIndex(room => room.tempId === tempId);
    // console.log("Found room to modify", rooms[roomIndex]);

    rooms[roomIndex] = {
      ...rooms[roomIndex],
      [name]: value,
    };

    setNewRooms(rooms);
  };

  const handleNewRoomPatientRoom = (tempId: string) => {
    let rooms = [...newRooms];
    let roomIndex = rooms.findIndex(room => room.tempId === tempId);
    rooms[roomIndex] = {
      ...rooms[roomIndex],
      isPatientRoom: !rooms[roomIndex].isPatientRoom,
    };

    setNewRooms(rooms);
  };

  const onDeleteNewRoom = (tempId: string) => {
    let rooms = [...newRooms];
    let updatedRooms = filter(rooms, (room: { tempId: string }) => {
      return room.tempId !== tempId;
    }) as IRoom[];

    setNewRooms(updatedRooms);
  };

  const onCreateRooms = () => {
    let token = localStorage.getItem('token');
    let rooms = [...newRooms];

    console.log('rooms', rooms);

    let latestSuccesses = 0;
    let latestFails = 0;

    rooms.forEach(room => {
      if (!room.importSuccess) {
        let roomNameError = false;
        let departmentError = false;

        if (room.roomName.length < 1) {
          roomNameError = true;
        }

        if (room.mongoDeptId.length < 1) {
          departmentError = true;
        }

        if (roomNameError || departmentError) {
          latestFails++;

          let roomIndex = rooms.findIndex(obj => obj.tempId === room.tempId);
          rooms[roomIndex] = {
            ...rooms[roomIndex],
            roomNameError: roomNameError,
            departmentError: departmentError,
          };

          setNewRooms(rooms);
          setLatestSuccesses(latestSuccesses);
          setLatestFails(latestFails);
        } else {
          fetch(API.REACT_APP_API_ROOM, {
            method: 'POST',
            body: JSON.stringify(room),
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              return res.json();
            })
            .then(data => {
              latestSuccesses++;
              let objIndex = rooms.findIndex(obj => obj.tempId === room.tempId);
              rooms[objIndex] = {
                ...rooms[objIndex],
                roomNameError: false,
                departmentError: false,
                importSuccess: true,
              };
              setNewRooms(rooms);
              setLatestSuccesses(latestSuccesses);
              setLatestFails(latestFails);
            })
            .catch(err => {
              console.log('Error: ', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      }

      setImportReportDialog(true);
    });
  };

  const onCloseModal = () => {
    refetchRooms();
    setNewRoomModalOpen(false);
  };

  const newRoomImport = () => {
    setNewRoomModalOpen(true);
    setCompletedCSVImport(false);
  };

  const processRooms = (roomsArray: any) => {
    // console.log("processing rooms", roomsArray);
    let preppedRooms: any[] = [];

    roomsArray.forEach(
      (existingRoom: {
        departmentName: string;
        roomName: string;
        isPatientRoom: string;
      }) => {
        // TODO

        let foundDepartment = '';

        console.log('processing', roomsArray);

        departments.forEach(department => {
          if (existingRoom.departmentName) {
            if (
              department.siteName + ' ' + department.deptName ===
              existingRoom.departmentName
            ) {
              foundDepartment = department.id;
            }
          }
        });

        let isPatientRoom =
          existingRoom.isPatientRoom?.toLowerCase?.() === 'true';

        preppedRooms.push({
          tempId: uniqid(),
          roomName: existingRoom.roomName.trim(),
          isPatientRoom: isPatientRoom,
          mongoDeptId: foundDepartment,
          departmentError: false,
          importSuccess: false,
          isActive: true,
          roomNameError: false,
        });
      }
    );

    setNewRooms(preppedRooms);
  };

  // console.log("check", this.state.newRooms);

  let preppedRoomData: IRoom[] = [];

  let filteredRooms: IRoom[] = [];

  if (rooms) {
    let filter = sortBy;

    // console.log("SORT!", this.props.rooms);
    // Sort By Select
    preppedRoomData = [...rooms].sort((a: IRoom, b: IRoom) => {
      if (sortDescending) {
        // DESCENDING
        if (a[filter as keyof IRoom] > b[filter as keyof IRoom]) {
          return -1;
        }
        if (a[filter as keyof IRoom] < b[filter as keyof IRoom]) {
          return 1;
        }
        return 0;
      } else {
        // ASCENDING
        if (a[filter as keyof IRoom] < b[filter as keyof IRoom]) {
          return -1;
        }
        if (a[filter as keyof IRoom] > b[filter as keyof IRoom]) {
          return 1;
        }
        return 0;
      }
    });


    for (let i = 0; i < preppedRoomData.length; i++) {
      for (let d = 0; d < departments.length; d++) {
        // Grabbing and setting deptName
        if (departments[d].id === preppedRoomData[i].mongoDeptId) {
          preppedRoomData[i].deptName = departments[d].deptName;
        }

        // Grabbing and setting siteName
        for (let s = 0; s < sites.length; s++) {
          if (
            departments[d].mongoSiteId === sites[s].id &&
            departments[d].id === preppedRoomData[i].mongoDeptId
          ) {
            preppedRoomData[i].siteName = sites[s].siteName;
          }
        }
      }
    }

    // console.log("Prepped Room Data", preppedRoomData);

    let keyword = filterString;

    filteredRooms = preppedRoomData.filter((obj: IRoom) => {
      return (
        obj['roomName'].toUpperCase().includes(keyword.toUpperCase()) ||
        obj['deptName'].toUpperCase().includes(keyword.toUpperCase()) ||
        obj['siteName'].toUpperCase().includes(keyword.toUpperCase()) ||
        // check if obj.roomDevices array of objects has a deviceId that matches keyword
        obj.roomDevices.some((device: any) =>
          device.deviceId.toUpperCase().includes(keyword.toUpperCase())
        )
      );
    });
  }

  if (hideInactiveRooms) {
    let activeRooms: IRoom[] = [];
    filteredRooms.filter((item: IRoom) => {
      if (item.isActive) {
        activeRooms.push(item);
      }
      return null;
    });
    filteredRooms = activeRooms;
  }

  // console.log("All organization departments", this.props.organizationDepartments);
  // console.log("All sites", this.props.sites);

  let dropdownOptions: { id: string; deptName: string; siteName: string }[] =
    [];

  departments.forEach(dept => {
    sites.forEach(site => {
      if (dept.mongoSiteId === site.id && dept.isActive) {
        dropdownOptions.push({
          id: dept.id,
          deptName: dept.deptName,
          siteName: site.siteName,
        });
      }
    });
  });

  dropdownOptions.sort((a: any, b: any) => {
    // return a.siteName < b.siteName ? -1 : a.siteName > b.siteName ? 1 : 0;
    let aVal = a.siteName + a.deptName;
    let bVal = b.siteName + b.deptName;
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
  });

  let departmentOptions = dropdownOptions.map((option, key) => (
    <option key={key} value={option.id}>
      {option.siteName} {option.deptName}
    </option>
  ));

  let roomNameErrorFound = false;
  let departmentErrorFound = false;
  let roomImportsLeft = 0;
  newRooms.forEach(room => {
    if (room.roomNameError) {
      roomNameErrorFound = true;
    }
    if (room.departmentError) {
      departmentErrorFound = true;
    }
    if (!room.importSuccess) {
      roomImportsLeft++;
    }
  });

  // console.log("rooms left", roomImportsLeft);

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog open={showSaveAlert}>
          <DialogTitle>Unsaved changes!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to save your changes before exiting?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => handleSubmit()}>
              Yes
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => closeWithoutSaving(roomToEdit)}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Typography variant="h4" gutterBottom component="h2">
          Rooms
          {canDo(['SyncAdmin'], loggedInUserData) ? (
            <div>
              <Tooltip
                title="Add"
                aria-label="Add"
                // onClick={() => this.newRoom(this.state.roomTemplate)}
                onClick={() => onNewRoom(roomTemplate)}
              >
                <Fab className={classes.newRoomButton}>
                  <AddIcon />
                </Fab>
              </Tooltip>
              <Tooltip
                title="Click here to import rooms via CSV"
                aria-label="Add"
                onClick={() => newRoomImport()}
              >
                <Fab
                  className={
                    classes.newRoomImportButton +
                    ' fourth-step users-second-step'
                  }
                >
                  <ImportIcon />
                </Fab>
              </Tooltip>
            </div>
          ) : null}
        </Typography>

        <TextField
          label="Search"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleFilter(event)
          }
        />

        <br />

        <div
          onClick={() => {
            setHideInactiveRooms(!hideInactiveRooms);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={hideInactiveRooms}
          />
          Hide Inactive Rooms
        </div>

        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('roomName')}
                >
                  {sortBy === 'roomName' ? (
                    <div>Name {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Name</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('isActive')}
                >
                  {sortBy === 'isActive' ? (
                    <div>Is Active {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Is Active</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('deptName')}
                >
                  {sortBy === 'deptName' ? (
                    <div>Department {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Department</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('siteName')}
                >
                  {sortBy === 'siteName' ? (
                    <div>Site {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Site</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('ehrRoomId')}
                >
                  {sortBy === 'ehrRoomId' ? (
                    <div>EHR Room ID {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>EHR Room ID</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('isPatientRoom')}
                >
                  {sortBy === 'isPatientRoom' ? (
                    <div>
                      Is Patient Room {sortDescending ? <>▼</> : <>▲</>}
                    </div>
                  ) : (
                    <div>Is Patient Room</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('roomDevices')}
                >
                  {sortBy === 'roomDevices' ? (
                    <div>Device IDs {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Device IDs</div>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {console.log("filtered rooms", filteredRooms)} */}
              {filteredRooms.map(room => (
                <TableRow
                  key={room.id}
                  className={classes.tableRow}
                  onClick={() => toggleDrawer(true, room)}
                >
                  <TableCell className={classes.tableCell}>
                    {room.roomName}
                  </TableCell>
                  {room.isActive ? (
                    <TableCell className={classes.tableCell}>
                      <CheckIcon />
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}></TableCell>
                  )}
                  <TableCell className={classes.tableCell}>
                    {departments.map((dept, index) => (
                      <div key={index}>
                        {dept.id === room.mongoDeptId ? dept.deptName : null}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {departments.map((dept, index) => (
                      <div key={index}>
                        {sites.map((site, siteIndex) => (
                          <div key={siteIndex}>
                            {dept.mongoSiteId === site.id &&
                            dept.id === room.mongoDeptId
                              ? site.siteName
                              : null}
                          </div>
                        ))}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {room.ehrRoomId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {room.isPatientRoom ? <CheckIcon /> : null}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {room.roomDevices.map((device, index) => (
                      <div key={index}>
                        {device.deviceId} - {device.isInside ? 'IN' : 'OUT'}{' '}
                        {device.isLocationOnly ? '(RTLS Only)' : null}
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Modal open={newRoomModalOpen} onClose={() => onCloseModal()}>
          <div className={classes.newRoomModal}>
            {/* <h2>New Room(s)</h2> */}
            <Dialog
              onClose={() => {
                setImportReportDialog(false);
                setLatestSuccesses(0);
                setLatestFails(0);
              }}
              open={importReportDialog}
            >
              {/* <div style={{width: "100%", color: "red", cursor: "pointer"}}>X</div> */}
              <DialogTitle>Import Results</DialogTitle>
              <DialogContent>
                <div style={{ fontSize: 24, color: '#4FAC48' }}>
                  Success: {latestSuccesses}
                </div>
                <div
                  style={{
                    height: 1,
                    width: '100%',
                    backgroundColor: 'lightgray',
                  }}
                />
                <div style={{ fontSize: 24, color: 'red' }}>
                  Failure: {latestFails}
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() => {
                      setImportReportDialog(false);
                      setLatestSuccesses(0);
                      setLatestFails(0);
                    }}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 25 }}
                  >
                    Return
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Typography variant="h4" gutterBottom component="h2">
              New Room(s)
            </Typography>

            <div>
              {!completedCSVImport ? (
                <div>
                  <Importer
                    assumeNoHeaders={false}
                    restartable={false}
                    processChunk={async (rows, { startIndex }) => {
                      rows.forEach(row => {
                        importedRooms.push(row);
                      });
                    }}
                    onComplete={({ file, preview, fields, columnFields }) => {
                      processRooms(importedRooms);
                      setCompletedCSVImport(true);
                      importedRooms = [];
                    }}
                  >
                    <ImporterField name="roomName" label="Room Name" />
                    <ImporterField
                      name="isPatientRoom"
                      label="Is Patient Room"
                    />
                    <ImporterField name="departmentName" label="Department" />
                  </Importer>
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onNewRoom(roomTemplate)}
                  >
                    Manually add Rooms
                  </Button>
                </div>
              ) : (
                <div>
                  <TableContainer
                    component={Paper}
                    style={{ maxHeight: '75vh' }}
                  >
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>
                            <div>Room Name</div>
                            {roomNameErrorFound ? (
                              <div style={{ color: 'red', fontSize: 12 }}>
                                * Room Name is missing
                              </div>
                            ) : null}
                          </TableCell>
                          <TableCell>
                            <div>Is Patient Room</div>
                            {/* {lastNameErrorFound ? <div style={{ color: "red", fontSize: 12 }}>* Last Name missing</div> : null} */}
                          </TableCell>
                          <TableCell>
                            <div>Department</div>
                            {departmentErrorFound ? (
                              <div style={{ color: 'red', fontSize: 12 }}>
                                * Department is required
                              </div>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {newRooms.map((room: any, index: number) =>
                          !room.importSuccess ? (
                            <TableRow key={index} style={{ height: 10 }}>
                              <TableCell
                                onClick={() => onDeleteNewRoom(room.tempId)}
                                style={{
                                  color: 'red',
                                  cursor: 'pointer',
                                  fontSize: 18,
                                  fontWeight: 'bold',
                                  padding: 10,
                                  textAlign: 'center',
                                }}
                              >
                                X
                              </TableCell>
                              <TableCell
                                style={{ padding: 0 }}
                                component="th"
                                scope="user"
                              >
                                <TextField
                                  autoFocus
                                  error={room.roomNameError}
                                  required
                                  className={classes.textField}
                                  value={room.roomName}
                                  onChange={event =>
                                    handleNewRoomValue({
                                      name: 'roomName',
                                      tempId: room.tempId,
                                      value: event.target.value,
                                    })
                                  }
                                  margin="dense"
                                />
                              </TableCell>
                              <TableCell style={{ padding: 0 }} component="th">
                                <div
                                  onClick={() => {
                                    handleNewRoomPatientRoom(room.tempId);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Checkbox
                                    onKeyPress={e => {
                                      if (e.key === 'Enter') {
                                        console.log('Enter key pressed');
                                        handleNewRoomPatientRoom(room.tempId);
                                      }
                                    }}
                                    classes={{
                                      root: classes.checkBox,
                                      checked: classes.checked,
                                    }}
                                    checked={room.isPatientRoom}
                                  />
                                </div>
                              </TableCell>
                              <TableCell style={{ padding: 0 }}>
                                {/* <TextField
                                    error={user.emailError}
                                    required
                                    // label="User Email"
                                    className={classes.textField}
                                    value={user.userEmail}
                                    onChange={this.handleImportedUser("userEmail", user.importId)}
                                    margin="dense"
                                  /> */}
                                <FormControl
                                  className={classes.textField}
                                  margin="normal"
                                >
                                  {/* <InputLabel shrink htmlFor="age-native-label-placeholder">
                              Department *
                            </InputLabel> */}
                                  <NativeSelect
                                    error={room.departmentError}
                                    value={
                                      room.mongoDeptId ? room.mongoDeptId : ''
                                    }
                                    onChange={event => {
                                      handleNewRoomValue({
                                        name: 'mongoDeptId',
                                        tempId: room.tempId,
                                        value: event.target.value,
                                      });
                                    }}
                                    input={
                                      <Input id="age-native-label-placeholder" />
                                    }
                                  >
                                    <option />
                                    {departmentOptions}
                                  </NativeSelect>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ) : null
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ marginTop: 25, float: 'right' }}>
                    <Button
                      onClick={() => onAddRow()}
                      style={{ marginRight: 25 }}
                      variant="contained"
                    >
                      Add Row
                    </Button>
                    {roomImportsLeft === 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onCloseModal()}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onCreateRooms()}
                      >
                        {newRooms.length > 1 ? 'Create Rooms' : 'Create Room'}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>

        {/* Drawer to edit selected room */}
        <Drawer
          anchor="right"
          open={right}
          onClose={() => toggleDrawer(false, roomToEdit)}
        >
          {roomToEdit ? (
            <div className={classes.drawer}>
              <div
                className={classes.closeButton}
                onClick={() => toggleDrawer(false, roomToEdit)}
              >
                <CloseButton />
              </div>
              {newRoom ? (
                <h2>New Room</h2>
              ) : (
                <h2>Editing {roomToEdit?.roomName}</h2>
              )}
              <br />
              <TextField
                required
                label="Room Name"
                className={classes.textField}
                value={roomToEdit.roomName}
                onChange={event =>
                  handleChange({ name: 'roomName', value: event.target.value })
                }
                margin="normal"
              />
              <br />
              <TextField
                label="EHR Room ID"
                className={classes.textField}
                value={roomToEdit.ehrRoomId}
                onChange={event =>
                  handleChange({ name: 'ehrRoomId', value: event.target.value })
                }
                margin="normal"
              />
              <br />
              {newRoom === false ? (
                <div
                  onClick={() => {
                    setRoomToEdit({
                      ...roomToEdit,
                      isActive: !roomToEdit.isActive,
                    });

                    setHasModified(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={roomToEdit.isActive}
                  />
                  Is Active
                </div>
              ) : null}
              <div
                onClick={() => {
                  setRoomToEdit({
                    ...roomToEdit,
                    isHidden: !roomToEdit.isHidden,
                  });

                  setHasModified(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={roomToEdit.isHidden}
                />
                Hide on Flowstations
              </div>
              <div
                onClick={() => {
                  setRoomToEdit({
                    ...roomToEdit,
                    isPatientRoom: !roomToEdit.isPatientRoom,
                  });

                  setHasModified(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={roomToEdit.isPatientRoom}
                />
                Is Patient Room
              </div>
              {roomToEdit.mongoDeptId ? (
                <FormControl className={classes.textField} margin="normal">
                  <InputLabel shrink htmlFor="age-native-label-placeholder">
                    Department *
                  </InputLabel>
                  <NativeSelect
                    value={roomToEdit.mongoDeptId}
                    onChange={event =>
                      handleChange({
                        name: 'mongoDeptId',
                        value: event.target.value,
                      })
                    }
                    input={<Input id="age-native-label-placeholder" />}
                  >
                    {departmentOptions}
                  </NativeSelect>
                </FormControl>
              ) : (
                <FormControl className={classes.textField} margin="normal">
                  <InputLabel shrink htmlFor="age-native-label-placeholder">
                    Department *
                  </InputLabel>
                  <NativeSelect
                    value={roomToEdit.mongoDeptId ? roomToEdit.mongoDeptId : ''}
                    onChange={event =>
                      handleChange({
                        name: 'mongoDeptId',
                        value: event.target.value,
                      })
                    }
                    input={<Input id="age-native-label-placeholder" />}
                  >
                    <option />
                    {departmentOptions}
                  </NativeSelect>
                </FormControl>
              )}
              <br />
              <div style={{ marginLeft: 8 }}>
                <div style={{ color: '#757575', fontSize: 12, marginTop: 10 }}>
                  Device IDs:
                </div>
                {roomToEdit?.roomDevices?.map((device: any, index: number) => (
                  <div key={index}>{device.deviceId}</div>
                ))}
              </div>
              <br />
              {hasModified ? (
                <div className={classes.saveWarning}>Unsaved Changes!</div>
              ) : (
                <div />
              )}
              <Button
                className={classes.submitButton}
                onClick={() => handleSubmit()}
                color="primary"
              >
                Save
              </Button>
            </div>
          ) : (
            <div />
          )}
        </Drawer>

        {fieldError ? (
          <ErrorSnack
            errorSnack={true}
            errorMessage="Please Fill Out All Required Fields"
          />
        ) : (
          <div />
        )}
      </MuiThemeProvider>
    </div>
  );
};

export default Rooms;
