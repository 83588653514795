import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { TAdminState } from '../../interfaces/interfaces';
import { getQueryVariable } from '../../utils/utils';
import Lobby from './Lobby'; // Assuming this import exists

type TProps = {
  // loggedInUserData: any;
  // fetchLoggedInUser: any;
  authenticated: boolean;
};
const LobbyDataWrapper: FC<TProps> = props => {
  const { authenticated } = props;

  const [regularLogin, setRegularLogin] = useState(null);
  // const [fireLogin, setFireLogin] = useState(false);

  const { fetchUser } = useLoggedInUser();

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state.adminData ?? ({} as TAdminState);

      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    const userEmail = localStorage.getItem('email');
    const token = localStorage.getItem('token');

    if (userEmail && token) {
      setRegularLogin(true);
      // setFireLogin(false);

      let orgId = null;
      if (localStorage.getItem('lastMockedOrganizationId')) {
        orgId = localStorage.getItem('lastMockedOrganizationId');
        console.log('MOCKED', orgId);
        fetchUser({ email: userEmail, token, mockedOrgId: orgId });
      } else {
        console.log('fetch user data');
        fetchUser({ email: userEmail, token });
      }
    } else {
      setRegularLogin(false);
      // setFireLogin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (isEmpty(user)) return null;

  if (regularLogin === false) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          // @ts-ignore
          state: {
            cameFrom: window.location.href,
            username: getQueryVariable('username'),
            password: getQueryVariable('password'),
          },
        }}
      />
    );
  }

  return (
    <div>
      {loggedInUserData?.id ? <Lobby authenticated={authenticated} /> : <div />}
    </div>
  );
};

export default LobbyDataWrapper;
