import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { checkRefreshToken } from '../utils/utils';
import { IBenchMarkGroup } from '../interfaces/interfaces';
import { API } from '../apiconfig';

interface BenchmarkGroupsResponse {
  statuscode: number;
  message: string;
  instanceid: string;
  webhostname: string;
  data: IBenchMarkGroup[];
}

export const useBenchmarkGroups = () => {
  const [benchmarkGroups, setBenchmarkGroups] = useState<IBenchMarkGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchBenchmarkGroups = useCallback(async (): Promise<
    IBenchMarkGroup[]
  > => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get<BenchmarkGroupsResponse>(
        API.REACT_APP_API_BENCHMARKGROUPS,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Pragma: 'no-cache',
          },
        }
      );
      const fetchedGroups = response.data.data;
      setBenchmarkGroups(fetchedGroups);
      return fetchedGroups;
    } catch (err) {
      handleError(err);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBenchmarkGroups();
  }, [fetchBenchmarkGroups]);

  const refreshBenchmarkGroups = useCallback((): Promise<IBenchMarkGroup[]> => {
    return fetchBenchmarkGroups();
  }, [fetchBenchmarkGroups]);

  const handleError = (err: any) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      console.log('UNAUTHORIZED, KICK EM OUT');
      setError('Unauthorized. Please log in again.');
      checkRefreshToken();
    } else {
      console.error('Error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return {
    benchmarkGroups,
    isLoading,
    error,
    refreshBenchmarkGroups,
  };
};
