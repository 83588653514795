import * as signalR from '@microsoft/signalr';
import { FunctionsEndpoint } from './apiconfig';

// import io from "socket.io-client";
// import {
//   // REACT_APP_API_SOCKETURL,
//   REACT_APP_API_BASE_URL,
// } from "./apiconfig";

import { getQueryVariable } from './utils/utils';

let disableSignalManually = getQueryVariable('disableSignal');

console.log('socket manager invoking.');

let allowSignalR = false;
let es6Ready = false;

let token = localStorage.getItem('token');
let email = localStorage.getItem('email');

const getBearerToken = () => {
  let authToken = localStorage.getItem('token');

  if (authToken) {
    return authToken;
  } else {
    return '';
  }
};

// START SignalR ==============================================
let connection: any = null;

// let test = false;
let buildConnection;
if (!disableSignalManually) {
  // if (supportsES6()){

  buildConnection = function () {
    try {
      // connection = new signalR.HubConnectionBuilder()
      //   .withUrl(
      //     "https://synctimesazurefunctions.azurewebsites.net/api"

      //     , {
      //       accessTokenFactory: () => {
      //         return token;
      //       },
      //     }
      //   )
      //   .configureLogging(signalR.LogLevel.None)
      //   .withAutomaticReconnect({
      //     nextRetryDelayInMilliseconds: (retryContext) => {
      //       // Retry every 5 seconds
      //       console.log("RETRY!");
      //       return 5000;
      //     },
      //   })
      //   .build();

      console.log('SignalR using', FunctionsEndpoint + '/api');

      connection = new signalR.HubConnectionBuilder()
        .withUrl(
          FunctionsEndpoint + '/api',
          // "https://functions.synctimes.com/api"
          {
            accessTokenFactory: getBearerToken,
          }
        )
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            return 5000;
          },
        })
        .build();

      // console.log('success build')
      allowSignalR = true;
      es6Ready = true;
      return true;
    } catch (err) {
      console.log('SignalR issue', err);
      return false;
    }
  };

  buildConnection();
}

if (es6Ready) {
  connection.on('consoleLog', (message: string) => {
    console.log('Signal Console!', message);
  });

  async function start() {
    try {
      connection.serverTimeoutInMilliseconds = 10000;
      connection.keepAliveIntervalInMilliseconds = 5000;

      await connection.start();

      console.log(
        '%c SignalR Connected ',
        'background: #020F3D; color: #ffffff'
      );
      allowSignalR = true;
    } catch (err) {
      console.log('ERR TRYING TO CONNECT TO SIGNALR');
      console.log(err);
      setTimeout(start, 5000);
    }
  }

  connection.onclose(async () => {
    allowSignalR = false;
    await start();
  });

  if (
    token &&
    email &&
    connection.state === signalR.HubConnectionState.Disconnected
  ) {
    // Check token & email, then startup SignalR Connection
    start();
  } else {
    console.log('Missing Auth Reqs, avoid attemping SignalR');
  }
}

// setTimeout(function(){ allowSignalR = true; }, 20000);

export { connection, allowSignalR, buildConnection };
