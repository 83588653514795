import React, { Component } from "react";
import _ from "lodash";
import { BarChart, Bar, YAxis, XAxis, LabelList } from "recharts";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const styles = (theme) => ({
  cardContainer: {
    fontSize: 12,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 5,
    // marginTop: 5,
    border: "2px solid white",
    // backgroundColor: "transparent"
  },
  cardContainerDark: {
    fontSize: 12,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 5,
    // marginTop: 5,
    border: "2px solid #666666",
    backgroundColor: "#666666",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  cardTitle: {
    textAlign: "center",
    fontSize: 30,
    paddingLeft: 5,
    paddingRight: 5,
  },
  barChart: {
    margin: "auto",
  },
});

class VisitsFinishedCard extends Component {
  constructor() {
    super();
    this.state = {
      myKey: 1,
    };
  }

  updateState = () => {
    this.setState({
      myKey: this.state.myKey + 1,
    });
  };

  render() {
    const { classes } = this.props;

    let darkMode = false;
    if (localStorage.getItem("darkMode")) {
      let isDark = localStorage.getItem("darkMode");
      if (JSON.parse(isDark)) {
        darkMode = true;
      } else {
        darkMode = false;
      }
    }

    let unorderedData = this.props.providerReporting.dailyData;

    // let unorderedData = [
    //   { providerName: "Unassigned", patientsSeen: 2, aloneTime: 6, staffTime: 4.6, providerTime: 0, providerId: null },
    //   { providerName: "Katie Gray", patientsSeen: 1, aloneTime: 13.6, staffTime: 7.5, providerTime: 32.9, providerId: "5f49ca01e0a5dc5f705b1a74" },
    //   { providerName: "Saad Hijazi", patientsSeen: 2, aloneTime: 6.8, staffTime: 7.5, providerTime: 10.2, providerId: "5f49ca01e0a5dc5f705b1a75" },
    // ];

    // console.log(JSON.stringify(this.props.providerReporting.dailyData));

    // let testData = [
    //   { providerName: "Chase", patientsSeen: 2, aloneTime: 7544.3, staffTime: null, providerTime: null, providerId: "5f49ca01e0a5dc5f705b16ac" },
    //   { providerName: "Mike", patientsSeen: 3, aloneTime: 5837.7, staffTime: null, providerTime: null, providerId: "5f49ca01e0a5dc5f705b1a33" },
    //   { providerName: "Jim", patientsSeen: 2, aloneTime: 5837.7, staffTime: null, providerTime: null, providerId: "5f49ca01e0a5dc5f705b1a38" },
    // ];

    // data = _.orderBy(data, ["patientsSeen"], ["desc"]); // Use Lodash to sort array by 'name'

    let orderData = (data) => {
      let filtered = _.orderBy(data, ["patientsSeen", "providerName"], ["desc"]);
      return filtered;
    };

    // console.log("ORDERED DATA", orderData(unorderedData));

    let data = orderData(unorderedData);

    return (
      // Recharts bug causing Y axis to not update correctly, so we are using a 'key' on the containing div to reference a always changing servertime to force everything to update on a prop change
      <div className={"noselect"} style={{ maxWidth: 257 }}>
        <div style={{ backgroundColor: this.props.roomColor, height: 10, width: "100%", marginBottom: 5 }}></div>
        <div className={classes.flexContainer} key={this.props.providerReporting.id}>
          {this.props.providerReporting.dailyData.length > 0 ? (
            <Card className={darkMode ? classes.cardContainerDark : classes.cardContainer} style={this.props.largeCards ? { height: 424 } : { height: 330 }}>
              <div style={darkMode ? { backgroundColor: "#333333", border: "2px solid #333333", color: "white" } : { border: "2px solid white" }}>
                <div className={classes.cardTitle} style={darkMode ? { color: "white" } : null}>
                  Visits Finished
                </div>
                <BarChart
                  className={classes.barChart}
                  width={this.props.largeCards ? 513 : 250}
                  height={this.props.largeCards ? 390 : 290}
                  // data={this.props.providerReporting.dailyData}
                  data={data}
                  layout="vertical"
                >
                  <XAxis hide={true} type="number" />
                  <YAxis type="category" dataKey="providerName" key={Math.random()} />
                  <Bar name="Patients Seen" dataKey="patientsSeen" fill="#4CB946" isAnimationActive={false}>
                    <LabelList fill="#ffffff" dataKey="patientsSeen" position="center" fontSize={this.props.largeCards ? 16 : 12} />
                  </Bar>
                </BarChart>
              </div>
            </Card>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(VisitsFinishedCard);
