import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FC } from 'react';
import { useStyles } from '../styles';

import LobbySequenceFormContainer from './lobbySequence/LobbySequenceFormContainer';
import LobbySettingsFormContainer from './lobbySettings/LobbySettingsFormContainer';

type TProps = {
  onClose: () => void;
  open: boolean;
  orgId: string;
};

const LobbySettingsModal: FC<TProps> = props => {
  const { onClose, open, orgId } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
      classes={{
        paper: 'w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl',
      }}
    >
      <div className="flex justify-between">
        <DialogTitle>Lobby Settings</DialogTitle>
        <Button aria-label="close" onClick={onClose} className="">
          <CloseIcon />
        </Button>
      </div>
      <DialogContent>
        <LobbySettingsFormContainer onClose={onClose} orgId={orgId} />

        <LobbySequenceFormContainer onClose={onClose} orgId={orgId} />
      </DialogContent>
    </Dialog>
  );
};

export default LobbySettingsModal;
