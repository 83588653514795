import React, { useCallback } from "react";

import { Paper, Typography, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import ScheduledEmailsListCell from "./ScheduledEmailsListCell";
import { ScheduledEmailReport } from "../emailReportingTypes";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

interface ScheduledEmailsListProps {
  userId: string;
  scheduledReports: ScheduledEmailReport[];
  onEditReport: (report: ScheduledEmailReport) => void;
  onRemoveReport: () => void;
}

const ScheduledEmailsList: React.FC<ScheduledEmailsListProps> = ({ userId, scheduledReports, onEditReport, onRemoveReport }) => {
  const classes = useStyles();

  const handleOpenModal = useCallback(
    (report: ScheduledEmailReport) => {
      onEditReport(report);
    },
    [onEditReport]
  );

  if (scheduledReports === null) {
    return <Typography>Loading reports...</Typography>;
  } else if (scheduledReports.length === 0) {
    return (
      <div>
        <Typography variant="body1"></Typography>
      </div>
    );
  }

  return (
    <Paper
      elevation={3}
      style={{
        overflow: "hidden",
        maxWidth: "500px",
        minWidth: "300px",
        maxHeight: "calc(100vh - 200px)", // Set a max height based on the viewport height
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.paper}
    >
      <Typography variant="h4" component="p" gutterBottom color="textSecondary">
        Scheduled Email Reports
      </Typography>
      <Box
        style={{
          width: "100%",
          overflowY: "auto", // Enable vertical scrolling
          flexGrow: 1, // Allow the content to grow and fill the available space
        }}
      >
        {scheduledReports.map((report, index) => (
          <React.Fragment key={report.id}>
            <ScheduledEmailsListCell report={report} onEditReport={handleOpenModal} userId={userId} onRemoveReport={onRemoveReport} />
            {index !== scheduledReports.length - 1 && <Divider style={{ margin: "16px 0" }} />}
          </React.Fragment>
        ))}
      </Box>
    </Paper>
  );
};

export default ScheduledEmailsList;
