import { makeStyles } from '@material-ui/core';

const styles: any = {
  card: {
    maxWidth: 1000,
  },
  drawer: {
    padding: 50,
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  actionImage: {
    maxWidth: 50,
    maxHeight: 50,
    paddingRight: 5,
  },
  addIconSquare: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 10,
    textAlign: 'center',
    cursor: 'pointer',
    color: '#bdbdbd',
    border: '1px dashed #bdbdbd',
    height: 48,
    width: 48,
  },
  closeButton: {
    cursor: 'pointer',
    color: 'red',
    textAlign: 'right',
  },
  submitButton: {
    backgroundColor: '#F0F1F9',
  },
  checkBox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
  saveWarning: {
    color: 'red',
    paddingBottom: 10,
  },
};

export const useStyles = makeStyles(styles);
