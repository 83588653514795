import { Button, FormControl, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { sortBy } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { IBadge } from '../../../../interfaces/interfaces';

type TProps = {
  classes: any;
  deleteBadge: (badgeId: string) => void;
  onAddBeacon: (beacon: string, expirationDate: string) => void;
  setBeaconNumberObject: (beaconNumberObject: any) => void;
  beaconNumberObject: any;
  handleNewBadge: (input: { overwrite?: boolean }) => void;
  displayBadgeExpiration: boolean;
  newBadgeExpiration: string | null;
  restrictedBadgeNumber: boolean;
  handleBadgeExpiration: (event: ChangeEvent<HTMLInputElement>) => void;
  nextFocusObject: any;
  setNextFocusObject: (nextFocusObject: any) => void;
  userBadages: IBadge[];
};

const Beacons: FC<TProps> = props => {
  const {
    classes,
    deleteBadge,
    displayBadgeExpiration,
    handleNewBadge,
    newBadgeExpiration,
    restrictedBadgeNumber,
    handleBadgeExpiration,
    setBeaconNumberObject,
    beaconNumberObject,
    setNextFocusObject,
    nextFocusObject,
    userBadages = [],
  } = props;

  const newUserBadgeInputs = (
    beaconNumber: string,
    value: any,
    nextFocusId: string | null
  ) => {
    if (value) {
      var lastChar = 0;
      if (value.length > 1) {
        lastChar = value[value.length - 1];
      } else {
        lastChar = value;
      }

      function isNumeric(str: number) {
        if (typeof str != 'string') return false; // we only process strings!
        return (
          !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str))
        ); // ...and ensure strings of whitespace fail
      }

      if (isNumeric(lastChar)) {
        //@ts-ignore

        setBeaconNumberObject({
          ...beaconNumberObject,
          [beaconNumber]: lastChar,
        });
        if (nextFocusId) {
          //@ts-ignore
          // this.setState({ [nextFocusId]: '' });
          setNextFocusObject({
            ...nextFocusObject,
            [nextFocusId]: '',
          });

          //@ts-ignore
          document.getElementById(nextFocusId).focus();
        } else {
          //@ts-ignore
          document.getElementById('insideAddBeaconButton').focus();
        }
      }
    }
  };

  let alphabetizedAppUserBadges: IBadge[] = sortBy(userBadages, 'badgeString');

  return (
    <div>
      <FormControl>
        {alphabetizedAppUserBadges.length > 0 ? (
          <div className={classes.badgesTitle}>Badges:</div>
        ) : (
          <div />
        )}

        {alphabetizedAppUserBadges ? (
          <div>
            {alphabetizedAppUserBadges.map((badge: IBadge) => (
              <div
                key={badge.badgeString}
                className="flex justify-between items-center"
              >
                {/* {badge.badgeString} <button onClick={() => this.deleteBadge(badge.badgeString)}>x</button> */}

                <div className={classes.badgeNumber}>
                  {badge.badgeString}{' '}
                  {badge.expirationDate ? (
                    <span className={classes.expirationDateText}>
                      (exp. {badge.expirationDate.substring(0, 10)})
                    </span>
                  ) : (
                    ''
                  )}
                </div>

                <div>
                  <Button
                    className={classes.deleteButton}
                    onClick={() => {
                      deleteBadge(badge.badgeString);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div />
        )}
      </FormControl>

      <Tooltip
        title="This is the badge ID number assigned to the staff member's beacon. The badge's ID typically starts with 'IR'. After inputting the ID, select 'Add Badge' below."
        placement="right"
      >
        <div>
          <div
            style={{
              color: '#757575',
              marginBottom: 5,
              fontSize: 14,
            }}
          >
            New Badge:
          </div>
        </div>
      </Tooltip>

      <div className="flex gap-6">
        <div
          style={{
            color: '#757575',
            fontSize: 24,
            marginBottom: 'auto',
            marginTop: 'auto',
          }}
        >
          IR
        </div>

        {[1, 2, 3, 4].map((beaconNumber, index) => (
          <TextField
            key={index}
            type="tel"
            id={`beacon${beaconNumber}`}
            style={{ width: 50, height: 50 }}
            inputProps={{
              min: 0,
              style: {
                textAlign: 'center',
                fontSize: 24,
                padding: 10,
              },
            }}
            value={beaconNumberObject[`beacon${beaconNumber}`]}
            onClick={() => {
              setBeaconNumberObject({
                ...beaconNumberObject,
                [`beacon${beaconNumber}`]: '',
              });
            }}
            onChange={e => {
              let nextBeacon: string | null = `beacon${beaconNumber + 1}`;

              if (beaconNumber === 4) {
                nextBeacon = null;
              }

              newUserBadgeInputs(
                `beacon${beaconNumber}`,
                e.target.value,
                nextBeacon
              );
            }}
            variant="outlined"
          />
        ))}
      </div>
      {restrictedBadgeNumber ? (
        <div style={{ color: 'red' }}>IR0000 is not allowed</div>
      ) : null}

      {displayBadgeExpiration ? (
        <div>
          <div className={classes.expireTitle}>
            Beacon Expiration: (00/00/0000)
          </div>
          <Tooltip title="Leave blank if no expiration." placement="right">
            <TextField
              type="date"
              value={newBadgeExpiration ? newBadgeExpiration : ''}
              className={classes.textBadgeExpireField}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleBadgeExpiration(event)
              }
              margin="normal"
              placeholder={'Never'}
            />
          </Tooltip>
        </div>
      ) : (
        <div />
      )}

      <div className="mt-4">
        <Button
          id="insideAddBeaconButton"
          disabled={
            !beaconNumberObject['beacon1'] ||
            !beaconNumberObject['beacon2'] ||
            !beaconNumberObject['beacon3'] ||
            !beaconNumberObject['beacon4']
          }
          onClick={() =>
            handleNewBadge({
              overwrite: false,
            })
          }
          component="label"
          color="primary"
        >
          Add Badge
        </Button>
      </div>
    </div>
  );
};

export default Beacons;
