import { useCallback } from 'react';
import { IAction, IApp } from '../../../../../interfaces/interfaces';
import { useNotify } from '../../../../../common/notify/useNotify';
import { useDeviceAction } from '../../../../../hooks/useDeviceAction';

export const useDeviceDetails = (
  deviceId: string,
  userId: string,
  onClose: () => void
) => {
  const { notify } = useNotify();
  const { deviceActionSend } = useDeviceAction();

  const onActionSend = useCallback(
    (
      selectedAction: IAction,
      options?: { shouldClose?: boolean; selectedApp?: IApp }
    ) => {
      const shouldClose = options?.shouldClose ?? true;

      if (selectedAction) {
        return new Promise<void>((resolve, reject) => {
          deviceActionSend({
            deviceIds: [deviceId],
            selectedAction,
            userId,
            selectedApp: options?.selectedApp,
            onSuccess: () => {
              notify({
                message: 'Action sent successfully',
                variant: 'success',
              });

              if (shouldClose) {
                onClose();
              }
              resolve();
            },
            onFail: (error: any) => {
              notify({
                message: 'Failed to send action',
                variant: 'error',
              });

              if (shouldClose) {
                onClose();
              }
              reject(error);
            },
          });
        });
      }
    },
    [deviceId, userId, onClose, notify, deviceActionSend]
  );

  return { onActionSend };
};
