import { ChangeEvent, FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { API } from '../../../../apiconfig';
import ConnectivityChart from '../charting/ConnectivityChart';

import { checkRefreshToken } from '../../../../utils/utils';

import { canDo } from '../../../../utils/permissionCheck';

import SuccessSnack from '../../../../utils/snack/SuccessSnack';

// Material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDisplays } from '../../../../hooks/useDisplays';
import { useLog } from '../../../../hooks/useLog';
import { IDevice, TAdminState } from '../../../../interfaces/interfaces';
import { theme } from './styles';
import { useStyles } from './styles';

type TProps = {};

const Displays: FC<TProps> = () => {
  const classes = useStyles();
  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const [sortBy, setSortBy] = useState('deviceLocation');
  const [filterString, setFilterString] = useState('');
  const [sortDescending, setSortDescending] = useState(false);

  const viewDemoDevices = false;
  const showExamRooms = true;
  const nonAndroidTablets = false;
  const showMissingAppDevices = false;
  const showAllDevices = false;

  const [devicesTimed, setDevicesTimed] = useState([]);
  const [screenshotTaken, setScreenshotTaken] = useState(false);
  const [showRebootWarning, setShowRebootWarning] = useState(false);
  const [deviceToReboot, setDeviceToReboot] = useState(null);
  const [deviceRebooted, setDeviceRebooted] = useState(false);
  const showFlowstations = true;

  const { devices: deviceList, refetch: refetchDisplays } = useDisplays(
    loggedInUserData?.mongoOrganizationId
  );
  const { logAction } = useLog();

  useEffect(() => {
    logAction(
      'Viewed Org Display',
      loggedInUserData.mongoOrganizationId,
      loggedInUserData.id
    );
    // eslint-disable-next-line
  }, [loggedInUserData]);

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      refetchDisplays();
    }, 120000); // 120000 ms = 2 minutes

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []); //

  const onHandleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const onHandleRefresh = () => {
    refetchDisplays();
  };

  const onTakeScreenshot = (device: IDevice) => {
    logAction(
      'Screenshot Org Display',
      loggedInUserData.mongoOrganizationId,
      loggedInUserData.id
    );
    let token = localStorage.getItem('token');
    let payload = {
      examRooms: [{ deviceId: device.deviceId }],
      actionTypeId: 5,
    };

    let currentDevicesTimed = [...devicesTimed];
    if (!currentDevicesTimed.includes(device.deviceId)) {
      currentDevicesTimed.push(device.deviceId);
    } else {
      currentDevicesTimed.splice(devicesTimed.indexOf(device.deviceId), 1);
    }

    // console.log("set this", devicesTimed);
    setDevicesTimed(currentDevicesTimed);
    setScreenshotTaken(true);

    if (!devicesTimed.includes(device.deviceId)) {
      // continue here, determined fi we should allow fetch.
      fetch(API.REACT_APP_API_ADDDEVICEACTION, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          // console.log("Success!", res);

          let runRefetchDisplays = () => {
            // Remove device id from devicesTimes
            let list = [...devicesTimed];
            let index = list.indexOf(device.deviceId);
            if (index > -1) {
              list.splice(index, 1);
            }

            let updatedDevicesTimed = list.splice(
              list.indexOf(device.deviceId),
              1
            );
            // console.log("Refetch!", updatedDevicesTimed);

            refetchDisplays();
            setDevicesTimed(updatedDevicesTimed);
          };

          setTimeout(runRefetchDisplays, 7000);
        })
        .catch(err => {
          console.log('Err sending screenshot request.');
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }

    setTimeout(() => setScreenshotTaken(false), 5000);
  };

  const onRebootDevice = () => {
    if (deviceToReboot) {
      let device = deviceToReboot;
      console.log('reboot', deviceToReboot);

      logAction(
        'Rebooted Org Display',
        loggedInUserData.mongoOrganizationId,
        loggedInUserData.id
      );
      let token = localStorage.getItem('token');
      let payload = {
        examRooms: [{ deviceId: device.deviceId }],
        actionTypeId: 1,
      };

      let currentDevicesTimed = [...devicesTimed];
      if (!currentDevicesTimed.includes(device.deviceId)) {
        currentDevicesTimed.push(device.deviceId);
      } else {
        currentDevicesTimed.splice(
          currentDevicesTimed.indexOf(device.deviceId),
          1
        );
      }

      // console.log("set this", devicesTimed);

      setDevicesTimed(currentDevicesTimed);
      setDeviceRebooted(true);

      if (!devicesTimed.includes(device.deviceId)) {
        // continue here, determined fi we should allow fetch.
        fetch(API.REACT_APP_API_ADDDEVICEACTION, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(res => {
            console.log('Success!', res);

            let refetchDisplays = () => {
              // Remove device id from devicesTimes
              let list = [...devicesTimed];
              let index = list.indexOf(device.deviceId);
              if (index > -1) {
                list.splice(index, 1);
              }

              let updatedDevicesTimed = list.splice(
                list.indexOf(device.deviceId),
                1
              );

              refetchDisplays();
              setDevicesTimed(updatedDevicesTimed);
            };

            setTimeout(refetchDisplays, 7000);

            setDeviceToReboot(null);
            setShowRebootWarning(false);
          })
          .catch(err => {
            console.log('Err sending screenshot request.');
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }

      setTimeout(() => setDeviceRebooted(false), 5000);
    } else {
      setShowRebootWarning(false);
      setDeviceToReboot(null);
    }
  };

  let filteredDevices: IDevice[] = [];

  if (deviceList) {
    let filter = sortBy;
    // Sort by Select
    //   console.log("Filtering by", this.state.sortBy);
    //   console.log("Descending?", this.state.sortDescending);

    filteredDevices = deviceList.sort((a: IDevice, b: IDevice) => {
      if (sortDescending) {
        // DESCENDING
        if (a[filter as keyof IDevice] > b[filter as keyof IDevice]) {
          return -1;
        }
        if (a[filter as keyof IDevice] < b[filter as keyof IDevice]) {
          return 1;
        }
        return 0;
      } else {
        // ASCENDING
        if (a[filter as keyof IDevice] < b[filter as keyof IDevice]) {
          return -1;
        }
        if (a[filter as keyof IDevice] > b[filter as keyof IDevice]) {
          return 1;
        }
        return 0;
      }
    });

    filteredDevices.sort((a: IDevice, b: IDevice) => {
      if (sortDescending) {
        // DESCENDING
        if (a[filter as keyof IDevice] > b[filter as keyof IDevice]) {
          return -1;
        }
        if (a[filter as keyof IDevice] < b[filter as keyof IDevice]) {
          return 1;
        }
        return 0;
      } else {
        // ASCENDING
        if (a[filter as keyof IDevice] < b[filter as keyof IDevice]) {
          return -1;
        }
        if (a[filter as keyof IDevice] > b[filter as keyof IDevice]) {
          return 1;
        }
        return 0;
      }
    });

    filteredDevices.sort((a: IDevice, b: IDevice) => {
      if (a['isConnectedToHub'] > b['isConnectedToHub']) {
        return 1;
      }
      if (a['isConnectedToHub'] < b['isConnectedToHub']) {
        return -1;
      }
      return 0;
    });

    let keyword = filterString.toUpperCase();
    filteredDevices = deviceList.filter((obj: IDevice) => {
      return (
        (obj.deviceId && obj.deviceId.toUpperCase().includes(keyword)) ||
        (obj.deviceLocation && obj.deviceLocation.toUpperCase().includes(keyword)) ||
        (obj.macAddress && obj.macAddress.toUpperCase().includes(keyword))
      );
    });

    if (!showAllDevices) {
      if (viewDemoDevices) {
        // Filter to ONLY view demo devices
        let customerDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isCustomerDevice === false) {
            customerDevices.push(device);
          }
          return null;
        });
        filteredDevices = customerDevices;
      } else {
        // Filter to ONLY view customer devices
        let customerDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isCustomerDevice === true) {
            customerDevices.push(device);
          }
          return null;
        });
        filteredDevices = customerDevices;
      }

      if (nonAndroidTablets) {
        let devices: IDevice[] = [];
        filteredDevices.filter(device => {
          if (device.isAndroidTablet === false) {
            devices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = devices;
      } else {
        let devices: IDevice[] = [];
        filteredDevices.filter(device => {
          if (device.isAndroidTablet === true) {
            devices.push(device);
            return null;
          }
          return null;
        });
        // console.log("filtered", devices);
        filteredDevices = devices;
      }

      if (showExamRooms && !showFlowstations) {
        // console.log("show ex");
        let examRooms: IDevice[] = [];
        filteredDevices.filter(device => {
          if (device.isFs === false) {
            examRooms.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = examRooms;
      } else if (!showExamRooms && showFlowstations) {
        // console.log("show fs");
        let flowstations: IDevice[] = [];
        filteredDevices.filter(device => {
          if (device.isFs === true) {
            flowstations.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = flowstations;
      }

      if (showMissingAppDevices) {
        // console.log('show devices with old app')
        let missingAppDevices: IDevice[] = [];
        filteredDevices.filter(device => {
          if (device.mongoAppId !== device.mongoLastLoggedAppId) {
            missingAppDevices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = missingAppDevices;
      }
    }
  }

  return (
    <div>
      {canDo(['View Displays'], loggedInUserData) ? (
        <div>
          <Dialog
            open={showRebootWarning}
            onClose={() => {
              setShowRebootWarning(false);
              setDeviceToReboot(null);
            }}
          >
            <DialogTitle>
              Are you sure you want to reboot this device?
            </DialogTitle>
            <DialogActions>
              <Button color="primary" onClick={onRebootDevice}>
                Yes
              </Button>
              <Button
                color="primary"
                autoFocus
                onClick={() => {
                  setShowRebootWarning(false);
                  setDeviceToReboot(null);
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

          <MuiThemeProvider theme={theme}>
            <Typography variant="h4" gutterBottom component="h2">
              Organization Displays
              <Tooltip
                title="Refresh Device List"
                aria-label="Refresh"
                onClick={onHandleRefresh}
              >
                <Fab className={classes.refreshButton}>
                  <RefreshIcon />
                </Fab>
              </Tooltip>
            </Typography>
            <TextField
              label="Search"
              className={classes.sortField}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onHandleFilter(event)
              }
            />

            <ConnectivityChart devices={deviceList} />

            <br />

            <Paper className={classes.root}>
              <Grid item xs={12}>
                <Table className={classes.table}>
                  <TableHead className={classes.head}>
                    <TableRow>
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('deviceLocation')}
                      >
                        {sortBy === 'deviceLocation' ? (
                          <div>
                            Device Location {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Device Location</div>
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('minutesSinceLastScreenshot')}
                      >
                        {sortBy === 'minutesSinceLastScreenshot' ? (
                          <div>
                            Screenshot Time {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Screenshot Time</div>
                        )}
                      </TableCell>
                      <TableCell className={classes.sortSelect}>
                        <div>Last Image</div>
                      </TableCell>
                      {/* MAC ADDRESS HEADER */}
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('macAddress')}
                      >
                        {sortBy === 'macAddress' ? (
                          <div>
                            MAC Address {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>MAC Address</div>
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('deviceId')}
                      >
                        {sortBy === 'deviceId' ? (
                          <div>DeviceId {sortDescending ? <>▼</> : <>▲</>}</div>
                        ) : (
                          <div>DeviceId</div>
                        )}
                      </TableCell>

                      <TableCell
                        className={classes.sortSelect}
                        // onClick={() => this.sortBy("minutesSinceLastConnection")}
                      >
                        {sortBy === 'minutesSinceLastConnection' ? (
                          <div>
                            Connection Status {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Connection Status</div>
                        )}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDevices.map((device: IDevice) => (
                      <TableRow
                        key={device.deviceId}
                        className={classes.tableRow}
                        style={
                          // Set background color of device whether the minutes last seen is >10 or >20
                          device.isConnectedToHub
                            ? {}
                            : { backgroundColor: '#ffb2b2' }
                        }
                      >
                        <TableCell className={classes.tableCell}>
                          {device.deviceLocation}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {device.minutesSinceLastScreenshot !== null ? (
                            <div>
                              {device.minutesSinceLastScreenshot} Minutes Ago
                            </div>
                          ) : (
                            <div />
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {device.screenshotUrl !== null ? (
                            <img
                              className="smallImage"
                              src={device.screenshotUrl}
                              alt=""
                            />
                          ) : (
                            <div />
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {device.macAddress}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {device.deviceId}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {device.isConnectedToHub
                            ? 'Connected'
                            : 'Disconnected for ' +
                              device.minutesSinceLastConnection +
                              ' minutes'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDo(['Display Updates'], loggedInUserData) ? (
                            <Button
                              disabled={devicesTimed.includes(device.deviceId)}
                              color="primary"
                              variant="contained"
                              onClick={() => onTakeScreenshot(device)}
                            >
                              Screenshot
                            </Button>
                          ) : null}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDo(['Display Updates'], loggedInUserData) ? (
                            <Button
                              style={{ backgroundColor: 'red', color: 'white' }}
                              disabled={devicesTimed.includes(device.deviceId)}
                              // color="secondary"
                              variant="contained"
                              onClick={() => {
                                setShowRebootWarning(true);
                                setDeviceToReboot(device);
                              }}
                            >
                              Reboot
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Paper>

            {screenshotTaken ? (
              <SuccessSnack
                autoHideDuration={2000}
                successSnack={true}
                successMessage="Requesting screenshot from device"
              />
            ) : null}

            {deviceRebooted ? (
              <SuccessSnack
                autoHideDuration={2000}
                successSnack={true}
                successMessage="Requesting reboot from device"
              />
            ) : null}
          </MuiThemeProvider>
        </div>
      ) : null}
    </div>
  );
};

export default Displays;
