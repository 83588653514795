import { useEffect } from 'react';
import { IProvider } from '../../interfaces/interfaces';
import { useProviderAssignmentUsers } from '../useProviderAssignmentUsers/useProviderAssignmentUsers';

type TProps = {
  orgId: string;
};

const useProviders = (
  input: TProps
): {
  providers: IProvider[];
  loading: boolean;
  error: any;
} => {
  const { orgId } = input;

  const { providerAssignmentUsers, refetch, isLoading, error } =
    useProviderAssignmentUsers(orgId);

  const providers = providerAssignmentUsers?.allProviders ?? [];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  return {
    providers,
    loading: isLoading,
    error,
  };
};

export default useProviders;
