import { FC } from 'react';
import { useSelector } from 'react-redux';
// import axios from "axios";
import { getQueryVariable } from '../../utils/utils';

import SmallIcon from '../../images/syncicon.png';
import synctimesLogoDev from '../../images/synctimes-development.png';
import synctimesLogo from '../../images/synctimes.png';

import { logout } from '../../actions/adminActions';

import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
// import { red } from '@material-ui/core/colors';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';

import { shallowEqual } from 'react-redux';
import { useDepartment } from '../../hooks/department/useDepartment';
import { IAction, TAdminState, TGroupState } from '../../interfaces/interfaces';
import ActionCard from './ActionCard';
import { useStyles } from './styles';

type TProps = {};

const Documentation: FC<TProps> = props => {
  const classes = useStyles();

  let deptId: string | undefined = getQueryVariable('deptId');

  const { site, organization } = useSelector(
    (state: { adminData: TAdminState; groupData: TGroupState }) => {
      return {
        groupData: state.groupData.groupData,
        loggedInUserData: state.adminData.loggedInUserData,
        department: state.adminData.department,
        siblingDepts: state.groupData.siblingDepts,
        site: state.adminData.site,
        errorLoggedInUser: state.adminData.errorLoggedInUser,
        organization: state.adminData.organization,
      };
    },
    shallowEqual
  );

  const { department } = useDepartment({ departmentId: deptId });

  // useEffect(() => {
  //   if (userEmail && token) {
  //     fetchLoggedInUser(userEmail, token, null);
  //     fetchDepartmentAndSite(deptId);
  //   }
  // }), [deptId, userEmail, token];

  // useEffect(() => {
  //   if (userEmail && token) {
  //     fetchLoggedInUser(userEmail, token, null);
  //     fetchDepartmentAndSite(deptId);
  //   }
  // }, [deptId]);

  const onLogout = () => {
    logout();
  };

  // console.log('this', site)

  // console.log('dept data:', department)
  let dept = department;

  let isDev = false;
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'dev.synctimes.com'
  ) {
    isDev = true;
  }

  let visibleActions: IAction[] = [];
  let hiddenActions: IAction[] = [];

  if (dept.actionsList) {
    dept.actionsList.forEach((action: IAction) => {
      if (action.isVisible) {
        visibleActions.push(action);
      } else {
        hiddenActions.push(action);
      }
    });
  }

  return (
    <div>
      <Toolbar className={classes.appBar}>
        <Typography>
          <a href="/">
            <img
              className={classes.headerImage}
              src={isDev ? synctimesLogoDev : synctimesLogo}
              alt=""
            />
            <img className={classes.smallHeaderImage} src={SmallIcon} alt="" />
          </a>
        </Typography>
        <Typography className={classes.siteName}>
          {organization.orgName}
        </Typography>

        <section className={classes.rightToolBar}>
          <div className="noprint">
            <Button onClick={() => onLogout()} className={classes.logoutButton}>
              Logout
            </Button>
          </div>
        </section>
      </Toolbar>
      {/* <div className={classes.orgHeader}>{organization.orgName} Workflows</div> */}
      <div className={classes.mainContainer}>
        <div className={classes.deptHeader}>
          {site.siteName} {department.deptName} Workflows
        </div>

        <div className={classes.sectionHeaderVisible}>Visible Actions</div>

        <div className={classes.docContainer}>
          <Grid container spacing={2} style={{ display: 'flex' }}>
            {visibleActions.map((action: any) => (
              <ActionCard
                key={action.id}
                action={action}
                autoClearActions={department.autoClearActions}
                scheduledActions={department.scheduledActions}
              />
            ))}
          </Grid>
        </div>

        <div className={classes.sectionHeaderHidden}>Hidden Actions</div>
        <div className={classes.docContainer}>
          <Grid container spacing={2} style={{ display: 'flex' }}>
            {hiddenActions.map((action: any) => (
              <ActionCard
                key={action.id}
                action={action}
                autoClearActions={department.autoClearActions}
                scheduledActions={department.scheduledActions}
              />
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
