import { Component } from 'react';
import { connect } from 'react-redux';

import {
  IDepartment,
  IOrganization,
  IPatient,
  IRoom,
  IUser,
} from '../../../../interfaces/interfaces';

import { canDo } from '../../../../utils/permissionCheck';

import SuccessSnack from '../../../../utils/snack/SuccessSnack';

import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import { API } from '../../../../apiconfig';
import { checkRefreshToken, getQueryVariable } from '../../../../utils/utils';

import { allowSignalR } from '../../../../socket-manager';

// Components
import ActionsList from '../actionsList/ActionsList';
import NewPatientDialog from '../newPatientDialog/NewPatientDialog';
import OccupantsList from '../OccupantsList';
import PatientHistory from '../PatientHistory';
import ProviderDialog from '../ProviderDialog';

//Material
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
//@ts-ignore
import noSound from '../../../../sounds/static.mp3';

// CSS
import axios from 'axios';
import '../../css/Flowstation.css';

import { logAction } from '../../../../actions/adminActions';
import RoomItemHead from './RoomItemHead';

const _ = require('lodash');

let mouseIsDown = false;
let clearRoom = true;
let timeOut: any;

const { Howl } = require('howler');

const theme = createTheme({
  overrides: {
    MuiAvatar: {
      img: {
        // Fix IE Avatar images from being stretched
        height: 'auto',
      },
    },
  },
});

const styles: {} = (theme: any) => ({
  actionTooltipText: {
    borderRadius: 4,
    color: 'white',
    padding: 10,
    position: 'fixed',
    marginLeft: -20,
    marginTop: 60,
    backgroundColor: '#696969',
    zIndex: 10,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  avatarGroup: {
    height: 55,
    width: 55,
    border: 0,
  },
  greenButton: {
    backgroundColor: '#50b848',
    color: '#FFFFFF',
    marginTop: 10,
    // marginBottom: 10,
    textDecoration: 'none',
    // height: 40,
  },
  iconRowsContainer: {
    marginTop: 15,
    width: '100%',
  },
  iconFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: 60,
    flexWrap: 'wrap',
  },
  roomNameContainer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: 5,
    paddingRight: 5,
    margin: 0,
    textAlign: 'left',
    height: 37,
    display: 'flex',
    cursor: 'pointer',
  },
  timeTextLarge: {
    fontSize: 32,
    color: '#808080',
  },
  timeTextSmall: {
    fontSize: 20,
    color: '#808080',
  },
  bigAvatar: {
    backgroundColor: '#e0e0e0',
    width: 55,
    height: 55,
    border: 0,
  },
  bigAvatarAlone: {
    margin: 'auto',
    backgroundColor: '#e0e0e0',
    width: 59,
    height: 59,
    border: 0,
    float: 'right',
  },
  avatarContainer: {
    margin: 'auto',
    cursor: 'pointer',
  },
  cardContent: {
    height: '100%',
    // backgroundColor: "white",
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  careTeamRoom: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  patientListContainer: {
    color: '#808080',
    // height: 22,
    minHeight: 22,
    paddingTop: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    // paddingTop: 10,
    paddingBottom: 5,
    fontSize: 19,
    // fontSize: "0.7em",
  },
  timeTitle: {
    fontSize: 20,
    // fontWeight: 500
  },
  patientSpacer: {
    height: 20,
  },
  patientBar: {
    fontSize: 19,
    cursor: 'pointer',
  },
  patientPopover: {
    margin: 10,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 10,
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  patientItem: {
    width: '100%',
    padding: 2,
  },
  patientNumber: {
    fontSize: 19,
    marginRight: 5,
    padding: '2px 4px 0px 4px',
    borderRadius: 14,
    color: 'white',
    backgroundColor: '#bdbdbd',
  },
  addIconSquare: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#e0e0e0',
    border: '1px dashed #e0e0e0',
    height: 45,
    width: 45,
    marginLeft: 2,
  },
  actionPopover: {
    width: 245,
    padding: 10,
    position: 'absolute',
    marginTop: 60,
    backgroundColor: 'white',
    zIndex: 10,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  popOverActions: {
    height: 50,
    width: 50,
    padding: 5,
    cursor: 'pointer',
  },
  popOverActionsGray: {
    height: 50,
    width: 50,
    padding: 5,
    filter: 'grayscale(100%)',
    opacity: '0.4',
  },
  popOverActionsGrayDarkMode: {
    height: 50,
    width: 50,
    padding: 5,
    filter: 'grayscale(100%)',
    opacity: '0.8',
  },
  addIconStyle: {
    fontSize: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  addPatientPopover: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    // width: 260,
    padding: 10,
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 10,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  iconTooltipText: {
    borderRadius: 4,
    // textAlign: "center",
    color: 'white',
    padding: 10,
    position: 'absolute',
    marginTop: 25,
    backgroundColor: '#696969',
    zIndex: 20,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  tooltiptext: {
    borderRadius: 4,
    // textAlign: "center",
    color: 'white',
    padding: 10,
    marginTop: 60,
    position: 'fixed',
    backgroundColor: '#696969',
    zIndex: 10,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  twoStep: {
    position: 'fixed',
    left: 0,
    top: '40%',
    width: '100%',
    outline: 'none',
    // height: "100%",
    overflow: 'auto',
    // backgroundColor: "rgb(0,0,0)",
    // backgroundColor: "rgba(0,0,0,0.4)",
  },
  twoStepContent: {
    borderRadius: 10,
    textAlign: 'center',
    fontSize: 22,
    backgroundColor: '#fefefe',
    margin: 'auto',
    padding: 20,
    border: '1px solid #888',
    width: 250,
    maxWidth: '70%',
  },
  newPatientButton: {
    // marginTop: 20,
    // marginBottom: 10,
    margin: '20px 5px 10px 5px',
    // width: "100%",
    backgroundColor: '#50b848',
    color: '#FFFFFF',
  },
  blankPatientButton: {
    // marginTop: 20,
    // marginBottom: 10,
    margin: '20px 5px 10px 5px',
  },
  sortCheckbox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

interface RoomItemProps {
  mute: boolean;
  mutedActions: string[];
  disableCards: boolean;
  authenticated: boolean;
  groupData: IDepartment;
  loggedInUserData: IUser;
  cardData: IRoom;
  timeZone: any;
  deptSettings: any;
  department: IDepartment;
  classes: any;
  loadedSoundObjects: any;
  selectedHistoryTime: any;
  roomColor: any;
  roomToFlash: string;
  allDepts: any;
  refetchFsData: () => void;
  playConfetti: (confettiTime: any) => void;
  highlightMember: any;
  logAction: (logName: string, patientId: string, userId: string) => void;
  serverTimeOffset: any;
  org: IOrganization;
}

interface RoomItemState {
  showProviders: boolean;
  commonProviders: any[];
  allProviders: any[];
  fireLogin: boolean;
  showAlert: boolean;
  openPatientPopover: boolean;
  openActionPopover: boolean;
  openAddPatientPopover: boolean;
  providerHover: boolean;
  hover: boolean;
  showTwoStep: boolean;
  twoStepAction: any;
  flashActionBySound: any[];
  showTimerInput: boolean;
  timerInput: any;
  tick: boolean;
  patients: any;
  showNewPatientDialog: boolean;
  patientHover: boolean;
  patientHover2: boolean;
  actionInfoHover: any;
  el: any;
  showToggleRoomOptions: boolean;
  toggleRoomStep: number;
  patientTransferDepartment: any;
  patientTransferRoom: any;
  clearTransferredRoom: boolean;
  availableRooms: any[];
  tabValue: number;
  patientTransferredSuccess: any;
  serviceLineRoles: any[];
  doubleConfirmationText: string;
  showRoomPatientHistory: boolean;
}

class RoomItem extends Component<RoomItemProps, RoomItemState> {
  constructor(props: RoomItemProps) {
    super(props);
    this.state = {
      patientTransferredSuccess: null,
      showProviders: false,
      commonProviders: [],
      allProviders: [],
      fireLogin: false,
      showAlert: false,
      openPatientPopover: false,
      openActionPopover: false,
      openAddPatientPopover: false,
      providerHover: false,
      hover: false,
      showTwoStep: false,
      twoStepAction: null,
      flashActionBySound: [],
      showTimerInput: false,
      timerInput: 10,
      tick: false,
      patients: {},
      showNewPatientDialog: false,
      patientHover: false,
      patientHover2: false,
      actionInfoHover: false,
      el: null,
      showToggleRoomOptions: false,
      toggleRoomStep: 0,
      patientTransferDepartment: null,
      patientTransferRoom: null,
      clearTransferredRoom: true,
      availableRooms: [],
      tabValue: 0,
      serviceLineRoles: [],
      doubleConfirmationText: '',
      showRoomPatientHistory: false,
    };
  }

  playSound = (soundUrl: string) => {
    let audio = new Howl({
      src: [noSound],
    });

    audio.play();

    setTimeout(() => {
      if (!this.props.mute && soundUrl) {
        let actionSound = new Howl({
          src: [soundUrl],
        });

        actionSound.play();
      }
    }, 800);
  };

  // Open dialog to assign a new provider to the room
  handleProvider = () => {
    // console.log("groupdata", this.props.groupData);
    if (!this.props.disableCards) {
      if (this.props.authenticated) {
        if (
          this.props.groupData.orgId ===
            this.props.loggedInUserData.mongoOrganizationId ||
          canDo(['SyncAdmin'], this.props.loggedInUserData)
        ) {
          // let URL = API.REACT_APP_API_GETPROVIDERS + this.props.groupData.id;
          let URL =
            API.REACT_APP_API_PROVIDERASSIGNMENTS +
            '/' +
            this.props.groupData.id +
            '/includenonproviders=true';
          // console.log("url", URL);
          let token = localStorage.getItem('token');
          let headers = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          };

          axios
            .get(URL, headers)
            .then(response => {
              // console.log("response!", response.data.data);
              this.setState({
                commonProviders: response.data.data.frequentProviders,
                allProviders: response.data.data.allProviders,
                serviceLineRoles: response.data.data.serviceLineRoles,
                showProviders: true,
              });

              if (!allowSignalR) {
                this.props.refetchFsData();
              }
              // console.log("sent!");
              // console.log('new state', this.state);
            })
            .catch(err => {
              console.log('Error fetching providers: ', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      }
    }
  };

  closeProviderDialog = () => {
    this.setState({ showProviders: false });
  };

  handlePatientPopover(e: any) {
    this.setState({
      openPatientPopover: !this.state.openPatientPopover,
      el: e.currentTarget,
    });
  }

  showPatientOptions = () => {
    let URL = API.REACT_APP_API_GETPATIENTSBYROOMID + this.props.cardData.id;
    console.log('fetch patient options');

    // console.log("card data", this.props.cardData);
    console.log('url', URL);
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("response!", response.data.data);
        this.setState({
          showToggleRoomOptions: true,
          patients: response.data.data,
          toggleRoomStep: 2,
        });
      })
      .catch(err => {
        console.log('Error fetching patients by roomid: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  handleAddPatientPopover() {
    if (!this.state.openAddPatientPopover) {
      let URL = API.REACT_APP_API_GETPATIENTSBYROOMID + this.props.cardData.id;

      // console.log("card data", this.props.cardData);
      console.log('url', URL);
      let token = localStorage.getItem('token');
      let headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      };

      axios
        .get(URL, headers)
        .then(response => {
          // console.log("response!", response.data.data);
          this.setState({
            patients: response.data.data,
          });
        })
        .catch(err => {
          console.log('Error fetching patients by roomid: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }

    this.setState({
      openAddPatientPopover: !this.state.openAddPatientPopover,
      // toggleRoomStep: 2,
    });
  }

  handleActionPopover = (e: any) => {
    if (!this.props.disableCards) {
      if (
        this.props.groupData.orgId ===
          this.props.loggedInUserData.mongoOrganizationId ||
        canDo(['SyncAdmin'], this.props.loggedInUserData)
      ) {
        // Check if there are any actions to display
        const hasActionsToDisplay = this.props.groupData.actionsList.some(
          action =>
            action.isVisible &&
            action.id !== '5f6fb35be0a5f22bf0d6a122' &&
            (this.props.cardData.statusName === 'Empty'
              ? action.canAddWithoutPatient
              : true)
        );

        // Only open the popover if there are actions to display
        if (hasActionsToDisplay) {
          this.setState({
            openActionPopover: !this.state.openActionPopover,
            showTimerInput: false,
            timerInput: 10,
          });
        }
      } else {
        console.log('cannot edit other organizations');
      }
    }
  };

  selectAction = (action: any, isFlashing: boolean) => {
    if (!action.action.isDisabled) {
      if (action.action.id === '6018823c060fd59ddb764e0f') {
        console.log('ITS A TIMER, BRING UP POPUP');
        this.setState({
          showTimerInput: true,
          timerInput: 10,
        });
      } else {
        if (action.action.verifyAction || action.action.notifyAllTablets) {
          console.log('SHOW TWO STEP');
          this.setState({
            showTwoStep: true,
            twoStepAction: action,
          });
        } else {
          this.addAction(action, isFlashing, null);
        }
      }
    }
  };

  addAction = (action: any, isFlashing: boolean, countdownTime: any) => {
    // console.log("Add This Action", action);
    ReactGA.event({
      category: 'Flowstation',
      action: 'Added action',
      label: 'Added action',
    });

    this.setState({
      openActionPopover: !this.state.openActionPopover,
      twoStepAction: false,
      doubleConfirmationText: '',
    });

    // console.log("sound URL", action.action.soundUrl);
    // this.playSound(action.action.soundUrl);

    let data = {
      roomId: this.props.cardData.id,
      actionId: action.action.id,
      isFlashing: isFlashing,
      timerMinutes: countdownTime,
    };

    console.log('DATA', data);

    if (data.roomId && data.actionId) {
      let token = localStorage.getItem('token');

      axios({
        method: 'post',
        url: API.REACT_APP_API_ADDACTION,
        data,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          if (!allowSignalR) {
            this.props.refetchFsData();
          }
          // console.log('GOOD!', response)
        })
        .catch(err => {
          console.log('Error sending action', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  componentDidUpdate(props: RoomItemProps) {
    let oldActions = [];
    let newActions = [];
    for (let i = 0; i < this.props.cardData.roomActions.length; i++) {
      newActions.push(this.props.cardData.roomActions[i].id);
    }
    for (let i = 0; i < props.cardData.roomActions.length; i++) {
      oldActions.push(props.cardData.roomActions[i].id);
    }
    let difference = _.difference(newActions, oldActions);
    // console.log("difference", difference); //expected array, ex [1, 5, 12]
    if (difference[0]) {
      for (let d = 0; d < difference.length; d++) {
        // console.log("Found difference, play sound", difference[0]);
        for (let i = 0; i < this.props.cardData.roomActions.length; i++) {
          if (this.props.cardData.roomActions[i].id === difference[d]) {
            console.log('flash this', this.props.cardData.roomActions[i]);

            if (
              this.props.cardData.roomActions[i].id ===
              '5f43f0a8e0a5d550acd7546a'
            ) {
              // Happy Icon 5f43f0a8e0a5d550acd7546a
              let actionTime = moment
                .utc(this.props.cardData.roomActions[i].startDateTime)
                .local();
              this.props.playConfetti(actionTime);
            }

            let addedActionToFlash = this.state.flashActionBySound;
            addedActionToFlash.push(this.props.cardData.roomActions[i]);

            this.setState({
              flashActionBySound: addedActionToFlash,
            });

            setTimeout(() => {
              // Stop flashing after 10 seconds
              // console.log("Stop flash");
              let flashingActions = this.state.flashActionBySound;
              flashingActions.shift();

              this.setState({
                flashActionBySound: flashingActions,
              });
            }, 10000);

            // Check if soundURL exists AND that it isn't a timer icon
            if (
              this.props.cardData.roomActions[i].soundUrl &&
              this.props.cardData.roomActions[i].id !==
                '6018823c060fd59ddb764e0f'
            ) {
              let actionTime = moment
                .utc(this.props.cardData.roomActions[i].startDateTime)
                .local();
              let currentTime = moment().tz(this.props.timeZone).local();

              let timeDiff = currentTime.diff(actionTime, 'seconds');
              console.log('Sound time diff', timeDiff);
              if (timeDiff < 30) {
                // Sound is less than 30 seconds old, play it.

                let deptSettings = this.props.deptSettings;

                console.log('deptSettings', deptSettings);
                if (deptSettings.actionSwitch) {
                  // Filtering by action, determine if this card should be hidden. We still need to render since we connect to websocket.
                  let found = false;
                  this.props.cardData.roomActions.forEach(action => {
                    if (
                      deptSettings.actionsToShow.indexOf(
                        this.props.cardData.roomActions[i].id
                      ) > -1 &&
                      !found
                    ) {
                      console.log(
                        'Play sound 1',
                        this.props.cardData.roomActions[i].soundUrl
                      );
                      var playsOnFlowstation =
                        this.props.cardData.roomActions[i]
                          .playSoundOnFlowstation;
                      // check if this.props.mutedActions contains this action.id
                      if (
                        playsOnFlowstation &&
                        this.props.mutedActions.indexOf(
                          this.props.cardData.roomActions[i].id
                        ) === -1
                      ) {
                        this.playSound(
                          this.props.cardData.roomActions[i].soundUrl
                        );
                      } else {
                        console.log('Action muted, dont play it');
                      }
                      found = true; // Add this to prevent multiple sounds from playing
                    }
                  });
                } else {
                  console.log(
                    'Play sound 2',
                    this.props.cardData.roomActions[i].soundUrl
                  );

                  var playsOnFlowstation =
                    this.props.cardData.roomActions[i].playSoundOnFlowstation;
                  var isMuted =
                    this.props.mutedActions.indexOf(
                      this.props.cardData.roomActions[i].id
                    ) !== -1;
                  if (playsOnFlowstation && !isMuted) {
                    this.playSound(this.props.cardData.roomActions[i].soundUrl);
                  } else {
                    console.log('Action muted, dont play it');
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  truncate(input: string) {
    if (input.length <= 17) {
      return 20;
    }
    if (input.length > 17 && input.length <= 20) {
      return 18;
    }
    if (input.length > 20 && input.length <= 25) {
      return 16;
    }
    if (input.length > 25 && input.length <= 30) {
      return 14;
    }
    if (input.length > 30) {
      return 12;
    }

    return 20;
  }

  addPatientToRoom = (patient: IPatient) => {
    console.log('assign patient to room', patient);
    // console.log("card data", this.props.cardData);
    let token = localStorage.getItem('token');

    let data: any = {
      roomId: this.props.cardData.id,
      patientIds: [{ Id: patient.id }],
      deviceId: null,
      deptId: this.props.department.id,
    };

    this.props.cardData.roomPatients.forEach((patient: IPatient) => {
      data.patientIds.push({
        Id: patient.id,
      });
    });
    // console.log("PAYLOAD", data);
    // this.setState({
    //   openAddPatientPopover: false,
    // });

    axios({
      method: 'post',
      url: API.REACT_APP_API_ROOMPATIENTS,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log(response);

        // this.handleAddPatientPopover();

        if (!allowSignalR) {
          this.props.refetchFsData();
        }

        this.setState({
          showToggleRoomOptions: false,
          toggleRoomStep: 0,
        });
      })
      .catch(err => {
        console.log('Error adding patient to room', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  toggleActionInfoHover = (actionId: any) => {
    // console.log('toggle hover', actionId)
    this.setState({ actionInfoHover: actionId });
  };

  holdRoom = () => {
    if (!this.props.disableCards && this.props.cardData.isPatientRoom) {
      let token = localStorage.getItem('token');
      let data = {
        roomId: this.props.cardData.id,
      };

      if (this.props.cardData.statusName === 'Patient') {
        axios({
          method: 'post',
          url: API.REACT_APP_API_HOLDPATIENT,
          data,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
          .then(response => {
            // console.log(response);
            this.setState({
              openAddPatientPopover: false,
            });
            if (!allowSignalR) {
              this.props.refetchFsData();
            }
          })
          .catch(err => {
            console.log(err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }

      this.setState({
        showToggleRoomOptions: false,
        toggleRoomStep: 0,
      });
    }
  };

  removePatient = () => {
    console.log('remove patient');
    let token = localStorage.getItem('token');
    let data = {
      roomId: this.props.cardData.id,
    };

    axios({
      method: 'post',
      url: API.REACT_APP_API_CLEARPATIENT,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        // console.log(response);
        // console.log('toggle room')
        if (!allowSignalR) {
          // console.log('manually toggle!')
          this.props.refetchFsData();
        }
      })
      .catch(err => {
        console.log(err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      showToggleRoomOptions: false,
      toggleRoomStep: 0,
    });
  };

  unHoldRoom = () => {
    let token = localStorage.getItem('token');
    let data = {
      roomId: this.props.cardData.id,
    };
    axios({
      method: 'post',
      url: API.REACT_APP_API_ADDPATIENT,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        // console.log(response);
        if (!allowSignalR) {
          // console.log('manually toggle!')
          this.props.refetchFsData();
        }
      })
      .catch(err => {
        console.log(err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      showToggleRoomOptions: false,
      toggleRoomStep: 0,
    });
  };

  checkoutRoom = () => {
    let token = localStorage.getItem('token');
    let data = {
      roomId: this.props.cardData.id,
    };
    axios({
      method: 'post',
      url: API.REACT_APP_API_CLEARPATIENT,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        // console.log(response);
        // console.log('toggle room')
        if (!allowSignalR) {
          // console.log('manually toggle!')
          this.props.refetchFsData();
        }
      })
      .catch(err => {
        console.log(err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      showToggleRoomOptions: false,
      toggleRoomStep: 0,
    });
  };

  toggleRoom = () => {
    // console.log("TOGGLE ROOM");
    if (!this.props.disableCards && this.props.cardData.isPatientRoom) {
      let token = localStorage.getItem('token');
      let data = {
        roomId: this.props.cardData.id,
      };

      if (this.props.department.isFrontDeskIntegration) {
        if (this.props.cardData.statusName === 'Patient') {
          // Remove Patient
          axios({
            method: 'post',
            url: API.REACT_APP_API_CLEARPATIENT,
            data,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
            .then(response => {
              // console.log(response);
              // console.log('toggle room')
              if (!allowSignalR) {
                // console.log('manually toggle!')
                this.props.refetchFsData();
              }
            })
            .catch(err => {
              console.log(err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        } else {
          // Add Patient
          axios({
            method: 'post',
            url: API.REACT_APP_API_ADDPATIENT,
            data,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
            .then(response => {
              // console.log(response);
              if (!allowSignalR) {
                // console.log('manually toggle!')
                this.props.refetchFsData();
              }
            })
            .catch(err => {
              console.log(err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      } else {
        if (this.props.cardData.statusName === 'Patient') {
          // Remove Patient
          axios({
            method: 'post',
            url: API.REACT_APP_API_CLEARPATIENT,
            data,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
            .then(response => {
              // console.log(response);
              // console.log('toggle room')
              if (!allowSignalR) {
                // console.log('manually toggle!')
                this.props.refetchFsData();
              }
            })
            .catch(err => {
              console.log(err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        } else {
          // Add Patient
          axios({
            method: 'post',
            url: API.REACT_APP_API_ADDPATIENT,
            data,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
            .then(response => {
              // console.log(response);
              if (!allowSignalR) {
                // console.log('manually toggle!')
                this.props.refetchFsData();
              }
            })
            .catch(err => {
              console.log(err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      }
    }
  };

  fetchAvailableRooms = () => {
    // REACT_APP_API_TRANSFERPATIENTSAVAILABLEROOMS
    // console.log('group', this.props.groupData.mongoSiteId)

    let URL =
      API.REACT_APP_API_TRANSFERPATIENTSAVAILABLEROOMS +
      this.props.groupData.mongoSiteId;
    console.log('URL', URL);
    // console.log("url", URL);
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        console.log('available rooms fetch!', response.data.data);

        let res = response.data.data;

        // Bring department room was selected to up front
        if (res) {
          res.forEach((dept: any, i: any) => {
            if (dept.deptId === this.props.department.id) {
              res.splice(i, 1);
              res.unshift(dept);
            }
          });
        }

        this.setState({
          availableRooms: res,
        });
      })
      .catch(err => {
        console.log('Error fetching available rooms: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  mouseDown = () => {
    // console.log("MOUSE DOWN");

    if (canDo(['Update Flowstation'], this.props.loggedInUserData)) {
      mouseIsDown = true;
      clearRoom = true;
      timeOut = setTimeout(() => {
        if (mouseIsDown) {
          // Mouse held down for > 2 seconds
          clearRoom = false;
          // console.log("FLASH Action", actionData);
          this.holdRoom();
        }
      }, 1000);
    }
  };

  mouseUp = () => {
    if (canDo(['Update Flowstation'], this.props.loggedInUserData)) {
      // console.log("MOUSE UP");
      clearTimeout(timeOut);
      if (clearRoom) {
        if (this.props.department.isFrontDeskIntegration) {
          if (this.props.cardData.statusName === 'Empty') {
            this.showPatientOptions();
          } else {
            this.fetchAvailableRooms();

            this.setState({
              showToggleRoomOptions: true,
            });
          }
        } else {
          this.toggleRoom();
        }
      }
    }
  };

  startTimer = (action: any) => {
    this.addAction(action, false, this.state.timerInput);
  };

  toggleHover = () => {
    this.setState({
      hover: !this.state.hover,
    });
  };

  transferPatientToWaitingRoom = (dept: IDepartment) => {
    console.log('Send them');
    let currentDeptId = this.props.department.id;
    let currentRoomId = this.props.cardData.id;
    let newDeptId = dept.id;
    let patientIds: any[] = [];
    let currentRoomNewStatus: string = this.state.clearTransferredRoom
      ? 'Empty'
      : 'Hold'; // <--- Determine if this should be 'Empty', 'Patient', or 'Hold'
    this.props.cardData.roomPatients.forEach(patient => {
      patientIds.push(patient);
    });

    let payload = {
      currentDeptId,
      currentRoomId,
      newDeptId,
      patientIds,
      currentRoomNewStatus,
    };
    console.log('Payload', payload);

    let token = localStorage.getItem('token');

    axios({
      method: 'POST',
      url: API.REACT_APP_API_TRANSFERPATIENTSTOWAITINGROOM,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('Patient sent to waiting room!', response);
        if (patientIds[0]) {
          // console.log("transfer this to waiting room", patientIds[0].id);
          this.props.logAction(
            'Patient Transferred',
            patientIds[0].id,
            this.props.loggedInUserData.id
          );
        }

        this.setState({
          patientTransferredSuccess: dept.deptName + ' Waiting Room',
        });
        setTimeout(() => {
          this.setState({
            patientTransferredSuccess: null,
          });
        }, 5000);
      })
      .catch(err => {
        console.log('Error sending action', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      showToggleRoomOptions: false,
      toggleRoomStep: 0,
    });
  };

  transferPatientToRoom = (payload: any, room: IRoom) => {
    // // console.log("room", room);
    // let currentDeptId = this.props.department.id;
    // let currentRoomId = this.props.cardData.id;
    // let newRoomId = room.id;
    // let patientIds: any[] = [];
    // let currentRoomNewStatus: string = this.state.clearTransferredRoom ? "Empty" : "Hold";
    // this.props.cardData.roomPatients.forEach((patient) => {
    //   patientIds.push(patient);
    // });

    // let payload = {
    //   currentDeptId,
    //   currentRoomId,
    //   newRoomId,
    //   patientIds,
    //   currentRoomNewStatus,
    // };

    console.log('PAYLOAD', payload);

    let token = localStorage.getItem('token');

    axios({
      method: 'POST',
      url: API.REACT_APP_API_TRANSFERPATIENTSTOEXAMROOM,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        // console.log("Patient sent to exam room!", response);
        if (payload.patientIds[0]) {
          // console.log("transfer this", patientIds[0].id);
          this.props.logAction(
            'Patient Transferred',
            payload.patientIds[0].id,
            this.props.loggedInUserData.id
          );
        }
        this.setState({
          patientTransferredSuccess:
            this.props.department.deptName + ' ' + room.roomName,
        });
        setTimeout(() => {
          this.setState({
            patientTransferredSuccess: null,
          });
        }, 5000);
      })
      .catch(err => {
        console.log('Error sending action', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      showToggleRoomOptions: false,
      toggleRoomStep: 0,
    });
  };

  startPatientTransfer = (selectedDept: any, selectedRoom: any) => {
    console.log('Select patient to transfer', selectedDept, selectedRoom);
    let deptData;
    // let roomData;
    // console.log("all depts", this.props.allDepts);
    this.props.allDepts.forEach((dept: IDepartment) => {
      if (selectedDept.deptId === dept.id) {
        deptData = dept;
        // dept.rooms.forEach((room) => {
        //   if (selectedRoom.roomId === room.id) {
        //     console.log("Found roomData", room);
        //     roomData = room;
        //   }
        // });
      }
    });

    // console.log("selcted room", selectedRoom);

    if (selectedRoom.isWaitingRoom) {
      if (deptData) {
        this.transferPatientToWaitingRoom(deptData);
      }
    } else {
      let payload: any = {
        currentDeptId: this.props.department.id,
        currentRoomId: this.props.cardData.id,
        newRoomId: selectedRoom.roomId,
        patientIds: [],
        currentRoomNewStatus: this.state.clearTransferredRoom
          ? 'Empty'
          : 'Hold',
      };

      this.props.cardData.roomPatients.forEach((patient: any) => {
        payload.patientIds.push(patient);
      });

      console.log('Call transferPatientToRoom');
      // this.transferPatientToRoom(roomData);
      this.transferPatientToRoom(payload, selectedRoom);
      // }
    }
  };

  handleTabChange = (event: any, newValue: number) => {
    // setValue(newValue);
    // console.log("new value", newValue);
    this.setState({
      tabValue: newValue,
    });
  };

  handleDoubleConfirmationText = (value: any) => {
    if (value) {
      if (value.toLowerCase() === 'yes') {
        this.addAction(this.state.twoStepAction, false, null);
      } else {
        this.setState({
          doubleConfirmationText: value,
        });
      }
    } else {
      this.setState({
        doubleConfirmationText: value,
      });
    }
  };

  openRoomPatientHistory = () => {
    // console.log("OPEN");
    this.props.logAction(
      'Opened Room Patient History',
      this.props.cardData.roomName,
      this.props.loggedInUserData.id
    );
    this.setState({
      showRoomPatientHistory: true,
    });
  };

  addBlankPatient = () => {
    let data = {
      roomId: this.props.cardData.id,
    };
    let token = localStorage.getItem('token');

    axios({
      method: 'post',
      url: API.REACT_APP_API_ADDPATIENT,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        if (!allowSignalR) {
          this.props.refetchFsData();
        }
        this.setState({
          showToggleRoomOptions: false,
          toggleRoomStep: 0,
        });
      })
      .catch(err => {
        console.log(err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  render() {
    // console.log("card data", this.props.includedDeptNameCard);
    // console.log("card data", this.props.cardData);
    // console.log("TICK");
    const { classes } = this.props;

    // console.log("Card data", this.props.cardData);

    let darkMode = false;
    if (localStorage.getItem('darkMode')) {
      let isDark = localStorage.getItem('darkMode');
      //@ts-ignore
      if (JSON.parse(isDark)) {
        darkMode = true;
      } else {
        darkMode = false;
      }
    }

    // Actions
    let actions = [];
    for (let i = 0; i < this.props.cardData.roomActions.length; i++) {
      if (
        this.props.cardData.roomActions[i].id !== '5f6fb35be0a5f22bf0d6a122'
      ) {
        actions.push(
          <ActionsList
            key={i}
            loggedInUserData={this.props.loggedInUserData}
            cardData={this.props.cardData}
            actionData={this.props.cardData.roomActions[i]}
            authenticated={this.props.authenticated}
            disableCards={this.props.disableCards}
            groupData={this.props.groupData}
            timeZone={this.props.timeZone}
            flashActionBySound={this.state.flashActionBySound}
            loadedSoundObjects={this.props.loadedSoundObjects}
            selectedHistoryTime={this.props.selectedHistoryTime}
            refetchFsData={() => this.props.refetchFsData()}
            serverTimeOffset={this.props.serverTimeOffset}
            mute={this.props.mute}
            mutedActions={this.props.mutedActions}
          />
        );
      }
    }

    // console.log("Got actions?", actions);

    // Occupants
    let occupants = [];

    let occupantSet = new Set(this.props.cardData.roomUsers);
    const uniqueOccupants = [...occupantSet];

    for (let i = 0; i < uniqueOccupants.length; i++) {
      occupants.push(
        <OccupantsList
          key={this.props.cardData.roomUsers[i].id}
          loggedInUserData={this.props.loggedInUserData}
          occupantData={this.props.cardData.roomUsers[i]}
          disableCards={this.props.disableCards}
          groupData={this.props.groupData}
          timeZone={this.props.timeZone}
          highlightMember={this.props.highlightMember}
          serverTimeOffset={this.props.serverTimeOffset}
        />
      );
    }

    let flashRoomToLocateUser = false;
    uniqueOccupants.forEach(occupant => {
      if (occupant.id === this.props.highlightMember) {
        flashRoomToLocateUser = true;
      }
    });

    // Times
    let touchTime = null;
    let visitTime = null;
    // let cleanTime = null;

    // Row Border Status Color
    let cardStatusColor = '#ffffff';
    if (
      this.props.cardData.statusName === 'Patient' &&
      this.props.cardData.isPatientRoom
    ) {
      cardStatusColor = '#4CB946';
    } else if (
      this.props.cardData.statusName === 'Hold' &&
      this.props.cardData.isPatientRoom
    ) {
      cardStatusColor = '#FBA919';
    } else {
      if (darkMode) {
        cardStatusColor = '#666666';
      } else {
        cardStatusColor = '#ffffff';
      }
    }

    let touchUTC = this.props.cardData.touchStartTime;
    let visitUTC = this.props.cardData.visitStartTime;

    // console.log("cardData", this.props.cardData);

    let touchStartTime = moment.utc(touchUTC).local();
    let visitStartTime = moment.utc(visitUTC).local();

    let currentTime;

    // if (this.props.cardData.roomName === "Exam 02") {
    //   console.log("Current", moment().tz(this.props.timeZone).local());
    // }

    // If looking at history, get current time of then.
    if (this.props.selectedHistoryTime) {
      currentTime = moment
        .utc(this.props.selectedHistoryTime)
        .tz(this.props.timeZone)
        .local();
    } else {
      currentTime = moment()
        .add('milliseconds', this.props.serverTimeOffset)
        .tz(this.props.timeZone)
        .local();
    }

    // console.log("current time", currentTime);

    // console.log("dept data", this.props.groupData);
    // console.log("room patients data", this.props.cardData.roomPatients);

    touchTime = currentTime.diff(touchStartTime, 'minutes');

    visitTime = currentTime.diff(visitStartTime, 'minutes');

    if (this.props.groupData.isTotalTimeFromCheckIn) {
      // Room empty
      if (this.props.cardData.roomPatients.length === 0) {
        visitTime = currentTime.diff(visitStartTime, 'minutes');
      }

      // One patient
      if (this.props.cardData.roomPatients.length === 1) {
        visitTime = currentTime.diff(
          this.props.cardData.roomPatients[0].startCheckInTime,
          'minutes'
        );
      }

      // Multiple patients, grab the earliest patient check in time
      if (this.props.cardData.roomPatients.length > 1) {
        let earliestCheckInTime: any = null;
        this.props.cardData.roomPatients.forEach(patient => {
          if (earliestCheckInTime === null) {
            earliestCheckInTime = patient.startCheckInTime;
          } else {
            if (
              moment(patient.startCheckInTime).isBefore(
                moment(earliestCheckInTime)
              )
            ) {
              earliestCheckInTime = patient.startCheckInTime;
            }
          }
        });
        // console.log("earliest check in time", earliestCheckInTime);
        visitTime = currentTime.diff(earliestCheckInTime, 'minutes');
      }
    }

    // console.log("Touch time", touchTime);

    let deptSettings = this.props.deptSettings;
    // console.log("deptSettings", deptSettings);
    // console.log("found it", this.props.foundIt);

    let showCard = false;
    if (deptSettings) {
      // console.log("check", deptSettings.actionSwitch);
      if (deptSettings.actionSwitch) {
        // Filtering by action, determine if this card should be hidden. We still need to render since we connect to websocket.
        let found = false;
        // console.log("cardData", this.props.cardData);

        // Always show non-patient rooms if filtering by Action
        if (!this.props.cardData.isPatientRoom) {
          showCard = true;
          found = true;
        } else {
          this.props.cardData.roomActions.forEach(action => {
            if (deptSettings.actionsToShow.indexOf(action.id) > -1 && !found) {
              showCard = true;
              found = true;
            }
          });
        }
      } else {
        showCard = true;
      }
    } else {
      showCard = true;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Dialog
          fullWidth={true}
          maxWidth={false}
          hideBackdrop={true}
          open={this.state.showRoomPatientHistory}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          BackdropProps={{
            classes: {
              root: classes.root,
            },
          }}
          PaperProps={{
            style: {
              width: '80%',
              maxWidth: '80%',
              margin: 'auto',
            },
            classes: {
              root: classes.paper,
            },
          }}
          transitionDuration={{ enter: 0, exit: 0 }}
          onClose={() =>
            this.setState({
              showRoomPatientHistory: false,
            })
          }
        >
          <div>
            <div
              onClick={() =>
                this.setState({
                  showRoomPatientHistory: false,
                })
              }
              style={{
                color: 'red',
                textAlign: 'right',
                fontSize: 32,
                cursor: 'pointer',
                padding: '10px 10px 0px 0px',
              }}
            >
              X
            </div>
            <div
              style={{
                fontSize: 32,
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              History
            </div>
            <DialogContent>
              <PatientHistory
                isFrontDeskIntegration={
                  this.props.groupData.isFrontDeskIntegration
                }
                timeZone={this.props.timeZone}
                organization={this.props.org}
                preSelectedRoom={this.props.cardData}
              />
            </DialogContent>
          </div>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'xs'} //xs
          hideBackdrop={true}
          open={this.state.showToggleRoomOptions}
          style={{ textAlign: 'center' }}
          BackdropProps={{
            classes: {
              root: classes.root,
            },
          }}
          PaperProps={{
            classes: {
              root: classes.paper,
            },
          }}
          transitionDuration={{ enter: 0, exit: 0 }}
          onClose={() =>
            this.setState({
              showToggleRoomOptions: false,
              toggleRoomStep: 0,
            })
          }
        >
          <div
            style={
              darkMode ? { backgroundColor: '#333333', color: 'white' } : {}
            }
          >
            <div
              onClick={() =>
                this.setState({
                  showToggleRoomOptions: false,
                  toggleRoomStep: 0,
                })
              }
              style={{
                color: 'red',
                textAlign: 'right',
                fontSize: 32,
                cursor: 'pointer',
                padding: '10px 10px 0px 0px',
              }}
            >
              X
            </div>
            <div style={{ fontSize: 32 }}>
              {this.state.toggleRoomStep === 2
                ? 'Select Patient'
                : 'Patient Options'}
            </div>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                {this.state.toggleRoomStep === 0 ? (
                  <div>
                    <div style={{ paddingBottom: 10 }}>
                      <Button
                        onClick={this.checkoutRoom}
                        variant="contained"
                        className={classes.greenButton}
                      >
                        Check-out
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={this.showPatientOptions}
                        color="primary"
                        style={darkMode ? { color: 'white' } : {}}
                      >
                        Add Patient
                      </Button>
                    </div>
                    <div>
                      {this.props.cardData.statusName !== 'Empty' ? (
                        <div>
                          {this.props.cardData.statusName !== 'Hold' ? (
                            <Button
                              onClick={this.holdRoom}
                              color="primary"
                              style={darkMode ? { color: 'white' } : {}}
                            >
                              Hold Room
                            </Button>
                          ) : (
                            <Button
                              onClick={this.unHoldRoom}
                              color="primary"
                              style={darkMode ? { color: 'white' } : {}}
                            >
                              Un-Hold Room
                            </Button>
                          )}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                    <div>
                      {this.props.cardData.roomPatients.length > 0 ? (
                        <Button
                          onClick={() => this.setState({ toggleRoomStep: 1 })}
                          color="primary"
                          style={darkMode ? { color: 'white' } : {}}
                        >
                          Transfer Patient
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div />
                )}

                {/* Transfer patient */}
                {this.state.toggleRoomStep === 1 ? (
                  <div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({ clearTransferredRoom: true })
                      }
                    >
                      <Checkbox
                        classes={{
                          root: classes.sortCheckbox,
                          checked: classes.checked,
                        }}
                        checked={this.state.clearTransferredRoom}
                      />
                      Clear this Room
                    </div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({ clearTransferredRoom: false })
                      }
                    >
                      <Checkbox
                        classes={{
                          root: classes.sortCheckbox,
                          checked: classes.checked,
                        }}
                        checked={!this.state.clearTransferredRoom}
                      />
                      Hold this Room
                    </div>

                    <div style={{ display: 'flex', marginTop: 25 }}>
                      <Tabs
                        style={
                          darkMode
                            ? { backgroundColor: '#333333', display: 'flex' }
                            : {
                                backgroundColor: theme.palette.background.paper,
                                display: 'flex',
                              }
                        }
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.tabValue}
                        // indicatorColor="primary"
                        TabIndicatorProps={{
                          style: darkMode
                            ? {
                                backgroundColor: '#4CB946',
                              }
                            : {
                                backgroundColor: '#3f51b5',
                              },
                        }}
                        onChange={this.handleTabChange}
                        aria-label="Vertical tabs example"
                        textColor="primary"
                        // style={{ backgroundColor: theme.palette.background.paper, display: "flex" }}
                      >
                        {/* {console.log("availble rooms", this.state.availableRooms)} */}
                        {this.state.availableRooms.map(
                          (dept: IDepartment, index: any) => (
                            <Tab
                              key={index}
                              label={dept.deptName}
                              style={
                                darkMode
                                  ? {
                                      backgroundColor: '#333333',
                                      color: 'white',
                                    }
                                  : {}
                              }
                            />
                          )
                        )}
                      </Tabs>

                      <div style={{ margin: '5px auto 0px auto' }}>
                        {this.state.availableRooms.map(
                          (dept: any, index: any) => (
                            <div key={index}>
                              {this.state.tabValue === index ? (
                                <div
                                  style={{
                                    padding: '6px 12px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {dept.roomsInDept.map(
                                    (room: any, roomIndex: any) => (
                                      <div key={roomIndex}>
                                        {room.roomId !==
                                        this.props.cardData.id ? (
                                          <div
                                            style={{ cursor: 'pointer' }}
                                            className="boldHover"
                                            onClick={() =>
                                              this.startPatientTransfer(
                                                dept,
                                                room
                                              )
                                            }
                                          >
                                            {room.roomName}
                                            {!room.isWaitingRoom ? (
                                              <span
                                                style={{ fontStyle: 'italic' }}
                                              >
                                                {' '}
                                                - {room.roomStatus}
                                              </span>
                                            ) : null}
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                            // <TabPanel key={dept.id} value={this.state.tabValue} dept={dept} index={index} />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}

                {/* Show add patient options */}
                {this.state.toggleRoomStep === 2 ? (
                  <div>
                    <div
                      style={
                        darkMode
                          ? { color: 'white', fontSize: 18 }
                          : { color: 'black', fontSize: 18 }
                      }
                    >
                      {_.isEmpty(this.state.patients) === false ? (
                        <div>
                          <div
                            style={{
                              textDecoration: 'underline',
                              color: 'gray',
                            }}
                          >
                            Assigned Provider Patients
                          </div>
                          {this.state.patients.providerPatients.length === 0 ? (
                            <div>No Patients Available</div>
                          ) : null}
                          {this.state.patients.providerPatients.map(
                            (providersPatient: IPatient, index: any) => (
                              <div
                                key={index}
                                onClick={() =>
                                  this.addPatientToRoom(providersPatient)
                                }
                                style={{ cursor: 'pointer' }}
                                className="boldHover"
                              >
                                {providersPatient.fsPatientInfoString}
                              </div>
                            )
                          )}
                          <div
                            style={{
                              textDecoration: 'underline',
                              color: 'gray',
                              marginTop: 10,
                            }}
                          >
                            Available Patients
                          </div>
                          {this.state.patients.deptPatients.length === 0 ? (
                            <div>No Patients Available</div>
                          ) : null}
                          {this.state.patients.deptPatients.map(
                            (patient: IPatient, index: any) => (
                              <div
                                key={index}
                                onClick={() => this.addPatientToRoom(patient)}
                                style={{ cursor: 'pointer' }}
                                className="boldHover"
                              >
                                {patient.fsPatientInfoString}
                              </div>
                            )
                          )}
                          <div
                            style={{
                              textDecoration: 'underline',
                              color: 'gray',
                              marginTop: 10,
                            }}
                          >
                            Checked Out Patients
                          </div>
                          {this.state.patients.expiredPatients.length === 0 ? (
                            <div>No Patients Available</div>
                          ) : null}
                          {this.state.patients.expiredPatients.map(
                            (expiredPatient: IPatient, index: any) => (
                              <div
                                key={index}
                                onClick={() =>
                                  this.addPatientToRoom(expiredPatient)
                                }
                                style={{ cursor: 'pointer' }}
                                className="boldHover"
                              >
                                {expiredPatient.fsPatientInfoString}
                              </div>
                            )
                          )}
                          {getQueryVariable('username') ? null : (
                            <div>
                              <Button
                                className={classes.newPatientButton}
                                onClick={() =>
                                  this.setState({ showNewPatientDialog: true })
                                }
                              >
                                New Patient
                              </Button>
                              <Button
                                className={classes.blankPatientButton}
                                onClick={this.addBlankPatient}
                              >
                                Add Blank Patient
                              </Button>
                            </div>
                          )}

                          {/* Wrapping NewPatientDialog in a conditional to prevent unnecessary render */}
                          {this.state.showNewPatientDialog ? (
                            <NewPatientDialog
                              // @ts-ignore
                              open={this.state.showNewPatientDialog}
                              department={this.props.department}
                              loggedInUserData={this.props.loggedInUserData}
                              closeDialog={() =>
                                this.setState(
                                  {
                                    showNewPatientDialog: false,
                                    showToggleRoomOptions: false,
                                    toggleRoomStep: 0,
                                  },
                                  this.handleAddPatientPopover.bind(this)
                                )
                              }
                              cardData={this.props.cardData}
                              autoAddPatientToRoom
                              refetchFsData={() => this.props.refetchFsData()}
                            />
                          ) : null}
                        </div>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </DialogContent>
          </div>
        </Dialog>
        <div style={!showCard ? { display: 'none' } : {}}>
          <div
            style={{
              backgroundColor: this.props.roomColor,
              height: 10,
              width: '100%',
              marginBottom: 5,
            }}
          ></div>
          <div
            className={
              this.props.roomToFlash === this.props.cardData.id ||
              flashRoomToLocateUser
                ? 'flashRoom'
                : ''
            }
            style={{
              marginLeft: 4,
              marginRight: 4,
              marginBottom: 4,
              marginTop: 4,
            }}
          >
            <Card
              className={'noselect'}
              style={
                {
                  border: '2px solid ' + cardStatusColor,
                  backgroundColor: cardStatusColor,
                  width: 250,
                  height: 330,
                }
                // this.props.cardData.isPatientRoom
                //   ? {
                //       border: "2px solid " + cardStatusColor,
                //       backgroundColor: cardStatusColor,
                //       width: 250,
                //       height: 330,
                //     }
                //   : {
                //       border: "2px solid white",
                //       width: 250,
                //       height: 330,
                //     }
              }
            >
              {this.state.twoStepAction ? (
                <Modal
                  open={this.state.showTwoStep}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div className={classes.twoStep}>
                    <ClickAwayListener
                      onClickAway={() => this.setState({ showTwoStep: false })}
                    >
                      <div className={classes.twoStepContent}>
                        <div style={{ textAlign: 'center' }}>
                          <img
                            style={{ width: 75 }}
                            alt=""
                            src={this.state.twoStepAction.action.actionImageUrl}
                          />
                        </div>
                        <div>
                          Are you sure you want to add{' '}
                          <strong>
                            {this.state.twoStepAction.action.actionName}
                          </strong>
                          ?
                        </div>
                        <br />
                        <TextField
                          autoFocus
                          style={{ marginBottom: 25 }}
                          label='Type "yes" to confirm'
                          value={this.state.doubleConfirmationText}
                          onChange={e =>
                            this.handleDoubleConfirmationText(e.target.value)
                          }
                        />
                        <div>
                          <Button
                            color="default"
                            variant="contained"
                            onClick={() =>
                              this.setState({ showTwoStep: false })
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </ClickAwayListener>
                  </div>
                </Modal>
              ) : null}

              <RoomItemHead
                cardData={this.props.cardData}
                darkMode={darkMode}
                mouseDown={this.mouseDown}
                mouseUp={this.mouseUp}
                groupData={this.props.groupData}
                openRoomPatientHistory={this.openRoomPatientHistory}
              />

              {/* Check if patient room */}
              {this.props.cardData.isPatientRoom ? (
                <CardContent
                  className={classes.cardContent}
                  style={
                    darkMode
                      ? { backgroundColor: '#333333' }
                      : { backgroundColor: 'white' }
                  }
                >
                  {this.props.groupData.isFrontDeskIntegration ? (
                    <div>
                      {this.props.loggedInUserData.mongoOrganizationId ===
                        this.props.groupData.orgId ||
                      canDo(['SyncAdmin'], this.props.loggedInUserData) ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <div
                              data-private
                              className={classes.patientListContainer}
                            >
                              {/* {console.log("CARD DATA", this.props.cardData)} */}
                              {this.props.cardData.roomPatients.length > 1 &&
                              this.props.groupData.isFrontDeskIntegration ? (
                                <div>
                                  <div
                                    onClick={this.handlePatientPopover.bind(
                                      this
                                    )}
                                    className={classes.patientBar}
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      fontSize: this.truncate(
                                        this.props.cardData.roomPatients[0]
                                          .fsPatientInfoString
                                      ),
                                    }}
                                  >
                                    <span className={classes.patientNumber}>
                                      +
                                      {this.props.cardData.roomPatients.length -
                                        1}
                                    </span>
                                    {
                                      this.props.cardData.roomPatients[0]
                                        .fsPatientInfoString
                                    }

                                    {/* {this.truncate(this.props.cardData.roomPatients[0].fsPatientInfoString)} */}
                                  </div>

                                  {this.state.openPatientPopover ? (
                                    <ClickAwayListener
                                      onClickAway={this.handlePatientPopover.bind(
                                        this
                                      )}
                                    >
                                      <div
                                        className={classes.patientPopover}
                                        style={{ padding: 10 }}
                                      >
                                        <div
                                          style={{
                                            textDecoration: 'underline',
                                            color: 'gray',
                                            paddingBottom: 10,
                                          }}
                                        >
                                          Patients In Room
                                        </div>
                                        {this.props.cardData.roomPatients.map(
                                          patient => (
                                            <div key={patient.id}>
                                              <div
                                                style={{ color: 'black' }}
                                                onMouseEnter={() =>
                                                  this.setState({
                                                    patientHover2: patient.id,
                                                  })
                                                }
                                                onMouseLeave={() =>
                                                  this.setState({
                                                    patientHover2: false,
                                                  })
                                                }
                                              >
                                                <div
                                                  className={
                                                    classes.patientItem
                                                  }
                                                >
                                                  {' '}
                                                  {patient.fsPatientInfoString}
                                                </div>
                                              </div>
                                              {this.state.patientHover2 ===
                                              patient.id ? (
                                                <span
                                                  className={
                                                    classes.tooltiptext
                                                  }
                                                  // style={{ marginTop: 25 }}
                                                >
                                                  EHR Patient ID:{' '}
                                                  {patient.mrnNumber}
                                                </span>
                                              ) : null}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </ClickAwayListener>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {this.props.cardData.roomPatients.map(
                                    patient => (
                                      <div key={patient.id}>
                                        <div
                                          className=""
                                          onMouseEnter={() =>
                                            this.setState({
                                              patientHover:
                                                !this.state.patientHover,
                                            })
                                          }
                                          onMouseLeave={() =>
                                            this.setState({
                                              patientHover:
                                                !this.state.patientHover,
                                            })
                                          }
                                        >
                                          {/* {patient.fsPatientInfoString.length} */}
                                          {patient.fsPatientInfoString.length <=
                                          20 ? (
                                            <span style={{ fontSize: 18 }}>
                                              {patient.fsPatientInfoString}
                                            </span>
                                          ) : null}

                                          {patient.fsPatientInfoString.length >=
                                            21 &&
                                          patient.fsPatientInfoString.length <=
                                            25 ? (
                                            <span style={{ fontSize: 16 }}>
                                              {patient.fsPatientInfoString}
                                            </span>
                                          ) : null}

                                          {patient.fsPatientInfoString.length >=
                                            26 &&
                                          patient.fsPatientInfoString.length <=
                                            30 ? (
                                            <span style={{ fontSize: 14 }}>
                                              {patient.fsPatientInfoString}
                                            </span>
                                          ) : null}

                                          {patient.fsPatientInfoString.length >=
                                            31 &&
                                          patient.fsPatientInfoString.length <=
                                            35 ? (
                                            <span style={{ fontSize: 12 }}>
                                              {patient.fsPatientInfoString}
                                            </span>
                                          ) : null}

                                          {patient.fsPatientInfoString.length >
                                          35 ? (
                                            <span style={{ fontSize: 10 }}>
                                              {patient.fsPatientInfoString}
                                            </span>
                                          ) : null}
                                        </div>

                                        {this.state.patientHover ? (
                                          <span
                                            className={classes.tooltiptext}
                                            // style={{ marginTop: 25 }}
                                          >
                                            EHR Patient ID: {patient.mrnNumber}
                                          </span>
                                        ) : null}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      ) : (
                        <div className={classes.patientSpacer} />
                      )}
                    </div>
                  ) : (
                    <div className={classes.patientSpacer} />
                  )}

                  <Grid container style={darkMode ? { color: '#C1C1C1' } : {}}>
                    {this.props.cardData.statusName === 'Patient' ||
                    this.props.cardData.statusName === 'Hold' ? (
                      <Grid container>
                        <Grid container>
                          <Grid item xs={3} style={{ margin: 'auto' }}>
                            {visitTime === 0 || visitTime > 0 ? (
                              <div className="">
                                <span className="text-[20px] leading-none">
                                  Total
                                </span>
                                <br />
                                <div className="">
                                  <div className="text-[20px] leading-none">
                                    {/* <div className={classes.timeTextSmall}>{Math.floor(visitTime / 60) + ":" + (visitTime % 60)}</div> */}
                                    {visitTime < 60 ? (
                                      <div
                                        className={
                                          touchTime < 60 && visitTime < 60
                                            ? classes.timeTextLarge
                                            : classes.timeTextSmall
                                        }
                                        style={
                                          darkMode ? { color: '#C1C1C1' } : {}
                                        }
                                      >
                                        {visitTime}
                                      </div>
                                    ) : (
                                      <div
                                        className={classes.timeTextSmall}
                                        style={
                                          darkMode ? { color: '#C1C1C1' } : {}
                                        }
                                      >
                                        {/* {moment.utc(moment.duration(moment(currentTime).diff(moment(visitStartTime))).asMilliseconds()).format("HH:mm")} */}
                                        {/* {console.log("visit start time", visitStartTime)}
                                        {console.log("current time", currentTime)}
                                        {console.log("visit time", visitTime)} */}
                                        {/* {Math.floor(visitTime / 60) + ":" + (visitTime % 60)} */}
                                        {Math.floor(visitTime / 60) +
                                          ':' +
                                          (visitTime % 60)
                                            .toString()
                                            .padStart(2, '0')}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className={classes.spacer} />
                            )}
                          </Grid>
                          <Grid item xs={3} style={{ margin: 'auto' }}>
                            {touchTime === 0 || touchTime > 0 ? (
                              <div>
                                <span
                                  className={`${classes.timeTitle} text-[20px] leading-none`}
                                >
                                  {this.props.cardData.touchStatus}
                                  {/* Provider */}
                                </span>

                                <div className="text-[20px] leading-none">
                                  {/* <div className={classes.timeTextSmall}>{Math.floor(touchTime / 60) + ":" + (touchTime % 60)}</div> */}
                                  {touchTime < 60 ? (
                                    <div
                                      className={
                                        touchTime < 60 && visitTime < 60
                                          ? classes.timeTextLarge
                                          : classes.timeTextSmall
                                      }
                                      style={
                                        darkMode ? { color: '#C1C1C1' } : {}
                                      }
                                    >
                                      {touchTime}
                                    </div>
                                  ) : (
                                    <div
                                      className={classes.timeTextSmall}
                                      style={
                                        darkMode ? { color: '#C1C1C1' } : {}
                                      }
                                    >
                                      {moment
                                        .utc(
                                          moment
                                            .duration(
                                              moment(currentTime).diff(
                                                moment(touchStartTime)
                                              )
                                            )
                                            .asMilliseconds()
                                        )
                                        .format('H:mm')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div />
                            )}
                          </Grid>
                          <Grid item xs={6} className={classes.avatarContainer}>
                            {this.props.cardData.roomAssignments.length > 0 ? (
                              <>
                                {/* @ts-ignore */}
                                <AvatarGroup
                                  max={2}
                                  spacing="small"
                                  // style={{ justifyContent: "end" }}
                                  style={{ float: 'right' }}
                                  classes={{
                                    avatar: classes.avatarGroup,
                                  }}
                                  onMouseEnter={() =>
                                    this.setState({
                                      providerHover: !this.state.providerHover,
                                    })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({
                                      providerHover: !this.state.providerHover,
                                    })
                                  }
                                >
                                  {/* @ts-ignore */}
                                  {this.props.cardData.roomAssignments.map(
                                    (user: any, i: any) => (
                                      // @ts-ignore
                                      <Avatar
                                        key={i}
                                        className={classes.bigAvatar}
                                        src={user.userImageUrl}
                                        onClick={
                                          canDo(
                                            ['Update Flowstation'],
                                            this.props.loggedInUserData
                                          )
                                            ? this.handleProvider
                                            : null
                                        }
                                      />
                                    )
                                  )}
                                </AvatarGroup>
                              </>
                            ) : (
                              <>
                                {/* @ts-ignore */}
                                <Avatar
                                  className={classes.bigAvatarAlone}
                                  onClick={
                                    canDo(
                                      ['Update Flowstation'],
                                      this.props.loggedInUserData
                                    )
                                      ? this.handleProvider
                                      : null
                                  }
                                />
                              </>
                            )}

                            {this.state.providerHover ? (
                              <span className={classes.tooltiptext}>
                                {this.props.cardData.roomAssignments.length >
                                0 ? (
                                  <>
                                    {this.props.cardData.roomAssignments.map(
                                      (user: any, i: any) => (
                                        <div key={user.userId}>
                                          {user.userFullName}
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <>{this.props.cardData.providerName}</>
                                )}
                              </span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}

                    {this.props.cardData.statusName === 'Empty' ? (
                      <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} />
                        <Grid item xs={4} className={classes.avatarContainer}>
                          {this.props.cardData.roomAssignments.length > 0 ? (
                            <>
                              {/* @ts-ignore */}
                              <AvatarGroup
                                max={2}
                                spacing="small"
                                // style={{ justifyContent: "end" }}
                                style={{ float: 'right' }}
                                classes={{
                                  avatar: classes.avatarGroup,
                                }}
                                onMouseEnter={() =>
                                  this.setState({
                                    providerHover: true,
                                  })
                                }
                                onMouseLeave={() =>
                                  this.setState({
                                    providerHover: false,
                                  })
                                }
                              >
                                {this.props.cardData.roomAssignments.map(
                                  (user: any, i: any) => (
                                    // @ts-ignore
                                    <Avatar
                                      key={i}
                                      src={user.userImageUrl}
                                      onClick={
                                        canDo(
                                          ['Update Flowstation'],
                                          this.props.loggedInUserData
                                        )
                                          ? this.handleProvider
                                          : null
                                      }
                                    />
                                  )
                                )}
                              </AvatarGroup>
                            </>
                          ) : (
                            <>
                              {/* @ts-ignore */}
                              <Avatar
                                className={classes.bigAvatarAlone}
                                onClick={
                                  canDo(
                                    ['Update Flowstation'],
                                    this.props.loggedInUserData
                                  )
                                    ? this.handleProvider
                                    : null
                                }
                              />
                            </>
                          )}

                          {this.state.providerHover ? (
                            <span className={classes.tooltiptext}>
                              {this.props.cardData.roomAssignments.length >
                              0 ? (
                                <>
                                  {this.props.cardData.roomAssignments.map(
                                    (user: any, i: any) => (
                                      <div key={user.userId}>
                                        {user.userFullName}
                                      </div>
                                    )
                                  )}
                                </>
                              ) : (
                                <>{this.props.cardData.providerName}</>
                              )}
                            </span>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}
                  </Grid>

                  {/* Room Actions */}
                  <Grid container>
                    <div className={classes.iconRowsContainer}>
                      <div className={classes.iconFlex}>
                        {actions}
                        {actions.length < 6 &&
                        canDo(
                          ['Update Flowstation'],
                          this.props.loggedInUserData
                        ) ? (
                          <div
                            style={{ cursor: 'pointer' }}
                            onMouseUp={this.handleActionPopover}
                            className={classes.addIconSquare}
                          >
                            <div className={classes.addIconStyle}>+</div>
                          </div>
                        ) : (
                          <div />
                        )}

                        {this.state.hover && (
                          <span className={classes.iconTooltipText}>
                            This icon can only be added from an exam room tablet
                          </span>
                        )}
                        {this.props.department.id &&
                        this.state.openActionPopover ? (
                          <ClickAwayListener
                            onClickAway={this.handleActionPopover}
                          >
                            <div
                              className={classes.actionPopover}
                              style={
                                darkMode ? { backgroundColor: '#212121' } : {}
                              }
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                {this.props.cardData.statusName === 'Empty' ? (
                                  <>
                                    {this.props.groupData.actionsList.map(
                                      action => (
                                        <div
                                          key={action.id}
                                          onMouseEnter={() =>
                                            this.toggleActionInfoHover(
                                              action.id
                                            )
                                          }
                                          onMouseLeave={() =>
                                            this.toggleActionInfoHover(null)
                                          }
                                        >
                                          {this.state.actionInfoHover ===
                                          action.id ? (
                                            <div
                                              className={
                                                classes.actionTooltipText
                                              }
                                              style={{ maxWidth: 250 }}
                                            >
                                              <div>
                                                <strong>
                                                  {action.actionName}{' '}
                                                  {action.isDisabled
                                                    ? '(Action Not Available)'
                                                    : null}
                                                </strong>
                                              </div>
                                              <div>
                                                {action.actionDescription}
                                              </div>
                                            </div>
                                          ) : null}
                                          {action.isVisible &&
                                          action.id !==
                                            '5f6fb35be0a5f22bf0d6a122' &&
                                          action.canAddWithoutPatient ===
                                            true ? (
                                            <div>
                                              <div>
                                                <img
                                                  className={
                                                    action.isDisabled
                                                      ? classes.popOverActionsGray
                                                      : classes.popOverActions
                                                  }
                                                  src={action.actionImageUrl}
                                                  onClick={() =>
                                                    this.selectAction(
                                                      { action },
                                                      false
                                                    )
                                                  }
                                                  alt=""
                                                />

                                                {action.id ===
                                                  '6018823c060fd59ddb764e0f' &&
                                                this.state.showTimerInput ? (
                                                  <ClickAwayListener
                                                    onClickAway={() =>
                                                      this.setState({
                                                        showTimerInput: false,
                                                        timerInput: 10,
                                                      })
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        zIndex: 20,
                                                        position: 'absolute',
                                                        backgroundColor:
                                                          'white',
                                                        padding: 10,
                                                        border:
                                                          '1px solid lightgray',
                                                        // marginLeft: 60,
                                                        marginLeft: -40,
                                                        // marginTop: -85,
                                                        marginTop: -150,
                                                      }}
                                                    >
                                                      <TextField
                                                        value={
                                                          this.state.timerInput
                                                        }
                                                        // onChange={this.handleFeatureRequestText}
                                                        onChange={e =>
                                                          this.setState({
                                                            timerInput:
                                                              e.target.value,
                                                          })
                                                        }
                                                        type="number"
                                                        label="Minutes"
                                                        // size="small"
                                                        variant="outlined"
                                                        style={{
                                                          width: 122,
                                                          marginBottom: 5,
                                                        }}
                                                        // style={{ width: "100%" }}
                                                      />
                                                      <div>
                                                        <Button
                                                          color="primary"
                                                          variant="contained"
                                                          onClick={() =>
                                                            this.startTimer({
                                                              action,
                                                            })
                                                          }
                                                        >
                                                          Start Timer
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </ClickAwayListener>
                                                ) : null}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {this.props.groupData.actionsList.map(
                                      action => (
                                        <div key={action.id}>
                                          {action.isVisible &&
                                          action.id !==
                                            '5f6fb35be0a5f22bf0d6a122' ? (
                                            <div
                                              onMouseEnter={() =>
                                                this.toggleActionInfoHover(
                                                  action.id
                                                )
                                              }
                                              onMouseLeave={() =>
                                                this.toggleActionInfoHover(null)
                                              }
                                            >
                                              {this.state.actionInfoHover ===
                                              action.id ? (
                                                <div
                                                  className={
                                                    classes.actionTooltipText
                                                  }
                                                  style={{ maxWidth: 250 }}
                                                >
                                                  <div>
                                                    <strong>
                                                      {action.actionName}{' '}
                                                      {action.isDisabled
                                                        ? '(Action Not Available)'
                                                        : null}
                                                    </strong>
                                                  </div>
                                                  <div>
                                                    {action.actionDescription}
                                                  </div>
                                                </div>
                                              ) : null}
                                              <div>
                                                <img
                                                  // className={classes.popOverActions}
                                                  className={
                                                    action.isDisabled
                                                      ? classes.popOverActionsGray
                                                      : classes.popOverActions
                                                  }
                                                  src={action.actionImageUrl}
                                                  onClick={() =>
                                                    this.selectAction(
                                                      { action },
                                                      false
                                                    )
                                                  }
                                                  alt=""
                                                />
                                                {action.id ===
                                                  '6018823c060fd59ddb764e0f' &&
                                                  this.state.showTimerInput && (
                                                    <ClickAwayListener
                                                      onClickAway={() =>
                                                        this.setState({
                                                          showTimerInput: false,
                                                          timerInput: 10,
                                                        })
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          zIndex: 20,
                                                          position: 'absolute',
                                                          backgroundColor:
                                                            'white',
                                                          padding: 10,
                                                          border:
                                                            '1px solid lightgray',
                                                          // marginLeft: 60,
                                                          marginLeft: -40,
                                                          // marginTop: -85,
                                                          marginTop: -150,
                                                        }}
                                                      >
                                                        <TextField
                                                          value={
                                                            this.state
                                                              .timerInput
                                                          }
                                                          // onChange={this.handleFeatureRequestText}
                                                          onChange={e =>
                                                            this.setState({
                                                              timerInput:
                                                                e.target.value,
                                                            })
                                                          }
                                                          type="number"
                                                          label="Minutes"
                                                          // size="small"
                                                          variant="outlined"
                                                          style={{
                                                            width: 122,
                                                            marginBottom: 5,
                                                          }}
                                                          // style={{ width: "100%" }}
                                                        />
                                                        <div>
                                                          <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() =>
                                                              this.startTimer({
                                                                action,
                                                              })
                                                            }
                                                          >
                                                            Start Timer here
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </ClickAwayListener>
                                                  )}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </ClickAwayListener>
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className={classes.iconFlex}>{occupants}</div>
                    </div>
                  </Grid>

                  <ProviderDialog
                    cardData={this.props.cardData}
                    open={this.state.showProviders}
                    onClose={this.closeProviderDialog}
                    commonProviders={this.state.commonProviders}
                    allProviders={this.state.allProviders}
                    refetchFsData={() => this.props.refetchFsData()}
                  />
                </CardContent>
              ) : (
                <CardContent
                  className={classes.careTeamRoom}
                  style={
                    darkMode
                      ? { backgroundColor: '#333333', height: '100%' }
                      : {}
                  }
                >
                  <div className={classes.iconFlex}>{occupants}</div>
                </CardContent>
              )}
            </Card>
          </div>
        </div>
        {this.state.patientTransferredSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage={
              'Patient Successfully Transferred to ' +
              this.state.patientTransferredSuccess
            }
          />
        ) : (
          <div />
        )}
      </MuiThemeProvider>
    );
  }
}
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  logAction,
})(withStyles(styles)(RoomItem));
