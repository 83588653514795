import axios from 'axios';
import { useEffect, useState } from 'react';
import { API } from '../apiconfig';
import {
  IAthenaConfig,
  TAthenaPatientLocation,
} from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useAthenaPatientLocations = (input: {
  athenaConfig: IAthenaConfig;
}): {
  athenaPatientLocations: TAthenaPatientLocation[];
  isLoading: boolean;
  error: string | null;
} => {
  const { athenaConfig } = input;

  const [athenaPatientLocations, setAthenaPatientLocations] = useState<
    TAthenaPatientLocation[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      setError(null);
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    const fetchAthenaPatientLocations = () => {
      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      let athenaPracticeUrl_patient =
        API.REACT_APP_API_ATHENAPRACTICECONFIGPATIENTLOCATIONS +
        athenaConfig.athenaPracticeId;

      if (athenaConfig.isPreview) {
        athenaPracticeUrl_patient += '&isPreview=true';
      } else {
        athenaPracticeUrl_patient += '&isPreview=false';
      }

      setIsLoading(true);
      axios
        .get(athenaPracticeUrl_patient, headers)
        .then(patient_res => {
          setIsLoading(true);
          console.log('fetchAthenaPatientLocations, patient res', patient_res);
          if (patient_res.data.data.patientLocations) {
            console.log(
              'fetchAthenaPatientLocations 2, patient res',
              patient_res
            );
            setAthenaPatientLocations(patient_res.data.data.patientLocations);
          }
        })
        .catch(err => {
          setError('Failed to fetch Athena patient locations');
          console.log('Patient ERR', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    };

    fetchAthenaPatientLocations();
  }, [athenaConfig.athenaPracticeId, athenaConfig.isPreview]);

  return { athenaPatientLocations, isLoading, error };
};
