import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-google-charts";

const _ = require("lodash");

interface AppChartProps {
  devices: any;
}

interface AppChartState {}

const styles: {} = (theme: any) => ({});

class AppChart extends Component<AppChartProps, AppChartState> {
  constructor(props: AppChartProps) {
    super(props);
    this.state = {};
  }

  render() {
    let dataPoints: any[] = [];

    this.props.devices.forEach((device: any) => {
      if (_.some(dataPoints, { appId: device.mongoLastLoggedAppId })) {
      } else {
        dataPoints.push({ appId: device.mongoLastLoggedAppId, count: 0 });
      }
    });

    this.props.devices.forEach((device: any) => {
      dataPoints.forEach((dataPoint) => {
        if (device.mongoLastLoggedAppId === dataPoint.appId) {
          dataPoint.count++;
        }
      });
    });

    // console.log("data points", dataPoints);

    let data: any = [["Version", "Count"]];

    dataPoints.forEach((dataPoint) => {
      data.push([dataPoint.appId + ": " + dataPoint.count, dataPoint.count]);
    });

    // console.log("app data", data);

    const pieOptions = {
      title: "Device Status",
      pieHole: 0.6,
      pieSliceText: "value",
      slices: [
        {
          color: "#4FAC48",
        },
        {
          color: "#0495DA",
        },
      ],
      // legend: "none",
      legend: {
        // position: "none",
        // position: "bottom",
        // alignment: "center",
        textStyle: {
          color: "233238",
          fontSize: 14,
        },
      },
      tooltip: {
        showColorCode: true,
      },
      chartArea: {
        left: 0,
        top: 10,
        width: "100%",
        height: "85%",
      },
      fontName: "Roboto",
    };

    return (
      <div style={{ marginTop: 15, width: 300 }}>
        <div style={{ display: "flex", alignItems: "baseline", marginLeft: 45 }}>Installed Apps</div>
        {data.length > 1 ? (
          <div>
            <Chart
              chartType="PieChart"
              options={pieOptions}
              width="300px"
              // height="180px"
              data={data}
              // chartEvents={[
              //   {
              //     eventName: "select",
              //     callback: ({ chartWrapper, google }) => {
              //       let selection = chartWrapper.getChart().getSelection();
              //       // console.log(selection);
              //       if (selection?.length > 0) {
              //         console.log("selection", selection);
              //       }
              //     },
              //   },
              // ]}
              // data={[
              //   ["Connected", "Disconnected"],
              //   ["Connected", connectedDevices],
              //   ["Disconnected", disconnectedDevices],
              // ]}
            />
          </div>
        ) : (
          <div>Loading...</div>
        )}

        <div style={{ textAlign: "center", display: "flex", alignItems: "baseline", justifyContent: "center" }}>
          <div
            style={{
              height: 10,
              width: 10,
              borderRadius: 10,
              // backgroundColor: "#4FAC48", marginRight: 10
            }}
          />
          {/* Connected: {connectedDevices} */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AppChart);
