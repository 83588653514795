import React, { ChangeEvent, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { canDo } from '../../../../utils/permissionCheck';

import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';

import { ICampus, TAdminState } from '../../../../interfaces/interfaces';
import { useStyles, theme } from './styles';
import { useCampuses } from '../../../../hooks/useCampuses';
import CampusDrawer from './CampusDrawer';
import { CAMPUS_TEMPLATE } from './constants';

const Campuses: React.FC = () => {
  const loggedInUserData = useSelector((state: { adminData: TAdminState }) => state.adminData?.loggedInUserData);

  const [hideInactiveCampuses, setHideInactiveCampuses] = useState(true);
  const [filterString, setFilterString] = useState('');
  const [sortDescending, setSortDescending] = useState(false);
  const [sortBy, setSortBy] = useState('campusName');
  const [campusToEdit, setCampusToEdit] = useState<ICampus | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCreatingNewCampus, setIsCreatingNewCampus] = useState(false);

  const classes = useStyles();

  const { campuses, refreshCampuses } = useCampuses(loggedInUserData.mongoOrganizationId);

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  const toggleHideInactiveCampuses = () => {
    setHideInactiveCampuses(prevState => !prevState);
  };

  const handleSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(prevSortDescending => !prevSortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const handleCreateNewCampus = () => {
    setCampusToEdit({ ...CAMPUS_TEMPLATE });
    setIsCreatingNewCampus(true);
    setIsDrawerOpen(true);
  };

  const handleEditCampus = (campus: ICampus) => {
    setCampusToEdit({ ...campus });
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setIsCreatingNewCampus(false);
    refreshCampuses();
  };

  const filteredAndSortedCampuses = useMemo(() => {
    if (!campuses) return [];

    return campuses
      .filter(campus => {
        const matchesFilter = campus.campusName.toUpperCase().includes(filterString.toUpperCase());
        const isActiveOrShowAll = !hideInactiveCampuses || campus.isActive;
        return matchesFilter && isActiveOrShowAll;
      })
      .sort((a, b) => {
        const aValue = a[sortBy as keyof ICampus];
        const bValue = b[sortBy as keyof ICampus];

        if (aValue === undefined && bValue === undefined) return 0;
        if (aValue === undefined) return sortDescending ? 1 : -1;
        if (bValue === undefined) return sortDescending ? -1 : 1;

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortDescending ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        }

        if (aValue < bValue) return sortDescending ? 1 : -1;
        if (aValue > bValue) return sortDescending ? -1 : 1;
        return 0;
      });
  }, [campuses, filterString, hideInactiveCampuses, sortBy, sortDescending]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="h4" gutterBottom component="h2">
          Campuses
          {canDo(['SyncAdmin'], loggedInUserData) && (
            <Tooltip title="Add" aria-label="Add">
              <Fab className={classes.newCampusButton} onClick={handleCreateNewCampus}>
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
        </Typography>

        <TextField label="Search" onChange={handleFilter} />
        <br />
        <div onClick={toggleHideInactiveCampuses} style={{ cursor: 'pointer' }}>
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={hideInactiveCampuses}
          />
          Hide Inactive Campuses
        </div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={classes.sortSelect} onClick={() => handleSortBy('campusName')}>
                  {sortBy === 'campusName' ? <div>Campus {sortDescending ? <>▼</> : <>▲</>}</div> : <div>Campus</div>}
                </TableCell>
                <TableCell className={classes.sortSelect} onClick={() => handleSortBy('isActive')}>
                  {sortBy === 'isActive' ? <div>Is Active {sortDescending ? <>▼</> : <>▲</>}</div> : <div>Is Active</div>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedCampuses.map(campus => (
                <TableRow key={campus.id} className={classes.tableRow} onClick={() => handleEditCampus(campus)}>
                  <TableCell className={classes.tableCell}>{campus.campusName}</TableCell>
                  <TableCell className={classes.tableCell}>{campus.isActive && <CheckIcon />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <CampusDrawer campusToEdit={campusToEdit} open={isDrawerOpen} onClose={handleCloseDrawer} isCreatingNewCampus={isCreatingNewCampus} />
      </ThemeProvider>
    </div>
  );
};

export default Campuses;
