import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Fade from "@material-ui/core/Fade";
import WarningIcon from "@material-ui/icons/Warning";

const styles = theme => ({
  snackbar: {
    backgroundColor: "#FF9800"
  },
  icon: {
    fontSize: 22,
    opacity: 0.9,
    marginRight: theme.spacing()
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

class WarningSnack extends Component {
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          className={classes.snackbar}
          varient={"error"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.props.warningSnack}
          TransitionComponent={Fade}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
        >
          <SnackbarContent
            className={classes.snackbar}
            message={
              <span id="message-id" className={classes.message}>
                <WarningIcon className={classes.icon} />
                {this.props.warningMessage}
              </span>
            }
          />
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(WarningSnack);
