import React, { Component } from "react";

import { connect } from "react-redux";

import { fetchLoggedInUser } from "../../../actions/adminActions";
import { IUser } from "../../../interfaces/interfaces";

// Pages
import Flowstation from "../Flowstation";

interface DataWrapperProps {
  loggedInUserData: IUser;
  authenticated: boolean;
  fetchLoggedInUser: (userEmail: string, token: string, orgId: any) => void;
}

interface DataWrapperState {
  regularLogin: boolean;
}

class DataWrapper extends Component<DataWrapperProps, DataWrapperState> {
  constructor(props: DataWrapperProps) {
    super(props);
    this.state = {
      regularLogin: false,
    };

    let userEmail = localStorage.getItem("email");
    let token = localStorage.getItem("token");
    if (userEmail && token) {
      this.state = {
        regularLogin: true,
      };
      // this.state.regularLogin = true;
      // this.setState({
      //   regularLogin: true,
      // });
    } else {
    }
  }

  componentDidMount() {
    if (this.state.regularLogin) {
      let userEmail = localStorage.getItem("email");
      let token = localStorage.getItem("token");



      console.log('DataWrapper componentDidMount', {userEmail, token});


      if (userEmail && token) {
        let orgId = null;

        if (localStorage.getItem("lastMockedOrganizationId")) {
          orgId = localStorage.getItem("lastMockedOrganizationId");
          this.props.fetchLoggedInUser(userEmail, token, orgId);
        } else {
          this.props.fetchLoggedInUser(userEmail, token, null);
        }
      } else {
        window.location.replace("/login");
      }
    }
  }

  render() {
    // console.log("CHECK", this.props.loggedInUserData.id);

    return (
      <div>
        {this.state.regularLogin ? (
          <div>{this.props.loggedInUserData.id ? <Flowstation authenticated={this.props.authenticated} /> : <div />}</div>
        ) : (
          <Flowstation authenticated={this.props.authenticated} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  loggedInUserData: state.adminData.loggedInUserData,
});

export default connect(mapStateToProps, { fetchLoggedInUser })(DataWrapper);
