import { ErrorBoundary, LEVEL_WARN, Provider } from '@rollbar/react';
import axios from 'axios';
import moment from 'moment-timezone';
import { Suspense, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import
  {
    Navigate,
    Outlet,
    Route,
    BrowserRouter as Router,
    Routes,
  } from 'react-router-dom';
import { API, MSALSSO } from './apiconfig';
import SynctimesIcon from './images/synctimesicon.png';
import { checkRefreshToken, getQueryVariable } from './utils/utils';

import './css/App.css';

import WarningSnack from './utils/WarningSnack';

import './init';

import { app, authentication } from '@microsoft/teams-js';

// Loading page for Lazy Loading
import { shallowEqual, useSelector } from 'react-redux';
import { AppProvider } from './AppContext';
// import CustomErrorBoundary from './CustomErrorBoundary';
import { NotifyProvider } from './common/notify/NotifyProvider';
import useIsSignedIn from './hooks/useIsSignedIn';
import { TAdminState } from './interfaces/interfaces';
import DataWrapper from './pages/flowstation/components/DataWrapper';
import Loading from './pages/Loading';
import LobbyDataWrapper from './pages/lobby/LobbyDataWrapper';
import LoginPage from './pages/login/Login';
import { processLogin, processLoginByToken } from './pages/login/utils';
import Alerts from './pages/security/Alerts';
import RollbarFallback from './RollbarFallback';

import { isEmpty } from 'lodash';
import AppVersion from './AppVersion';
import BlankSound from './BlankSound';
import AdminContainer from './pages/admin/AdminContainer';
import ClinicMap from './pages/ClinicMap';
import ClinicMapCreator from './pages/ClinicMapCreator';
import ClinicMapLoader from './pages/ClinicMapLoader';
import Documentation from './pages/documentation/Documentation';
import Flowmap from './pages/flowmap/Flowmap';
import MobileApp from './pages/MobileApp';
import NotFound from './pages/NotFound';
import PatientContent from './pages/patientContent/PatientContent';
import Splash from './pages/Splash';
import Video from './pages/Video';
// import { useOrganization } from './hooks/useOrganization';
import { useOrg } from './hooks/useOrg';

// Pages
// const Alerts = lazy(() => import('./pages/security/Alerts'));
// const LobbyDataWrapper = lazy(() => import('./pages/lobby/LobbyDataWrapper'));
// const NotFound = lazy(() => import('./pages/NotFound'));
// const LoginPage = lazy(() => import('./pages/login/Login'));
// const Admin = lazy(() => import('./pages/admin/Admin'));
// const Splash = lazy(() => import('./pages/Splash'));
// const Video = lazy(() => import('./pages/Video'));
// const Flowmap = lazy(() => import('./pages/flowmap/Flowmap'));
// const MobileApp = lazy(() => import('./pages/MobileApp'));
// const DataWrapper = lazy(
//   () => import('./pages/flowstation/components/DataWrapper')
// );
// const Documentation = lazy(() => import('./pages/documentation/Documentation'));
// const AppVersion = lazy(() => import('./AppVersion'));
// const PatientContent = lazy(
//   () => import('./pages/patientContent/PatientContent')
// );
// const ClinicMap = lazy(() => import('./pages/ClinicMap'));
// const ClinicMapCreator = lazy(() => import('./pages/ClinicMapCreator'));
// const ClinicMapLoader = lazy(() => import('./pages/ClinicMapLoader'));

// console.log("Host", window.location.hostname === "localhost");

// Rollbar configuration
const rollbarConfig = {
  accessToken: 'd943d4eb218d44cb8c50c37e107e2c8b',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'production',
  autoInstrument: true,
};

function App() {
  const [loaded, setLoaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  // const [testMessage, setTestMessage] = useState("None");
  // const [otherTestMessage, setOtherTestMessage] = useState("Waiting...");
  const [ssoNotAllowed, ssoLoading] = useIsSignedIn();
  const [ssoLoadingOther, setSsoLoading] = useState(false);
  const [ssoNotAllowedOther, setSsoNotAllowed] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  // useIsSignedIn();

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);

      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const orgId = loggedInUserData?.mongoOrganizationId;

  const { organization } = useOrg(loggedInUserData?.mongoOrganizationId);


  useEffect(() => {
    console.log('App Mounted');
    localStorage.removeItem('pauseRefreshToken');

    // if (localStorage.getItem("token") && localStorage.getItem("email") && localStorage.getItem("token_expire")) {
    //   console.log("get latest")
    //   getLatestRefreshToken();
    // }

    let refreshTokenInterval = setInterval(() => {
      if (
        localStorage.getItem('token') &&
        localStorage.getItem('email') &&
        localStorage.getItem('token_expire')
      ) {
        // console.log("Checking token expire")
        let tokenExpireTime = localStorage.getItem('token_expire');
        let diff = moment.utc(tokenExpireTime).diff(moment.utc(), 'minutes');
        // console.log('expire time', tokenExpireTime)
        // console.log("now", moment.utc())
        // console.log("Expire min left", diff)
        if (diff < 10) {
          // token expires in less than 10 minutes, refretch new token
          checkRefreshToken();
        }
      }
    }, 60000);

    // if (localStorage.getItem("token") && localStorage.getItem("email")) {
    //   setInterval(() => {
    //     console.log('tick')
    //     // getLatestRefreshToken()
    //   }, 50000) // <--- set this to 90% of what token expireation time is. Orrrr, fire this every 5 seconds and check the time difference between now and token expiration time
    // }

    window.addEventListener('setAppType', (e: any) => {
      console.log('Received setAppType', e.detail);
      if (e.detail?.appType) {
        // setTestMessage("AppType: " + e.detail.appType)
        localStorage.setItem('appType', e.detail.appType);
        forceUpdate();
      }
    });

    window.addEventListener('iosMessage', (e: any) => {
      console.log('Handling MSAL Token with iOS', e.detail);
      // let message = e.detail;
      // setTestMessage(JSON.stringify(message))
      // setOtherTestMessage("Message Received")
      if (e.detail) {
        // setOtherTestMessage("Detail Found")
        let incMessage = e.detail;
        if (incMessage.messageType === 'msaltoken') {
          // console.log("Handling MSAL Token with iOS", data)
          setSsoLoading(true);
          fetch(MSALSSO, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + incMessage.token,
            },
          })
            .then(res => {
              // console.log("RES", res)
              return res.json();
            })
            .then(data => {
              if (data.message) {
                if (data.message === 'sso_not_enabled') {
                  setSsoLoading(false);
                  setSsoNotAllowed(true);
                  localStorage.removeItem('token');
                  localStorage.removeItem('email');
                  setTimeout(() => {
                    setSsoNotAllowed(false);
                  }, 10000);
                }
              }

              if (data.access_token && data.refresh_token) {
                let payload = {
                  useremail: incMessage.username,
                };
                axios({
                  method: 'post',
                  url: API.REACT_APP_API_LOGINDETAILS,
                  data: payload,
                  headers: {
                    Authorization: 'Bearer ' + data.access_token,
                    Pragma: 'no-cache',
                  },
                }).then(response => {
                  // setSsoLoading(false)
                  console.log('response', response);
                  localStorage.setItem('token', data.access_token);
                  localStorage.setItem('refresh_token', data.refresh_token);
                  localStorage.setItem('email', incMessage.username);
                  window.location.replace('/');
                });
              }
              setSsoLoading(false);
            })
            .catch(err => {
              console.log('Error getting new token', err);
              setSsoLoading(false);
            });
        } else {
          // setOtherTestMessage("messageType Not Found")
        }
      } else {
        // setOtherTestMessage("e.detail Not Found")
      }
    });

    // @ts-ignore
    if (window.webkit && window.webkit.messageHandlers) {
      let iosPayload = {
        ssoTest: 2,
      };
      // @ts-ignore
      if (window.webkit.messageHandlers.ssoTest) {
        // @ts-ignore
        window.webkit.messageHandlers.ssoTest.postMessage(iosPayload);
      }
    }

    // if (!localStorage.getItem("token")) {
    //   // Let Electron know app is ready
    //   if (window.self !== window.top) {
    //     window.parent.postMessage(
    //       {
    //         event_id: "webapp_ready",
    //         data: {
    //           success: true
    //         },
    //       },
    //       "*"
    //     );
    //   }
    // }

    // Auto Login functionality

    let urlUsername = getQueryVariable('username');
    let password = getQueryVariable('password');
    let flowStationToken = getQueryVariable('flowStationToken');

    if (urlUsername) {
      console.log('urlUsername', urlUsername);
      localStorage.setItem('email', urlUsername);
    }

    let token = localStorage.getItem('token');
    let email = localStorage.getItem('email');

    console.log({ flowStationToken, email, urlUsername });

    if (flowStationToken) {
      if (token && email) {
        // this.state = { loaded: true, authenticated: true };
        setAuthenticated(true);
        setLoaded(true);
      } else {
        processLoginByToken({
          flowStationToken,
          onSuccess: () => {
            setAuthenticated(true);
            setLoaded(true);
          },
          onFail: () => {
            setAuthenticated(false);
            setLoaded(true);
          },
        });
      }
    } else if (urlUsername) {
      if (!isEmpty(email) && urlUsername !== email) {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        // this.state = { loaded: true, authenticated: false };
        setAuthenticated(false);
        setLoaded(true);
      } else {
        if (token && email) {
          let token = localStorage.getItem('token');
          let email = localStorage.getItem('email');
          let username = getQueryVariable('username');
          let password = getQueryVariable('password');

          if (username && password && email && token) {
            localStorage.removeItem('token');

            processLogin({
              username: email,
              password: password,
              onSuccess: () => {
                setAuthenticated(true);
                setLoaded(true);
              },
              onFail: () => {
                setAuthenticated(false);
                setLoaded(true);
              },
            });
          }

          // this.state = { loaded: true, authenticated: true };
        } else if (email && password) {
          console.log({ email });
          console.log({ password });
          processLogin({
            username: email,
            password: password,
            onSuccess: () => {
              setAuthenticated(true);
              setLoaded(true);
            },
            onFail: () => {
              setAuthenticated(false);
              setLoaded(true);
            },
          });
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('email');
          setAuthenticated(false);
          setLoaded(true);
        }
      }
    } else {
      if (token && email) {
        // this.state = { loaded: true, authenticated: true };
        setAuthenticated(true);
        setLoaded(true);
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        // this.state = { loaded: true, authenticated: false };
        setAuthenticated(false);
        setLoaded(true);
      }
    }

    // Check if Notifications are supported
    if ('Notification' in window) {
      // Check if they have not allowed notifications yet
      if (Notification.permission !== 'denied') {
        Notification.requestPermission();
      }
    }

    return () => {
      clearTimeout(refreshTokenInterval);
      window.removeEventListener('iosMessage', () => {});
      window.removeEventListener('setAppType', () => {});
    };
    // eslint-disable-next-line
  }, []);

  const PrivateLayout = ({ authenticated }: any) => {
    return authenticated ? <Outlet /> : <Navigate to="/login" replace />;
  };

  let msTeamsLogin = () => {
    authentication
      .getAuthToken()
      .catch(e => {
        console.log('Teams err', e);
        localStorage.setItem('SSOTeamsLoggedOut', 'true');
      })
      .then(token => {
        // console.log('Teams token!', token);
        setSsoLoading(true);
        if (token) {
          fetch(MSALSSO, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              console.log('RES', res);

              return res.json();
            })
            .then(data => {
              if (data.message) {
                if (data.message === 'sso_not_enabled') {
                  setSsoLoading(false);
                  setSsoNotAllowed(true);
                  localStorage.removeItem('token');
                  localStorage.removeItem('email');
                  setTimeout(() => {
                    setSsoNotAllowed(false);
                  }, 10000);
                }
              }

              if (data.access_token && data.refresh_token) {
                let payload = {
                  useremail: data.user_email,
                };

                let autoLogin = async () => {
                  axios({
                    method: 'post',
                    url: API.REACT_APP_API_LOGINDETAILS,
                    data: payload,
                    headers: {
                      Authorization: 'Bearer ' + data.access_token,
                      Pragma: 'no-cache',
                    },
                  }).then(response => {
                    // setSsoLoading(false)
                    console.log('response', response);
                    localStorage.setItem('token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    localStorage.setItem('email', data.user_email);
                    window.location.replace('/');
                  });
                };
                autoLogin();
              }
              setSsoLoading(false);
            })
            .catch(err => {
              setSsoLoading(false);
              console.log('Error getting new token', err);
            });
        }
      });
  };

  // let readStorage = () => {
  //   for (let i = 0; i < localStorage.length; i++) {
  //     let read = localStorage.getItem(localStorage.key(i));
  //     console.log(read);
  //   }
  // }

  // let testListener = () => {
  //   console.log("test listener")
  //   const testMessage = new CustomEvent("setAppType", {
  //     detail: {
  //       appType: "android"
  //     }
  //   }
  //   );
  //   window.dispatchEvent(testMessage);
  // }

  // let testLogout = () => {
  //   getLatestRefreshToken();
  // }

  // Check if we are in Teams App
  if (
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  ) {
    // app.initialize();
    console.log('Detected Teams iFrame');
    app.initialize().then(() => {
      console.log('SUCCESS!!!');
      if (
        !localStorage.getItem('token') &&
        !localStorage.getItem('SSOTeamsLoggedOut')
      ) {
        msTeamsLogin();
      }
    });
    app.notifySuccess();
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary level={LEVEL_WARN} fallbackUI={RollbarFallback}>
        <AppProvider
          value={{
            loggedInUser: loggedInUserData,
            orgId,
            organization
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>SyncTimes</title>
            <meta
              name="description"
              content="Log into SyncTimes web application"
            />
          </Helmet>

          {loggedInUserData && <BlankSound />}

          {ssoLoading || ssoLoadingOther ? (
            <div
              style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: 2,
                backgroundColor: 'white',
              }}
            >
              <img
                className="flashing"
                style={{
                  height: 200,
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                src={SynctimesIcon}
                alt="SyncTimes"
              />
            </div>
          ) : (
            <div />
          )}

          <Router>
            {loaded ? (
              <Suspense fallback={<Loading />}>
                <NotifyProvider>
                  <Routes>
                    <Route
                      path="/"
                      element={<DataWrapper authenticated={authenticated} />}
                    />
                    <Route
                      path="/alerts"
                      element={<Alerts authenticated={authenticated} />}
                    />
                    <Route
                      path="/lobby"
                      element={
                        <LobbyDataWrapper authenticated={authenticated} />
                      }
                    />
                    <Route
                      path="/login"
                      element={<LoginPage location={window.location} />}
                    />
                    <Route path="/splash" element={<Splash />} />
                    <Route path="/video" element={<Video />} />
                    <Route
                      path="/patientcontent"
                      element={<PatientContent />}
                    />
                    <Route path="/version" element={<AppVersion />} />
                    <Route path="/clinicmap" element={<ClinicMap />} />
                    <Route
                      path="/clinicmapcreator"
                      element={<ClinicMapCreator />}
                    />
                    <Route
                      path="/clinicmaploader"
                      element={<ClinicMapLoader />}
                    />
                    <Route path="/mobileapp" element={<MobileApp />} />

                    <Route
                      path="/admin"
                      element={<AdminContainer user={loggedInUserData} />}
                    />

                    <Route
                      element={<PrivateLayout authenticated={authenticated} />}
                    >
                      <Route path="/flowmap" element={<Flowmap />} />
                      <Route
                        path="/documentation"
                        element={<Documentation />}
                      />
                    </Route>
                    {/* The 'NotFound' route matches when no other routes match */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </NotifyProvider>
              </Suspense>
            ) : (
              <div>Loading</div>
            )}
          </Router>
          {ssoNotAllowed || ssoNotAllowedOther ? (
            <WarningSnack
              warningSnack={true}
              warningMessage="Microsoft Single Sign On is not enabled in your Organization. Please reach out to your IT team."
            />
          ) : (
            <div />
          )}
        </AppProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
