import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { API } from '../apiconfig';
import { ISite } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useSites = (initialOrgId: string | null) => {
  const [sites, setSites] = useState<ISite[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSites = useCallback(
    async (orgId: string | null): Promise<ISite[]> => {
      if (!orgId) {
        return [];
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(API.REACT_APP_API_SITES + orgId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Pragma: 'no-cache',
          },
        });
        const fetchedSites = response?.data?.data ?? [];
        setSites(fetchedSites);
        return fetchedSites;
      } catch (err) {
        handleError(err);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (initialOrgId) {
      fetchSites(initialOrgId);
    }
  }, [initialOrgId, fetchSites]);

  const refreshSites = useCallback(
    (orgId: string | null): Promise<ISite[]> => {
      return fetchSites(orgId);
    },
    [fetchSites]
  );

  const handleError = (err: any) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      console.log('UNAUTHORIZED, KICK EM OUT');
      setError('Unauthorized. Please log in again.');
      checkRefreshToken();
    } else {
      console.error('Error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return {
    sites,
    isLoading,
    error,
    refreshSites,
    fetchSites,
  };
};
