import
  {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Typography
  } from '@material-ui/core';
import { FC, Fragment } from 'react';

import { CheckboxField, TextField } from '../../../../../common';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import
  {
    Control,
    FieldErrors,
    useFieldArray,
    UseFormSetValue,
    UseFormWatch,
  } from 'react-hook-form';
import
  {
    IDepartment,
    IOrganization,
  } from '../../../../../interfaces/interfaces';
import { TFormSchema } from '../DepartmentForm';
import AthenaPatientConfig from './AthenaPatientConfig';
import DepartmentFrontDeskInputFieldsSubform from './DepartmentFrontDeskInputFieldsSubform';
import DepartmentPatientIdentifierSubform from './DepartmentPatientIdentifierSubform';

type TProps = {
  classes: any;
  department: IDepartment;
  control: Control<TFormSchema>;
  organization: IOrganization;
  setValue: UseFormSetValue<TFormSchema>;
  watch: UseFormWatch<TFormSchema>;
  errors: FieldErrors<TFormSchema>;
};

const DepartmentFrontDeskIntegrationSubform: FC<TProps> = props => {
  const {
    control,
    classes,
    department,
    organization,
    setValue,
    watch,
    errors,
  } = props;

  // const { loggedInUserData } = useAppContext();

  // const departmentPatientContentList = watch('departmentPatientContentList');
  // const { patientContentList = [] } = useFetchPatientContentList(
  //   loggedInUserData?.mongoOrganizationId
  // );

  // const newEhrDepartmentId = watch('ehrDeptId');

  const newEhrDeptId = watch('newEhrDeptId');
  const newEhrDeptIdIsDefault = watch('newEhrDeptIdIsDefault');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ehrDeptIdList',
  });

  const addNewEhrDepartmentId = () => {
    let newEhrData = {
      ehrDeptId: newEhrDeptId,
      isDefaultDept: newEhrDeptIdIsDefault,
    };
    console.log('NEW!', newEhrData);

    append(newEhrData);

    setValue('newEhrDeptId', '');
    setValue('newEhrDeptIdIsDefault', false);
  };

  return (
    <div>
      <div>
        <div>
          <div>
            <Typography variant="h4" style={{ marginTop: 25 }}>
              Front Desk
            </Typography>

            <TextField
              label="EHR Dept Name"
              name={'ehrDeptName'}
              control={control}
              errors={errors}
            />
            <br />
            <br />
          </div>
        </div>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>EHR Department IDs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="w-full">
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontSize: 14,
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                }}
              >
                EHR Department Ids:
              </div>
              {fields ? (
                <div className="">
                  <table>
                    <tbody>
                      {fields.map((ehr: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <div className="font-bold">{ehr.ehrDeptId}</div>
                          </td>

                          <td>
                            <div>
                              <CheckboxField
                                name={`ehrDeptIdList[${index}].isDefaultDept`}
                                classes={{
                                  root: classes.checkBox,
                                  checked: classes.checked,
                                }}
                                control={control}
                                errors={errors}
                                label={'Default'}
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <Button
                                className={``}
                                onClick={() => remove(index)}
                              >
                                <span className="text-red-500">X</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              <br />
              <TextField
                label="New EHR Dept ID"
                style={{ marginTop: 0 }}
                className={classes.textField}
                name="newEhrDeptId"
                margin="normal"
                control={control}
                errors={errors}
              />
              <br />

              <CheckboxField
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                name="newEhrDeptIdIsDefault"
                control={control}
                errors={errors}
                label={'Default Department'}
              />

              <Button
                color="primary"
                // disabled={newEhrDepartmentId.length === 0}
                onClick={addNewEhrDepartmentId}
              >
                Add New EHR Dept ID
              </Button>

              {organization.orgUsesAthena && (
                <AthenaPatientConfig
                  control={control}
                  errors={errors}
                  watch={watch}
                  organization={organization}
                  department={department}
                />
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        {!department.inheritSettingsFromDeptId && (
          <Fragment>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Front Desk Input Fields</Typography>
              </AccordionSummary>
              <DepartmentFrontDeskInputFieldsSubform
                control={control}
                errors={errors}
              />
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Flowstation Patient Identifier</Typography>
              </AccordionSummary>

              <DepartmentPatientIdentifierSubform
                control={control}
                errors={errors}
                watch={watch}
                namePrefix="fsPatientInfo"
                patientInfo={watch('fsPatientInfo')}
              />
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Inside Exam Room Patient Identifier</Typography>
              </AccordionSummary>

              <DepartmentPatientIdentifierSubform
                control={control}
                errors={errors}
                watch={watch}
                namePrefix="inRoomPatientInfo"
                patientInfo={watch('inRoomPatientInfo')}
              />
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Outside Exam Room Patient Identifier</Typography>
              </AccordionSummary>
              <DepartmentPatientIdentifierSubform
                control={control}
                errors={errors}
                watch={watch}
                namePrefix="outsideRoomPatientInfo"
                patientInfo={watch('outsideRoomPatientInfo')}
              />
            </Accordion>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DepartmentFrontDeskIntegrationSubform;
