import React, { useEffect, useState } from 'react';

// Material UI Imports
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
// import { Theme, makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Services & Types
import { IUser } from '../../../../../interfaces/interfaces';
import
  {
    ActionFormField,
    ActionUsageFields,
    DateRangeOnlyFields,
    LocalStorageToken,
    OnDemandEmailReport,
    ReportsAccessFields,
  } from '../emailReportingTypes';
import { sendOnDemandEmailReport } from '../emailReportsService';
import { availableReports, ReportData } from '../reportData';

// Components
import { ActionRequestsForm } from './ActionRequestsForm';
import EmailFieldManager from './EmailFieldManager';
import { FirstPatientForm } from './FirstPatientForm';
import { KeptApptsForm } from './KeptApptsForm';
import { PTVisitsBreakdownForm } from './PTVisitsBreakdownForm';
import { ReportsAccessedForm } from './ReportsAccessedForm';
import { UsersInOrgForm } from './UsersInOrgForm';

interface ReportFormProps {
  user: IUser;
  selectedReportType: ReportData | string;
  formFields: ActionFormField[];
  isRecurring: boolean;
  setIsFormReady: (isReady: boolean) => void;
  handleSelectOptionsChange: (key: string, value: string | boolean) => void;
}

const ReportForm: React.FC<ReportFormProps> = ({
  selectedReportType,
  formFields,
  handleSelectOptionsChange,
  user,
  setIsFormReady,
  isRecurring,
}) => {
  const renderForm = () => {
    if (typeof selectedReportType !== 'string') {
      switch (selectedReportType.reportType) {
        case 'ActionUsage':
          return (
            <ActionRequestsForm
              formFields={formFields}
              handleSelectChange={handleSelectOptionsChange}
              orgId={user.mongoOrganizationId}
              setIsFormReady={setIsFormReady}
              isRecurring={false}
            />
          );
        case 'FirstPatient':
          return (
            <FirstPatientForm
              handleSelectChange={handleSelectOptionsChange}
              setIsFormReady={setIsFormReady}
              isRecurring={isRecurring}
            />
          );
        case 'KeptAppointments':
          return (
            <KeptApptsForm
              handleSelectChange={handleSelectOptionsChange}
              setIsFormReady={setIsFormReady}
              isRecurring={isRecurring}
            />
          );
        case 'PatientVisitBreakdown':
          return (
            <PTVisitsBreakdownForm
              handleSelectChange={handleSelectOptionsChange}
              setIsFormReady={setIsFormReady}
              isRecurring={isRecurring}
            />
          );
        case 'ReportsAccess':
          return (
            <ReportsAccessedForm
              handleSelectChange={handleSelectOptionsChange}
              setIsFormReady={setIsFormReady}
              isSyncTimesAdmin={user.isSyncTimesAdmin}
              isRecurring={isRecurring}
            />
          );
        case 'UsersInOrg':
          return <UsersInOrgForm setIsFormReady={setIsFormReady} />;
        default:
          return null;
      }
    }
  };

  return renderForm();
};

interface OnDemandReportDialogProps {
  open: boolean;
  formFields: ActionFormField[];
  token: LocalStorageToken;
  availableUsers: IUser[];
  user: IUser;
  handleCancelPressed: () => void;
  showSnackbar: (message: string, isError: boolean) => void;
}

const OnDemandReportDialog: React.FC<OnDemandReportDialogProps> = ({
  open,
  user,
  availableUsers,
  handleCancelPressed,
  formFields,
  token,
  showSnackbar,
}) => {
  const [selectedReportType, setSelectedReportType] = useState<
    ReportData | string
  >(null);
  const [isFormReady, setIsFormReady] = useState(false);
  const [onDemandReport, setOnDemandReport] =
    useState<OnDemandEmailReport | null>(null);
  const [availableRecipients, setAvailableRecipients] =
    useState<IUser[]>(availableUsers);
  const [toRecipients, setToRecipients] = useState<IUser[]>([]);
  const [ccRecipients, setCcRecipients] = useState<IUser[]>([]);

  const initializeState = () => {
    setOnDemandReport({
      reportType: '',
      orgId: user.mongoOrganizationId,
      to: [],
      cc: [],
      customFields: {
        orgId: user.mongoOrganizationId,
        dateRangeName: '',
        reportAllOrgs: false,
        customStartDate: undefined,
        customEndDate: undefined,
      } as DateRangeOnlyFields,
    });
    setToRecipients([user]);
    setCcRecipients([]);
    setAvailableRecipients(availableUsers.filter(u => u.id !== user.id));
    setSelectedReportType(null);
    setIsFormReady(false);
  };

  useEffect(() => {
    console.log('Selected Report Type:', selectedReportType);
  }, [selectedReportType]);

  const handleSend = async () => {
    if (onDemandReport && selectedReportType && typeof selectedReportType !== 'string') {
      const updatedReport: OnDemandEmailReport = {
        ...onDemandReport,
        reportType: selectedReportType.reportType,
        to: toRecipients.map(recipient => recipient.userEmail),
        cc: ccRecipients.map(recipient => recipient.userEmail),
      };

      // Close the dialog box and reset the form
      handleCancelPressed();
      initializeState();

      console.log('Sending on-demand email report:', updatedReport);
      try {
        const response = await sendOnDemandEmailReport(token, updatedReport);

        if (response.success) {
          console.log('On-demand email report sent successfully');
          showSnackbar('Report sent successfully', false);
        } else {
          console.error('Failed to send on-demand email report');
          showSnackbar('Failed to send the report', true);
        }
      } catch (error) {
        console.error('Error sending on-demand email report:', error);
        showSnackbar('Error sending the report', true);
      }
    }
  };

  const handleSelectOptionsChange = (key: string, value: string | boolean) => {
    console.log('Select Options Change', key, value);
    setOnDemandReport(prevReport => {
      if (prevReport && selectedReportType && typeof selectedReportType !== 'string') {
        const { customFields } = prevReport;
        let updatedCustomFields;

        switch (selectedReportType.reportType) {
          case 'ActionUsage':
            updatedCustomFields = {
              ...(customFields as ActionUsageFields),
              [key]: value,
              orgId: user.mongoOrganizationId,
            };
            break;
          case 'FirstPatient':
          case 'KeptAppointments':
          case 'PatientVisitBreakdown':
            updatedCustomFields = {
              ...(customFields as DateRangeOnlyFields),
              [key]: value,
              orgId: user.mongoOrganizationId,
            };
            break;
          case 'ReportsAccess':
            updatedCustomFields = {
              ...(customFields as ReportsAccessFields),
              [key]: value,
              orgId: user.mongoOrganizationId,
            };
            break;
          case 'UsersInOrg':
            updatedCustomFields = {
              ...customFields,
              [key]: value,
              orgId: user.mongoOrganizationId,
            };
            break;
          default:
            return prevReport;
        }

        return {
          ...prevReport,
          customFields: updatedCustomFields,
          reportType: selectedReportType.reportType,
        };
      }
      return null;
    });
  };

  const handleAddRecipient = (recipient: IUser, field: 'to' | 'cc') => {
    if (field === 'to') {
      setToRecipients(prevRecipients => [...prevRecipients, recipient]);
    } else {
      setCcRecipients(prevRecipients => [...prevRecipients, recipient]);
    }
    setAvailableRecipients(prevAvailableRecipients =>
      prevAvailableRecipients.filter(r => r.id !== recipient.id)
    );
  };

  const handleRemoveRecipient = (recipient: IUser, field: 'to' | 'cc') => {
    if (field === 'to') {
      setToRecipients(prevRecipients =>
        prevRecipients.filter(r => r.id !== recipient.id)
      );
    } else {
      setCcRecipients(prevRecipients =>
        prevRecipients.filter(r => r.id !== recipient.id)
      );
    }
    setAvailableRecipients(prevAvailableRecipients => [
      ...prevAvailableRecipients,
      recipient,
    ]);
  };

  const handleCancel = () => {
    handleCancelPressed();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancelPressed}
      onEnter={initializeState}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { width: '600px' } }}
    >
      <DialogTitle id="form-dialog-title">Send an email report</DialogTitle>
      <DialogContent>
        <Paper
          style={{
            padding: 25,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Autocomplete
            id="report-search"
            options={availableReports}
            getOptionLabel={(option: any) => option?.displayLabel}
            getOptionSelected={(option: any, value) =>
              option.reportType === value.reportType
            }
            onChange={(_, value) => setSelectedReportType(value)}
            value={selectedReportType}
            renderInput={params => (
              <TextField {...params} label="Reports" variant="outlined" />
            )}
          />

          {selectedReportType && (
            <>
              <EmailFieldManager
                toRecipients={toRecipients}
                ccRecipients={ccRecipients}
                availableUsers={availableRecipients}
                onAddRecipient={handleAddRecipient}
                onRemoveRecipient={handleRemoveRecipient}
              />

              <ReportForm
                selectedReportType={selectedReportType}
                formFields={formFields}
                handleSelectOptionsChange={handleSelectOptionsChange}
                user={user}
                setIsFormReady={setIsFormReady}
                isRecurring={false}
              />
            </>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSend}
          color="primary"
          disabled={
            !isFormReady || !selectedReportType || toRecipients.length === 0
          }
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnDemandReportDialog;
