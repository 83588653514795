import { FC, useEffect } from 'react';
import { getQueryVariable } from '../utils/utils';

import { makeStyles } from '@material-ui/core/styles';

import { API } from '../apiconfig';

const styles: any = () => ({
  videoContainer: {
    textAlign: 'center',
    fontSize: 36,
    backgroundColor: 'black',
  },
  splashImage: {
    width: 350,
  },
  videoStyle: {
    width: '100%',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
});

const useStyles = makeStyles(styles);

type TProps = {
  image?: any;
};

const Video: FC<TProps> = () => {
  // const [image, setImage] = useState(synctimesLogo);

  const classes = useStyles();

  useEffect(() => {
    let orgId = getQueryVariable('orgId');

    if (orgId) {
      let URL = API.REACT_APP_API_ORGANIZATIONS + '/orgId=' + orgId;

      fetch(URL, { headers: { 'Org-UID': 'UID1', Pragma: 'no-cache' } })
        .then(response => response.json())
        .then(data => {
          // console.log(data);
          // setImage(data.data.orgImageUrl);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div className={classes.videoContainer}>
      <video className={classes.videoStyle} muted loop autoPlay>
        <source
          src="https://cesiumstorage.blob.core.windows.net/patienteducation/TCC.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
