import { createTheme, makeStyles, Theme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    secondary: {
      main: "#A7D6A4",
    },
  },
});

const styles: any = (theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  head: {
    backgroundColor: "#fafafa",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  sortSelect: {
    cursor: "pointer",
  },
  sortField: {
    marginLeft: theme.spacing(),
    maxWidth: 350,
  },
  tableRow: {
    height: 70,
  },
  tableCell: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  drawer: {
    padding: 50,
  },
  colorSwitchBase: {
    color: "#4FAC48",
    "&$colorChecked": {
      color: "#4FAC48",
      "& + $colorBar": {
        backgroundColor: "#4FAC48",
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  globalActionsContainer: {
    display: "block",
  },
  actionButton: {
    cursor: "pointer",
    fontSize: 16,
  },
  autoMargin: {
    margin: "auto",
  },
  confirmText: {
    fontSize: "1.5em",
    textAlign: "center",
  },
  successText: {
    color: "#4FAC48",
    fontSize: "1.5em",
    textAlign: "center",
  },
  warningText: {
    color: "red",
  },
  warningContainer: {
    marginBottom: 10,
  },
  executeButton: {
    border: "1px solid red",
    color: "red",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  groupActionExpansionPanel: {
    maxWidth: 900,
  },
  appName: {
    fontSize: 16,
    fontWeight: "bold",
    cursor: "pointer",
  },
  sortCheckbox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
  },
  refreshButton: {
    zIndex: 10,
    backgroundColor: "#4FAC48",
    position: "absolute",
    right: theme.spacing(3),
  },
  newDeviceButton: {
    zIndex: 10,
    backgroundColor: "#4FAC48",
    position: "absolute",
    right: theme.spacing(12),
  },
  snackbar: {
    backgroundColor: "#4FAC48",
    color: "white",
  },
  wrapIcon: {
    color: "white",
    verticalAlign: "middle",
    display: "inline-flex",
  },
  textField: {
    width: 180,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  saveNewDeviceButton: {
    backgroundColor: "#F0F1F9",
  },
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const useStyles = makeStyles(styles);