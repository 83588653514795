import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDevice } from '../../../../../hooks/useDevice';
import { IDeviceHistory } from '../../../../../interfaces/interfaces';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
});

interface DeviceHistoryTableProps {
  deviceId: string;
}

const DeviceHistoryTable: React.FC<DeviceHistoryTableProps> = ({
  deviceId,
}) => {
  const classes = useStyles();
  const { deviceHistory } = useDevice();
  const [historyData, setHistoryData] = useState<IDeviceHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchDeviceHistory = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const history = await deviceHistory(deviceId);
      if (Array.isArray(history)) {
        setHistoryData(history);
      } else {
        console.error('Fetched data is not an array:', history);
        setHistoryData([]);
      }
    } catch (err) {
      console.error('Failed to fetch device history:', err);
      setError(
        err instanceof Error ? err : new Error('An unknown error occurred')
      );
      setHistoryData([]);
    } finally {
      setIsLoading(false);
    }
  }, [deviceId, deviceHistory]);

  useEffect(() => {
    fetchDeviceHistory();
  }, [fetchDeviceHistory]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const paginatedData = historyData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table} aria-label="device history table">
          <TableHead>
            <TableRow>
              <TableCell>Note</TableCell>
              <TableCell>MT Timestamp</TableCell>
              <TableCell>Client</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.note}</TableCell>
                <TableCell>{row.mountainTimeStamp}</TableCell>
                <TableCell>{row.deviceClientName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={historyData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DeviceHistoryTable;
