import { getQueryVariable } from '../../utils/utils';

export const getCurrentPage = () => {
  let currentPage = 'admin';

  if (getQueryVariable('sites')) {
    currentPage = 'sites';
  }
  if (getQueryVariable('departments')) {
    currentPage = 'departments';
  }
  if (getQueryVariable('rooms')) {
    currentPage = 'rooms';
  }
  if (getQueryVariable('users')) {
    currentPage = 'users';
  }
  if (getQueryVariable('equipment')) {
    currentPage = 'equipment';
  }
  if (getQueryVariable('devices')) {
    currentPage = 'devices';
  }
  if (getQueryVariable('applications')) {
    currentPage = 'applications';
  }
  if (getQueryVariable('media')) {
    currentPage = 'media';
  }
  if (getQueryVariable('displays')) {
    currentPage = 'displays';
  }
  if (getQueryVariable('servicelines')) {
    currentPage = 'servicelines';
  }
  if (getQueryVariable('ehrmapping')) {
    currentPage = 'ehrmapping';
  }
  if (getQueryVariable('surveys')) {
    currentPage = 'surveys';
  }
  if (getQueryVariable('integrations')) {
    currentPage = 'integrations';
  }
  if (getQueryVariable('clinicmapbuilder')) {
    currentPage = 'clinicmapbuilder';
  }
  if (getQueryVariable('syncadminoptions')) {
    currentPage = 'syncadminoptions';
  }
  if (getQueryVariable('campuses')) {
    currentPage = 'campuses';
  }

  return currentPage;
};
