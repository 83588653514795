import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { canDo } from '../../../../utils/permissionCheck';
import { ICampus, TAdminState } from '../../../../interfaces/interfaces';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  TextField,
  Drawer,
} from '@material-ui/core';
import CloseButton from '@material-ui/icons/Clear';
import { useStyles } from './styles';
import { useCampus } from '../../../../hooks/useCampus';
import useToast from '../../../../hooks/useToast';
import { CAMPUS_TEMPLATE } from './constants';

type TProps = {
  campusToEdit: ICampus | null;
  open: boolean;
  onClose: () => void;
  isCreatingNewCampus: boolean;
};

const CampusDrawer: React.FC<TProps> = ({
  campusToEdit,
  open,
  onClose,
  isCreatingNewCampus,
}) => {
  const classes = useStyles();
  const loggedInUserData = useSelector(
    (state: { adminData: TAdminState }) => state.adminData?.loggedInUserData
  );

  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [updatedCampus, setUpdatedCampus] = useState<ICampus>(CAMPUS_TEMPLATE);
  const [hasModified, setHasModified] = useState(false);

  const { createCampus, updateCampus } = useCampus(
    loggedInUserData.mongoOrganizationId
  );
  const { presentToast, ToastComponent } = useToast();

  useEffect(() => {
    if (isCreatingNewCampus) {
      setUpdatedCampus(CAMPUS_TEMPLATE);
    } else if (campusToEdit) {
      setUpdatedCampus(campusToEdit);
    }
    setHasModified(false);
  }, [campusToEdit, isCreatingNewCampus]);

  const handleChange = useCallback(
    (name: keyof ICampus) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUpdatedCampus(prev => ({ ...prev, [name]: event.target.value }));
      setHasModified(true);
    },
    []
  );

  const closeWithoutSaving = useCallback(() => {
    setShowSaveAlert(false);
    onClose();
  }, [onClose]);

  const handleClose = useCallback(() => {
    if (hasModified) {
      setShowSaveAlert(true);
    } else {
      onClose();
    }
  }, [hasModified, onClose]);

  const handleSave = useCallback(() => {
    console.log('handleSave tapped');

    if (!updatedCampus.campusName.trim()) {
      presentToast('error', 'Campus name cannot be empty');
      return;
    }

    const operation = isCreatingNewCampus ? createCampus : updateCampus;

    operation(updatedCampus)
      .then(() => {
        presentToast(
          'success',
          `Campus ${isCreatingNewCampus ? 'created' : 'updated'} successfully`
        );
        setShowSaveAlert(false); // Dismiss the save alert dialog
        setHasModified(false); // Reset the modified flag
        onClose(); // Close the drawer
      })
      .catch(err => {
        console.error('Error saving campus:', err);
        presentToast(
          'error',
          `Failed to ${
            isCreatingNewCampus ? 'create' : 'update'
          } campus. Please try again.`
        );
      });
  }, [
    updatedCampus,
    isCreatingNewCampus,
    createCampus,
    updateCampus,
    presentToast,
    onClose,
  ]);

  const toggleIsActive = useCallback(() => {
    console.log('toggleIsActive');
    setUpdatedCampus(prev => ({ ...prev, isActive: !prev.isActive }));
    setHasModified(true);
  }, []);

  return (
    <>
      {/* On Save Dialog */}
      <Dialog open={showSaveAlert}>
        <DialogTitle>Unsaved changes!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to save your changes before exiting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Yes
          </Button>
          <Button onClick={closeWithoutSaving} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Drawer  */}
      <Drawer anchor="right" open={open} onClose={handleClose}>
        {campusToEdit && (
          <div className={classes.drawer}>
            <div className={classes.closeButton} onClick={handleClose}>
              <CloseButton />
            </div>
            <h2>
              {isCreatingNewCampus
                ? 'Create a new organization'
                : `Editing ${updatedCampus.campusName}`}
            </h2>
            <br />
            {canDo(['SyncAdmin'], loggedInUserData) && (
              <div
                style={{
                  marginLeft: 8,
                  marginRight: 8,
                  marginTop: 16,
                  marginBottom: 8,
                }}
              >
                <span
                  style={{ fontSize: 13, color: '#757575', fontWeight: 400 }}
                >
                  Campus ID
                </span>
                <div>{campusToEdit.id}</div>
              </div>
            )}

            <TextField
              required
              label="Campus Name"
              className={classes.textField}
              value={updatedCampus.campusName}
              onChange={handleChange('campusName')}
              margin="normal"
            />

            <br />

            <div style={{ cursor: 'pointer' }} onClick={toggleIsActive}>
              <Checkbox
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={updatedCampus.isActive}
              />
              <span>Is Active</span>
            </div>

            {hasModified && (
              <div className={classes.saveWarning}>Unsaved Changes!</div>
            )}
            <Button
              className={classes.submitButton}
              onClick={() => handleSave()}
              color="primary"
            >
              Save
            </Button>
          </div>
        )}
      </Drawer>
      <ToastComponent />
    </>
  );
};

export default CampusDrawer;
