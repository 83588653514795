import axios from "axios";
import { API } from "../apiconfig"

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  // console.log('Query variable %s not found', variable);
}

export function getCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function checkRefreshToken() {


  if (localStorage.getItem("pauseRefreshToken")) {
    console.log("its paused")
  } else {
    localStorage.setItem("pauseRefreshToken", true)
    console.log("called checkRefreshToken!!")
    let token = localStorage.getItem("token");
    let refreshToken = localStorage.getItem("refresh_token");

    let URL = API.REACT_APP_API_REFRESHTOKEN
    let data = {
      refreshTokenString: refreshToken,
    };

    console.log('data to send', data)

    axios({
      method: "post",
      url: URL,
      data,
      headers: {
        Authorization: "Bearer " + token,
        Pragma: "no-cache",
      }
    }).then((response) => {
      console.log("REFRESH TOKEN", response)
      if (response.data) {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("token_expire", response.data.utc_expiry)
        localStorage.removeItem("pauseRefreshToken")
        window.location.reload();
      }
    }).catch(err => {
      console.log("refresh token err", err)

      if (err.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        localStorage.removeItem("pauseRefreshToken")

        if (getQueryVariable("username") && getQueryVariable("password")) {
          // Reload FS with login params
          let location = window.location.href;
          window.location.replace(location);
        } else {
          window.location.replace("/");
        }
      }

      localStorage.removeItem("pauseRefreshToken")
      
      })
  }

}