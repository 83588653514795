import { useState } from 'react';
import { API } from '../apiconfig';
import { checkRefreshToken } from '../utils/utils';
import { fetchLoggedInUser } from '../actions/adminActions';

export const useSubscription = (input: { orgId: string }) => {
  const { orgId } = input;

  const [updating, setUpdating] = useState(false);

  const update = (updates: any): Promise<any> => {
    let token = localStorage.getItem('token');
    return fetch(API.REACT_APP_API_SUBSCRIPTIONS, {
      method: 'PUT',
      body: JSON.stringify(updates),
      // body: JSON.stringify(deleted),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        setUpdating(false);
        return res.json();
      })
      .then(data => {
        // console.log("res", data);

        let userEmail = localStorage.getItem('email');
        let token = localStorage.getItem('token');
        fetchLoggedInUser(userEmail, token, orgId);
      })
      .catch(err => {
        setUpdating(false);
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  return {
    update,
    updating,
  };
};
