import React, { Component } from "react";
import { API } from "../../../apiconfig";

// Material
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";

import { checkRefreshToken } from "../../../utils/utils";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#A7D6A4",
    },
  },
});

const styles: {} = {
  container: {
    padding: 50,
  },
  fileInput: {
    marginTop: 16,
    marginBottom: 16,
  },
  gone: {
    paddingTop: 20,
    display: "none",
  },
  progress: {
    color: "#4FAC48",
  },
  error: {
    color: "red",
  },
  loading: {
    paddingTop: 20,
    textAlign: "center",
  },
  colorSwitchBase: {
    color: "#4FAC48",
    "&$colorChecked": {
      color: "#4FAC48",
      "& + $colorBar": {
        backgroundColor: "#4FAC48",
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  apkError: {
    color: "red",
  },
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
};

interface UploadApkProps {
  closeDialog: () => void;
  classes: any;
}

interface UploadApkState {
  appName: string;
  appVersion: string;
  apkByte: any;
  apkDetails: any;
  isSyncTimesRoomApp: boolean;
  loading: boolean;
  isDuplicate: any;
  apkError: string;
}

class UploadApk extends Component<UploadApkProps, UploadApkState> {
  constructor(props: UploadApkProps) {
    super(props);
    this.state = {
      apkDetails: null,
      appName: "",
      appVersion: "",
      isSyncTimesRoomApp: true,
      loading: false,
      isDuplicate: false,
      apkByte: null,
      apkError: "",
    };
  }

  fileSelectedHandler = (event: any) => {
    if (event.target.files[0]) {
      // console.log("file name", fileName); // make sure it has .apk at the end of it!
      var reader = new FileReader();

      // let appVersion = event.target.files[0].name;
      // Determine app name
      // if (this.state.appVersion.length > 1) {
      //   appVersion = this.state.appVersion;
      // } else {
      //   appVersion = event.target.files[0].name;
      // }

      reader.onload = (event: any) => {
        this.setState({
          appName: this.state.appName,
          appVersion: this.state.appVersion,
          apkByte: event.target.result.split("base64,").pop(),
          isSyncTimesRoomApp: this.state.isSyncTimesRoomApp,
        });
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  uploadApk = () => {
    if (this.state.apkByte) {
      let token = localStorage.getItem("token");

      let payload = JSON.stringify({
        appName: this.state.appName,
        appVersion: this.state.appVersion,
        isSyncTimesRoomApp: this.state.isSyncTimesRoomApp,
        apkByte: this.state.apkByte,
      });

      console.log("Payload", payload);

      // CHECK IF APPNAME AND VERSION NUMBER EXIST
      if (this.state.appVersion && this.state.appName) {
        this.setState({
          loading: true,
        });

        fetch(API.REACT_APP_API_APK, {
          method: "POST",
          body: payload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res: any) => {
            res
              .json()
              .then((data: any) => ({
                data: data,
              }))
              .then((res: any) => {
                // console.log("res", res);
                // console.log("response!", res.data.data);
                if (res.data.data.isDuplicate) {
                  console.log("DUPLICATE");
                  this.setState({
                    loading: false,
                    isDuplicate: true,
                  });
                } else {
                  // console.log("NOT DUPLICATE");
                  this.setState({
                    loading: false,
                    isDuplicate: false,
                    apkError: "",
                  });
                  this.props.closeDialog();
                }
              });
            // console.log("sent", res);
            // return res.json();
          })
          .catch(function (err) {
            console.log("Error: ", err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      } else {
        console.log("Missing Fields");
        this.setState({
          apkError: "Missing Fields",
          loading: false,
        });
      }
    } else {
      console.log("No apk selected");
    }
  };

  handleAppName = (event: any) => {
    this.setState({
      appName: event.target.value,
    });
  };

  handleAppVersion = (event: any) => {
    this.setState({
      appVersion: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <Typography variant="h4" gutterBottom component="h2">
            Upload APK
          </Typography>
          {this.state.isDuplicate ? <div className={classes.error}>This version of app already exists!</div> : <div />}
          <TextField required label="App Name" value={this.state.appName} onChange={this.handleAppName} />
          <br />
          <TextField required label="App Version" value={this.state.appVersion} onChange={this.handleAppVersion} />
          <br />
          <div
            onClick={() => {
              this.setState({
                isSyncTimesRoomApp: !this.state.isSyncTimesRoomApp,
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={this.state.isSyncTimesRoomApp}
            />
            Room App
          </div>
          <input type="file" onChange={this.fileSelectedHandler} className={classes.fileInput} />
          <br />
          <Typography className={classes.apkError} gutterBottom>
            {this.state.apkError}
          </Typography>
          <Button variant="contained" color="primary" onClick={this.uploadApk}>
            Upload APK
          </Button>
          <div className={this.state.loading ? classes.loading : classes.gone}>
            <CircularProgress className={classes.progress} color="secondary" />
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(UploadApk);
