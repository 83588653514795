import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { dateRangeOptions } from "../reportData";
import { DateRangeOnlyFields } from "../emailReportingTypes";

export interface PTVisitsBreakdownFormProps {
  handleSelectChange: (key: keyof DateRangeOnlyFields, value: string) => void;
  setIsFormReady: (isReady: boolean) => void;
  isRecurring: boolean;
  incomingSettings?: {
    dateRangeName?: string;
    customStartDate?: Date | undefined;
    customEndDate?: Date | undefined;
  };
}

export const PTVisitsBreakdownForm: React.FC<PTVisitsBreakdownFormProps> = ({ handleSelectChange, setIsFormReady, isRecurring, incomingSettings }) => {
  const [selectedDateRange, setSelectedDateRange] = useState(incomingSettings?.dateRangeName || "");
  const [customStartDate, setCustomStartDate] = useState(incomingSettings?.customStartDate?.toString() || "");
  const [customEndDate, setCustomEndDate] = useState(incomingSettings?.customEndDate?.toString() || "");
  const [dateError, setDateError] = useState("");

  const getDateRangeOptions = () => {
    if (isRecurring) {
      return dateRangeOptions.filter((option) => option.key !== "Custom");
    }
    return dateRangeOptions;
  };

  const filteredDateRangeOptions = getDateRangeOptions();

  useEffect(() => {
    let isReady = selectedDateRange !== "";

    if (selectedDateRange === "Custom") {
      isReady = customStartDate !== "" && customEndDate !== "" && dateError === "";
    }

    if (setIsFormReady) {
      setIsFormReady(isReady);
    }
  }, [selectedDateRange, customStartDate, customEndDate, dateError, setIsFormReady]);

  const handleFormChange = (value: string) => {
    setSelectedDateRange(value);
    handleSelectChange("dateRangeName", value);
  };

  const handleDateChange = (key: keyof DateRangeOnlyFields, value: string) => {
    if (key === "customStartDate") {
      setCustomStartDate(value);
      if (value && customEndDate && new Date(value) > new Date(customEndDate)) {
        setDateError("Start date must be before or equal to the end date");
      } else {
        setDateError("");
        handleSelectChange("customStartDate", value);
      }
    } else if (key === "customEndDate") {
      setCustomEndDate(value);
      if (customStartDate && value && new Date(customStartDate) > new Date(value)) {
        setDateError("End date must be after or equal to the start date");
      } else {
        setDateError("");
        handleSelectChange("customEndDate", value);
      }
    }
  };

  return (
    <div>
      <InputLabel style={{ fontSize: 12 }}>Date Range</InputLabel>
      <Select value={selectedDateRange} onChange={(e) => handleFormChange(e.target.value as string)} style={{ width: 250 }}>
        {filteredDateRangeOptions.map((option, index) => (
          <MenuItem key={index} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <div>
        {selectedDateRange === "Custom" && (
          <>
            <TextField
              label="Start Date"
              type="date"
              value={customStartDate}
              onChange={(e) => handleDateChange("customStartDate", e.target.value)}
              style={{ marginTop: 10 }}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(dateError)}
            />
            <TextField
              label="End Date"
              type="date"
              value={customEndDate}
              onChange={(e) => handleDateChange("customEndDate", e.target.value)}
              style={{ marginTop: 10, marginLeft: 10 }}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(dateError)}
            />
            {dateError && <div style={{ color: "red", marginTop: 5 }}>{dateError}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default PTVisitsBreakdownForm;
