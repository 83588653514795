import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Fade from '@material-ui/core/Fade';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  snackbar: {
    backgroundColor: '#D32F2F',
  },
  icon: {
    fontSize: 22,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface ErrorSnackProps {
  errorSnack: boolean;
  errorMessage: string;
  autoHideDuration?: number; // Optional for custom duration
}

const ErrorSnack: React.FC<ErrorSnackProps> = ({
  errorSnack,
  errorMessage,
  autoHideDuration = 3000,
}) => {
  const [open, setOpen] = useState(false);

  console.log({ open });
  const classes = useStyles();

  useEffect(() => {
    if (errorSnack) {
      setOpen(true);
      const timer = setTimeout(() => setOpen(false), autoHideDuration);
      return () => clearTimeout(timer); // Cleanup on unmount/errorSnack change
    }
  }, [errorSnack, autoHideDuration]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={errorSnack}
      TransitionComponent={Fade}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.snackbar}
        message={
          <span id="message-id" className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {errorMessage}
          </span>
        }
      />
    </Snackbar>
  );
};

export default ErrorSnack;
