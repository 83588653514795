import { createContext, useContext } from 'react';
import { IOrganization, IUser } from './interfaces/interfaces';

type TProps = {
  loggedInUser: IUser;
  orgId: string;
  organization: IOrganization;
};

export const AppContext = createContext<TProps>({
  loggedInUser: null,
  orgId: '',
  organization: null,
});

export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

export default AppContext;

export const useAppContext = () => {
  const context = useContext(AppContext);

  return context;
};
