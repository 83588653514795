import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API } from '../apiconfig';
import { fetchLoggedInUser } from '../actions/adminActions';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { RootState } from '../store';

type AppDispatch = ThunkDispatch<RootState, unknown, UnknownAction>;

export const useMockOrganization = (token: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const mockOrganization = useCallback(
    async (orgId: string): Promise<void> => {
      setIsLoading(true);
      setError(null);

      try {
        const URL = `${API.REACT_APP_API_ORGANIZATION}${orgId}`;
        const headers = {
          Authorization: `Bearer ${token}`,
          Pragma: 'no-cache',
        };

        await axios.get(URL, { headers });
        console.log('Mock organization API call successful for orgId:', orgId);

        localStorage.setItem('lastMockedOrganizationId', orgId);


        // Get the user email from localStorage
        const userEmail = localStorage.getItem('email');

        if (userEmail) {
          // Dispatch fetchLoggedInUser action with the mocked orgId
          dispatch(fetchLoggedInUser(userEmail, token, orgId));
          console.log('Logged in user data updated with mocked organization');
        } else {
          console.error('User email not found in localStorage');
          setError('User email not found');
        }
      } catch (err) {
        console.error('Error mocking organization', err);
        setError('Failed to mock organization');
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [token, dispatch]
  );

  return { mockOrganization, isLoading, error };
};
