import React, { useState, useRef, useEffect } from 'react';

type TProps = {
  className?: string;
  children: React.ReactNode;
  minFontSize?: number;
  maxFontSize?: number;
};

const ExpandableText: React.FC<TProps> = ({ 
  children, 
  className = '', 
  minFontSize = 12, 
  maxFontSize = 32 
}) => {
  const [fontSize, setFontSize] = useState(maxFontSize);
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeText = () => {
      if (containerRef.current && textRef.current) {
        let low = minFontSize;
        let high = maxFontSize;
        let mid;

        while (low <= high) {
          mid = Math.floor((low + high) / 2);
          textRef.current.style.fontSize = `${mid}px`;
          
          if (textRef.current.scrollHeight <= containerRef.current.clientHeight) {
            low = mid + 1;
          } else {
            high = mid - 1;
          }
        }

        setFontSize(high);
      }
    };

    resizeText();
    window.addEventListener('resize', resizeText);

    return () => {
      window.removeEventListener('resize', resizeText);
    };
  }, [children, minFontSize, maxFontSize]);

  return (
    <div 
      ref={containerRef} 
      className={`flex-grow min-h-0 w-full ${className}`}
    >
      <div 
        ref={textRef}
        className="w-full h-full overflow-auto"
        style={{ fontSize: `${fontSize}px` }}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandableText;