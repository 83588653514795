import { ChangeEvent, FC, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { API } from '../../../../apiconfig';

import { canDo } from '../../../../utils/permissionCheck';

import SuccessSnack from '../../../../utils/snack/SuccessSnack';

import Fuse from 'fuse.js';

// Material
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import CloseButton from '@material-ui/icons/Clear';

import Checkbox from '@material-ui/core/Checkbox';
// import ErrorSnack from "../../../utils/ErrorSnack";
// import CheckIcon from "@material-ui/icons/Check";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import { isEmpty } from 'lodash';
import { usePatientContentList } from '../../../../hooks/partientContent/usePatientContentList';
import { TAdminState } from '../../../../interfaces/interfaces';
import { PATIENT_CONTENT_TEMPLATE } from './constants';
import { theme, useStyles } from './styles';

import { QRCodeSVG } from 'qrcode.react';

type TProps = {};

const PatientContentListContainer: FC<TProps> = () => {
  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const { patientContentList = [], refetch: refetchPatientContentList } =
    usePatientContentList({
      orgId: loggedInUserData.mongoOrganizationId,
    });

  const [right, setRight] = useState(false);
  const [newPatientContent, setNewPatientContent] = useState(false);
  const [patientContentToEdit, setPatientContentToEdit] = useState(null);
  const [hasModified, setHasModified] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [showNewSurveyModal, setShowNewSurveyModal] = useState(false);
  const [newSurveyQuestion, setNewSurveyQuestion] = useState({
    surveyQuestionName: '',
    languageQuestions: [],
    questionResponseType: 'Text',
  });
  const [newLanguageQuestion, setNewLanguageQuestion] = useState({
    questionText: '',
    questionResponse: null,
    patientVisitId: null,
    questionLanguage: 'ENG',
    responseOptions: [],
  });
  const [newMultipleChoiceOption, setNewMultipleChoiceOption] = useState('');
  const [editingSurveyQuestion, setEditingSurveyQuestion] = useState(false);
  const [surveyQuestionToEditIndex, setSurveyQuestionToEditIndex] =
    useState(null);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [hideInactivePatientContent, setHideInactivePatientContent] =
    useState(true);
  const [sortBy, setSortBy] = useState('patientContentName');
  const [sortDescending, setSortDescending] = useState(false);
  const [filterString, setFilterString] = useState('');
  const [showGeneratedQrCode, setShowGeneratedQrCode] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [qrCodeCustomTag, setQrCodeCustomTag] = useState('');
  const [qrCodeSelectedPatientContent, setQrCodeSelectedPatientContent] =
    useState(null);

  const qrRef = useRef<any>();

  const [isSurveyKiosk, setIsSurveyKiosk] = useState(false);

  const qrCodeHideQrCode = true;
  const classes = useStyles();

  const onNewPatientContent = () => {
    setRight(true);
    setNewPatientContent(true);
    setPatientContentToEdit(PATIENT_CONTENT_TEMPLATE);
  };

  const onCloseWithoutSaving = (patientContentToEdit: any) => {
    setRight(false);
    setPatientContentToEdit(patientContentToEdit);

    setNewPatientContent(false);
    setHasModified(false);
    setShowSaveAlert(false);
  };

  const onToggleDrawer = (open: boolean, patientContentToEdit: any) => {
    console.log('Editing', patientContentToEdit);
    // Neeed to check permission here
    // if (canDo(["Update Patient Content"], this.props.loggedInUserData)) {
    if (hasModified) {
      setShowSaveAlert(true);
    } else {
      setRight(open);
      setPatientContentToEdit(patientContentToEdit);

      setNewPatientContent(false);
      setHasModified(false);
    }
  };

  const onHandleChange = (input: { name: string; value: string }) => {
    const { name, value } = input;

    setPatientContentToEdit({
      ...patientContentToEdit,
      [name]: value,
    });
    setHasModified(true);
  };

  const onChangeQrCodeDescription = (language: string) => (event: any) => {
    let newValue = event.target.value;

    let existingQrCodeDescriptions = patientContentToEdit.qrCodeDescriptions
      ? [...patientContentToEdit.qrCodeDescriptions]
      : [
          {
            descriptionText: '',
            descriptionLanguage: 'ENG',
          },
          {
            descriptionText: '',
            descriptionLanguage: 'SPA',
          },
          {
            descriptionText: '',
            descriptionLanguage: 'YID',
          },
        ];

    existingQrCodeDescriptions.forEach(description => {
      if (description.descriptionLanguage === language) {
        description.descriptionText = newValue;
      }
    });

    setPatientContentToEdit({
      ...patientContentToEdit,

      qrCodeDescriptions: [...existingQrCodeDescriptions],
    });

    setHasModified(true);
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const onHandleSubmit = () => {
    console.log('Handle submit');
    if (patientContentToEdit.patientContentType === 'Education') {
      if (!onValidateUrl(patientContentToEdit.patientEducationUrl)) {
        return;
      }
    }

    let token = localStorage.getItem('token');
    if (patientContentToEdit.patientContentName === '') {
    } else {
      // Save NEW patient content
      if (newPatientContent) {
        let newPatientContent = {
          ...patientContentToEdit,
          mongoOrgId: loggedInUserData.mongoOrganizationId,
        };

        let payload = JSON.stringify(newPatientContent);
        console.log('New patient content payload: ', newPatientContent);

        let URL = null;
        if (patientContentToEdit.patientContentType === 'Survey') {
          URL = API.REACT_APP_API_PATIENTSURVEY;
        }

        if (patientContentToEdit.patientContentType === 'Education') {
          URL = API.REACT_APP_API_PATIENTEDUCATION;
        }

        if (patientContentToEdit.patientContentType === 'Education Video') {
          URL = API.REACT_APP_API_PATIENTEDUCATIONVIDEO;
        }

        if (URL) {
          console.log('POSTING to', URL);
          fetch(URL, {
            method: 'POST',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(response => {
              return response.json();
            })
            .then(data => {
              console.log('RESPONSE', data);

              refetchPatientContentList();

              setRight(false);
              setHasModified(false);
              setNewPatientContent(false);

              setShowSaveSuccess(true);
              setShowSaveAlert(false);

              setTimeout(() => setShowSaveSuccess(false), 5000);
            });
        }
      } else {
        // Update existing patient content
        console.log('Updating patient content');
        let newPatientContent = {
          ...patientContentToEdit,
          mongoOrgId: loggedInUserData.mongoOrganizationId,
        };

        console.log('PAYLOAD', newPatientContent);
        let payload = JSON.stringify(newPatientContent);
        // console.log("New patient content payload: ", payload);

        let URL = null;
        if (patientContentToEdit.patientContentType === 'Survey') {
          URL = API.REACT_APP_API_PATIENTSURVEY;
        }

        if (patientContentToEdit.patientContentType === 'Education') {
          URL = API.REACT_APP_API_PATIENTEDUCATION;
        }

        if (patientContentToEdit.patientContentType === 'Education Video') {
          URL = API.REACT_APP_API_PATIENTEDUCATIONVIDEO;
        }

        if (URL) {
          console.log('PUTTING to', URL);
          fetch(URL, {
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(response => {
              return response.json();
            })
            .then(data => {
              console.log('PUT RESPONSE', data);
              refetchPatientContentList();

              setRight(false);
              setHasModified(false);
              setNewPatientContent(false);

              setShowSaveSuccess(true);
              setShowSaveAlert(false);

              setTimeout(() => setShowSaveSuccess(false), 5000);
            });
        }
      }
    }
  };

  const onAddLanguageQuestion = () => {
    console.log('New language question', newLanguageQuestion);

    let allowAdd = false;

    if (newSurveyQuestion.questionResponseType === 'Multiple Choice') {
      if (
        newLanguageQuestion.questionText !== '' &&
        newLanguageQuestion.responseOptions.length > 0
      ) {
        allowAdd = true;
      }
    } else {
      if (newLanguageQuestion.questionText !== '') {
        allowAdd = true;
      }
    }

    if (!allowAdd) {
    } else {
      let existingQuestions = [...newSurveyQuestion.languageQuestions];
      existingQuestions.push(newLanguageQuestion);
      console.log('done?', existingQuestions);

      let updatedNewSurveyQuestion = {
        ...newSurveyQuestion,
        languageQuestions: existingQuestions,
      };

      setNewSurveyQuestion(updatedNewSurveyQuestion);
      setNewLanguageQuestion({
        questionText: '',
        questionResponse: null,
        patientVisitId: null,
        questionLanguage: 'ENG',
        responseOptions: [],
      });

      console.log('DONE', newSurveyQuestion);
    }
  };

  const onDeleteLanguageQuestion = (index: number) => {
    let updatedLanguageQuestions = [...newSurveyQuestion.languageQuestions];
    updatedLanguageQuestions.splice(index, 1);

    let updatedNewSurveyQuestion = {
      ...newSurveyQuestion,
      languageQuestions: updatedLanguageQuestions,
    };

    setNewSurveyQuestion(updatedNewSurveyQuestion);
  };

  const onUpdateSurveyQuestion = () => {
    console.log('Updating survey question');
    let updatedSurveyQuestions = [...patientContentToEdit.surveyQuestions];
    updatedSurveyQuestions[surveyQuestionToEditIndex] = newSurveyQuestion;

    setPatientContentToEdit({
      ...patientContentToEdit,
      surveyQuestions: updatedSurveyQuestions,
    });

    setNewSurveyQuestion({
      surveyQuestionName: '',
      languageQuestions: [],
      questionResponseType: 'Text',
    });

    setNewLanguageQuestion({
      questionText: '',
      questionResponse: null,
      patientVisitId: null,
      questionLanguage: 'ENG',
      responseOptions: [],
    });

    setNewMultipleChoiceOption('');
    setShowNewSurveyModal(false);
    setSurveyQuestionToEditIndex(null);
    setHasModified(true);
  };

  const onCreateSurveyQuestion = () => {
    if (
      newSurveyQuestion.surveyQuestionName !== '' &&
      newSurveyQuestion.languageQuestions.length > 0
    ) {
      setPatientContentToEdit({
        ...patientContentToEdit,
        surveyQuestions: [
          ...patientContentToEdit.surveyQuestions,
          newSurveyQuestion,
        ],
      });

      setNewSurveyQuestion({
        surveyQuestionName: '',
        languageQuestions: [],
        questionResponseType: 'Text',
      });

      setNewLanguageQuestion({
        questionText: '',
        questionResponse: null,
        patientVisitId: null,
        questionLanguage: 'ENG',
        responseOptions: [],
      });

      setNewMultipleChoiceOption('');
      setShowNewSurveyModal(false);
    } else {
      // this.setState({
      //   fieldError: true,
      // });
    }
  };

  const onDeleteSurveyQuestion = (surveyQuestionToDelete: any) => {
    let updatedSurveyQuestions = patientContentToEdit.surveyQuestions.filter(
      (surveyQuestion: any) => surveyQuestion !== surveyQuestionToDelete
    );

    setPatientContentToEdit({
      ...patientContentToEdit,
      surveyQuestions: updatedSurveyQuestions,
    });
  };

  const onCloseNewSurveyModal = () => {
    setNewSurveyQuestion({
      surveyQuestionName: '',
      languageQuestions: [],
      questionResponseType: 'Text',
    });

    setNewLanguageQuestion({
      questionText: '',
      questionResponse: null,
      patientVisitId: null,
      questionLanguage: 'ENG',
      responseOptions: [],
    });

    setNewMultipleChoiceOption('');

    setShowNewSurveyModal(false);
    setEditingSurveyQuestion(false);
  };

  const onEditSurvey = (surveyQuestion: any, index: number) => {
    console.log('Edit this', surveyQuestion);

    setNewSurveyQuestion(surveyQuestion);
    setShowNewSurveyModal(true);
    setEditingSurveyQuestion(true);
    setSurveyQuestionToEditIndex(index);
  };

  const onHandleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  const onValidateUrl = (value: string) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const onGenerateQrCode = (patientContent: any) => {
    let generatedUrl = `${window.location.origin}/patientcontent?surveyId=${patientContent.id}&language=ENG`;
    if (qrCodeCustomTag !== '') {
      generatedUrl = `${window.location.origin}/patientcontent?surveyId=${patientContent.id}&language=ENG&tag=${qrCodeCustomTag}`;
    }

    // If patient content is an education video, use the video url instead
    if (
      patientContent.patientContentType === 'Education Video' ||
      patientContent.patientContentType === 'Education'
    ) {
      generatedUrl = patientContent.patientEducationUrl;
    }

    setQrCodeUrl(generatedUrl);
    setQrCodeSelectedPatientContent(patientContent);
    setShowGeneratedQrCode(true);
  };

  const onUpdateQrCode = (event: ChangeEvent<HTMLInputElement>) => {
    let generatedUrl = `${window.location.origin}/patientcontent?surveyId=${qrCodeSelectedPatientContent.id}&language=ENG`;
    if (event.target.value !== '') {
      generatedUrl = `${window.location.origin}/patientcontent?surveyId=${qrCodeSelectedPatientContent.id}&language=ENG&tag=${event.target.value}`;
    }
    // console.log("changed!", generatedUrl);

    setQrCodeUrl(generatedUrl);
    setQrCodeCustomTag(event.target.value);
  };

  const onDownloadQrCode = () => {
    const svgElement = qrRef.current?.querySelector('svg');
    if (!svgElement) {
      console.error('SVG element not found');
      return;
    }

    // Create a canvas element
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas size to match SVG
    const svgRect = svgElement.getBoundingClientRect();
    canvas.width = svgRect.width;
    canvas.height = svgRect.height;

    // Create image from SVG
    const img = new Image();
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const svgBlob = new Blob([svgData], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {
      // Draw image on canvas
      ctx?.drawImage(img, 0, 0);

      // Convert canvas to PNG
      const pngFile = canvas.toDataURL('image/png');

      // Trigger download
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QrCode';
      downloadLink.href = pngFile;
      downloadLink.click();

      // Clean up
      URL.revokeObjectURL(url);
    };

    img.src = url;
  };

  const onMoveSurveyQuestionUp = (index: number) => {
    let updatedSurveyQuestions = [...patientContentToEdit.surveyQuestions];
    let temp = updatedSurveyQuestions[index];
    if (updatedSurveyQuestions[index - 1]) {
      updatedSurveyQuestions[index] = updatedSurveyQuestions[index - 1];
      updatedSurveyQuestions[index - 1] = temp;

      setPatientContentToEdit({
        ...patientContentToEdit,
        surveyQuestions: updatedSurveyQuestions,
      });
    }
  };

  const onMoveSurveyQuestionDown = (index: number) => {
    console.log('check', index, patientContentToEdit.surveyQuestions.length);
    let updatedSurveyQuestions = [...patientContentToEdit.surveyQuestions];
    let temp = updatedSurveyQuestions[index];

    if (updatedSurveyQuestions[index + 1]) {
      updatedSurveyQuestions[index] = updatedSurveyQuestions[index + 1];
      updatedSurveyQuestions[index + 1] = temp;

      setPatientContentToEdit({
        ...patientContentToEdit,
        surveyQuestions: updatedSurveyQuestions,
      });
    }
  };

  const onChangeVideoHandler = (event: any) => {
    console.log('UPLOADED', event.target.files[0]);

    if (event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        // console.log("UMMM", event.target.result);
        let result = event.target.result;

        // data:video/mp4;base64,
        let videoByte = result.replace('data:video/mp4;base64,', '');
        console.log('FINISHED', videoByte);

        setPatientContentToEdit({
          ...patientContentToEdit,
          patientEducationVideoBytes: videoByte,
        });
      };
    }
  };

  let filteredPatientContent: any = [];

  let filter = sortBy;
  // Sort By Select
  if (!isEmpty(patientContentList)) {
    filteredPatientContent = patientContentList.sort((a: any, b: any) => {
      if (sortDescending) {
        // DESCENDING
        if (a[filter as keyof any] > b[filter as keyof any]) {
          return -1;
        }
        if (a[filter as keyof any] < b[filter as keyof any]) {
          return 1;
        }
        return 0;
      } else {
        // ASCENDING
        if (a[filter as keyof any] < b[filter as keyof any]) {
          return -1;
        }
        if (a[filter as keyof any] > b[filter as keyof any]) {
          return 1;
        }
        return 0;
      }
    });

    var fuseOptions = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['patientContentName', 'patientContentDescription'],
    };

    // console.log("check", filteredUsers);
    var fuse = new Fuse(filteredPatientContent, fuseOptions);
    var result = fuse.search(filterString);
    // console.log("Fuse Result", result);

    if (filterString.length > 0) {
      filteredPatientContent = result?.map((result: any) => result.item);
    }

    if (hideInactivePatientContent) {
      filteredPatientContent = filteredPatientContent.filter(
        (patientContent: any) => patientContent.isActive === true
      );
    }
  }

  let englishQrCodeDescription = '';
  let spanishQrCodeDescription = '';
  let yiddishQrCodeDescription = '';

  patientContentToEdit?.qrCodeDescriptions?.forEach(
    (qrCodeDescription: any) => {
      if (qrCodeDescription.descriptionLanguage === 'ENG') {
        englishQrCodeDescription = qrCodeDescription.descriptionText;
      } else if (qrCodeDescription.descriptionLanguage === 'SPA') {
        spanishQrCodeDescription = qrCodeDescription.descriptionText;
      } else if (qrCodeDescription.descriptionLanguage === 'YID') {
        yiddishQrCodeDescription = qrCodeDescription.descriptionText;
      }
    }
  );

  let generatedQrCode = qrCodeUrl;
  if (
    qrCodeHideQrCode &&
    qrCodeSelectedPatientContent?.patientContentType === 'Survey'
  ) {
    generatedQrCode += '&hideQrCode=true';
  }

  if (isSurveyKiosk) {
    generatedQrCode += '&isSurveyKiosk=true';
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog open={showSaveAlert}>
          <DialogTitle>Unsaved changes!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to save your changes before exiting?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => onHandleSubmit()}>
              Yes
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => onCloseWithoutSaving(patientContentToEdit)}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Modal open={showNewSurveyModal} onClose={onCloseNewSurveyModal}>
          <div className={classes.newSurveyModal}>
            <Typography variant="h4" gutterBottom component="h2">
              {editingSurveyQuestion
                ? 'Editing Survey Question'
                : 'New Survey Question'}
            </Typography>
            <TextField
              required
              label="Admin Label"
              className={classes.textField}
              style={{ width: '95%' }}
              value={newSurveyQuestion.surveyQuestionName}
              onChange={event => {
                setNewSurveyQuestion({
                  ...newSurveyQuestion,
                  surveyQuestionName: event.target.value,
                });
              }}
              margin="normal"
              // multiline
            />
            <br />
            <FormControl
              style={{ width: '95%' }}
              className={classes.textField}
              margin="normal"
            >
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Response Type
              </InputLabel>
              <NativeSelect
                disabled={
                  newSurveyQuestion.languageQuestions.length > 0 ? true : false
                }
                value={newSurveyQuestion.questionResponseType}
                onChange={event => {
                  setNewSurveyQuestion({
                    ...newSurveyQuestion,
                    questionResponseType: event.target.value,
                  });
                }}
                input={<Input id="age-native-label-placeholder" />}
              >
                <option value={'Text'}>Text</option>
                <option value={'Multiple Choice'}>Multiple Choice</option>
                <option value={'Smiley Survey'}>
                  Happy / Neutral / Sad Face
                </option>
              </NativeSelect>
            </FormControl>
            <br />
            {newSurveyQuestion.languageQuestions.length > 0 ? (
              <div style={{ marginLeft: 8 }}>Language Questions</div>
            ) : null}

            {/* Loop through existing language questions */}
            <div style={{ marginLeft: 8 }}>
              {newSurveyQuestion.languageQuestions.map(
                (languageQuestion, index) => (
                  <div key={index}>
                    <div>
                      <li style={{ display: 'flex' }}>
                        Question ({languageQuestion.questionLanguage}):{' '}
                        <div
                          style={
                            languageQuestion.questionLanguage === 'YID'
                              ? {
                                  direction: 'rtl',
                                  marginLeft: 5,
                                  marginRight: 5,
                                }
                              : { marginLeft: 5, marginRight: 5 }
                          }
                        >
                          {languageQuestion.questionText}
                        </div>{' '}
                        <span
                          onClick={() => onDeleteLanguageQuestion(index)}
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                        >
                          X
                        </span>
                      </li>
                      {languageQuestion.responseOptions?.length > 0 ? (
                        <div style={{ marginLeft: 16, color: 'gray' }}>
                          {languageQuestion.responseOptions.map(
                            (option: string, i: any) => (
                              <div key={i}>- {option}</div>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {/* Answer Type: {" "} */}
                      {/* {this.state.newSurveyQuestion.questionResponseType === "Text" ? "Text" : "Multiple Choice"} */}
                      {/* Answer:{" "}
                    {languageQuestion.responseOptions.map((option: string, i: any) => (
                      <span key={i}>{option}, {" "}</span>
                    ))} */}
                    </div>
                  </div>
                )
              )}
            </div>
            <br />
            <div style={{ border: '1px solid lightgray' }}>
              <FormControl
                style={{ width: '95%' }}
                className={classes.textField}
                margin="normal"
              >
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Question Language
                </InputLabel>
                <NativeSelect
                  value={newLanguageQuestion.questionLanguage}
                  onChange={event => {
                    setNewLanguageQuestion({
                      ...newLanguageQuestion,
                      questionLanguage: event.target.value,
                    });
                  }}
                  input={<Input id="age-native-label-placeholder" />}
                >
                  <option value={'ENG'}>English</option>
                  <option value={'SPA'}>Espanol</option>
                  <option value={'YID'}>Yiddish</option>
                </NativeSelect>
              </FormControl>

              <TextField
                required
                label="Question Text"
                className={classes.textField}
                // style={{ width: "95%" }}
                style={
                  newLanguageQuestion.questionLanguage === 'YID'
                    ? { direction: 'rtl', width: '95%' }
                    : { width: '95%' }
                }
                value={newLanguageQuestion.questionText}
                // onChange={this.handleChange("patientContentDescription")}
                onChange={event => {
                  setNewLanguageQuestion({
                    ...newLanguageQuestion,
                    questionText: event.target.value,
                  });
                }}
                margin="normal"
                // multiline
              />
              <br />

              {newSurveyQuestion.questionResponseType === 'Multiple Choice' ? (
                <div>
                  {newLanguageQuestion.responseOptions.length > 0 ? (
                    <div style={{ color: '#757575', marginLeft: 8 }}>
                      Options:{' '}
                    </div>
                  ) : null}

                  {/* Loop through existing response options */}
                  <div style={{ marginLeft: 8 }}>
                    {newLanguageQuestion.responseOptions.map(
                      (responseOption: string, index: any) => (
                        <div key={index}>
                          {index + 1}. {responseOption}
                        </div>
                      )
                    )}
                  </div>

                  <TextField
                    required
                    label="Choice Option"
                    className={classes.textField}
                    style={{ width: '95%' }}
                    value={newMultipleChoiceOption}
                    onChange={event => {
                      setNewMultipleChoiceOption(event.target.value);
                    }}
                    // onChange={this.handleChange("patientContentDescription")}
                    // onChange={(event) => this.setState({ newLanguageQuestion: { ...newLanguageQuestion, questionText: event.target.value } })}
                    margin="normal"
                    // multiline
                  />
                  <br />
                  <Button
                    style={{ marginLeft: 8, marginBottom: 20 }}
                    variant="contained"
                    onClick={() => {
                      if (newMultipleChoiceOption === '') return;

                      setNewLanguageQuestion({
                        ...newLanguageQuestion,
                        responseOptions: [
                          ...newLanguageQuestion.responseOptions,
                          newMultipleChoiceOption,
                        ],
                      });

                      setNewMultipleChoiceOption('');
                    }}
                  >
                    Add Option
                  </Button>
                  <br />
                </div>
              ) : null}
              <Button
                variant="outlined"
                color="primary"
                style={{ marginBottom: 10, marginLeft: 8 }}
                onClick={onAddLanguageQuestion}
              >
                Add Language Question
              </Button>
            </div>

            <br />
            {newSurveyQuestion.languageQuestions.length > 0 ? (
              <div>
                {editingSurveyQuestion ? (
                  <Button
                    onClick={onUpdateSurveyQuestion}
                    color="primary"
                    variant="contained"
                  >
                    Update Survey Question
                  </Button>
                ) : (
                  <Button
                    onClick={onCreateSurveyQuestion}
                    color="primary"
                    variant="contained"
                  >
                    Create Survey Question
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </Modal>

        <Typography variant="h4" gutterBottom component="h2">
          Patient Content
          {canDo(['SyncAdmin'], loggedInUserData) ? (
            <Tooltip
              title="Add"
              aria-label="Add"
              onClick={() => onNewPatientContent()}
            >
              <Fab className={classes.newPatientContentButton}>
                <AddIcon />
              </Fab>
            </Tooltip>
          ) : null}
        </Typography>

        <Drawer
          anchor="right"
          open={right}
          onClose={() => onToggleDrawer(false, patientContentToEdit)}
        >
          {patientContentToEdit ? (
            <div className={classes.drawer}>
              <div
                className={classes.closeButton}
                onClick={() => onToggleDrawer(false, patientContentToEdit)}
                style={{ paddingBottom: '5px' }}
              >
                <CloseButton />
              </div>
              {newPatientContent ? (
                <h2>New Patient Content</h2>
              ) : (
                <h2>Editing {patientContentToEdit.patientContentName} </h2>
              )}

              <br />

              <div
                onClick={() => {
                  setPatientContentToEdit({
                    ...patientContentToEdit,
                    isActive: !patientContentToEdit.isActive,
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={patientContentToEdit.isActive}
                />
                Is Active
              </div>

              <TextField
                required
                label="Content Name"
                className={classes.textField}
                value={patientContentToEdit.patientContentName}
                onChange={event =>
                  onHandleChange({
                    name: 'patientContentName',
                    value: event.target.value,
                  })
                }
                margin="normal"
              />
              <br />

              <TextField
                required
                label="Content Description"
                className={classes.textField}
                value={patientContentToEdit.patientContentDescription}
                onChange={event =>
                  onHandleChange({
                    name: 'patientContentDescription',
                    value: event.target.value,
                  })
                }
                margin="normal"
                multiline
              />
              <br />

              {patientContentToEdit.patientContentType === 'Survey' ? (
                <div>
                  <br />
                  <div style={{ color: '#757575', marginLeft: 8 }}>
                    QR Code Prompt
                  </div>
                  <TextField
                    label="English (optional)"
                    style={{ width: '93%', marginTop: 8 }}
                    className={classes.textField}
                    placeholder="If you'd like to take this survey on your phone, please scan the QR code."
                    value={englishQrCodeDescription}
                    // value=""
                    onChange={() => onChangeQrCodeDescription('ENG')}
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                  <br />

                  <TextField
                    label="Spanish (optional)"
                    style={{ width: '93%', marginTop: 4 }}
                    className={classes.textField}
                    placeholder="Háganos saber cómo lo estamos haciendo escaneando el código QR de arriba."
                    value={spanishQrCodeDescription}
                    // value=""
                    onChange={() => onChangeQrCodeDescription('SPA')}
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                  <br />

                  <TextField
                    label="Yiddish (optional)"
                    style={{ width: '93%', marginTop: 4, direction: 'rtl' }}
                    className={classes.textField}
                    placeholder="אויב ווילט איר נעמען די סערוועי, דאן ביטע סקענט די QR קאוד"
                    value={yiddishQrCodeDescription}
                    // value=""
                    onChange={() => onChangeQrCodeDescription('YID')}
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                  <br />
                </div>
              ) : null}

              {newPatientContent ? (
                <FormControl
                  style={{ width: '90%' }}
                  className={classes.textField}
                  margin="normal"
                >
                  <InputLabel shrink htmlFor="age-native-label-placeholder">
                    Content Type *
                  </InputLabel>
                  <NativeSelect
                    value={patientContentToEdit.patientContentType}
                    onChange={event =>
                      onHandleChange({
                        name: 'patientContentType',
                        value: event.target.value,
                      })
                    }
                    input={<Input id="age-native-label-placeholder" />}
                  >
                    <option value={'Survey'}>Survey</option>
                    <option value={'Education'}>Education</option>
                    <option value={'Education Video'}>Education Video</option>
                  </NativeSelect>
                </FormControl>
              ) : null}

              {patientContentToEdit.patientContentType === 'Survey' ? (
                <div>
                  <div style={{ marginLeft: 8, marginBottom: 10 }}>
                    {patientContentToEdit.surveyQuestions.length > 0 ? (
                      <div>Survey Questions</div>
                    ) : null}
                    {patientContentToEdit.surveyQuestions.map(
                      (surveyQuestion: any, index: any) => (
                        <div key={index}>
                          <div>
                            <IconButton
                              aria-label="delete"
                              onClick={() => onMoveSurveyQuestionUp(index)}
                              size="small"
                              style={{ padding: 0 }}
                            >
                              <ArrowUp fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => onMoveSurveyQuestionDown(index)}
                              size="small"
                              style={{ padding: 0 }}
                            >
                              <ArrowDown fontSize="inherit" />
                            </IconButton>
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                onEditSurvey(surveyQuestion, index)
                              }
                            >
                              {index + 1}. {surveyQuestion.surveyQuestionName}{' '}
                            </span>
                            <strong
                              onClick={() =>
                                onDeleteSurveyQuestion(surveyQuestion)
                              }
                              style={{ color: 'red', cursor: 'pointer' }}
                            >
                              X
                            </strong>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {/* <br /> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginBottom: 10 }}
                    onClick={() => {
                      setShowNewSurveyModal(true);
                      setEditingSurveyQuestion(false);
                    }}
                  >
                    New Survey Question
                  </Button>
                </div>
              ) : null}

              {patientContentToEdit.patientContentType === 'Education' ? (
                <div>
                  <TextField
                    required
                    error={
                      onValidateUrl(
                        patientContentToEdit.patientEducationUrl
                      ) === false
                        ? true
                        : false
                    }
                    label="Patient Education URL"
                    className={classes.textField}
                    value={patientContentToEdit.patientEducationUrl}
                    onChange={event =>
                      onHandleChange({
                        name: 'patientEducationUrl',
                        value: event.target.value,
                      })
                    }
                    margin="normal"
                  />
                  <br />
                </div>
              ) : null}

              {patientContentToEdit.patientContentType === 'Education Video' ? (
                <div style={{ marginLeft: 8 }}>
                  <form>
                    <label>
                      {newPatientContent
                        ? 'Upload an MP4:'
                        : 'Replace existing MP4:'}

                      <br />
                      <br />
                      <input
                        type="file"
                        name="file"
                        accept="video/mp4"
                        onChange={onChangeVideoHandler}
                      />
                    </label>
                    <br />
                    <br />
                  </form>
                </div>
              ) : null}

              {hasModified && !newPatientContent ? (
                <div className={classes.saveWarning}>Unsaved Changes!</div>
              ) : (
                <div />
              )}
              <Button
                variant="contained"
                style={{ marginTop: 20 }}
                onClick={() => onHandleSubmit()}
                color="primary"
              >
                Save
              </Button>
            </div>
          ) : null}
        </Drawer>

        {showSaveSuccess ? (
          <SuccessSnack
            autoHideDuration={2000}
            successSnack={true}
            successMessage="Patient Content Saved!"
          />
        ) : null}

        <TextField
          label="Search"
          className={classes.textBadgeField}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onHandleFilter(event)
          }
        />
        <br />

        <div
          onClick={() => {
            setHideInactivePatientContent(!hideInactivePatientContent);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={hideInactivePatientContent}
          />
          Hide Inactive Patient Content
        </div>

        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell
                  style={{ cursor: 'pointer' }}
                  onClick={() => onSortBy('patientContentName')}
                >
                  {sortBy === 'patientContentName' ? (
                    <div>Name {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Name</div>
                  )}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }}>Description</TableCell>
                <TableCell style={{ cursor: 'pointer' }}>Type</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPatientContent.map((patientContent: any, index: any) => (
                <TableRow key={index}>
                  <TableCell
                    onClick={() => onToggleDrawer(true, patientContent)}
                    style={{ cursor: 'pointer' }}
                  >
                    {patientContent.patientContentName}
                  </TableCell>
                  <TableCell
                    onClick={() => onToggleDrawer(true, patientContent)}
                    style={{ cursor: 'pointer' }}
                  >
                    {patientContent.patientContentDescription}
                  </TableCell>
                  <TableCell
                    onClick={() => onToggleDrawer(true, patientContent)}
                    style={{ cursor: 'pointer' }}
                  >
                    {patientContent.patientContentType}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onGenerateQrCode(patientContent)}
                    >
                      Generate QR Code
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Dialog
          open={showGeneratedQrCode}
          onClose={() => setShowGeneratedQrCode(false)}
          style={{ padding: 10 }}
        >
          {/* <DialogTitle>QR Code</DialogTitle> */}
          <div style={{ margin: 25 }}>
            <div ref={qrRef}>
              <div>
                <QRCodeSVG value={generatedQrCode} size={250} />
              </div>
            </div>

            {qrCodeSelectedPatientContent?.patientContentType === 'Survey' ? (
              <div>
                <TextField
                  label="Custom Tag"
                  className={classes.textField}
                  style={{ width: '95%' }}
                  value={qrCodeCustomTag}
                  // onChange={(event) => this.setState({ qrCodeCustomTag: event.target.value })}
                  onChange={onUpdateQrCode}
                  margin="normal"
                  // multiline
                />
              </div>
            ) : null}

            {/* <div
                onClick={() =>
                  this.setState({
                    qrCodeHideQrCode: !this.state.qrCodeHideQrCode,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={this.state.qrCodeHideQrCode}
                />
                Hide QR Code in Survey
              </div> */}
            <div>
              <br />
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsSurveyKiosk(!isSurveyKiosk);
                }}
              >
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={isSurveyKiosk}
                />
                Is Survey Kiosk
              </div>

              <br />
              <Tooltip title="Click to Copy to Clipboard" placement="top">
                <TextField
                  label="Generated URL"
                  onClick={() => navigator.clipboard.writeText(generatedQrCode)}
                  value={generatedQrCode}
                  variant="outlined"
                  margin="none"
                  style={{ width: '100%' }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Tooltip>
            </div>
            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onDownloadQrCode}
              >
                Download QR Code
              </Button>
            </div>
          </div>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
};

export default PatientContentListContainer;
