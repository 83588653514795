import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { RecurrenceSchedule } from "../emailReportingTypes";
import Chip from "@material-ui/core/Chip";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#f5f5f5",
    color: "#333",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  chipSelected: {
    backgroundColor: "#50b848",
    color: "white",
    "&:hover": {
      backgroundColor: "#50b848",
    },
  },
}));

const defaultRecurrenceSchedule: RecurrenceSchedule = {
  scheduledTime: "9:00", // Set to the current date and time
  tzIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
  recurrencePattern: "Week",
  interval: 1,
  daysOfWeek: [1, 2, 3, 4, 5],
  // startDate: new Date(),
};

interface RecurringConfiguratorProps {
  recurrenceSchedule?: RecurrenceSchedule;
  setRecurrenceSchedule: (schedule: RecurrenceSchedule) => void;
}

const RecurringConfigurator: React.FC<RecurringConfiguratorProps> = ({ recurrenceSchedule, setRecurrenceSchedule }) => {
  const [schedule, setSchedule] = useState<RecurrenceSchedule>(defaultRecurrenceSchedule);
  const classes = useStyles();

  // const memoizedSetRecurrenceSchedule = useCallback(setRecurrenceSchedule, []);


  useEffect(() => {
    if (recurrenceSchedule) {
      const updatedSchedule = {
        ...recurrenceSchedule,
        tzIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // startDate: recurrenceSchedule.startDate || undefined,
        interval: recurrenceSchedule.interval || 1,
        daysOfWeek: recurrenceSchedule.daysOfWeek || [],
        scheduledTime: recurrenceSchedule.scheduledTime || "09:00",
      };
      setSchedule(updatedSchedule);
      setRecurrenceSchedule(updatedSchedule);
    } else {
      const defaultSchedule = {
        ...defaultRecurrenceSchedule,
        tzIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // startDate: new Date(),
        interval: 1,
        daysOfWeek: [1, 2, 3, 4, 5],
        scheduledTime: "09:00",
      };
      setSchedule(defaultSchedule);
      setRecurrenceSchedule(defaultSchedule);
    }
  }, [recurrenceSchedule, setRecurrenceSchedule]);

  // const handleScheduleChange = (key: string, value: any) => {
  //   const updatedSchedule = { ...schedule, [key]: value };
  //   setSchedule(updatedSchedule);
  //   setRecurrenceSchedule(updatedSchedule);
  // };

  const handleDaysOfWeekChange = (daysOfWeek: number[]) => {
    const updatedSchedule = { ...schedule, daysOfWeek };
    if (daysOfWeek.length === 7) {
      updatedSchedule.recurrencePattern = "Day";
    } else {
      updatedSchedule.recurrencePattern = "Week";
    }
    setSchedule(updatedSchedule);
    setRecurrenceSchedule(updatedSchedule);
  };

  const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const selectDay = (index: number) => {
    const newDaysOfWeek = schedule.daysOfWeek?.includes(index)
      ? schedule.daysOfWeek.filter((day: number) => day !== index)
      : [...(schedule.daysOfWeek || []), index];
    console.log("New days of week:", newDaysOfWeek); // Debug log
    handleDaysOfWeekChange(newDaysOfWeek);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Selected time:", event.target.value);
    const selectedTime = event.target.value;
    const updatedSchedule = {
      ...schedule,
      scheduledTime: selectedTime,
    };
    setSchedule(updatedSchedule);
    setRecurrenceSchedule(updatedSchedule);
  };

  return (
    <div style={{ border: "1px solid gray", padding: 10, borderRadius: 5, marginBottom: 10 }}>
      {/* <TextField
        label="Start sending report on:"
        type="date"
        value={schedule.startDate instanceof Date ? schedule.startDate.toISOString().slice(0, 10) : ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleScheduleChange("startDate", new Date(event.target.value))}
        InputLabelProps={{ shrink: true }}
        style={{ width: 250 }}
      /> */}
      {/* <br /> */}
      {/* <br /> */}
      <div style={{ display: "flex", alignItems: "center" }}>
        Repeat each week on:
        {/* <select style={{ marginLeft: 5 }} value={schedule.interval} onChange={(event) => handleScheduleChange("interval", parseInt(event.target.value))}>
          {[...Array(99).keys()].map((i) => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select> */}
        {/* <select
          style={{ marginLeft: 5, marginRight: 5 }}
          value={schedule.recurrencePattern}
          onChange={(event) => handleScheduleChange("recurrencePattern", event.target.value)}
        >
          <option value="Day">Days</option>
          <option value="Week">Weeks</option>
          <option value="Month">Months</option>
        </select> */}
      </div>
      <br />
      {/* {schedule.recurrencePattern !== "Month" && schedule.interval === 1 && ( */}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {dayNames.map((day, index) => (
          <Chip
            key={index}
            label={day}
            onClick={() => selectDay(index)}
            className={classes.chip}
            style={{
              backgroundColor: schedule.daysOfWeek?.includes(index) ? "#50b848" : "#f5f5f5",
              color: schedule.daysOfWeek?.includes(index) ? "white" : "#333",
            }}
          />
        ))}
      </div>
      {/* )} */}
      <br />
      <div style={{ paddingBottom: 20, display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>At:</span>
        <TextField type="time" value={schedule.scheduledTime} onChange={handleTimeChange} InputLabelProps={{ shrink: true }} style={{ width: 250 }} />
      </div>

      {/* <TextField
        label="End Date"
        type="date"
        value={schedule.endDate instanceof Date ? schedule.endDate.toISOString().slice(0, 10) : ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleScheduleChange("endDate", new Date(event.target.value))}
        InputLabelProps={{ shrink: true }}
        style={{ width: 250 }}
      /> */}

      {/* <br />
      <br /> */}
    </div>
  );
};

export default RecurringConfigurator;
