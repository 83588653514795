import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import clinicimage2 from '../../../../images/clinicimage2.jpg';

// import { API } from "../apiconfig";

import { Typography } from '@material-ui/core';
// import Tooltip from "@material-ui/core/Tooltip";
// import Fab from "@material-ui/core/Fab";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

//@ts-ignore
// import Cropper from "react-cropper";
import { API } from '../../../../apiconfig';
import { IRoom, TAdminState } from '../../../../interfaces/interfaces';

import { shallowEqual } from 'react-redux';
import { useClinicMaps } from '../../../../hooks/useClinicMaps';
import { useRooms } from '../../../../hooks/useRooms';
import { checkRefreshToken } from '../../../../utils/utils';
import { useStyles } from './styles';

// interface ClinicMapBuilderProps {
//   classes: any;
//   loggedInUserData: IUser;
//   fetchRoomsByOrgId: (orgId: string) => void;
//   fetchClinicMapsByOrgId: (orgId: string) => void;
//   rooms: IRoom[];
//   clinicMaps: any;
// }

// interface ClinicMapBuilderState {
//   coordArray: any;
//   selectedFileDetails: any;
//   clinicMapName: string;
//   fileHasBeenSelected: boolean;
//   selectedRoom: any;
//   imageLoaded: boolean;
//   updatingClinicMap: boolean;
//   updateClinicMapId: string;
//   loadedClinicMap: any;
//   roomCircleSize: number;
//   clinicMapSearchKey: number;
//   realRoomDistance: {
//     measuredDistance: number;
//     roomId1: string;
//     roomId2: string;
//     units: string;
//   };
//   showRoomSelectionOverlays: boolean;
// }

type TProps = {};
const ClinicMapBuilder: FC<TProps> = () => {
  // class ClinicMapBuilder extends Component<ClinicMapBuilderProps, ClinicMapBuilderState> {
  // constructor(props: ClinicMapBuilderProps) {
  //   super(props);
  //   this.state = {
  //     coordArray: [],
  //     selectedFileDetails: null,
  //     clinicMapName: "",
  //     fileHasBeenSelected: false,
  //     selectedRoom: null,
  //     imageLoaded: false,
  //     updatingClinicMap: false,
  //     updateClinicMapId: "",
  //     loadedClinicMap: null,
  //     roomCircleSize: 20,
  //     clinicMapSearchKey: 0,
  //     realRoomDistance: {
  //       measuredDistance: 0,
  //       roomId1: "",
  //       roomId2: "",
  //       units: "",
  //     },
  //     showRoomSelectionOverlays: true,
  //   };

  //   // if (localStorage.getItem("testClinicCoords")) {
  //   //     // @ts-ignore
  //   //     this.state.coordArray = JSON.parse(localStorage.getItem("testClinicCoords"));
  //   // }
  // }

  const classes = useStyles();

  const [coordArray, setCoordArray] = React.useState([]);
  const [selectedFileDetails, setSelectedFileDetails] = React.useState(null);
  const [clinicMapName, setClinicMapName] = React.useState('');
  const [fileHasBeenSelected, setFileHasBeenSelected] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [updatingClinicMap, setUpdatingClinicMap] = React.useState(false);
  const [updateClinicMapId, setUpdateClinicMapId] = React.useState('');
  // const [loadedClinicMap, setLoadedClinicMap] = React.useState(null);
  const [roomCircleSize, setRoomCircleSize] = React.useState(20);
  const [clinicMapSearchKey, setClinicMapSearchKey] = React.useState(0);
  const [realRoomDistance, setRealRoomDistance] = React.useState({
    measuredDistance: 0,
    roomId1: '',
    roomId2: '',
    units: '',
  });
  const [showRoomSelectionOverlays, setShowRoomSelectionOverlays] =
    React.useState(true);

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);

      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const organizationId = loggedInUserData?.mongoOrganizationId;

  const { rooms } = useRooms(organizationId);

  const { clinicMaps = [], refetch: refetchClinicMaps } =
    useClinicMaps(organizationId);

  // componentDidMount() {
  //   fetchRoomsByOrgId(loggedInUserData.mongoOrganizationId);
  //   fetchClinicMapsByOrgId(loggedInUserData.mongoOrganizationId);
  // }

  // getImageDetails = (e: any) => {

  //     var rect = e.target.getBoundingClientRect();
  //     var x = e.clientX - rect.left; //x position within the element.
  //     var y = e.clientY - rect.top;  //y position within the element.

  //     if (x) {
  //         // round up to whole number
  //         x = Math.round(x);
  //     }
  //     if (y) {
  //         // round up to whole number
  //         y = Math.round(y);
  //     }

  //     console.log("Image Clicked", e.clientX, e.clientY);

  //     // let x = e.clientX;
  //     // let y = e.clientY;

  //     this.setState({
  //         coordArray: [...this.state.coordArray, { x, y, roomName: "" }]
  //     })
  // }

  const onSelectImageCoords = (e: any) => {
    console.log('e', e.target.id);

    if (e.target.id.includes('circle-')) {
      setCoordArray(
        coordArray.filter(
          (item: any, i: number) => i !== parseInt(e.target.id.split('-')[1])
        )
      );
    }

    // make sure clinic-viewbox is the target.
    if (e.target.id === 'clinic-viewbox') {
      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.

      if (x) {
        // round up to whole number
        x = Math.round(x);
      }
      if (y) {
        // round up to whole number
        y = Math.round(y);
      }

      console.log('Image Clicked', e.clientX, e.clientY);

      // let x = e.clientX;
      // let y = e.clientY;

      setCoordArray([...coordArray, { x, y, roomId: '' }]);
    }
  };

  // const onSaveToLocalStorage = () => {
  //   localStorage.setItem('testClinicCoords', JSON.stringify(coordArray));
  // };

  const onFileSelectedHandler = (event: any) => {
    if (event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        var img = new Image();
        img.src = event.target.result;

        let imageDetails = {
          clinicMapImage: event.target.result.replace(
            /data:image\/[a-zA-Z]+;base64,/g,
            ''
          ),
          previewImage: reader.result,
        };

        setSelectedFileDetails(imageDetails);
        setFileHasBeenSelected(true);
      };
    }
  };

  const onHandleNewClinicMapNameChange = (event: any) => {
    setClinicMapName(event.target.value);
  };

  const onSubmitClinicMap = () => {
    console.log('Submit file!');
    let token = localStorage.getItem('token');

    // console.log("send this", payload);

    if (updatingClinicMap) {
      console.log('updating clinic map');

      let payload: any = {
        id: updateClinicMapId,
        clinicMapName: clinicMapName,
        clinicMapUrl: selectedFileDetails.clinicMapUrl,
        orgId: loggedInUserData.mongoOrganizationId,
        clinicMapDescription: '',
        roomCoordinates: [...coordArray],
        image: null,
        roomCircleSize: roomCircleSize,
        realRoomDistance: {
          ...realRoomDistance,
          roomId1: coordArray[0].roomId,
          roomId2: coordArray[1].roomId,
        },
      };

      console.log('payload', payload);

      fetch(API.REACT_APP_API_UPDATECLINICMAP, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          console.log('res', res);
          // fetchAllActions();
          setSelectedFileDetails(null);
          setClinicMapName('');
          setFileHasBeenSelected(false);
          setClinicMapSearchKey(clinicMapSearchKey + 1);
          setCoordArray([]);
          setRealRoomDistance({
            measuredDistance: 0,
            roomId1: '',
            roomId2: '',
            units: '',
          });

          refetchClinicMaps();
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    } else {
      console.log('creating new clinic map');

      let payload: any = {
        clinicMapName: clinicMapName,
        image: selectedFileDetails.clinicMapImage,
        orgId: loggedInUserData.mongoOrganizationId,
        clinicMapDescription: '',
        roomCoordinates: [...coordArray],
        roomCircleSize: roomCircleSize,
        coordArray: [],
        realRoomDistance: {
          ...realRoomDistance,
          roomId1: coordArray[0].roomId,
          roomId2: coordArray[1].roomId,
        },
      };
      fetch(API.REACT_APP_API_CREATECLINICMAP, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          console.log('res', res);
          // this.props.fetchAllActions();
          setSelectedFileDetails(null);
          setClinicMapName('');
          setFileHasBeenSelected(false);

          refetchClinicMaps();
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const onDeleteClinicMap = () => {
    console.log('Delete clinic map');

    let token = localStorage.getItem('token');
    let payload = {
      id: updateClinicMapId,
    };

    console.log('Payload', payload);

    fetch(API.REACT_APP_API_DELETECLINICMAP, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        console.log('res', res);

        setSelectedFileDetails(null);
        setClinicMapName('');
        setFileHasBeenSelected(false);
        setUpdatingClinicMap(false);
        setUpdateClinicMapId('');
        setClinicMapSearchKey(clinicMapSearchKey + 1);
        setRealRoomDistance({
          measuredDistance: 0,
          roomId1: '',
          roomId2: '',
          units: '',
        });

        refetchClinicMaps();
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onSelectClinicMap = (map: any) => {
    console.log('Selected', map);

    setCoordArray(map.roomCoordinates);
    // setLoadedClinicMap(map);
    setSelectedFileDetails({
      //  previewImage: `data:image/png;base64,${map.clinicMapUrl}`
      previewImage: map.clinicMapUrl,
    });
    setClinicMapName(map.clinicMapName);
    setFileHasBeenSelected(true);
    setUpdatingClinicMap(true);
    setUpdateClinicMapId(map.id);
    setRoomCircleSize(map.roomCircleSize);
    setRealRoomDistance({
      measuredDistance: map.realRoomDistance.measuredDistance,
      roomId1: map.realRoomDistance.roomId1,
      roomId2: map.realRoomDistance.roomId2,
      units: map.realRoomDistance.units,
    });
  };

  const onHandleDistanceUnitChange = (event: any) => {
    setRealRoomDistance({
      ...realRoomDistance,
      units: event.target.value,
    });
  };

  const onHandleDistanceMeasuredChange = (event: any) => {
    setRealRoomDistance({
      ...realRoomDistance,
      measuredDistance: event.target.value,
    });
  };

  console.log('logged in user data', loggedInUserData);

  // get width and height of clinicimage
  let image = new Image();
  image.src = clinicimage2; // <-- CHANGE THIS TO THE IMAGE BEING USED

  image.onload = () => {
    console.log('image width', image.width, 'image height', image.height);
  };

  console.log('CoordArray', coordArray);

  let activeRooms = rooms.filter((room: any) => room.isActive === true);
  console.log('active rooms', activeRooms);

  let uploadedImage = new Image();
  uploadedImage.src = selectedFileDetails
    ? selectedFileDetails.previewImage
    : '';
  uploadedImage.onload = () => {
    console.log(
      'uploadedImage width',
      uploadedImage.width,
      'uploadedImage height',
      uploadedImage.height
    );
    if (imageLoaded === false) {
      setImageLoaded(true);
    }
  };

  let dottedLinePathway: any = [];

  if (coordArray.length > 1) {
    // grab the first two coordinates
    let firstCoord = coordArray[0];
    let secondCoord = coordArray[1];
    dottedLinePathway.push(
      <g>
        <line
          x1={firstCoord.x}
          y1={firstCoord.y}
          x2={secondCoord.x}
          y2={secondCoord.y}
          stroke="red"
          strokeWidth="6"
          strokeDasharray="10,5"
        />
      </g>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom component="h2">
        Clinic Map Builder
        {/* <Tooltip title="Add" aria-label="Add"
                    // onClick={() => this.newOrganization(organizationTemplate)}
                    >
                        <Fab className={classes.newClinicMapButton}>
                            <AddIcon />
                        </Fab>
                    </Tooltip> */}
      </Typography>
      <div
      // style={{ display: "flex" }}
      >
        <Autocomplete
          id="clinicmap-search"
          key={clinicMapSearchKey}
          options={clinicMaps}
          getOptionLabel={(map: any) => map.clinicMapName}
          onChange={(event: any, map: any | null) => {
            if (map) {
              onSelectClinicMap(map);
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              margin="none"
              label="Search Clinic Map"
              style={{ width: 300, marginBottom: 20 }}
            />
          )}
        />

        {/* Clinic map inputs */}
        {/* <Typography color="textSecondary" gutterBottom>
                        New Clinic Map
                    </Typography> */}

        <div style={{ color: 'gray' }}>
          Note: Avoid excessive blankspace around map images.
        </div>
        <br />
        {selectedFileDetails && uploadedImage ? (
          <div style={{ display: 'flex' }}>
            {/* <div style={{ marginRight: 5 }}>
                                <Checkbox
                                    classes={{
                                        root: classes.checkBox,
                                        checked: classes.checked,
                                    }}
                                    style={{ marginLeft: 0, paddingLeft: 0 }}
                                    checked={showRoomSelectionOverlays}
                                    onClick={() => this.setState({
                                        showRoomSelectionOverlays: !showRoomSelectionOverlays
                                    })}
                                />
                                Show Room Selection Overlays
                                {coordArray.map((coord: any, index: number) => {
                                    return (
                                        <div key={index}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <strong>{index + 1}. </strong>
                                                <Autocomplete
                                                    id="room-search"
                                                    options={activeRooms}
                                                    value={activeRooms.find((room: IRoom) => room.id === coord.roomId)}
                                                    style={{ width: 150, padding: 0, marginTop: "-10px" }}
                                                    disableClearable
                                                    getOptionLabel={(room: IRoom) => room.siteDeptName + " - " + room.roomName}
                                                    onChange={(event: any, room: IRoom | null) => {
                                                        if (room) {
                                                            this.setState({
                                                                coordArray: this.state.coordArray.map((item: any, i: number) => {
                                                                    if (i === index) {
                                                                        return { ...item, roomId: room.id }
                                                                    }
                                                                    return item;
                                                                })
                                                            })
                                                        }
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            placeholder="Select Room"

                                                            style={{ width: 200 }}
                                                            multiline
                                                        />
                                                    }
                                                />
                                                <span style={{ color: "red", fontWeight: "bold", cursor: "pointer" }} onClick={() =>
                                                    this.setState({
                                                        coordArray: this.state.coordArray.filter((item: any, i: number) => i !== index)
                                                    })
                                                }>X</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> */}
            <div
              className="img-overlay-wrap"
              onMouseDown={e => onSelectImageCoords(e)}
              style={{ border: '1px solid gray' }}
            >
              <img
                src={selectedFileDetails.previewImage}
                alt="clinic"
                style={{ minWidth: uploadedImage.width }}
              />
              <svg
                id="clinic-viewbox"
                viewBox={`0 0 ${uploadedImage.width} ${uploadedImage.height}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                {console.log('Coordarray', coordArray)}
                {coordArray.map((coord: any, index: number) => {
                  return (
                    <g key={index}>
                      <circle
                        id={'circle-' + index}
                        style={{ cursor: 'pointer' }}
                        cx={coord.x}
                        cy={coord.y}
                        r={roomCircleSize}
                        stroke="black"
                        strokeWidth="3"
                        fill="pink"
                        shapeRendering="geometricPrecision"
                      />

                      {/* <foreignObject className="node" x={coord.x - 5} y={coord.y - 17} height="16" width="16">
                                                    <div id={"circle-" + index} style={{ cursor: "pointer" }}>
                                                        {index + 1}
                                                    </div>
                                                </foreignObject> */}

                      {showRoomSelectionOverlays ? (
                        <foreignObject
                          className="node"
                          x={coord.x - 70}
                          y={coord.y}
                          height="60"
                          width="200"
                        >
                          {/* @ts-ignore */}

                          <div
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid black',
                            }}
                          >
                            <Autocomplete
                              id="room-search"
                              options={activeRooms}
                              value={activeRooms.find(
                                (room: IRoom) => room.id === coord.roomId
                              )}
                              style={{
                                width: 150,
                                padding: 0,
                                marginTop: '-10px',
                              }}
                              disableClearable
                              getOptionLabel={(room: IRoom) =>
                                room.siteDeptName + ' - ' + room.roomName
                              }
                              onChange={(event: any, room: IRoom | string) => {
                                if (room && typeof room !== 'string') {
                                  // console.log("found room")
                                  setCoordArray(
                                    coordArray.map((item: any, i: number) => {
                                      if (i === index) {
                                        return { ...item, roomId: room.id };
                                      }
                                      return item;
                                    })
                                  );
                                }
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  margin="none"
                                  // autoFocus
                                  // label="Search Room"
                                  placeholder="Select Room"
                                  style={{ width: 200 }}
                                  multiline
                                />
                              )}
                            />

                            {/* <span style={{ color: "red", fontWeight: "bold", cursor: "pointer" }} onClick={() =>
                                                                this.setState({
                                                                    coordArray: this.state.coordArray.filter((item: any, i: number) => i !== index)
                                                                })
                                                            }>Delete</span> */}
                          </div>
                        </foreignObject>
                      ) : null}
                    </g>
                  );
                })}

                {dottedLinePathway}
              </svg>
            </div>

            {/* <div>
                                CoordArray
                                {this.state.coordArray.map((coord: any, index: number) => {
                                    return (
                                        <div key={index}>
                                            <strong>{index + 1}. </strong>
                                            X: {coord.x}, Y: {coord.y}
                                            <span style={{ color: "red", fontWeight: "bold" }} onClick={() =>
                                                // delete the item from the array
                                                this.setState({
                                                    coordArray: this.state.coordArray.filter((item: any, i: number) => i !== index)
                                                })
                                            }>X</span>

                                            <Autocomplete
                                                id="room-search"
                                                options={activeRooms}
                                                value={activeRooms.find((room: IRoom) => room.id === coord.roomId)}

                                                getOptionLabel={(room: IRoom) => room.roomName}
                                                onChange={(event: any, room: IRoom | null) => {
                                                    if (room) {
                                                        // console.log("found room")
                                                        this.setState({
                                                            coordArray: this.state.coordArray.map((item: any, i: number) => {
                                                                if (i === index) {
                                                                    return { ...item, roomId: room.id }
                                                                }
                                                                return item;
                                                            })
                                                        })
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        margin="none"
                                                        autoFocus
                                                        label="Search Room"
                                                        style={{ width: 300 }}
                                                    />
                                                }
                                            />

                                        </div>
                                    )
                                })}
                            </div> */}
          </div>
        ) : null}

        {/* {this.state.selectedFileDetails ? (
                        <Cropper
                            className="test"
                            ref="cropper"
                            src={this.state.selectedFileDetails.previewImage}
                            style={{ height: 200, width: "30%" }}
                            checkCrossOrigin={false}
                            aspectRatio={1}
                            autoCropArea={1}
                            guides={true}
                            crop={() => this._crop()}
                        />
                    ) : null} */}
        <br />
        <div>
          <label htmlFor="upload-action-file">
            {selectedFileDetails ? null : (
              <div className={classes.addMapSquare}>
                <AddIcon style={{ fontSize: 35, paddingTop: 12 }} />
              </div>
            )}
          </label>
          <input
            id="upload-action-file"
            style={{ display: 'none' }}
            type="file"
            onChange={onFileSelectedHandler}
          />
          <TextField
            id="standard-name"
            label="Clinic Map Name"
            style={{ marginTop: 0, marginRight: 10 }}
            className={classes.textField}
            value={clinicMapName}
            onChange={onHandleNewClinicMapNameChange}
            margin="normal"
          />
          <br />
          {selectedFileDetails && uploadedImage ? (
            <div style={{ width: 200 }}>
              Room Circle Size
              <Slider
                min={10}
                max={100}
                step={5}
                marks
                defaultValue={20}
                value={roomCircleSize}
                onChange={(event: any, value: any) => {
                  setRoomCircleSize(value);
                }}
              />
            </div>
          ) : null}
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            style={{ marginLeft: 0, paddingLeft: 0 }}
            checked={showRoomSelectionOverlays}
            onClick={() => {
              setShowRoomSelectionOverlays(!showRoomSelectionOverlays);
            }}
          />
          Show Room Selection Overlays
          {coordArray.length > 1 ? (
            <div>
              <TextField
                label="Distance of dotted red line"
                type="number"
                // value={distanceMeasured}
                value={realRoomDistance.measuredDistance}
                style={{ marginTop: 0, marginRight: 10 }}
                className={classes.textField}
                margin="normal"
                onChange={onHandleDistanceMeasuredChange}
              />

              <FormControl className={classes.formControl}>
                <InputLabel id="distance-unit">Distance Unit</InputLabel>
                <Select
                  labelId="distance-unit"
                  // value={distanceUnit}
                  value={realRoomDistance.units}
                  style={{ width: 150 }}
                  // onChange={handleChange}
                  // onChange={(e) => this.setState({ distanceUnit: e.target.value })}
                  onChange={onHandleDistanceUnitChange}
                >
                  <MenuItem value={'Ft'}>Feet</MenuItem>
                  <MenuItem value={'Meters'}>Meters</MenuItem>
                  <MenuItem value={'Steps'}>Steps</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : null}
        </div>
        <Button
          size="small"
          disabled={
            clinicMapName.length === 0 ||
            fileHasBeenSelected === false ||
            coordArray.length < 2
          }
          color="primary"
          variant="contained"
          onClick={onSubmitClinicMap}
        >
          {updatingClinicMap ? 'Update' : 'Submit'}
        </Button>

        {updatingClinicMap ? (
          <Button
            size="small"
            variant="outlined"
            onClick={onDeleteClinicMap}
            style={{ marginLeft: 20 }}
          >
            Delete Clinic Map
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ClinicMapBuilder;

// const mapStateToProps = (state: any) => ({
//   loggedInUserData: state.adminData.loggedInUserData,
//   rooms: state.adminData.rooms,
//   clinicMaps: state.adminData.clinicMaps,
// });

// export default connect(mapStateToProps, { fetchRoomsByOrgId, fetchClinicMapsByOrgId })(withStyles(styles)(ClinicMapBuilder));
