import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { API } from "../apiconfig";
import { IDepartment } from "../interfaces/interfaces";

export const useEHRMappings = (orgId: string) => {
  const [EHRMappings, setEHRMappings] = useState<IDepartment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchEHRMappings = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem("token");
    
    let URL =
    API.REACT_APP_API_UNLINKEDEHRMAPPING + '/orgId=' + orgId;

    const headers = {
      Authorization: `Bearer ${token}`,
      Pragma: "no-cache",
    };

    try {
      const response = await axios.get(URL, { headers });
      setEHRMappings(response.data.data);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        console.log("Unauthorized, attempting to refresh token");
        // You might want to implement the checkRefreshToken function
        // and call it here, or handle the unauthorized case differently
        setError("Unauthorized. Please log in again.");
      } else {
        console.error("Error fetching departments:", err);
        setError("Failed to fetch departments. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    fetchEHRMappings();
  }, [orgId, fetchEHRMappings]); // This effect runs when orgId changes

  const refetch = useCallback(() => {
    if (orgId) {
      fetchEHRMappings();
    }
  }, [orgId, fetchEHRMappings]);

  return { EHRMappings, isLoading, refetch,  error };
};
