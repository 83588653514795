import { makeStyles, Theme } from "@material-ui/core";

const styles: any = (theme: Theme) => ({
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
  MuiAutocomplete: {
    root: {
      "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput- root": {
        paddingRight: 0,
      },
    },
  },
  newClinicMapButton: {
    zIndex: 10,
    backgroundColor: "#4FAC48",
    position: "absolute",
    right: theme.spacing(3),
  },
  addMapSquare: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 10,
    textAlign: "center",
    cursor: "pointer",
    color: "#bdbdbd",
    border: "1px dashed #bdbdbd",
    height: 48,
    width: 48,
  },
});


export const useStyles = makeStyles(styles)