import { FC, useCallback, useState, useEffect } from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Interfaces
import {
  IAction,
  IDevice,
  TAdminState,
} from '../../../../../interfaces/interfaces';

// Hooks
import { useDevice } from '../../../../../hooks/useDevice';
import { useSignalR } from '../../../../../hooks/useSignalR';
import { useStyles } from './styles';
import { useDeviceDetails } from './useDeviceDetails';
import { useDeviceDetail } from '../../../../../hooks/useDeviceDetail';

// Utils
import { getObjectFromGzipEncodedData } from '../../../../../utils/Compression';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

// Components
import DeviceScreenshotContainer from './DeviceScreenshotContainer';
import DeviceEditForm from './DeviceEditForm';
import DeviceActionsPod from './DeviceActionsPod';
import DeviceDetailsInfo from './DeviceDetailsInfo';

type TProps = {
  deviceId: string;
  onClose: () => void;
  actionTypes: any[];
};

const DeviceDetails: FC<TProps> = ({ actionTypes, deviceId, onClose }) => {
  const classes = useStyles();
  const { delete: deleteDevice } = useDevice();

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const [isSignalRConnected, setIsSignalRConnected] = useState(false);
  const [actionExecuted, setActionExecuted] = useState(false);
  const [actionExecutedMessage, setActionExecutedMessage] = useState('');
  const [deviceToEdit, setDeviceToEdit] = useState<IDevice>({} as IDevice);

  const { refetch: refetchDevice } = useDeviceDetail({
    deviceId,
    onCompleted: device => {
      if (device) {
        setDeviceToEdit({
          ...device,
        });
      }
    },
  });

  useEffect(() => {
    if (deviceId) {
      refetchDevice(deviceId);
    }
  }, [refetchDevice, deviceId]);

  const handleUpdate = useCallback(
    (data: any) => {
      const updatedDevice = data.roomData;

      setDeviceToEdit({
        ...deviceToEdit,
        ...updatedDevice,
      });
    },
    [deviceToEdit]
  );

  const handleDeviceUpdate = useCallback((field: keyof IDevice, value: any) => {
    setDeviceToEdit(prevDevice => ({
      ...prevDevice,
      [field]: value,
    }));
  }, []);

  const { onActionSend } = useDeviceDetails(
    deviceId,
    loggedInUserData.userId,
    onClose
  );

  // SignalR
  useSignalR({
    onConnected: hub => {
      hub.on('remoteControlGzip', (stringData: any) => {
        const data = getObjectFromGzipEncodedData(stringData);
        handleUpdate(data);
      });

      hub
        .invoke('subscribeToMdmDeviceGzip', deviceId)
        .catch((error: any) => {
          console.error(
            '[SignalR]Error invoking subscribeToMdmDeviceGzip',
            error
          );

          setIsSignalRConnected(false);
        })
        .then(() => {
          console.log(`[SignalR] Subscribed to MdmDeviceGzip for ${deviceId}`);
          setIsSignalRConnected(true);
        });
    },
    onUnmount: hub => {
      hub
        .invoke('unsubscribeFromMdmDeviceGzip', deviceId)
        .catch((error: any) => {
          console.error(
            '[SignalR] Error invoking unsubscribeFromMdmDeviceGzip',
            error
          );
        });
    },
  });

  const onTakeScreenshot = () => {
    const action = {
      actionTypeId: 5,
      actionTypeName: 'Take Screenshot',
    } as IAction;

    if (!isEmpty(deviceToEdit)) {
      onActionSend(action, {
        shouldClose: false,
      });
    }
  };

  const onHome = () => {
    const action = {
      actionTypeId: 10,
      actionTypeName: 'Home Button',
    } as IAction;
    onActionSend(action, { shouldClose: false });
  };

  const onBack = () => {
    const action = {
      actionTypeId: 13,
      actionTypeName: 'Back Button',
    } as IAction;

    onActionSend(action, { shouldClose: false });
  };

  const onHandleClose = () => {
    setActionExecuted(false);
    setActionExecutedMessage('');
  };

  const onDeleteDevice = (deviceId: string) => {
    console.log('DELETE DEVICE', deviceId);
    deleteDevice(deviceId);
    onClose();
  };

  console.log('deviceToEdit >>>', deviceToEdit);

  return (
    <Grid item xs={12}>
      {isEmpty(deviceToEdit) ? (
        <div className={classes.loading}>
          <h2>Loading...</h2>
        </div>
      ) : (
        <div>
          {/* Device Details */}
          <DeviceDetailsInfo
            deviceToEdit={deviceToEdit}
            onClose={onClose}
            loggedInUserData={loggedInUserData}
          />

          {/* Screenshot */}
          {isSignalRConnected && !isEmpty(deviceToEdit) ? (
            <DeviceScreenshotContainer
              deviceToEdit={deviceToEdit}
              onTakeScreenshot={onTakeScreenshot}
              onHome={onHome}
              onBack={onBack}
              onSetDevice={deviceUpdates => {
                setDeviceToEdit({
                  ...deviceToEdit,
                  ...deviceUpdates,
                });
              }}
            />
          ) : (
            <div>
              <h2>Error loading screenshot</h2>
              <p>Refresh the page to try again</p>
            </div>
          )}

          <br />

          {/* Device Edit Form */}
          {!isEmpty(deviceToEdit) ? (
            <div>
              <DeviceEditForm
                deviceToEdit={deviceToEdit}
                onDeviceUpdate={handleDeviceUpdate}
              />
              <DeviceActionsPod
                deviceToEdit={deviceToEdit}
                actionTypes={actionTypes}
                onDeviceUpdate={handleDeviceUpdate}
              />
            </div>
          ) : (
            <div>Loading...</div>
          )}

          <Button
            onClick={() => onDeleteDevice(deviceToEdit?.deviceId)}
            variant="contained"
            style={{ marginTop: 50, float: 'right' }}
          >
            Delete Device
          </Button>
        </div>
      )}

      {/* Snackbar */}
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={actionExecuted}
        onClose={onHandleClose}
        autoHideDuration={2000}
        TransitionComponent={Fade}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Action Sent</span>}
      >
        <SnackbarContent
          className={classes.snackbar}
          message={
            <Typography variant="subtitle1" className={classes.wrapIcon}>
              <CheckCircleIcon />{' '}
              <span style={{ paddingLeft: 10 }}>{actionExecutedMessage}</span>
            </Typography>
          }
        />
      </Snackbar>
    </Grid>
  );
};

export default DeviceDetails;
