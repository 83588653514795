import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';

import { ExpandMoreIcon } from '../../../../../common';
import { IDepartment } from '../../../../../interfaces/interfaces';

import { useStyles } from '../style';
import DepartmentAutoClearActions from './autoClear/DepartmentAutoClearActions';
import ActionModifyDialog from './modify/ActionModifyDialog';
import DepartmentScheduledActions from './scheduled/DepartmentScheduledActions';

type TProps = {
  control: any;
  errors: any;
  watch: any;
  setValue: any;
  department: IDepartment;
  refetchDepartment: (...params: any) => any;
};

const ActionsListSubform: FC<TProps> = props => {
  const { department, setValue } = props;
  const [showActionDialog, toggleActionDialog] = React.useState(false);
  const [showVisibleActions, toggleVisibleActions] = React.useState(true);

  const classes = useStyles();

  const handleUpdateLocalDeptActions = (values: any) => {
    console.log('handleUpdateLocalDeptActions', { values });
  };

  return (
    <>
      <Typography variant="h4" style={{ marginTop: 25 }}>
        Actions
      </Typography>

      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            toggleActionDialog(true);
            toggleVisibleActions(true);
          }}
        >
          Actions
        </Button>

        <Button
          style={{ marginLeft: 10 }}
          variant="outlined"
          color="primary"
          onClick={() => {
            toggleActionDialog(true);
            toggleVisibleActions(false);
          }}
        >
          Hidden Actions
        </Button>
      </div>

      <br />

      <ActionModifyDialog
        open={showActionDialog}
        onClose={() => {
          toggleActionDialog(false);
        }}
        onSuccess={updatedDepartment => {
          console.log('ActionModifyDialog onSuccess', { updatedDepartment });

          setValue('actionsList', updatedDepartment.actionsList);
        }}
        department={department}
        showVisibleActions={showVisibleActions}
        updateLocalDeptActions={handleUpdateLocalDeptActions}
        modifyingMultipleDepts={false}
      />

      <Accordion>
        <AccordionSummary expandIcon={ExpandMoreIcon}>
          <Typography>Auto Clear Actions</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.actionsExpansionPanelDetails}>
          <DepartmentAutoClearActions
            department={department}
            classes={classes}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={ExpandMoreIcon}>
          <Typography>Scheduled Actions</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.actionsExpansionPanelDetails}>
          <DepartmentScheduledActions
            department={department}
            classes={classes}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ActionsListSubform;
