import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IDeviceSwitchDetails } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useDeviceSwitchDetails = (
  deviceId: string,
  options?: {
    shouldFetch?: boolean;
  }
): {
  deviceSwitchDetails: IDeviceSwitchDetails | null;
  loading: boolean;
  error: string | null;
  refetch: (deviceId: string) => Promise<IDeviceSwitchDetails>;
} => {
  const [deviceSwitchDetails, setDeviceSwitchDetails] =
    useState<IDeviceSwitchDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const shouldFetch = options?.shouldFetch ?? true;

  const fetchDeviceSwitchDetails =
    useCallback((): Promise<IDeviceSwitchDetails> => {
      return new Promise((resolve, reject) => {
        setLoading(true);
        setError(null);

        const token = localStorage.getItem('token');

        let headers = {
          headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
        };

        axios
          .get(API.REACT_APP_API_SWITCHDETAILS + deviceId, headers)
          .then(response => {
            setDeviceSwitchDetails(response.data);
            resolve(response.data);
          })
          .catch(error => {
            handleError(error);
          });
      });
    }, [deviceId]);

  // const takeScreenshot = useCallback((deviceId: string): Promise<IDeviceLocation> => {

  // }, []);

  useEffect(() => {
    if (shouldFetch) {
      fetchDeviceSwitchDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (err: any) => {
    console.error('Error:', err);
    setError(
      'An error occurred while fetching device locations. Please try again.'
    );
    setLoading(false);
    if (err.response?.status === 401) {
      checkRefreshToken();
    }
  };

  return {
    refetch: fetchDeviceSwitchDetails,
    deviceSwitchDetails,
    loading,
    error,
  };
};
