import { FC } from 'react';
import { IAction, IDepartment } from '../../../../../interfaces/interfaces';
import DndAction from './DndAction';
import { useStyles } from './styles';

type TProps = {
  showVisibleActions: boolean;
  department: IDepartment;
  onOpenActionPopover: (show: boolean) => void;
  onSetActions: (actions: IAction[]) => Promise<IDepartment>;
  allSounds: any[];
  modifySound: (action: IAction) => void;
  onUpdateActionKey: (updatedKey: string) => void;
  actionsKey: string;
  onDeleteAction: (actionId: string) => void;
};

const DndActions: FC<TProps> = props => {
  const {
    showVisibleActions,
    department,
    onOpenActionPopover,
    onSetActions,
    allSounds,
    modifySound,
    actionsKey,
    onDeleteAction,
  } = props;

  const classes = useStyles();

  const actionsList = department.actionsList ?? [];

  const moveCard = (dragIndex: any, hoverIndex: any, id: any) => {
    console.log('DndActionContainer', {
      moveCard: { dragIndex, hoverIndex, id },
    });

    let actionsToModify = department.actionsList;
    const dragCard = department.actionsList[dragIndex];

    const index = actionsToModify.indexOf(dragCard);

    // Remove and Place in new spot
    if (index > -1) {
      actionsToModify.splice(index, 1);
    }
    actionsToModify.splice(hoverIndex, 0, dragCard);

    // Loop through and re assign order ids
    actionsToModify.forEach((action, index) => {
      action.orderId = index;
    });

    onSetActions(actionsToModify);
  };

  const renderAction = (action: any, index: number) => {
    return (
      <DndAction
        key={`${action.id}-${new Date().getTime()}`}
        index={index}
        id={action.id}
        text={action.id}
        moveCard={moveCard}
        card={action}
        modifySound={(action: IAction) => modifySound(action)}
        department={department}
        allSounds={allSounds}
        deleteAction={() => onDeleteAction(action.id)}
        grayOut
      />
    );
  };

  return (
    <div className={classes.action} key={`${actionsKey}-actions`}>
      {showVisibleActions
        ? actionsList.map((action, i) =>
            action.isVisible ? renderAction(action, i) : null
          )
        : actionsList.map((action, i) =>
            !action.isVisible ? renderAction(action, i) : null
          )}

      <div
        onClick={() => {
          onOpenActionPopover(true);
        }}
        className={classes.addIconSquare}
      >
        <div className={classes.addIconStyle}>+</div>
      </div>
    </div>
  );
};

export default DndActions;
