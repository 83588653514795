// Polyfills, important for IE compatibility and ES5 transpiling
import "react-app-polyfill/ie11";
import "core-js/es6/map";
import "core-js/es6/set";
import "raf/polyfill";
import "babel-polyfill";

import { createRoot } from "react-dom/client";

// CSS
import "./css/index.css";

// import AppTest from "./AppTest"

import { LoginType, Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import React from "react";
import AppContainer from "./AppContainer";

const msalConfig = {
  auth: {
    clientId: "17da5be1-4c89-40b8-bcaa-f0548758605d",
    navigateToLoginRequestUrl: false,
  },
  // system: {
  //     allowRedirectInIframe: true,
  //     loadFrameTimeout: 10000,
  //     navigateFrameWait: 10000,
  //     asyncPopups: true,
  // },
  // cache: {
  //     cacheLocation: "localStorage"
  // },
};

Providers.globalProvider = new Msal2Provider({
  clientId: "17da5be1-4c89-40b8-bcaa-f0548758605d",
  scopes: ["api://17da5be1-4c89-40b8-bcaa-f0548758605d/sign-in"],
  loginType: LoginType.Popup,
  redirectUri: "/blank.html",
  options: msalConfig,
});

const container: HTMLElement | null = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(<AppContainer />);
} else {
  throw new Error("Root element not found");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// serviceWorker.unregister();
