import { FC, useEffect, useState } from 'react';

import UploadApk from '../UploadApk';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { API } from '../../../../apiconfig';

import { useApps } from '../../../../hooks/useApps';
import { IApp } from '../../../../interfaces/interfaces';
import { theme, useStyles } from './styles';

type TProps = {};

const Applications: FC<TProps> = () => {
  const [newApkDialog, setNewApkDialog] = useState(false);
  const [sortBy, setSortBy] = useState('isActive');
  const [sortDescending, setSortDescending] = useState(false);
  const [hideInactiveApps, setHideInactiveApps] = useState(true);

  const { apps = [], refetch } = useApps();
  const classes = useStyles();
  useEffect(() => {}, []);

  // componentDidMount() {
  //   this.props.fetchAllApps();
  // }

  const onOpenApkDialog = () => {
    setNewApkDialog(true);
  };

  const onCloseApkDialog = () => {
    setNewApkDialog(false);

    refetch();
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const onToggleApp = (app: IApp) => {
    let token = localStorage.getItem('token');

    let data = {
      ...app,
      isActive: !app.isActive,
    };

    let payload = JSON.stringify(data);
    // console.log("PAYLOAD", payload);

    fetch(API.REACT_APP_API_APK, {
      method: 'PUT',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(() => {
        refetch();
      })
      .catch(function (err) {
        console.log('Error: ', err);
      });
  };

  let filteredApps: IApp[] = [];

  if (apps) {
    let filter = sortBy;

    filteredApps = apps.sort((a: IApp, b: IApp) => {
      if (sortDescending) {
        // DESCENDING
        if (a[filter as keyof IApp] > b[filter as keyof IApp]) {
          return -1;
        }
        if (a[filter as keyof IApp] < b[filter as keyof IApp]) {
          return 1;
        }
        return 0;
      } else {
        // ASCENDING
        if (a[filter as keyof IApp] < b[filter as keyof IApp]) {
          return -1;
        }
        if (a[filter as keyof IApp] > b[filter as keyof IApp]) {
          return 1;
        }
        return 0;
      }
    });

    if (hideInactiveApps) {
      let activeApps: IApp[] = [];
      filteredApps.filter((app: IApp) => {
        if (app.isActive) {
          activeApps.push(app);
        }
        return null;
      });
      filteredApps = activeApps;
    }
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Typography variant="h4" gutterBottom component="h2">
          Applications
          <Tooltip title="New App" aria-label="Add" onClick={onOpenApkDialog}>
            <Fab className={classes.newOrganizationButton}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Typography>

        <Dialog open={newApkDialog} onClose={onCloseApkDialog}>
          <UploadApk closeDialog={onCloseApkDialog} />
        </Dialog>

        <div
          onClick={() => {
            setHideInactiveApps(!hideInactiveApps);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={hideInactiveApps}
          />
          Hide Inactive Apps
        </div>

        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('appName')}
                >
                  {sortBy === 'appName' ? (
                    <div>Name {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Name</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('appVersion')}
                >
                  {sortBy === 'appVersion' ? (
                    <div>Version {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Version</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('appUrl')}
                >
                  {sortBy === 'appUrl' ? (
                    <div>Url {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Url</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => onSortBy('isActive')}
                >
                  {sortBy === 'isActive' ? (
                    <div>Active {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Active</div>
                  )}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredApps.map((app: IApp, index: number) => (
                <TableRow
                  key={index}
                  style={app.isActive ? {} : { backgroundColor: '#ededed' }}
                >
                  <TableCell
                    className={classes.tableCell}
                    style={app.isActive ? {} : { color: 'gray' }}
                  >
                    {app.appName}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={app.isActive ? {} : { color: 'gray' }}
                  >
                    {app.appVersion}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <a
                      style={
                        app.isActive ? { color: 'black' } : { color: 'gray' }
                      }
                      href={app.appUrl}
                    >
                      {app.appUrl}
                    </a>
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    style={app.isActive ? {} : { color: 'gray' }}
                  >
                    {app.isActive ? (
                      <div>
                        <CheckIcon />
                      </div>
                    ) : null}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {app.isActive ? (
                      <Button
                        variant="contained"
                        onClick={() => onToggleApp(app)}
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button onClick={() => onToggleApp(app)}>Activate</Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </MuiThemeProvider>
    </div>
  );
};

export default Applications;
