import axios from "axios";
import { API } from "../../apiconfig";
import { IPermission } from "../../interfaces/interfaces";

export const fetchPermissions = async (): Promise<IPermission[]> => {
  const token = localStorage.getItem("token");
  const URL = API.REACT_APP_API_PERMISSIONS;
  const headers = { 
    Authorization: `Bearer ${token}`,
    Pragma: "no-cache"
  };

  try {
    const response = await axios.get(URL, { headers });
    if (response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (error) {
    console.error("Error fetching permissions:", error);
    throw error;
  }
};