import {
  FETCH_LOGGED_IN_USER,
  FETCH_APP_USERS_BY_ORG_ID,
  FETCH_ORGANIZATIONS,
  FETCH_CAMPUSES,
  FETCH_SITES,
  FETCH_ORGANIZATIONDEPARTMENTS,
  FETCH_ALLACTIONS,
  FETCH_DEPARTMENT,
  FETCH_SOUNDS,
  FETCH_ROOMS_BY_ORG_ID,
  FETCH_ALL_DEVICE_DETAILS,
  FETCH_ALL_DISCONNECTED_DEVICES,
  FETCH_DEVICE_DETAIL,
  UPDATE_DEVICE_DETAIL,
  FETCH_ALL_APPS,
  FETCH_REPORTING,
  UPDATE_SECURITYDEPTS,
  FETCH_DEVICE_LOCATIONS,
  FETCH_INSTRUMENTS_BY_ORG_ID,
  FETCH_SITE,
  FETCH_ORG_PROVIDERS,
  FETCH_DISTINCT_ORG_ACTIONS,
  ERROR_LOGGED_IN_USER,
  ERROR_LOGOUT_USER,
  FETCH_TRAINING_DOCUMENTATION,
  FETCH_PERMISSIONS,
  FETCH_PATIENT_CONTENT_BY_ORG_ID,
  FETCH_CLINIC_MAPS_BY_ORG_ID,
  SET_ORGANIZATION
} from '../actions/types';
import { IOrganization, IUser, TAdminState } from '../interfaces/interfaces';

const initialState: TAdminState = {
  loggedInUserData: {} as IUser,
  usersData: [],
  organizations: [],
  site: {
    siteName: '',
    isActive: true,
    timeZoneId: 9,
    id: '',
    isLocked: false,
    goLiveDate: '',
    campusId: '',
    mongoOrgId: '',
  },
  sites: [],
  campuses: [],
  organizationDepartments: [],
  department: {},
  allActions: [],
  allSounds: [],
  rooms: [],
  deviceList: [],
  actionTypes: [],
  activeApps: [],
  allApps: [],
  deviceDetail: {},
  reporting: {},
  securityDepts: [],
  deviceLocations: [],
  instruments: [],
  orgProviders: [],
  distinctOrgActions: [],
  errorLoggedInUser: false,
  errorLogoutUser: false,
  trainingDocumentation: {},
  organization: {} as IOrganization,
  permissions: {},
  patientContent: [],
  clinicMaps: [],
};

export default function adminReducer(
  state = initialState,
  action: { type: string; payload: any }
): TAdminState {
  switch (action.type) {
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case FETCH_LOGGED_IN_USER:
      return {
        ...state,
        loggedInUserData: action.payload.user,
        organization: action.payload.org,
        errorLoggedInUser: false,
      };
    case FETCH_APP_USERS_BY_ORG_ID:
      return {
        ...state,
        usersData: [...action.payload]
          .slice()
          .sort((a: any, b: any) => a.fullName.localeCompare(b.fullName)),
      };
    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        organizations: [...action.payload],
      };
    case FETCH_SITES:
      return {
        ...state,
        sites: [...action.payload],
      };
    case FETCH_CAMPUSES:
      return {
        ...state,
        campuses: [...action.payload], // Copy the array
      };
    case FETCH_ORGANIZATIONDEPARTMENTS:
      return {
        ...state,
        organizationDepartments: [...action.payload], // Copy the array
      };
    case FETCH_DEPARTMENT:
      // If action.payload is an object, you need a deep copy:
      return {
        ...state,
        department: { ...action.payload }, // Deep copy the object (if applicable)
      };
    case FETCH_ALLACTIONS:
      return {
        ...state,
        allActions: [...action.payload], // Copy the array
      };
    case FETCH_SOUNDS:
      return {
        ...state,
        allSounds: [...action.payload], // Copy the array
      };
    case FETCH_ROOMS_BY_ORG_ID:
      return {
        ...state,
        rooms: action.payload,
      };
    case FETCH_ALL_DEVICE_DETAILS:
    case FETCH_ALL_DISCONNECTED_DEVICES:
      return {
        ...state,
        deviceList: [...action.payload.devices],
        actionTypes: [...action.payload.actionTypes],
        activeApps: [...action.payload.apps],
      };
    case FETCH_DEVICE_DETAIL:
    case UPDATE_DEVICE_DETAIL:
      return {
        ...state,
        deviceDetail: { ...action.payload },
      };
    case FETCH_ALL_APPS:
      return {
        ...state,
        allApps: [...action.payload],
      };
    case FETCH_REPORTING:
    case UPDATE_SECURITYDEPTS:
      return {
        ...state,
        securityDepts: [...action.payload],
      };
    case FETCH_DEVICE_LOCATIONS:
      return {
        ...state,
        deviceLocations: [...action.payload],
      };
    case FETCH_INSTRUMENTS_BY_ORG_ID:
      return {
        ...state,
        instruments: [...action.payload],
      };
    case FETCH_SITE:
      return {
        ...state,
        site: { ...action.payload },
      };
    case FETCH_ORG_PROVIDERS:
      return {
        ...state,
        orgProviders: [...action.payload],
      };
    case FETCH_DISTINCT_ORG_ACTIONS:
      return {
        ...state,
        distinctOrgActions: [...action.payload],
      };
    case ERROR_LOGGED_IN_USER:
      return {
        ...state,
        errorLoggedInUser: true,
      };
    case ERROR_LOGOUT_USER:
      return {
        ...state,
        errorLoggedInUser: true,
        errorLogoutUser: true,
      };
    case FETCH_TRAINING_DOCUMENTATION:
      return {
        ...state,
        trainingDocumentation: { ...action.payload },
      };
    case FETCH_PERMISSIONS:
      return {
        ...state,
        permissions: { ...action.payload },
      };
    case FETCH_PATIENT_CONTENT_BY_ORG_ID:
      return {
        ...state,
        patientContent: [...action.payload],
      };
    case FETCH_CLINIC_MAPS_BY_ORG_ID:
      return {
        ...state,
        clinicMaps: [...action.payload],
      };
    default:
      return state;
  }
}
