import axios from 'axios';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { API } from '../apiconfig';
import { IAction, IApp } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useDeviceAction = (): {
  isLoading: boolean;
  error: string | null;
  deviceActionSend: (input: {
    deviceIds: string[];
    selectedAction: IAction;
    userId: string;
    selectedApp?: IApp;
    onSuccess: () => void;
    onFail: (error: any) => void;
  }) => Promise<void>;
  deleteDeviceAction: (input: {
    deviceId: string;
    actionQueueId: string;
    onSuccess: () => void;
    onFail: (error: any) => void;
  }) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const deviceActionSend = useCallback(
    (input: {
      deviceIds: string[];
      selectedAction: IAction;
      userId: string;
      selectedApp?: IApp;
      onSuccess: () => void;
      onFail: (error: any) => void;
    }): Promise<void> => {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        setError(null);

        const {
          deviceIds,
          selectedAction,
          selectedApp,
          userId,
          onSuccess,
          onFail,
        } = input;

        let token = localStorage.getItem('token');

        // let deviceIds: { deviceId: string }[] = [];

        // devices.map((device: IDevice) => {
        //   deviceIds.push({ deviceId: device.deviceId });
        //   return null;
        // });

        const examRooms = deviceIds.map(deviceId => {
          return { deviceId };
        });

        console.log('deviceActionSend', { deviceIds });
        if (isEmpty(deviceIds)) return reject('No devices selected');

        let action = {
          examRooms,
          actionTypeId: selectedAction.actionTypeId,
          appId: selectedApp?.appVersion,
          userId,
        };

        console.log('deviceActionSend', { action });

        fetch(API.REACT_APP_API_ADDDEVICEACTION, {
          method: 'POST',
          body: JSON.stringify(action),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(() => {
            setIsLoading(false);
            onSuccess();
            resolve();
          })
          .catch(err => {
            handleError(err);
            setIsLoading(false);
            if (err.response?.status === 401) {
              checkRefreshToken();
            } else {
              console.log('Failed to send action to devices', err);
              onFail(err);
              reject(err);
            }
          });
      });
    },
    []
  );

  const deleteDeviceAction = useCallback(
    (input: {
      deviceId: string;
      actionQueueId: string;
      onSuccess: () => void;
      onFail: (error: any) => void;
    }): Promise<void> => {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        setError(null);

        const { deviceId, actionQueueId, onSuccess, onFail } = input;
        let token = localStorage.getItem('token');

        const payload = {
          deviceId,
          actionQueueId,
        };

        fetch(API.REACT_APP_API_REMOVEDEVICEACTION, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(res => res.json())
          .then(() => {
            setIsLoading(false);
            onSuccess();
            resolve();
          })
          .catch(err => {
            handleError(err);
            setIsLoading(false);
            if (err.response?.status === 401) {
              checkRefreshToken();
            } else {
              console.log('Error removing action', err);
              onFail(err);
              reject(err);
            }
          });
      });
    },
    []
  );

  const handleError = (err: any) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      console.log('UNAUTHORIZED, KICK EM OUT');
      setError('Unauthorized. Please log in again.');
      checkRefreshToken();
    } else {
      console.error('Error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return {
    deviceActionSend,
    deleteDeviceAction,
    isLoading,
    error,
  };
};
