import React, { Component } from 'react';
import { API } from '../../apiconfig';
import { getQueryVariable } from '../../utils/utils';
import axios from 'axios';
import InfoSnack from '../../utils/InfoSnack';

// http://localhost:3000/patientcontent?patientId=12345&surveyId=639b88a9d6411c3f9f5485d6&language=ENG

// Material
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import queryString from 'query-string';
import happy from '../../images/happy.png';
import sad from '../../images/sad.png';
import neutral from '../../images/neutral.png';

import { QRCodeSVG } from 'qrcode.react';

var uniqid: any = require('uniqid');

interface PatientContentProps {}
interface PatientContentState {
  patientContentData: any;
  questionIndex: number;
  responseTextInput: string;
  surveyCompleted: boolean;
  preSelectResponse: any;
  preSelectQuestionId: number | null;
  timer: any;
  tickInterval: number;
  showUrl: boolean;
}

class PatientContent extends Component<
  PatientContentProps,
  PatientContentState
> {
  constructor(props: PatientContentProps) {
    super(props);
    this.state = {
      patientContentData: null,
      questionIndex: 0,
      responseTextInput: '',
      surveyCompleted: false,
      preSelectResponse: null,
      preSelectQuestionId: null,
      timer: null,
      tickInterval: 0,
      showUrl: false,
    };
  }

  componentDidMount() {
    // const patientId = getQueryVariable("patientId");
    // console.log("patientId", patientId);
    let patientId: any = getQueryVariable('patientId');
    if (!patientId) {
      let storedPatientId = localStorage.getItem('generatedPatientId');
      if (!storedPatientId) {
        let generatedPatientId = uniqid();
        console.log('GENERATE IT', generatedPatientId);
        patientId = generatedPatientId;
        localStorage.setItem('generatedPatientId', generatedPatientId);
      }
    }

    const surveyId = getQueryVariable('surveyId');
    console.log('surveyId', surveyId);

    if (surveyId) {
      axios
        .get(API.REACT_APP_API_SURVEY + surveyId)
        .then(res => {
          console.log('Res', res);
          if (res.data.data) {
            // filter out free text questions if excludeFreeTextQuestions param is set to true
            let excludeFreeTextQuestions = getQueryVariable(
              'excludeFreeTextQuestions'
            );
            if (excludeFreeTextQuestions) {
              let filteredQuestions = res.data.data.surveyQuestions.filter(
                (question: { questionResponseType: string }) => {
                  return question.questionResponseType !== 'Text';
                }
              );
              res.data.data.surveyQuestions = filteredQuestions;
            }
            this.setState({
              patientContentData: res.data.data,
            });
          }
        })
        .catch(err => {
          console.log('Err', err);
        });
    }

    if (getQueryVariable('isSurveyKiosk') === 'true') {
      //@ts-ignore
      this.timer = setInterval(() => {
        // console.log("TICK", this.state.tickInterval)
        if (this.state.tickInterval === 90) {
          // reset survey
          this.setState({
            questionIndex: 0,
            tickInterval: 0,
            responseTextInput: '',
            surveyCompleted: false,
            preSelectResponse: null,
            preSelectQuestionId: null,
          });
        }

        this.setState({
          tickInterval: this.state.tickInterval + 1,
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    //@ts-ignore
    clearInterval(this.timer);
  }

  selectOption = (option: string, questionId: number | null) => {
    let patientId: any = getQueryVariable('patientId');
    if (!patientId) {
      let storedPatientId = localStorage.getItem('generatedPatientId');
      if (!storedPatientId) {
        let generatedPatientId = uniqid();
        console.log('GENERATE UNIQ ID', generatedPatientId);
        patientId = generatedPatientId;
        localStorage.setItem('generatedPatientId', generatedPatientId);
      }
      patientId = storedPatientId;
    }

    // console.log("PatientID", patientId);
    // console.log("Selected", option);
    // console.log("For", questionId);

    // let currentQuestion = this.state.patientContentData?.surveyQuestions[this.state.questionIndex];

    let updatedPatientContentData = {
      ...this.state.patientContentData,
    };

    const parsed = queryString.parse(window.location.search);
    console.log('parsed query params', parsed);

    updatedPatientContentData.surveyQuestions[
      this.state.questionIndex
    ].languageQuestions.forEach((question: any) => {
      if (question.id === questionId) {
        // console.log("update this", question);
        question.questionResponse = option;
        question.patientVisitId = patientId;

        // console.log("Answered!", question);

        let builtPayload = {
          // id: this.state.patientContentData.id,
          patientVisitId: patientId,
          surveyQuestionId: question.id,
          surveyQuestionName: question.questionText,
          surveyQuestionResponse: option,
          surveyQuestionResponseLanguage: question.questionLanguage,
          orgId: this.state.patientContentData?.mongoOrgId,
          surveyResponseCustomData: parsed ? { ...parsed } : {},
          surveyResponseTag: parsed.tag ? parsed.tag : null,
        };

        console.log('PAYLOAD', builtPayload);

        let payload = JSON.stringify(builtPayload);
        let URL = API.REACT_APP_API_SUBMITSURVEYRESPONSE;

        fetch(URL, {
          method: 'POST',
          body: payload,
          headers: {
            'Content-Type': 'application/json',
            // Authorization: "Bearer " + token,
          },
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            console.log('RESPONSE', data);
          })
          .catch(err => {
            console.log('Err submitting survey', err);
          });
      }
    });

    this.setState(
      {
        patientContentData: updatedPatientContentData,
        questionIndex: this.state.questionIndex + 1,
        responseTextInput: '',
        preSelectResponse: null,
        preSelectQuestionId: null,
      },
      () => {
        if (
          this.state.questionIndex ===
          this.state.patientContentData.surveyQuestions.length
        ) {
          console.log('Done');
          this.setState({
            surveyCompleted: true,
          });

          if (getQueryVariable('isSurveyKiosk') === 'true') {
            setTimeout(() => {
              this.setState({
                questionIndex: 0,
                responseTextInput: '',
                surveyCompleted: false,
                preSelectResponse: null,
                preSelectQuestionId: null,
              });
            }, 30000);
          }
        }
      }
    );
  };

  userInteracted = () => {
    console.log('user interacted');
    this.setState({
      tickInterval: 0,
    });
  };

  restartSurvey = () => {
    this.setState({
      tickInterval: 0,
      questionIndex: 0,
      responseTextInput: '',
      surveyCompleted: false,
      preSelectResponse: null,
      preSelectQuestionId: null,
    });
  };

  toastUrl = () => {
    // set showUrl to true then to false after 5 seconds
    this.setState({
      showUrl: true,
    });
    setTimeout(() => this.setState({ showUrl: false }), 10000);
  };

  render() {
    console.log('tick');
    let currentQuestion =
      this.state.patientContentData?.surveyQuestions[this.state.questionIndex];

    let language = getQueryVariable('language');
    if (!language) {
      language = 'ENG';
    }
    let hideQrCode: any = getQueryVariable('hideQrCode');
    let onlyQrCode: any = getQueryVariable('onlyQrCode');

    // console.log("question index", this.state.questionIndex);
    if (this.state.questionIndex !== 0) {
      hideQrCode = true;
    }

    let qrCodeUrl = window.location.href + '&hideQrCode';

    let updatedQrCodeUrl = qrCodeUrl.replace('&onlyQrCode', '');
    updatedQrCodeUrl = updatedQrCodeUrl.replace(
      '&excludeFreeTextQuestions',
      ''
    );
    // console.log("Constructed QR Code", updatedQrCodeUrl);
    // ADD HIDE QR CODE

    let englishQrCodeDescription =
      "If you'd like to take this survey on your phone, please scan the QR code.";
    let spanishQrCodeDescription =
      'Háganos saber cómo lo estamos haciendo escaneando el código QR de arriba.';
    let yiddishQrCodeDescription =
      'אויב ווילט איר נעמען די סערוועי, דאן ביטע סקענט די QR קאוד';

    this.state.patientContentData?.qrCodeDescriptions?.forEach(
      (qrCodeDescription: any) => {
        if (
          qrCodeDescription.descriptionLanguage === 'ENG' &&
          qrCodeDescription.descriptionText
        ) {
          englishQrCodeDescription = qrCodeDescription.descriptionText;
        } else if (
          qrCodeDescription.descriptionLanguage === 'SPA' &&
          qrCodeDescription.descriptionText
        ) {
          spanishQrCodeDescription = qrCodeDescription.descriptionText;
        } else if (
          qrCodeDescription.descriptionLanguage === 'YID' &&
          qrCodeDescription.descriptionText
        ) {
          yiddishQrCodeDescription = qrCodeDescription.descriptionText;
        }
      }
    );

    return (
      <div onClick={this.userInteracted}>
        <div data-private>
          {this.state.patientContentData ? (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <img
                src={this.state.patientContentData.orgImageUrl}
                alt=""
                style={{ maxHeight: '100px', margin: 10 }}
              />
              <div style={{ margin: 10, color: '#808080' }}>
                <div onClick={this.toastUrl} style={{ cursor: 'pointer' }}>
                  {this.state.patientContentData.patientContentName}
                </div>
              </div>
            </div>
          ) : null}

          {!onlyQrCode ? (
            <div>
              {!this.state.surveyCompleted && this.state.patientContentData ? (
                <div
                  style={{
                    textAlign: 'center',
                    color: 'gray',
                    position: 'fixed',
                    width: '100%',
                    bottom: 0,
                    fontSize: 18,
                    zIndex: 10,
                  }}
                >
                  {this.state.patientContentData.surveyQuestions.length > 0 ? (
                    <div>
                      {getQueryVariable('isSurveyKiosk') === 'true' ? (
                        <Button
                          onClick={this.restartSurvey}
                          color="primary"
                          variant="contained"
                          style={{
                            marginBottom: 10,
                            cursor: 'pointer',
                            zIndex: 1000,
                          }}
                        >
                          Restart Survey
                        </Button>
                      ) : null}
                      <br />
                      {this.state.questionIndex + 1} /{' '}
                      {this.state.patientContentData.surveyQuestions.length}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}

          <Container>
            <div style={{ position: 'relative', height: '100vh' }}>
              {this.state.patientContentData ? (
                <div
                  style={
                    hideQrCode
                      ? { position: 'absolute', top: '40%', width: '100%' }
                      : { position: 'absolute', top: '15%', width: '100%' }
                  }
                >
                  {this.state.patientContentData.patientContentType ===
                  'Survey' ? (
                    <div>
                      {onlyQrCode ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          <QRCodeSVG
                            fgColor="#FFFFFF"
                            bgColor="#808080"
                            size={225}
                            value={updatedQrCodeUrl}
                          />
                          <div style={{ fontSize: 35, marginTop: 20 }}>
                            {language === 'ENG' && (
                              <div>{englishQrCodeDescription}</div>
                            )}
                            {language === 'SPA' && (
                              <div>{spanishQrCodeDescription}</div>
                            )}
                            {language === 'YID' && (
                              <div style={{ direction: 'rtl' }}>
                                {yiddishQrCodeDescription}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {!hideQrCode && (
                            <div
                              style={{
                                textAlign: 'center',
                                marginBottom: 45,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <QRCodeSVG
                                fgColor="#FFFFFF"
                                bgColor="#808080"
                                size={250}
                                value={updatedQrCodeUrl}
                              />
                              <div
                                style={{
                                  fontSize: 18,
                                  color: 'gray',
                                  marginTop: 10,
                                }}
                              >
                                {language === 'ENG' && (
                                  <div>{englishQrCodeDescription}</div>
                                )}
                                {language === 'SPA' && (
                                  <div>{spanishQrCodeDescription}</div>
                                )}
                                {language === 'YID' && (
                                  <div style={{ direction: 'rtl' }}>
                                    {yiddishQrCodeDescription}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {currentQuestion?.languageQuestions.map(
                            (question: any, index: number) => {
                              // return <div key={index}>{question.questionText}</div>
                              if (
                                question.questionLanguage === language ||
                                currentQuestion.languageQuestions.length === 1
                              ) {
                                return (
                                  <div key={index}>
                                    <div
                                      style={
                                        question.questionLanguage === 'YID'
                                          ? {
                                              direction: 'rtl',
                                              textAlign: 'center',
                                              fontSize: 35,
                                              marginBottom: 25,
                                            }
                                          : {
                                              textAlign: 'center',
                                              fontSize: 35,
                                              marginBottom: 25,
                                            }
                                      }
                                    >
                                      {question.questionText}
                                    </div>

                                    {/* {console.log("Current question", currentQuestion)} */}

                                    {/* Multiple Choice */}
                                    {currentQuestion.questionResponseType ===
                                    'Multiple Choice' ? (
                                      <div
                                        style={{
                                          fontSize: 35,
                                          textAlign: 'center',
                                        }}
                                      >
                                        {question.responseOptions.map(
                                          (
                                            option: any,
                                            optionIndex: number
                                          ) => (
                                            <Button
                                              key={optionIndex}
                                              style={{
                                                margin: 5,
                                                fontSize: 24,
                                              }}
                                              variant="contained"
                                              color={
                                                this.state.preSelectResponse ===
                                                option
                                                  ? 'primary'
                                                  : 'default'
                                              }
                                              // onClick={() => this.selectOption(option, question.id)}
                                              onClick={() =>
                                                this.setState({
                                                  preSelectResponse: option,
                                                  preSelectQuestionId:
                                                    question.id,
                                                })
                                              }
                                            >
                                              {option}
                                            </Button>
                                          )
                                        )}
                                      </div>
                                    ) : null}

                                    {/* Text Response */}
                                    {currentQuestion.questionResponseType ===
                                    'Text' ? (
                                      <div style={{ textAlign: 'center' }}>
                                        <TextField
                                          inputProps={{
                                            onKeyDown: e => {
                                              // Blur the input when the user presses enter
                                              if (e.key === 'Enter') {
                                                // @ts-ignore
                                                document.activeElement?.blur();
                                              }
                                            },
                                          }}
                                          // multiline
                                          variant="outlined"
                                          style={{
                                            width: '75%',
                                            marginBottom: 10,
                                          }}
                                          value={this.state.responseTextInput}
                                          onChange={e =>
                                            this.setState({
                                              responseTextInput: e.target.value,
                                            })
                                          }
                                        />
                                        <br />
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() =>
                                            this.selectOption(
                                              this.state.responseTextInput,
                                              question.id
                                            )
                                          }
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    ) : null}

                                    {/* Survey response (smiley faces) */}
                                    {currentQuestion.questionResponseType ===
                                    'Smiley Survey' ? (
                                      <div>
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                            flex: 'flex-wrap',
                                            justifyContent: 'center',
                                            fontSize: 65,
                                          }}
                                        >
                                          <div
                                            style={
                                              this.state.preSelectResponse ===
                                              'Happy'
                                                ? {
                                                    cursor: 'pointer',
                                                    marginRight: 10,
                                                    outline:
                                                      '2px solid #3F52B5',
                                                  }
                                                : {
                                                    cursor: 'pointer',
                                                    marginRight: 10,
                                                  }
                                            }
                                            // onClick={() => this.selectOption("Happy", question.id)}
                                            onClick={() =>
                                              this.setState({
                                                preSelectResponse: 'Happy',
                                                preSelectQuestionId:
                                                  question.id,
                                              })
                                            }
                                          >
                                            <img
                                              src={happy}
                                              style={{
                                                width: 100,
                                                height: 100,
                                              }}
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style={
                                              this.state.preSelectResponse ===
                                              'Neutral'
                                                ? {
                                                    cursor: 'pointer',
                                                    outline:
                                                      '2px solid #3F52B5',
                                                  }
                                                : { cursor: 'pointer' }
                                            }
                                            // onClick={() => this.selectOption("Neutral", question.id)}
                                            onClick={() =>
                                              this.setState({
                                                preSelectResponse: 'Neutral',
                                                preSelectQuestionId:
                                                  question.id,
                                              })
                                            }
                                          >
                                            <img
                                              src={neutral}
                                              style={{
                                                width: 100,
                                                height: 100,
                                              }}
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style={
                                              this.state.preSelectResponse ===
                                              'Sad'
                                                ? {
                                                    cursor: 'pointer',
                                                    marginLeft: 10,
                                                    outline:
                                                      '2px solid #3F52B5',
                                                  }
                                                : {
                                                    cursor: 'pointer',
                                                    marginLeft: 10,
                                                  }
                                            }
                                            // onClick={() => this.selectOption("Sad", question.id)}
                                            onClick={() =>
                                              this.setState({
                                                preSelectResponse: 'Sad',
                                                preSelectQuestionId:
                                                  question.id,
                                              })
                                            }
                                          >
                                            <img
                                              src={sad}
                                              style={{
                                                width: 100,
                                                height: 100,
                                              }}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {this.state.preSelectResponse &&
                                    this.state.preSelectQuestionId ? (
                                      <div
                                        style={{
                                          textAlign: 'center',
                                          marginTop: 25,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() =>
                                            this.selectOption(
                                              this.state.preSelectResponse,
                                              this.state.preSelectQuestionId
                                            )
                                          }
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                        </div>
                      )}

                      {this.state.surveyCompleted ? (
                        <div style={{ fontSize: 35, textAlign: 'center' }}>
                          <div>
                            {language === 'ENG' ? (
                              <div>Thank you for taking our survey!</div>
                            ) : null}
                          </div>
                          <div>
                            {language === 'SPA' ? (
                              <div>Gracias por tomar nuestra encuesta!</div>
                            ) : null}
                          </div>
                          <div>
                            {language === 'YID' ? (
                              <div style={{ direction: 'rtl' }}>
                                א דאנק פארן ענטפערן אונזערע פראגעס
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {/* {this.state.patientContentData.patientContentType === "Education" ? (
              <div>
                Education Content
                <iframe
                  style={{ height: "100%", width: "100%" }}
                  src={this.state.patientContentData.patientEducationUrl}
                  title="description"
                ></iframe>
              </div>
            ) : null} */}
                </div>
              ) : null}
            </div>

            {this.state.showUrl ? (
              <InfoSnack
                successSnack={true}
                successMessage={window.location.href}
              />
            ) : (
              <div />
            )}
          </Container>
        </div>
      </div>
    );
  }
}

export default PatientContent;
