import { inflate } from "pako";
import { Buffer } from "buffer";

export const getObjectFromGzipEncodedData = (updateDataEncoded: string) => {
  try {
    const binData = Buffer.from(updateDataEncoded, "base64");

    const data = inflate(binData);
    const updateDataUnencoded = String.fromCharCode.apply(null, Array.from(new Uint16Array(data)));

    const updateData = JSON.parse(updateDataUnencoded);

    return updateData;
  } catch (error) {
    console.log("getObjectFromGzipEncodedData", error);
  }
};
