import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { canDo } from '../../../../utils/permissionCheck';

// Material
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import SuccessSnack from '../../../../utils/snack/SuccessSnack';

import axios from 'axios';
import { API } from '../../../../apiconfig';
import { TAdminState } from '../../../../interfaces/interfaces';
import { getQueryVariable } from '../../../../utils/utils';

import { useIntegrations } from '../../../../hooks/useIntegrations';
import { checkRefreshToken } from '../../../../utils/utils';
import { theme, useStyles } from './styles';

type TProps = {};

const Integrations: FC<TProps> = () => {
  const [showMicrosoftRedirectMessage, setShowMicrosoftRedirectMessage] =
    useState(false);
  const [showDeleteIntegrationMessage, setShowDeleteIntegrationMessage] =
    useState(false);
  const [integrationToDelete, setIntegrationToDelete] = useState(null);
  const [integrationHasBeenCreated, setIntegrationHasBeenCreated] =
    useState(false);
  const [buttonLink, setButtonLink] = useState('');

  const classes = useStyles();

  const { loggedInUserData, organization } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );


  const organizationId = loggedInUserData?.mongoOrganizationId

  const { integrations, refetch: refetchIntegrations } = useIntegrations(
    organizationId
  );

  useEffect(() => {
    if (
      getQueryVariable('tenant') &&
      getQueryVariable('admin_consent') === 'True'
    ) {
      // Detected Microsoft Teams callback

      // console.log("TENANT", getQueryVariable("tenant"))
      let tenantId = getQueryVariable('tenant');
      // console.log("props", this.props)

      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };
      // fetch initial integrations
      let getIntegrationsUrl =
        API.REACT_APP_API_INTEGRATION + '/orgId=' + organizationId;

      let newTntegrationName: any = '';
      if (localStorage.getItem('MicrosoftTeamsIntegration')) {
        newTntegrationName = localStorage.getItem('MicrosoftTeamsIntegration');
      }

      axios.get(getIntegrationsUrl, headers).then(response => {
        console.log('Org integrations', response.data.data);
        let organizationIntegrations = response.data.data;
        if (organizationIntegrations) {
          let foundExistingIntegration = false;
          organizationIntegrations.forEach((integration: any) => {
            if (integration.integrationName === newTntegrationName) {
              if (
                integration.customIntegrationData.microsoftTenantId === tenantId
              ) {
                foundExistingIntegration = true;
              }
            }
          });
          console.log('Found?', foundExistingIntegration);

          if (foundExistingIntegration === false) {
            // Create new integration

            if (newTntegrationName) {
              let newIntegrationPayload = {
                integrationName: newTntegrationName,
                orgId: organization.id,
                customIntegrationData: {
                  microsoftTenantId: tenantId,
                },
              };

              console.log('create integration!', newIntegrationPayload);

              fetch(API.REACT_APP_API_INTEGRATION, {
                method: 'POST',
                body: JSON.stringify(newIntegrationPayload),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + token,
                },
              }).then(response => {
                console.log('response creating integration', response);
                refetchIntegrations();

                var newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?integrations';
                window.history.pushState({ path: newurl }, '', newurl);

                localStorage.removeItem('MicrosoftTeamsIntegration');

                setIntegrationHasBeenCreated(true);
                setTimeout(() => setIntegrationHasBeenCreated(false), 5000);
              });
            }
          }
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  const onHandleIntegrationButton = (integration: any) => {
    if (integration.integrationName === 'Microsoft Teams') {
      localStorage.setItem(
        'MicrosoftTeamsIntegration',
        integration.integrationName
      );
      setShowMicrosoftRedirectMessage(true);
      setButtonLink(integration.buttonLink);
    } else if (integration.integrationName === 'Microsoft SSO') {
      localStorage.setItem(
        'MicrosoftTeamsIntegration',
        integration.integrationName
      );

      setShowMicrosoftRedirectMessage(true);
      setButtonLink(integration.buttonLink);
    }
  };

  const onDeleteIntegration = (integration: any) => {
    console.log('delete', integration);
    if (integration) {
      let token = localStorage.getItem('token');

      let payload = {
        ...integration,
      };

      // let tempUrl = "https://h1hkg7ph-4000.usw2.devtunnels.ms/api/v3/integration"
      axios
        .delete(API.REACT_APP_API_INTEGRATION, {
          headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
          data: payload,
        })
        .then(response => {
          console.log('Delete response', response);
          refetchIntegrations();

          setShowDeleteIntegrationMessage(false);
          setIntegrationToDelete(null);
        })
        .catch(err => {
          console.log('err deleting integration', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  let host = window.location.origin + '/admin?integrations';
  // console.log("HOST", host)
  let urlEncodedRedirectUrl = encodeURIComponent(host);
  // console.log("URL ENCODED", urlEncodedRedirectUrl)

  let hardCodedIntegrations = [
    {
      integrationName: 'Microsoft Teams',
      description:
        'Receive realtime messages from SyncTimes through Microsoft Teams by messaging individuals and creating group chats for those tied to the notification',
      buttonLink:
        'https://login.microsoftonline.com/common/adminconsent?client_id=55885e24-4444-4fc0-9a33-3d8679b54bca&redirect_uri=' +
        urlEncodedRedirectUrl,
    },
    {
      integrationName: 'Microsoft SSO',
      description:
        'Allow users to sign in to SyncTimes using their Microsoft account',
      buttonLink:
        'https://login.microsoftonline.com/common/adminconsent?client_id=e22309fc-12d8-4f77-9668-619ea53a0909&redirect_uri=' +
        urlEncodedRedirectUrl,
    },
  ];
  // filtet out hardCodeIntegration if it's already in organizationIntegrations
  let filteredIntegrations = hardCodedIntegrations.filter(
    (hardCodedIntegration: any) => {
      let found = false;

      integrations.forEach((organizationIntegration: any) => {
        if (
          organizationIntegration.integrationName ===
          hardCodedIntegration.integrationName
        ) {
          found = true;
        }
      });
      return !found;
    }
  );

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Modal
          open={showMicrosoftRedirectMessage}
          onClose={() => setShowMicrosoftRedirectMessage(false)}
        >
          <div className={classes.modalStyle} style={{ textAlign: 'center' }}>
            A Microsoft administrator must perform this step. You will be
            redirected to log in to your Microsoft account and grant SyncTimes
            access to certain information in your account, including the ability
            to install the SyncTimes Teams Bot for users and chats, as well as
            to read which users are in your account. Press OK to continue.
            <div style={{ textAlign: 'center' }}>
              <Link
                // href={"https://login.microsoftonline.com/common/adminconsent?client_id=55885e24-4444-4fc0-9a33-3d8679b54bca&redirect_uri=" + urlEncodedRedirectUrl}
                href={buttonLink}
              >
                <Button
                  style={{ marginTop: 15 }}
                  color="primary"
                  variant="contained"
                >
                  Ok
                </Button>
              </Link>
            </div>
          </div>
        </Modal>

        <Modal
          open={showDeleteIntegrationMessage}
          onClose={() => {
            setShowDeleteIntegrationMessage(false);
            setIntegrationToDelete(null);
          }}
        >
          <div className={classes.modalStyle} style={{ textAlign: 'center' }}>
            Are you sure you want to remove{' '}
            {integrationToDelete?.integrationName}?
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                onClick={() => {
                  setShowDeleteIntegrationMessage(false);
                  setIntegrationToDelete(null);
                }}
                style={{ marginTop: 15 }}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onDeleteIntegration(integrationToDelete);
                }}
                style={{ marginTop: 15 }}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>

        <Typography variant="h4" gutterBottom component="h2">
          Available Integrations
        </Typography>

        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell>Integrations</TableCell>
                <TableCell>Description</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredIntegrations.map((integration: any, i: number) => (
                <TableRow key={i}>
                  <TableCell>{integration.integrationName}</TableCell>
                  <TableCell>{integration.description}</TableCell>
                  <TableCell align="right">
                    {canDo(['Update Integrations'], loggedInUserData) ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          onHandleIntegrationButton(integration);
                        }}
                      >
                        Add Integration
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <br />
        <Typography variant="h4" gutterBottom component="h2">
          {organization.orgName} Installed Integrations
        </Typography>

        {/* Installed integrations */}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell>Integration</TableCell>
                <TableCell>{/* Description */}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {integrations.map((integration: any, i: number) => (
                <TableRow key={i}>
                  <TableCell>{integration.integrationName}</TableCell>
                  <TableCell>
                    {/* {JSON.stringify(integration.customIntegrationData, null, 2)} */}
                    <div style={{ color: 'gray' }}>
                      {JSON.stringify(
                        integration.customIntegrationData,
                        null,
                        '\t'
                      )
                        .replace(/[{}]/g, '')
                        .replace(/['"]+/g, '')}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {canDo(['Update Integrations'], loggedInUserData) ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setShowDeleteIntegrationMessage(true);
                          setIntegrationToDelete(integration);
                        }}
                        // onClick={() => this.deleteIntegration(integration)}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {integrationHasBeenCreated ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Integration has been successfully created!"
          />
        ) : null}
      </MuiThemeProvider>
    </div>
  );
};

export default Integrations;
