import { z } from 'zod';

export const schema = z.object({
  isActive: z.boolean().optional().nullish(),
  deptName: z.string().min(3),
  mongoSiteId: z.string(),
  ehrDeptId: z.string().optional().nullish(),
  inheritSettingsFromDeptId: z.string().optional().nullish(),
  secondsInRoomTimeout: z.number().optional().nullish(),
  isFrontDeskIntegration: z.boolean().optional().nullish(),
  showNurseCallButton: z.boolean().optional().nullish(),
  isTotalTimeFromCheckIn: z.boolean().optional().nullish(),
  clearRoomAssignmentsOnPatientCheckOut: z.boolean().optional().nullish(),
  assignPatientProviderOnPatientRoom: z.boolean().optional().nullish(),
  deptNotes: z.string().optional().nullish(),
  selectedPatientContentId: z.string().optional().nullish(),
  ehrDeptName: z.string().optional().nullish(),
  actionsList: z
    .array(
      z.object({
        id: z.string(),
        actionName: z.string(),
        actionDescription: z.string().nullish(),
        actionImageUrl: z.string().nullable().nullish(),
        addForFinishedVisit: z.boolean().nullish(),
        addForNewVisit: z.boolean().nullish(),
        availableChecklistItems: z.array(z.unknown()).nullish(),
        canAddWithoutPatient: z.boolean().nullish(),
        isActive: z.boolean().nullish(),
        isDisabled: z.boolean().nullish(),
        isVisible: z.boolean().nullish(),
        lockTabletsInCampus: z.boolean().nullish(),
        mongoSoundId: z.string().nullable().nullish(),
        notifyAllTablets: z.boolean().nullish(),
        notifyInRooms: z.boolean().nullish(),
        playSoundOnFlowstation: z.boolean().nullish(),
        playSoundOnInside: z.boolean().nullish(),
        playSoundOnOutside: z.boolean().nullish(),
        soundReplaySeconds: z.number().nullish(),
        soundUrl: z.string().nullable().nullish(),
        verifyAction: z.boolean().nullish(),
        verifyHoldSeconds: z.number().nullish(),
      })
    )
    .optional()
    .nullish(),
  ehrDeptIds: z.array(z.string()).optional().nullish(),
  patientEducationUrl: z.string().optional().nullish(),
  showSurvey: z.boolean().optional().nullish(),
  subscribedActionId: z.string().optional().nullish(),
  departmentPatientContentList: z
    .array(
      z.object({
        id: z.string().optional().nullish(),
        name: z.string().optional().nullish(),
        onlyPlayOnce: z.boolean().optional().nullish(),
        showBeforeProvider: z.boolean().optional().nullish(),
        showAfterProvider: z.boolean().optional().nullish(),
        showInResources: z.boolean().optional().nullish(),
        showFullSurvey: z.boolean().optional().nullish(),
        hideQrCode: z.boolean().optional().nullish(),
        secondsToShow: z.number().optional().nullish(),
        patientContentType: z.string().optional().nullish(),
      })
    )
    .optional()
    .nullish(),
  athenaPracticeId: z.number().optional().nullish(),
  patientContentIds: z
    .array(
      z.object({
        id: z.string().nullish(),
        name: z.string().optional().nullish(),
        onlyPlayOnce: z.boolean().optional().nullish(),
        showBeforeProvider: z.boolean().optional().nullish(),
        showAfterProvider: z.boolean().optional().nullish(),
        showInResources: z.boolean().optional().nullish(),
        showFullSurvey: z.boolean().optional().nullish(),
        hideQrCode: z.boolean().optional().nullish(),
        secondsToShow: z
          .union([z.string(), z.number().transform(val => Number(val))])
          .optional()
          .nullish(),
        patientContentType: z.string().optional().nullish(),
      })
    )
    .optional()
    .nullish(),
  ehrDeptIdList: z
    .array(
      z.object({
        ehrDeptId: z.string().nullish(),
        isDefaultDept: z.boolean().nullish(),
      })
    )
    .optional()
    .nullish(),

  newEhrDeptId: z.string().optional().nullish(),
  newEhrDeptIdIsDefault: z.boolean().optional().nullish(),
  athenaWaitingRoomId: z.string().optional().nullish(),
  fsPatientInfo: z
    .object({
      patientInfoTypeId: z
        .union([z.string(), z.number().transform(val => Number(val))])
        .optional()
        .transform(val => Number(val)),
      appendAge: z.boolean().optional().nullish(),
      appendMrn: z.boolean().optional().nullish(),
      appendApptTime: z.boolean().optional().nullish(),
      appendApptType: z.boolean().optional().nullish(),
      appendDob: z.boolean().optional().nullish(),
    })
    .optional()
    .nullish(),
  inRoomPatientInfo: z
    .object({
      patientInfoTypeId: z
        .union([z.string(), z.number().transform(val => Number(val))])
        .optional()
        .transform(val => Number(val)),
      appendAge: z.boolean().optional().nullish(),
      appendMrn: z.boolean().optional().nullish(),
      appendApptTime: z.boolean().optional().nullish(),
      appendApptType: z.boolean().optional().nullish(),
      appendDob: z.boolean().optional().nullish(),
    })
    .optional()
    .nullish(),
  outsideRoomPatientInfo: z
    .object({
      patientInfoTypeId: z
        .union([z.string(), z.number().transform(val => Number(val))])
        .optional()
        .transform(val => Number(val)),
      appendAge: z.boolean().optional().nullish(),
      appendMrn: z.boolean().optional().nullish(),
      appendApptTime: z.boolean().optional().nullish(),
      appendApptType: z.boolean().optional().nullish(),
      appendDob: z.boolean().optional().nullish(),
    })
    .optional()
    .nullish(),
  showProvider: z.boolean().optional().nullish(),
  showFirstName: z.boolean().optional().nullish(),
  showLastName: z.boolean().optional().nullish(),
  showMRN: z.boolean().optional().nullish(),
  showDOB: z.boolean().optional().nullish(),
  showApptTime: z.boolean().optional().nullish(),
  showApptType: z.boolean().optional().nullish(),
});
