import axios from "axios";
import { useState, useCallback } from "react";
import { API } from "../apiconfig";
import { ISite } from "../interfaces/interfaces";
import { checkRefreshToken } from "../utils/utils";

export const useSite = (orgId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateSite = useCallback(
    (site: ISite, isNewSite: boolean): Promise<ISite> => {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        setError(null);

        const token = localStorage.getItem("token");
        const method = isNewSite ? "POST" : "PUT";

        let modifiedSite: ISite;
        if (isNewSite) {
          modifiedSite = {
            siteName: site.siteName,
            isActive: site.isActive,
            timeZoneId: typeof site.timeZoneId === "string" ? parseInt(site.timeZoneId, 10) : site.timeZoneId,
            mongoOrgId: orgId,
            goLiveDate: site.goLiveDate,
            campusId: site.campusId,
            isLocked: site.isLocked,
          };
        } else {
          modifiedSite = { ...site };
        }

        axios({
          method,
          url: API.REACT_APP_API_UPDATESITE,
          data: modifiedSite,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            setIsLoading(false);
            resolve(response.data);
          })
          .catch((err) => {
            handleError(err);
            setIsLoading(false);
            reject(err);
          });
      });
    },
    [orgId]
  );

  const unlockSite = useCallback((site: ISite): Promise<ISite> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);

      const token = localStorage.getItem("token");

      axios({
        method: "PUT",
        url: API.REACT_APP_API_UNLOCKSITE,
        data: site,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setIsLoading(false);
          resolve(response.data);
        })
        .catch((err) => {
          handleError(err);
          setIsLoading(false);
          reject(err);
        });
    });
  }, []);

  const handleError = (err: any) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      console.log("UNAUTHORIZED, KICK EM OUT");
      setError("Unauthorized. Please log in again.");
      checkRefreshToken();
    } else {
      console.error("Error:", err);
      setError("An error occurred. Please try again.");
    }
  };

  return {
    updateSite,
    unlockSite,
    isLoading,
    error,
  };
};
