import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../../apiconfig';
import { IAction } from '../../interfaces/interfaces';
import { checkRefreshToken } from '../../utils/utils';

export const useDistinctOrgActions = (
  orgId: string | null
): {
  actions: IAction[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IAction[]>;
} => {
  const [actions, setActions] = useState<
    IAction[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchActions = useCallback(async (): Promise<
    IAction[]
  > => {
    if (!orgId) {
      return [] as IAction[];
    }

    let URL = API.REACT_APP_API_DISTINCTORGACTIONS + orgId;

    let token = localStorage.getItem('token');

    let headers = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Org-UID': 'UID1',
        Pragma: 'no-cache',
      },
    };


    setIsLoading(true);
    axios
      .get(URL, headers)
      .then(response => {
        // console.log("GOT DISTINCT ORG ACTIONS", response);
        // dispatch({
        //   type: FETCH_DISTINCT_ORG_ACTIONS,
        //   payload: [...response.data.data],
        // });
        setIsLoading(false);
        setActions(response.data.data);
      })
      .catch(err => {

        setIsLoading(false);
        console.log('Error fetching distinct org actions', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        setError('Error fetching distinct org actions');
      });
  }, [orgId]);

  useEffect(() => {
    fetchActions();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IAction[]> => {
    return fetchActions();
  }, [fetchActions]);

  return {
    actions,
    isLoading,
    error,
    refetch,
  };
};
