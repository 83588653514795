import React, { useEffect, useState, useCallback } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { dateRangeOptions } from "../reportData";
import { ReportsAccessFields } from "../emailReportingTypes";

interface ReportsAccessedFormProps {
  handleSelectChange: (key: keyof ReportsAccessFields | "orgId", value: string | boolean) => void;
  setIsFormReady: (isReady: boolean) => void;
  isSyncTimesAdmin: boolean;
  isRecurring: boolean;
  incomingSettings?: {
    dateRangeName?: string;
    customStartDate?: Date | undefined;
    customEndDate?: Date | undefined;
    reportAllOrgs?: boolean | undefined;
  };
}

export const ReportsAccessedForm: React.FC<ReportsAccessedFormProps> = ({
  handleSelectChange,
  setIsFormReady,
  isSyncTimesAdmin,
  isRecurring,
  incomingSettings,
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState(incomingSettings?.dateRangeName || "");
  const [customStartDate, setCustomStartDate] = useState(incomingSettings?.customStartDate?.toString() || "");
  const [customEndDate, setCustomEndDate] = useState(incomingSettings?.customEndDate?.toString() || "");
  const [reportAllOrgs, setReportAllOrgs] = useState(incomingSettings?.reportAllOrgs || false);
  const [dateError, setDateError] = useState("");

  const getDateRangeOptions = () => {
    if (isRecurring) {
      return dateRangeOptions.filter((option) => option.key !== "Custom");
    }
    return dateRangeOptions;
  };

  const filteredDateRangeOptions = getDateRangeOptions();

  useEffect(() => {
    let isReady = selectedDateRange !== "";

    if (selectedDateRange === "Custom") {
      isReady = customStartDate !== "" && customEndDate !== "" && dateError === "";
    }

    if (setIsFormReady) {
      setIsFormReady(isReady);
    }
  }, [selectedDateRange, customStartDate, customEndDate, dateError, setIsFormReady]);

  const handleFormChange = (key: keyof ReportsAccessFields, value: string) => {
    if (key === "dateRangeName") {
      setSelectedDateRange(value);
      handleSelectChange(key, value);
    } else {
      handleSelectChange(key, value);
    }
  };

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setReportAllOrgs((prevReportAllOrgs) => {
        handleSelectChange("reportAllOrgs", checked);
        return checked;
      });
    },
    [handleSelectChange]
  );

  const handleDateChange = (key: keyof ReportsAccessFields, value: string) => {
    if (key === "customStartDate") {
      setCustomStartDate(value);
      if (value && customEndDate && new Date(value) > new Date(customEndDate)) {
        setDateError("Start date must be before or equal to the end date");
      } else {
        setDateError("");
        handleSelectChange("customStartDate", value);
      }
    } else if (key === "customEndDate") {
      setCustomEndDate(value);
      if (customStartDate && value && new Date(customStartDate) > new Date(value)) {
        setDateError("End date must be after or equal to the start date");
      } else {
        setDateError("");
        handleSelectChange("customEndDate", value);
      }
    }
  };

  return (
    <div>
      <div>
        <InputLabel style={{ fontSize: 12 }}>Date Range</InputLabel>
        <Select value={selectedDateRange} onChange={(e) => handleFormChange("dateRangeName", e.target.value as string)} style={{ width: 250 }}>
          {filteredDateRangeOptions.map((option, index) => (
            <MenuItem key={index} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      {selectedDateRange === "Custom" && (
        <>
          <TextField
            label="Start Date"
            type="date"
            value={customStartDate}
            onChange={(e) => handleDateChange("customStartDate", e.target.value)}
            style={{ marginTop: 10 }}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(dateError)}
          />
          <TextField
            label="End Date"
            type="date"
            value={customEndDate}
            onChange={(e) => handleDateChange("customEndDate", e.target.value)}
            style={{ marginTop: 10, marginLeft: 10 }}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(dateError)}
          />
          {dateError && <div style={{ color: "red", marginTop: 5 }}>{dateError}</div>}
        </>
      )}
      {isSyncTimesAdmin && (
        <div style={{ marginTop: 16 }}>
          <FormControlLabel control={<Checkbox checked={reportAllOrgs} onChange={handleCheckboxChange} color="primary" />} label="Report on all orgs" />
        </div>
      )}
    </div>
  );
};

export default ReportsAccessedForm;
