import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { API } from '../apiconfig';
import
  {
    IAthenaConfig,
    IDepartment,
    IOrganization,
  } from '../interfaces/interfaces';

export const useFetchAthenaConfigList = (input: {
  organization: IOrganization;
  department: IDepartment;
  onSelectAthenaConfig: (config: IAthenaConfig) => void;
}): {
  athenaConfigList: IAthenaConfig[];
  isLoading: boolean;
  error: string | null;
} => {
  const { organization, department, onSelectAthenaConfig } = input;

  const [athenaConfigList, setAthenaConfigList] = useState<IAthenaConfig[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const prevOrgIdRef = useRef<string | undefined>();
  const prevDeptIdRef = useRef<string | undefined>();

  const fetchAthenaConfigList = useCallback(async () => {
    if (!organization.id || !organization.orgUsesAthena) return;

    setIsLoading(true);
    setError(null);

    let URL = API.REACT_APP_API_ATHENAPRACTICECONFIG + organization.id;
    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    try {
      const response = await axios.get(URL, headers);

      if (response.data.data) {
        setAthenaConfigList(response.data.data);

        const matchingConfig = response.data.data.find(
          (config: IAthenaConfig) =>
            config.athenaPracticeId === department.athenaPracticeId
        );

        if (matchingConfig) {
          onSelectAthenaConfig(matchingConfig);
        }
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        setError('Unauthorized. Please log in again.');
      } else {
        console.error('Error Athena config:', err);
        setError('Failed to fetch Athena config. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    organization.id,
    organization.orgUsesAthena,
    department.athenaPracticeId,
    onSelectAthenaConfig,
  ]);

  useEffect(() => {
    if (
      organization.id &&
      organization.orgUsesAthena &&
      (organization.id !== prevOrgIdRef.current ||
        department.athenaPracticeId !== prevDeptIdRef.current)
    ) {
      prevOrgIdRef.current = organization.id;
      prevDeptIdRef.current = department.athenaPracticeId;
      fetchAthenaConfigList();
    }
  }, [
    organization.id,
    organization.orgUsesAthena,
    department.athenaPracticeId,
    fetchAthenaConfigList,
  ]);

  return { athenaConfigList, isLoading, error };
};
