import React, { useState, useRef, useEffect } from 'react';
import { Avatar, Button } from '@material-ui/core';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Resizer from 'react-image-file-resizer';

interface TProps {
  initialImageUrl: string | undefined;
  onImageChange: (newImageData: {
    orgImageUrl?: string;
    orgImage?: string;
  }) => void;
}

const AvatarPod: React.FC<TProps> = ({ initialImageUrl, onImageChange }) => {
  const [displayImageUrl, setDisplayImageUrl] = useState<string | undefined>(
    initialImageUrl
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const cropperRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setDisplayImageUrl(initialImageUrl);
  }, [initialImageUrl]);

  const handleEditImage = () => {
    setIsEditMode(!isEditMode);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        'JPEG',
        100,
        0,
        uri => {
          if (typeof uri === 'string') {
            setUploadedFile(uri);
            setIsEditMode(true);
          } else if (uri instanceof Blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              if (typeof reader.result === 'string') {
                setUploadedFile(reader.result);
                setIsEditMode(true);
              }
            };
            reader.readAsDataURL(uri);
          }
        },
        'base64'
      );
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const imageElement: any = cropperRef.current;
      const cropper: any = imageElement.cropper;
      const croppedImageBase64 = cropper.getCroppedCanvas().toDataURL();
      const croppedImageData = croppedImageBase64.replace(
        /^data:image\/[a-z]+;base64,/,
        ''
      );

      setDisplayImageUrl(croppedImageBase64);
      onImageChange({
        orgImageUrl: croppedImageBase64,
        orgImage: croppedImageData,
      });
      setIsEditMode(false);
      setUploadedFile(null);
    }
  };

  const hasImage = displayImageUrl && displayImageUrl.trim() !== '';

  return (
    <div>
      {hasImage && (
        <Avatar
          alt=""
          src={displayImageUrl}
          style={{ width: 60, height: 60, margin: 10 }}
        />
      )}

      {isEditMode && (
        <div>
          <Cropper
            ref={cropperRef}
            src={uploadedFile || displayImageUrl}
            style={{ height: 200, width: '30%' }}
            aspectRatio={1}
            guides={true}
          />
          <Button onClick={handleCrop} color="primary">
            Apply Crop
          </Button>
        </div>
      )}

      {hasImage && (
        <Button onClick={handleEditImage} color="primary">
          {isEditMode ? 'Cancel Edit' : 'Edit Image'}
        </Button>
      )}

      <input
        type="file"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="image-upload"
      />
      <label htmlFor="image-upload">
        <Button component="span" color="primary">
          {hasImage ? 'Upload New Image' : 'Add Image'}
        </Button>
      </label>
    </div>
  );
};

export default AvatarPod;
