import { FC } from "react";
import { IStaffMember } from "../../../../interfaces/interfaces";
import { Avatar } from "@material-ui/core";
import moment from "moment";

type TProps = {
  staffMember: IStaffMember;
  serverTimeOffset: number;
  onHighlightMember: (staffMember: IStaffMember) => any;
}

const LocateStaffResult :FC<TProps> = (props) => {

  const { staffMember, onHighlightMember, serverTimeOffset } = props;
  // const hasSearch = staffSearchKeyword.length > 0;
  const shouldRender = true;
  // hasSearch ||
  // (moment()
  //   .add('milliseconds', serverTimeOffset)
  //   .local()
  //   .diff(
  //     moment.utc(staffMember.lastLocationUTCTimeStamp).local(),
  //     'minutes'
  //   ) < 30 &&
  //   (staffMember.userTypeId === 1 || staffMember.userTypeId === 4) &&
  //   staffMember.isInSameSite);


  const formatTimeDifference = (lastLocationUTCTimeStamp: string) => {
    const diff = moment()
      .add('milliseconds', serverTimeOffset)
      .local()
      .diff(moment.utc(lastLocationUTCTimeStamp).local(), 'minutes');

    if (diff <= 0) return ': Now';
    if (diff < 60) return `: ${diff} mins ago`;

    const hours = Math.floor(diff / 60);
    if (hours > 99) return ': 99+ hours ago';
    return `: ${hours} hours ago`;
  };



  if (!shouldRender) return null;

  const isStaff =
    staffMember.userTypeId === 1 || staffMember.userTypeId === 4;
  // const isUser = staffMember.userTypeId === 2 || staffMember.userTypeId === 3;

  return (
    <div>
      <div
        style={{
          cursor: 'pointer',
          borderBottom: '1px solid lightgray',
          paddingBottom: 10,
          marginBottom: 10,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        onClick={() => onHighlightMember(staffMember)}
      >
        <div>
          {staffMember.userTypeId !== 3 && (
            <Avatar src={staffMember.userImageUrl} />
          )}
        </div>
        <div style={{ marginLeft: 10 }}>
          <strong>
            {staffMember.firstName} {staffMember.lastName}
          </strong>
          {(isStaff || staffMember.userTypeId === 3) && (
            <>{formatTimeDifference(staffMember.lastLocationUTCTimeStamp)}</>
          )}
          <br />
          {staffMember.lastDeviceLocationName}
        </div>
      </div>
    </div>
  );
};


export default LocateStaffResult;