import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { FC, useCallback, useState } from 'react';

import { IAction, IDepartment } from '../../../../../interfaces/interfaces';

import { useFetchAllActions } from '../../../../../hooks/useFetchAllActions';
import { useFetchSounds } from '../../../../../hooks/useFetchSounds';
import { useStyles } from './styles';

import some from 'lodash/some';
import moment from 'moment';
import { useDepartment } from '../../../../../hooks/department/useDepartment';
import DndActions from './DndActions';

type TProps = {
  department: IDepartment;
  showVisibleActions: boolean;
  // allSounds: ISound[];
  // allActions: IAction[];
  onSetActions: (actions: IAction[]) => Promise<IDepartment>;
  // fetchDepartment: (deptId: string) => void;
  // updateLocalDeptActions: (dept: IDepartment) => void;
};
const DndActionContainer: FC<TProps> = props => {
  const {
    department,
    showVisibleActions,
    // allSounds,
    // allActions,
    onSetActions,
    // fetchDepartment,
    // updateLocalDeptActions,
  } = props;

  const classes = useStyles();

  const [actionsKey, setActionsKey] = useState(moment().toISOString());
  const [openActionPopover, setOpenActionPopover] = useState(false);
  const [openSoundPopover, setOpenSoundPopover] = useState(false);
  const [actionToModifySound, setActionToModifySound] = useState<any>(null);
  const [actionSearchString, setActionSearchString] = useState('');
  const [hideInactiveActions, setHideInactiveActions] = useState(true);
  const [newChecklistItemName, setNewChecklistItemName] = useState('');
  const [newChecklistItemAutoAdd, setNewChecklistItemAutoAdd] = useState(false);

  const [currentSound, setCurrentSound] = useState<HTMLAudioElement | null>(
    null
  );

  const { update: updateDepartment } = useDepartment({
    departmentId: department?.id,
  });
  const { actions: allActions = [] } = useFetchAllActions();
  const { sounds: allSounds = [] } = useFetchSounds();

  const previewSound = useCallback(
    (url: string) => {
      // Stop the currently playing audio if it exists
      if (currentSound) {
        currentSound.pause();
        currentSound.currentTime = 0;
      }

      // Create and play the new audio
      const newAudio = new Audio(url);
      newAudio.play();
      setCurrentSound(newAudio);
    },
    [currentSound]
  );

  const modifySound = (selectedAction: IAction) => {
    setActionToModifySound({
      ...selectedAction,
      playSoundOnFlowstation: selectedAction.playSoundOnFlowstation || false,
    });

    setOpenSoundPopover(true);
  };

  const addAction = (newAction: any) => {
    // console.log("add action", newAction);
    let preppedAction = {
      action: {
        ...newAction.action,
        isVisible: showVisibleActions,
        notifyAllTablets: false,
        lockTabletsInCampus: false,
        verifyAction: false,
        addForNewVisit: false,
        canAddWithoutPatient: false,
        addForFinishedVisit: false,
        actionDescription: newAction.action.actionDescription,
        availableChecklistItems: [],
        playSoundOnFlowstation: true,
      },
    };

    console.log('prepped action', preppedAction);

    // console.log("prepped action", preppedAction);
    let actionsToModify = department.actionsList;
    let duplicate = false;

    // Check for duplicate
    actionsToModify.forEach(action => {
      if (preppedAction.action.id === action.id) {
        duplicate = true;
      }
    });

    // Add action if not duplicate
    if (!duplicate) {
      actionsToModify.push(preppedAction.action);
      onSetActions(actionsToModify);
      // softSave();
      // setState({
      //   openActionPopover: false,
      // });
    }
  };

  const onDeleteAction = (actionId: string) => {
    let actionsToModify = department.actionsList;

    let modifiedActions = actionsToModify.filter(function (obj) {
      return obj.id !== actionId;
    });

    let modifiedDepartment = {
      ...department,
      actionsList: modifiedActions,
      // AdminUserId: loggedInUserData.userId,
    };

    console.log({
      actionId,
      actionsToModify,
      modifiedActions,
      modifiedDepartment,
    });

    console.log('deleteAction modifiedDepartment then', { modifiedDepartment });

    updateDepartment({
      departmentId: department.id,
      data: modifiedDepartment,
    }).then(updatedDepartment => {
      console.log('deleteAction updateDepartment then', { updatedDepartment });

      const updatedActions = updatedDepartment?.actionsList.filter(
        action => action.id !== actionId
      );

      console.log('deleteAction updateDepartment then', {
        updatedActions,
        length: updatedActions.length,
      });

      onSetActions(updatedActions);
    });
  };

  const handleChange = (name: string) => (event: any) => {
    let newValue = event.target.value;

    // setState((prevState) => ({
    //   actionToModifySound: {
    //     ...prevState.actionToModifySound,
    //     [name]: newValue,
    //   },
    // }));

    // console.log("newValue", newValue);

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          [name]: newValue,
        };
      }
    });

    setActionToModifySound({
      ...actionToModifySound,
      [name]: newValue,
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  // const handleIsDisabled = () => {
  //   let actionsListToModify = department.actionsList;
  //   actionsListToModify.forEach((action, i) => {
  //     if (action.id === actionToModifySound.id) {
  //       actionsListToModify[i] = {
  //         ...actionsListToModify[i],
  //         isDisabled: !actionsListToModify[i].isDisabled,
  //       };
  //     }
  //   });

  //   setActionToModifySound({
  //     ...actionToModifySound,
  //     isDisabled: !actionToModifySound.isDisabled,
  //   });

  //   onSetActions(actionsListToModify);
  // };

  const handleVerifyHoldSeconds = (event: any) => {
    let value = event.target.value;
    if (value.startsWith('0')) {
      value = value.substring(1);
    }

    if (!value) {
      value = '0';
    }

    // console.log("Value", value);

    function isNumeric(str: number) {
      if (typeof str != 'string') return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    }

    if (isNumeric(value)) {
      let actionsListToModify = department.actionsList;
      actionsListToModify.forEach((action, i) => {
        if (action.id === actionToModifySound.id) {
          actionsListToModify[i] = {
            ...actionsListToModify[i],
            verifyHoldSeconds: value,
          };
        }
      });

      setActionToModifySound({
        ...actionToModifySound,
        verifyHoldSeconds: value,
      });

      onSetActions(actionsListToModify);
    } else {
      setActionToModifySound({
        ...actionToModifySound,
        verifyHoldSeconds: null,
      });
    }
  };

  const handleSoundReplaySeconds = (event: any) => {
    let value = event.target.value;
    if (value.startsWith('0')) {
      value = value.substring(1);
    }

    if (!value) {
      value = '0';
    }

    function isNumeric(str: number) {
      if (typeof str != 'string') return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    }

    if (isNumeric(value)) {
      let actionsListToModify = department.actionsList;
      actionsListToModify.forEach((action, i) => {
        if (action.id === actionToModifySound.id) {
          actionsListToModify[i] = {
            ...actionsListToModify[i],
            soundReplaySeconds: value,
          };
        }
      });

      setActionToModifySound({
        ...actionToModifySound,
        soundReplaySeconds: value,
      });

      onSetActions(actionsListToModify);
    } else {
      setActionToModifySound({
        ...actionToModifySound,
        soundReplaySeconds: null,
      });
    }
  };

  const handleLockTabletsInCampus = () => {
    setActionToModifySound({
      ...actionToModifySound,
      lockTabletsInCampus: !actionToModifySound.lockTabletsInCampus,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          lockTabletsInCampus: !actionToModifySound.lockTabletsInCampus,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handleNotifyAllTablets = () => {
    setActionToModifySound({
      ...actionToModifySound,
      notifyAllTablets: !actionToModifySound.notifyAllTablets,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          notifyAllTablets: !actionToModifySound.notifyAllTablets,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handlePlaySoundOnFlowstation = () => {
    setActionToModifySound({
      ...actionToModifySound,
      playSoundOnFlowstation: !actionToModifySound.playSoundOnFlowstation,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          playSoundOnFlowstation: !actionToModifySound.playSoundOnFlowstation,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handlePlaySoundOnInside = () => {
    setActionToModifySound({
      ...actionToModifySound,
      playSoundOnInside: !actionToModifySound.playSoundOnInside,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          playSoundOnInside: !actionToModifySound.playSoundOnInside,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handlePlaySoundOnOutside = () => {
    setActionToModifySound({
      ...actionToModifySound,
      playSoundOnOutside: !actionToModifySound.playSoundOnOutside,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          playSoundOnOutside: !actionToModifySound.playSoundOnOutside,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handleVerifyAction = () => {
    setActionToModifySound({
      ...actionToModifySound,
      verifyAction: !actionToModifySound.verifyAction,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          verifyAction: !actionToModifySound.verifyAction,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handleAddForNewVisit = () => {
    setActionToModifySound({
      ...actionToModifySound,
      addForNewVisit: !actionToModifySound.addForNewVisit,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          addForNewVisit: !actionToModifySound.addForNewVisit,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handleAddForFinishedVisit = () => {
    setActionToModifySound({
      ...actionToModifySound,
      addForFinishedVisit: !actionToModifySound.addForFinishedVisit,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          addForFinishedVisit: !actionToModifySound.addForFinishedVisit,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handleCanAddWithoutPatient = () => {
    setActionToModifySound({
      ...actionToModifySound,
      canAddWithoutPatient: !actionToModifySound.canAddWithoutPatient,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          canAddWithoutPatient: !actionToModifySound.canAddWithoutPatient,
        };
      }
    });

    // console.log("TO UPDATE", actionsListToModify);
    onSetActions(actionsListToModify);
  };

  const handleNotifyInRooms = () => {
    setActionToModifySound({
      ...actionToModifySound,
      notifyInRooms: !actionToModifySound.notifyInRooms,
    });

    let actionsListToModify = department.actionsList;
    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        // console.log("modify this action!", actionsListToModify[i]);
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          notifyInRooms: !actionToModifySound.notifyInRooms,
        };
      }
    });

    onSetActions(actionsListToModify);
  };

  const assignSound = (soundId: string, soundUrl: string) => {
    let actionsListToModify = department.actionsList;

    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        actionsListToModify[i] = {
          ...actionsListToModify[i],
          id: actionToModifySound.id,
          actionImageUrl: actionToModifySound.actionImageUrl,
          orderId: actionsListToModify[i].orderId,
          mongoSoundId: soundId,
          soundUrl: soundUrl,
        };
      }
    });

    let modifiedDepartment = {
      ...department,
      actionsList: actionsListToModify,
      // AdminUserId: loggedInUserData.userId,
    };

    updateDepartment({
      departmentId: department.id,
      data: modifiedDepartment,
    }).then(() => {
      let updatedAction = modifiedDepartment.actionsList.find(
        (a: IAction) => a.id === actionToModifySound.id
      );
      setActionToModifySound(updatedAction);
    });
  };

  // const handleNewPhoneReceiver = () => (event: any) => {
  //   setNewPhoneReceiver(event.target.value);
  // };

  // const handleNewPhoneNumber = () => (event: any) => {
  //   setNewPhoneNumber(event.target.value);
  // };

  const addCheckListItem = () => {
    let actionsListToModify = department.actionsList;

    let newActionListItem = {
      name: newChecklistItemName,
      autoAdd: newChecklistItemAutoAdd,
    };

    setNewChecklistItemName('');
    setNewChecklistItemAutoAdd(false);

    setActionToModifySound({
      ...actionToModifySound,
      availableChecklistItems: [
        ...actionToModifySound.availableChecklistItems,
        newActionListItem,
      ],
    });

    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        actionsListToModify[i].availableChecklistItems.push(newActionListItem);
      }
    });

    onSetActions(actionsListToModify);
  };

  const handleCheckListItemKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      addCheckListItem();
    }
  };

  const deleteActionItem = (index: number) => {
    let actionsListToModify = department.actionsList;

    setActionToModifySound({
      availableChecklistItems:
        actionToModifySound.availableChecklistItems.filter(
          (item: any, i: number) => i !== index
        ),
    });

    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        actionsListToModify[i].availableChecklistItems = actionsListToModify[
          i
        ].availableChecklistItems.filter((item: any, i: number) => i !== index);
      }
    });

    onSetActions(actionsListToModify);
  };

  const toggleAutoAdd = (index: number) => {
    let actionsListToModify = department.actionsList;

    setActionToModifySound({
      ...actionToModifySound,
      availableChecklistItems: actionToModifySound.availableChecklistItems.map(
        (item: any, i: number) => {
          if (i === index) {
            return {
              ...item,
              autoAdd: !item.autoAdd,
            };
          }
          return item;
        }
      ),
    });

    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        actionsListToModify[i].availableChecklistItems[index].autoAdd =
          !actionsListToModify[i].availableChecklistItems[index].autoAdd;
      }
    });

    onSetActions(actionsListToModify);
  };

  const updateCheckListName = (e: any, index: number) => {
    let actionsListToModify = department.actionsList;

    setActionToModifySound({
      ...actionToModifySound,
      availableChecklistItems: actionToModifySound.availableChecklistItems.map(
        (item: any, i: number) => {
          if (i === index) {
            return {
              ...item,
              name: e.target.value,
            };
          }
          return item;
        }
      ),
    });

    actionsListToModify.forEach((action, i) => {
      if (action.id === actionToModifySound.id) {
        actionsListToModify[i].availableChecklistItems[index].name =
          e.target.value;
      }
    });

    onSetActions(actionsListToModify);
  };

  // console.log("playSoundOnFlowstation in render:", actionToModifySound?.playSoundOnFlowstation);

  // console.log("allsound", allSounds);
  // console.log("all actions", allActions);

  // console.log("dept to edit", department);
  // console.log("to edit", actionToModifySound);

  // console.log("actions", allActions);

  let filteredActions = allActions.filter(obj => {
    // if (obj['actionName']) {
    if (obj.actionName) {
      return (
        obj.actionName
          .toUpperCase()
          .includes(actionSearchString.toUpperCase()) ||
        obj.actionDescription
          .toUpperCase()
          .includes(actionSearchString.toUpperCase())
      );
    }
    // if (obj["actionDescription"]) {
    //   return obj["actionDescription"].toUpperCase().includes(actionSearchString.toUpperCase());
    // }
    return null;
  });

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Grid item lg={7} style={{ maxWidth: '50%' }}>
            {openActionPopover ? (
              <ClickAwayListener
                onClickAway={() => {
                  setOpenActionPopover(false);
                }}
              >
                <div>
                  <div
                    className={classes.actionPopover}
                    style={window.innerWidth > 1000 ? { right: '15%' } : {}}
                  >
                    <TextField
                      // error={lastNameError}
                      label="Action Name/Description"
                      className={classes.textField}
                      value={actionSearchString}
                      onChange={e => {
                        setActionSearchString(e.target.value);
                      }}
                      margin="none"
                    // style={{ marginTop: 0 }}
                    />
                    <Checkbox
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={!hideInactiveActions}
                      onClick={() => {
                        setHideInactiveActions(!hideInactiveActions);
                      }}
                    />
                    Show Inactive Actions
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        maxHeight: '60vh',
                        overflow: 'auto',
                      }}
                    >
                      {filteredActions.map((action: IAction) => (
                        <div key={action.id}>
                          {action.isActive === hideInactiveActions ? (
                            <div>
                              {some(department.actionsList, {
                                id: action.id,
                              }) ? (
                                <>
                                  {department.actionsList.map(
                                    (deptAction, key) => (
                                      <div key={key}>
                                        {deptAction.id === action.id ? (
                                          // Grayed out action since its being used in either hidden or visible actions
                                          <Tooltip
                                            title={'Action is already in use'}
                                          >
                                            <img
                                              className={
                                                classes.grayPopOverActions
                                              }
                                              src={action.actionImageUrl}
                                              alt=""
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    )
                                  )}
                                </>
                              ) : (
                                <img
                                  className={classes.popOverActions}
                                  src={action.actionImageUrl}
                                  onClick={() => {
                                    addAction({ action });
                                  }}
                                  alt=""
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        color="primary"
                        style={{ backgroundColor: '#F0F1F9' }}
                        onClick={() => {
                          setOpenActionPopover(false);
                        }}
                      >
                        Done
                      </Button>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            ) : null}

            {openSoundPopover ? (
              <div>
                <img
                  style={{ maxWidth: 80, width: 80 }}
                  src={actionToModifySound.actionImageUrl}
                  alt=""
                />

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Assign Sound</Typography>
                  </AccordionSummary>
                  {/* <AccordionDetails> */}
                  <div>
                    <div
                      onClick={() => {
                        handlePlaySoundOnFlowstation();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Checkbox
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                        checked={actionToModifySound.playSoundOnFlowstation}
                      />
                      Play Sound on Flowstation
                    </div>

                    <div
                      onClick={() => handlePlaySoundOnInside()}
                      style={{ cursor: 'pointer' }}
                    >
                      <Checkbox
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                        checked={actionToModifySound.playSoundOnInside}
                      />
                      Play Sound on Inside Tablet
                    </div>

                    <div
                      onClick={() => handlePlaySoundOnOutside()}
                      style={{ cursor: 'pointer' }}
                    >
                      <Checkbox
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                        checked={actionToModifySound.playSoundOnOutside}
                      />
                      Play Sound on Outside Tablet
                    </div>
                  </div>
                  <div
                    style={{
                      padding: '8px 5px 24px',
                      maxHeight: 200,
                      overflow: 'scroll',
                      overflowX: 'hidden',
                    }}
                  >
                    {allSounds.map((sound, i) => (
                      //   <div key={sound.soundId}>Sound</div>
                      <div key={sound.id} className={classes.soundList}>
                        <div className={classes.flexContainer}>
                          <div
                            onClick={() => previewSound(sound.soundUrl)}
                            className={classes.playArrow}
                          >
                            <PlayArrow />
                          </div>
                          <div
                            onClick={() =>
                              assignSound(sound.id, sound.soundUrl)
                            }
                            className={
                              actionToModifySound.mongoSoundId === sound.id
                                ? classes.selectedSound
                                : classes.nonSelectedSound
                            }
                          >
                            {sound.soundName}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* </AccordionDetails> */}
                </Accordion>
                <br />
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Assign Checklist Items</Typography>
                  </AccordionSummary>
                  <div
                    style={{
                      padding: '8px 24px 24px',
                      overflow: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    {actionToModifySound.availableChecklistItems?.length ===
                      0 ? (
                      <div style={{ color: 'gray' }}>No checklist items...</div>
                    ) : null}
                    {actionToModifySound.availableChecklistItems?.map(
                      (item: any, i: number) => (
                        <div
                          key={i}
                        // style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                        >
                          <div>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <strong>{i + 1}</strong>.{' '}
                              <TextField
                                style={{ width: '100%' }}
                                value={
                                  actionToModifySound.availableChecklistItems[i]
                                    .name
                                }
                                onChange={e => updateCheckListName(e, i)}
                              />
                              <div
                                onClick={() => deleteActionItem(i)}
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                }}
                              >
                                X
                              </div>
                            </div>
                            <div>
                              <Checkbox
                                classes={{
                                  root: classes.checkBox,
                                  checked: classes.checked,
                                }}
                                checked={item.autoAdd}
                                onClick={() => toggleAutoAdd(i)}
                              />
                              Auto Add
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    <div>
                      <br />
                      <TextField
                        style={{ width: '100%' }}
                        label="New Item Name"
                        value={newChecklistItemName}
                        onChange={e => {
                          setNewChecklistItemName(e.target.value);
                        }}
                        onKeyDown={handleCheckListItemKeyPress}
                      />
                      <div
                        onClick={() => {
                          setNewChecklistItemAutoAdd(!newChecklistItemAutoAdd);
                        }}
                      >
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          style={{ paddingLeft: 0 }}
                          checked={newChecklistItemAutoAdd}
                        />
                        Auto Add
                      </div>
                      <Button
                        disabled={newChecklistItemName.length === 0}
                        color="primary"
                        variant="contained"
                        onClick={addCheckListItem}
                      >
                        Add Checklist Item
                      </Button>
                    </div>
                  </div>
                </Accordion>

                <TextField
                  label="Action Name"
                  className={classes.textField}
                  value={
                    actionToModifySound.actionName
                      ? actionToModifySound.actionName
                      : ''
                  }
                  onChange={handleChange('actionName')}
                  margin="normal"
                />

                <TextField
                  label="Action Description"
                  className={classes.textField}
                  value={
                    actionToModifySound.actionDescription
                      ? actionToModifySound.actionDescription
                      : ''
                  }
                  onChange={handleChange('actionDescription')}
                  margin="normal"
                  multiline
                />

                <Tooltip
                  title={
                    'Speak action & location on all tablets & Flowstations in site'
                  }
                >
                  <div
                    onClick={() => handleNotifyAllTablets()}
                    style={{ cursor: 'pointer' }}
                  >
                    <Checkbox
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={actionToModifySound.notifyAllTablets}
                    />
                    Notify All Tablets
                  </div>
                </Tooltip>

                <div
                  onClick={() => handleVerifyAction()}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={actionToModifySound.verifyAction}
                  />
                  Two-Step Confirmation
                </div>
                <div
                  onClick={() => handleAddForNewVisit()}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={actionToModifySound.addForNewVisit}
                  />
                  Add for New Visit
                </div>
                <div
                  onClick={() => handleAddForFinishedVisit()}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={actionToModifySound.addForFinishedVisit}
                  />
                  Add for Finished Visit
                </div>
                <div
                  onClick={() => handleCanAddWithoutPatient()}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={actionToModifySound.canAddWithoutPatient}
                  />
                  Can add Without Patient
                </div>

                <Tooltip
                  title={'Play action sound on tablet when staff inside rooms'}
                >
                  <div
                    onClick={() => handleNotifyInRooms()}
                    style={{ cursor: 'pointer' }}
                  >
                    <Checkbox
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={actionToModifySound.notifyInRooms}
                    />
                    Notify Users in Room
                  </div>
                </Tooltip>

                <div
                  onClick={() => handleLockTabletsInCampus()}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={actionToModifySound.lockTabletsInCampus}
                  />
                  Lock Tablets in Campus
                </div>

                {actionToModifySound.verifyAction ? (
                  <div>
                    <TextField
                      // type="tel"
                      label="Verify Hold Seconds"
                      // className={classes.textField}
                      value={
                        actionToModifySound.verifyHoldSeconds
                          ? actionToModifySound.verifyHoldSeconds
                          : '0'
                      }
                      onChange={handleVerifyHoldSeconds}
                      margin="normal"
                    // multiline
                    />
                    <br />
                  </div>
                ) : null}

                {actionToModifySound.mongoSoundId ? (
                  <TextField
                    // type="tel"
                    label="Sound Replay Seconds"
                    // className={classes.textField}
                    value={
                      actionToModifySound.soundReplaySeconds
                        ? actionToModifySound.soundReplaySeconds
                        : '0'
                    }
                    onChange={handleSoundReplaySeconds}
                    margin="normal"
                  // multiline
                  />
                ) : null}
              </div>
            ) : null}
          </Grid>
          <Grid
            item
            lg={5}
            style={{ maxWidth: 300, margin: 'auto', marginTop: 0 }}
          >
            <DndActions
              actionsKey={actionsKey}
              allSounds={allSounds}
              modifySound={modifySound}
              showVisibleActions={showVisibleActions}
              department={department}
              onOpenActionPopover={setOpenActionPopover}
              onSetActions={onSetActions}
              onUpdateActionKey={updatedKey => {
                setActionsKey(updatedKey);
              }}
              onDeleteAction={(actionId: string) => {
                onDeleteAction(actionId);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DndActionContainer;

// const mapStateToProps = (state: { adminData: any }) => ({
//   loggedInUserData: state.adminData.loggedInUserData,
//   organizationDepartments: state.adminData.organizationDepartments,
//   allActions: state.adminData.allActions,
//   department: state.adminData.department,
//   sites: state.adminData.sites,
//   allSounds: state.adminData.allSounds,
// });

// export default connect(mapStateToProps, { fetchOrganizationDepartments, fetchDepartment, fetchAllActions, fetchSites, fetchSounds })(
//   withStyles(styles)(DndActionContainer)
// );
