import React, { FC, useEffect } from 'react';
import ReactPlayer from 'react-player';

type TProps = {
  url: string;
  duration: number;
  onEnd: () => void;
};

const VideoPlayer: FC<TProps> = props => {
  const { url, onEnd } = props;

  const [playing, setPlaying] = React.useState(false);

  useEffect(() => {
    setPlaying(true);
  }, []);

  return (
    <div>
      <ReactPlayer
        playing={playing}
        url={url}
        controls={false}
        onEnded={onEnd}
        onError={e => console.error('VideoPlayer error', e)}
        width="100%"
        height="100%"
        muted={false}
      />
    </div>
  );
};

export default VideoPlayer;
