import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  docContainer: {
    margin: "auto",
    // maxWidth: "90%"
  },
  // actionIcon: {
  //     width: 75,
  //     height: 75
  // },
  orgHeader: {
    textAlign: "center",
    fontSize: 24,
  },
  deptHeader: {
    // textAlign: 'center',
    // fontSize: 18,
    color: "#7e7e7e",
    fontSize: "25px",
    // marginBottom: "3%",
    marginTop: "2%",
    // borderBottom: "1px solid #DCDCDC",
  },
  sectionHeaderVisible: {
    color: "#7e7e7e",
    fontSize: "16px",
    marginBottom: "3%",
    marginTop: "2%",
    borderBottom: "1px solid #DCDCDC",
  },
  sectionHeaderHidden: {
    color: "#7e7e7e",
    fontSize: "16px",
    marginBottom: "3%",
    marginTop: "3%",
    borderBottom: "1px solid #DCDCDC",
  },
  mainContainer: {
    maxWidth: "80%",
    paddingLeft: 20,
    paddingRight: 20,
    margin: "auto",
    marginTop: "1%",
  },
  appBar: {
    boxShadow: "0px 0px 5px 0px #707070",
    color: "#000000",
    marginBottom: 10,
    backgroundColor: "#ffffff",
  },
  headerImage: {
    maxWidth: 200,
    height: 45,
    paddingBottom: 3,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  smallHeaderImage: {
    // paddingTop: 5,
    maxWidth: 200,
    height: 45,
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  siteName: {
    fontSize: 25,
    paddingLeft: 20,
    color: "#7e7e7e",
  },
  rightToolBar: {
    marginLeft: "auto",
    marginRight: "-12px",
  },
  logoutButton: {
    color: "#7e7e7e",
  },
  //   card: {
  //       height: "100%"
  //   }
}));
