import { Tooltip } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { FC, useRef, useState } from 'react';

import { IStaffMember } from '../../../../interfaces/interfaces';
import LocateStaffPopover from './LocateStaffPopover';
import { useStyles } from './styles';

type TProps = {
  openLocateStaff: () => void;
  onHighlightMember: (staffMember: IStaffMember) => any;
  onClose: () => void;
  open: boolean;
  serverTimeOffset: number;
};

const LocateStaffPopoverContainer: FC<TProps> = props => {
  const {
    openLocateStaff,
    open,
    onHighlightMember,
    serverTimeOffset,
  } = props;

  const [staffSearchKeyword, setStaffSearchKeyword] = useState('');

  const providerInput = useRef();
  const classes = useStyles();

  return (
    <div>
      <Tooltip
        title="Hint: Use CTRL+F to search quickly"
        placement="left"
        disableFocusListener={true}
      >
        <div
          className={classes.searchPopover + ' step-search'}
          onClick={openLocateStaff}
        >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <div>
            <InputBase
              //@ts-ignore
              inputRef={providerInput}
              placeholder="Locate"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={e => {
                setStaffSearchKeyword(e.target.value);
              }}
            />
          </div>
        </div>
      </Tooltip>
      {open && (
        <div className={classes.locateStaffPopover}>
          <LocateStaffPopover
            staffSearchKeyword={staffSearchKeyword}
            serverTimeOffset={serverTimeOffset}
            onHighlightMember={staffMember => {
              onHighlightMember(staffMember);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LocateStaffPopoverContainer;
