import { IOrganization } from '../../../../interfaces/interfaces';

export const ORG_TEMPLATE: IOrganization = {
  id: '',
  orgName: '',
  isActive: false,
  orgImageUrl: '',
  organizationId: null,
  isCustomerOrg: false,
  serviceLineLabel: '',
  serviceLineSubGroupLabel: '',
  serviceLines: [],
  allowedEmailDomains: [],
  serviceLineSubGroups: [],
  timeZoneId: 9,
  benchmarkGroups: [],
};
