import { IRoomPatientInfo } from "../../../../../interfaces/interfaces";

export const getLiveExample = (input: {
  patientInfo: IRoomPatientInfo;
}): string => {
  let patientIdentifierExample = '';
  const { patientInfo = {} as IRoomPatientInfo } = input;

  switch (Number(patientInfo.patientInfoTypeId)) {
    case 0:
      patientIdentifierExample = '';
      break;
    case 1:
      patientIdentifierExample = 'J Smith';
      break;
    case 2:
      patientIdentifierExample = 'JS';
      break;
    case 3:
      patientIdentifierExample = 'John S';
      break;
    case 4:
      patientIdentifierExample = 'John Smith';
      break;
    default:
  }

  if (patientInfo.appendAge) {
    patientIdentifierExample += ' | 30';
  }
  if (patientInfo.appendMrn) {
    patientIdentifierExample += ' | 123456';
  }
  if (patientInfo.appendApptTime) {
    patientIdentifierExample += ' | 2:30PM';
  }
  if (patientInfo.appendApptType) {
    patientIdentifierExample += ' | 15-FU';
  }
  if (patientInfo.patientInfoTypeId === 0) {
    patientIdentifierExample = patientIdentifierExample.substring(3);
  }
  if (patientInfo.appendDob) {
    patientIdentifierExample += ' | 1/1/1980';
  }

  return patientIdentifierExample
};
