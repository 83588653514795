import React from 'react';
import { Chip, TextField, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IUser } from '../../../../../interfaces/interfaces';

interface EmailFieldManagerProps {
  toRecipients: IUser[];
  ccRecipients: IUser[];
  availableUsers: IUser[];
  onAddRecipient: (recipient: IUser, field: 'to' | 'cc') => void;
  onRemoveRecipient: (recipient: IUser, field: 'to' | 'cc') => void;
}

const EmailFieldManager: React.FC<EmailFieldManagerProps> = ({
  toRecipients,
  ccRecipients,
  availableUsers,
  onAddRecipient,
  onRemoveRecipient,
}) => {
  const handleAddToRecipient = (user: IUser | null) => {
    if (user && !toRecipients.some(recipient => recipient.id === user.id)) {
      onAddRecipient(user, 'to');
    }
  };

  const handleRemoveToRecipient = (user: IUser) => {
    onRemoveRecipient(user, 'to');
  };

  const handleAddCcRecipient = (user: IUser | null) => {
    if (user && !ccRecipients.some(recipient => recipient.id === user.id)) {
      onAddRecipient(user, 'cc');
    }
  };

  const handleRemoveCcRecipient = (user: IUser) => {
    onRemoveRecipient(user, 'cc');
  };

  const filteredAvailableUsers = availableUsers
    .filter(
      user =>
        !toRecipients.some(recipient => recipient.id === user.id) &&
        !ccRecipients.some(recipient => recipient.id === user.id)
    )
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

  return (
    <div>
      {/* Render the "To" section */}
      <div style={{ paddingBottom: '8px' }}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
        >
          <div style={{ marginRight: '8px' }}>To:</div>
          <Divider style={{ flexGrow: 1 }} />
        </div>
        {toRecipients.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            {toRecipients.map(recipient => (
              <div key={recipient.id} style={{ padding: '2px' }}>
                <Chip
                  label={recipient.fullName}
                  onDelete={() => handleRemoveToRecipient(recipient)}
                />
              </div>
            ))}
          </div>
        )}
        <Autocomplete
          key={toRecipients.length}
          options={filteredAvailableUsers}
          getOptionLabel={option => option.fullName}
          onChange={(_, user: IUser | string) =>  typeof user !== 'string' && handleAddToRecipient(user)}
          renderInput={params => (
            <TextField {...params} label="Search for recipients..." />
          )}
        />
      </div>
      {/* Render the "CC" section */}
      <div style={{ paddingBottom: '8px' }}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
        >
          <div style={{ marginRight: '8px' }}>CC:</div>
          <Divider style={{ flexGrow: 1 }} />
        </div>
        {ccRecipients.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            {ccRecipients.map(recipient => (
              <div key={recipient.id} style={{ padding: '2px' }}>
                <Chip
                  label={recipient.fullName}
                  onDelete={() => handleRemoveCcRecipient(recipient)}
                />
              </div>
            ))}
          </div>
        )}
        <Autocomplete
          key={ccRecipients.length}
          options={filteredAvailableUsers}
          getOptionLabel={option => option.fullName}
          onChange={(_, user: IUser | string) =>
            typeof user !== 'string' && handleAddCcRecipient(user)
          }
          renderInput={params => (
            <TextField {...params} label="Search for recipients..." />
          )}
        />
      </div>
    </div>
  );
};

export default EmailFieldManager;
