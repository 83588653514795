import { ChangeEvent, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  fetchDistinctOrgActions,
  fetchLoggedInUser,
  setOrganization,
} from '../../../../actions/adminActions';
import { API } from '../../../../apiconfig';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';

import { checkRefreshToken } from '../../../../utils/utils';

import { canDo } from '../../../../utils/permissionCheck';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseButton from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import DialogActions from "@material-ui/core/DialogActions";
import { Grid } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Popover from '@material-ui/core/Popover';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import {
  IAction,
  IPermission,
  IServiceLine,
  IServiceSubGroup,
  TAdminState,
} from '../../../../interfaces/interfaces';
import { DeleteButton } from '../department/DeleteButton';
import { SERVICE_LINE_TEMPLATE } from './constants';
import { theme, useStyles } from './styles';
import { useOrg } from '../../../../hooks/useOrg';

const _ = require('lodash');

let mockedOrgId: string | null = null;

const ServiceLines = () => {
  const { loggedInUserData, organization } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);

      console.log({ adminState });
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const dispatch = useDispatch();
  const organizationId = loggedInUserData.mongoOrganizationId;
  const { refetch: refetchOrg } = useOrg(loggedInUserData.mongoOrganizationId);
  const { permissions } = usePermissions();

  const classes = useStyles();
  // const permissions: IPermission[] = [];

  const distinctOrgActions: IAction[] = [];
  const [serviceLineCreated, setServiceLineCreated] = useState(false);
  const [right, setRight] = useState(false);
  const [newServiceLine, setNewServiceLine] = useState(false);
  const [serviceLineToEdit, setServiceLineToEdit] = useState(null);

  const [newSubGroupName, setNewSubGroupName] = useState('');

  const [tempName, setTempName] = useState('');
  const [inconsistentPermissions, setInconsistentPermissions] = useState([]);
  const [usersToApplyPermissionChangeTo, setUsersToApplyPermissionChangeTo] =
    useState([]);
  const [fixedInconsistentPermissions, setFixedInconsistentPermissions] =
    useState(false);
  const [selectedAutoClearAction, setSelectedAutoClearAction] = useState(null);
  const [newClearActionAnchorEl, setNewClearActionAnchorEl] = useState(null);

  const [newAutoClearActionError, setNewAutoClearActionError] = useState(false);
  const [newAutoClearActionClearOnEnter, setNewAutoClearActionClearOnEnter] =
    useState(false);
  const [newAutoClearActionClearOnExit, setNewAutoClearActionClearOnExit] =
    useState(false);

  // constructor(props: ServiceLinesProps) {
  //   super(props);
  //   this.state = {
  //     serviceLineCreated: false,
  //     right: false,
  //     newServiceLine: false,
  //     serviceLineToEdit: null,
  //     serviceLineTemplate: {
  //       name: "",
  //       serviceLineSubGroups: [],
  //     },
  //     newSubGroupName: "",
  //     showNewSubGroupInput: false,
  //     hasModified: false,
  //     tempName: "",
  //     inconsistentPermissions: [],
  //     usersToApplyPermissionChangeTo: [],
  //     fixedInconsistentPermissions: false,
  //     newClearActionAnchorEl: null,
  //     selectedAutoClearAction: null,
  //     anchorEl: null,
  //     newAutoClearActionError: false,
  //     newAutoClearActionClearOnEnter: false,
  //     newAutoClearActionClearOnExit: false,
  //   };
  // }

  useEffect(() => {
    if (localStorage.getItem('lastMockedOrganizationId')) {
      mockedOrgId = localStorage.getItem('lastMockedOrganizationId');
      console.log('Mock orgId', mockedOrgId);
    }

    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('email');
    if (userEmail && token) {
      fetchLoggedInUser(userEmail, token, mockedOrgId);
      fetchDistinctOrgActions(mockedOrgId ? mockedOrgId : organization.id);
    }
  }, [loggedInUserData, organization?.id]);

  // componentDidMount() {
  // this.props.fetchOrganizations();

  // if (localStorage.getItem("lastMockedOrganizationId")) {
  //   mockedOrgId = localStorage.getItem("lastMockedOrganizationId");
  //   console.log("Mock orgId", mockedOrgId);
  // }

  // let token = localStorage.getItem("token");
  // let userEmail = localStorage.getItem("email");
  // if (userEmail && token) {
  //   this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
  //   this.props.fetchDistinctOrgActions(mockedOrgId ? mockedOrgId : this.props.organization.id);
  // }
  // }

  const onNewServiceLine = (serviceLine: any) => {
    console.log('new service line w/ template', serviceLine);
    setRight(true);
    setNewServiceLine(true);
    setServiceLineToEdit(serviceLine);
  };

  const onToggleDrawer = (input: {
    open: boolean;
    serviceLine: IServiceLine | null;
  }) => {
    const { open, serviceLine } = input;

    if (canDo(['Update Service Lines'], loggedInUserData)) {
      let serviceLineName: string = '';
      if (serviceLine) {
        serviceLineName = serviceLine.name;
      }

      setRight(open);
      // setHasModified(false);
      setServiceLineToEdit(serviceLine);
      setNewServiceLine(false);
      setTempName(open ? serviceLineName : '');
      setNewSubGroupName('');
    }
  };

  const onHandleServiceLineNameChange = (input: {
    value: string;
    name: string;
  }) => {
    const { value, name } = input;

    // console.log("Service line to edit", serviceLineToEdit);

    setServiceLineToEdit({
      ...serviceLineToEdit,
      [name]: value,
    });
  };

  const onHandleSubGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    // console.log("Service line to edit", serviceLineToEdit);

    setNewSubGroupName(newValue);
  };

  const onFetchInconsistentPermissions = () => {
    let token = localStorage.getItem('token');

    let orgId = null;
    if (mockedOrgId) {
      orgId = mockedOrgId;
    } else {
      orgId = organization.id;
    }

    fetch(API.REACT_APP_API_INCONSISTENTPERMISSIONS + orgId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('response!');
        return response.json();
      })
      .then(data => {
        console.log('Inconsistent Permissions', data);
        if (data.data.length > 0) {
          setInconsistentPermissions(data.data);
        }
      })
      .catch(err => {
        console.log(err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onHandleSubmit = () => {
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('email');

    // console.log("handle submit!", serviceLineToEdit);
    //TODO: Make field checks to prevent empty service lines being saved.

    let orgServiceLinesToEdit = [...organization.serviceLines];
    if (newServiceLine) {
      // New Service line
      // console.log("Create new Service Line");
      orgServiceLinesToEdit.push(serviceLineToEdit);
      let modifiedOrg = {
        ...organization,
        serviceLines: orgServiceLinesToEdit,
      };
      console.log('payload', modifiedOrg);
      let payload = JSON.stringify(modifiedOrg);

      fetch(API.REACT_APP_API_UPDATESERVICELINES, {
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(async data => {
          // console.log("Success!", data);
          if (userEmail && token) {
            fetchLoggedInUser(userEmail, token, mockedOrgId);

            // fetch loggedinUser
          }

          const updatedOrg = await refetchOrg(organizationId);
          dispatch(setOrganization(updatedOrg));

          setRight(false);
          setServiceLineToEdit(null);
          setServiceLineCreated(true);

          onFetchInconsistentPermissions();

          setTimeout(() => {
            setServiceLineCreated(false);
          }, 5000);
        })
        .catch(err => console.log('Err', err));
    } else {
      // Update existing service line
      // console.log("Update existing Service Line", serviceLineToEdit);
      orgServiceLinesToEdit.forEach((serviceLine, i) => {
        if (serviceLine.id === serviceLineToEdit.id) {
          orgServiceLinesToEdit[i] = serviceLineToEdit;
        }
      });
      let modifiedOrg = {
        ...organization,
        serviceLines: orgServiceLinesToEdit,
      };
      console.log('payload', modifiedOrg);
      let payload = JSON.stringify(modifiedOrg);

      fetch(API.REACT_APP_API_UPDATESERVICELINES, {
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(async data => {
          // console.log("Success!", data);
          if (userEmail && token) {
            fetchLoggedInUser(userEmail, token, mockedOrgId);
          }

          const updatedOrg = await refetchOrg(organizationId);
          dispatch(setOrganization(updatedOrg));

          setRight(false);
          setServiceLineToEdit(null);
          setServiceLineCreated(true);

          // this.setState({
          //   right: false,
          //   serviceLineToEdit: null,
          //   serviceLineCreated: true,
          // });

          onFetchInconsistentPermissions();

          setTimeout(() => {
            setServiceLineCreated(false);
          }, 5000);
        })
        .catch(err => {
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
          console.log('Err', err);
        });
    }
  };

  const onAddSubGroup = (autoSave: boolean) => {
    if (newSubGroupName.length > 0) {
      let newSubGroup: any = { name: newSubGroupName, starterPermissions: [] };

      permissions.forEach((permission: IPermission) => {
        // Automatically add Flowstation permissions
        if (permission.permissionGroup === ' General Users - Flowstation') {
          let alreadyExists = false;
          newSubGroup.starterPermissions.forEach(
            (starterPermission: IPermission) => {
              if (
                starterPermission.permissionName === permission.permissionName
              ) {
                alreadyExists = true;
              }
            }
          );
          if (!alreadyExists) {
            newSubGroup.starterPermissions.push(permission);
          }
        }
      });

      console.log('new sub group', newSubGroup);

      let existingSubGroups = [...serviceLineToEdit.serviceLineSubGroups];
      existingSubGroups.push(newSubGroup);
      console.log('check', existingSubGroups);

      new Promise((resolve, reject) => {
        setServiceLineToEdit({
          ...serviceLineToEdit,
          serviceLineSubGroups: existingSubGroups,
        });
        resolve(null);
      }).then(() => {
        if (autoSave) {
          onHandleSubmit();
        }
      });
    } else {
      console.log('Missing name input');
    }
  };

  const onStartSubmit = () => {
    if (newSubGroupName.length > 0) {
      onAddSubGroup(true);
    } else {
      onHandleSubmit();
    }
  };

  const onDeleteSubGroup = (subGroupToDelete: IServiceSubGroup) => {
    // console.log("delete", subGroupToDelete);
    let existingSubGroups = [...serviceLineToEdit.serviceLineSubGroups];
    let updatedSubGroups = existingSubGroups.filter(subGroup => {
      return subGroup.name !== subGroupToDelete.name;
    });
    // console.log("updated", updatedSubGroups);

    setServiceLineToEdit({
      ...serviceLineToEdit,
      serviceLineSubGroups: updatedSubGroups,
    });
  };

  const onDeleteServiceLine = () => {
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('email');

    console.log('Delete service line', serviceLineToEdit);
    //TODO: Make field checks to prevent empty service lines being saved.
    let orgServiceLinesToEdit = [...organization.serviceLines];
    let updatedServiceLines = orgServiceLinesToEdit.filter(serviceLine => {
      return serviceLineToEdit.id !== serviceLine.id;
    });

    let modifiedOrg = {
      ...organization,
      serviceLines: updatedServiceLines,
    };
    // console.log("payload", modifiedOrg);
    let payload = JSON.stringify(modifiedOrg);

    fetch(API.REACT_APP_API_UPDATESERVICELINES, {
      method: 'PUT',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(async data => {
        // console.log("Success!", data);
        if (userEmail && token) {
          fetchLoggedInUser(userEmail, token, mockedOrgId);
        }

        const updatedOrg = await refetchOrg(organizationId);
        dispatch(setOrganization(updatedOrg));

        setRight(false);
        setServiceLineToEdit(null);
      })
      .catch(err => {
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
        console.log('Err', err);
      });
  };

  const onDetermineCheckAll = (
    groupName: string,
    groupedPermissions: any,
    subGroup: IServiceSubGroup
  ) => {
    let total: number = 0;
    let checked: number = 0;

    if (groupName && groupedPermissions) {
      groupedPermissions[groupName].forEach(
        (groupedPermission: IPermission) => {
          if (
            _.some(loggedInUserData.userPermissions, [
              'permissionName',
              groupedPermission.permissionName,
            ]) ||
            canDo(['SyncAdmin'], loggedInUserData)
          ) {
            total++;
            // console.log("Sub", subGroup);
            if (
              _.some(subGroup.starterPermissions, [
                'permissionName',
                groupedPermission.permissionName,
              ])
            ) {
              checked++;
            }
          }
        }
      );
    }
    if (checked < total) {
      return true;
    } else {
      return false;
    }
  };

  const onCheckAllPermission = (
    permissionGroup: string,
    checkAll: boolean,
    subGroupToEdit: { name: string }
  ) => {
    console.log('permissionGroup', permissionGroup); // General Users - Flowstation
    let currentServiceLineToEdit = { ...serviceLineToEdit };

    currentServiceLineToEdit.serviceLineSubGroups.forEach(
      (subGroup: IServiceSubGroup, i: number) => {
        if (subGroup.name === subGroupToEdit.name) {
          permissions.forEach((permission: IPermission) => {
            if (permission.permissionGroup === permissionGroup) {
              if (checkAll) {
                let alreadyExists = false;
                subGroup.starterPermissions.forEach(
                  (starterPermission: IPermission) => {
                    if (
                      starterPermission.permissionName ===
                      permission.permissionName
                    ) {
                      alreadyExists = true;
                    }
                  }
                );
                if (!alreadyExists) {
                  subGroup.starterPermissions.push(permission);
                }
              } else {
                _.remove(subGroup.starterPermissions, [
                  'permissionName',
                  permission.permissionName,
                ]);
              }
            }
          });
        }
      }
    );

    console.log('check it', currentServiceLineToEdit);

    setServiceLineToEdit(currentServiceLineToEdit);
  };

  const onTogglePermission = (
    permission: IPermission,
    serviceLineSubGroup: IServiceSubGroup
  ) => {
    // console.log("toggle perm!", permission);
    // console.log("subgroup to edit", serviceLineToEdit.serviceLineSubGroups);

    let currentServiceLineToEdit = { ...serviceLineToEdit };

    console.log('service line to edit', serviceLineToEdit.serviceLineSubGroups);

    console.log('check', serviceLineSubGroup);

    currentServiceLineToEdit.serviceLineSubGroups.forEach(
      (subGroup: IServiceSubGroup) => {
        if (subGroup.name === serviceLineSubGroup.name) {
          let permissionsToEdit = [...subGroup.starterPermissions];
          console.log('permissionsToEdit', permissionsToEdit);
          if (
            _.find(permissionsToEdit, [
              'permissionName',
              permission.permissionName,
            ])
          ) {
            _.remove(permissionsToEdit, [
              'permissionName',
              permission.permissionName,
            ]);
          } else {
            permissionsToEdit.push(permission);
          }
          subGroup.starterPermissions = permissionsToEdit;
        }
      }
    );

    setServiceLineToEdit(currentServiceLineToEdit);
  };

  const onApplyToSelectedUsers = () => {
    console.log('check', usersToApplyPermissionChangeTo);
    if (usersToApplyPermissionChangeTo.length > 0) {
      let payload = JSON.stringify(usersToApplyPermissionChangeTo);
      let token = localStorage.getItem('token');

      fetch(API.REACT_APP_API_FIXINCONSISTENTPERMISSIONS, {
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          console.log('RES', res);
          return res.json();
        })
        .then(data => {
          console.log('Success!', data);

          setUsersToApplyPermissionChangeTo([]);
          setInconsistentPermissions([]);
          setFixedInconsistentPermissions(true);

          setTimeout(() => {
            setFixedInconsistentPermissions(false);
          }, 5000);
        });
      console.log('Payload sent', payload);
    }
  };

  const onHandleAutoClearActionPopper = (event: any) => {
    setNewClearActionAnchorEl(event.currentTarget);
  };

  const onCloseNewClearActionPopper = () => {
    setNewClearActionAnchorEl(null);
  };

  const onAssignAutoClearActionIcon = (action: IAction) => {
    onCloseNewClearActionPopper();

    setSelectedAutoClearAction(action);
    setNewAutoClearActionError(false);
  };

  const onAddAutoClearAction = (serviceLineSubGroup: any) => {
    if (selectedAutoClearAction) {
      let currentServiceLineToEdit = { ...serviceLineToEdit };

      currentServiceLineToEdit.serviceLineSubGroups.forEach((subGroup: any) => {
        if (subGroup.id === serviceLineSubGroup.id) {
          subGroup.autoClearRoleActions.push({
            clearOnEnter: newAutoClearActionClearOnEnter,
            clearOnExit: newAutoClearActionClearOnExit,
            actionId: selectedAutoClearAction.id,
          });
        }
      });

      setServiceLineToEdit(currentServiceLineToEdit);
      setNewAutoClearActionClearOnEnter(false);
      setNewAutoClearActionClearOnExit(false);
      setSelectedAutoClearAction(null);
    } else {
      setNewAutoClearActionError(true);
    }
  };

  const onDeleteAutoClearAction = (actionId: any, serviceLineSubGroup: any) => {
    // console.log("ACTION", actionId);
    let currentServiceLineToEdit = { ...serviceLineToEdit };

    currentServiceLineToEdit.serviceLineSubGroups.forEach((subGroup: any) => {
      // console.log("sub group check", subGroup);
      if (subGroup.id === serviceLineSubGroup.id) {
        _.remove(subGroup.autoClearRoleActions, ['actionId', actionId]);
      }
    });

    setServiceLineToEdit(currentServiceLineToEdit);
  };

  const onToggleAutoClearRoleActionOnEnter = (
    actionId: any,
    serviceLineSubGroup: any
  ) => {
    let currentServiceLineToEdit = { ...serviceLineToEdit };

    currentServiceLineToEdit.serviceLineSubGroups.forEach((subGroup: any) => {
      if (subGroup.id === serviceLineSubGroup.id) {
        subGroup.autoClearRoleActions.forEach((action: any) => {
          if (action.actionId === actionId) {
            action.clearOnEnter = !action.clearOnEnter;
          }
        });
      }
    });

    setServiceLineToEdit(serviceLineToEdit);
  };

  const onToggleAutoClearRoleActionOnExit = (
    actionId: any,
    serviceLineSubGroup: any
  ) => {
    let currentServiceLineToEdit = { ...serviceLineToEdit };

    currentServiceLineToEdit.serviceLineSubGroups.forEach((subGroup: any) => {
      if (subGroup.id === serviceLineSubGroup.id) {
        subGroup.autoClearRoleActions.forEach((action: any) => {
          if (action.actionId === actionId) {
            action.clearOnExit = !action.clearOnExit;
          }
        });
      }
    });

    setServiceLineToEdit(currentServiceLineToEdit);
  };

  let groupedPermissions: any = {};
  let permissionGroups: any = [];

  // console.log("check", mockedOrgId);

  function groupPermissions(arr: IPermission[], property: string) {
    return arr.reduce((memo: any, x: any) => {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  if (permissions?.length > 0) {
    // console.log("permissions data", this.props.permissions);
    groupedPermissions = groupPermissions(permissions, 'permissionGroup');
    permissions.forEach((permission: IPermission) => {
      if (!permissionGroups.includes(permission.permissionGroup)) {
        permissionGroups.push(permission.permissionGroup);
      }
    });
  }

  let alphabetizedServiceLineSubGroups: any = [];
  if (serviceLineToEdit) {
    alphabetizedServiceLineSubGroups =
      serviceLineToEdit.serviceLineSubGroups.sort((a: any, b: any) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
  }

  // console.log("groupedPermissions", groupedPermissions);
  // console.log("permissionGroups", permissionGroups);

  return (
    <div>
      {canDo(['View Service Lines'], loggedInUserData) ? (
        <MuiThemeProvider theme={theme}>
          {organization ? <div></div> : null}
          <Typography variant="h4" gutterBottom component="h2">
            {organization.serviceLineLabel}
            {canDo(['Create Service Lines'], loggedInUserData) ? (
              <>
                <Tooltip
                  title="Add"
                  aria-label="Add"
                  onClick={() => onNewServiceLine(SERVICE_LINE_TEMPLATE)}
                >
                  <Fab className={classes.newServiceLineButton}>
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </>
            ) : null}
          </Typography>
          <Dialog
            open={inconsistentPermissions.length > 0 ? true : false}
            onClose={() => {
              setInconsistentPermissions([]);
              setUsersToApplyPermissionChangeTo([]);
            }}
            BackdropProps={{
              classes: {
                root: classes.dialogStyle,
              },
            }}
          >
            <DialogTitle style={{ textAlign: 'center' }}>
              Inconsistent Permissions
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                The following permissions are inconsistent with the permissions
                of the {organization.serviceLineLabel} you are editing. Select
                the users to override their permissions.
              </DialogContentText>
              <br />
              {inconsistentPermissions.map((permission: any, i: number) => {
                return (
                  <div key={i}>
                    <Checkbox
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={
                        usersToApplyPermissionChangeTo.includes(
                          permission.userId
                        )
                          ? true
                          : false
                      }
                      onClick={() => {
                        let currentUsersToApplyPermissionChangeTo = [
                          ...usersToApplyPermissionChangeTo,
                        ];
                        if (
                          currentUsersToApplyPermissionChangeTo.includes(
                            permission.userId
                          )
                        ) {
                          _.remove(
                            currentUsersToApplyPermissionChangeTo,
                            (userId: string) => {
                              return userId === permission.userId;
                            }
                          );
                        } else {
                          currentUsersToApplyPermissionChangeTo.push(
                            permission.userId
                          );
                        }

                        setUsersToApplyPermissionChangeTo(
                          currentUsersToApplyPermissionChangeTo
                        );
                      }}
                    />
                    <strong>{permission.fullName}</strong> |{' '}
                    {permission.serviceLineName} -{' '}
                    {permission.serviceLineSubGroupName}
                    <br />
                    <div style={{ marginLeft: 15 }}>
                      {permission.permissionsToAddToUser.length > 0 ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              Permissions to add (
                              {permission.permissionsToAddToUser.length})
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>
                              {permission.permissionsToAddToUser.map(
                                (permissionToAdd: any) => {
                                  return (
                                    <div
                                      key={permissionToAdd.id}
                                      style={{ color: '#50b848' }}
                                    >
                                      {permissionToAdd.permissionName}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}

                      {permission.permissionsToRemoveFromUser.length > 0 ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              Permissions to remove (
                              {permission.permissionsToRemoveFromUser.length})
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>
                              {permission.permissionsToRemoveFromUser.map(
                                (permissionToRemove: any) => {
                                  return (
                                    <div
                                      key={permissionToRemove.id}
                                      style={{ color: 'red' }}
                                    >
                                      {permissionToRemove.permissionName}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ float: 'right' }}
                onClick={onApplyToSelectedUsers}
              >
                Apply to Selected Users
              </Button>
            </DialogContent>
          </Dialog>
          <Drawer
            anchor="right"
            open={right}
            onClose={() => onToggleDrawer({ open: false, serviceLine: null })}
          >
            {serviceLineToEdit ? (
              <div className={classes.drawer}>
                <div
                  className={classes.closeButton}
                  onClick={() =>
                    onToggleDrawer({ open: false, serviceLine: null })
                  }
                >
                  <CloseButton />
                </div>
                {newServiceLine ? (
                  <h2>New {organization.serviceLineLabel}</h2>
                ) : (
                  <h2>Editing {tempName} </h2>
                )}

                <br />
                <TextField
                  style={{ width: '100%' }}
                  required
                  label={organization.serviceLineLabel + ' Name'}
                  className={classes.textField}
                  value={serviceLineToEdit.name}
                  onChange={event =>
                    onHandleServiceLineNameChange({
                      name: 'name',
                      value: event.target.value,
                    })
                  }
                  margin="normal"
                />
                <br />

                {/* {console.log("EDIT THIS", serviceLineToEdit)} */}

                {serviceLineToEdit.serviceLineSubGroups.length > 0 ? (
                  <div>
                    <FormControl className={classes.textField} margin="normal">
                      <div className={classes.badgesTitle}>
                        {organization.serviceLineSubGroupLabel}:
                      </div>
                    </FormControl>
                    <br />
                    {alphabetizedServiceLineSubGroups.map(
                      (
                        serviceLineSubGroup: IServiceSubGroup,
                        index: number
                      ) => (
                        <div key={index} style={{ marginBottom: 10 }}>
                          <Button
                            className={classes.deleteButton}
                            onClick={() =>
                              onDeleteSubGroup(serviceLineSubGroup)
                            }
                          >
                            x
                          </Button>{' '}
                          <strong>{serviceLineSubGroup.name}</strong>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                {serviceLineSubGroup.name} Permissions (
                                {serviceLineSubGroup.starterPermissions.length})
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                                {permissionGroups.map(
                                  (groupName: string, index: number) => (
                                    <div key={index}>
                                      {/* Header */}
                                      <div>
                                        {groupName === 'SyncAdmin' ||
                                        groupName === 'Tablets' ? (
                                          <div>
                                            {canDo(
                                              ['SyncAdmin'],
                                              loggedInUserData
                                            ) ? (
                                              <div>
                                                <strong>{groupName} </strong>
                                              </div>
                                            ) : null}
                                          </div>
                                        ) : (
                                          <div>
                                            <strong>{groupName} </strong>(
                                            {onDetermineCheckAll(
                                              groupName,
                                              groupedPermissions,
                                              serviceLineSubGroup
                                            ) ? (
                                              <span
                                                onClick={() =>
                                                  onCheckAllPermission(
                                                    groupName,
                                                    true,
                                                    serviceLineSubGroup
                                                  )
                                                }
                                                style={{
                                                  textDecoration: 'underline',
                                                  cursor: 'pointer',
                                                }}
                                              >
                                                check all
                                              </span>
                                            ) : (
                                              <span
                                                onClick={() =>
                                                  onCheckAllPermission(
                                                    groupName,
                                                    false,
                                                    serviceLineSubGroup
                                                  )
                                                }
                                                style={{
                                                  textDecoration: 'underline',
                                                  cursor: 'pointer',
                                                }}
                                              >
                                                un-check all
                                              </span>
                                            )}
                                            )
                                          </div>
                                        )}
                                      </div>

                                      {/* Checkboxes */}
                                      {groupedPermissions[groupName].map(
                                        (
                                          groupedPermission: IPermission,
                                          i: number
                                        ) => (
                                          <div key={i}>
                                            <div>
                                              {groupName === 'SyncAdmin' ||
                                              groupName === 'Tablets' ? (
                                                <div>
                                                  {canDo(
                                                    ['SyncAdmin'],
                                                    loggedInUserData
                                                  ) ? (
                                                    <div>
                                                      <Tooltip
                                                        title={
                                                          groupedPermission.permissionDescription
                                                        }
                                                        placement="right"
                                                      >
                                                        <div
                                                          // onClick={() => this.togglePermission(groupedPermission)}
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          <Checkbox
                                                            classes={{
                                                              root: classes.checkBox,
                                                              checked:
                                                                classes.checked,
                                                            }}
                                                          />
                                                          {
                                                            groupedPermission.permissionName
                                                          }
                                                        </div>
                                                      </Tooltip>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <div>
                                                  <Tooltip
                                                    title={
                                                      groupedPermission.permissionDescription
                                                    }
                                                    placement="right"
                                                  >
                                                    <div
                                                      onClick={() =>
                                                        onTogglePermission(
                                                          groupedPermission,
                                                          serviceLineSubGroup
                                                        )
                                                      }
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <Checkbox
                                                        classes={{
                                                          root: classes.checkBox,
                                                          checked:
                                                            classes.checked,
                                                        }}
                                                        checked={_.some(
                                                          serviceLineSubGroup.starterPermissions,
                                                          [
                                                            'permissionName',
                                                            groupedPermission.permissionName,
                                                          ]
                                                        )}
                                                      />
                                                      {
                                                        groupedPermission.permissionName
                                                      }
                                                    </div>
                                                  </Tooltip>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                {serviceLineSubGroup.name} Auto Clear Role
                                Actions
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                                {/* {console.log("Distinct Org Actions", distinctOrgActions)} */}
                                {serviceLineSubGroup.autoClearRoleActions?.map(
                                  (
                                    autoClearRoleAction: {
                                      clearOnEnter: boolean;
                                      clearOnExit: boolean;
                                      actionId: string;
                                    },
                                    i: any
                                  ) => (
                                    <div key={i}>
                                      <Grid
                                        container
                                        style={{ alignItems: 'center' }}
                                      >
                                        <div>
                                          {distinctOrgActions.map(
                                            (action: IAction, a: any) => {
                                              if (
                                                action.id ===
                                                autoClearRoleAction.actionId
                                              ) {
                                                return (
                                                  <div key={a}>
                                                    <img
                                                      alt=""
                                                      src={
                                                        action.actionImageUrl
                                                      }
                                                      style={{
                                                        width: 50,
                                                        height: 50,
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }
                                          )}
                                        </div>
                                        <div>
                                          <div>
                                            <Checkbox
                                              classes={{
                                                root: classes.checkBox,
                                                checked: classes.checked,
                                              }}
                                              checked={
                                                autoClearRoleAction.clearOnEnter
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                onToggleAutoClearRoleActionOnEnter(
                                                  autoClearRoleAction.actionId,
                                                  serviceLineSubGroup
                                                )
                                              }
                                            />
                                            Clear on Enter
                                          </div>
                                          <div>
                                            <Checkbox
                                              classes={{
                                                root: classes.checkBox,
                                                checked: classes.checked,
                                              }}
                                              checked={
                                                autoClearRoleAction.clearOnExit
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                onToggleAutoClearRoleActionOnExit(
                                                  autoClearRoleAction.actionId,
                                                  serviceLineSubGroup
                                                )
                                              }
                                            />
                                            Clear on Exit
                                          </div>
                                        </div>
                                      </Grid>
                                      <DeleteButton
                                        onClick={() =>
                                          onDeleteAutoClearAction(
                                            autoClearRoleAction.actionId,
                                            serviceLineSubGroup
                                          )
                                        }
                                      >
                                        Delete Action
                                      </DeleteButton>
                                    </div>
                                  )
                                )}

                                <br />
                                <Typography variant="h5">
                                  New Auto Clear Action
                                </Typography>
                                <div className={classes.imageSelectContainer}>
                                  <br />
                                  <Grid
                                    container
                                    style={{ alignItems: 'center' }}
                                  >
                                    <Tooltip
                                      title="Choose an icon to automatically clear"
                                      placement="right"
                                    >
                                      <div>
                                        {selectedAutoClearAction ? (
                                          <img
                                            onClick={
                                              onHandleAutoClearActionPopper
                                            }
                                            src={
                                              selectedAutoClearAction.actionImageUrl
                                            }
                                            className={
                                              classes.selectedScheduledActionImage
                                            }
                                            alt="#"
                                          />
                                        ) : (
                                          <div>
                                            <div
                                              className={
                                                newAutoClearActionError
                                                  ? classes.errorAddIconSquare
                                                  : classes.addIconSquare
                                              }
                                              onClick={
                                                onHandleAutoClearActionPopper
                                              }
                                            >
                                              <AddIcon
                                                className={classes.addIconStyle}
                                                style={{ fontSize: 35 }}
                                              />
                                            </div>
                                            {newAutoClearActionError ? (
                                              <div
                                                className={
                                                  classes.scheduledActionErrorMessage
                                                }
                                              >
                                                You must select an action first!
                                              </div>
                                            ) : (
                                              <div />
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Tooltip>

                                    <div>
                                      <div>
                                        <Checkbox
                                          classes={{
                                            root: classes.checkBox,
                                            checked: classes.checked,
                                          }}
                                          checked={
                                            newAutoClearActionClearOnEnter
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            setNewAutoClearActionClearOnEnter(
                                              !newAutoClearActionClearOnEnter
                                            )
                                          }
                                        />
                                        Clear on Enter
                                      </div>
                                      <div>
                                        <Checkbox
                                          classes={{
                                            root: classes.checkBox,
                                            checked: classes.checked,
                                          }}
                                          checked={
                                            newAutoClearActionClearOnExit
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            setNewAutoClearActionClearOnExit(
                                              !newAutoClearActionClearOnExit
                                            )
                                          }
                                        />
                                        Clear on Exit
                                      </div>
                                    </div>
                                  </Grid>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      onAddAutoClearAction(serviceLineSubGroup)
                                    }
                                  >
                                    Add Auto Clear Action
                                  </Button>

                                  <Popover
                                    open={Boolean(newClearActionAnchorEl)}
                                    anchorEl={newClearActionAnchorEl}
                                    onClose={onCloseNewClearActionPopper}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <div
                                      className={
                                        classes.scheduleActionImageContainer
                                      }
                                    >
                                      {distinctOrgActions.map(
                                        (imageAction: IAction, i: any) => (
                                          <img
                                            key={i}
                                            src={imageAction.actionImageUrl}
                                            className={
                                              classes.selectedScheduledActionImage
                                            }
                                            alt=""
                                            onClick={() =>
                                              onAssignAutoClearActionIcon(
                                                imageAction
                                              )
                                            }
                                          />
                                        )
                                      )}
                                    </div>
                                  </Popover>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )
                    )}
                  </div>
                ) : null}

                <TextField
                  style={{ width: '100%', marginTop: 40 }}
                  label={
                    'New ' + organization.serviceLineSubGroupLabel + ' Name'
                  }
                  placeholder=""
                  className={classes.textField}
                  value={newSubGroupName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onHandleSubGroupNameChange(event)
                  }
                  margin="normal"
                />
                <br />
                <div>
                  <Button
                    disabled={newSubGroupName.length === 0}
                    onClick={() => onAddSubGroup(false)}
                  >
                    Add
                  </Button>
                </div>

                <br />

                {!newServiceLine ? (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Advanced Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Button
                        onClick={() => onDeleteServiceLine()}
                        style={{ backgroundColor: 'red', color: 'white' }}
                        variant="contained"
                      >
                        Delete {organization.serviceLineLabel}
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                <br />
                <Button
                  className={classes.submitButton}
                  onClick={() => onStartSubmit()}
                  color="primary"
                >
                  Save
                </Button>
                <br />
              </div>
            ) : null}
          </Drawer>

          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell>
                    <div>{organization.serviceLineLabel}</div>
                  </TableCell>
                  <TableCell>
                    <div>{organization.serviceLineSubGroupLabel}</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organization.serviceLines.map(serviceLine => {
                  return (
                    <TableRow
                      key={serviceLine.id}
                      style={{ cursor: 'pointer' }}
                      className={classes.tableRow}
                      onClick={() =>
                        onToggleDrawer({ open: true, serviceLine })
                      }
                    >
                      <TableCell className={classes.tableCell}>
                        {serviceLine.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {serviceLine.serviceLineSubGroups.map(
                          (serviceLineSubGroup: IServiceSubGroup) => {
                            return (
                              <li key={serviceLineSubGroup.id}>
                                {serviceLineSubGroup.name}
                                <br />
                              </li>
                            );
                          }
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>

          {serviceLineCreated ? (
            <SuccessSnack successSnack={true} successMessage="Success!" />
          ) : (
            <div />
          )}
          {fixedInconsistentPermissions ? (
            <SuccessSnack
              successSnack={true}
              successMessage="Fixed Inconsistent Permissions!"
            />
          ) : (
            <div />
          )}
        </MuiThemeProvider>
      ) : null}
    </div>
  );
};

export default ServiceLines;
