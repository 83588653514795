import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IApp, IDevice } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useDisplays = (orgId: string): {
  devices: IDevice[];
  isLoading: boolean;
  error: string | null;
  actionTypes: any[];
  apps: IApp[];
  refetch: () => Promise<IDevice[]>;
} => {
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [apps, setApps] = useState<IApp[]>([]);
  const [actionTypes, setActionTypes] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchDevices = useCallback(async (): Promise<IDevice[]> => {
    setIsLoading(true);
    setError(null);

    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETORGDEVICEDETAILS + orgId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    return axios
      .get(URL, headers)
      .then(response => {
        setIsLoading(false);
        const devices: IDevice[] = response.data.devices ?? [];
        setDevices(devices);
        const apps: IApp[] = response?.data?.apps ?? [];
        setApps(apps);
        const actionTypes: any[] = response?.data?.actionTypes ?? [];
        setActionTypes(actionTypes);

        return devices;
      })
      .catch(err => {
        console.log('Error: ', err);
        setIsLoading(false);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        return [] as IDevice[];
      });
      // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchDevices();
    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IDevice[]> => {
    return fetchDevices();
    // eslint-disable-next-line
  }, []);

  return {
    isLoading,
    error,
    refetch,
    devices,
    actionTypes,
    apps,
  };
};
