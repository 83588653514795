import React, { FC, useEffect, useState, ChangeEvent, ReactNode } from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// Utils
import { sortBy } from 'lodash';

// Interfaces
import {
  IDepartment,
  IDevice,
  IOrganization,
  ISite,
} from '../../../../../interfaces/interfaces';

// Hooks
import { useDepartments } from '../../../../../hooks/department/useDepartments';
import { useDepartment } from '../../../../../hooks/department/useDepartment';
import { useDeviceDetail } from '../../../../../hooks/useDeviceDetail';
import { useOrganizations } from '../../../../../hooks/useOrganizations';
import { useDevice } from '../../../../../hooks/useDevice';
import { useSites } from '../../../../../hooks/useSites';
import { useDeviceLocations } from '../../../../../hooks/useDeviceLocations';
import { CustomSlider, useStyles } from './styles';
import useToast from '../../../../../hooks/useToast';

// Components
import FlowStationUrlGenerator from './urlGenerator/FlowStationUrlGenerator';

interface TProps {
  deviceToEdit: IDevice;
  onDeviceUpdate: (field: keyof IDevice, value: any) => void;
}

const DeviceEditForm: FC<TProps> = ({ deviceToEdit, onDeviceUpdate }) => {
  const classes = useStyles();

  // Form UI State
  const [showDeptDropdown, setShowDeptDropdown] = useState(false);
  const [showNurseCallDialog, setShowNurseCallDialog] = useState(false);

  // Hooks
  const { refetch: refetchDevice } = useDeviceDetail({
    deviceId: deviceToEdit.deviceId,
    onCompleted: device => {
      if (device) {
        onDeviceUpdate('mongoDeptId', device?.mongoDeptId);
      }
    },
  });

  const { update: updateDevice } = useDevice();
  const { sites, refreshSites } = useSites(deviceToEdit.orgId);

  const { deviceLocations } = useDeviceLocations();
  const { department } = useDepartment({
    departmentId: deviceToEdit.mongoDeptId,
  });

  const { departments, refetch: refetchDepartments } = useDepartments({
    orgId: deviceToEdit.orgId,
  });

  // Assign to Room Data Fetching and State - Preserved and added in at saving
  const { organizations } = useOrganizations();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>(
    deviceToEdit.orgId
  );
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string>(
    deviceToEdit.mongoDeptId
  );
  const [selectedRoomId, setSelectedRoomId] = useState<string>(
    deviceToEdit.mongoRoomId
  );

  // RTLS Override State - Preserved and added in at saving
  const [
    selectedRtlsOverrideDepartmentId,
    setSelectedRtlsOverrideDepartmentId,
  ] = useState('');
  const [selectedRtlsOverrideRoomId, setSelectedRtlsOverrideRoomId] =
    useState('');

  let alphabetizedOrgs = sortBy(organizations, 'orgName');
  let alphabetizedDeviceLocations = sortBy(deviceLocations, 'deviceLocation');
  let alphabetizedSites = sortBy(sites, 'siteName');

  const onStartNewRoomAssignment = () => {
    // Show dropdown.
    setShowDeptDropdown(true);

    // Get last set room details from local storage to speed up creation process for entire sites.
    // let lastSetRoomDetails = JSON.parse(
    //   localStorage.getItem('lastSetRoomDetails') || '{}'
    // );
    // if (
    //   lastSetRoomDetails.lastDeviceSelectedOrganization &&
    //   lastSetRoomDetails.lastDeviceSelectedDepartment &&
    //   lastSetRoomDetails.lastDeviceSelectedRoom
    // ) {
    //   console.log('Set from last set room details', lastSetRoomDetails);
    //   refetchDepartments(lastSetRoomDetails.lastDeviceSelectedOrganization);
    //   refreshSites(lastSetRoomDetails.lastDeviceSelectedOrganization);

    //   onDeviceUpdate(
    //     'orgId',
    //     lastSetRoomDetails.lastDeviceSelectedOrganization
    //   );
    //   onDeviceUpdate('mongoRoomId', lastSetRoomDetails.lastDeviceSelectedRoom);

    //   setSelectedOrganizationId(
    //     lastSetRoomDetails.lastDeviceSelectedOrganization
    //   );
    //   setSelectedDepartmentId(lastSetRoomDetails.lastDeviceSelectedDepartment);
    //   setSelectedRoomId(lastSetRoomDetails.lastDeviceSelectedRoom);
    // } else if (localStorage.getItem('lastMockedOrganizationId')) {
    //   console.log('Set from last mocked org id');
    //   let mockedOrgId = localStorage.getItem('lastMockedOrganizationId');
    //   refetchDepartments(mockedOrgId);
    //   refreshSites(mockedOrgId);

    //   setSelectedOrganizationId(mockedOrgId);
    //   setSelectedDepartmentId('');
    //   setSelectedRoomId('');
    // }
  };

  const { presentToast } = useToast();

  const onHandleSubmit = async () => {
    console.log('onHandleSubmit', { deviceToEdit });

    let payload = {
      ...deviceToEdit,
      mongoAppId: deviceToEdit.mongoAppId,
      mongoLastLoggedAppId: deviceToEdit.mongoLastLoggedAppId,
      mongoLastLoggedWatchdogAppId: deviceToEdit.mongoLastLoggedWatchdogAppId,
      mongoWatchdogAppId: deviceToEdit.mongoWatchdogAppId,
      ...(selectedOrganizationId && { orgId: selectedOrganizationId }),
      ...(selectedDepartmentId && { mongoDeptId: selectedDepartmentId }),
      ...(selectedRoomId && { mongoRoomId: selectedRoomId }),
    };

    console.log('onHandleSubmit', { payload });

    try {
      await updateDevice(payload);
      refetchDevice(deviceToEdit.deviceId);
      presentToast('success', 'Device updated successfully');
    } catch (err) {
      console.log('Error updating device ', err);
      presentToast('error', 'Error updating device');
    }
  };

  const onHandleVolumeOverrideCheckbox = () => {
    if (deviceToEdit.volumeOverride !== null) {
      onDeviceUpdate('volumeOverride', null);
    } else {
      onDeviceUpdate('volumeOverride', 0);
    }
  };

  const onSelectAction = (action: any) => {
    onDeviceUpdate('nurseCallActionId', action.id);
  };

  // Assign to Room Event Handlers
  const onSelectOrganization = (e: any) => {
    let selectedOrgId = e.target.value as string;
    console.log('Selecting orgId??', selectedOrgId);
    if (selectedOrgId) {
      setSelectedOrganizationId(selectedOrgId);
      refetchDepartments(selectedOrgId);
      refreshSites(selectedOrgId);

      setSelectedDepartmentId('');
      setSelectedRoomId('');
    }
  };

  const onSelectDepartment = (
    event: ChangeEvent<{ name?: string; value: unknown }>,
    child: ReactNode
  ) => {
    const selectedDeptId = event.target.value;
    if (typeof selectedDeptId === 'string') {
      console.log('Select dept', selectedDeptId);
      setSelectedDepartmentId(selectedDeptId);
      setSelectedRoomId('');
    }
  };

  const onSelectAssignedRoom = (
    event: ChangeEvent<{ name?: string; value: unknown }>,
    child: ReactNode
  ) => {
    const selectedRoomId = event.target.value;
    if (typeof selectedRoomId === 'string') {
      console.log('Select room', selectedRoomId);
      setSelectedRoomId(selectedRoomId);
    }
  };

  // RTLS Override Event Handlers
  const onSelectRtlsOverrideRoom = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const roomId = e.target.value as string;
    setSelectedRtlsOverrideRoomId(roomId);
    onDeviceUpdate('overrideRoomId', roomId);
  };

  const onSelectDepartmentForRtlsOverride = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    console.log('Select dept for override', e.target.value);
    const dept = e.target.value as string;
    setSelectedRtlsOverrideDepartmentId(dept);
    setSelectedRtlsOverrideRoomId(null);
  };

  useEffect(() => {
    if (deviceToEdit.overrideRoomId !== null) {
      const overrideRoom = alphabetizedDeviceLocations.find(
        room => room.roomId === deviceToEdit.overrideRoomId
      );

      if (overrideRoom) {
        setSelectedRtlsOverrideRoomId(overrideRoom.roomId);

        const overrideDepartment = departments.find(
          dept => dept.id === overrideRoom.deptId
        );

        if (overrideDepartment) {
          setSelectedRtlsOverrideDepartmentId(overrideDepartment.id);
        }
      }
    } else {
      setSelectedRtlsOverrideRoomId(null);
      setSelectedRtlsOverrideDepartmentId(null);
    }
  }, [deviceToEdit.overrideRoomId, alphabetizedDeviceLocations, departments]);

  return (
    <div>
      {!showDeptDropdown && (
        <Button
          color="primary"
          variant="contained"
          onClick={onStartNewRoomAssignment}
        >
          Assign to Room
        </Button>
      )}
      <div>
        {showDeptDropdown && (
          <FormControl className={classes.formControl}>
            <InputLabel id="Organization">Select Organization</InputLabel>
            <Select
              labelId="Organization"
              value={selectedOrganizationId ? selectedOrganizationId : ''}
              onChange={onSelectOrganization}
            >
              <MenuItem style={{ whiteSpace: 'normal' }} value="">
                Select Organization
              </MenuItem>
              {alphabetizedOrgs.map(
                (org: IOrganization, index: any) =>
                  org.isActive && (
                    <MenuItem
                      style={{ whiteSpace: 'normal' }}
                      key={org.id}
                      value={org.id}
                    >
                      {org.orgName}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        )}
      </div>

      {/* Select Department for room assignment*/}
      <div>
        {showDeptDropdown &&
          selectedOrganizationId &&
          departments.length > 0 && (
            <FormControl className={classes.formControl}>
              <InputLabel id="Organization">Select Department</InputLabel>
              <Select
                labelId="Department"
                value={selectedDepartmentId ? selectedDepartmentId : ''}
                onChange={onSelectDepartment}
              >
                <MenuItem style={{ whiteSpace: 'normal' }} value="">
                  Select Department
                </MenuItem>
                {departments.map(
                  (dept: IDepartment, index: any) =>
                    dept.isActive && (
                      <MenuItem
                        style={{ whiteSpace: 'normal' }}
                        key={dept.id}
                        value={dept.id}
                      >
                        {sites.map(
                          (site: ISite) =>
                            dept.mongoSiteId === site.id && site.siteName
                        )}{' '}
                        {dept.deptName}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          )}
      </div>

      {/* Select Room for room assignment */}
      <div>
        {showDeptDropdown && selectedDepartmentId && (
          <FormControl className={classes.formControl}>
            <InputLabel id="Room">Select Room</InputLabel>
            <Select
              labelId="Room"
              value={selectedRoomId ? selectedRoomId : ''}
              onChange={onSelectAssignedRoom}
            >
              <MenuItem value="" style={{ whiteSpace: 'normal' }}>
                Select Room
              </MenuItem>
              {alphabetizedDeviceLocations.map(
                (room: any, index: any) =>
                  room.deptId === selectedDepartmentId && (
                    <MenuItem
                      style={{ whiteSpace: 'normal' }}
                      key={room.roomId}
                      value={room.roomId}
                    >
                      {room.deviceLocation}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        )}
      </div>

      <br />

      {/* Volume Override */}
      <div
        onClick={onHandleVolumeOverrideCheckbox}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={deviceToEdit.volumeOverride !== null}
        />
        Volume Override
      </div>

      {/* VolumeSlider */}
      {deviceToEdit.volumeOverride !== null && (
        <div
          style={{
            marginLeft: '20px',
            border: '1px solid gray',
            padding: '20px',
          }}
        >
          <CustomSlider
            onChange={(e, newValue) => {
              onDeviceUpdate('volumeOverride', newValue);
            }}
            value={deviceToEdit.volumeOverride ?? 1}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            valueLabelDisplay="on"
            min={1}
            max={10}
          />
        </div>
      )}

      {/* Is Inside selector*/}
      <div
        onClick={() => {
          onDeviceUpdate('isInside', !deviceToEdit.isInside);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={deviceToEdit.isInside}
        />
        Is Inside
      </div>

      {/* Is Flowstation selector */}
      <div
        onClick={() => {
          onDeviceUpdate('isFs', !deviceToEdit.isFs);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={deviceToEdit.isFs}
        />
        Is Flowstation
      </div>

      {/* Generate FS URL */}
      {deviceToEdit.isFs && (
        <div>
          <br />

          {deviceToEdit.isFs && (
            <FlowStationUrlGenerator
              device={deviceToEdit}
              orgId={selectedOrganizationId}
            />
          )}
        </div>
      )}

      {/* Is Sanitizer */}
      <div
        onClick={() => {
          onDeviceUpdate('isSanitizer', !deviceToEdit.isSanitizer);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={deviceToEdit.isSanitizer}
        />
        Is Sanitizer
      </div>

      {/* Android or Raspi */}
      <div
        onClick={() => {
          onDeviceUpdate('isAndroidTablet', !deviceToEdit.isAndroidTablet);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={deviceToEdit.isAndroidTablet}
        />
        Android or Raspi
      </div>

      {/* Override RTLS Room */}
      <div
        onClick={() => {
          console.log('OVERRIDE RTLS ROOM');
          const newOverrideRoomId =
            deviceToEdit.overrideRoomId === null ? '' : null;
          onDeviceUpdate('overrideRoomId', newOverrideRoomId);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={deviceToEdit.overrideRoomId !== null}
        />
        Override RTLS Room
      </div>

      {/* Assign Override Room */}
      {deviceToEdit.overrideRoomId !== null && (
        <div
          style={{
            marginLeft: '20px',
            border: '1px solid gray',
            padding: '10px',
          }}
        >
          <FormControl className={classes.formControl}>
            <InputLabel id="Organization">Select Department</InputLabel>
            <Select
              labelId="Department"
              value={selectedRtlsOverrideDepartmentId || ''}
              onChange={onSelectDepartmentForRtlsOverride}
            >
              <MenuItem style={{ whiteSpace: 'normal' }} value="">
                Select Department
              </MenuItem>
              {alphabetizedSites.flatMap((site: ISite) =>
                departments
                  .filter(
                    (dept: IDepartment) =>
                      dept.isActive && dept.mongoSiteId === site.id
                  )
                  .map((dept: IDepartment) => (
                    <MenuItem
                      style={{ whiteSpace: 'normal' }}
                      key={`${site.id}-${dept.id}`}
                      value={dept.id}
                    >
                      {site.siteName} {dept.deptName}
                    </MenuItem>
                  ))
              )}
            </Select>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel style={{ marginTop: '4px' }} id="Room">
              Select Room
            </InputLabel>
            <Select
              labelId="Room"
              value={selectedRtlsOverrideRoomId || ''}
              onChange={onSelectRtlsOverrideRoom}
            >
              <MenuItem value="" style={{ whiteSpace: 'normal' }}>
                Select Room
              </MenuItem>
              {alphabetizedDeviceLocations
                .filter(
                  room => room.deptId === selectedRtlsOverrideDepartmentId
                )
                .map((room: any) => (
                  <MenuItem
                    style={{ whiteSpace: 'normal' }}
                    key={room.roomId}
                    value={room.roomId}
                  >
                    {room.deviceLocation}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <div
            onClick={() => {
              onDeviceUpdate(
                'overridenIsInside',
                !deviceToEdit.overridenIsInside
              );
            }}
            style={{ cursor: 'pointer' }}
          >
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={deviceToEdit.overridenIsInside}
            />
            RTLS Room Is Inside
          </div>
        </div>
      )}

      {/* Patient Self Room Device */}
      <div
        onClick={() => {
          onDeviceUpdate(
            'isPatientSelfRoomDevice',
            !deviceToEdit.isPatientSelfRoomDevice
          );
        }}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={deviceToEdit.isPatientSelfRoomDevice}
        />
        Patient Self Room Device
      </div>

      {/* Hybrid Self Room Device */}
      {deviceToEdit.isPatientSelfRoomDevice && (
        <div
          onClick={() => {
            onDeviceUpdate(
              'isHybridSelfRoomDevice',
              !deviceToEdit.isHybridSelfRoomDevice
            );
          }}
          style={{
            cursor: 'pointer',
            marginLeft: '20px',
            border: '1px solid gray',
            padding: '10px',
          }}
        >
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={deviceToEdit.isHybridSelfRoomDevice}
          />
          Hybrid Self Room Device
        </div>
      )}

      {/* Nurse Call */}
      {department?.actionsList && (
        <div>
          <div>
            Nurse Call Action{' '}
            {deviceToEdit.nurseCallActionId ? null : (
              <div
                className={classes.addIconSquare}
                onClick={() => {
                  setShowNurseCallDialog(true);
                }}
              >
                <div className={classes.addIconStyle}>+</div>
              </div>
            )}
            {department.actionsList.map((action: any) => (
              <div key={action.id}>
                {action.id === deviceToEdit.nurseCallActionId && (
                  <div style={{ position: 'relative' }}>
                    <div
                      onClick={() => {
                        onDeviceUpdate('nurseCallActionId', null);
                      }}
                      style={{
                        color: 'white',
                        position: 'absolute',
                        backgroundColor: 'red',
                        width: 15,
                        height: 15,
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontSize: 10,
                      }}
                    >
                      X
                    </div>
                    <img
                      onClick={() => {
                        setShowNurseCallDialog(true);
                      }}
                      src={action.actionImageUrl}
                      alt=""
                      style={{ width: 50, cursor: 'pointer' }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <Dialog
            open={showNurseCallDialog}
            onClose={() => {
              setShowNurseCallDialog(false);
            }}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: 300,
                margin: 20,
              }}
            >
              {department.actionsList.map((action: any) => (
                <div key={action.id} style={{ cursor: 'pointer' }}>
                  <img
                    onClick={() => onSelectAction(action)}
                    alt=""
                    src={action.actionImageUrl}
                    style={{ width: 50 }}
                  />
                </div>
              ))}
            </div>
          </Dialog>
        </div>
      )}
      <br />

      {/* Save Button */}
      <Button
        onClick={() => onHandleSubmit()}
        className={classes.submitButton}
        color="primary"
      >
        Save
      </Button>
      <br />
    </div>
  );
};

export default DeviceEditForm;
