import React, { Component } from "react";

class MobileApp extends Component {
  componentDidMount() {
    function getMobileOperatingSystem() {
      // @ts-ignore
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      // @ts-ignore
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }

    let os = getMobileOperatingSystem();
    if (os === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=io.ionic.synctimes";
    } else if (os === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/synctimes/id1546265625";
    } else {
      window.location.href = "https://app.synctimes.com";
    }
  }

  render() {
    return <div></div>;
  }
}

export default MobileApp;
