import { FC, useEffect } from 'react';
import { IDevice } from '../../../../../interfaces/interfaces';
import DeviceScreenshot from './DeviceScreenshot';

type TProps = {
  onTakeScreenshot: () => void;
  deviceToEdit: IDevice;
  onHome: () => void;
  onBack: () => void;
  onSetDevice: (deviceUpdates: Partial<IDevice>) => void;
};

const DeviceScreenshotContainer: FC<TProps> = props => {
  const { onTakeScreenshot, deviceToEdit, onHome, onBack, onSetDevice } = props;

  useEffect(() => {
    onTakeScreenshot();
    // eslint-disable-next-line
  }, [deviceToEdit.id]);

  return (
    <DeviceScreenshot
      device={deviceToEdit}
      onTakeScreenshot={onTakeScreenshot}
      onHome={onHome}
      onBack={onBack}
      onSetDevice={onSetDevice}
    />
  );
};

export default DeviceScreenshotContainer;
