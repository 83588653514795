import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { includes, truncate } from 'lodash';
import { FC, useRef, useState } from 'react';

import { useDepartments } from '../../../hooks/department/useDepartments';
import { IDepartment, TDepartmentOption } from '../../../interfaces/interfaces';

type TProps = {
  orgId: string;
  onSelect: (selectedDepartments: IDepartment[]) => void;
  selectedDepartmentIds: string[];
  showSite?: boolean;
};

const DepartmentMultiSelectField: FC<TProps> = props => {
  const {
    orgId,
    onSelect = () => {},
    selectedDepartmentIds: initialSelectedDepartmentIds = [],
    showSite = true,
  } = props;

  const { departments = [] } = useDepartments({
    orgId,
    onCompleted: departments => {
      const initialSelectedDepartments =
        initialSelectedDepartmentIds.map(departmentId =>
          departments.find(d => d.id === departmentId)
        ) ?? [];

      setSelectedDepartments(initialSelectedDepartments);
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedDepartments, setSelectedDepartments] = useState<IDepartment[]>(
    []
  );

  const [departmentSearchKey, setDepartmentSearchKey] = useState(0);

  const onAdd = (departmentOption: TDepartmentOption) => {
    const department = departmentOption?.department;
    if (department) {
      console.log('department', department);

      const updatedSelectedDepartments = [...selectedDepartments, department];
      setSelectedDepartments(updatedSelectedDepartments);
      onSelect(updatedSelectedDepartments);
    }
  };

  const onRemove = (provider: IDepartment) => {
    const updatedSelectedDepartments = selectedDepartments.filter(
      (p: IDepartment) => p.id !== provider.id
    );
    setSelectedDepartments(updatedSelectedDepartments);
    onSelect(updatedSelectedDepartments);
  };

  const selectedDepartmentIds = selectedDepartments.map(
    provider => provider.id
  );

  const departmentOptions: TDepartmentOption[] = departments
    .filter((department: IDepartment) => {
      return (
        !includes(selectedDepartmentIds, department.id) && department.isActive
      );
    })
    .map((department: IDepartment) => ({
      label: showSite
        ? `${department?.siteName} ${department?.deptName}`
        : department.deptName,
      value: department.id,
      department,
    }));

  const onTruncate = (value: string) => {
    return truncate(value, { length: 40 });
  };

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedDepartments.map(department => (
          <div key={department.id}>
            <Chip
              style={{ margin: '5px 5px 5px 0px' }}
              label={onTruncate(`${department.siteName} ${department.deptName}`)}
              onDelete={() => onRemove(department)}
            />
          </div>
        ))}
      </div>

      <Autocomplete
        id="departmentInput"
        key={departmentSearchKey}
        autoHighlight
        options={departmentOptions}
        getOptionLabel={(departmentOption: TDepartmentOption) =>
          showSite
            ? `${departmentOption?.department?.siteName} ${onTruncate(
                departmentOption?.department?.deptName
              )}`
            : onTruncate(departmentOption?.department?.deptName)
        }
        onChange={(event: any, departmentOption: any) => {
          onAdd(departmentOption as TDepartmentOption);

          new Promise(resolve => {
            setDepartmentSearchKey(departmentSearchKey + 1);

            resolve(null);
          }).then(() => {
            // providerInputRef.focus();
            inputRef.current?.focus();
          });
        }}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus
            ref={inputRef}
            label="Select Department"
          />
        )}
      />
    </div>
  );
};

export default DepartmentMultiSelectField;
