import { createTheme, makeStyles, Theme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    secondary: {
      main: "#A7D6A4",
    },
  },
});

const styles: any = (theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  modalStyle: {
    outline: "none",
    position: "absolute",
    width: "450px",
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
  },
});


export const useStyles = makeStyles(styles);