import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
// import clinicimage from "../images/clinicimage.png";
import clinicimage2 from "../images/clinicimage2.jpg"
// import synctimesLogo from "../images/synctimes.png";

// import { API } from "../apiconfig";

const styles = (theme) => ({});


class ClinicMapLoader extends Component {
    constructor() {
        super();
        this.state = {
            coordArray: [],

            clinicRooms: [
                // {
                //     name: "Office",
                //     x: 223,
                //     y: 350,
                //     busyLevel: 10, // maybe 10-100?
                // },
                // {
                //     name: "Exam 1",
                //     x: 123,
                //     y: 661,
                //     busyLevel: 70,
                // },
                // {
                //     name: "Exam 2",
                //     x: 529,
                //     y: 661,
                //     busyLevel: 40,
                // },
                // {
                //     name: "Waiting Room",
                //     x: 550,
                //     y: 350,
                //     busyLevel: 25,
                // },
                // {
                //     name: "Recovery",
                //     x: 819,
                //     y: 661,
                //     busyLevel: 25,
                // }
            ],


            clinicPathways: [
                {
                    provider: "Dr. Smith",
                    path: ["Room 1", "Room 2", "Staff/Kitchen", "Conference"],
                    color: "red",
                    lineWidth: 6
                },
                {
                    provider: "Dr. Johnson",
                    path: ["Staff/Kitchen", "Room 3"],
                    color: "blue",
                    lineWidth: 3
                }
            ]
        };

        if (localStorage.getItem("testClinicCoords")) {
            let coordArray = JSON.parse(localStorage.getItem("testClinicCoords"));
            // randomly calculate busy levels
            console.log("randomly calculate busy levels")
            coordArray.forEach(room => {
                room.busyLevel = Math.floor(Math.random() * 50) + 1;
            })

            console.log("coordArray", coordArray)

            this.state.clinicRooms = coordArray;
        }


    }

    render() {
        // const { classes } = this.props;


        // console.log("clinicRooms", this.state.clinicRooms)
        let xyPathways = [];
        this.state.clinicPathways.forEach((pathway, index) => {
            let xyPath = [];
            pathway.path.forEach((roomName) => {
                // console.log("roomName", roomName)
                let room = this.state.clinicRooms.find(room => room.roomName === roomName);
                // console.log("room", room)
                xyPath.push({ x: room.x, y: room.y, color: pathway.color, lineWidth: pathway.lineWidth });
            })
            xyPathways.push(xyPath);
        })


        console.log("pathways", xyPathways)


        // get width and height of clinicimage
        let image = new Image();
        image.src = clinicimage2;
        image.onload = () => {
            console.log("image width", image.width, "image height", image.height);
        }



        return (
            <div style={{ textAlign: "center" }}>


                <div className="img-overlay-wrap">


                    <img src={clinicimage2} alt="clinic"
                        style={{ width: "75vw" }}
                    />

                    <svg
                        // viewBox={`0 0 1000 1000`} // SINCE CREATOR USED 1000, WE USE 1000
                        viewBox={`0 0 ${image.width} ${image.height}`}
                        xmlns="http://www.w3.org/2000/svg"
                    >

                        {/* Draws Pathways from xyPathways */}
                        {xyPathways.map((path, index) => {
                            return (
                                <g key={index}>

                                    {path.map((coord, index) => {
                                        return (
                                            <line key={index}
                                                x1={path[index - 1]?.x ? path[index - 1].x : coord.x}
                                                y1={path[index - 1]?.y ? path[index - 1].y : coord.y}
                                                x2={coord.x}
                                                y2={coord.y}
                                                stroke={coord.color}
                                                strokeWidth={coord.lineWidth}
                                            // stroke="black"
                                            // strokeWidth="4"
                                            />
                                        )
                                    })}

                                    {path.map((coord, index) => {
                                        return (
                                            // create curved line between two points
                                            <path key={index}
                                                d={`
                                                M ${path[index - 1]?.x ? path[index - 1].x : coord.x} ${path[index - 1]?.y ? path[index - 1].y : coord.y}
                                                Q ${coord.x} ${coord.y} ${path[index + 1]?.x ? path[index + 1].x : coord.x} ${path[index + 1]?.y ? path[index + 1].y : coord.y}`}
                                                fill="none"
                                                stroke={coord.color}
                                                strokeWidth={coord.lineWidth}
                                            />
                                        )
                                    })}
                                </g>
                            )
                        })}


                        {/* Draws Room circles and their details */}
                        {this.state.clinicRooms.map((room, index) => {
                            return (
                                <g key={index}>
                                    <circle cx={room.x} cy={room.y} r={room.busyLevel} stroke="black" strokeWidth="3" fill="pink" shapeRendering="geometricPrecision" />
                                    <text x={room.x - 10} y={room.y - 5} fontSize="12" fill="black">{room.name}</text>
                                    <text x={room.x - 10} y={room.y + 5} fontSize="12" fill="black">Busy Level: {room.busyLevel}</text>
                                </g>
                            )
                        })}




                    </svg>

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ClinicMapLoader);
