import RefreshIcon from '@material-ui/icons/Refresh';
import axios from 'axios';
import Fuse from 'fuse.js';
import { FC, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { API } from '../../../../apiconfig';
import ErrorSnack from '../../../../utils/ErrorSnack';
import { canDo } from '../../../../utils/permissionCheck';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';

import { checkRefreshToken } from '../../../../utils/utils';

// Material
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseButton from '@material-ui/icons/Clear';
// import AddIcon from "@material-ui/icons/Add";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TableContainer from '@material-ui/core/TableContainer';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImportIcon from '@material-ui/icons/Publish';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import CircularProgress from "@material-ui/core/CircularProgress";
// import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';

import {
  IAthenaConfig,
  IDepartment,
  IEHRMapping,
  ILinkedSyncTimesObject,
  TAdminState,
} from '../../../../interfaces/interfaces';

import moment from 'moment';
import { useDepartments } from '../../../../hooks/department/useDepartments';
import { useEHRMappings } from '../../../../hooks/useEHRMappings2';
import { useOrg } from '../../../../hooks/useOrg';
import athenahealthlogo from './../../../../images/athenahealthlogo.png';
import { EHR_TEMPLATE } from './constants';
import { theme, useStyles } from './styles';

const _ = require('lodash');
const arraySort = require('array-sort');

const uniqid: any = require('uniqid');
let importedEHRMappings: any = [];

type TProps = {};

const EHRMapping: FC<TProps> = () => {
  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const classes = useStyles();

  const { EHRMappings = [], refetch } = useEHRMappings(
    loggedInUserData?.mongoOrganizationId
  );

  const { departments, refetch: refetchDepartments } = useDepartments({
    orgId: loggedInUserData?.mongoOrganizationId,
  });

  const { organization } = useOrg(loggedInUserData?.mongoOrganizationId);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [ehrMappingToEdit, setEhrMappingToEdit] = useState<IEHRMapping>(null);
  // const [hasModified, setHasModified] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('ehrId');
  const [sortDescending, setSortDescending] = useState<boolean>(false);
  const [newEHRMappingModalOpen, setNewEHRMappingModalOpen] =
    useState<boolean>(false);
  const [completedCSVImport, setCompletedCSVImport] = useState<boolean>(false);
  const [importReportDialog, setImportReportDialog] = useState<boolean>(false);
  const [latestSuccesses, setLatestSuccesses] = useState<number>(0);
  const [latestFails, setLatestFails] = useState<number>(0);
  const [newEHRMappings, setNewEHRMappings] = useState<IEHRMapping[]>([]);
  const [newLinkModalOpen, setNewLinkModalOpen] = useState<boolean>(false);
  const [newLinkObjectIsDefault, setNewLinkObjectIsDefault] =
    useState<boolean>(false);
  const [newLinkObjectId, setNewLinkObjectId] = useState<string>('');
  const [filterEhrMappingStatus, setFilterEhrMappingStatus] =
    useState<string>('All');
  const [filterLinked, setFilterLinked] = useState<string>('All');
  const [filterMappingType, setFilterMappingType] = useState<string>('All');

  const [fetchedProviders, setFetchedProviders] = useState<any[]>([]);
  const [filterString, setFilterString] = useState<string>('');
  const [dupliateEhrIds, setDupliateEhrIds] = useState<string[]>([]);
  const [athenaConfigList, setAthenaConfigList] = useState<any[]>([]);
  const [athenaConfigOpen, setAthenaConfigOpen] = useState<boolean>(false);
  const [athenaConfigToEdit, setAthenaConfigToEdit] =
    useState<IAthenaConfig>(null);
  const [athenaPatients, setAthenaPatients] = useState<any>([]);
  const [athenaDepartments, setAthenaDepartments] = useState<any>(null);
  const [athenaProviders, setAthenaProviders] = useState<any>(null);
  const [departmentSearchKey, setDepartmentSearchKey] = useState<number>(0);
  const [creatingNewAthenaConfig, setCreatingNewAthenaConfig] =
    useState<boolean>(false);
  const [athenaConfigCreateSuccess, setAthenaConfigCreateSuccess] =
    useState<boolean>(false);
  const [athenaConfigEditSuccess, setAthenaConfigEditSuccess] =
    useState<boolean>(false);
  const [athenaConfigDeleteSuccess, setAthenaConfigDeleteSuccess] =
    useState<boolean>(false);
  const [updatingConfigInProgress, setUpdatingConfigInProgress] =
    useState<boolean>(false);
  const [creatingConfigInProgress, setCreatingConfigInProgress] =
    useState<boolean>(false);
  const [athenaError, setAthenaError] = useState<boolean>(false);
  const [sortAthenaPatientLocationsBy, setSortAthenaPatientLocationsBy] =
    useState<string>('patientlocationid');
  const [
    sortAthenaPatientLocationsDescending,
    setSortAthenaPatientLocationsDescending,
  ] = useState<boolean>(false);
  const [sortAthenaDepartmentsBy, setSortAthenaDepartmentsBy] =
    useState<string>('name');
  const [sortAthenaDepartmentsDescending, setSortAthenaDepartmentsDescending] =
    useState<boolean>(false);
  const [sortAthenaProvidersBy, setSortAthenaProvidersBy] =
    useState<string>('firstname');
  const [sortAthenaProvidersDescending, setSortAthenaProvidersDescending] =
    useState<boolean>(false);

  // class EHRMapping extends Component<EHRMappingProps, EHRMappingState> {
  // constructor(props: EHRMappingProps) {
  //   super(props);
  //   this.state = {
  //     ehrMappings: [],
  //     openDrawer: false,
  //     ehrMappingToEdit: null,
  //     hasModified: false,
  //     saveSuccess: false,
  //     sortBy: "ehrId",
  //     sortDescending: false,
  //     newEHRMappingModalOpen: false,
  //     completedCSVImport: false,
  //     importReportDialog: false,
  //     latestSuccesses: 0,
  //     latestFails: 0,
  //     newEHRMappings: [],
  //     ehrMappingTemplate: {
  //       ehrName: "",
  //       ehrId: "",
  //       ehrMappingStatus: "Included",
  //       ehrMappingType: "Provider/Resource",
  //       importSuccess: false,
  //     },
  //     newLinkModalOpen: false,
  //     newLinkedObjectName: "",
  //     newLinkObjectIsDefault: false,
  //     newLinkObjectId: "",
  //     filterEhrMappingStatus: "All",
  //     filterLinked: "All",
  //     filterMappingType: "All",
  //     autoCompleteSearchKey: uniqid(),
  //     fetchedProviders: [],
  //     filterString: "",
  //     dupliateEhrIds: [],
  //     athenaConfigList: [],
  //     athenaConfigOpen: false,
  //     athenaConfigToEdit: null,
  //     athenaPatients: [],
  //     athenaDepartments: null,
  //     athenaProviders: null,
  //     departmentSearchKey: 0,
  //     creatingNewAthenaConfig: false,
  //     athenaConfigCreateSuccess: false,
  //     athenaConfigEditSuccess: false,
  //     athenaConfigDeleteSuccess: false,
  //     updatingConfigInProgress: false,
  //     creatingConfigInProgress: false,
  //     athenaError: false,
  //     sortAthenaPatientLocationsBy: "patientlocationid",
  //     sortAthenaPatientLocationsDescending: false,
  //     sortAthenaDepartmentsBy: "name",
  //     sortAthenaDepartmentsDescending: false,
  //     sortAthenaProvidersBy: "firstname",
  //     sortAthenaProvidersDescending: false,
  //   };
  // }

  // componentDidMount() {
  //   this.fetchUnlinkedEhrMapping();
  // }

  const onOpenDrawer = (ehrMapping: IEHRMapping) => {
    if (canDo(['Update EHR Mapping'], loggedInUserData)) {
      setOpenDrawer(true);
      setEhrMappingToEdit(ehrMapping);
    }
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setEhrMappingToEdit(null);
  };

  const onHandleFilterEhrMappingStatus = (input: {
    name: string;
    event: any;
  }) => {
    const { event } = input;

    let newValue = event.target.value;
    setFilterEhrMappingStatus(newValue);
  };

  const onHandleFilterMappingType = (input: { name: string; event: any }) => {
    const { event } = input;

    let newValue = event.target.value;
    setFilterMappingType(newValue);
  };

  const onHandleFilterMappingLinked = (input: { name: string; event: any }) => {
    const { event } = input;
    let newValue = event.target.value;
    setFilterLinked(newValue);
  };

  const onHandleChange = (input: { name: string; event: any }) => {
    const { name, event } = input;
    let newValue = event.target.value;

    setEhrMappingToEdit({
      ...ehrMappingToEdit,
      [name]: newValue,
    });

    // setHasModified(true);
  };

  const onHandleSubmit = () => {
    let token = localStorage.getItem('token');

    let modifiedEhrMapping = { ...ehrMappingToEdit };
    // console.log("Payload", modifiedEhrMapping);

    let payload = JSON.stringify(modifiedEhrMapping);

    fetch(API.REACT_APP_API_UNLINKEDEHRMAPPING, {
      method: 'PUT',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('Good', data);

        setOpenDrawer(false);
        // setHasModified(false);
        setSaveSuccess(true);

        refetch();
        setTimeout(() => setSaveSuccess(false), 3000);
      })
      .catch(err => {
        console.log('Err updating ehrMapping', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const onSortAthenaPatientLocationsBy = (sortValue: string) => {
    if (sortValue === sortAthenaPatientLocationsBy) {
      setSortAthenaPatientLocationsDescending(
        !sortAthenaPatientLocationsDescending
      );
    } else {
      setSortAthenaPatientLocationsBy(sortValue);
      setSortAthenaPatientLocationsDescending(false);
    }
  };

  const onSortAthenaDepartmentsBy = (sortValue: string) => {
    if (sortValue === sortAthenaDepartmentsBy) {
      setSortAthenaDepartmentsDescending(!sortAthenaDepartmentsDescending);
    } else {
      setSortAthenaDepartmentsBy(sortValue);
      setSortAthenaDepartmentsDescending(false);
    }
  };

  const onSortAthenaProvidersBy = (sortValue: string) => {
    if (sortValue === sortAthenaProvidersBy) {
      setSortAthenaProvidersDescending(!sortAthenaProvidersDescending);
    } else {
      setSortAthenaProvidersBy(sortValue);
      setSortAthenaProvidersDescending(false);
    }
  };

  const newEHRMappingImport = () => {
    setNewEHRMappingModalOpen(true);
    setCompletedCSVImport(false);
  };

  const onCloseModal = () => {
    refetch();

    setNewEHRMappingModalOpen(false);
  };

  const onProcessEHRMappings = (ehrMappingsArray: any) => {
    // WIP
    // console.log("processing", ehrMappingsArray);

    let preppedEHRMappings: any[] = [];

    ehrMappingsArray.forEach((ehrMapping: any) => {
      preppedEHRMappings.push({
        tempId: uniqid(),
        importSuccess: false,
        ...ehrMapping,
      });
    });

    // console.log("DONE", preppedEHRMappings);

    setNewEHRMappings(preppedEHRMappings);
  };

  const onHandleNewEHRMappingValue = (input: {
    name: string;
    tempId: string;
    event: any;
  }) => {
    // console.log("CHANGE", name);

    const { name, tempId, event } = input;
    let newValue = event.target.value;

    let ehrMappings = [...newEHRMappings];

    let ehrMappingIndex = ehrMappings.findIndex(
      ehrMapping => ehrMapping.tempId === tempId
    );

    if (name === 'ehrMappingType') {
      let updatedEHRMappingStatus = '';
      if (newValue === 'Appointment Status') {
        updatedEHRMappingStatus = 'Register';
      } else {
        updatedEHRMappingStatus = 'Included';
      }

      // If changing ehrMappingType, reset ehrMappingStatus to default
      ehrMappings[ehrMappingIndex] = {
        ...ehrMappings[ehrMappingIndex],
        [name]: newValue,
        ehrMappingStatus: updatedEHRMappingStatus,
      };
    } else {
      ehrMappings[ehrMappingIndex] = {
        ...ehrMappings[ehrMappingIndex],
        [name]: newValue,
      };
    }

    setNewEHRMappings(ehrMappings);
  };

  const onDeleteNewEHRMapping = (tempId: string) => {
    let ehrMappings = [...newEHRMappings];
    let updatedEhrMappings = _.filter(
      ehrMappings,
      (ehrMapping: { tempId: string }) => {
        return ehrMapping.tempId !== tempId;
      }
    );

    setNewEHRMappings(updatedEhrMappings);
  };

  const onAddRow = () => {
    setNewEHRMappings([
      ...newEHRMappings,
      { ...EHR_TEMPLATE, tempId: uniqid() } as IEHRMapping,
    ]);
  };

  const onCreateEhrMappings = () => {
    let token = localStorage.getItem('token');
    let ehrMappings = [...newEHRMappings];

    let latestSuccesses = 0;
    let latestFails = 0;

    ehrMappings.forEach(mapping => {
      if (!mapping.importSuccess) {
        let modifiedMapping = {
          ...mapping,
          orgId: loggedInUserData.mongoOrganizationId,
        };

        // CHECK ERRORS HERE
        let ehrIdError = false;
        let ehrMappingStatusError = false;
        let ehrMappingTypeError = false;
        let ehrNameError = false;

        if (modifiedMapping.ehrId.length < 1) {
          ehrIdError = true;
        }
        if (modifiedMapping.ehrMappingStatus.length < 1) {
          ehrMappingStatusError = true;
        }
        if (modifiedMapping.ehrMappingType.length < 1) {
          ehrMappingTypeError = true;
        }
        if (modifiedMapping.ehrName.length < 1) {
          ehrNameError = true;
        }

        // Check if any err exists;
        if (
          ehrIdError ||
          ehrMappingStatusError ||
          ehrMappingTypeError ||
          ehrNameError
        ) {
          latestFails++;
          console.log('ERR IN MAPPING', modifiedMapping);
        } else {
          // No errs found, continue to post request
          console.log('PAYLOAD', modifiedMapping);
          let ehrId = modifiedMapping.ehrId;
          let payload = JSON.stringify(modifiedMapping);
          fetch(API.REACT_APP_API_UNLINKEDEHRMAPPING, {
            method: 'POST',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              return res.json();
            })
            .then(data => {
              latestSuccesses++;
              console.log('RES!', data);
              // CHECK 200 RESPONSE HERE "EhrId exists for this org and mapping type" or "EhrId exists for this org and mapping type, updated name & status"?
              // data.data = "EhrId exists for this org and mapping type, updated name & status" || "EhrId exists for this org and mapping type"
              let existingEhrIds = [...dupliateEhrIds];
              if (
                data.data ===
                'EhrId exists for this org and mapping type, updated name & status'
              ) {
                if (!existingEhrIds.includes(ehrId)) {
                  existingEhrIds.push(ehrId);
                }
              }
              let objIndex = ehrMappings.findIndex(
                obj => obj.tempId === mapping.tempId
              );
              ehrMappings[objIndex] = {
                ...ehrMappings[objIndex],
                importSuccess: true,
              };

              setNewEHRMappings(ehrMappings);
              setLatestSuccesses(latestSuccesses);
              setLatestFails(latestFails);
              setDupliateEhrIds(existingEhrIds);
            })
            .catch(err => {
              console.log('ERR creating ehr mapping', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      }

      setImportReportDialog(true);
    });
  };

  const onDeleteEHRMapping = () => {
    let token = localStorage.getItem('token');

    let modifiedEhrMapping = { ...ehrMappingToEdit };

    console.log('DELETE', modifiedEhrMapping);

    let payload = JSON.stringify(modifiedEhrMapping);

    fetch(API.REACT_APP_API_UNLINKEDEHRMAPPING, {
      method: 'DELETE',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // console.log("DELETED", data);
        refetch();
        onCloseDrawer();
      })
      .catch(err => {
        console.log('ERR deleting ehr mapping');
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onOpenLinkModal = () => {
    let token = localStorage.getItem('token');
    console.log('to edit', ehrMappingToEdit);

    // check ehrType to determine to fetch providers or departments.
    if (ehrMappingToEdit.ehrMappingType === 'Department') {
      refetchDepartments(loggedInUserData.mongoOrganizationId);
    }

    if (ehrMappingToEdit.ehrMappingType === 'Provider/Resource') {
      // fetchAppUsersByOrgId(loggedInUserData.mongoOrganizationId);
      // REACT_APP_API_UNLINKEDEHRMAPPINGPROVIDERS (in body, send the ehrMappingToEdit)
      fetch(
        API.REACT_APP_API_UNLINKEDEHRMAPPINGPROVIDERS +
          '/orgId=' +
          ehrMappingToEdit.orgId +
          '/unlinkedEhrMappingId=' +
          ehrMappingToEdit.id,
        {
          method: 'GET',
          // body: JSON.stringify(ehrMappingToEdit),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(res => {
          // console.log("RES", res);
          return res.json();
        })
        .then(res => {
          console.log('RES', res);
          setFetchedProviders(res.data);
        })
        .catch(err => {
          console.log('ERR', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }

    setNewLinkModalOpen(true);
  };

  const onCloseLinkModal = () => {
    setNewLinkModalOpen(false);
  };

  const onMakeLinkedObjectDefault = (linkedObject: any) => {
    console.log('Make default', linkedObject);
    let modifiedEhrMapping = { ...ehrMappingToEdit };
    let linkedObjectsToModify = [...ehrMappingToEdit.linkedSyncTimesObjects];
    // console.log("existing", linkedObjectsToModify);
    linkedObjectsToModify.forEach((object, i) => {
      if (object.id === linkedObject.id) {
        linkedObjectsToModify[i].isDefault = true;
      } else {
        linkedObjectsToModify[i].isDefault = false;
      }
    });

    modifiedEhrMapping.linkedSyncTimesObjects = linkedObjectsToModify;

    // console.log("DONE", modifiedEhrMapping);

    let payload = JSON.stringify(modifiedEhrMapping);
    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_UNLINKEDEHRMAPPING, {
      method: 'PUT',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('default response', data);
        setEhrMappingToEdit(data.data);

        refetch();
      })
      .catch(err => {
        console.log('ERR defaulting new link', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onDeleteLinkedObject = (linkedObject: any) => {
    console.log('Delete', linkedObject);
    let modifiedEhrMapping = { ...ehrMappingToEdit };
    modifiedEhrMapping.linkedSyncTimesObjects.forEach((object: any, i: any) => {
      if (linkedObject.id === object.id) {
        modifiedEhrMapping.linkedSyncTimesObjects.splice(i, 1);
      }
    });

    // console.log("done", modifiedEhrMapping);
    let payload = JSON.stringify(modifiedEhrMapping);
    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_UNLINKEDEHRMAPPING, {
      method: 'PUT',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('res deleting', data);
        setEhrMappingToEdit(data.data);
        refetch();
      })
      .catch(err => {
        console.log('ERR creating new link', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onCreateNewLink = () => {
    if (newLinkObjectId.length > 0) {
      let modifiedEhrMapping = { ...ehrMappingToEdit };
      let linkedObjectsToModify: ILinkedSyncTimesObject[] = [
        ...ehrMappingToEdit.linkedSyncTimesObjects,
      ];

      // If new link is new default, make all others not default first.
      if (newLinkObjectIsDefault) {
        linkedObjectsToModify.forEach((object, i) => {
          linkedObjectsToModify[i].isDefault = false;
        });
      }

      // Push new object (or push/replace if mappingType is provider)
      if (ehrMappingToEdit.ehrMappingType === 'Provider/Resource') {
        linkedObjectsToModify = [
          {
            id: newLinkObjectId,
            syncTimesName: '',
            isDefault: newLinkObjectIsDefault,
          },
        ];
      } else {
        console.log('PUSHING NEW DEPT LINK');
        linkedObjectsToModify.push({
          id: newLinkObjectId,
          syncTimesName: '',
          isDefault: newLinkObjectIsDefault,
        });
      }

      modifiedEhrMapping.linkedSyncTimesObjects = linkedObjectsToModify;

      console.log('Payload for creating new link', modifiedEhrMapping);

      let payload = JSON.stringify(modifiedEhrMapping);
      let token = localStorage.getItem('token');

      console.log('create link!', modifiedEhrMapping);
      fetch(API.REACT_APP_API_UNLINKEDEHRMAPPING, {
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          console.log('DATA', data);
          setEhrMappingToEdit(data.data);

          refetch();
        })
        .catch(err => {
          console.log('ERR creating new link', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    } else {
      console.log('NO ID SELECTED');
    }
  };

  // selectNewLinkId = () => (event: any) => {
  //   console.log("GRABBED", event);
  //   this.setState({
  //     newLinkObjectId: event.target.value,
  //   });
  // };

  const onSelectNewLinkId = (option: any) => {
    console.log('GRAB', option);
    if (option) {
      setNewLinkObjectId(option.value);
    } else {
      setNewLinkObjectId('');
    }
  };

  const onOpenAthenaConfig = () => {
    console.log('click');
    let URL = API.REACT_APP_API_ATHENAPRACTICECONFIG + organization.id;
    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    axios.get(URL, headers).then(res => {
      console.log('res', res);
      if (res.data.data) {
        console.log('Grab first and load it', res.data.data[0]);
        setAthenaConfigList(res.data.data);
        setAthenaConfigOpen(true);

        if (res.data.data[0]?.athenaPracticeId) {
          onSelectAthenaConfig(res.data.data[0]);
        } else {
          onSelectAthenaConfig('new');
        }
      }
    });
  };

  const onSelectAthenaConfig = (config: any) => {
    if (config === 'new') {
      // New config
      console.log('NEW CONFIG');
      let newConfigTemplate: any = {
        athenaPracticeId: '',
        deptsToInclude: [],
        includeSaturday: false,
        includeSunday: false,
        isPreview: false,
        localTimeToStart: '',
        localTimeToEnd: '',
        overrideProviderWithRenderingProvider: false,
        timeZoneId: 9,
      };

      setAthenaConfigToEdit(newConfigTemplate);
      setAthenaPatients([]);
      setAthenaDepartments([]);
      setAthenaProviders([]);
      setCreatingNewAthenaConfig(true);
    } else {
      console.log('SELECT', config);
      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      let athenaPracticeUrl_patient =
        API.REACT_APP_API_ATHENAPRACTICECONFIGPATIENTLOCATIONS +
        config.athenaPracticeId;
      let athenaPracticeUrl_dept =
        API.REACT_APP_API_ATHENAPRACTICECONFIGDEPT + config.athenaPracticeId;
      let athenaPracticeUrl_provider =
        API.REACT_APP_API_ATHENAPRACTICECONFIGPROVIDERS +
        config.athenaPracticeId;

      if (config.isPreview) {
        athenaPracticeUrl_patient += '&isPreview=true';
        athenaPracticeUrl_dept += '&isPreview=true';
        athenaPracticeUrl_provider += '&isPreview=true';
      } else {
        athenaPracticeUrl_patient += '&isPreview=false';
        athenaPracticeUrl_dept += '&isPreview=false';
        athenaPracticeUrl_provider += '&isPreview=false';
      }

      // console.log("Athena Config to Edit", config);
      setAthenaConfigToEdit(config);

      axios
        .get(athenaPracticeUrl_patient, headers)
        .then(patient_res => {
          console.log('patient res', patient_res);
          if (patient_res.data.data.patientLocations) {
            setAthenaPatients(patient_res.data.data.patientLocations);
          }
        })
        .catch(err => {
          console.log('Patient ERR', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });

      axios
        .get(athenaPracticeUrl_dept, headers)
        .then(dept_res => {
          console.log('dept res', dept_res);
          setAthenaDepartments(dept_res.data.data.departments);
        })
        .catch(err => {
          console.log('Dept ERR', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });

      axios
        .get(athenaPracticeUrl_provider, headers)
        .then(provider_res => {
          console.log('provider res', provider_res);
          setAthenaProviders(provider_res.data.data.providers);
        })
        .catch(err => {
          console.log('ERR', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const onDeleteDeptToInclude = (deptIdToDelete: number) => {
    let newDeptIds = athenaConfigToEdit.deptsToInclude.filter(
      (deptId: number) => {
        return deptId !== deptIdToDelete;
      }
    );
    setAthenaConfigToEdit({
      ...athenaConfigToEdit,
      deptsToInclude: newDeptIds,
    });
  };

  const onSelectAthenaDept = (departmentId: number) => {
    console.log('DEPT', departmentId);

    setAthenaConfigToEdit({
      ...athenaConfigToEdit,
      deptsToInclude: [...athenaConfigToEdit.deptsToInclude, departmentId],
    });
  };

  const onLocalTimeToStartChange = (time: any) => {
    console.log('Time input', time);
    // 11/3/1993 7:00:00 AM format to this
    let formattedTime = moment(time, 'H mm').format('LTS');
    let finalAdjustment = '11/3/1993 ' + formattedTime;

    console.log('Time adjusted/formatted', finalAdjustment);
    const newAthenaConfigToEdit = {
      ...athenaConfigToEdit,
      localTimeToStart: finalAdjustment,
    };

    setAthenaConfigToEdit(newAthenaConfigToEdit);
  };

  const onLocalTimeToEndChange = (time: any) => {
    console.log('Time input', time);
    // 11/3/1993 7:00:00 AM format to this
    let formattedTime = moment(time, 'H mm').format('LTS');
    let finalAdjustment = '11/3/1993 ' + formattedTime;

    console.log('Time adjusted/formatted', finalAdjustment);

    setAthenaConfigToEdit({
      ...athenaConfigToEdit,
      localTimeToEnd: finalAdjustment,
    });
  };

  const onUpdateAthenaConfig = () => {
    // PUT
    // console.log("update!");

    if (
      athenaConfigToEdit.localTimeToStart &&
      athenaConfigToEdit.localTimeToEnd
    ) {
      setTimeout(() => setAthenaConfigEditSuccess(false), 3000);

      let URL = API.REACT_APP_API_ATHENAPRACTICECONFIGROOT;
      let token = localStorage.getItem('token');

      console.log('payload', athenaConfigToEdit);

      let payload = JSON.stringify(athenaConfigToEdit);

      setUpdatingConfigInProgress(true);

      fetch(URL, {
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          console.log('PUT data', data);
          setAthenaConfigEditSuccess(true);
          setUpdatingConfigInProgress(false);

          setTimeout(() => {
            setAthenaConfigEditSuccess(false);
          }, 3000);
        });
    }
  };

  const onCreateAthenaConfig = () => {
    if (
      athenaConfigToEdit.localTimeToStart &&
      athenaConfigToEdit.localTimeToEnd
    ) {
      // POST
      console.log('POST', athenaConfigToEdit);
      let preppedData = {
        ...athenaConfigToEdit,
        orgId: organization.id,
      };
      let payload = JSON.stringify(preppedData);
      let URL = API.REACT_APP_API_ATHENAPRACTICECONFIGROOT;
      let token = localStorage.getItem('token');

      console.log('preppedData', preppedData);

      setCreatingConfigInProgress(true);

      fetch(URL, {
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          console.log('POST res', data);

          if (data.data.isSuccessCodes) {
            setAthenaConfigCreateSuccess(true);
            setAthenaConfigOpen(false);
            setAthenaConfigList([]);
            setAthenaConfigToEdit(null);
            setCreatingConfigInProgress(false);

            setTimeout(() => {
              setAthenaConfigCreateSuccess(false);
            }, 3000);
          } else {
            setAthenaError(true);
            setCreatingConfigInProgress(false);

            setTimeout(() => {
              setAthenaError(false);
            }, 3000);
          }
        })
        .catch(err => {
          console.log('ERR creating config', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const onDeleteConfig = () => {
    console.log('DELETE', athenaConfigToEdit);

    if (athenaConfigToEdit.athenaPracticeId && !creatingNewAthenaConfig) {
      let payload = JSON.stringify(athenaConfigToEdit);

      let URL = API.REACT_APP_API_ATHENAPRACTICECONFIGROOT;
      let token = localStorage.getItem('token');

      fetch(URL, {
        method: 'DELETE',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          console.log('DELETE data', data);
          setAthenaConfigDeleteSuccess(true);
          setAthenaConfigOpen(false);
          setAthenaConfigList([]);
          setAthenaConfigToEdit(null);

          setTimeout(() => {
            setAthenaConfigDeleteSuccess(false);
          }, 3000);
        });
    }
  };

  // console.log("dups", dupliateEhrIds);

  // console.log("DEPARTMENTS", departments);
  // console.log("USERS", usersData);
  // console.log("CURRENT", newEHRMappings);

  let sortedAthenaPatientLocations: any[] = [];
  if (athenaPatients) {
    let unsortedAthenaPatientLocations = [...athenaPatients];
    let filter = sortAthenaPatientLocationsBy;
    // console.log("filter", filter);
    sortedAthenaPatientLocations = arraySort(
      unsortedAthenaPatientLocations,
      filter,
      { reverse: sortAthenaPatientLocationsDescending }
    );
  }

  // console.log("sorted locations", sortedAthenaPatientLocations);

  let sortedAthenaDepartments: any[] = [];
  if (athenaDepartments) {
    let unsortedAthenaDepartments = [...athenaDepartments];
    let filter = sortAthenaDepartmentsBy;
    // console.log("filter", filter);
    if (filter === 'departmentid') {
      let modifiedUnsortedAthenaDepartments: any = [];
      // set modifiedUnsortedAthenaDepartments to unsortedAthenaDepartments with departmentid as a int
      unsortedAthenaDepartments.forEach(department => {
        let modifiedDepartment = { ...department };
        modifiedDepartment.departmentid = parseInt(
          modifiedDepartment.departmentid
        );
        modifiedUnsortedAthenaDepartments.push(modifiedDepartment);
      });
      unsortedAthenaDepartments = modifiedUnsortedAthenaDepartments;
    }

    sortedAthenaDepartments = arraySort(unsortedAthenaDepartments, filter, {
      reverse: sortAthenaDepartmentsDescending,
    });
  }

  // console.log("SORTED DEPARTMENTS", sortedAthenaDepartments);

  let sortedAthenaUsers: any[] = [];
  if (athenaProviders) {
    let unsortedAthenaUsers = [...athenaProviders];
    let filter = sortAthenaProvidersBy;
    sortedAthenaUsers = arraySort(unsortedAthenaUsers, filter, {
      reverse: sortAthenaProvidersDescending,
    });
  }

  // console.log("SORTED USERS", sortedAthenaUsers);

  let filteredEhrMappings: IEHRMapping[] = [];

  if (EHRMappings) {
    let unfilteredEhrMappings = [...EHRMappings];
    let filter = sortBy;
    filteredEhrMappings = arraySort(unfilteredEhrMappings, filter, {
      reverse: sortDescending,
    });
  }

  // console.log("CHECK", filteredEhrMappings);

  if (filterEhrMappingStatus !== 'All') {
    filteredEhrMappings = filteredEhrMappings.filter(mapping => {
      return mapping.ehrMappingStatus === filterEhrMappingStatus;
    });
  }

  if (filterMappingType !== 'All') {
    filteredEhrMappings = filteredEhrMappings.filter(mapping => {
      return mapping.ehrMappingType === filterMappingType;
    });
  }

  if (filterLinked === 'Linked') {
    filteredEhrMappings = filteredEhrMappings.filter(mapping => {
      return mapping.isLinked;
    });
  }
  if (filterLinked === 'Not Linked') {
    filteredEhrMappings = filteredEhrMappings.filter(mapping => {
      return !mapping.isLinked;
    });
  }

  // filteredEhrMappings = filteredEhrMappings.filter((mapping) => {
  //   return mapping.ehrName.toLowerCase().includes(filterString.toLowerCase());
  // });

  let fuseOptions = {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['ehrName', 'ehrId'],
  };

  let fuse = new Fuse(filteredEhrMappings, fuseOptions);
  let result = fuse.search(filterString);
  // console.log("Fuse Result", result);

  console.log({ result });
  if (filterString.length > 0) {
    filteredEhrMappings = result.map(fuseResult => fuseResult.item);
  }

  let ehrNameErrorFound = false;
  let ehrIdErrorFound = false;
  let ehrMappingImportsLeft = 0;
  newEHRMappings.forEach((ehrMapping: any) => {
    if (ehrMapping.ehrNameErrorFound) {
      ehrNameErrorFound = true;
    }
    if (ehrMapping.ehrIdErrorFound) {
      ehrIdErrorFound = true;
    }
    if (!ehrMapping.importSuccess) {
      ehrMappingImportsLeft++;
    }
  });

  // let newLinkIdOptions: any = [];
  let autoCompleteNewLinkIdOptions: any = [];

  if (ehrMappingToEdit) {
    if (ehrMappingToEdit.ehrMappingType === 'Department') {
      // sort departments by deptName
      let sortedDepartments = [...departments].sort((a: any, b: any) => {
        if (a.deptName < b.deptName) {
          return -1;
        }
        if (a.deptName > b.deptName) {
          return 1;
        }
        return 0;
      });

      // newLinkIdOptions = sortedDepartments.map((dept: IDepartment) => {
      //   if (dept.isActive) {
      //     return (
      //       <option key={dept.id} value={dept.id}>
      //         {dept.deptName}
      //       </option>
      //     );
      //   }
      //   return null;
      // });

      sortedDepartments.forEach((dept: IDepartment) => {
        if (dept.isActive) {
          autoCompleteNewLinkIdOptions.push({
            value: dept.id,
            label: dept.siteName + ' ' + dept.deptName,
          });
        }
      });
    }

    if (ehrMappingToEdit.ehrMappingType === 'Provider/Resource') {
      // sort usersData by fullName
      let sortedUsersData = [...fetchedProviders].sort((a: any, b: any) => {
        if (a.fuzzyMatchPercent > b.fuzzyMatchPercent) {
          return -1;
        }
        if (a.fuzzyMatchPercent < b.fuzzyMatchPercent) {
          return 1;
        }
        return 0;
      });

      // newLinkIdOptions = sortedUsersData.map((user: IUser) => {
      //   if (user.isActive && user.userTypeId === 4) {
      //     return (
      //       <option key={user.id} value={user.id}>
      //         {user.fullName}
      //       </option>
      //     );
      //   }
      //   return null;
      // });
      // console.log("UMMMM", sortedUsersData);

      sortedUsersData.forEach((user: any) => {
        autoCompleteNewLinkIdOptions.push({
          value: user.id,
          label:
            user.syncTimesName +
            ' (' +
            user.fuzzyMatchPercent.toString() +
            '% match)',
        });
      });
    }
  }

  let alphabetizedSyncTimesObjects: any[] = [];
  if (ehrMappingToEdit) {
    // alphabetize array of objects by syncTimesName
    alphabetizedSyncTimesObjects = ehrMappingToEdit.linkedSyncTimesObjects.sort(
      (a: any, b: any) => {
        if (a.syncTimesName < b.syncTimesName) {
          return -1;
        }
        if (a.syncTimesName > b.syncTimesName) {
          return 1;
        }
        return 0;
      }
    );
  }

  // console.log("org", organization);

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Snackbar
          // className={classes.snackbar}
          style={{ backgroundColor: '#2296F3' }}
          // variant={"warning"}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={dupliateEhrIds.length > 0}
          // TransitionComponent={Fade}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
          <SnackbarContent
            // className={classes.snackbar}
            style={{ backgroundColor: '#2296F3' }}
            message={
              <span
                id="message-id"
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                // }}
              >
                {/* <ErrorIcon className={classes.icon} /> */}
                {/* {errorMessage} */}
                Updated name and status for these IDs:
                {dupliateEhrIds.map((ehrId: any) => {
                  return <div key={ehrId}>- {ehrId}</div>;
                })}
                <br />
                <Button
                  variant="contained"
                  onClick={() => {
                    setDupliateEhrIds([]);
                  }}
                >
                  Dismiss
                </Button>
              </span>
            }
          />
        </Snackbar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        >
          <Typography variant="h4" component="h2">
            EHR Mapping
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              paddingTop: '14px',
            }}
          >
            {organization?.orgUsesAthena && (
              <img
                className={classes.athenaLogo}
                src={athenahealthlogo}
                alt="Athena Health Logo"
                style={{
                  width: 80,
                  cursor: 'pointer',
                  paddingTop: '4px',
                }}
                onClick={onOpenAthenaConfig}
              />
            )}
            {canDo(['Create EHR Mapping'], loggedInUserData) && (
              <Tooltip
                title="Click here to import EHR mappings via CSV"
                aria-label="Import"
              >
                <Fab
                  className={`${classes.newEHRMappingImportButton} fourth-step users-second-step`}
                  onClick={newEHRMappingImport}
                >
                  <ImportIcon />
                </Fab>
              </Tooltip>
            )}
            <Tooltip title="Refresh EHR Mappings" aria-label="Refresh">
              <Fab className={classes.refreshButton} onClick={() => refetch()}>
                <RefreshIcon />
              </Fab>
            </Tooltip>
          </div>
        </div>

        <FormControl
          className={classes.textField}
          style={{ width: 200 }}
          margin="normal"
        >
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            EHR Mapping Status
          </InputLabel>
          <NativeSelect
            value={filterEhrMappingStatus}
            onChange={event =>
              onHandleFilterEhrMappingStatus({
                name: 'ehrMappingStatus',
                event,
              })
            }
            input={<Input id="age-native-label-placeholder" />}
          >
            <option value={'All'}>All</option>
            {/* <option value={"Linked"}>Linked</option> */}
            <option value={'Excluded'}>Excluded</option>
            <option value={'Included'}>Included</option>
          </NativeSelect>
        </FormControl>
        <br />
        <FormControl
          className={classes.textField}
          style={{ width: 200 }}
          margin="normal"
        >
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            EHR Mapping Type
          </InputLabel>
          <NativeSelect
            value={filterMappingType}
            onChange={event =>
              onHandleFilterMappingType({ name: 'filterMappingType', event })
            }
            input={<Input id="age-native-label-placeholder" />}
          >
            <option value={'All'}>All</option>
            <option value={'Provider/Resource'}>Provider/Resource</option>
            <option value={'Department'}>Department</option>
            <option value={'Appointment Type'}>Appointment Type</option>
            <option value={'Appointment Status'}>Appointment Status</option>
            <option value={'Quality Measure'}>Quality Measure</option>
          </NativeSelect>
        </FormControl>
        <br />

        <FormControl
          className={classes.textField}
          style={{ width: 200 }}
          margin="normal"
        >
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            EHR Mapping Linked
          </InputLabel>
          <NativeSelect
            value={filterLinked}
            onChange={event =>
              onHandleFilterMappingLinked({ name: 'filterLinked', event })
            }
            input={<Input id="age-native-label-placeholder" />}
          >
            <option value={'All'}>All</option>
            <option value={'Linked'}>Linked</option>
            <option value={'Not Linked'}>Not Linked</option>
          </NativeSelect>
        </FormControl>
        <br />

        <TextField
          label="Search"
          margin="normal"
          className={classes.textField}
          style={{ width: 200 }}
          // onChange={this.handleFilter()}
          onChange={e => {
            setFilterString(e.target.value);
          }}
        />

        {/* 
          <div
            onClick={() =>
              this.setState({
                filterLinked: !filterLinked,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <Checkbox checked={filterLinked} />
            Linked
          </div> */}

        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => {
                    onSortBy('ehrId');
                  }}
                >
                  {sortBy === 'ehrId' ? (
                    <div>EHR Identifier {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>EHR Identifier</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => {
                    onSortBy('ehrName');
                  }}
                >
                  {sortBy === 'ehrName' ? (
                    <div>Name {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Name</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => {
                    onSortBy('ehrMappingType');
                  }}
                >
                  {sortBy === 'ehrMappingType' ? (
                    <div>
                      EHR Mapping Type {sortDescending ? <>▼</> : <>▲</>}
                    </div>
                  ) : (
                    <div>EHR Mapping Type</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => {
                    onSortBy('ehrMappingStatus');
                  }}
                >
                  {sortBy === 'ehrMappingStatus' ? (
                    <div>
                      EHR Mapping Status {sortDescending ? <>▼</> : <>▲</>}
                    </div>
                  ) : (
                    <div>EHR Mapping Status</div>
                  )}
                </TableCell>
                <TableCell className={classes.sortSelect}>Linked</TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => {
                    onSortBy('fuzzyMatchPercent');
                  }}
                >
                  {sortBy === 'fuzzyMatchPercent' ? (
                    <div>% Match {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>% Match</div>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEhrMappings.map((ehrMapping, i: any) => (
                <TableRow
                  key={i}
                  className={classes.tableRow}
                  onClick={() => onOpenDrawer(ehrMapping)}
                >
                  <TableCell className={classes.tableCell}>
                    {ehrMapping.ehrId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {ehrMapping.ehrName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {ehrMapping.ehrMappingType}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {ehrMapping.ehrMappingStatus}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {ehrMapping.isLinked ? <CheckIcon /> : null}{' '}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {ehrMapping.fuzzyMatchPercent}%
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Modal
          open={athenaConfigOpen}
          onClose={() => {
            setAthenaConfigOpen(false);
            setAthenaConfigList([]);
            setAthenaConfigToEdit(null);
          }}
        >
          <div className={classes.newEHRMappingModal}>
            {!athenaConfigToEdit ? (
              <div>
                {/* <Typography variant="h4" component="h2">
                    Athena Configs
                  </Typography>
                  <div>
                    {athenaConfigList.map((config, i) => (
                      <div key={i} onClick={() => this.selectAthenaConfig(config)}>
                        {config.athenaPracticeId}
                      </div>
                    ))}
                  </div> */}
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginBottom: 30,
                  }}
                >
                  {creatingNewAthenaConfig ? (
                    <div>
                      <Typography
                        variant="h4"
                        component="h2"
                        style={{ marginRight: 10 }}
                      >
                        New Athena Config
                      </Typography>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flex: '' }}>
                      <Typography
                        variant="h4"
                        component="h2"
                        style={{ marginRight: 10 }}
                      >
                        Editing
                      </Typography>
                      <Select
                        value={athenaConfigToEdit.athenaPracticeId}
                        // onChange={handleChange}
                        onChange={e => {
                          onSelectAthenaConfig(e.target.value);
                        }}
                      >
                        {athenaConfigList.map((config, i) => (
                          <MenuItem key={i} value={config.athenaPracticeId}>
                            {config.athenaPracticeId}
                          </MenuItem>
                        ))}
                        <MenuItem value={'new'}>+ New Config</MenuItem>
                      </Select>
                    </div>
                  )}
                </div>

                {/* {console.log("ConfigToEdit", athenaConfigToEdit)} */}

                {creatingNewAthenaConfig ? (
                  <TextField
                    label="Athena Practice ID"
                    type="number"
                    value={athenaConfigToEdit.athenaPracticeId}
                    className={classes.textField}
                    style={{ marginBottom: 10 }}
                    onChange={e => {
                      if (e.target.value) {
                        setAthenaConfigToEdit({
                          ...athenaConfigToEdit,
                          athenaPracticeId: parseInt(e.target.value),
                        });
                      }
                    }}
                  />
                ) : null}

                {creatingNewAthenaConfig ? null : (
                  <div>
                    <div style={{ color: 'gray' }}>Departments to Include:</div>
                    {athenaConfigToEdit.deptsToInclude.map(
                      (dept: number, i: number) => (
                        <Chip
                          key={i}
                          label={dept}
                          onDelete={() => {
                            onDeleteDeptToInclude(dept);
                          }}
                          style={{ marginRight: 5, marginBottom: 5 }}
                        />
                      )
                    )}

                    {athenaDepartments ? (
                      <Autocomplete
                        id="department-search"
                        key={departmentSearchKey}
                        autoHighlight
                        // options={athenaDepartments}
                        options={athenaDepartments.filter((dept: any) => {
                          return !athenaConfigToEdit.deptsToInclude.includes(
                            parseInt(dept.departmentid)
                          );
                        })}
                        getOptionLabel={dept => dept.departmentid}
                        style={{ marginBottom: 10 }}
                        onChange={(event: any, department: any) => {
                          if (department) {
                            // this.toggleDepartment(department.id, true);
                            onSelectAthenaDept(
                              parseInt(department.departmentid)
                            );
                            setDepartmentSearchKey(departmentSearchKey + 1);
                          }
                        }}
                        renderInput={params => (
                          <TextField {...params} label="Search Department" />
                        )}
                      />
                    ) : null}
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginBottom: 10,
                  }}
                >
                  {/* Column 1 */}
                  <div style={{ width: '50%' }}>
                    <div
                      onClick={() => {
                        setAthenaConfigToEdit({
                          ...athenaConfigToEdit,
                          includeSaturday: !athenaConfigToEdit.includeSaturday,
                        });
                      }}
                    >
                      <Checkbox
                        checked={athenaConfigToEdit.includeSaturday}
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                      />
                      Include Saturday
                    </div>
                    <div
                      onClick={() => {
                        setAthenaConfigToEdit({
                          ...athenaConfigToEdit,
                          includeSunday: !athenaConfigToEdit.includeSunday,
                        });
                      }}
                    >
                      <Checkbox
                        checked={athenaConfigToEdit.includeSunday}
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                      />
                      Include Sunday
                    </div>
                    <div
                      onClick={() => {
                        setAthenaConfigToEdit({
                          ...athenaConfigToEdit,
                          isPreview: !athenaConfigToEdit.isPreview,
                        });
                      }}
                    >
                      <Checkbox
                        checked={athenaConfigToEdit.isPreview}
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                      />
                      Is Preview
                    </div>

                    <div
                      onClick={() => {
                        setAthenaConfigToEdit({
                          ...athenaConfigToEdit,
                          overrideProviderWithRenderingProvider:
                            !athenaConfigToEdit.overrideProviderWithRenderingProvider,
                        });
                      }}
                    >
                      <Checkbox
                        checked={
                          athenaConfigToEdit.overrideProviderWithRenderingProvider
                        }
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                      />
                      Override Provider with rendering Provider
                    </div>
                  </div>

                  {/* Column 2 */}
                  <div>
                    <TextField
                      style={{ width: '100%' }}
                      label="Local Start Time"
                      type="time"
                      value={
                        athenaConfigToEdit.localTimeToStart
                          ? moment(athenaConfigToEdit.localTimeToStart).format(
                              'HH:mm'
                            )
                          : ''
                      }
                      // defaultValue={selectedStartDate}
                      // onChange={this.handleSurveyStartDateChange()}
                      onChange={e => {
                        onLocalTimeToStartChange(e.target.value);
                      }}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      style={{ width: '100%' }}
                      label="Local End Time"
                      type="time"
                      value={
                        athenaConfigToEdit.localTimeToEnd
                          ? moment(athenaConfigToEdit.localTimeToEnd).format(
                              'HH:mm'
                            )
                          : ''
                      }
                      // defaultValue={selectedStartDate}
                      // onChange={this.handleSurveyStartDateChange()}
                      onChange={e => {
                        onLocalTimeToEndChange(e.target.value);
                      }}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <FormControl className={classes.textField}>
                      <InputLabel shrink htmlFor="age-native-label-placeholder">
                        Time Zone
                      </InputLabel>
                      <NativeSelect
                        value={
                          athenaConfigToEdit.timeZoneId
                            ? athenaConfigToEdit.timeZoneId
                            : 9
                        }
                        onChange={e => {
                          setAthenaConfigToEdit({
                            ...athenaConfigToEdit,
                            timeZoneId: e.target.value,
                          });
                        }}
                        input={<Input id="age-native-label-placeholder" />}
                      >
                        <option value={3}>HST</option>
                        <option value={4}>AKST</option>
                        <option value={6}>PST</option>
                        <option value={7}>MST-AZ</option>
                        <option value={9}>MST</option>
                        <option value={10}>CST</option>
                        <option value={15}>EST</option>
                        <option value={16}>AST-PR</option>
                      </NativeSelect>
                    </FormControl>

                    {/* <div style={{ padding: 9 }}>
                        <input type="time" />
                        Local Start Time
                      </div>
                      <div style={{ padding: 9 }}>
                        <input type="time" />
                        Local End Time
                      </div> */}
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  {creatingNewAthenaConfig ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onCreateAthenaConfig}
                      disabled={creatingConfigInProgress}
                    >
                      Create Athena Config
                    </Button>
                  ) : (
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={onUpdateAthenaConfig}
                        disabled={updatingConfigInProgress}
                      >
                        Update Athena Config
                      </Button>
                      {/* <CircularProgress style={{ color: "#4FAC48" }} /> */}

                      <Button
                        variant="contained"
                        onClick={onDeleteConfig}
                        style={{ float: 'right' }}
                      >
                        Delete Config
                      </Button>
                    </div>
                  )}
                  {creatingConfigInProgress ? (
                    <CircularProgress
                      style={{ color: '#4FAC48', marginLeft: 10 }}
                    />
                  ) : null}
                </div>

                <br />

                {creatingNewAthenaConfig ? (
                  <div></div>
                ) : (
                  <div>
                    {athenaPatients ? (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            Patient Locations ({athenaPatients?.length})
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer
                            component={Paper}
                            style={{ maxHeight: '50vh' }}
                          >
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() => {
                                      setSortAthenaPatientLocationsBy(
                                        'patientlocationid'
                                      );
                                    }}
                                  >
                                    {sortAthenaPatientLocationsBy ===
                                    'patientlocationid' ? (
                                      <div>
                                        Location ID{' '}
                                        {sortAthenaPatientLocationsDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Location ID</div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() => {
                                      onSortAthenaPatientLocationsBy('name');
                                    }}
                                  >
                                    {sortAthenaPatientLocationsBy === 'name' ? (
                                      <div>
                                        Name{' '}
                                        {sortAthenaPatientLocationsDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Name</div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {sortedAthenaPatientLocations?.map(
                                  (patient: any, i: number) => (
                                    <TableRow key={i}>
                                      <TableCell>
                                        {patient.patientlocationid}
                                      </TableCell>
                                      <TableCell>{patient.name}</TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    ) : null}

                    {athenaDepartments ? (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            Departments ({athenaDepartments?.length})
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer
                            component={Paper}
                            style={{ maxHeight: '50vh' }}
                          >
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() => {
                                      onSortAthenaDepartmentsBy('departmentid');
                                    }}
                                  >
                                    {sortAthenaDepartmentsBy ===
                                    'departmentid' ? (
                                      <div>
                                        Department ID{' '}
                                        {sortAthenaDepartmentsDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Department ID</div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() =>
                                      onSortAthenaDepartmentsBy('name')
                                    }
                                  >
                                    {sortAthenaDepartmentsBy === 'name' ? (
                                      <div>
                                        Name{' '}
                                        {sortAthenaDepartmentsDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Name</div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() =>
                                      onSortAthenaDepartmentsBy('address')
                                    }
                                  >
                                    {sortAthenaDepartmentsBy === 'address' ? (
                                      <div>
                                        Address{' '}
                                        {sortAthenaDepartmentsDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Address</div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() =>
                                      onSortAthenaDepartmentsBy('city')
                                    }
                                  >
                                    {sortAthenaDepartmentsBy === 'city' ? (
                                      <div>
                                        City{' '}
                                        {sortAthenaDepartmentsDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>City</div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {sortedAthenaDepartments?.map(
                                  (department: any, i: number) => (
                                    <TableRow key={i}>
                                      <TableCell>
                                        {department.departmentid}
                                      </TableCell>
                                      <TableCell>{department.name}</TableCell>
                                      <TableCell>
                                        {department.address}
                                      </TableCell>
                                      <TableCell>{department.city}</TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    ) : null}

                    {athenaProviders ? (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            Providers ({athenaProviders?.length})
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer
                            component={Paper}
                            style={{ maxHeight: '50vh' }}
                          >
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() =>
                                      onSortAthenaProvidersBy('providerid')
                                    }
                                  >
                                    {sortAthenaProvidersBy === 'providerid' ? (
                                      <div>
                                        Provider ID{' '}
                                        {sortAthenaProvidersDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Provider ID</div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() =>
                                      onSortAthenaProvidersBy('displayname')
                                    }
                                  >
                                    {sortAthenaProvidersBy === 'displayname' ? (
                                      <div>
                                        Display Name{' '}
                                        {sortAthenaProvidersDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Display Name</div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.sortSelect}
                                    onClick={() =>
                                      onSortAthenaProvidersBy('specialty')
                                    }
                                  >
                                    {sortAthenaProvidersBy === 'specialty' ? (
                                      <div>
                                        Specialty{' '}
                                        {sortAthenaProvidersDescending ? (
                                          <>▼</>
                                        ) : (
                                          <>▲</>
                                        )}
                                      </div>
                                    ) : (
                                      <div>Specialty</div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {sortedAthenaUsers?.map(
                                  (provider: any, i: number) => (
                                    <TableRow key={i}>
                                      <TableCell>
                                        {provider.providerid}
                                      </TableCell>
                                      <TableCell>
                                        {provider.displayname}
                                      </TableCell>
                                      <TableCell>
                                        {provider.specialty}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>

        <Modal
          open={newLinkModalOpen && ehrMappingToEdit ? true : false}
          onClose={onCloseLinkModal}
        >
          <div className={classes.newEHRMappingModal}>
            {ehrMappingToEdit ? (
              <div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant="h4" gutterBottom component="h2">
                    Linked {ehrMappingToEdit?.ehrMappingType}
                  </Typography>
                  <div
                    onClick={onCloseLinkModal}
                    style={{
                      color: 'red',
                      textAlign: 'right',
                      fontSize: 32,
                      cursor: 'pointer',
                    }}
                  >
                    X
                  </div>
                </div>
                <div>
                  <div>
                    {/* <FormControl className={classes.textField} margin="normal">
                        <InputLabel shrink htmlFor="age-native-label-placeholder">
                          ID Selection
                        </InputLabel>
                        <NativeSelect value={newLinkObjectId} onChange={this.selectNewLinkId()} input={<Input id="age-native-label-placeholder" />}>
                          <option />
                          {newLinkIdOptions}
                        </NativeSelect>
                      </FormControl> */}

                    <Autocomplete
                      style={{ marginBottom: 10 }}
                      key={uniqid()}
                      autoHighlight
                      options={autoCompleteNewLinkIdOptions}
                      getOptionLabel={(option: { label: string }) =>
                        option.label
                      }
                      // onChange={this.selectNewLinkId()}
                      onChange={(event, option) => {
                        onSelectNewLinkId(option);
                      }}
                      renderInput={params => (
                        <TextField
                          className={classes.autoCompleteTextField}
                          {...params}
                          // autoFocus
                          // inputRef={(input) => {
                          //   providerInputRef = input;
                          // }}
                          label="ID Selection"
                        />
                      )}
                    />
                  </div>

                  {ehrMappingToEdit.ehrMappingType !== 'Provider/Resource' ? (
                    <div
                      onClick={() => {
                        setNewLinkObjectIsDefault(!newLinkObjectIsDefault);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Checkbox checked={newLinkObjectIsDefault} />
                      Is Default
                    </div>
                  ) : null}

                  <Button
                    disabled={newLinkObjectId ? false : true}
                    variant="contained"
                    color="primary"
                    onClick={onCreateNewLink}
                  >
                    Create Link
                  </Button>
                  <br />
                </div>
                <br />
                <br />
                <div style={{ fontSize: 18, marginTop: 25 }}>
                  Current Link(s):{' '}
                  {ehrMappingToEdit.linkedSyncTimesObjects.length === 0 ? (
                    <div>None</div>
                  ) : null}
                </div>
                <br />
                <div style={{ maxHeight: '40vh', overflow: 'scroll' }}>
                  {alphabetizedSyncTimesObjects.map(
                    (linkedObject: any, i: any) => (
                      <div key={i}>
                        {/* <hr /> */}
                        <Paper style={{ padding: 20, marginBottom: 10 }}>
                          <div>
                            <strong>Name: </strong>
                            {linkedObject.syncTimesName}
                          </div>
                          <div>
                            <strong>Id: </strong>
                            {linkedObject.id}
                          </div>
                          {/* <div>Default: {linkedObject.isDefault ? "TRUE" : "FALSE"}</div> */}
                          <div style={{ marginTop: 10 }}>
                            {ehrMappingToEdit.ehrMappingType !==
                              'Provider/Resource' &&
                            linkedObject.isDefault === false ? (
                              <Button
                                color="primary"
                                variant="contained"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                  onMakeLinkedObjectDefault(linkedObject);
                                }}
                              >
                                Make Default
                              </Button>
                            ) : null}

                            {ehrMappingToEdit.ehrMappingType !==
                            'Provider/Resource' ? (
                              <Button
                                className={classes.deleteButton}
                                onClick={() =>
                                  onDeleteLinkedObject(linkedObject)
                                }
                              >
                                Delete Link
                              </Button>
                            ) : null}
                          </div>
                        </Paper>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </Modal>

        <Modal open={newEHRMappingModalOpen} onClose={onCloseModal}>
          <div className={classes.newEHRMappingModal}>
            <Dialog
              onClose={() => {
                setImportReportDialog(false);
                setLatestSuccesses(0);
                setLatestFails(0);
              }}
              open={importReportDialog}
            >
              <DialogTitle>Import Results</DialogTitle>
              <DialogContent>
                <div style={{ fontSize: 24, color: '#4FAC48' }}>
                  Success: {latestSuccesses}
                </div>
                <div
                  style={{
                    height: 1,
                    width: '100%',
                    backgroundColor: 'lightgray',
                  }}
                />
                <div style={{ fontSize: 24, color: 'red' }}>
                  Failure: {latestFails}
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() => {
                      setImportReportDialog(false);
                      setLatestSuccesses(0);
                      setLatestFails(0);
                    }}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 25 }}
                  >
                    Return
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Typography variant="h4" gutterBottom component="h2">
              New EHR Mapping(s)
            </Typography>

            <div>
              {!completedCSVImport ? (
                <div>
                  <Importer
                    assumeNoHeaders={false}
                    restartable={false}
                    processChunk={async (rows, { startIndex }) => {
                      rows.forEach(row => {
                        importedEHRMappings.push(row);
                      });
                    }}
                    onComplete={({ file, preview, fields, columnFields }) => {
                      onProcessEHRMappings(importedEHRMappings);
                      setCompletedCSVImport(true);
                      importedEHRMappings = [];
                    }}
                  >
                    <ImporterField name="ehrName" label="Name" />
                    <ImporterField name="ehrId" label="EHR Identifier" />
                    <ImporterField
                      name="ehrMappingType"
                      label="EHR Mapping Type"
                    />
                    <ImporterField
                      name="ehrMappingStatus"
                      label="EHR Mapping Status"
                    />
                  </Importer>
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      const newMapping: IEHRMapping = {
                        ...EHR_TEMPLATE,
                        id: '', // This will likely be generated on the server
                        tempId: uniqid(),
                        orgId: loggedInUserData.mongoOrganizationId, // Assuming this is available in the component
                      };
                      setNewEHRMappings([newMapping]);
                      setCompletedCSVImport(true);
                    }}
                  >
                    Manually add EHR Mappings
                  </Button>
                </div>
              ) : (
                <div>
                  <TableContainer
                    component={Paper}
                    style={{ maxHeight: '75vh' }}
                  >
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>
                            <div>Name</div>
                            {ehrNameErrorFound ? (
                              <div style={{ color: 'red', fontSize: 12 }}>
                                * Name is missing
                              </div>
                            ) : null}
                          </TableCell>
                          <TableCell>
                            <div>EHR Identifier</div>
                            {ehrIdErrorFound ? (
                              <div style={{ color: 'red', fontSize: 12 }}>
                                * EHR Identifier is missing
                              </div>
                            ) : null}
                          </TableCell>
                          <TableCell>
                            <div>EHR Mapping Type</div>
                          </TableCell>
                          <TableCell>
                            <div>EHR Mapping Status</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {newEHRMappings &&
                          newEHRMappings.map((ehrMapping: any, index: number) =>
                            !ehrMapping.importSuccess ? (
                              <TableRow key={index} style={{ height: 10 }}>
                                <TableCell
                                  onClick={() => {
                                    onDeleteNewEHRMapping(ehrMapping.tempId);
                                  }}
                                  style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                    padding: 10,
                                    textAlign: 'center',
                                  }}
                                >
                                  X
                                </TableCell>
                                <TableCell
                                  style={{ padding: 0 }}
                                  component="th"
                                  scope="ehrMapping"
                                >
                                  <TextField
                                    autoFocus
                                    // error={ehrMapping.ehrNameError}
                                    required
                                    className={classes.textField}
                                    value={ehrMapping.ehrName}
                                    onChange={event =>
                                      onHandleNewEHRMappingValue({
                                        name: 'ehrName',
                                        tempId: ehrMapping.tempId,
                                        event,
                                      })
                                    }
                                    margin="dense"
                                  />
                                </TableCell>
                                <TableCell
                                  style={{ padding: 0 }}
                                  component="th"
                                  scope="ehrMapping"
                                >
                                  <TextField
                                    autoFocus
                                    // error={ehrMapping.ehrNameError}
                                    required
                                    className={classes.textField}
                                    value={ehrMapping.ehrId}
                                    onChange={event =>
                                      onHandleNewEHRMappingValue({
                                        name: 'ehrId',
                                        tempId: ehrMapping.tempId,
                                        event,
                                      })
                                    }
                                    margin="dense"
                                  />
                                </TableCell>
                                <TableCell
                                  style={{ padding: 0 }}
                                  component="th"
                                  scope="ehrMapping"
                                >
                                  <FormControl
                                    className={classes.textField}
                                    style={{ width: 200 }}
                                    margin="normal"
                                  >
                                    <NativeSelect
                                      value={ehrMapping.ehrMappingType}
                                      onChange={event =>
                                        onHandleNewEHRMappingValue({
                                          name: 'ehrMappingType',
                                          tempId: ehrMapping.tempId,
                                          event,
                                        })
                                      }
                                      input={
                                        <Input id="age-native-label-placeholder" />
                                      }
                                    >
                                      <option value={'Provider/Resource'}>
                                        Provider/Resource
                                      </option>
                                      <option value={'Department'}>
                                        Department
                                      </option>
                                      <option value={'Appointment Type'}>
                                        Appointment Type
                                      </option>
                                      <option value={'Appointment Status'}>
                                        Appointment Status
                                      </option>
                                      <option value={'Quality Measure'}>
                                        Quality Measure
                                      </option>
                                    </NativeSelect>
                                  </FormControl>
                                </TableCell>
                                <TableCell
                                  style={{ padding: 0 }}
                                  component="th"
                                  scope="ehrMapping"
                                >
                                  <FormControl
                                    className={classes.textField}
                                    style={{ width: 200 }}
                                    margin="normal"
                                  >
                                    <NativeSelect
                                      value={ehrMapping.ehrMappingStatus}
                                      onChange={event =>
                                        onHandleNewEHRMappingValue({
                                          name: 'ehrMappingStatus',
                                          tempId: ehrMapping.tempId,
                                          event,
                                        })
                                      }
                                      input={
                                        <Input id="age-native-label-placeholder" />
                                      }
                                    >
                                      {/* <option value={"All"}>All</option>
                                      <option value={"Linked"}>Linked</option> */}

                                      {ehrMapping.ehrMappingType ===
                                      'Appointment Status' ? (
                                        <>
                                          <option value={'Register'}>
                                            Register
                                          </option>
                                          <option value={'CheckIn'}>
                                            CheckIn
                                          </option>
                                          <option value={'CheckOut'}>
                                            CheckOut
                                          </option>
                                          <option value={'Cancel'}>
                                            Cancel
                                          </option>
                                          <option value={'Confirm'}>
                                            Confirm
                                          </option>
                                          <option value={'Excluded'}>
                                            Excluded
                                          </option>
                                        </>
                                      ) : (
                                        <>
                                          <option value={'Excluded'}>
                                            Excluded
                                          </option>
                                          <option value={'Included'}>
                                            Included
                                          </option>
                                        </>
                                      )}
                                    </NativeSelect>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ) : null
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div style={{ marginTop: 25, float: 'right' }}>
                    <Button
                      onClick={onAddRow}
                      style={{ marginRight: 25 }}
                      variant="contained"
                    >
                      Add Row
                    </Button>
                    {ehrMappingImportsLeft === 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={onCloseModal}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={onCreateEhrMappings}
                      >
                        {newEHRMappings.length > 1
                          ? 'Create Mappings'
                          : 'Create Mapping'}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>

        <Drawer anchor="right" open={openDrawer} onClose={onCloseDrawer}>
          {ehrMappingToEdit ? (
            <div className={classes.drawer}>
              <div className={classes.closeButton} onClick={onCloseDrawer}>
                <CloseButton />
              </div>
              <h2>Editing {ehrMappingToEdit.ehrName}</h2>
              <br />
              <FormControl
                className={classes.textField}
                style={{ width: 200 }}
                margin="normal"
              >
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  EHR Mapping Status
                </InputLabel>
                <NativeSelect
                  value={ehrMappingToEdit.ehrMappingStatus}
                  onChange={event =>
                    onHandleChange({ name: 'ehrMappingStatus', event })
                  }
                  input={<Input id="age-native-label-placeholder" />}
                >
                  {ehrMappingToEdit.ehrMappingType === 'Appointment Status' ? (
                    <>
                      <option value={'Register'}>Register</option>
                      <option value={'CheckIn'}>CheckIn</option>
                      <option value={'CheckOut'}>CheckOut</option>
                      <option value={'Cancel'}>Cancel</option>
                      <option value={'Confirm'}>Confirm</option>
                      <option value={'Excluded'}>Excluded</option>
                    </>
                  ) : (
                    <>
                      <option value={'Excluded'}>Excluded</option>
                      <option value={'Included'}>Included</option>
                    </>
                  )}
                </NativeSelect>
              </FormControl>
              <br />
              <Button
                color="primary"
                variant="contained"
                onClick={onOpenLinkModal}
              >
                Manage Links
              </Button>
              <br />
              <br />
              <div style={{ fontSize: 18, marginTop: 25 }}>
                Current Link(s):{' '}
                {ehrMappingToEdit.linkedSyncTimesObjects.length === 0 ? (
                  <div>None</div>
                ) : null}
              </div>
              {alphabetizedSyncTimesObjects.map((linkedObject: any, i: any) => (
                <div key={i}>
                  {/* <hr /> */}
                  <Paper style={{ padding: 20, marginBottom: 10 }}>
                    <div>
                      <strong>Name: </strong>
                      {linkedObject.syncTimesName}
                    </div>
                    <div>
                      <strong>Id: </strong>
                      {linkedObject.id}
                    </div>
                    {/* <div>Default: {linkedObject.isDefault ? "TRUE" : "FALSE"}</div> */}
                  </Paper>
                </div>
              ))}
              <br />
              <br />
              <Button
                className={classes.deleteButton}
                onClick={onDeleteEHRMapping}
              >
                Delete EHR Mapping
              </Button>
              {/* {hasModified ? <div className={classes.saveWarning}>Unsaved Changes!</div> : <div />} */}
              <Button
                className={classes.submitButton}
                onClick={() => onHandleSubmit()}
                color="primary"
              >
                Save
              </Button>
            </div>
          ) : (
            <div />
          )}
        </Drawer>
        {saveSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="EHR Mapping Updated"
          />
        ) : null}
        {athenaConfigCreateSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Athena Config Created!"
          />
        ) : (
          <div />
        )}
        {athenaConfigEditSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Athena Config Updated!"
          />
        ) : (
          <div />
        )}
        {athenaConfigDeleteSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Athena Config Deleted!"
          />
        ) : (
          <div />
        )}
        {athenaError ? (
          <ErrorSnack
            errorSnack={true}
            errorMessage="SyncTimes does not have access to this Practice Id"
          />
        ) : (
          <div />
        )}
      </MuiThemeProvider>
    </div>
  );
};

// const mapStateToProps = (state: any) => ({
//   loggedInUserData: state.adminData.loggedInUserData,
//   departments: state.adminData.departments,
//   usersData: state.adminData.usersData,
//   organization: state.adminData.organization,
// });

// export default connect(mapStateToProps, { fetchdepartments, fetchAppUsersByOrgId })(withStyles(styles)(EHRMapping));

export default EHRMapping;
