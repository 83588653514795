import { Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { FC, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNotify } from '../../../../../common/notify/useNotify';
import { useDeviceAction } from '../../../../../hooks/useDeviceAction';
import {
  IAction,
  IApp,
  IDevice,
  TAdminState,
} from '../../../../../interfaces/interfaces';
import { useStyles } from './styles';
import { useApps } from '../../../../../hooks/useApps';

import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

type TProps = {
  actionTypes: IAction[];
  filteredDevices: IDevice[];
};

const DeviceActions: FC<TProps> = props => {
  const { actionTypes, filteredDevices } = props;
  const classes = useStyles();

  const { activeApps } = useApps();

  const { deviceActionSend } = useDeviceAction();
  const [appSelected, setAppSelected] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<{
    appId: string | null;
    appName: string | null;
    appVersion: string | null;
  }>({
    appId: null,
    appName: null,
    appVersion: null,
  });

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const userId = loggedInUserData?.userId;

  const { notify } = useNotify();

  const [selectedAction, setSelectedAction] = useState<IAction>(null);

  const onApplyToList = (devices: IDevice[] = []) => {
    return deviceActionSend({
      deviceIds: devices.map(device => device.deviceId),
      selectedAction: selectedAction as IAction,
      userId,
      selectedApp: selectedApp as IApp,
      onSuccess: function (): void {
        notify({
          message: 'Action sent successfully',
          variant: 'success',
        });

        onClose();
      },
      onFail: function (error: any): void {
        notify({
          message: 'Failed to send action',
          variant: 'error',
        });

        onClose();
      },
    });
  };

  const onAssignApp = (app: IApp) => {
    console.log('App selected:  ', app);
    setAppSelected(true);
    setSelectedApp({
      appId: app.appId,
      appName: app.appName,
      appVersion: app.appVersion,
    });
  };

  const onAssignAction = (input: {
    action: IAction;
    deviceId: string | null;
  }) => {
    const { action } = input;
    setSelectedAction(action);
  };

  const onClose = () => {
    setSelectedAction(null);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        {actionTypes.map((action: IAction) => (
          <div key={action.actionTypeId}>
            <div
              className={classes.actionButton}
              onClick={() => onAssignAction({ action, deviceId: null })}
            >
              {action.actionTypeName}
            </div>
          </div>
        ))}
      </Grid>

      <Grid item xs={6} className={classes.autoMargin}>
        {/* Standard Action */}
        {selectedAction && selectedAction?.actionTypeId !== 4 && (
          <div className={classes.confirmText}>
            Execute <strong>{selectedAction.actionTypeName}?</strong> <br />
            <div className="flex gap-2 justify-center">
              <Button
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon />
                &nbsp;Cancel
              </Button>
              <Button
                className={classes.executeButton}
                variant="outlined"
                onClick={() => onApplyToList(filteredDevices)}
              >
                {filteredDevices.length > 1 ? (
                  <>
                    <WarningIcon />
                    Apply to ({filteredDevices.length}) Devices
                  </>
                ) : (
                  <>
                    <WarningIcon />
                    Apply to Device
                  </>
                )}
              </Button>
            </div>
          </div>
        )}

        {selectedAction && selectedAction?.actionTypeId === 4 ? (
          <div className={classes.confirmText}>
            {appSelected ? (
              <Alert className={classes.alertStyle}>
                <AlertTitle className={classes.alertTitleStyle}>
                  Confirm Installation
                </AlertTitle>
                <p className={classes.messageStyle}>
                  Are you sure you want to Install
                  <br />
                  <strong>{selectedApp.appName}</strong>?
                </p>
                <div className={classes.buttonContainerStyle}>
                  <Button
                    variant="outlined"
                    onClick={() => setAppSelected(false)}
                    className={classes.cancelButtonStyle}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onApplyToList(filteredDevices)}
                    className={classes.installButtonStyle}
                  >
                    <WarningIcon className={classes.iconStyle} />
                    {filteredDevices.length > 1
                      ? `Install app to (${filteredDevices.length}) devices`
                      : 'Install app to device'}
                  </Button>
                </div>
              </Alert>
            ) : (
              <div className=" bg-white rounded-lg shadow-md">
                <Typography
                  variant="h6"
                  className="mb-2 font-bold text-gray-800"
                >
                  Which app would you like installed?
                </Typography>
                <Divider className="mb-2" />
                <List className="space-y-2">
                  {activeApps.map((app, index) => (
                    <ListItem
                      key={index}
                      className="cursor-pointer hover:bg-gray-100 rounded transition-colors duration-200"
                      onClick={() => onAssignApp(app)}
                    >
                      <ListItemText
                        primary={app.appName}
                        secondary={
                          <Typography variant="body2" className="text-gray-500">
                            {app.appVersion}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
          </div>
        ) : (
          <div />
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceActions;
