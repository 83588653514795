import { Button } from '@material-ui/core';
import SynctimesIcon from './images/synctimesicon.png';

const RollbarFallback: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <img
        src={SynctimesIcon}
        style={{
          maxWidth: '100%',
          height: 'auto',
          maxHeight: '300px',
        }}
        alt="SyncTimes Icon"
      />
      <div
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          fontSize: '1.5em',
        }}
      >
        Uh oh. Something didn't quite go as planned. The error you ran into has
        been reported to the SyncTimes team!
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.location.replace('/')}
      >
        Return Home
      </Button>
    </div>
  );
};

export default RollbarFallback;
