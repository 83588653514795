import React, { FC } from 'react';
import { IUser } from '../../../../../interfaces/interfaces';
import { find } from 'lodash';
import { useStyles } from '../styles';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from './constants';

type TProps = {
  actionData: any;
  user: IUser;
  darkMode: boolean;
  greencheck: string;
  greencheck5: string;
  greencheck10: string;
  greencheck15: string;
  redcheck: string;
  respondToAction: (...params: any) => any;
};

const ActionResponse: FC<TProps> = props => {
  const {
    user,
    actionData,
    darkMode,
    redcheck,
    greencheck15,
    greencheck10,
    greencheck,
    greencheck5,
    respondToAction,
  } = props;

  const classes = useStyles();

  const items: {
    responseType: number;
    check: string;
  }[] = [
    {
      responseType: 1,
      check: greencheck5,
    },
    {
      responseType: 2,
      check: greencheck10,
    },
    {
      responseType: 3,
      check: greencheck15,
    },
    {
      responseType: 4,
      check: greencheck,
    },
    {
      responseType: 5,
      check: redcheck,
    },
  ];

  return (
    <div style={{ textAlign: 'center', color: 'gray' }}>
      Action Response
      <div
        className="flex justify-center"
        style={{ marginTop: 10, marginBottom: 20 }}
      >
        {items.map((item, index) => {
          return (
            <img
              key={index}
              style={
                find(actionData.actionResponses, {
                  userId: user.id,
                  responseType: item?.responseType,
                })
                  ? {
                      borderBottom: '3px solid #0096DB',
                      paddingBottom: 2,
                      height: IMAGE_HEIGHT,
                      width: IMAGE_WIDTH,
                    }
                  : {
                      borderBottom: darkMode
                        ? '3px solid #333333'
                        : '3px solid white',
                      paddingBottom: 2,
                      height: IMAGE_HEIGHT,
                      width: IMAGE_WIDTH,
                    }
              }
              onClick={() => respondToAction(item.responseType)}
              src={item?.check}
              className={classes.actionResponseSelection}
              alt=""
            />
          );
        })}
      </div>
    </div>
  );
};

export default ActionResponse;
