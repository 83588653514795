import { createTheme, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto" as const,
  },
  table: {
    minWidth: 700,
  },
  drawer: {
    padding: 50,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 185,
  },
  saveWarning: {
    color: "red",
    paddingBottom: 10,
  },
  newSiteButton: {
    zIndex: 10,
    backgroundColor: "#4FAC48",
    position: "absolute",
    right: theme.spacing(3),
  },
  tableRow: {
    cursor: "pointer",
  },
  sortSelect: {
    cursor: "pointer",
  },
  closeButton: {
    cursor: "pointer",
    color: "red",
    textAlign: "right" as const,
  },
  submitButton: {
    backgroundColor: "#F0F1F9",
  },
  colorSwitchBase: {
    color: "#4FAC48",
    "&$colorChecked": {
      color: "#4FAC48",
      "& + $colorBar": {
        backgroundColor: "#4FAC48",
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  head: {
    backgroundColor: "#fafafa",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: (theme.typography.fontWeightRegular as number) || 400,
  },
  unlockButton: {
    marginRight: 10,
    backgroundColor: "#50b848",
    color: "#FFFFFF",
    textDecoration: "none", // Better to set this on the button's inner text
    marginTop: 10,
    marginBottom: 10,
  },
}));

// Tooltip Modifications
export const theme = createTheme({
  palette: {
    secondary: {
      main: "#A7D6A4",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
});
