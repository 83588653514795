import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IOrganization } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useOrg = (
  orgId: string,
  options?: {
    shouldFetch?: boolean;
  }
) => {
  const [organization, setOrganization] = useState<IOrganization | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const shouldFetch = options?.shouldFetch ?? true;

  const fetchOrg = useCallback((orgId: string): Promise<IOrganization> => {
    if (!orgId) return Promise.resolve(null);

    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);

      const token = localStorage.getItem('token');

      axios({
        method: 'GET',
        url: API.REACT_APP_API_ORGANIZATION + orgId,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          setIsLoading(false);

          const organization: IOrganization = response?.data?.data;
          setOrganization(organization);

          resolve(organization);
        })
        .catch(err => {
          handleError(err);
          setIsLoading(false);
          reject(err);
        });
    });
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      fetchOrg(orgId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const handleError = (err: any) => {
    console.error('Error:', err);
    setError(
      'An error occurred while submitting the organization. Please try again.'
    );
    setIsLoading(false);
    if (err.response?.status === 401) {
      checkRefreshToken();
    }
  };

  return {
    refetch: fetchOrg,
    organization,
    isLoading,
    error,
  };
};
