import Fuse from 'fuse.js';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useStaffLocations } from '../../../../hooks/useStaffLocations';
import { IStaffMember, TAdminState } from '../../../../interfaces/interfaces';
import { RootState } from '../../../../store';
import LocateStaffResult from './LocateStaffResult';

interface StaffListProps {
  staffSearchKeyword: string;
  serverTimeOffset: number;
  onHighlightMember: (member: IStaffMember) => void;
}

const LocateStaffPopover: React.FC<StaffListProps> = ({
  staffSearchKeyword,
  serverTimeOffset,
  onHighlightMember,
}) => {
  const { loggedInUserData } = useSelector((state: RootState) => {
    const adminState = state.adminData as TAdminState;

    return {
      ...adminState,
    };
  }, shallowEqual);

  const { staffLocations } = useStaffLocations({
    userId: loggedInUserData?.id,
    orgId: loggedInUserData.mongoOrganizationId,
  });

  let staffSearchResults: any[] = [];

  if (staffLocations) {
    var fuseOptions = {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'fullName',
        'firstName',
        'lastName',
        'userEmail',
        'lastDeviceLocationName',
      ],
    };

    let fuse = new Fuse(staffLocations, fuseOptions);

    if (staffSearchKeyword.length > 0) {
      staffSearchResults = fuse.search(staffSearchKeyword);
      staffSearchResults = staffSearchResults.map(
        staffSearchResult => staffSearchResult.item
      );
    } else {
      staffSearchResults = staffLocations;
    }
  }

  return (
    <>
      {staffSearchResults.map((staffMember, index) => {
        return (
          <LocateStaffResult
            key={index}
            staffMember={staffMember}
            serverTimeOffset={serverTimeOffset}
            onHighlightMember={onHighlightMember}
          />
        );
      })}
    </>
  );
};

export default LocateStaffPopover;
