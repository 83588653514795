import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

import { IPatientContent } from '../../interfaces/interfaces';
import { API } from '../../apiconfig';

interface UseOrganizationsResult {
  patientContentList: IPatientContent[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IPatientContent[]>;
}

export const usePatientContentList = (input: {
  orgId: string;
  onCompleted?: (patientContentList: IPatientContent[]) => void;
}): UseOrganizationsResult => {
  const { orgId, onCompleted = () => {} } = input;

  const [patientContentList, setPatientContentList] = useState<
    IPatientContent[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPatientContentList = useCallback((): Promise<
    IPatientContent[]
  > => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);

      const token = localStorage.getItem('token');

      let URL = API.REACT_APP_API_PATIENTCONTENT + orgId;

      axios
        .request<{ data: IPatientContent[] }>({
          method: 'GET',
          url: URL,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
          const patientContentList =
            data?.data.map(patientContent => {
              return {
                ...patientContent,
              };
            }) ?? [];

          setPatientContentList(patientContentList);
          onCompleted(patientContentList);
          setIsLoading(false);
          resolve(patientContentList);
        })
        .catch(error => {
          console.error('Error fetching organizations:', error);
          setError(
            'An error occurred while fetching organizations. Please try again.'
          );
          setIsLoading(false);
          reject(error);
        });
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    fetchPatientContentList();

    // eslint-disable-next-line
  }, []);

  const refetchPatientContentList = useCallback((): Promise<
    IPatientContent[]
  > => {
    return fetchPatientContentList();

    // eslint-disable-next-line
  }, []);

  return {
    patientContentList,
    isLoading,
    error,
    refetch: refetchPatientContentList,
  };
};
