import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getLobbySettings, saveLobbySettings } from '../../lobbyDB';
import { TLobbySettings } from '../../types';
import { TFormSchema } from '../lobbySettings/types';

import LobbySettingsForm from './LobbySettingsForm';
import { schema } from './schema';

type TProps = {
  orgId: string;
  onClose: () => void;
};

const LobbySettingsFormContainer: FC<TProps> = props => {
  const { orgId, onClose } = props;

  const [lobbySettings, setLobbySettings] = useState<TLobbySettings>(
    getLobbySettings(orgId)
  );

  const defaultValues: TFormSchema = {
    departmentIds: lobbySettings.departmentsToShow,
  };

  const formMethods = useForm<TFormSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<TFormSchema> = (values: TFormSchema) => {
    console.log('onSubmit', { values });

    const { departmentIds } = values;

    const updatedLobbySettings = saveLobbySettings({
      orgId,
      ...lobbySettings,
      departmentsToShow: departmentIds,
    });

    setLobbySettings(updatedLobbySettings);
  };

  return (
    <div>
      <LobbySettingsForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        orgId={orgId}
        onClose={onClose}
      />
    </div>
  );
};

export default LobbySettingsFormContainer;
