import { Component } from "react";
import { IAction, IDepartment } from "../../../interfaces/interfaces";

import {
  // socket,
  connection,
  allowSignalR,
} from "../../../socket-manager";

const _ = require("lodash");

interface DepartmentItemProps {
  dept: IDepartment;
  actionsToPlaySoundsOn: any;
  mutedActions: any;
  allowSounds: boolean;
}

class DepartmentItem extends Component<DepartmentItemProps> {
  constructor(props: DepartmentItemProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // socket.on("connect", () => {
    //   console.log("%c Connected to Security Websocket ", "background: #4CB946; color: #ffffff");
    //   // socket.emit("subscribeToRoom", this.props.dept.id);
    // });

    if (allowSignalR) {
      if (connection.state === "Connected") {
        connection.send("subscribeToFlowstationWithRoomUpdatesGzip", this.props.dept.id);
        connection.send("subscribeToSiteGzip", this.props.dept.mongoSiteId);
      }
    }

    // socket.emit("subscribeToRoom", this.props.dept.id);
  }

  componentDidUpdate(prevProps: DepartmentItemProps) {
    // console.log("allow sounds?", this.props.allowSounds);
    let oldActions: string[] = [];
    let newActions: string[] = [];
    let allActions: IAction[] = [];

    // console.log("prev props", prevProps);

    this.props.dept.rooms.forEach((room) => {
      room.roomActions.forEach((action: IAction) => {
        newActions.push(action.id);
        allActions.push(action);
      });
    });

    prevProps.dept.rooms.forEach((room) => {
      room.roomActions.forEach((action: IAction) => {
        oldActions.push(action.id);
      });
    });

    let difference = _.difference(newActions, oldActions);
    if (difference[0]) {
      newActions.forEach((action) => {
        if (action === difference[0]) {
          if (_.includes(this.props.actionsToPlaySoundsOn, action)) {
            allActions.forEach((action) => {
              if (action.id === difference[0]) {
                if (!_.includes(this.props.mutedActions, action.id)) {
                  // console.log("Play sound from", action);
                  let audio = new Audio(action.soundUrl);
                  if (this.props.allowSounds) {
                    audio.play();
                  }
                }
              }
            });
          }
        }
      });
    }
  }

  render() {
    return <div />;
  }
}

export default DepartmentItem;
