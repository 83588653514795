import { Dialog, DialogContent, TextField, Button } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useStyles } from './styles';
import moment from 'moment';
import { checkRefreshToken } from '../../utils/utils';
import { API } from '../../apiconfig';
import { TProviderToOverride } from './types';

type TProps = {
  setShowTimeOverride: (value: boolean) => void;
  setProviderToOverride: (value: any) => void;
  showTimeOverride: boolean;
  providerToOverride: TProviderToOverride;
};

const StatusOverrideModal: FC<TProps> = props => {
  const {
    setShowTimeOverride,
    setProviderToOverride,
    showTimeOverride,
    providerToOverride,
  } = props;

  const classes = useStyles();
  const [hoursInEffect, setHoursInEffect] = useState<number>(1);
  const [overrideTime, setOverrideTime] = useState(null);

  const onOverrideTime = () => {
    // console.log("providerToOverride", this.state.providerToOverride);
    // console.log("hoursInEffect"", this.state.hoursInEffect);

    let now = moment.utc();
    let expireDate = now.add(hoursInEffect, 'hours').toISOString();

    // In string format utc

    // console.log("expire date", expireDate);
    // find longest waiting patient
    let selectedPatient: {
      providerId: string;
    } = null;
    let oldestTime: any = null;
    providerToOverride.patients.forEach(patient => {
      let timeInWaitingRoom = moment()
        .local()
        .diff(moment.utc(patient.startVisitTime).local(), 'minutes');
      if (!oldestTime) {
        selectedPatient = patient;
        oldestTime = timeInWaitingRoom;
      } else {
        if (oldestTime < timeInWaitingRoom) {
          selectedPatient = patient;
          oldestTime = timeInWaitingRoom;
        }
      }
    });

    // console.log("oldest time", oldestTime);
    // console.log("oldest patient", selectedPatient);

    if (selectedPatient) {
      let payload = {
        deptId: providerToOverride.deptId,
        providerId: selectedPatient.providerId,
        // orgId: selectedPatient.orgId,
        utcExpirationTime: expireDate,
        overrideProviderStatus: overrideTime,
      };

      // console.log("Payload", payload);

      let token = localStorage.getItem('token');

      fetch(API.REACT_APP_API_PATIENTLOBBY, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          // console.log("res", res);

          setShowTimeOverride(false);
          setProviderToOverride(null);
          setOverrideTime(null);
          setHoursInEffect(1);
        })
        .catch(err => {
          console.log('err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  return (
    <Dialog
      open={showTimeOverride && providerToOverride ? true : false}
      onClose={() => {
        setShowTimeOverride(false);
        setProviderToOverride(null);
      }}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogContent>
        <div
          style={{
            textAlign: 'center',
            fontSize: 32,
            padding: '16px 24px',
          }}
        >
          Status override for{' '}
          <div style={{ fontWeight: 'bold' }}>
            {providerToOverride?.providerName}
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            maxWidth: 130,
            margin: 'auto',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <TextField
            // inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={hoursInEffect}
            variant="outlined"
            label="Hours in effect"
            type="number"
            onChange={e => {
              setHoursInEffect(Number(e.target.value));
            }}
          />
        </div>

        <div
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 24,
          }}
        >
          <div
            onClick={() => {
              setOverrideTime('0');
            }}
            style={overrideTime === '0' ? { backgroundColor: 'lightgray' } : {}}
            className="boldHover"
          >
            On Time
          </div>
          <div
            onClick={() => {
              setOverrideTime('5-15');
            }}
            style={
              overrideTime === '5-15' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            5-15
          </div>
          <div
            onClick={() => {
              setOverrideTime('15-25');
            }}
            style={
              overrideTime === '15-25' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            15-25
          </div>
          <div
            onClick={() => {
              setOverrideTime('25-35');
            }}
            style={
              overrideTime === '25-35' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            25-35
          </div>
          <div
            onClick={() => {
              setOverrideTime('35-45');
            }}
            style={
              overrideTime === '35-45' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            35-45
          </div>
          <div
            onClick={() => {
              setOverrideTime('45-55');
            }}
            style={
              overrideTime === '45-55' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            45-55
          </div>
          <div
            onClick={() => {
              setOverrideTime('55+');
            }}
            style={
              overrideTime === '55+' ? { backgroundColor: 'lightgray' } : {}
            }
            className="boldHover"
          >
            55+
          </div>
        </div>

        <div
          style={{
            textAlign: 'center',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Button
            onClick={onOverrideTime}
            variant="contained"
            color="primary"
            disabled={!hoursInEffect || !overrideTime || hoursInEffect === 0}
          >
            Update time
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StatusOverrideModal;
