import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { API } from '../../../../apiconfig';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start', // Change from 'center' to 'flex-start'
    alignItems: 'flex-start', // Change from 'center' to 'flex-start'
    height: '100vh',
    padding: '10px', // Optional: Add padding to avoid touching the edges
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    marginRight: '10px',
    width: '300px', // Set the width of the TextField
  },
});

const SyncAdminOptions: React.FC = () => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    let token = localStorage.getItem('token');
    // Use the input value here
    console.log('Input value:', inputValue);
    if (inputValue === null || inputValue === "") return;
    let payload = {
      tts: inputValue
    };
    fetch(API.REACT_APP_API_SYNCADMINTESTTTS, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    .then(res => res.json())
    .then(data => {
      const ttsUrl = data.ttsUrl;
      console.log('ttsUrl', ttsUrl);
      const audio = new Audio(ttsUrl);
      audio.play();
    })
      .catch(err => {
        console.log('Error: ', err);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <TextField
          className={classes.textField}
          label="Input Text"
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
        />
        <Button variant="contained" color="primary" onClick={handleClick}>
          Play Text to Speech
        </Button>
      </div>
    </div>
  );
};

export default SyncAdminOptions;