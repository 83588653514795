import React, { Component } from 'react';
// import { withRouter } from "react-router-dom";
import axios from 'axios';
import LogRocket from 'logrocket';
import { connect } from 'react-redux';
import { API } from '../../../apiconfig';
import { checkRefreshToken, getQueryVariable } from '../../../utils/utils';

import PowerReport from './PowerReport';

// Utilities
import { canDo } from '../../../utils/permissionCheck';

import Joyride, { EVENTS, STATUS } from 'react-joyride';

// Components
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
import NotificationIcon from '@material-ui/icons/Notifications';
import NotificationOffIcon from '@material-ui/icons/NotificationsOff';
import moment from 'moment-timezone';
import { isMobile } from 'react-device-detect';
import AppStore from '../../../images/appstore.png';
import GooglePlay from '../../../images/googleplay.png';
import MobileQrCode from '../../../images/mobileqrcode.png';
import SmallIcon from '../../../images/syncicon.png';
import AppSearch from './AppSearch';
import CompetitionCard from './navigationItems/CompetitionCard';

// Redux Actions
import {
  fetchAllActions,
  fetchDistinctOrgActions,
  fetchLoggedInUser,
  fetchOrganizationDepartments,
  fetchSites,
  fetchTrainingDocumentation,
  logAction,
  logout,
} from '../../../actions/adminActions';

import { fetchProviderAssignmentUsers } from '../../../actions/flowstationActions';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import PublishIcon from '@material-ui/icons/Share';
import flowstationImage from '../../../images/fsimage.png';
import synctimesLogoDev from '../../../images/synctimes-development.png';
import synctimesLogo from '../../../images/synctimes.png';

import navAdminImage from '../../../images/nav_adminconsole.png';
import navAnalyticsImage from '../../../images/nav_analytics.png';
import navTrainingImage from '../../../images/nav_training.png';

import navResourcesImages from '../../../images/nav_resources.png';

import binodark from '../../../images/binodark.png';

// Material UI Components
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DesktopIcon from '@material-ui/icons/DesktopMac';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/HelpOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import MobileIcon from '@material-ui/icons/Smartphone';
import SMSIcon from '@material-ui/icons/Textsms';

// Interaces
import {
  IAlert,
  IDepartment,
  IOrganization,
  IPatient,
  IProvider,
  ISite,
  IUser,
} from '../../../interfaces/interfaces';
import Notifications from './notification/Notifications';

import chromeStep1 from '../../../images/chromeStep1Image.png';
import chromeStep2 from '../../../images/chromeStep2Image.png';
import chromeStep3 from '../../../images/chromeStep3Image.png';
import chromeStep4 from '../../../images/chromeStep4Image.png';

// @ts-ignore
import notificationSMS from '../../../images/notificationSMS.PNG';
// @ts-ignore
import notificationAppImage from '../../../images/notificationApp.PNG';
// @ts-ignore
import notificationDesktopImage from '../../../images/notificationDesktop.PNG';
// @ts-ignore
import notificationPhone from '../../../images/notificationPhone.PNG';

import { some } from 'lodash';
import { checkIfIsDesktopApp } from '../../../util';

var uniqid = require('uniqid');

let metaData = require('../../../metadata.json');

let isMobileDevice = false;
// let isDesktop = false;

let orgId: any = null;

let deviceId = getQueryVariable('deviceId');
let deviceIdUrl = deviceId ? '&deviceId=' + deviceId : '';

isMobileDevice = isMobile;

// if (getQueryVariable('desktop')) {
//   isDesktop = true;
//   localStorage.setItem('isDesktop', 'true');
// } else {
//   const savedIsDesktop = localStorage.getItem('isDesktop');
//   if (savedIsDesktop === 'true') {
//     isDesktop = true;
//   }
// }

const styles: {} = (theme: any) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  card: {
    height: '100%',
    // maxWidth: 345,
  },
  headerImage: {
    maxWidth: 200,
    height: 45,
    paddingBottom: 3,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  smallHeaderImage: {
    // paddingTop: 5,
    maxWidth: 200,
    height: 45,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  siteName: {
    fontSize: 25,
    paddingLeft: 20,
    color: '#7e7e7e',
  },
  welcomeMessage: {
    color: '#7e7e7e',
    fontSize: '2.125rem',
    marginBottom: '3%',
    padding: 0,
    borderBottom: '1px solid #DCDCDC',
    lineHeight: 'normal'
  },
  appBar: {
    boxShadow: '0px 0px 5px 0px #707070',
    color: '#000000',
    backgroundColor: 'white',
    marginBottom: 20,
  },
  mainContainer: {
    maxWidth: '90%',
    // paddingLeft: 20,
    // paddingRight: 20,
    margin: 'auto',
    // marginTop: '1%',
    // backgroundColor: 'red',
  },
  departmentText: {
    fontSize: 36,
    marginBottom: 10,
  },
  adminText: {
    fontSize: 36,
    paddingTop: 16,
    marginBottom: 10,
  },
  linkButton: {
    backgroundColor: '#50b848',
    color: '#FFFFFF',
    marginTop: 10,
    marginBottom: 10,
    height: 50,
  },
  reportingButton: {
    backgroundColor: '#50b848',
    color: '#FFFFFF',
    marginTop: 10,
    textDecoration: 'none',
  },
  rightToolBar: {
    marginLeft: 'auto',
    marginRight: '-12px',
  },
  logoutButton: {
    color: 'black',
  },
  deptField: {
    width: '100%',
    marginBottom: 8,
    marginTop: 10,
  },
  tutorialPopup: {
    borderRadius: 5,
    textAlign: 'center',
    outline: 'none',
    position: 'absolute',
    width: '450px',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
  },
  customBadge: {
    backgroundColor: '#4CB946',
    color: 'white',
  },
});

interface JoyRideProps {
  continuous: boolean;
  index: number;
  step: any;
  backProps: any;
  closeProps: any;
  primaryProps: any;
  tooltipProps: any;
  skipProps: any;
  isLastStep: boolean;
}

const finishTutorialTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  isLastStep,
}: JoyRideProps) => (
  <div
    {...tooltipProps}
    style={{
      backgroundColor: 'white',
      borderRadius: 5,
      boxSizing: 'border-box',
      color: 'rgb(51, 51, 51)',
      fontSize: 16,
      maxWidth: '100%',
      padding: 15,
      position: 'relative',
      width: 550,
      lineHeight: '1.4',
      zIndex: 1500,
    }}
  >
    {step.title && <div>{step.title}</div>}

    <div style={{ textAlign: 'center' }}>
      <div style={{ padding: '20px 10px' }}>{step.content}</div>

      {step.image ? (
        <img style={{ maxWidth: '100%' }} src={step.image} alt="" />
      ) : null}
    </div>
    {/* @ts-ignore */}
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
      }}
    >
      <div style={{ flex: '1 1 0%' }}>
        {!isLastStep && (
          <button
            {...skipProps}
            style={{
              backgroundColor: 'transparent',
              border: 0,
              borderRadius: 0,
              cursor: 'pointer',
              color: 'rgb(51, 51, 51)',
              fontSize: 14,
              lineHeight: 1,
              padding: 8,
              appearance: 'none',
            }}
          >
            <div id="skip">Skip</div>
          </button>
        )}
      </div>
      {continuous && (
        <button
          {...primaryProps}
          style={{
            backgroundColor: 'rgb(80, 184, 72)',
            border: 0,
            borderRadius: 4,
            color: 'rgb(255, 255, 255)',
            cursor: 'pointer',
            fontSize: 16,
            lineHeight: 1,
            padding: 8,
            appearance: 'none',
          }}
        >
          <div id="next">Next</div>
        </button>
      )}
      {continuous && (
        <div
          {...skipProps}
          id="skip"
          style={{
            border: 0,
            borderRadius: 0,
            cursor: 'pointer',
            fontSize: 16,
            lineHeight: 1,
            padding: 15,
            appearance: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          X
        </div>
      )}
    </div>
  </div>
);

const JoyrideTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  isLastStep,
}: JoyRideProps) => (
  <div
    {...tooltipProps}
    style={{
      backgroundColor: 'white',
      borderRadius: 5,
      boxSizing: 'border-box',
      color: 'rgb(51, 51, 51)',
      fontSize: 16,
      maxWidth: '100%',
      padding: 15,
      position: 'relative',
      width: 550,
      lineHeight: '1.4',
      zIndex: 1500,
    }}
  >
    {step.title && <div>{step.title}</div>}

    {/* THIS STEP IS JUST FOR ALERT SUBSCRIPTIONS */}
    {index === 2 ? (
      <div style={{ textAlign: 'center' }}>
        {/* <div style={{ padding: "20px 10px" }}>{step.content}</div> */}
        <div style={{ padding: '20px 10px' }}>
          You can manage your Notifications here. There are four different types
          of notifications you can receive.
        </div>
        <br />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <SMSIcon /> <strong>SMS</strong> (You'll receive a text message)
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img style={{ maxWidth: '100%' }} src={notificationSMS} alt="" />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <PhoneIcon /> <strong>Phone Call</strong> (An auto generated voice
            will call you and describe the alert)
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img style={{ maxWidth: '100%' }} src={notificationPhone} alt="" />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <DesktopIcon /> <strong>Desktop</strong> Note: You must have Browser
            notifications enabled
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img
              style={{ maxWidth: '100%' }}
              src={notificationDesktopImage}
              alt=""
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <MobileIcon /> <strong>App Push Notification</strong> Note: You must
            have the SyncTimes mobile app
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img
              style={{ maxWidth: '100%' }}
              src={notificationAppImage}
              alt=""
            />
          </Grid>
        </Grid>
      </div>
    ) : null}

    {/* THIS STEP IS JUST FOR CHROME CONFIGURATION */}

    {index === 3 ? (
      <div style={{ textAlign: 'center' }}>
        {/* <div style={{ padding: "20px 10px" }}>{step.content}</div> */}
        <div style={{ padding: '20 10px 10px 10px' }}>
          Configuring Chrome to launch SyncTimes on startup
        </div>
        <div style={{ color: '#7e7e7e', fontSize: 12, paddingBottom: 10 }}>
          Please use chrome for best user experience
        </div>
        <br />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <div>
              <div>
                <strong>1.</strong> Set the tabs in your browser to the pages
                you would like to open every time you open Google Chrome
              </div>
              <div>
                <strong>2.</strong> Click on the three dots in the upper right
                corner of your browser.
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <img style={{ maxWidth: '100%' }} src={chromeStep1} alt="" />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <div>
              <div>
                <strong>3.</strong> Click settings
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <img style={{ maxWidth: '100%' }} src={chromeStep2} alt="" />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <div>
              <div>
                <strong>4.</strong> Click on startup, and then click Use current
                pages
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <img style={{ maxWidth: '100%' }} src={chromeStep3} alt="" />
          </Grid>
        </Grid>
      </div>
    ) : null}

    {index === 4 ? (
      <div style={{ textAlign: 'center' }}>
        {/* <div style={{ padding: "20px 10px" }}>{step.content}</div> */}
        <div style={{ padding: '20px 10px' }}>Saving to Task Bar</div>
        <br />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <div>
              <div>
                To add Chrome to the taskbar at the bottom of your screen right
                click on the Google Chrome icon when Chrome is open and select
                Pin to taskbar
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>
            <img style={{ maxWidth: '100%' }} src={chromeStep4} alt="" />
          </Grid>
        </Grid>
      </div>
    ) : null}

    <div style={{ textAlign: 'center' }}>
      <div style={{ padding: '20px 10px' }}>{step.content}</div>
      {step.image ? (
        <img style={{ maxWidth: '100%' }} src={step.image} alt="" />
      ) : null}
    </div>
    {/* @ts-ignore */}
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
      }}
    >
      <div style={{ flex: '1 1 0%' }}>
        {/* {index > 0 && (
        <button {...backProps}>
          <div id="back">Back</div>
        </button>
      )} */}
        {!isLastStep && (
          <button
            {...skipProps}
            style={{
              backgroundColor: 'transparent',
              border: 0,
              borderRadius: 0,
              cursor: 'pointer',
              color: 'rgb(51, 51, 51)',
              fontSize: 14,
              lineHeight: 1,
              padding: 8,
              appearance: 'none',
            }}
          >
            <div id="skip">Skip</div>
          </button>
        )}
      </div>
      {continuous && (
        <button
          {...primaryProps}
          style={{
            backgroundColor: 'rgb(80, 184, 72)',
            border: 0,
            borderRadius: 4,
            color: 'rgb(255, 255, 255)',
            cursor: 'pointer',
            fontSize: 16,
            lineHeight: 1,
            padding: 8,
            appearance: 'none',
          }}
        >
          <div id="next">Next</div>
        </button>
      )}
      {continuous && (
        <div
          {...skipProps}
          id="skip"
          style={{
            border: 0,
            borderRadius: 0,
            cursor: 'pointer',
            fontSize: 16,
            lineHeight: 1,
            padding: 15,
            appearance: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          X
        </div>
      )}
    </div>
  </div>
);

interface NavigationProps {
  organization: IOrganization;
  loggedInUserData: IUser;
  allActions: any;
  classes: any;
  orgProviders: any[];
  sites: ISite[];
  trainingDocumentation: any;
  distinctOrgActions: any;
  providerAssignmentUsers: any;
  logAction: (action: string, actionDetail: any, userId: string) => void;
  fetchAllActions: () => void;
  fetchSites: (orgId: string) => void;
  fetchDistinctOrgActions: (orgId: string) => void;
  fetchTrainingDocumentation: (orgId: string) => void;
  logout: () => void;
  fetchLoggedInUser: (userEmail: any, token: any, orgId: any) => void;
  fetchProviderAssignmentUsers: (orgId: string) => void;
  activeAlerts: any[];
  serverTimeOffset: any;
  timeZone: any;
}

interface NavigationState {
  showAppSearch: boolean;
  organizationDetails: any;
  siblingDepts: any[];
  anchorEl: any;
  viewEl: any;
  reportingEl: any;
  updatesEl: any;
  orgsEl: any;
  synctimesEl: any;
  featureRequestText: string;
  disableRequest: boolean;
  selectedDeptForSMS: any;
  newSMSSuccess: boolean;
  madeChangeSuccess: boolean;
  messageTypeSelected: boolean;
  selectedProvider: string;
  showPhoneSave: boolean;
  activeStep: number;
  showNotificationError: boolean;
  run: boolean;
  openSyncTimesTutorial: boolean;
  tutorialRedirect: boolean;
  runFinishTour: boolean;
  steps: any[];
  finishTourSteps: any[];
  selectedActions: any[];
  renameView: boolean;
  renameViewValue: string;
  renameViewId: any;
  showPromptToSaveView: boolean;
  viewPowerReport: boolean;
  reportEmbedDetails: any;
  deptSearchKey: any;
  providerSearchKey: any;
  preppedDepartments: any;
  preppedProviders: any;
  showActiveAlerts: boolean;
  // providerInput: any;
  // departmentInput: any;
}

class Navigation extends Component<NavigationProps, NavigationState> {
  constructor(props: NavigationProps) {
    super(props);

    // @ts-ignore
    this.providerInput = React.createRef();
    // @ts-ignore
    this.departmentInput = React.createRef();
    // @ts-ignore
    this.alertRef = React.createRef();

    this.state = {
      showAppSearch: false,
      organizationDetails: null,
      siblingDepts: [],
      anchorEl: null,
      viewEl: null,
      reportingEl: null,
      updatesEl: null,
      orgsEl: null,
      synctimesEl: null,
      featureRequestText: '',
      disableRequest: false,
      selectedDeptForSMS: '',
      newSMSSuccess: false,
      madeChangeSuccess: false,
      messageTypeSelected: false,
      selectedProvider: '',
      showPhoneSave: true,
      activeStep: 0,
      showNotificationError: false,
      run: false,
      openSyncTimesTutorial: false,
      tutorialRedirect: false,
      runFinishTour: false,
      showActiveAlerts: false,
      steps: [
        {
          // disableBeacon: true,
          // target: ".first-step",
          target: 'body',
          content:
            'Welcome to the dashboard! This is where you can find reports, Flowstation quick links, training workflows, and Notifications!',
          placement: 'center',
        },
        {
          content: 'You can find your latest reports here',
          // placement: "center",
          target: '.step-reports',
        },
        {
          // content: "EMPTY - ALERT SUBSCRIPTION, SEE JOYRIDE TOOLTIP",
          // placement: "center",
          target: '.step-subscriptions',
          placement: 'auto',
        },
        {
          // content: "EMPTY - CONFIGURING CHROME, SEE JOYRIDE TOOLTIP",
          placement: 'center',
          // placement: "top",
          target: 'body',
          // isFixed: true,
        },
        {
          // content: "EMPTY - ADDING TO TASKBAR, SEE JOYRIDE TOOLTIP",
          placement: 'center',
          target: 'body',
          // isFixed: true,
        },
      ],
      finishTourSteps: [
        {
          content:
            "And that's it! You can reach out to a SyncTimes team member anytime by using this chat widget located at the bottom right of the page.",
          placement: 'center',
          target: 'body',
          // image: hubspotChatIcon,
        },
        // {
        //   content: "And that's it! You can reach out to a SyncTimes team member anytime by using this chat widget.",
        //   // placement: "center",
        //   target: ".widget-align-right",
        //   disableBeacon: true,
        // },
      ],
      selectedActions: [],
      renameView: false,
      renameViewValue: '',
      renameViewId: null,
      showPromptToSaveView: false,
      viewPowerReport: false,
      reportEmbedDetails: null,
      deptSearchKey: 0,
      providerSearchKey: 0,
      preppedDepartments: [],
      preppedProviders: [],
    };

    if (isMobileDevice === false) {
      if (getQueryVariable('desktop')) {
        // console.log("its a desktop");
      } else {
        // console.log("continue, not a desktop");
        if (
          props.loggedInUserData.hasTutorialStarted === false &&
          props.loggedInUserData.resetPasswordNextLogin === false
        ) {
          // this.state.run = true;

          // this.state.openSyncTimesTutorial = true;
          this.state = {
            ...this.state,
            openSyncTimesTutorial: true,
          };
        }
        if (getQueryVariable('finishTour')) {
          // this.state.runFinishTour = true;
          this.state = {
            ...this.state,
            runFinishTour: true,
          };
        }
      }
    }
  }

  componentDidMount() {
    if (
      getQueryVariable('desktop') ||
      getQueryVariable('android') ||
      getQueryVariable('mobile')
    ) {
      if (localStorage.getItem('lastVisitedFs')) {
        window.location.replace(
          window.location.pathname +
          '?groupId=' +
          localStorage.getItem('lastVisitedFs') +
          deviceIdUrl
        );
      } else {
        if (localStorage.getItem('lastVisitedView')) {
          window.location.replace(
            window.location.pathname +
            '?viewId=' +
            localStorage.getItem('lastVisitedView') +
            deviceIdUrl
          );
        }
      }
    }

    if (getQueryVariable('openReport')) {
      this.openPowerReport();
    }

    // LOG ROCKET
    if (
      window.location.hostname === 'app.synctimes.com' ||
      window.location.hostname === 'cesium-dev.azurewebsites.net' ||
      window.location.hostname === 'cesium.azurewebsites.net'
    ) {
      if (this.props.loggedInUserData.mongoOrganizationId) {
        let URL =
          API.REACT_APP_API_ORGANIZATION +
          this.props.loggedInUserData.mongoOrganizationId;
        let token = localStorage.getItem('token');
        let headers = {
          headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
        };

        axios
          .get(URL, headers)
          .then(response => {
            if (response.data.data) {
              LogRocket.identify(this.props.loggedInUserData.id, {
                name: this.props.loggedInUserData.fullName,
                email: this.props.loggedInUserData.userEmail,
                orgName: response.data.data.orgName,
                userType: this.props.loggedInUserData.userTypeId,
                admin: this.props.loggedInUserData.isAdmin,
              });
            }
          })
          .catch(err => {
            console.log('Error: ', err);
          });
      }
    }
    // END LOG ROCKET

    this.props.fetchAllActions();

    if (this.props.loggedInUserData.mongoOrganizationId) {
      orgId = this.props.loggedInUserData.mongoOrganizationId;
    } else if (getQueryVariable('orgId')) {
      orgId = getQueryVariable('orgId');
    }

    // check if their is a mocked organization and user is a SyncTimes admin.
    if (
      localStorage.getItem('lastMockedOrganizationId') &&
      canDo(['SyncAdmin'], this.props.loggedInUserData)
    ) {
      orgId = localStorage.getItem('lastMockedOrganizationId');
    }

    if (orgId) {
      this.props.fetchSites(orgId);
      this.props.fetchDistinctOrgActions(orgId);
      this.props.fetchTrainingDocumentation(orgId);
      this.props.fetchProviderAssignmentUsers(orgId);
      let URL = API.REACT_APP_API_ORGANIZATION + orgId;
      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      // console.log("lets get", orgId);
      axios
        .get(URL, headers)
        .then(response => {
          this.setState({
            organizationDetails: response.data.data,
          });
        })
        .catch(err => {
          if (err.response) {
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          }
          console.log('Error: ', err);
        });

      // Get Sibling depts for all flowstation urls
      let orgHeaders = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };
      axios
        .get(API.REACT_APP_API_GETORGDEPARTMENT + orgId, orgHeaders)
        .then(response => {
          // console.log("depts", response.data.data);
          this.setState({
            siblingDepts: response.data.data,
          });
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  }

  logout = () => {
    this.props.logout();
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleView = (event: any) => {
    let userEmail = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.props.fetchLoggedInUser(userEmail, token, orgId);
    this.setState({ viewEl: event.currentTarget });
  };

  handleReport = (event: any) => {
    this.setState({ reportingEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      viewEl: null,
      reportingEl: null,
      updatesEl: null,
      orgsEl: null,
      synctimesEl: null,
    });
  };

  openHubspotChat = () => {
    // @ts-ignore
    window.HubSpotConversations.widget.open();
  };

  handleFeatureRequestText = (e: any) => {
    this.setState({
      featureRequestText: e.target.value,
    });
  };

  viewReport = (report: { reportingUrl: string; reportingName: string }) => {
    this.props.logAction(
      'Viewed Report',
      report.reportingName,
      this.props.loggedInUserData.id
    );
    window.open(report.reportingUrl);
  };

  handleJoyrideCallback = (data: any) => {
    const { index, status, type } = data;

    console.log('index', index);

    if (index === 3 && isMobileDevice) {
      if (this.state.tutorialRedirect) {
        window.location.replace(
          '/?groupId=' +
          this.state.siblingDepts[0].id +
          '&startTour=true' +
          deviceIdUrl
        );
      } else {
        this.setState({
          run: false,
        });
      }
    } else {
      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        console.log('TOUR IS FINISHED ON THIS PAGE', status);

        if (status === 'finished') {
          if (this.state.tutorialRedirect) {
            if (canDo(['View Flowstations'], this.props.loggedInUserData)) {
              window.location.replace(
                '/?groupId=' +
                this.state.siblingDepts[0].id +
                '&startTour=true' +
                deviceIdUrl
              );
            }
          }
        } else {
          this.setState({ run: false });
        }
      }
    }
  };

  startTutorial = (answer: string) => {
    let startTutorial = false;
    let userStartedTutorial = false;

    if (answer === 'yes') {
      console.log('YES');
      startTutorial = true;
      userStartedTutorial = true;
    }

    if (answer === 'later') {
      console.log('LATER');
      startTutorial = false;
      userStartedTutorial = false;
    }

    if (answer === 'skip') {
      console.log('SKIP');
      startTutorial = false;
      userStartedTutorial = true;
    }

    let payload = {
      ...this.props.loggedInUserData,
      // hasTutorialStarted: userStartedTutorial,
    };

    let token = localStorage.getItem('token');

    console.log('PAYLOAD', payload);

    if (userStartedTutorial) {
      fetch(API.REACT_APP_API_TUTORIAL, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((res: any) => {
          console.log('RES', res.data);
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }

    this.setState({
      openSyncTimesTutorial: false,
      run: startTutorial,
      tutorialRedirect: startTutorial,
    });
  };

  handleJoyrideFinishCallback = () => {
    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  // TODO - Update router call to allow back nav.
  goToCreateView = async (deptId: any) => {
    console.log('goToCreateview');
    if (deptId && this.props.loggedInUserData.id) {
      window.location.replace(
        '/?groupId=' + deptId + '&newView=true' + deviceIdUrl
      );
    }
  };

  deletePublishedView = (viewToDelete: any) => {
    let publishedViews = [];

    if (this.props.organization.publishedViews) {
      if (this.props.organization.publishedViews.length > 0) {
        publishedViews = JSON.parse(this.props.organization.publishedViews);
      }
    }

    publishedViews = publishedViews.filter((savedView: any) => {
      return savedView.viewId !== viewToDelete.viewId;
    });

    let modifiedOrganization = {
      ...this.props.organization,
      publishedViews: JSON.stringify(publishedViews),
    };

    // console.log("payload", payload);
    let payload = JSON.stringify(modifiedOrganization);
    let token = localStorage.getItem('token');
    // console.log("SENDING", modifiedOrganization);

    fetch(API.REACT_APP_API_PUBLISHEDVIEWS, {
      method: 'PUT',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        console.log('res', res);
        return res.json();
      })
      .then(data => {
        console.log('Deleted View', data);
        // this.deleteView(view);
        let userEmail = localStorage.getItem('email');
        let token = localStorage.getItem('token');
        this.props.fetchLoggedInUser(userEmail, token, orgId);
      })
      .catch(err => {
        console.log('err updating published views', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  deleteView = (viewToDelete: any) => {
    let userViews = [];
    if (this.props.loggedInUserData.userSettings) {
      let savedSettings: any = {};
      savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      if (savedSettings.userViews) {
        userViews = savedSettings.userViews;
      }
    }

    userViews = userViews.filter((savedView: any) => {
      return savedView.viewId !== viewToDelete.viewId;
    });

    let savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
    let settingsToUpdate = savedSettings;

    settingsToUpdate.userViews = userViews;
    let updatedUserSettings = JSON.stringify(settingsToUpdate);

    let payload = {
      ...this.props.loggedInUserData,
      userSettings: updatedUserSettings,
    };

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SETUSERSETTINGS, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('Updated res', data);
        let userEmail = localStorage.getItem('email');
        let token = localStorage.getItem('token');
        this.props.fetchLoggedInUser(userEmail, token, orgId);
      })
      .catch(err => {
        console.log('err', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  renameView = (viewId: any) => {
    let allViews = [];
    let userViews = [];
    if (this.props.loggedInUserData.userSettings) {
      let savedSettings: any = {};
      savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      if (savedSettings.userViews) {
        userViews = savedSettings.userViews;
      }
    }
    let publishedViews = [];
    if (this.props.organization.publishedViews.length > 0) {
      publishedViews = JSON.parse(this.props.organization.publishedViews);
    }

    allViews = userViews.concat(publishedViews);

    allViews.forEach((view: any) => {
      if (view.viewId === viewId) {
        if (view.publisherId) {
          // UPDATE ORGANIZATION
          console.log('ITS A PUBLISHED VIEW, MODIFY ORG');
          let currentOrgViews = JSON.parse(
            this.props.organization.publishedViews
          );

          let orgViewsToUpdate = currentOrgViews;
          currentOrgViews.forEach((view: any) => {
            if (view.viewId === viewId) {
              if (this.state.renameView) {
                view.viewName = this.state.renameViewValue;
              }

              view.viewSettings = {
                ...view.viewSettings,
                viewName: this.state.renameView
                  ? this.state.renameViewValue
                  : view.viewName,
                // mute: this.state.mute,
                // autoZoom: true,
                // hideLargeCards: this.state.hideLargeCards,
                // fsDepartmentsToShow: this.state.fsDepartmentsToShow,
                // deptSettings: this.state.viewDeptSettings,
              };
            }
          });

          let updatedOrgViews = JSON.stringify(orgViewsToUpdate);

          // console.log("um", orgViewsToUpdate);

          let modifiedOrganization = {
            ...this.props.organization,
            publishedViews: updatedOrgViews,
            // publishedViews: "",
          };

          // console.log("payload", modifiedOrganization);

          let payload = JSON.stringify(modifiedOrganization);
          // let token = localStorage.getItem("token");
          // console.log("SENDING", modifiedOrganization);

          let token = localStorage.getItem('token');
          fetch(API.REACT_APP_API_PUBLISHEDVIEWS, {
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              console.log('res', res);
              return res.json();
            })
            .then(data => {
              // console.log("Final updated view", data);
              this.setState({
                renameView: false,
                renameViewValue: '',
                showPromptToSaveView: false,
              });
              let userEmail = localStorage.getItem('email');
              let token = localStorage.getItem('token');
              this.props.fetchLoggedInUser(userEmail, token, orgId);
            })
            .catch(err => {
              console.log('err updating published views', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        } else {
          // UPDATE USER DATA
          console.log('NOT A PUBLISHED VIEW');
          let savedSettings = JSON.parse(
            this.props.loggedInUserData.userSettings
          );
          let settingsToUpdate = savedSettings;
          if (settingsToUpdate.userViews) {
            settingsToUpdate.userViews.forEach((view: any) => {
              if (view.viewId === viewId) {
                if (this.state.renameView) {
                  view.viewName = this.state.renameViewValue;
                }
                view.viewSettings = {
                  ...view.viewSettings,
                  viewName: this.state.renameView
                    ? this.state.renameViewValue
                    : view.viewName,
                };
              }
            });

            let updatedUserSettings = JSON.stringify(settingsToUpdate);

            let payload = {
              ...this.props.loggedInUserData,
              userSettings: updatedUserSettings,
            };

            let token = localStorage.getItem('token');
            fetch(API.REACT_APP_API_SETUSERSETTINGS, {
              method: 'POST',
              body: JSON.stringify(payload),
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
            })
              .then(res => {
                // console.log("RES!", res.json());
                return res.json();
              })
              .then(data => {
                // console.log("Updated res", data);
                this.setState({
                  renameView: false,
                  renameViewValue: '',
                  showPromptToSaveView: false,
                });
                let userEmail = localStorage.getItem('email');
                let token = localStorage.getItem('token');
                this.props.fetchLoggedInUser(userEmail, token, orgId);
              })
              .catch(err => {
                console.log('err', err);
                if (err.response?.status === 401) {
                  checkRefreshToken();
                }
              });
          } else {
            console.log("Didn't find any views?");
          }
        }
      }
    });
  };

  publishView = (view: any) => {
    // console.log("sib depts", allDepts);
    // console.log("publish view", view);
    let URL = API.REACT_APP_API_ORGANIZATION + this.props.organization.id;
    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    // console.log("lets get", orgId);
    axios
      .get(URL, headers)
      .then(response => {
        // console.log("Good!", response.data.data);

        let latestOrgDetails = response.data.data;
        console.log('orgDetails', latestOrgDetails);

        let currentPublishedViews = null;
        if (latestOrgDetails.publishedViews === '') {
          currentPublishedViews = [];
        } else {
          currentPublishedViews = JSON.parse(latestOrgDetails.publishedViews);
        }

        let duplicateFound = false;
        let belongsToOrg = false;

        currentPublishedViews.forEach((publishedView: any) => {
          if (publishedView.viewId === view.viewId) {
            duplicateFound = true;
            console.log('duplicate found!');
          }
        });

        this.state.siblingDepts.forEach(dept => {
          if (dept.id === view.defaultDeptId) {
            belongsToOrg = true;
          }
        });

        // console.log("belongs to org?", belongsToOrg);

        if (!duplicateFound && belongsToOrg) {
          view.publisher = this.props.loggedInUserData.fullName;
          view.publisherId = this.props.loggedInUserData.id;

          let generatedId = uniqid();
          view.viewId = generatedId;
          currentPublishedViews.push(view);

          let modifiedOrganization = {
            ...latestOrgDetails,
            publishedViews: JSON.stringify(currentPublishedViews),
            // publishedViews: "",
          };

          // console.log("modifiedOrgDetails", modifiedOrganization);

          let payload = JSON.stringify(modifiedOrganization);
          let token = localStorage.getItem('token');
          // console.log("SENDING", modifiedOrganization);

          fetch(API.REACT_APP_API_PUBLISHEDVIEWS, {
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              // console.log("res", res);
              return res.json();
            })
            .then(data => {
              // console.log("Final publish", data);
              // this.popView(view);
              let userEmail = localStorage.getItem('email');
              let token = localStorage.getItem('token');
              this.props.fetchLoggedInUser(userEmail, token, orgId);
            })
            .catch(err => {
              console.log('err updating published views', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  viewDocumentation = (workflow: any) => {
    this.props.logAction(
      'Viewed Documentation',
      workflow.url,
      this.props.loggedInUserData.id
    );
    window.open(workflow.url);
    // console.log("workflwo url", workflow.url);
  };

  setActiveAlertsAnchor = (event: any) => {
    this.setState({
      showActiveAlerts: true,
    });
  };

  openPowerReport = () => {
    // this.props.logAction("Viewed Report", getQueryVariable("groupId")!, this.props.loggedInUserData.id);

    let URL = API.REACT_APP_API_EMBEDDEDREPORTS;
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        let data = response.data.data;
        console.log('data!', data);
        this.setState({
          viewPowerReport: true,
          reportEmbedDetails: data,
        });
        // this.setState({ viewPowerReport: true });
      })
      .catch(err => {
        console.log('Error fetching report embedd: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  selectProvider = (provider: IProvider) => {
    // console.log("selected", provider);
    if (provider) {
      let providersToModify = [...this.state.preppedProviders];

      if (some(providersToModify, { id: provider.id })) {
      } else {
        providersToModify.push(provider);
        this.setState({
          preppedProviders: providersToModify,
        });
      }
    }

    // @ts-ignore
    // this.providerInput.focus();
  };

  navigateToLobby = () => {
    window.location.replace('/lobby');
  };

  handleCompetitonClose = () => {
    localStorage.setItem('showCompetitionPopup', 'false');
    this.forceUpdate();
    // this.setState({ showCompetitionPopup: false });
  };

  competitionLearnMore = () => {
    localStorage.setItem('showCompetitionPopup', 'false');
    window.open('https://www.synctimes.com/partner-competition');
  };

  render() {
    const { classes } = this.props;

    const isDesktop = checkIfIsDesktopApp()

    let isFrontDeskIntegration = some(this.state.siblingDepts, {
      isFrontDeskIntegration: true,
    });

    let adminRights = false;
    adminRights = canDo(
      [
        'View Sites',
        'View Equipment',
        'View Displays',
        'View Users',
        'View Service Lines',
        'View Departments',
        'View Rooms',
      ],
      this.props.loggedInUserData
    );

    let isTeamsApp: boolean = false;

    if (
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      isTeamsApp = true;
    }

    let OSName = 'Unknown';
    if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1)
      OSName = 'Windows 10';
    if (window.navigator.userAgent.indexOf('Windows NT 6.3') !== -1)
      OSName = 'Windows 8.1';
    if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1)
      OSName = 'Windows 8';
    if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1)
      OSName = 'Windows 7';
    if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1)
      OSName = 'Windows Vista';
    if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1)
      OSName = 'Windows XP';

    let userViews = [];
    if (this.props.loggedInUserData.userSettings) {
      let savedSettings: any = {};
      savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      if (savedSettings.userViews) {
        userViews = savedSettings.userViews;
      }
    }

    userViews.sort((a: any, b: any) => {
      return a.viewName < b.viewName ? -1 : a.viewName > b.viewName ? 1 : 0;
    });

    let viewDefaultDeptId: any = null;

    this.state.siblingDepts.forEach(dept => {
      if (dept.isActive) {
        viewDefaultDeptId = dept.id;
      }
    });

    let publishedViews = [];
    if (this.props.organization) {
      if (this.props.organization.publishedViews !== '') {
        console.log();
        publishedViews = JSON.parse(this.props.organization.publishedViews);
      }
    }
    publishedViews.sort((a: any, b: any) => {
      return a.viewName < b.viewName ? -1 : a.viewName > b.viewName ? 1 : 0;
    });

    let isDev = false;
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'dev.synctimes.com'
    ) {
      isDev = true;
    }

    let alphabetizedDepartments: IDepartment[] = [];
    alphabetizedDepartments = this.state.siblingDepts.sort((a, b) => {
      return a.siteName < b.siteName ? -1 : a.siteName > b.siteName ? 1 : 0;
    });

    let alphabetizedOrgDocumentation: any[] = [];
    if (this.props.trainingDocumentation.orgDocumentation) {
      alphabetizedOrgDocumentation =
        this.props.trainingDocumentation.orgDocumentation.sort(
          (a: any, b: any) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          }
        );
    }

    let alphabetizedSyncTimesDocumentation: any[] = [];
    if (this.props.trainingDocumentation.syncTimesDocumentation) {
      alphabetizedSyncTimesDocumentation =
        this.props.trainingDocumentation.syncTimesDocumentation.sort(
          (a: any, b: any) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          }
        );
    }

    let deptSearchIndex: any = [];

    alphabetizedDepartments.forEach(dept => {
      if (dept.isActive) {
        deptSearchIndex.push({
          name: 'Flowstation - ' + dept.siteName + ' ' + dept.deptName,
          type: 'Flowstation',
          url: '/?groupId=' + dept.id + deviceIdUrl,
          description: '',
        });
      }
    });

    return (
      <div
        className={classes.root}
        style={{ backgroundColor: 'white', height: '100vh' }}
      >
        <AppSearch
          showSearch={this.state.showAppSearch}
          searchData={[...deptSearchIndex]}
          closeSearch={() => this.setState({ showAppSearch: false })}
          openPowerReport={() => this.openPowerReport()}
        />

        <Dialog
          hideBackdrop={true}
          open={
            this.state.viewPowerReport &&
              this.state.reportEmbedDetails &&
              this.props.organization
              ? true
              : false
          }
          style={{ width: '100%' }}
          transitionDuration={{ enter: 0, exit: 0 }}
          fullWidth={true}
          maxWidth={'xl'}
          fullScreen={true}
        >
          <div style={{ height: '90vh' }}>
            <PowerReport
              embedData={this.state.reportEmbedDetails}
              closeReport={() => this.setState({ viewPowerReport: false })}
              // dept={this.props.groupData}
              org={this.props.organization}
              loggedInUserData={this.props.loggedInUserData}
              isFrontDeskIntegration={isFrontDeskIntegration}
              timeZone={this.props.timeZone}
            />
          </div>
        </Dialog>

        {this.state.organizationDetails ? (
          <div>
            <Toolbar className={classes.appBar}>
              {isTeamsApp ? null : (
                <Typography>
                  <img
                    className={classes.headerImage}
                    src={isDev ? synctimesLogoDev : synctimesLogo}
                    alt=""
                  />
                  <img
                    className={classes.smallHeaderImage}
                    src={SmallIcon}
                    alt=""
                  />
                </Typography>
              )}

              <Typography className={classes.siteName}>
                {this.state.organizationDetails.orgName}
              </Typography>

              <Popover
                open={this.state.showActiveAlerts}
                // @ts-ignore
                anchorEl={this.alertRef.current}
                onClose={() => this.setState({ showActiveAlerts: false })}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Container style={{ paddingBottom: 25, paddingTop: 20 }}>
                  {/* {console.log("active alerts", this.props.activeAlerts)} */}
                  {this.props.activeAlerts.length === 0 ? (
                    <div
                      style={{
                        color: 'gray',
                        fontSize: 12,
                        textAlign: 'center',
                        marginTop: 5,
                      }}
                    >
                      No alerts to show...
                    </div>
                  ) : null}
                  {this.props.activeAlerts.map(
                    (activeAlert: IAlert, i: number) => (
                      <div key={i} style={{ paddingTop: 10 }}>
                        <Grid
                          container
                          spacing={1}
                          style={{
                            border: '1px solid lightgray',
                            borderRadius: '10px',
                            marginBottom: 5,
                            padding: '5px 10px 5px 10px',
                          }}
                          alignItems="center"
                        >
                          <Grid item xs={12} sm={2}>
                            <img
                              style={{ width: 50, height: 50 }}
                              alt=""
                              src={activeAlert.actionImageUrl}
                            />
                          </Grid>
                          <Grid item xs={12} sm={10} style={{ width: 500 }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                              }}
                            >
                              <h3 style={{ margin: '0px' }}>
                                {activeAlert.actionName +
                                  ' ' +
                                  activeAlert.roomSiteDeptName}
                              </h3>
                              {activeAlert.roomSiteDeptName ===
                                'Waiting Room' ? null : (
                                <h3 style={{ margin: '0px' }}>
                                  {moment()
                                    .add(
                                      'milliseconds',
                                      this.props.serverTimeOffset
                                    )
                                    .tz(this.props.timeZone)
                                    .local()
                                    .diff(
                                      moment
                                        .utc(activeAlert.actionStartTime)
                                        .local(),
                                      'minutes'
                                    )}{' '}
                                  minutes
                                </h3>
                              )}
                            </div>

                            <div style={{ marginLeft: 20 }}>
                              {activeAlert.patientsInRoom.map(
                                (patient: IPatient, patientIndex: number) => (
                                  <ul
                                    key={patientIndex}
                                    style={{
                                      marginTop: 0,
                                      paddingLeft: 0,
                                      marginBottom: 0,
                                    }}
                                  >
                                    <li>{patient.fsPatientInfoString}</li>
                                  </ul>
                                )
                              )}
                            </div>
                            {activeAlert.actionId !==
                              '6092bf9a394a8d85bfc7e50c' ? (
                              <Link
                                href={
                                  'https://app.synctimes.com/?groupId=' +
                                  activeAlert.deptId +
                                  '&roomId=' +
                                  activeAlert.roomId +
                                  deviceIdUrl
                                }
                              >
                                <Button
                                  style={{
                                    backgroundColor: '#50b848',
                                    color: '#FFFFFF',
                                    textDecoration: 'none',
                                    marginTop: 10,
                                  }}
                                  variant="contained"
                                >
                                  View Room in Flowstation
                                </Button>
                              </Link>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}
                </Container>
              </Popover>

              <section className={classes.rightToolBar}>
                <Tooltip title="Help me find..." placement="bottom">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        showAppSearch: true,
                      })
                    }
                  >
                    <img alt="" style={{ width: 25 }} src={binodark} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Active Alerts" placement="bottom">
                  <IconButton
                    // @ts-ignore
                    ref={this.alertRef}
                    onClick={this.setActiveAlertsAnchor}
                  >
                    <Badge
                      classes={{ badge: classes.customBadge }}
                      badgeContent={this.props.activeAlerts.length}
                      color="primary"
                    >
                      {localStorage.getItem('disableNotificationPopover') ===
                        'true' ? (
                        <NotificationOffIcon style={{ color: 'black' }} />
                      ) : (
                        <NotificationIcon style={{ color: 'black' }} />
                      )}

                      {/* NotificationOffIcon */}
                    </Badge>
                  </IconButton>
                </Tooltip>

                {isMobileDevice || isDesktop ? null : (
                  <Tooltip title="Dashboard Tutorial" placement="left">
                    <IconButton
                      onClick={() =>
                        this.setState(
                          {
                            run: false,
                          },
                          () => {
                            this.setState({
                              run: true,
                            });
                          }
                        )
                      }
                    >
                      <HelpIcon style={{ color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                )}

                <Button
                  onClick={() => this.logout()}
                  className={classes.logoutButton}
                >
                  Logout
                </Button>
              </section>
            </Toolbar>
            <div className={classes.mainContainer}>
              <div className={classes.welcomeMessage}>
                Welcome, {this.props.loggedInUserData.firstName}
              </div>

              <Grid container spacing={3}>
                {adminRights && (
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.media}
                        // image={adminImage}
                        image={navAdminImage}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="h2">
                          Admin Console
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Create or edit users and equipment
                        </Typography>
                        <Button
                          size="small"
                          variant="contained"
                          className={classes.reportingButton}
                          aria-owns={
                            this.state.anchorEl ? 'simple-menu' : undefined
                          }
                          aria-haspopup="true"
                          href="/admin?users"
                        >
                          Admin Console
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {canDo(['View Flowstations'], this.props.loggedInUserData) ? (
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Card className={classes.card}>
                      <div style={{ height: '100%' }}>
                        <CardMedia
                          className={classes.media}
                          image={flowstationImage}
                        // image={navFlowstationImage}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h4" component="h2">
                            Flowstation
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            View individual room statuses and details throughout
                            your departments
                          </Typography>

                          {/* {userViews.length} {publishedViews.length} */}

                          {userViews.length > 0 || publishedViews.length > 0 ? (
                            <Button
                              style={{ marginRight: 10 }}
                              size="small"
                              variant="contained"
                              className={classes.reportingButton}
                              aria-owns={
                                this.state.anchorEl ? 'simple-menu' : undefined
                              }
                              aria-haspopup="true"
                              onClick={this.handleView}
                            >
                              Select View <ArrowDown fontSize={'small'} />
                            </Button>
                          ) : (
                            // <div style={{cursor: "pointer"}} onClick={() => this.goToCreateView(viewDefaultDeptId)}>+ Create New View</div>
                            <Button
                              style={{ marginRight: 10 }}
                              size="small"
                              variant="contained"
                              aria-haspopup="true"
                              className={classes.reportingButton}
                              onClick={() =>
                                this.goToCreateView(viewDefaultDeptId)
                              }
                            >
                              + Create New View
                            </Button>
                          )}

                          <Button
                            style={{ marginRight: 10 }}
                            size="small"
                            variant="contained"
                            className={classes.reportingButton}
                            aria-owns={
                              this.state.anchorEl ? 'simple-menu' : undefined
                            }
                            aria-haspopup="true"
                            onClick={this.handleClick}
                          >
                            Select Department <ArrowDown fontSize={'small'} />
                          </Button>

                          {canDo(
                            ['View Lobbies'],
                            this.props.loggedInUserData
                          ) ? (
                            <Button
                              size="small"
                              variant="contained"
                              className={classes.reportingButton}
                              onClick={this.navigateToLobby}
                            >
                              Lobby
                            </Button>
                          ) : null}
                        </CardContent>

                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleClose}
                        >
                          {/* {console.log("sibling depts", this.state.siblingDepts)} */}
                          {alphabetizedDepartments.map(dept =>
                            dept.isActive ? (
                              <a
                                key={dept.id}
                                href={'/?groupId=' + dept.id + deviceIdUrl}
                                style={{
                                  textDecoration: 'none',
                                  color: 'black',
                                }}
                              >
                                <MenuItem>
                                  {this.props.sites.map(site =>
                                    site.id === dept.mongoSiteId
                                      ? site.siteName
                                      : null
                                  )}{' '}
                                  {dept.deptName}
                                </MenuItem>
                              </a>
                            ) : null
                          )}
                        </Menu>

                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.viewEl}
                          open={Boolean(this.state.viewEl)}
                          onClose={this.handleClose}
                        >
                          {viewDefaultDeptId ? (
                            <div>
                              {/* {publishedViews.length > 0 ? (
                                <div>
                                  <div style={{ paddingLeft: 16, paddingRight: 16, fontSize: 24 }}>Published Views</div>
                                  <div style={{ backgroundColor: "lightgray", height: 1, width: "90%", margin: "auto", marginTop: 5, marginBottom: 10 }} />
                                </div>
                              ) : null} */}

                              <Accordion
                                style={{ margin: '20px 16px 0px 16px' }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>Published Views</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{
                                    maxHeight: 150,
                                    overflow: 'scroll',
                                    overflowX: 'hidden',
                                  }}
                                >
                                  <div>
                                    {publishedViews.map(
                                      (publishedView: any) => (
                                        <div
                                          key={publishedView.viewId}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: '100%',
                                              paddingRight: 10,
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {this.props.loggedInUserData
                                              .isAdmin ? (
                                              <>
                                                {/* <UnpublishIcon style={{ paddingLeft: 5, cursor: "pointer" }} onClick={() => this.unPublishView(publishedView)} /> */}

                                                {/* <EditIcon
                                                  style={{
                                                    paddingLeft: 5,
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      renameViewId:
                                                        publishedView.viewId,
                                                      renameView: true,
                                                      renameViewValue:
                                                        publishedView.viewName,
                                                    })
                                                  } */}

                                                  {this.state.renameView &&
                                                    this.state.renameViewId ===
                                                    publishedView.viewId ? (
                                                    <CheckIcon
                                                      style={{
                                                        paddingLeft: 5,
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() => {
                                                        this.renameView(publishedView.viewId);
                                                        this.setState({
                                                          renameViewId: null,
                                                          renameView: false,
                                                          renameViewValue: null,
        
                                                        })
                                                      }
                                                      }
                                                    />) : (
                                                    <EditIcon
                                                      style={{
                                                        paddingLeft: 5,
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() =>
                                                        this.setState({
                                                          renameViewId: publishedView.viewId,
                                                          renameView: true,
                                                          renameViewValue: publishedView.viewName,
                                                        })
                                                      }
                                                    />
        
                                                  )}
                                                {/* /> */}
                                              </>
                                            ) : null}

                                            {this.state.renameView &&
                                              this.state.renameViewId ===
                                              publishedView.viewId ? (
                                              <TextField
                                                onKeyPress={ev => {
                                                  if (ev.key === 'Enter') {
                                                    // Do code here
                                                    this.renameView(
                                                      publishedView.viewId
                                                    );
                                                    ev.preventDefault();
                                                  }
                                                }}
                                                style={{ paddingLeft: 16 }}
                                                autoFocus
                                                value={
                                                  this.state.renameViewValue
                                                }
                                                onChange={e =>
                                                  this.setState({
                                                    renameViewValue:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            ) : (
                                              <div>
                                                <a
                                                  href={
                                                    '/?viewId=' +
                                                    publishedView.viewId +
                                                    deviceIdUrl
                                                  }
                                                  style={{
                                                    textDecoration: 'none',
                                                    color: 'black',
                                                  }}
                                                >
                                                  <MenuItem
                                                    style={{ paddingRight: 32 }}
                                                  >
                                                    {publishedView.viewName}
                                                    <span
                                                      style={{
                                                        color: 'gray',
                                                        marginLeft: '.4rem',
                                                      }}
                                                    >
                                                      {' '}
                                                      by{' '}
                                                      {publishedView.publisher}
                                                    </span>
                                                  </MenuItem>
                                                </a>
                                              </div>
                                            )}

                                            <div style={{ width: '100%' }}>
                                              {this.props.loggedInUserData
                                                .isAdmin ? (
                                                <strong
                                                  style={{
                                                    color: 'red',
                                                    float: 'right',
                                                    cursor: 'pointer',
                                                    fontSize: 17,
                                                  }}
                                                  onClick={() =>
                                                    this.deletePublishedView(
                                                      publishedView
                                                    )
                                                  }
                                                >
                                                  X
                                                </strong>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </AccordionDetails>
                              </Accordion>

                              <Accordion
                                style={{ margin: '20px 16px 0px 16px' }}
                                defaultExpanded={true}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>Your Views</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div>
                                    {userViews.map((view: any) => (
                                      <div
                                        key={view.viewId}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: '100%',
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            paddingRight: 10,
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          {this.props.loggedInUserData
                                            .isAdmin ? (
                                            <>
                                              <Tooltip
                                                title="Publishing will allow anyone in your organization to view a read only copy of this view."
                                                placement="left"
                                              >
                                                <PublishIcon
                                                  style={{
                                                    paddingLeft: 5,
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() =>
                                                    this.publishView(view)
                                                  }
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                          {this.state.renameView &&
                                            this.state.renameViewId ===
                                            view.viewId ? (
                                            <CheckIcon
                                              style={{
                                                paddingLeft: 5,
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                this.renameView(view.viewId);
                                                this.setState({
                                                  renameViewId: null,
                                                  renameView: false,
                                                  renameViewValue: null,

                                                })
                                              }
                                              }
                                            />) : (
                                            <EditIcon
                                              style={{
                                                paddingLeft: 5,
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  renameViewId: view.viewId,
                                                  renameView: true,
                                                  renameViewValue: view.viewName,
                                                })
                                              }
                                            />

                                          )}
                                          {this.state.renameView &&
                                            this.state.renameViewId ===
                                            view.viewId ? (
                                            <div>
                                              <TextField
                                                onKeyPress={ev => {
                                                  // console.log(`Pressed keyCode ${ev.key}`);
                                                  if (ev.key === 'Enter') {
                                                    // Do code here
                                                    this.renameView(view.viewId);
                                                    ev.preventDefault();
                                                  }
                                                }}
                                                style={{ paddingLeft: 16 }}
                                                autoFocus
                                                value={this.state.renameViewValue}
                                                onChange={e =>
                                                  this.setState({
                                                    renameViewValue:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>) : (
                                            <div>
                                              <a
                                                href={
                                                  '/?viewId=' +
                                                  view.viewId +
                                                  deviceIdUrl
                                                }
                                                style={{
                                                  textDecoration: 'none',
                                                  color: 'black',
                                                }}
                                              >
                                                <MenuItem
                                                  style={{ paddingRight: 32 }}
                                                >
                                                  {view.viewName}
                                                </MenuItem>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            right: 0,
                                          }}
                                        >
                                          <strong
                                            style={{
                                              color: 'red',
                                              float: 'right',
                                              cursor: 'pointer',
                                              fontSize: 17,
                                              paddingRight: 10,
                                              paddingLeft: 10,
                                            }}
                                            onClick={() =>
                                              this.deleteView(view)
                                            }
                                          >
                                            X
                                          </strong>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </AccordionDetails>
                              </Accordion>

                              {/* {console.log("user views", userViews)} */}
                            </div>
                          ) : null}

                          {viewDefaultDeptId ? (
                            // <a href={"/?groupId=" + viewDefaultDeptId + "&newView=true"} style={{ textDecoration: "none", color: "black" }}>
                            //   <MenuItem>+ Create New View</MenuItem>
                            // </a>
                            // <MenuItem onClick={() => this.goToCreateView(viewDefaultDeptId)}>+ Create New View</MenuItem>
                            <Button
                              style={{ margin: '20px 16px 16px 16px' }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                this.goToCreateView(viewDefaultDeptId)
                              }
                            >
                              + Create New View
                            </Button>
                          ) : null}
                        </Menu>
                        <CardActions>
                          {/* <Button size="small" color="primary" href="/alerts">
                           Alerts
                         </Button> */}
                        </CardActions>
                      </div>
                    </Card>
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card className={classes.card + ' step-subscriptions'}>
                    <div style={{ height: '100%' }}>
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="h2">
                          Notifications
                        </Typography>

                        <Notifications
                          loggedInUserData={this.props.loggedInUserData}
                          modifyingOtherUsers={false}
                        />
                      </CardContent>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card className={classes.card}>
                    <div style={{ height: '100%' }}>
                      <CardMedia
                        className={classes.media}
                        image={navAnalyticsImage}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="h2">
                          Analytics
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {isMobileDevice || isDesktop
                            ? 'Analytics are available on the web experience of SyncTimes'
                            : 'View indepth reporting details of your clinic and historical progress'}
                        </Typography>

                        {this.state.siblingDepts ? (
                          <Button
                            onClick={this.openPowerReport}
                            size="small"
                            variant="contained"
                            className={
                              classes.reportingButton + ' step-reports'
                            }
                            disabled={
                              isMobileDevice || isDesktop ? true : false
                            }
                          >
                            Reports
                          </Button>
                        ) : null}
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card className={classes.card}>
                    <div style={{ height: '100%' }}>
                      <CardMedia
                        className={classes.media}
                        // image={trainingImage}
                        image={navTrainingImage}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="h2">
                          Training
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Join your peers and SyncTimes experts during
                          interactive, virtual training.
                        </Typography>
                        <Button
                          style={{ marginRight: 10 }}
                          size="small"
                          variant="contained"
                          className={classes.reportingButton}
                          aria-owns={
                            this.state.anchorEl ? 'updates-menu' : undefined
                          }
                          // aria-haspopup="true"
                          onClick={() =>
                            window.open(
                              'https://synctimes.com/training',
                              '_blank'
                            )
                          }
                        >
                          Virtual Classroom Training
                        </Button>

                        <Button
                          style={{ marginRight: 10 }}
                          size="small"
                          variant="contained"
                          className={classes.reportingButton}
                          aria-owns={
                            this.state.anchorEl ? 'updates-menu' : undefined
                          }
                          // aria-haspopup="true"
                          onClick={() =>
                            window.open(
                              'https://synctimes.com/training#advancedtraining',
                              '_blank'
                            )
                          }
                        >
                          Quarterly Product Update Webinar
                        </Button>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card className={classes.card}>
                    <div style={{ height: '100%' }}>
                      <CardMedia
                        className={classes.media}
                        // image={trainingImage}
                        image={navResourcesImages}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="h2">
                          Helpful Resources
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Find answers to questions and become a super user
                          using our resource library.
                        </Typography>
                        {this.props.trainingDocumentation.orgDocumentation ? (
                          <>
                            {this.props.trainingDocumentation.orgDocumentation
                              .length > 0 ? (
                              <>
                                <Button
                                  style={{ marginRight: 10 }}
                                  size="small"
                                  variant="contained"
                                  className={classes.reportingButton}
                                  aria-owns={
                                    this.state.anchorEl
                                      ? 'orgdocumentation-menu'
                                      : undefined
                                  }
                                  // aria-haspopup="true"
                                  onClick={event =>
                                    this.setState({
                                      orgsEl: event.currentTarget,
                                    })
                                  }
                                >
                                  {/* {this.state.organizationDetails.orgName} Workflows */}
                                  Icon Definitions
                                  <ArrowDown />
                                </Button>
                                <Menu
                                  id="orgdocumentation-menu"
                                  anchorEl={this.state.orgsEl}
                                  open={Boolean(this.state.orgsEl)}
                                  onClose={this.handleClose}
                                >
                                  {alphabetizedOrgDocumentation.map(
                                    (workflow: any, index: any) => (
                                      // <MenuItem key={index} onClick={() => window.open(workflow.url)}>
                                      <MenuItem
                                        key={index}
                                        onClick={() =>
                                          this.viewDocumentation(workflow)
                                        }
                                      >
                                        {workflow.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Menu>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {this.props.trainingDocumentation
                          .syncTimesDocumentation ? (
                          <>
                            {this.props.trainingDocumentation
                              .syncTimesDocumentation.length > 0 ? (
                              <>
                                <Button
                                  style={{ marginRight: 10 }}
                                  size="small"
                                  variant="contained"
                                  className={classes.reportingButton}
                                  aria-owns={
                                    this.state.anchorEl
                                      ? 'synctimesdocumentation-menu'
                                      : undefined
                                  }
                                  // aria-haspopup="true"
                                  // onClick={this.handleClick}
                                  onClick={event =>
                                    this.setState({
                                      synctimesEl: event.currentTarget,
                                    })
                                  }
                                >
                                  Resource Documents <ArrowDown />
                                </Button>
                                <Menu
                                  id="synctimesdocumentation-menu"
                                  anchorEl={this.state.synctimesEl}
                                  open={Boolean(this.state.synctimesEl)}
                                  onClose={this.handleClose}
                                >
                                  {alphabetizedSyncTimesDocumentation.map(
                                    (workflow: any, index: any) => (
                                      <MenuItem
                                        key={index}
                                        onClick={() =>
                                          window.open(workflow.url)
                                        }
                                      >
                                        {workflow.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Menu>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        <Button
                          style={{ marginRight: 10 }}
                          size="small"
                          variant="contained"
                          className={classes.reportingButton}
                          aria-owns={
                            this.state.anchorEl ? 'updates-menu' : undefined
                          }
                          // aria-haspopup="true"
                          onClick={() =>
                            window.open('https://help.synctimes.com', '_blank')
                          }
                        >
                          Help Center
                        </Button>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      {OSName !== 'Unknown' ? (
                        <div>
                          <Typography gutterBottom variant="h4" component="h2">
                            Applications
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Paper
                                className={classes.paper}
                                style={{ height: '100%' }}
                              >
                                <Typography
                                  style={{ paddingLeft: 10 }}
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  Mobile
                                </Typography>
                                <div style={{ textAlign: 'center' }}>
                                  <img
                                    style={{ width: '80%', maxWidth: 500 }}
                                    src={MobileQrCode}
                                    alt=""
                                  />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                  <img
                                    onClick={() =>
                                      window.open(
                                        'https://play.google.com/store/apps/details?id=io.ionic.synctimes'
                                      )
                                    }
                                    style={{
                                      width: '80%',
                                      margin: 'auto',
                                      cursor: 'pointer',
                                    }}
                                    src={GooglePlay}
                                    alt=""
                                  />

                                  <img
                                    onClick={() =>
                                      window.open(
                                        'https://apps.apple.com/us/app/synctimes/id1546265625'
                                      )
                                    }
                                    style={{
                                      width: '80%',
                                      margin: 'auto',
                                      cursor: 'pointer',
                                    }}
                                    src={AppStore}
                                    alt=""
                                  />
                                </div>
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              <Paper
                                className={classes.paper}
                                style={{ paddingBottom: 20 }}
                              >
                                <Typography
                                  style={{ paddingLeft: 10, marginBottom: 7 }}
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  Desktop
                                </Typography>
                                <Typography
                                  style={{ paddingLeft: 10 }}
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Some major features include:
                                </Typography>
                                <br />
                                <Typography
                                  style={{ paddingLeft: 10 }}
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  - Auto Launch
                                </Typography>
                                <Typography
                                  style={{ paddingLeft: 10 }}
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  - Sticky Positioning and Static Overlap
                                </Typography>
                                <Typography
                                  style={{ paddingLeft: 10 }}
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  - Adjustable Opacity
                                </Typography>

                                <div style={{ textAlign: 'center' }}>
                                  <Button
                                    style={{ width: '90%', marginTop: 20 }}
                                    size="small"
                                    variant="contained"
                                    className={classes.reportingButton}
                                    aria-haspopup="true"
                                    href="https://cesiumstorage.blob.core.windows.net/desktopapplications/SyncTimes Setup.exe"
                                  >
                                    Download
                                  </Button>
                                </div>
                              </Paper>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        <div>
                          <Typography gutterBottom variant="h4" component="h2">
                            Mobile Apps
                          </Typography>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginBottom: 20,
                            }}
                          >
                            <img
                              style={{ width: '50%', maxWidth: 500 }}
                              src={MobileQrCode}
                              alt=""
                            />
                          </div>
                          <div style={{ display: 'flex' }}>
                            <img
                              onClick={() =>
                                window.open(
                                  'https://play.google.com/store/apps/details?id=io.ionic.synctimes'
                                )
                              }
                              style={{
                                width: '45%',
                                margin: 'auto',
                                cursor: 'pointer',
                              }}
                              src={GooglePlay}
                              alt=""
                            />

                            <img
                              onClick={() =>
                                window.open(
                                  'https://apps.apple.com/us/app/synctimes/id1546265625'
                                )
                              }
                              style={{
                                width: '45%',
                                margin: 'auto',
                                cursor: 'pointer',
                              }}
                              src={AppStore}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <CompetitionCard classes={classes} />
                </Grid>
              </Grid>

              <Modal
                open={this.state.openSyncTimesTutorial}
                // open={true}
                onClose={() => {
                  this.setState({ openSyncTimesTutorial: false });
                }}
              >
                <div className={classes.tutorialPopup}>
                  <div style={{ paddingBottom: 20 }}>
                    Would you like to start the SyncTimes Web App tutorial?
                  </div>

                  <Button
                    style={{ margin: '0px 5px 0px 5px' }}
                    onClick={() => this.startTutorial('skip')}
                  >
                    Skip
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: '0px 5px 0px 5px' }}
                    onClick={() => this.startTutorial('later')}
                  >
                    Later
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    style={{ margin: '0px 5px 0px 5px' }}
                    onClick={() => this.startTutorial('yes')}
                  >
                    Yes
                  </Button>
                </div>
              </Modal>

              <Joyride
                run={this.state.run}
                steps={this.state.steps}
                callback={this.handleJoyrideCallback}
                tooltipComponent={JoyrideTooltip}
                disableOverlayClose
                showSkipButton
                hideBackButton
                continuous
                spotlightClicks
                disableScrollParentFix
                styles={{
                  options: {
                    primaryColor: '#50B848',
                  },
                }}
              />

              <Joyride
                run={this.state.runFinishTour}
                steps={this.state.finishTourSteps}
                callback={this.handleJoyrideFinishCallback}
                tooltipComponent={finishTutorialTooltip}
                disableOverlayClose
                showSkipButton
                hideBackButton
                continuous
                spotlightClicks
                disableScrollParentFix
                styles={{
                  options: {
                    primaryColor: '#50B848',
                  },
                }}
              />
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                fontSize: 12,
                paddingTop: 4,
              }}
            >
              <div style={{ marginRight: 10 }}>Version: {metaData.build}</div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  loggedInUserData: state.adminData.loggedInUserData,
  organizationDepartments: state.adminData.organizationDepartments,
  allActions: state.adminData.allActions,
  sites: state.adminData.sites,
  orgProviders: state.adminData.orgProviders,
  distinctOrgActions: state.adminData.distinctOrgActions,
  trainingDocumentation: state.adminData.trainingDocumentation,
  organization: state.adminData.organization,
  providerAssignmentUsers: state.groupData.providerAssignmentUsers,
});

export default connect(mapStateToProps, {
  fetchLoggedInUser,
  fetchOrganizationDepartments,
  fetchAllActions,
  fetchSites,
  fetchDistinctOrgActions,
  logAction,
  logout,
  fetchTrainingDocumentation,
  fetchProviderAssignmentUsers,
})(withStyles(styles)(Navigation));