import React, { useEffect, useRef } from 'react';

interface TProps {
  url: string;
  duration: number;
  onEnd: () => void;
}

const TimedIframe: React.FC<TProps> = ({ url, duration, onEnd }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      onEnd();
    }, duration * 1000);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, url]);

  return (
    <div ref={containerRef} className="w-full h-full">
      <iframe
        src={url}
        width="100%"
        height="100%"
        className="border-0"
        title="Timed content"
        allow="autoplay; fullscreen"
      />
    </div>
  );
};

export default TimedIframe;
