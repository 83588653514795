import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IStaffMember } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';


type TProps = {
  userId: string;
  orgId: string;
};

export const useStaffLocations = (
  input: TProps
): {
  staffLocations: IStaffMember[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IStaffMember[]>;
} => {
  const { userId, orgId } = input;

  const [staffLocations, setStaffLocations] = useState<IStaffMember[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchStaffLocations = useCallback(async (): Promise<IStaffMember[]> => {
    if (!orgId) {
      return [] as IStaffMember[];
    }

    setIsLoading(true);
    setError(null);

    let token = localStorage.getItem('token');
    let URL = API.REACT_APP_API_LOCATESTAFF + userId + '/orgId=' + orgId;

    let headers = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Org-UID': 'UID1',
        Pragma: 'no-cache',
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("staff locations", response.data.data);

        setStaffLocations(response.data.data);
      })
      .catch(err => {
        console.log('Err fetching staff locations', err);
        if (err.response) {
          if (err.response?.status === 401) {
            // FORCE LOGOUT, UNAUTHORIZED
            checkRefreshToken();
            // localStorage.removeItem("token");
            // localStorage.removeItem("email");

            // if (getQueryVariable("username") && getQueryVariable("password")) {
            //   // Reload FS with login params
            //   let location = window.location.href;
            //   window.location.replace(location);
            // } else {
            //   window.location.replace("/");
            // }
          }
        }
      });
  }, [orgId, userId]);

  useEffect(() => {
    fetchStaffLocations();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IStaffMember[]> => {
    return fetchStaffLocations();

    // eslint-disable-next-line
  }, []);

  return {
    staffLocations,
    isLoading,
    error,
    refetch,
  };
};
