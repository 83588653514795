import { IEHRMapping } from '../../../../interfaces/interfaces';

export const EHR_TEMPLATE: Omit<IEHRMapping, 'id' | 'tempId' | 'orgId'> = {
  ehrMappingType: 'Provider/Resource',
  ehrMappingStatus: 'Included',
  importSuccess: false,
  ehrId: '',
  ehrName: '',
  linkedSyncTimesObjects: [],
  deptsToInclude: [],
  isLinked: false,
  fuzzyMatchPercent: 0,
};
