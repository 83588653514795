import moment from 'moment';
import { TProviderToOverride } from '../pages/lobby/types';
import { API } from '../apiconfig';
import { checkRefreshToken } from '../utils/utils';

const useLobby = () => {
  const onOverrideTime = (input: {
    hoursInEffect: number;
    overrideTime: string;
    providerToOverride: TProviderToOverride;
  }) => {
    const { hoursInEffect, overrideTime, providerToOverride } = input;

    // console.log("providerToOverride", this.state.providerToOverride);
    // console.log("hoursInEffect"", this.state.hoursInEffect);

    let now = moment.utc();
    let expireDate = now.add(hoursInEffect, 'hours').toISOString();

    // In string format utc

    // console.log("expire date", expireDate);
    // find longest waiting patient
    let selectedPatient: any = null;
    let oldestTime: number = null;
    providerToOverride.patients.forEach(patient => {
      let timeInWaitingRoom = moment()
        .local()
        .diff(moment.utc(patient.startVisitTime).local(), 'minutes');
      if (!oldestTime) {
        selectedPatient = patient;
        oldestTime = timeInWaitingRoom;
      } else {
        if (oldestTime < timeInWaitingRoom) {
          selectedPatient = patient;
          oldestTime = timeInWaitingRoom;
        }
      }
    });

    // console.log("oldest time", oldestTime);
    // console.log("oldest patient", selectedPatient);

    if (selectedPatient) {
      let payload = {
        deptId: providerToOverride.deptId,
        providerId: selectedPatient.providerId,
        // orgId: selectedPatient.orgId,
        utcExpirationTime: expireDate,
        overrideProviderStatus: overrideTime,
      };

      // console.log("Payload", payload);

      let token = localStorage.getItem('token');

      return fetch(API.REACT_APP_API_PATIENTLOBBY, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          // console.log("res", res);
          // this.setState({
          //   showTimeOverride: false,
          //   providerToOverride: null,
          //   overrideTime: null,
          //   hoursInEffect: 1,
          // });
        })
        .catch(err => {
          console.log('err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  return {
    onOverrideTime,
  };
};

export default useLobby;
