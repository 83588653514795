import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useCallback, useState } from 'react';
import { NotifyContext } from './NotifyContext';
import { TNotifyInput, TNotifyVariant } from './types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const DEFAULT_AUTO_HIDE_DURATION = 5000;

export const NotifyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState<TNotifyVariant>('success');
  const [autoHideDuration, setAutoHideDuration] = useState(DEFAULT_AUTO_HIDE_DURATION);

  const notify = useCallback((input: TNotifyInput) => {
    const { message, variant = 'success', autoHideDuration = DEFAULT_AUTO_HIDE_DURATION } = input;

    setMessage(message);
    setVariant(variant);
    setOpen(true);
    setAutoHideDuration(autoHideDuration);
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <NotifyContext.Provider value={{ notify }}>
      {children}
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={variant}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </NotifyContext.Provider>
  );
};
