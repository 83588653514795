import { ISite } from "../../../../interfaces/interfaces";

const getTodayFormatted = () => {
  const today = new Date();
  return `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()} 12:00:00 AM`;
};

export const SITE_TEMPLATE: ISite = {
  siteName: "",
  isActive: true,
  timeZoneId: 9,
  id: "",
  isLocked: false,
  goLiveDate: getTodayFormatted(),
  campusId: "",
  mongoOrgId: "",
};