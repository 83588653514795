import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import Actions from './actions/Actions';
import Sounds from './Sounds';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    maxWidth: 1000,
    color: '#000000',
    backgroundColor: '#FFFFFF',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const Media: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom component="h2">
        Media
      </Typography>
      <AppBar position="static" className={classes.appBar}>
        <Tabs indicatorColor="primary" value={value} onChange={handleTabChange}>
          <Tab label="Actions" />
          <Tab label="Sounds" />
        </Tabs>
      </AppBar>
      <br />
      {value === 0 && <Actions />}
      {value === 1 && <Sounds />}
    </div>
  );
};

export default Media;