import React, { Component } from "react";
import { API } from "../../../apiconfig";
import { getQueryVariable, checkRefreshToken } from "../../../utils/utils";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { fetchGroup, updateGroup } from "../../../actions/flowstationActions";
import { fetchLoggedInUser, fetchDepartment, fetchOrganizationDepartments, fetchSecurityDepts, updateSecurityDepts } from "../../../actions/adminActions";

import ReactGA from "react-ga";

import DepartmentItem from "./DepartmentItem";

import {
  // socket,
  connection,
  allowSignalR,
} from "../../../socket-manager";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import VolumeOff from "@material-ui/icons/VolumeOff";
import VolumeUp from "@material-ui/icons/VolumeUp";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Error from "@material-ui/icons/Error";
// import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import synctimesLogo from "../../../images/synctimes.png";
import synctimesLogoDev from "../../../images/synctimes-development.png";
import { getObjectFromGzipEncodedData } from "../../../utils/Compression";
import { IAction, IDepartment, IUser } from "../../../interfaces/interfaces";

const _ = require("lodash");

let lastCheckedTimeForNonSignalR: any = null;

const styles: {} = (theme: any) => ({
  appBar: {
    boxShadow: "0px 0px 5px 0px #707070",
    color: "#000000",
    marginBottom: 20,
    backgroundColor: "#ffffff",
  },
  headerImage: {
    paddingTop: 5,
    maxWidth: 200,
  },
  appBarTitle: {
    fontSize: 25,
    paddingLeft: 20,
    color: "#7e7e7e",
  },
  rightToolBar: {
    marginLeft: "auto",
    marginRight: "-12px",
    display: "flex",
    alignItems: "center",
  },
  actionIcon: {
    width: 50,
  },
  actionIconSmall: {
    width: 40,
    height: 40,
  },
  drawerActionIcon: {
    width: 50,
    cursor: "pointer",
  },
  fadedActionId: {
    width: 50,
    opacity: "0.2",
    cursor: "pointer",
  },
  volumeIcon: {
    height: 50,
    width: 50,
    cursor: "pointer",
  },
  alertCard: {
    marginRight: "2%",
    marginLeft: "2%",
    marginTop: 25,
  },
  roomList: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 24,
  },
  socketConnected: {
    color: "#4CB946",
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
  },
  socketDisconnected: {
    color: "red",
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
  },
  drawerAction: {
    display: "inline-block",
  },
  sortCheckbox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
  sectionTitle: {
    fontSize: 25,
    paddingLeft: 20,
    color: "#FFFFFF",
    backgroundColor: "#0096DB",
  },
  alertIconRow: {
    padding: 5,
    marginBottom: 10,
    backgroundColor: "#FFB2B2",
  },
  returnHomeButton: {
    color: "#7e7e7e",
  },
});

interface UserSubscriptionProps {
  userInfo: IUser;
}

interface UserSubscriptionState {
  intervalId: any;
}

class UserSubscription extends Component<UserSubscriptionProps, UserSubscriptionState> {
  constructor(props: UserSubscriptionProps) {
    super(props);
    this.state = {
      intervalId: 0,
    };
  }

  componentDidMount() {
    if (connection.state === "Connected") {
      console.log("Subscribe UserId", this.props.userInfo.id);
      connection.send("userPing", this.props.userInfo.id);

      let sendDeviceId = () => {
        console.log("9 Minutes, resend DeviceId");
        connection.send("userPing", this.props.userInfo.id);
      };

      let intervalId = setInterval(sendDeviceId, 540000);
      this.setState({ intervalId: intervalId });
    }
  }

  componentWillUnmount() {
    console.log("Kill UserPing Interval");
    clearInterval(this.state.intervalId);
  }
  render() {
    return <></>;
  }
}

interface DeviceSubscriptionProps {
  deviceId: string;
}

interface DeviceSubscriptionState {
  intervalId: any;
}

class DeviceSubscription extends Component<DeviceSubscriptionProps, DeviceSubscriptionState> {
  constructor(props: DeviceSubscriptionProps) {
    super(props);
    this.state = {
      intervalId: 0,
    };
  }

  componentDidMount() {
    if (connection.state === "Connected") {
      console.log("Subscribe DeviceId", this.props.deviceId);
      connection.send("devicePing", this.props.deviceId);

      let sendDeviceId = () => {
        console.log("9 Minutes, resend DeviceId");
        connection.send("devicePing", this.props.deviceId);
      };

      let intervalId = setInterval(sendDeviceId, 540000);
      this.setState({ intervalId: intervalId });
    }
  }

  componentWillUnmount() {
    console.log("Kill DeviceId Interval");
    clearInterval(this.state.intervalId);
  }
  render() {
    return <></>;
  }
}

interface DepartmentContainerProps {
  loggedInUserData: IUser;
  organizationDepartments: IDepartment[];
  securityDepartments: IDepartment[];
  classes: any;
  fetchSecurityDepts: (orgDepts: any) => void;
  updateSecurityDepts: (depts: any) => void;
}

interface DepartmentContainerState {
  actionsToShow: any[];
  mutedActions: any[];
}

class DepartmentContainer extends Component<DepartmentContainerProps, DepartmentContainerState> {
  constructor(props: DepartmentContainerProps) {
    super(props);
    this.state = {
      actionsToShow: [],
      mutedActions: [],
    };
  }

  componentDidMount() {
    this.props.fetchSecurityDepts(this.props.organizationDepartments);

    if (!localStorage.getItem("departmentsToShow")) {
      localStorage.setItem("departmentsToShow", JSON.stringify([]));
    }

    if (!localStorage.getItem("actionsToShow")) {
      localStorage.setItem("actionsToShow", JSON.stringify([]));
    } else {
      this.setState({
        actionsToShow: JSON.parse(localStorage.getItem("actionsToShow") || "{}"),
      });
    }

    if (!localStorage.getItem("mutedActions")) {
      localStorage.setItem("mutedActions", JSON.stringify([]));
    } else {
      this.setState({
        mutedActions: JSON.parse(localStorage.getItem("mutedActions") || "{}"),
      });
    }

    if (allowSignalR) {
      connection.on("updateRoomGzip", (roomDataEncoded: any) => {
        console.log("Received room update");
        let updatedRoomData = getObjectFromGzipEncodedData(roomDataEncoded);

        if (!updatedRoomData.message) {
          let roomMongoDeptId = updatedRoomData.mongoDeptId;
          let depts = [...this.props.securityDepartments];
          let deptToModify: any;

          depts.forEach((dept) => {
            if (dept.id === roomMongoDeptId) {
              deptToModify = dept;
            }
          });

          let roomsToModify = [...deptToModify.rooms];
          roomsToModify.forEach((room, index) => {
            if (room.id === updatedRoomData.id) {
              roomsToModify[index] = updatedRoomData;
            }
          });

          depts.forEach((dept, index) => {
            if (dept.id === deptToModify.id) {
              depts[index].rooms = roomsToModify;
            }
          });

          this.props.updateSecurityDepts(depts);
          this.forceUpdate();
        }
      });

      connection.on("updateFsGzip", (updateDataEncoded: any) => {
        console.log("Received fs update");
        // Make sure it isn't an alert message coming through room endpoint

        var updateData = getObjectFromGzipEncodedData(updateDataEncoded);

        if (!updateData.message) {
          let arr = this.props.securityDepartments;

          let index = _.findIndex(arr, { id: updateData.id });

          arr.splice(index, 1, updateData.fsData);

          this.props.updateSecurityDepts(arr);
          this.forceUpdate();
        }
      });
    }

    let incrementTime = () => {
      // this.setState({
      //   cardInterval: !this.state.cardInterval
      // })

      if (!allowSignalR) {
        // console.log('tick')
        // console.log('SignalR not allowed, auto fetch every 15 seconds.')
        if (!lastCheckedTimeForNonSignalR) {
          lastCheckedTimeForNonSignalR = moment();
        } else {
          // console.log('diff', moment().diff(lastCheckedTimeForNonSignalR, "seconds"))
          if (moment().diff(lastCheckedTimeForNonSignalR, "seconds") > 15) {
            lastCheckedTimeForNonSignalR = moment();
            console.log("No SignalR, auto refetch...");

            if (this.props.organizationDepartments.length > 0) {
              this.props.fetchSecurityDepts(this.props.organizationDepartments);
            }

            // this.props.fetchDepartment(groupId)
          }
        }
      }

      this.forceUpdate();
    };
    incrementTime();
    setInterval(incrementTime, 1000);
  }

  toggleAction = (id: string) => {
    ReactGA.event({
      category: "Alerts",
      action: "User toggled an Action to view",
      label: "Action Toggle",
    });
    if (this.state.actionsToShow.indexOf(id) > -1) {
      let array = [...this.state.actionsToShow];
      let index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ actionsToShow: array });
        localStorage.setItem("actionsToShow", JSON.stringify(array));
      }
    } else {
      let array = JSON.parse(localStorage.getItem("actionsToShow") || "{}");
      array.push(id);
      localStorage.setItem("actionsToShow", JSON.stringify(array));
      this.setState({
        actionsToShow: [...this.state.actionsToShow, id],
      });
    }
  };

  toggleMute = (id: string) => {
    if (this.state.mutedActions.indexOf(id) > -1) {
      let array = [...this.state.mutedActions];
      let index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ mutedActions: array });
        localStorage.setItem("mutedActions", JSON.stringify(array));
      }
    } else {
      let array = JSON.parse(localStorage.getItem("mutedActions") || "{}");
      array.push(IDBCursor);
      localStorage.setItem("mutedActions", JSON.stringify(array));
      this.setState({
        mutedActions: [...this.state.mutedActions, id],
      });
    }
  };

  clearIcon = (actionData: any) => {
    ReactGA.event({
      category: "Alerts",
      action: "User deleted an Action from a Department",
      label: "Delete Action",
    });
    console.log("action data", actionData);

    let token = localStorage.getItem("token");
    let data = {
      roomId: actionData.roomData.id,
      actionId: actionData.id,
    };

    console.log("payload", data);

    axios({
      method: "post",
      url: API.REACT_APP_API_CLEARICON,
      data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        // console.log("Success", response);
        if (this.props.organizationDepartments.length > 0) {
          if (!allowSignalR) {
            // console.log("REFETCH ALL AGAIN")
            this.props.fetchSecurityDepts(this.props.organizationDepartments);
          }
        }
      })
      .catch((err) => {
        console.log("Error removing action", err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  toggleDepartment = (department: string) => () => {
    ReactGA.event({
      category: "Alerts",
      action: "User toggled a Department to view",
      label: "Department Toggle",
    });
    if (JSON.parse(localStorage.getItem("departmentsToShow") || "{}").indexOf(department) > -1) {
      let array = [...JSON.parse(localStorage.getItem("departmentsToShow") || "{}")];
      // console.log("array", array);
      let index = array.indexOf(department);
      if (index !== -1) {
        array.splice(index, 1);

        localStorage.setItem("departmentsToShow", JSON.stringify(array));
        this.forceUpdate();
      }
    } else {
      let array = JSON.parse(localStorage.getItem("departmentsToShow") || "{}");
      array.push(department);

      // console.log(array);

      localStorage.setItem("departmentsToShow", JSON.stringify(array));
      this.forceUpdate();
    }
  };

  returnHome = () => {
    localStorage.removeItem("lastVisitedFs");
    localStorage.removeItem("lastVisitedView");
    window.location.replace("/");
  };

  render() {
    const { classes } = this.props;

    // console.log("muted actions", this.state.mutedActions);
    // console.log("actions to show", this.state.actionsToShow);

    let departmentsToShow: any[] = [];
    if (JSON.parse(localStorage.getItem("departmentsToShow") || "{}")) {
      // console.log("Depts to show");
      departmentsToShow = JSON.parse(localStorage.getItem("departmentsToShow") || "{}");
    }

    let departments: any[] = [];
    this.props.securityDepartments.forEach((dept, index) => {
      departments.push(
        <DepartmentItem
          key={index}
          dept={dept}
          actionsToPlaySoundsOn={this.state.actionsToShow}
          mutedActions={this.state.mutedActions}
          allowSounds={departmentsToShow.indexOf(dept.id) > -1 ? true : false}
        />
      );
    });

    let actionsToDisplay: any[] = [];

    this.props.securityDepartments.forEach((dept) => {
      // If local storage departmentsToShow is EMPTY, then show all departments.
      // Check if filter out departments we only want to show

      // console.log("CHECK DEPT", dept);

      if (JSON.parse(localStorage.getItem("departmentsToShow") || "{}").length === 0) {
        // console.log("Empty, show all");
        // console.log("DEPT", dept);
        dept.rooms.forEach((room) => {
          room.roomActions.forEach((action: IAction) => {
            if (_.includes(this.state.actionsToShow, action.id)) {
              actionsToDisplay.push({ id: action.id, actionData: action, deptData: dept, roomData: room });
            }
          });
        });
      } else if (_.includes(departmentsToShow, dept.id)) {
        // If local storage departmentsToShow contains ids, then start filtering
        // console.log("NOT empty, show specific");
        dept.rooms.forEach((room) => {
          room.roomActions.forEach((action: IAction) => {
            if (_.includes(this.state.actionsToShow, action.id)) {
              actionsToDisplay.push({ id: action.id, actionData: action, deptData: dept, roomData: room });
            }
          });
        });
      }
    });

    let groupedActions = _.groupBy(actionsToDisplay, "id");

    let allDeptActions: IAction[] = [];
    let allDeptActionsIds: string[] = []; // <-- This is used primarily for filtering out actions that are in cookies but is no longer in the dept. By using _.includes
    this.props.organizationDepartments.forEach((dept) => {
      if (dept.isActive) {
        dept.actionsList.forEach((action) => {
          allDeptActions.push(action);
          allDeptActionsIds.push(action.id);
        });
      }
    });

    let filterableActions = _.uniqBy(allDeptActions, "id");

    // console.log("filterable", filterableActions);

    this.state.actionsToShow.forEach((action) => { });

    // console.log("sec depts", this.props.securityDepartments);

    // console.log("Sites", this.props.sites);

    let deviceId = null;
    if (getQueryVariable("deviceId")) {
      deviceId = getQueryVariable("deviceId");
    }

    let isDev = false;
    if (window.location.hostname === "localhost" || window.location.hostname === "dev.synctimes.com") {
      isDev = true;
    }

    return (
      <div>
        {allowSignalR ? (
          <>{this.props.loggedInUserData.id && connection.state === "Connected" ? <UserSubscription userInfo={this.props.loggedInUserData} /> : null}</>
        ) : null}
        {allowSignalR ? <>{connection.state === "Connected" && deviceId ? <DeviceSubscription deviceId={deviceId} /> : null}</> : null}

        <Toolbar className={classes.appBar}>
          <Typography>
            <img className={classes.headerImage} src={isDev ? synctimesLogoDev : synctimesLogo} alt="SyncTimes" />
          </Typography>
          <Typography className={classes.appBarTitle}>Alerts Dashboard</Typography>

          <section className={classes.rightToolBar}>
            {!allowSignalR ? (
              <div className={classes.socketDisconnected}>
                <Error /> Connection may be delayed
              </div>
            ) : null}

            {allowSignalR ? (
              <>
                {connection.state !== "Connected" ? (
                  <div className={classes.socketDisconnected}>
                    <Error /> Connection may be delayed
                  </div>
                ) : null}
              </>
            ) : null}

            {!getQueryVariable("username") ? (
              <Tooltip title="Return home" placement="left">
                <IconButton onClick={this.returnHome}>
                  <HomeIcon style={{ color: "black" }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </section>
        </Toolbar>

        <Grid container>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.sectionTitle}>Actions</Typography>

            {filterableActions.map((action: IAction, index: any) => (
              <div key={index} className={classes.drawerAction}>
                {action.id !== "5f6fb35be0a5f22bf0d6a122" ? (
                  <div onClick={() => this.toggleAction(action.id)}>
                    <img
                      className={_.includes(this.state.actionsToShow, action.id) ? classes.drawerActionIcon : classes.fadedActionId}
                      src={action.actionImageUrl}
                      alt="#"
                    />
                  </div>
                ) : null}
              </div>
            ))}

            <Typography className={classes.sectionTitle}>Departments</Typography>
            {this.props.securityDepartments.map((dept, index) => (
              <div key={index}>
                <div style={{ fontSize: 25, cursor: "pointer" }} onClick={this.toggleDepartment(dept.id)}>
                  <Checkbox
                    classes={{
                      root: classes.sortCheckbox,
                      checked: classes.checked,
                    }}
                    checked={departmentsToShow.indexOf(dept.id) > -1 ? true : false}
                  />
                  {dept.deptName}
                  {/* {this.props.sites.map((site) => (site.id === dept.mongoSiteId ? site.siteName : null))} {dept.deptName} */}
                </div>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography className={classes.sectionTitle}>Filtered Actions</Typography>
            {this.state.actionsToShow.map((id, index) => (
              <div key={index}>
                {_.includes(allDeptActionsIds, id) ? (
                  <Card className={classes.alertCard}>
                    <CardContent>
                      {filterableActions.map((deptAction: IAction, index: any) => (
                        <div key={index}>
                          {deptAction.id === id ? (
                            <div>
                              <img className={classes.actionIcon} src={deptAction.actionImageUrl} alt="#" />
                              <span>
                                {_.includes(this.state.mutedActions, id) ? (
                                  <VolumeOff className={classes.volumeIcon} onClick={() => this.toggleMute(id)} />
                                ) : (
                                  <VolumeUp className={classes.volumeIcon} onClick={() => this.toggleMute(id)} />
                                )}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      ))}

                      {groupedActions[id] ? (
                        <div>
                          <Divider />
                          <CardContent>
                            {groupedActions[id].map((action: any, index: any) => (
                              <div key={index} className={classes.alertIconRow}>
                                <img src={action.actionData.actionImageUrl} className={classes.actionIconSmall} style={{ verticalAlign: "bottom" }} alt="#" />
                                {/* {console.log("ACTION", action)} */}
                                <span style={{ fontSize: 32, paddingLeft: 10 }}>
                                  {action.deptData.deptName} - {action.roomData.roomName}
                                </span>
                                <span style={{ float: "right", fontSize: 32 }}>
                                  {moment().local().diff(moment.utc(action.actionData.startDateTime), "minutes") > 60 ? (
                                    "60+ "
                                  ) : (
                                    <span>{moment().local().diff(moment.utc(action.actionData.startDateTime), "minutes")} </span>
                                  )}
                                  minutes
                                  <span
                                    onClick={() => this.clearIcon(action)}
                                    style={{ cursor: "pointer", color: "red", float: "right", fontSize: 32, paddingLeft: 10 }}
                                  >
                                    x
                                  </span>
                                </span>
                              </div>
                            ))}
                          </CardContent>
                        </div>
                      ) : null}
                    </CardContent>
                  </Card>
                ) : null}
              </div>
            ))}
          </Grid>
        </Grid>

        <div>{departments}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  groupData: state.groupData.groupData,
  loggedInUserData: state.adminData.loggedInUserData,
  department: state.adminData.department,
  organizationDepartments: state.adminData.organizationDepartments,
  securityDepartments: state.adminData.securityDepts,
  sites: state.adminData.sites,
});

export default connect(mapStateToProps, {
  fetchOrganizationDepartments,
  fetchGroup,
  updateGroup,
  fetchLoggedInUser,
  fetchDepartment,
  fetchSecurityDepts,
  updateSecurityDepts,
})(withStyles(styles)(DepartmentContainer));
