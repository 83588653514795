import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';

import CloseButton from '@material-ui/icons/Clear';
import { shallowEqual, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../AppContext';
import { useDepartment } from '../../../../hooks/department/useDepartment';
import { useFetchPatientContentList } from '../../../../hooks/useFetchPatientContentList';
import { IDepartment, ISite, TAdminState } from '../../../../interfaces/interfaces';
import { DRAWER_WIDTH } from './constants';
import { DepartmentProvider } from './DepartmentContext';
import DepartmentForm, { TFormSchema } from './DepartmentForm';
import { schema } from './schema';
import { useStyles } from './style';
import { getInitialValues } from './utils';

type TProps = {
  isNew?: boolean;
  departmentId: string;
  open: boolean;
  onHide: (...params: any) => any;
  showNewDepartmentModal: boolean;
  toggleCopyWarning: (...params: any) => any;
  selectDepartment: (...params: any) => any;
  handleChange: (...params: any) => any;
  handleNewDialogSuccess: (...params: any) => any;
  handleCopyDepartment: (...params: any) => any;
  handlePasteDepartment: (...params: any) => any;
  sites: ISite[];
  organizationDepartments: IDepartment[];
  siteOptions: any;
  hasModified: boolean;
  loading: boolean;
  showCopyWarning: boolean;
  handleSubmit: (...params: any) => any;
  onSuccess: (...params: any) => any;
};

const DepartmentDrawer: FC<TProps> = props => {
  const {
    isNew = false,
    departmentId,
    open = false,
    onHide,
    showNewDepartmentModal,
    toggleCopyWarning,
    sites,
    organizationDepartments,
    handleCopyDepartment,
    handlePasteDepartment,
    loading,
    showCopyWarning,
    onSuccess,
  } = props;

  const [defaultValues, setDefaultValues] = useState<TFormSchema | null>(null);
  const [showSaveAlert, setShowSaveAlert] = useState(false);

  const { loggedInUser } = useAppContext();

  const { organization } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state.adminData ?? ({} as TAdminState);

      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const {
    department,
    refetch,
    update,
    create,
    isLoading: departmentLoading,
  } = useDepartment({
    departmentId,
    initialFetch: !isNew,
  });

  const { patientContentList = [], isLoading: patientContentLoading } =
    useFetchPatientContentList(loggedInUser?.mongoOrganizationId);

  const formMethods = useForm<TFormSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const reset = formMethods.reset;
  const isDirty = formMethods.formState.isDirty;
  const handleSubmit = formMethods.handleSubmit;

  useEffect(() => {
    // console.log({ department, patientContentList, isNew });
    if (!isNew && isEmpty(department)) return;

    // Remove the check for isEmpty(patientContentList)
    const initialValues = getInitialValues({
      department,
      patientContentList,
      isNew,
    });

    setDefaultValues(initialValues);
    reset(initialValues);
  }, [department, patientContentList, isNew, reset]);

  let copiedDepartment = !isEmpty(localStorage.getItem('copiedDepartment'))
    ? JSON.parse(localStorage.getItem('copiedDepartment') || '')
    : null;

  const classes = useStyles();

  const onUpdate = (values: TFormSchema) => {
    return update({
      departmentId: departmentId,
      // @ts-ignore
      data: {
        ...department,
        ...values,
      },
    }).then(updatedDepartment => {
      onHide();
      onSuccess();
    });
  };

  const onCreate = (values: TFormSchema) => {
    // console.log('DepartmentDrawer onSubmit', { values, departmentId });

    // return new Promise(() => {
    //   console.log('DepartmentDrawer onSubmit then', { values, departmentId });
    // });

    return create({
      departmentId: departmentId,
      // @ts-ignore
      data: {
        ...department,
        ...values,
      },
    }).then(updatedDepartment => {
      console.log('DepartmentDrawer onSubmit then', { updatedDepartment });

      onHide();
      onSuccess();
    });
  };

  const onProccessHide = () => {
    if (isDirty) {
      setShowSaveAlert(true);
    } else {
      onHide();
    }
  };

  const filteredOrganizationDepartments = organizationDepartments.filter(
    currentDepartment => {
      return (
        currentDepartment.isActive &&
        currentDepartment.id !== department.id &&
        !currentDepartment.inheritSettingsFromDeptId
      );
    }
  );

  // const organizationDepartmentOptions = filteredOrganizationDepartments.map(
  //   (currentDepartment, index: number) => (
  //     <option key={index} value={currentDepartment.id}>
  //       {currentDepartment.siteName} {currentDepartment.deptName}
  //     </option>
  //   )
  // );

  // organizationDepartmentOptions.unshift(<option value=""></option>);

  if (isNew) {
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={() => onProccessHide()}
        PaperProps={{
          style: {
            width: DRAWER_WIDTH,
          },
        }}
      >
        <DepartmentProvider
          value={{
            department,
            isLoading: loading,
            refetch,
            error: null,
          }}
        >
          <div className={classes.drawer}>
            <div
              className={classes.closeButton}
              onClick={() => onProccessHide()}
            >
              <CloseButton />
            </div>
            <h2>New Department</h2>
            <DepartmentForm
              isNew={isNew}
              formMethods={formMethods}
              department={department}
              organization={organization}
              sites={sites}
              organizationDepartments={filteredOrganizationDepartments}
              patientContentList={patientContentList?.filter(patientContent => {
                return patientContent?.isActive;
              })}
              onSubmit={onCreate}
            />
          </div>
        </DepartmentProvider>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => onProccessHide()}
      PaperProps={{
        style: {
          width: DRAWER_WIDTH,
        },
      }}
    >
      <DepartmentProvider
        value={{
          department,
          isLoading: loading,
          refetch,
          error: null,
        }}
      >
        {!isNew && isEmpty(department) && 'Loading...'}
        {!isEmpty(department) && (
          <div className={classes.drawer}>
            <div
              className={classes.closeButton}
              onClick={() => onProccessHide()}
            >
              <CloseButton />
            </div>

            <h2>Editing {department.deptName}</h2>

            {!showNewDepartmentModal ? (
              <div>
                <Button onClick={handleCopyDepartment}>Copy</Button>
                <Button
                  disabled={copiedDepartment ? false : true}
                  onClick={() => toggleCopyWarning(true)}
                  className={classes.executeButton}
                >
                  Paste
                </Button>
              </div>
            ) : null}

            {defaultValues !== null &&
              !(patientContentLoading || departmentLoading) && (
                <DepartmentForm
                  formMethods={formMethods}
                  isNew={isNew}
                  department={department}
                  organization={organization}
                  sites={sites}
                  organizationDepartments={filteredOrganizationDepartments}
                  patientContentList={patientContentList?.filter(
                    patientContent => {
                      return patientContent?.isActive;
                    }
                  )}
                  onSubmit={onUpdate}
                />
              )}

            {!showNewDepartmentModal && (
              <div>
                <div className={classes.expansionsContainer}>
                  <div
                    style={
                      department.inheritSettingsFromDeptId
                        ? { display: 'none' }
                        : {}
                    }
                  >
                    <Dialog
                      onClose={() => toggleCopyWarning(false)}
                      aria-labelledby="simple-dialog-title"
                      open={showCopyWarning}
                    >
                      <DialogTitle id="alert-dialog-title">
                        Overwrite department with copied department{' '}
                        <span style={{ color: '#50B848' }}>
                          {copiedDepartment ? copiedDepartment.deptName : null}
                        </span>
                        ?
                      </DialogTitle>
                      <DialogActions style={{ justifyContent: 'center' }}>
                        <Button
                          color="primary"
                          variant="contained"
                          autoFocus
                          onClick={handlePasteDepartment}
                        >
                          Yes
                        </Button>
                        <Button
                          color="default"
                          onClick={() => toggleCopyWarning(false)}
                        >
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                  <br />
                </div>
              </div>
            )}
          </div>
        )}

        <Dialog open={showSaveAlert}>
          <DialogTitle>Unsaved changes!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to save your changes before exiting?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                if (isNew) {
                  handleSubmit(onCreate)();
                } else {
                  handleSubmit(onUpdate)();
                }
              }}
            >
              Yes
            </Button>
            <Button color="primary" autoFocus onClick={() => onHide()}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </DepartmentProvider>
    </Drawer>
  );
};

export default DepartmentDrawer;
