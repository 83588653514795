import BarChartIcon from '@material-ui/icons/BarChart';
import MenuIcon from '@material-ui/icons/MoreVert';
import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TAdminState } from '../../../../interfaces/interfaces';
import { canDo } from '../../../../utils/permissionCheck';
import RoomName from './RoomName';

type TProps = {
  cardData: any;
  darkMode: boolean;
  mouseDown: (...params: any) => any;
  mouseUp: (...params: any) => any;
  openRoomPatientHistory: (...params: any) => any;
  groupData: any;
};

const RoomItemHead: FC<TProps> = props => {
  const {
    cardData,
    darkMode,
    mouseDown,
    mouseUp,
    openRoomPatientHistory,
    groupData,
  } = props;

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  return (
    <div
      className={`${cardData.statusName} height-[37px] flex justify-between items-center pl-1 pr-1`}
      style={darkMode ? { color: 'white' } : {}}
    >
      <div className="w-full flex justify-between items-center cursor-pointer">
        <div onMouseDown={mouseDown} onMouseUp={mouseUp}>
          <RoomName name={cardData.roomName} />
        </div>
        <div className="flex items-center">
          {canDo(['Update Flowstation'], loggedInUserData) && (
            <>
              {cardData.isPatientRoom && (
                <>
                  <BarChartIcon
                    className="cursor-pointer"
                    onClick={openRoomPatientHistory}
                    fontSize="small"
                  />
                  {cardData.isPatientRoom &&
                    groupData.isFrontDeskIntegration === true && (
                      <MenuIcon
                        className="cursor-pointer"
                        onMouseDown={mouseDown}
                        onMouseUp={mouseUp}
                        fontSize="small"
                      />
                    )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomItemHead;
