import { Button, withStyles } from "@material-ui/core";

export const DeleteButton = withStyles((theme) => ({
  root: {
    color: "#FF0000",
    border: "1px solid #FF0000",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFE5E5",
    },
  },
}))(Button);