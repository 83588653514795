// # CesiumAPI documentation: https://cesiumapi-dev.azurewebsites.net/documentation/index.html

let REACT_APP_API_BASE_URL = 'https://api.synctimes.com';
let FSEndpoint = 'https://functions.synctimes.com/api/v3/fs/deptId=';
let FunctionsEndpoint = 'https://functions.synctimes.com';
let MSALSSO = 'https://functions.synctimes.com/api/v3/token/msalsso';

// Set endpoints to dev if localhost or dev
if (
  window.location.hostname === 'dev.synctimes.com' ||
  window.location.hostname === 'localhost' ||
  window.location.hostname === '192.168.1.119'
) {
  REACT_APP_API_BASE_URL = 'https://api-dev.synctimes.com';
  FSEndpoint = 'https://functions-dev.synctimes.com/api/v3/fs/deptId=';
  FunctionsEndpoint = 'https://functions-dev.synctimes.com';
  MSALSSO = 'https://functions-dev.synctimes.com/api/v3/token/msalsso';
}

if (window.location.hostname === 'static.synctimes.com') {
  REACT_APP_API_BASE_URL = 'https://api.synctimes.com';
}

var ua = navigator.userAgent;
if (ua.indexOf('Android') >= 0) {
  var androidversion = parseFloat(ua.slice(ua.indexOf('Android') + 8));
  console.log('ANDROID VERSION', androidversion);
  console.log(androidversion);
  if (androidversion === 4.4) {
    // Avoid using Azure functions with old 4.4 tablets
    FSEndpoint = `${REACT_APP_API_BASE_URL}/api/v3/fs/deptid=`;
  }
} else {
  // console.log("Using Azure Functions");
}

console.log({ REACT_APP_API_BASE_URL });

const API = {
  REACT_APP_API_FLOWSTATIONDATA: FSEndpoint,
  REACT_APP_API_OLDFLOWSTATIONDATA: `${REACT_APP_API_BASE_URL}/api/v3/fs/olddeptid=`,
  REACT_APP_API_LOGIN: `${REACT_APP_API_BASE_URL}/token`,
  // REACT_APP_API_LOGIN_BY_FLOWSTATION_TOKEN: `${REACT_APP_API_BASE_URL}/token/flowstation`,
  REACT_APP_API_LOGIN_BY_FLOWSTATION_TOKEN: `${REACT_APP_API_BASE_URL}/token/flowstation`,
  REACT_APP_API_LOGINDETAILS: `${REACT_APP_API_BASE_URL}/api/v3/appuser/logindetails`,
  REACT_APP_API_GETSINGLEUSERDATA: `${REACT_APP_API_BASE_URL}/api/v3/appuser/appUserId=`,
  REACT_APP_API_GETAPPUSERSBYORGID: `${REACT_APP_API_BASE_URL}/api/v3/appuser/orgId=`,
  REACT_APP_API_UPDATEUSER: `${REACT_APP_API_BASE_URL}/api/v3/appuser`,
  REACT_APP_API_ORGANIZATIONS: `${REACT_APP_API_BASE_URL}/api/v3/org`,
  REACT_APP_API_ORGANIZATION: `${REACT_APP_API_BASE_URL}/api/v3/org/orgId=`,
  REACT_APP_API_GETPROVIDERS: `${REACT_APP_API_BASE_URL}/api/v3/providerassignment/`,
  REACT_APP_API_UPDATEPROVIDER: `${REACT_APP_API_BASE_URL}/api/v3/providerassignment`,
  REACT_APP_API_CLEARICON: `${REACT_APP_API_BASE_URL}/api/v3/action/removeaction`,
  REACT_APP_API_CLEARUSER: `${REACT_APP_API_BASE_URL}/api/v3/badge/removeuser`,
  REACT_APP_API_CLEARPATIENT: `${REACT_APP_API_BASE_URL}/api/v3/patient/remove`,
  REACT_APP_API_SITES: `${REACT_APP_API_BASE_URL}/api/v3/site/orgid=`,
  REACT_APP_API_CAMPUSES: `${REACT_APP_API_BASE_URL}/api/v3/campus/orgid=`,
  REACT_APP_API_UPDATECAMPUS: `${REACT_APP_API_BASE_URL}/api/v3/campus`,
  REACT_APP_API_UPDATESITE: `${REACT_APP_API_BASE_URL}/api/v3/site`,
  REACT_APP_API_UNLOCKSITE: `${REACT_APP_API_BASE_URL}/api/v3/site/unlock`,
  REACT_APP_API_RESETPASSWORD: `${REACT_APP_API_BASE_URL}/api/v3/appuser/resetpassword`,
  REACT_APP_API_UPDATEPASSWORD: `${REACT_APP_API_BASE_URL}/api/v3/appuser/updatepassword`,
  REACT_APP_API_GETORGDEPARTMENT: `${REACT_APP_API_BASE_URL}/api/v3/dept/orgid=`,
  REACT_APP_API_GETALLACTIONS: `${REACT_APP_API_BASE_URL}/api/v3/dept/actions`,
  REACT_APP_API_GETDEPARTMENT: `${REACT_APP_API_BASE_URL}/api/v3/dept/deptid=`,
  REACT_APP_API_UPDATE_DEPARTMENT: `${REACT_APP_API_BASE_URL}/api/v3/dept`,
  REACT_APP_API_GETSOUNDS: `${REACT_APP_API_BASE_URL}/api/v3/dept/sounds`,
  REACT_APP_API_GETROOMSBYORGID: `${REACT_APP_API_BASE_URL}/api/v3/room/orgId=`,
  REACT_APP_API_ROOM: `${REACT_APP_API_BASE_URL}/api/v3/room`,
  REACT_APP_API_CREATEPATIENT: `${REACT_APP_API_BASE_URL}/api/v3/patient/create`,
  REACT_APP_API_ADDACTION: `${REACT_APP_API_BASE_URL}/api/v3/action/addaction`,
  REACT_APP_API_ADDPATIENT: `${REACT_APP_API_BASE_URL}/api/v3/patient/add`,
  REACT_APP_API_HOLDPATIENT: `${REACT_APP_API_BASE_URL}/api/v3/patient/hold`,
  REACT_APP_API_DELETEPATIENT: `${REACT_APP_API_BASE_URL}/api/v3/patient/delete`,
  REACT_APP_API_ALLDEVICEDETAILS: `${REACT_APP_API_BASE_URL}/api/v3/sockets/details`,
  REACT_APP_API_GETORGDEVICEDETAILS: `${REACT_APP_API_BASE_URL}/api/v3/sockets/details/orgId=`,
  REACT_APP_API_GETDEVICEDETAIL: `${REACT_APP_API_BASE_URL}/api/v3/sockets/details/deviceId=`,
  REACT_APP_API_SENDLOGMESSAGE: `${REACT_APP_API_BASE_URL}/api/v3/sockets/message`,
  REACT_APP_API_ADDDEVICEACTION: `${REACT_APP_API_BASE_URL}/api/v3/sockets/addaction`,
  REACT_APP_API_SENDCLICK: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendclick`,
  REACT_APP_API_APK: `${REACT_APP_API_BASE_URL}/api/v3/apk`,
  REACT_APP_API_REMOVEDEVICEACTION: `${REACT_APP_API_BASE_URL}/api/v3/sockets/removeaction`,
  REACT_APP_API_CREATEACTION: `${REACT_APP_API_BASE_URL}/api/v3/action/createaction`,
  REACT_APP_API_CREATESOUND: `${REACT_APP_API_BASE_URL}/api/v3/action/createsound`,
  REACT_APP_API_REPORTING: `${REACT_APP_API_BASE_URL}/api/v3/data/populategraphs/`,
  REACT_APP_API_DISCONNECTEDEVICES: `${REACT_APP_API_BASE_URL}/api/v3/sockets/disconnecteddetails`,
  REACT_APP_API_ADDDEVICE: `${REACT_APP_API_BASE_URL}/api/v3/sockets/adddevice`,
  REACT_APP_API_UPDATEDEVICE: `${REACT_APP_API_BASE_URL}/api/v3/sockets/updatedevice`,
  REACT_APP_API_GETDEVICELOCATIONS: `${REACT_APP_API_BASE_URL}/api/v3/sockets/devicelocations`,
  REACT_APP_API_GETDEVICELOG: `${REACT_APP_API_BASE_URL}/api/v3/devicelog/deviceId=`,
  REACT_APP_API_ROOMPATIENTS: `${REACT_APP_API_BASE_URL}/api/v3/patient/roompatients`,
  REACT_APP_API_LOCATESTAFF: `${REACT_APP_API_BASE_URL}/api/v3/fs/findusers/userid=`,
  REACT_APP_API_GETINSTRUMENTSBYORGID: `${REACT_APP_API_BASE_URL}/api/v3/instrument/orgId=`,
  REACT_APP_API_UPDATEINSTRUMENT: `${REACT_APP_API_BASE_URL}/api/v3/instrument/`,
  REACT_APP_API_REQUESTFEATURE: `${REACT_APP_API_BASE_URL}/api/v3/fs/requestfeature`,
  REACT_APP_API_SITE: `${REACT_APP_API_BASE_URL}/api/v3/site/siteid=`,
  REACT_APP_API_SUBSCRIPTIONS: `${REACT_APP_API_BASE_URL}/api/v3/appuser/subscriptions`,
  REACT_APP_API_GETORGPROVIDERS: `${REACT_APP_API_BASE_URL}/api/v3/providerassignment/orgId=`,
  REACT_APP_API_DISTINCTORGACTIONS: `${REACT_APP_API_BASE_URL}/api/v3/action/orgid=`,
  REACT_APP_API_ORGANIZATIONSPLASH: `${REACT_APP_API_BASE_URL}/api/v3/org/splash/orgid=`,
  REACT_APP_API_TESTNOTIFICATION: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendTestNotification/userId=`,
  REACT_APP_API_TESTSMS: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendTestSms/userId=`,
  REACT_APP_API_TESTTEAMSCHAT: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendTestTeamsChat/userId=`,
  REACT_APP_API_TESTCALL: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendTestCall/userId=`,
  REACT_APP_API_TESTMOBILE: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendTestMobile/userId=`,
  REACT_APP_API_DELETEDEVICE: `${REACT_APP_API_BASE_URL}/api/v3/sockets/deletedevice`,
  REACT_APP_API_FLASHACTION: `${REACT_APP_API_BASE_URL}/api/v3/action/flashaction`,
  REACT_APP_API_LOGACTION: `${REACT_APP_API_BASE_URL}/api/v3/sockets/logaction`,
  REACT_APP_API_VERIFYBYSMS: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendVerifySMS/userId=`,
  REACT_APP_API_VERIFYBYCALL: `${REACT_APP_API_BASE_URL}/api/v3/sockets/sendVerifyCall/userId=`,
  REACT_APP_API_VERIFYPHONE: `${REACT_APP_API_BASE_URL}/api/v3/appuser/verifyphone`,
  REACT_APP_API_TRAININGDOCUMENTATION: `${REACT_APP_API_BASE_URL}/api/v3/documentation/orgId=`,
  REACT_APP_API_TUTORIAL: `${REACT_APP_API_BASE_URL}/api/v3/AppUser/tutorialstarted`,
  REACT_APP_API_PATIENTVISIT: `${REACT_APP_API_BASE_URL}/api/v3/patienthistory/`,
  REACT_APP_API_ORGUSERS: `${REACT_APP_API_BASE_URL}/api/v3/patienthistory/users/orgId=`,
  REACT_APP_API_SETUSERSETTINGS: `${REACT_APP_API_BASE_URL}/api/v3/appUser/setUserSettings`,
  REACT_APP_API_GETPATIENTSBYROOMID: `${REACT_APP_API_BASE_URL}/api/v3/patient/roomid=`,
  REACT_APP_API_PUBLISHEDVIEWS: `${REACT_APP_API_BASE_URL}​/api/v3/org/publishedviews`,
  REACT_APP_API_GETDEPARTMENTHISTORY: `${REACT_APP_API_BASE_URL}​/api/v3/fs/history/deptId=`,
  REACT_APP_API_UPDATESERVICELINES: `${REACT_APP_API_BASE_URL}​/api/v3/org/servicelines`,
  REACT_APP_API_ACTION: `${REACT_APP_API_BASE_URL}​/api/v3/action`,
  REACT_APP_API_TRANSFERPATIENTSTOEXAMROOM: `${REACT_APP_API_BASE_URL}​/api/v3/patient/transferpatientstoexamroom`,
  REACT_APP_API_TRANSFERPATIENTSTOWAITINGROOM: `${REACT_APP_API_BASE_URL}​/api/v3/patient/transferpatientstowaitingroom`,
  REACT_APP_API_TRANSFERPATIENTSAVAILABLEROOMS: `${REACT_APP_API_BASE_URL}​/api/v3/patient/availablerooms/siteId=`,
  REACT_APP_API_ACTIONHISTORY: `${REACT_APP_API_BASE_URL}/api/v3/patienthistory/actionId=`,
  REACT_APP_API_PERMISSIONS: `${REACT_APP_API_BASE_URL}/api/v3/permission`,
  REACT_APP_API_EMBEDDEDREPORTS: `${REACT_APP_API_BASE_URL}/api/v3/embeddedreports`,
  REACT_APP_API_USERREPORTVIEWS: `${REACT_APP_API_BASE_URL}/api/v3/userreportviews/userId=`,
  REACT_APP_API_SAVEREPORTVIEWS: `${REACT_APP_API_BASE_URL}/api/v3/userreportviews`,
  REACT_APP_API_INVALIDDEVICES: `${REACT_APP_API_BASE_URL}/api/v3/invaliddevices`,
  REACT_APP_API_SYNCADMINTESTTTS: `${REACT_APP_API_BASE_URL}/api/v3/syncadminoptions/tts`,
  REACT_APP_API_PROVIDERASSIGNMENTS: `${REACT_APP_API_BASE_URL}/api/v3/providerassignment`,
  REACT_APP_API_PROVIDERASSIGNMENTUSERS: `${REACT_APP_API_BASE_URL}/api/v3/providerassignment/allusers/orgId=`,
  REACT_APP_API_PHONEVERIFICATIONCODE: `${REACT_APP_API_BASE_URL}/api/v3/appuser/phoneverifycode/appuserid=`,
  // REACT_APP_API_ACTIVEALERTS: `${REACT_APP_API_BASE_URL}/api/v3/action/activealerts/userid=`,
  REACT_APP_API_ACTIVEALERTS: `${FunctionsEndpoint}/api/v3/action/activealerts/userid=`,
  REACT_APP_API_PATIENTLOBBY: `${REACT_APP_API_BASE_URL}/api/v3/patientlobby`,
  REACT_APP_API_ADDACTIONRESPONSE: `${REACT_APP_API_BASE_URL}/api/v3/action/addactionresponse`,
  REACT_APP_API_CLEARACTIONRESPONSE: `${REACT_APP_API_BASE_URL}/api/v3/action/clearactionresponse`,
  REACT_APP_API_INVALIDEHRPROVIDERIDS: `${REACT_APP_API_BASE_URL}/api/v3/invalidehrproviderid/orgId=`,
  REACT_APP_API_DELETEINVALIDEHRPROVIDERID: `${REACT_APP_API_BASE_URL}/api/v3/invalidehrproviderid`,
  REACT_APP_API_UNLINKEDEHRMAPPING: `${REACT_APP_API_BASE_URL}/api/v3/unlinkedehrmapping`,
  REACT_APP_API_UNLINKEDEHRMAPPINGPROVIDERS: `${REACT_APP_API_BASE_URL}/api/v3/unlinkedehrmapping/providers`,
  REACT_APP_API_BENCHMARKGROUPS: `${REACT_APP_API_BASE_URL}/api/v3/org/benchmarkgroups`,
  REACT_APP_API_UPDATEACTIONCHECKLIST: `${REACT_APP_API_BASE_URL}/api/v3/action/updateactionchecklist`,
  REACT_APP_API_PATIENTSURVEY: `${REACT_APP_API_BASE_URL}/api/v3/patientcontent/patientsurvey`,
  REACT_APP_API_PATIENTEDUCATION: `${REACT_APP_API_BASE_URL}/api/v3/patientcontent/patienteducation`,
  REACT_APP_API_PATIENTEDUCATIONVIDEO: `${REACT_APP_API_BASE_URL}/api/v3/patientcontent/patienteducationvideo`,
  REACT_APP_API_PATIENTCONTENT: `${REACT_APP_API_BASE_URL}/api/v3/patientcontent/orgId=`,
  REACT_APP_API_SURVEY: `${REACT_APP_API_BASE_URL}/api/v3/patientcontent/survey/surveyId=`,
  REACT_APP_API_SUBMITSURVEY: `${REACT_APP_API_BASE_URL}/api/v3/patientcontent/submitsurvey`,
  REACT_APP_API_SUBMITSURVEYRESPONSE: `${REACT_APP_API_BASE_URL}/api/v3/patientcontent/submitsurveyresponse`,
  REACT_APP_API_SURVEYRESPONSE: `${REACT_APP_API_BASE_URL}/api/v3/patienthistory/surveyresponse`,
  REACT_APP_API_INCONSISTENTPERMISSIONS: `${REACT_APP_API_BASE_URL}/api/v3/appuser/inconsistentpermissions/orgId=`,
  REACT_APP_API_FIXINCONSISTENTPERMISSIONS: `${REACT_APP_API_BASE_URL}/api/v3/appuser/fixinconsistentpermissions`,
  REACT_APP_API_ATHENAPRACTICECONFIG: `${REACT_APP_API_BASE_URL}/api/v3/athenapracticeconfig/orgId=`,
  REACT_APP_API_ATHENAPRACTICECONFIGPATIENTLOCATIONS: `${REACT_APP_API_BASE_URL}/api/v3/athenapracticeconfig/patientlocations/practiceid=`,
  REACT_APP_API_ATHENAPRACTICECONFIGDEPT: `${REACT_APP_API_BASE_URL}/api/v3/athenapracticeconfig/dept/practiceId=`,
  REACT_APP_API_ATHENAPRACTICECONFIGPROVIDERS: `${REACT_APP_API_BASE_URL}/api/v3/athenapracticeconfig/providers/practiceId=`,
  REACT_APP_API_ATHENAPRACTICECONFIGROOT: `${REACT_APP_API_BASE_URL}/api/v3/athenapracticeconfig`,
  REACT_APP_API_SWITCHDETAILS: `${REACT_APP_API_BASE_URL}/api/v3/sockets/switchdetails/deviceId=`,
  REACT_APP_API_REFRESHAUTOMATICPORTMAPPINGS: `${REACT_APP_API_BASE_URL}/api/v3/sockets/refreshautomaticportmappings`,
  REACT_APP_API_INTEGRATION: `${REACT_APP_API_BASE_URL}/api/v3/integration`,
  REACT_APP_API_EHRHISTORY: `${REACT_APP_API_BASE_URL}/api/v3/patientHistory/ehrHistory/`,
  REACT_APP_API_REFRESHTOKEN: `${REACT_APP_API_BASE_URL}/token/refresh`,
  REACT_APP_API_MSALSSO: `${REACT_APP_API_BASE_URL}/api/v3/token/msalsso`,
  REACT_APP_API_HUBSPOTCONVO: `${FunctionsEndpoint}/api/v3/hubspot/conversationtoken`,
  REACT_APP_API_EMAILLOCATIONSEXPORT: `${FunctionsEndpoint}/api/v3/emaillocationsexport`,
  REACT_APP_API_LOGSEARCHREQUEST: `${REACT_APP_API_BASE_URL}/api/v3/logsearchrequest`,
  REACT_APP_API_SNOOZEDEVICE: `${REACT_APP_API_BASE_URL}/api/v3/sockets/snoozedevice`,
  REACT_APP_API_UNSNOOZEDEVICE: `${REACT_APP_API_BASE_URL}/api/v3/sockets/unsnoozedevice`,
  REACT_APP_API_CREATECLINICMAP: `${REACT_APP_API_BASE_URL}/api/v3/clinicmap/create`,
  REACT_APP_API_CLINICMAP: `${REACT_APP_API_BASE_URL}/api/v3/clinicmap/orgId=`,
  REACT_APP_API_UPDATECLINICMAP: `${REACT_APP_API_BASE_URL}/api/v3/clinicmap`,
  REACT_APP_API_PATHWAYS: `${REACT_APP_API_BASE_URL}/api/v3/pathways/clinicMapId=`,
  REACT_APP_API_PATHWAYSFILTER: `${REACT_APP_API_BASE_URL}/api/v3/pathways`,
  REACT_APP_API_DELETECLINICMAP: `${REACT_APP_API_BASE_URL}/api/v3/clinicmap/delete`,

  // On demand report
  REACT_APP_API_SENDEMAILREPORT: `${REACT_APP_API_BASE_URL}/api/v3/emailreport/sendreport`,

  // Scheduled reports
  REACT_APP_API_GETSCHEDULEDREPORTS: `${REACT_APP_API_BASE_URL}/api/v3/scheduledreports/user/`,
  REACT_APP_API_CREATEDSCHEDULEDREPORT: `${REACT_APP_API_BASE_URL}/api/v3/scheduledreports`,
  REACT_APP_API_UPDATESCHEDULEDREPORT: `${REACT_APP_API_BASE_URL}/api/v3/scheduledreports/`,
  REACT_APP_API_DELETESCHEDULEDREPORT: `${REACT_APP_API_BASE_URL}/api/v3/scheduledreports/`,
  REACT_APP_API_UNSUBSCRIBESCHEDULEDREPORT: `${REACT_APP_API_BASE_URL}/api/v3/scheduledreports/`,
  REACT_APP_API_GETACTIONFORMFIELDS: `${REACT_APP_API_BASE_URL}/api/v3/emailreport/formfields/ActionUsage/`,

  // Flowstation URL update
  REACT_APP_API_UPDATEFLOWSTATIONURL: `${REACT_APP_API_BASE_URL}/api/v3/sockets/updateflowstationurl`,
};

export {
  // REACT_APP_API_SOCKETURL,
  REACT_APP_API_BASE_URL,
  API,
  MSALSSO,
  FunctionsEndpoint,
};
