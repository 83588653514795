import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';

import { IClinicMap } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

interface TResult {
  clinicMaps: IClinicMap[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IClinicMap[]>;
}

export const useClinicMaps = (orgId: string): TResult => {
  const [clinicMaps, setClinicMaps] = useState<IClinicMap[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchClinicMaps = useCallback((): Promise<IClinicMap[]> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);

      const URL = API.REACT_APP_API_CLINICMAP + orgId;
      let token = localStorage.getItem('token');

      let headers = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Org-UID': 'UID1',
          Pragma: 'no-cache',
        },
      };

      axios
        .get(URL, headers)
        .then(response => {
          console.log('GOT CLINIC MAPS', response);

          setIsLoading(false);
          setClinicMaps([...response.data.data]);
          resolve([...response.data.data]);
        })
        .catch(err => {
          console.log('Error fetching clinic maps', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    });
  }, [orgId]);

  useEffect(() => {
    fetchClinicMaps();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IClinicMap[]> => {
    return fetchClinicMaps();
  }, [fetchClinicMaps]);


  console.log({clinicMaps});


  return {
    clinicMaps,
    isLoading,
    error,
    refetch,
  };
};
