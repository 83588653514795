import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from '@material-ui/core';
import navWinners from '../../../../images/navWinners.png';

interface Props {
  classes: any;
}

const CompetitionCard: React.FC<Props> = ({ classes }) => {
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const updateCountdown = useCallback(() => {
    const targetTime = new Date('September 18, 2024 00:00:00').getTime();
    const currentTime = Date.now();
    const timeBetween = targetTime - currentTime;

    if (timeBetween <= 0) {
      setCountdown({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      });
      return;
    }

    const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeBetween % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeBetween % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeBetween % (1000 * 60)) / 1000);

    setCountdown({
      days: formatValue(days),
      hours: formatValue(hours),
      minutes: formatValue(minutes),
      seconds: formatValue(seconds),
    });
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      updateCountdown();
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [updateCountdown]);

  const formatValue = (value: number) => {
    return value < 10 ? `0${value}` : value.toString();
  };

  return (
    <div>
      <Card className={classes.card}>
        <div style={{ height: '100%' }}>
          <CardContent>
            <Typography gutterBottom variant="h4" component="h2">
              SyncTimes Partner-Wide Competition
            </Typography>
            <CardMedia className={classes.media} image={navWinners} />
            <br />

            <Typography variant="body2" color="textSecondary" component="p">
              SyncTimes competition has started and will end on 
              <strong> Sep 18th!</strong>
            </Typography>

            <div
              style={{ textAlign: 'center', marginTop: 10, marginBottom: 5 }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ fontSize: 28, fontWeight: 'bold' }}
              >
                {countdown.days} : {countdown.hours} : {countdown.minutes} :{' '}
                {countdown.seconds}
              </Typography>
            </div>

            <Button
              size="small"
              variant="contained"
              className={classes.reportingButton}
              aria-haspopup="true"
              onClick={() =>
                window.open(
                  'https://www.synctimes.com/partner-competition',
                  '_blank'
                )
              }
            >
              Learn More
            </Button>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

export default CompetitionCard;

// This seems to have been used in the past...

/* <Card className={classes.card}>
                    <div style={{ height: "100%" }}>
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="h2">
                          2023 Illuminate Summit
                        </Typography>
                        <CardMedia
                          className={classes.media}
                          // image={eventsImage}
                          image={navSummitImage}
                        />
                        <br />
                        <Typography variant="body2" color="textSecondary" component="p">
                          <strong>You're Invited!</strong>
                        </Typography>
                        <br />
                        <Typography variant="body2" color="textSecondary" component="p">
                          Join us for the 2023 Illuminate Summit September 28-30 Provo, UT
                        </Typography>
                        <br />

                        <Button
                          size="small"
                          variant="contained"
                          className={classes.reportingButton}
                          aria-haspopup="true"
                          // href="https://www.synctimes.com/summit"
                          onClick={() => window.open("https://www.synctimes.com/summit", "_blank")}
                        >
                          Learn More
                        </Button>
                      </CardContent>
                    </div>
                  </Card> */
