import { uniqBy } from 'lodash';
import { IApp, IDevice } from '../../../../interfaces/interfaces';

export const getAppGroups = (input: {
  deviceList: IDevice[];
  apps: IApp[];
}): {
  allInstalledApps: IApp[];
  allWatchApps: IApp[];
} => {
  const allInstalledApps: IApp[] = [];
  const allWatchApps: IApp[] = [];

  const { deviceList = [], apps = [] } = input;

  for (const device of deviceList) {
    if (device.mongoLastLoggedAppId) {
      for (const app of apps) {
        if (app.appVersion === device.mongoLastLoggedAppId) {
          allInstalledApps.push(app);
        }

        if (app.appVersion === device.mongoLastLoggedWatchdogAppId) {
          allWatchApps.push(app);
        }
      }
    }
  }

  return {
    allInstalledApps: uniqBy(allInstalledApps, 'appVersion'),
    allWatchApps: uniqBy(allWatchApps, 'appVersion'),
  };
};
