import { groupBy, some } from 'lodash';
import {
  IDepartment,
  IPatientContent,
  IPatientContentOption,
  ISite,
} from '../../interfaces/interfaces';
import { WAITING_ROOM_ENGLISH, WAITING_ROOM_SPANISH } from './constants';
import { getLobbySettings } from './lobbyDB';
import { IProviderWaitingRoomItem, TLobbySequence } from './types';

export const getDefaultValues = () => {};

export const checkIfIsWaitingRoom = (lobbySequence: TLobbySequence) => {
  return (
    lobbySequence.patientEducationToShow === WAITING_ROOM_ENGLISH ||
    lobbySequence.patientEducationToShow === WAITING_ROOM_SPANISH
  );
};

export const getPatientContentOptions = (
  patientContentList: IPatientContent[] = []
): IPatientContentOption[] => {
  const patientContentOptions = patientContentList
    .filter(
      patientContent =>
        (patientContent.isActive &&
          patientContent.patientContentType === 'Education') ||
        patientContent.patientContentType === 'Education Video'
    )
    .map(patientContent => ({
      label: patientContent.patientContentName,
      value: patientContent.id,
      patientContent,
    }));

  return [
    { label: '', value: null, patientContent: null },
    {
      label: WAITING_ROOM_ENGLISH,
      value: WAITING_ROOM_ENGLISH,
      patientContent: null,
    },
    {
      label: WAITING_ROOM_SPANISH,
      value: WAITING_ROOM_SPANISH,
      patientContent: null,
    },
    ...patientContentOptions,
  ];
};

export const buildFrames = (input: {
  orgId: string;
  lobbySequences: TLobbySequence[];
  patientContentList: IPatientContent[];
}): TLobbySequence[] => {
  const { orgId, lobbySequences, patientContentList } = input;

  const lobbySettings = getLobbySettings(orgId);

  const results: TLobbySequence[] = [];

  for (const lobbySequence of lobbySequences) {
    const patientContent = patientContentList.find(
      patientContent =>
        patientContent.id === lobbySequence.patientEducationToShow
    );

    const isWaitingRoom = checkIfIsWaitingRoom(lobbySequence);

    if (patientContent) {
      results.push({
        isVideo: patientContent?.patientEducationUrl?.includes('.mp4'),
        ...lobbySequence,
        patientContentUrl: patientContent.patientEducationUrl,
        isWaitingRoom: false,
      });
    }

    if (isWaitingRoom) {
      results.push({
        ...lobbySequence,
        patientContentUrl: null,
        isWaitingRoom: true,
        isVideo: false,
        language:
          lobbySequence.patientEducationToShow === WAITING_ROOM_ENGLISH
            ? 'English'
            : 'Spanish',
        departmentIds: lobbySettings?.departmentsToShow,
      });
    }
  }

  return results;
};

export const getTimeZone = (sites: ISite[]): string => {
  let timeZone = 'America/Denver';
  // console.log("SITE", this.props.sites);
  if (sites[0]) {
    let firstSite: ISite = null;

    sites.forEach(site => {
      if (!firstSite) {
        if (site.isActive) {
          firstSite = site;
        }
      }
    });

    if (firstSite) {
      switch (firstSite.timeZoneId) {
        case 3:
          timeZone = 'Pacific/Honolulu';
          break;
        case 4:
          timeZone = 'America/Anchorage';
          break;
        case 6:
          timeZone = 'America/Los_Angeles';
          break;
        case 7:
          timeZone = 'America/Phoenix';
          break;
        case 9:
          timeZone = 'America/Denver';
          break;
        case 10:
          timeZone = 'America/Chicago';
          break;
        case 15:
          timeZone = 'America/New_York';
          break;
        case 16:
          timeZone = 'America/Puerto_Rico';
          break;
        default:
          timeZone = 'America/Denver';
      }
    }
  }

  return timeZone;
};

export const buildProviderPatientItems = (input: {
  departmentIds: string[];
  departments: IDepartment[];
}): IProviderWaitingRoomItem[] => {
  const { departments, departmentIds } = input;

  let providerPatientsWaiting: any[] = [];

  // console.log("1", this.state.departmentsToShow);
  // console.log("2", this.props.lobbyDepartments);
  departments.forEach(dept => {
    if (departmentIds.includes(dept.id)) {
      // console.log("got it", dept);

      dept.providerPatientsWaiting.forEach(provider => {
        providerPatientsWaiting.push({ ...provider, deptId: dept.id });
      });
    }
  });

  let groupedProviders = groupBy(providerPatientsWaiting, 'providerId');

  let conjoinedProviderPatientsWaiting: IProviderWaitingRoomItem[] = [];

  // console.log("here", providerPatientsWaiting);

  providerPatientsWaiting.forEach(provider => {
    if (
      some(conjoinedProviderPatientsWaiting, {
        providerId: provider.providerId,
      })
    ) {
    } else {
      // console.log("FOUND", groupedProviders[provider.providerId]);

      let patientsWaiting = 0;
      let patients: any[] = [];
      groupedProviders[provider.providerId].forEach(provider => {
        patientsWaiting = patientsWaiting + provider.patients.length;
        // patients.push(provider.patients);
        provider.patients.forEach((patient: any) => {
          patients.push(patient);
        });
      });

      let newProviderData = {
        ...provider,
        patientsWaiting: patientsWaiting,
        patients: patients,
        // patientsWaiting: groupedProviders[provider.providerId].length,
        // patientsWaiting: groupedProviders[provider.providerId].patients.length,
      };
      conjoinedProviderPatientsWaiting.push(newProviderData);
      // console.log("Count", groupedProviders[provider.providerId].length);
    }
  });

  return conjoinedProviderPatientsWaiting;
};
