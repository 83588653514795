import { FC, useRef } from 'react';

import { ISite } from '../../../interfaces/interfaces';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { useSites } from '../../../hooks/useSites';

type TSiteOption = {
  label: string;
  value: string;
  site: ISite;
};

type TProps = {
  orgId: string;
  onSelect?: (siteOption: TSiteOption) => void;
  selectedSite: ISite;
  name: string;
  control: any;
  errors: any;
  errorMessage?: string;
};

const SiteField: FC<TProps> = props => {
  const { orgId, onSelect = null, name, control, errors, errorMessage } = props;

  const { sites = [] } = useSites(orgId);

  const inputRef = useRef<HTMLInputElement>(null);

  const activeSites = sites.filter((site: ISite) => {
    return site.isActive;
  });

  const siteOptions: TSiteOption[] = activeSites.map(site => {
    return {
      label: site.siteName,
      value: site.siteName,
      site,
    };
  });

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            id="siteField"
            autoHighlight
            options={siteOptions}
            getOptionLabel={(site: TSiteOption) => site.label}
            onChange={(event: any, siteOption: any) => {
              if (onSelect) {
                onSelect(siteOption);
              } else {
                field.onChange(siteOption?.site);
              }
            }}
            renderInput={params => (
              <TextField
                value={field.value}
                {...params}
                autoFocus
                ref={inputRef}
                label="Select Site"
              />
            )}
          />
        )}
      />

      {errors.isActive && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </>
  );
};

export default SiteField;
