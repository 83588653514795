import { createTheme, makeStyles, Theme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#A7D6A4',
    },
  },
});

const styles: any = (theme: Theme) => ({
  actionButton: {
    cursor: 'pointer',
    fontSize: 16,
  },
  autoMargin: {
    margin: 'auto',
  },
  confirmText: {
    fontSize: '1.5em',
    textAlign: 'center',
  },
  executeButton: {
    border: '1px solid red',
    color: 'red',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  alertStyle: {
    marginBottom: '1rem',
    backgroundColor: '#FFFBEB',
    borderColor: '#FEF3C7',
  },

  alertTitleStyle: {
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '0.5rem',
  },

  messageStyle: {
    marginBottom: '1rem',
  },

  buttonContainerStyle: {
    display: 'flex',
    gap: '1rem',
  },

  cancelButtonStyle: {
    border: '1px solid #D1D5DB',
    color: '#374151',
  },

  installButtonStyle: {
    backgroundColor: '#F59E0B',
    color: 'white',
    border: '1px solid #000',
  },

  iconStyle: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
  },
});

export const useStyles = makeStyles(styles);
