import axios from 'axios';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { API } from '../../../../apiconfig';

import { canDo } from '../../../../utils/permissionCheck';
import { checkRefreshToken } from '../../../../utils/utils';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import Resizer from 'react-image-file-resizer';

import 'cropperjs/dist/cropper.css';
import xlsx from 'json-as-xlsx';
import Cropper, { ReactCropperElement } from 'react-cropper';

import Notifications from '../../../flowstation/components/notification/Notifications';

import ErrorSnack from '../../../../utils/ErrorSnack';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';
import WarningSnack from '../../../../utils/WarningSnack';

// Material
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import CheckIcon from '@material-ui/icons/Check';
import CloseButton from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpIcon from '@material-ui/icons/HelpOutline';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ImportIcon from '@material-ui/icons/Publish';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

import { Importer, ImporterField } from 'react-csv-importer';
//@ts-ignore
// import { CSVLink } from "react-csv";

// include the widget CSS file whichever way your bundler supports it
import _ from 'lodash';
import 'react-csv-importer/dist/index.css';

import { useInstruments } from '../../../../hooks/useInstruments';
import { useOrg } from '../../../../hooks/useOrg';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import { useUsers } from '../../../../hooks/useUsers';
import {
  IBadge,
  IDepartment,
  IPermission,
  IServiceSubGroup,
  IUser,
  TAdminState,
} from '../../../../interfaces/interfaces';
import Beacons from './Beacons';
import { userTemplate, USERS_TUTORIAL_STEPS } from './constants';
import { theme, useStyles } from './styles';
import { filterUsers } from './utils';

const filter = createFilterOptions();

var uniqid: any = require('uniqid');

// Tooltip Modifications

type TProps = {};

const Users: FC<TProps> = props => {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const [nextFocusObject, setNextFocusObject] = useState<any>({});
  const [beaconNumberObject, setBeaconNumberObject] = useState<any>({});
  const [emailSentSuccess, setEmailSentSuccess] = useState<boolean>(false);
  const [missingEhrProviderIdsModalOpen, setMissingEhrProviderIdsModalOpen] =
    useState<boolean>(false);
  const [missingEhrProviderIds, setMissingEhrProviderIds] = useState<string[]>(
    []
  );
  const [newEhrProviderId, setNewEhrProviderId] = useState<string>('');
  const [showNewUserSaveWarning, setShowNewUserSaveWarning] =
    useState<boolean>(false);
  const [newUserModalOpen, setNewUserModalOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hideInactiveUsers, setHideInactiveUsers] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('firstName');
  const [sortDescending, setSortDescending] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');
  const [badgeString, setBadgeString] = useState<string>('');
  const [hasModified, setHasModified] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [newUser, setNewUser] = useState<boolean>(false);
  const [duplicateBadgeError, setDuplicateBadgeError] =
    useState<boolean>(false);
  const [userImageError, setUserImageError] = useState<boolean>(false);
  const [displayBadgeExpiration, setDisplayBadgeExpiration] =
    useState<boolean>(false);
  const [hideEditImageButton, setHideEditImageButton] =
    useState<boolean>(false);
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [userEmailError, setUserEmailError] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [importReportDialog, setImportReportDialog] = useState<boolean>(false);
  const [missingServiceLines, setMissingServiceLines] = useState<string[]>([]);
  const [missingSubGroups, setMissingSubGroups] = useState<string[]>([]);
  const [latestFails, setLatestFails] = useState<number>(0);
  const [latestSuccesses, setLatestSuccesses] = useState<number>(0);
  const [domainError, setDomainError] = useState<boolean>(false);

  const [run, setRun] = useState<boolean>(false);
  const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
  const [restrictedBadgeNumber, setRestrictedBadgeNumber] =
    useState<boolean>(false);
  const [newUserImportModalOpen, setNewUserImportModalOpen] =
    useState<boolean>(false);
  const [importedUsers, setImportedUsers] = useState<any[]>([]);
  const [completedCSVImport, setCompletedCSVImport] = useState<boolean>(false);
  const [showDuplicateUserBadgeError, setShowDuplicateUserBadgeError] =
    useState<boolean>(false);
  const [userWithDuplicateBadge, setUserWithDuplicateBadge] =
    useState<any>(null);
  const [beginSaveProcess, setBeginSaveProcess] = useState<boolean>(false);
  const [userToEdit, setUserToEdit] = useState<IUser>(null);
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [imageEditMode, setImageEditMode] = useState<boolean>(false);
  const [newBadgeExpiration, setNewBadgeExpiration] = useState<string | null>(
    null
  );
  const [, setNewBadge] = useState<string>('');
  const [newUserErrorMessage, setNewUserErrorMessage] = useState<string>('');
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const [badgeWarning, setBadgeWarning] = useState<boolean>(false);
  const [, setUserEmail] = useState<boolean>(false);
  const [userHasBeenCreated, setUserHasBeenCreated] = useState<boolean>(false);
  const [, setOverwriteBadge] = useState<boolean>(false);
  // const [, setStepIndex] = useState<number | null>(null);
  const [userTutorialStepIndex, setUserTutorialStepIndex] = useState<number>(0);
  // const [, setComponentToView] = useState<string>('');
  // const [, setOpen] = useState<boolean>(false);
  const [passwordResetResponse, setPasswordResetResponse] = useState<any>(null);
  const [
    subGroupStarterPermissionsDialog,
    setSubGroupStarterPermissionsDialog,
  ] = useState<boolean>(false);
  const [subGroupStarterPermissions, setSubGroupStarterPermissions] = useState<
    any[]
  >([]);
  const [serviceLineKey, setServiceLineKey] = useState<string>(uniqid());
  const [serviceLineSubGroupKey, setServiceLineSubGroupKey] = useState<string>(
    uniqid()
  );
  const [confirmationDetails, setConfirmationDetails] = useState<any>(null);
  const [newUserDetails, setNewUserDetails] = useState<any>(null);
  const [showUserNotifications, setShowUserNotifications] =
    useState<boolean>(false);
  const [orgDepartments, setOrgDepartments] = useState<any[]>([]);
  const [departmentSearchKey, setDepartmentSearchKey] = useState<number>(0);

  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const cropperRef = useRef<ReactCropperElement>(null);

  const { organization } = useOrg(loggedInUserData.mongoOrganizationId);
  const { permissions } = usePermissions();
  const { instruments } = useInstruments(loggedInUserData.mongoOrganizationId);
  const { users, refetch: refetchUsers } = useUsers(
    loggedInUserData.mongoOrganizationId
  );
  // const { fetchUser } = useLoggedInUser();

  const classes = useStyles();

  useEffect(() => {
    const cropper = cropperRef.current?.cropper;
    return () => {
      // Destroy Cropper on component unmount
      if (cropper) {
        cropper.destroy();
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('paste', (event: any) => {
      console.log('Global paste triggered', event.clipboardData.files);
      if (!imageEditMode) {
        if (right || newUserModalOpen) {
          if (event.clipboardData.files[0]) {
            console.log('pasting image', event.clipboardData.files[0]);

            let mountImage = (smallImage: any) => {
              setUploadedFile(smallImage);
              setImageEditMode(true);
            };

            //===============
            let fileInput = false;
            if (event.clipboardData.files[0]) {
              fileInput = true;
            }

            if (fileInput) {
              Resizer.imageFileResizer(
                event.clipboardData.files[0],
                300,
                300,
                'JPEG',
                100,
                0,
                (uri: any) => {
                  // console.log("uri and file size", uri);
                  // Resized it, now set state
                  mountImage(uri);
                },
                'base64'
              );
            }
          } else {
            console.log('No file found to paste');
          }
        } else {
          console.log('USER DRAWER NOT OPEN');
        }
      }
    });
  }, [imageEditMode, newUserModalOpen, right]);

  const handleNext = () => {
    // console.log("Current step", activeStep);

    let nextStep = () => {
      setActiveStep(activeStep + 1);
    };

    // Check User Image Step
    if (activeStep === 0) {
      // if (!userToEdit.userImage && userToEdit.userImageUrl < 1) {
      //   this.setState({
      //     fieldError: true,
      //     userImageError: true,
      //   });
      // } else {
      //   this.setState({
      //     fieldError: false,
      //     userImageError: false,
      //   });
      //   nextStep();
      // }
      nextStep();
    }

    // Check User Info Step
    if (activeStep === 1) {
      let fieldError = false;
      let firstNameError = false;
      let lastNameError = false;
      let userEmailError = false;

      if (userToEdit?.firstName?.length < 1) {
        fieldError = true;
        firstNameError = true;
      }
      if (userToEdit?.lastName?.length < 1) {
        fieldError = true;
        lastNameError = true;
      }
      if (userToEdit?.userEmail?.length < 1) {
        fieldError = true;
        userEmailError = true;
      }

      if (fieldError) {
        setFieldError(true);
        setFirstNameError(firstNameError);
        setLastNameError(lastNameError);
        setUserEmailError(userEmailError);
      } else {
        setFieldError(false);
        setFirstNameError(firstNameError);
        setLastNameError(lastNameError);
        setUserEmailError(userEmailError);

        nextStep();
      }
    }

    // Check User Type and Settings Step
    if (activeStep === 2) {
      // let npiError = false;

      // if (npiError) {
      //   this.setState({
      //     npiError: true,
      //   });
      // } else {
      //   this.setState({
      //     npiError: false,
      //   });
      //   nextStep();
      // }
      nextStep();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onNewUserModal = () => {
    setImportedUsers([]);
    setNewUserImportModalOpen(true);
    setCompletedCSVImport(true);

    onAddRow();
  };

  const onEmailStaffPatientReport = () => {
    let token = localStorage.getItem('token');

    let payload = {
      userId: loggedInUserData.id,
    };

    let URL = API.REACT_APP_API_EMAILLOCATIONSEXPORT;

    setEmailSentSuccess(true);

    setTimeout(() => setEmailSentSuccess(false), 3000);

    fetch(URL, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('response', response);
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const newUserModalMobile = () => {
    // console.log("PERMISSIONS", this.props.permissions);

    let defaultPermissions: any = [];
    permissions.forEach((permission: any) => {
      let p = permission;
      if (
        p.permissionName === 'View Flowstations' ||
        p.permissionName === 'Update Flowstation' ||
        p.permissionName === 'Publish Views'
      ) {
        defaultPermissions.push(p);
      }
    });

    // console.log("default perms", defaultPermissions);

    setActiveStep(0);
    setNewUserModalOpen(true);
    setNewUser(true);
    setUploadedFile(null);
    setImageEditMode(false);
    setNewBadgeExpiration(null);
    setUserToEdit({
      ...userTemplate,
      appUserBadges: [],
      userPermissions: defaultPermissions,
    } as any);
  };

  const closeMissingEhrProviderIds = () => {
    setMissingEhrProviderIdsModalOpen(false);
  };

  const deleteMissingEhrProviderId = (data: any) => {
    // console.log("Delete", data);
    let token = localStorage.getItem('token');

    axios({
      method: 'DELETE',
      url: API.REACT_APP_API_DELETEINVALIDEHRPROVIDERID,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
        Pragma: 'no-cache',
      },
    })
      .then(response => {
        console.log('DELETED MISSING EHR PROVIDER ID', response.data.data);
        openMissingEhrProviderIdsModal();
      })
      .catch(err => {
        console.log('Err deleting missing ehr provider id', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const openMissingEhrProviderIdsModal = () => {
    console.log('OPENING');
    let URL = API.REACT_APP_API_INVALIDEHRPROVIDERIDS + organization?.id;
    let token = localStorage.getItem('token');

    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    // console.log("lets get", orgId);
    axios
      .get(URL, headers)
      .then(response => {
        console.log('res', response.data.data);
        if (response.data.data) {
          setMissingEhrProviderIds(response.data.data);
        }
      })
      .catch(err => {
        console.log('Err fetching invalid ehr provider ids');
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    setMissingEhrProviderIdsModalOpen(true);
  };

  const newUserImport = () => {
    setNewUserImportModalOpen(true);
    setCompletedCSVImport(false);
  };

  const onCloseNewUserImport = (forceClose: boolean) => {
    console.log('check', importedUsers);

    let onClose = () => {
      setNewUserImportModalOpen(false);
      setCompletedCSVImport(false);
      setImportedUsers([]);
      setMissingServiceLines([]);
      setMissingSubGroups([]);
      setShowNewUserSaveWarning(false);
      setRestrictedBadgeNumber(false);
    };

    if (forceClose) {
      onClose();
    } else {
      let showWarning = false;
      importedUsers.forEach(user => {
        if (user.importSuccess === false) {
          // show warning
          showWarning = true;
          setShowNewUserSaveWarning(true);
        }
      });
      if (!showWarning) {
        onClose();
      }
    }

    refetchUsers();
  };

  const closeWithoutSaving = (user: IUser) => {
    setShowSaveAlert(false);
    setLoading(false);
    setRight(false);
    setNewUser(false);
    setUserToEdit(user);
    setUploadedFile(null);
    setImageEditMode(false);
    setNewBadgeExpiration(null);
    setFieldError(false);
    setDuplicateBadgeError(false);
    setUserImageError(false);
    setNewBadge('');
    setNewUserErrorMessage('');
    setHasModified(false);
    setPasswordReset(false);
    setDisplayBadgeExpiration(false);
    setFirstNameError(false);
    setLastNameError(false);
    setUserEmailError(false);
    setInvalidEmail(false);
    setHideEditImageButton(false);
    setBeaconNumberObject({
      ...beaconNumberObject,
      beacon1: '',
      beacon2: '',
      beacon3: '',
      beacon4: '',
    });

    setRestrictedBadgeNumber(false);

    refetchUsers();
  };

  const toggleDrawer = (open: boolean, user: IUser | null) => {
    
    
    
    console.log({hasModified});
    
    if (
      canDo(
        ['Update Users', 'Update Badges', 'Update Photos'],
        loggedInUserData
      )
    ) {
      if (hasModified) {
        console.log('show alert dialog');
        setShowSaveAlert(true);
      } else {
        // console.log("selected user", user);

        let URL = API.REACT_APP_API_GETORGDEPARTMENT + user.mongoOrganizationId;
        // console.log("URL", URL);
        let token = localStorage.getItem('token');
        let headers = {
          headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
        };

        axios
          .get(URL, headers)
          .then(response => {
            let resDepartments = response.data.data;

            // filter orgDepartments to only isActive
            resDepartments = resDepartments.filter((dept: IDepartment) => {
              return dept.isActive;
            });

            console.log('Filtered', resDepartments);
            setOrgDepartments(resDepartments);
          })
          .catch(err => {
            console.log('Err fetching org departments');
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });

        // if (user.lastAssignedDeptId) {
        //   let URL = API.REACT_APP_API_GETDEPARTMENT + user.lastAssignedDeptId;
        //   let token = localStorage.getItem("token");

        //   let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

        //   // console.log("lets get", orgId);
        //   axios
        //     .get(URL, headers)
        //     .then((response) => {
        //       console.log("user dept response", response.data.data);
        //       if (response.data.data) {
        //         this.setState({
        //           userToEditDepartment: response.data.data,
        //         });
        //       }
        //     })
        //     .catch((err) => {
        //       console.log("Err fetching user department");
        //     });
        // }

        if (open === false) {
          console.log('clear userToEditDepartment');
          setOrgDepartments([]);
        }

        setLoading(false);
        setRight(open);
        setNewUser(false);
        setUserToEdit(user);
        setUploadedFile(null);
        setImageEditMode(false);
        setNewBadgeExpiration(null);
        setFieldError(false);
        setDuplicateBadgeError(false);
        setUserImageError(false);
        setNewBadge('');
        setNewUserErrorMessage('');
        setHasModified(false);
        setPasswordReset(false);
        setDisplayBadgeExpiration(false);
        setFirstNameError(false);
        setLastNameError(false);
        setUserEmailError(false);
        setInvalidEmail(false);
        setHideEditImageButton(false);
        setBeaconNumberObject({
          ...beaconNumberObject,
          beacon1: '',
          beacon2: '',
          beacon3: '',
          beacon4: '',
        });
        setConfirmationDetails(null);
        setNewEhrProviderId('');
        setRestrictedBadgeNumber(false);
      }

      refetchUsers();
    }
  };

  // const onNewUser = () => {
  //   setRight(true);
  //   setNewUser(true);
  //   setUserToEdit(userTemplate);
  //   setUploadedFile(null);
  //   setImageEditMode(false);
  //   setNewBadgeExpiration(null);
  // };

  const onCloseNewUserModal = () => {
    setNewUserModalOpen(false);
    setLoading(false);
    setNewUser(false);
    setUploadedFile(null);
    setImageEditMode(false);

    setNewBadgeExpiration(null);
    setFieldError(false);
    setDuplicateBadgeError(false);
    setUserImageError(false);
    setNewBadge('');
    setNewUserErrorMessage('');
    setHasModified(false);
    setPasswordReset(false);
    setDisplayBadgeExpiration(false);
    setFirstNameError(false);
    setLastNameError(false);
    setUserEmailError(false);
    setInvalidEmail(false);
    setBadgeWarning(false);
    setBeaconNumberObject({
      ...beaconNumberObject,
      beacon1: '',
      beacon2: '',
      beacon3: '',
      beacon4: '',
    });
  };

  const deleteImportedUser = (importId: string) => {
    let users = importedUsers;
    let modifiedUsers = _.filter(users, (user: { importId: string }) => {
      return user.importId !== importId;
    });

    console.log('delete', modifiedUsers);

    setImportedUsers(modifiedUsers);
  };

  const handleImportedUser = (input: {
    name: string;
    importId: string;
    value: string;
  }) => {
    const { name, value = '', importId } = input;

    let newValue: any = value;

    if (name === 'userTypeId') {
      newValue = Number(newValue);
    }

    let users = importedUsers;

    let objIndex = users.findIndex(obj => obj.importId === importId);
    // console.log("Found user to modify", users[objIndex]);

    users[objIndex] = {
      ...users[objIndex],
      [name]: newValue,
    };

    setImportedUsers([...users]);
  };

  const selectImportedUserServiceLine = (value: any, importId: string) => {
    console.log('value', value);

    let foundUser = false;
    let users = importedUsers;

    let objIndex = users.findIndex(obj => obj.importId === importId);
    if (users[objIndex]) {
      foundUser = true;
    }

    if (typeof value === 'string') {
      (organization?.serviceLines ?? []).forEach(serviceLine => {
        if (serviceLine.name === value) {
          console.log('FOUND IT');
          value = { name: value };
        }
      });
    }

    if (value) {
      console.log('Value recieved', value);
      if (value.name) {
        // Service line exists, update user
        console.log('Service line exists');
        console.log('Found user to modify', users[objIndex]);
        (organization?.serviceLines ?? []).forEach(serviceLine => {
          if (serviceLine.name === value.name && foundUser) {
            users[objIndex] = {
              ...users[objIndex],
              serviceLine: { id: serviceLine.id, name: serviceLine.name },
              serviceLineSubGroup: null,
            };
          }
        });

        setImportedUsers(users);
      } else {
        console.log('clear it');
        users[objIndex] = {
          ...users[objIndex],
          serviceLine: null,
          serviceLineSubGroup: null,
        };

        setServiceLineKey(uniqid());
        setImportedUsers(users);
      }
    } else {
      // Clear the service line
      users[objIndex] = {
        ...users[objIndex],
        serviceLine: null,
        serviceLineSubGroup: null,
      };

      setImportedUsers(users);
    }
  };

  // const selectImportedUserServiceLineSubGroup = (e: any, importId: string) => {
  //   // console.log("selected", subGroups);
  //   let foundUser = false;
  //   let users = importedUsers;

  //   let objIndex = users.findIndex(obj => obj.importId === importId);
  //   if (users[objIndex]) {
  //     foundUser = true;
  //   }

  //   (organization?.serviceLines ?? []).forEach(serviceLine => {
  //     serviceLine.serviceLineSubGroups.forEach((subGroup: IServiceSubGroup) => {
  //       if (e.target.value === subGroup.id && foundUser) {
  //         users[objIndex] = {
  //           ...users[objIndex],
  //           serviceLineSubGroup: subGroup,
  //         };
  //       }
  //     });
  //   });

  //   setImportedUsers(users);
  // };

  const autoCompleteSelectImportedUserServiceLineSubGroup = (
    value: any,
    importId: string
  ) => {
    let foundUser = false;
    let users = importedUsers;

    let objIndex = users.findIndex(obj => obj.importId === importId);
    if (users[objIndex]) {
      foundUser = true;
    }

    if (value) {
      // Subgroup doesn't exist yet, make request to create it

      if (typeof value === 'string') {
        (organization?.serviceLines ?? []).forEach(serviceLine => {
          serviceLine.serviceLineSubGroups.forEach((subGroup: any) => {
            if (value === subGroup.name) {
              value = { name: value };
            }
          });
        });
      }

      if (value.name) {
        (organization?.serviceLines ?? []).forEach(serviceLine => {
          serviceLine.serviceLineSubGroups.forEach((subGroup: any) => {
            if (value.name === subGroup.name && foundUser) {
              console.log(
                'Subgroup exists, update user serviceLineSubGroup',
                subGroup
              );
              users[objIndex] = {
                ...users[objIndex],
                serviceLineSubGroup: subGroup,
                userPermissions: subGroup.starterPermissions,
              };
            }
          });
        });

        setImportedUsers(users);
      } else {
        users[objIndex] = {
          ...users[objIndex],
          serviceLineSubGroup: null,
          userPermissions: [],
        };

        setImportedUsers(users);
        setServiceLineSubGroupKey(uniqid());
      }
    } else {
      // Clear the sub group
      console.log('clear it');
      users[objIndex] = {
        ...users[objIndex],
        serviceLineSubGroup: null,
        userPermissions: [],
      };
      setImportedUsers(users);
    }
  };

  const onAddRow = () => {
    let users = importedUsers;

    users.push({
      importId: uniqid(),
      firstName: '',
      lastName: '',
      userEmail: '',
      // isAdmin: false,
      userTypeId: 1,
      importSuccess: false,
      isActive: true,
      appUserBadges: [],
      fieldError: false,
      newUserErrorMessage: '',
      // npi: null,
      emailError: false,
      firstNameError: false,
      lastNameError: false,
    });

    setImportedUsers([...users]);
  };

  const onImportUsers = () => {
    let defaultPermissions: any = [];
    permissions.forEach((permission: IPermission) => {
      let p = permission;
      if (
        p.permissionName === 'View Flowstations' ||
        p.permissionName === 'Update Flowstation' ||
        p.permissionName === 'Publish Views'
      ) {
        defaultPermissions.push(p);
      }
    });

    // console.log('IMPORT USERS')

    let token = localStorage.getItem('token');

    let users = importedUsers;

    let latestSuccesses = 0;
    let latestFails = 0;

    users.forEach(user => {
      if (!user.importSuccess) {
        // make post req

        let modifiedUser = {
          ...user,
          mongoOrganizationId: loggedInUserData.mongoOrganizationId,
        };

        // console.log('modified user', modifiedUser)

        function validateEmail(email: string) {
          var re =
            /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        }

        let firstNameError = false;
        let lastNameError = false;
        let userEmailError = false;
        let domainError = false;

        if (modifiedUser.firstName.length < 1) {
          // firstname length error
          firstNameError = true;
        }

        if (modifiedUser.lastName.length < 1) {
          // lastname length error
          lastNameError = true;
        }

        if (!validateEmail(modifiedUser.userEmail)) {
          userEmailError = true;
        }

        if (modifiedUser.userEmail) {
          let domain = modifiedUser.userEmail.substring(
            modifiedUser.userEmail.lastIndexOf('@') + 1
          );
          if (domain && (organization?.allowedEmailDomains ?? [])?.length > 0) {
            if (organization?.allowedEmailDomains.includes(domain)) {
              // Domain allowed.
            } else {
              // Domain not allowed
              domainError = true;
            }
          }
        }

        if (!modifiedUser.serviceLineSubGroup) {
          // Set default perms if service line sub group doesn't exist
          modifiedUser.userPermissions = defaultPermissions;
        }
        console.log('user', modifiedUser);

        // Temporary set npi to first array of ehrProviderIds
        modifiedUser.ehrProviderIds = [modifiedUser.npi];
        modifiedUser.npi = null;
        // ================================================

        let newUser = JSON.stringify(modifiedUser);

        if (firstNameError || lastNameError || userEmailError || domainError) {
          latestFails++;

          let objIndex = users.findIndex(obj => obj.importId === user.importId);
          users[objIndex] = {
            ...users[objIndex],
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            emailError: userEmailError,
            domainError: domainError,
          };

          setImportedUsers(users);
          setLatestSuccesses(latestSuccesses);
          setLatestFails(latestFails);
        } else {
          console.log('SEND REQUEST', newUser);
          fetch(API.REACT_APP_API_UPDATEUSER, {
            method: 'POST',
            body: newUser,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              return res.json();
            })
            .then(data => {
              // console.log(data.data);
              if (data.data === 'userEmailExists') {
                latestFails++;
                // update user state with error info
                let objIndex = users.findIndex(
                  obj => obj.importId === user.importId
                );
                users[objIndex] = {
                  ...users[objIndex],
                  emailError: true,
                  importSuccess: false,
                };

                setImportedUsers(users);
                setLatestSuccesses(latestSuccesses);
                setLatestFails(latestFails);
              } else {
                latestSuccesses++;
                console.log('Import Response', data);
                let objIndex = users.findIndex(
                  obj => obj.importId === user.importId
                );
                users[objIndex] = {
                  ...users[objIndex],
                  emailError: false,
                  importSuccess: true,
                };

                setImportedUsers(users);
                setLatestSuccesses(latestSuccesses);
                setLatestFails(latestFails);
              }
            })
            .catch(err => {
              console.log('ERR!!!', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      }

      setImportReportDialog(true);
    });

    // loop through users to import
    // make request
    // if error, modifiy imported users state with error
  };

  const handleChange = (input: { name: string; value: string }) => {
    const { name, value } = input;

    let newValue: any = value;
    if (name === 'userTypeId') {
      newValue = Number(value);
    }

    setHasModified(true);
    setUserToEdit({
      ...userToEdit,
      [name]: newValue,
    });
  };

  const handleNewEhrProviderId = (event: any) => {
    console.log('EVENT', event.target.value);

    setNewEhrProviderId(event.target.value);
  };

  const addNewEhrProviderId = () => {
    setUserToEdit({
      ...userToEdit,
      ehrProviderIds: [...userToEdit.ehrProviderIds, newEhrProviderId],
    });

    setNewEhrProviderId('');
  };

  const deleteEhrProviderId = (idToDelete: string) => {
    let existingEhrProviderIds = userToEdit.ehrProviderIds;

    existingEhrProviderIds = existingEhrProviderIds.filter((id: string) => {
      return id !== idToDelete;
    });

    setUserToEdit({
      ...userToEdit,
      ehrProviderIds: existingEhrProviderIds,
    });
  };

  const handleSubmit = () => {
    setUserImageError(false);
    setUserEmailError(false);
    setFieldError(false);
    setDuplicateBadgeError(false);
    // setNpiError(false);
    setBadgeWarning(false);

    let token = localStorage.getItem('token');

    function validateEmail(email: string) {
      var re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    let fieldError = false;
    let firstNameError = false;
    let lastNameError = false;
    let userEmailError = false;
    let userImageError = false;
    // let npiError = false;
    let domainError = false;

    if (userToEdit?.firstName?.length < 1) {
      fieldError = true;
      firstNameError = true;
    }
    if (userToEdit?.lastName?.length < 1) {
      fieldError = true;
      lastNameError = true;
    }
    if (userToEdit?.userEmail?.length < 1) {
      fieldError = true;
      userEmailError = true;
    }

    if (userToEdit?.userEmail) {
      let domain = userToEdit.userEmail.substring(
        userToEdit.userEmail.lastIndexOf('@') + 1
      );
      if (domain && (organization?.allowedEmailDomains ?? []).length > 0) {
        if (organization?.allowedEmailDomains?.includes(domain)) {
          // Domain allowed.
        } else {
          // Domain not allowed
          fieldError = true;
          domainError = true;
        }
      }
    }

    // if (!userToEdit.userImage && userToEdit.userImageUrl < 1) {
    //   fieldError = true;
    //   userImageError = true;
    // }

    if (
      fieldError
      // || npiError
    ) {
      setFieldError(true);
      setFirstNameError(firstNameError);
      setLastNameError(lastNameError);
      setUserEmailError(userEmailError);
      setUserImageError(userImageError);
      // setNpiError(npiError);
      setDomainError(domainError);
    } else {
      // If its a new user
      if (newUser) {
        // if (!userToEdit.userImage) {
        //   this.setState({
        //     userImageError: true,
        //     firstNameError: firstNameError,
        //     lastNameError: lastNameError,
        //     userEmailError: userEmailError,
        //   });
        // } else
        if (!validateEmail(userToEdit.userEmail)) {
          setUserEmail(true);
          setFirstNameError(firstNameError);
          setLastNameError(lastNameError);
          setUserEmailError(true);
          setInvalidEmail(true);
          setActiveStep(1);
        } else {
          let modifiedUser = {
            ...userToEdit,
            mongoOrganizationId: loggedInUserData.mongoOrganizationId,
          };

          // console.log("Payload", modifiedUser);
          let newUser = JSON.stringify(modifiedUser);

          setLoading(true);
          setInvalidEmail(false);

          fetch(API.REACT_APP_API_UPDATEUSER, {
            method: 'POST',
            body: newUser,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(function (res) {
              return res.json();
            })
            .then(data => {
              console.log(data);

              setLoading(false);

              if (data.data === 'userEmailExists') {
                console.log('error', data.data);
                setNewUserErrorMessage('Email Address Already Exists');

                if (data.data === 'userEmailExists') {
                  setActiveStep(1);
                  setUserEmailError(true);
                }
              } else {
                console.log('new user created', data);

                refetchUsers();

                setHasModified(false);
                setRight(false);
                setFieldError(false);
                setUserImageError(false);
                setUserEmailError(false);
                setNewUserErrorMessage('');
                setUserHasBeenCreated(true);
                setNewUserDetails(data.data);

                onCloseNewUserModal();
                // setTimeout(() => this.setState({ userHasBeenCreated: false }), 3000);
              }
            })
            .catch(err => {
              console.log('Error: ', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      }
      // If its an existing user thats being modified
      else {
        let modifiedUser = {
          ...userToEdit,
          // AdminUserId: loggedInUserData.userId,
        };

        // console.log("MODIFIED USER", modifiedUser);

        // if (modifiedUser.AdminUserId === null) {
        //   modifiedUser.AdminUserId = 0;
        // }

        let payload = JSON.stringify(modifiedUser);

        // console.log('OK', userToEdit);
        // console.log('sending..', modifiedUser)

        setLoading(true);

        // console.log("Sending", modifiedUser);
        fetch(API.REACT_APP_API_UPDATEUSER, {
          method: 'PUT',
          body: payload,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(data => {
            // console.log("Good", data);
            setShowSaveAlert(false);
            setLoading(false);

            if (data.data === 'userEmailExists') {
              console.log('error', data.data);

              setNewUserErrorMessage('Email Address Already Exists');

              if (data.data === 'userEmailExists') {
                setActiveStep(1);
                setUserEmailError(true);
              }
            } else {
              if (data.data.isDuplicate) {
                console.log('Error: Duplicate Badge');

                setNewUserErrorMessage(data.data.response);

                refetchUsers();
              } else {
                refetchUsers();

                setHasModified(false);
                setRight(false);
                setFieldError(false);
                setNewUserErrorMessage('');
              }
            }
          })
          .catch(err => {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    }
  };

  // const handleBadgeInput = () => (event: ChangeEvent<HTMLInputElement>) => {
  //   setNewBadge(event.target.value);
  // };

  const handleBadgeExpiration = (event: ChangeEvent<HTMLInputElement>) => {
    setNewBadgeExpiration(event.target.value);
  };

  const handleNewBadge = (input: { overwrite?: boolean }) => {
    const { overwrite } = input;

    const beacon1 = beaconNumberObject['beacon1'];
    const beacon2 = beaconNumberObject['beacon2'];
    const beacon3 = beaconNumberObject['beacon3'];
    const beacon4 = beaconNumberObject['beacon4'];

    if (beacon1 && beacon2 && beacon3 && beacon4) {
      let newBadge = 'IR' + beacon1 + beacon2 + beacon3 + beacon4;

      if (newBadge === 'IR0000') {
        newBadge = '';
        setBeaconNumberObject({
          ...beaconNumberObject,
          beacon1: '',
          beacon2: '',
          beacon3: '',
          beacon4: '',
        });
        setRestrictedBadgeNumber(true);

        return;
      }

      console.log('made it here');
      let badgeExpiration = null;
      if (newBadgeExpiration === '' || newBadgeExpiration === null) {
        badgeExpiration = null;
      } else {
        badgeExpiration = new Date(newBadgeExpiration).toISOString();
      }

      let error = null;

      let trimmedBadge = newBadge.replace(/\s/g, '');

      for (let i = 0; i < userToEdit.appUserBadges.length; i++) {
        if (userToEdit.appUserBadges[i].badgeString === trimmedBadge) {
          console.log('Error: Duplicate Badge THIS ONE');
          error = true;
        }
      }

      console.log('handleNewBadge', { error });

      if (error) {
        console.log('Error');
        setDuplicateBadgeError(true);
      } else {
        if (trimmedBadge === '' || trimmedBadge === undefined) {
          console.log('Please input a badge');
        } else {
          if (!trimmedBadge.toLowerCase().startsWith('ir')) {
            console.log('Give warning');

            setBadgeWarning(true);
          }

          // Check if duplicate exists with other users/equipment
          let userWithDuplicateBadge: any = null;

          users.forEach((user: IUser) => {
            user.appUserBadges.forEach(badge => {
              if (badge.badgeString === trimmedBadge) {
                // console.log("found duplicate badge!", user, trimmedBadge);
                userWithDuplicateBadge = user;
              }
            });
          });

          console.log('handleNewBadge', { userWithDuplicateBadge });

          instruments.forEach(instrument => {
            instrument.appUserBadges.forEach(badge => {
              if (badge.badgeString === trimmedBadge) {
                // console.log("duplicate badge with equipment", badge);
                userWithDuplicateBadge = instrument;
              }
            });
          });

          if (overwrite) {
            // console.log("MAKE IT NULL");
            userWithDuplicateBadge = null;
          }

          if (!userWithDuplicateBadge) {
            let newBadgeDetails = {
              userId: userToEdit.userId,
              badgeString: trimmedBadge,
              expirationDate: badgeExpiration,
            };
            const updatedBadges = [
              ...userToEdit.appUserBadges,
              newBadgeDetails,
            ];

            setUserToEdit({
              ...userToEdit,
              appUserBadges: updatedBadges,
            });

            setDuplicateBadgeError(false);
            setHasModified(true);
            setNewBadge('');
            setNewBadgeExpiration('');
            setBeaconNumberObject({
              ...beaconNumberObject,
              beacon1: '',
              beacon2: '',
              beacon3: '',
              beacon4: '',
            });

            setOverwriteBadge(false);
            setShowDuplicateUserBadgeError(false);
            setRestrictedBadgeNumber(false);

            if (beginSaveProcess) {
              handleSubmit();

              setBeginSaveProcess(false);
            }
          } else {
            console.log('handleNewBadge has dup', { userWithDuplicateBadge });

            new Promise((resolve, reject) => {
              setUserWithDuplicateBadge(userWithDuplicateBadge);

              resolve(null);
            }).then(() => {
              setShowDuplicateUserBadgeError(true);
            });
          }
        }
      }
    } else {
      handleSubmit();
    }
  };

  const editImage = () => {
    setImageEditMode(true);
    setHideEditImageButton(true);
  };

  const closeEditImage = () => {
    setImageEditMode(false);
  };

  const onCrop = () => {
    // console.log(this.refs.cropper.getCroppedCanvas().toDataURL().replace(/data:image\/[a-zA-Z]+;base64,/g, ''));
    // this.setState({ newImage: this.refs.cropper.getCroppedCanvas().toDataURL().replace(/data:image\/[a-zA-Z]+;base64,/g, '')})
    // console.log(this.refs.cropper.getCroppedCanvas().toDataURL().replace(/data:image\/[a-zA-Z]+;base64,/g, ''));
    // console.log(this.refs)

    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    if (cropper) {
      setUserToEdit({
        ...userToEdit,
        userImage: cropper
          // @ts-ignore
          .getCroppedCanvas()
          .toDataURL()
          .replace(/data:image\/[a-zA-Z]+;base64,/g, ''),
      });
    }
  };

  const resetPassword = (userEmail: string) => {
    console.log('Reset user pass', userEmail);
    let token = localStorage.getItem('token');

    let beforePayload = {
      userEmail: userEmail,
      // AdminUserId: loggedInUserData.userId,
    };

    let payload = JSON.stringify(beforePayload);

    // console.log("sending", payload);

    setLoading(true);

    fetch(API.REACT_APP_API_RESETPASSWORD, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(data => {
        console.log(data);

        refetchUsers();

        setHasModified(false);
        setLoading(false);
        setPasswordReset(true);
        setPasswordResetResponse(data.data ? data.data : null);

        setTimeout(() => setPasswordReset(false), 3000);
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const fileSelectedHandler = (event: any) => {
    console.log('Ding', event.target.files[0]);
    console.log('File selected handler', event.target);
    // var canvas = document.getElementById("imageCanvas");
    // var ctx = canvas.getContext("2d");

    let mountImage = (smallImage: any) => {
      setUploadedFile(smallImage);
      setImageEditMode(true);
    };

    //===============
    let fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }

    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        'JPEG',
        100,
        0,
        (uri: any) => {
          // console.log("uri and file size", uri);
          // Resized it, now set state
          mountImage(uri);
        },
        'base64'
      );
    }
  };

  const onDeleteBadge = (badgeToDelete: string) => {
    let badges = userToEdit.appUserBadges;

    badges = badges.filter((obj: IBadge) => {
      return obj.badgeString !== badgeToDelete;
    });

    // userToEdit.appUserBadges = badges;

    setBadgeWarning(false);
    setHasModified(true);
    setUserToEdit({
      ...userToEdit,
      appUserBadges: badges,
    });
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  const handleBadgeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setBadgeString(event.target.value);
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const onRotateImage = () => {
    // @ts-ignore
    cropperRef.current.cropper.rotate(90);
  };

  // pasteImage = async () => {
  //   console.log("paste!");
  //   if (navigator.clipboard) {
  //     console.log("Clipboard API available");
  //     let clipboardItems = await navigator.clipboard.read();
  //     console.log("clipboard items", clipboardItems);
  //     if (clipboardItems[0]) {
  //       let blob = await clipboardItems[0].getType("image/png");
  //       console.log("blob", blob);
  //       // let url = URL.createObjectURL(blob);
  //       // console.log("url", url);
  //     }
  //   }
  // };

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    console.log('type', type);

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (nextStepIndex < USERS_TUTORIAL_STEPS.length) {
        setUserTutorialStepIndex(nextStepIndex);
      } else {
        // If we've reached the end of the steps, finish the tour
        setRun(false);
        setUserTutorialStepIndex(0);
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log('finish');
      setRun(false);
      setUserTutorialStepIndex(0);
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };

  const processUsers = (usersArray: any) => {
    console.log('processing', usersArray);
    // let existingImportedUsers = importedUsers;
    // console.log("Users arr", usersArray);
    let preppedUsers: any[] = [];
    let missingServiceLine = '';
    let missingSubGroup = '';

    usersArray.forEach((existingUser: any) => {
      let foundServiceLine: any = '';
      let foundSubGroup: any = '';
      let foundUserType = 1;
      let foundNPI = '';

      (organization?.serviceLines ?? []).forEach(serviceLine => {
        if (existingUser.serviceLine) {
          if (serviceLine.name === existingUser.serviceLine) {
            foundServiceLine = { ...serviceLine };
          }
        }
        serviceLine.serviceLineSubGroups.forEach(
          (subGroup: IServiceSubGroup) => {
            if (existingUser.serviceLineSubGroup) {
              if (subGroup.name === existingUser.serviceLineSubGroup) {
                foundSubGroup = { ...subGroup };
              }
            }
          }
        );
      });

      if (foundServiceLine === '') {
        // Service line missing
        if (existingUser.serviceLine) {
          missingServiceLine = existingUser.serviceLine;
        }
      }

      if (foundSubGroup === '') {
        // Subgroup missing
        if (existingUser.serviceLineSubGroup) {
          missingSubGroup = existingUser.serviceLineSubGroup;
        }
      }

      if (existingUser.npi) {
        foundUserType = 4;
        foundNPI = existingUser.npi;
      }

      if (foundServiceLine === '') {
        foundServiceLine = null;
      }

      if (foundSubGroup === '') {
        foundSubGroup = null;
      }

      preppedUsers.push({
        importId: uniqid(),
        // ...existingUser,
        firstName: existingUser.firstName.trim(),
        lastName: existingUser.lastName.trim(),
        userEmail: existingUser.userEmail.trim(),
        // isAdmin: false,
        userTypeId: foundUserType,
        importSuccess: false,
        isActive: true,
        appUserBadges: [],
        fieldError: false,
        newUserErrorMessage: '',
        ehrProviderIds: [foundNPI],
        emailError: false,
        firstNameError: false,
        lastNameError: false,
        serviceLine: foundServiceLine,
        serviceLineSubGroup: foundSubGroup,
      });
    });

    console.log('Prepped!', preppedUsers);

    setImportedUsers(preppedUsers);
    setMissingServiceLines(
      missingServiceLine.length > 0
        ? [...missingServiceLines, missingServiceLine]
        : [...missingServiceLines]
    );
    setMissingSubGroups(
      missingSubGroup.length > 0
        ? [...missingSubGroups, missingSubGroup]
        : [...missingSubGroups]
    );
  };

  const selectServiceLine = (e: any) => {
    // console.log("HERE", e.target.value);
    let found = false;
    (organization?.serviceLines ?? []).forEach(serviceLine => {
      if (serviceLine.id === e.target.value) {
        found = true;
        setUserToEdit({
          ...userToEdit,
          serviceLine: { id: serviceLine.id, name: serviceLine.name } as any,
        });
      }
    });
    if (!found) {
      setUserToEdit({
        ...userToEdit,
        serviceLine: null,
        serviceLineSubGroup: null,
      });
    }
  };

  const selectServiceLineSubGroup = (e: any, subGroups: IServiceSubGroup[]) => {
    // console.log("here", e.target.value, subGroups);
    let found = false;
    subGroups.forEach(subGroup => {
      if (e.target.value === subGroup.id) {
        found = true;
        console.log('Selected group', subGroup);
        if (subGroup.starterPermissions.length > 0) {
          setUserToEdit({
            ...userToEdit,
            serviceLineSubGroup: subGroup,
          });

          setSubGroupStarterPermissionsDialog(true);
          setSubGroupStarterPermissions(subGroup.starterPermissions);
        } else {
          setUserToEdit({
            ...userToEdit,
            serviceLineSubGroup: subGroup,
          });
        }
      }
    });
    if (!found) {
      setUserToEdit({
        ...userToEdit,
        serviceLineSubGroup: null,
      });
    }
  };

  const onOverwriteBadge = () => {
    console.log('overwrite badge!');
    handleNewBadge({
      overwrite: true,
    });
  };

  const saveChanges = () => {
    handleNewBadge({
      overwrite: false,
    });
    setBeginSaveProcess(true);
  };

  // const createMissingServiceLine = (missingServiceLine: string) => {
  //   let mockedOrgId = localStorage.getItem('lastMockedOrganizationId');
  //   let token = localStorage.getItem('token');
  //   let userEmail = localStorage.getItem('email');

  //   console.log('Create missing service line', missingServiceLine);
  //   let orgServiceLinesToEdit = [...(organization?.serviceLines ?? [])];

  //   orgServiceLinesToEdit.push({
  //     name: missingServiceLine,
  //     serviceLineSubGroups: [],
  //   });

  //   let modifiedOrg = {
  //     ...organization,
  //     serviceLines: orgServiceLinesToEdit,
  //   };

  //   console.log('payload', modifiedOrg);
  //   let payload = JSON.stringify(modifiedOrg);

  //   fetch(API.REACT_APP_API_UPDATESERVICELINES, {
  //     method: 'PUT',
  //     body: payload,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + token,
  //     },
  //   })
  //     .then(res => {
  //       return res.json();
  //     })
  //     .then(data => {
  //       console.log('Success!', data);
  //       if (userEmail && token) {
  //         fetchLoggedInUser(userEmail, token, mockedOrgId);
  //       }

  //       let missingServiceLinesToUpdate = [...missingServiceLines];
  //       missingServiceLinesToUpdate = missingServiceLinesToUpdate.filter(
  //         serviceLine => serviceLine !== missingServiceLine
  //       );

  //       setMissingServiceLines(missingServiceLinesToUpdate);

  //       // setTimeout(() => {
  //       //   this.setState({ serviceLineCreated: false });
  //       // }, 5000);
  //     })
  //     .catch(err => {
  //       if (err.response?.status === 401) {
  //         checkRefreshToken();
  //       }
  //       console.log('Err', err);
  //     });
  // };

  const togglePermission = (permission: IPermission) => {
    // console.log("TOGGLE", permission);
    let updatedUserToEdit = { ...userToEdit };

    if (
      _.find(updatedUserToEdit.userPermissions, [
        'permissionName',
        permission.permissionName,
      ])
    ) {
      _.remove(updatedUserToEdit.userPermissions, [
        'permissionName',
        permission.permissionName,
      ]);
    } else {
      updatedUserToEdit.userPermissions.push(permission);
    }

    setUserToEdit(updatedUserToEdit);
    setHasModified(true);
  };

  const checkAllPermission = (permissionGroup: string, checkAll: boolean) => {
    // console.log("Check all", permissionGroup);

    let updatedUserToEdit = { ...userToEdit };

    // console.log("permissions", this.props.permissions);

    permissions.forEach((permission: IPermission) => {
      // _.some(userToEdit.userPermissions, ["permissionName", groupedPermission.permissionName])

      if (
        _.some(updatedUserToEdit.userPermissions, [
          'permissionName',
          permission,
        ])
      ) {
      } else {
        if (permission.permissionGroup === permissionGroup) {
          if (checkAll) {
            let alreadyExists = false;

            updatedUserToEdit.userPermissions.forEach(
              (existingPermission: IPermission) => {
                if (
                  existingPermission.permissionName ===
                  permission.permissionName
                ) {
                  alreadyExists = true;
                }
              }
            );

            if (!alreadyExists) {
              updatedUserToEdit.userPermissions.push(permission);
            }
          } else {
            _.remove(updatedUserToEdit.userPermissions, [
              'permissionName',
              permission.permissionName,
            ]);
          }
        }
      }
      // }
    });

    setHasModified(true);
    setUserToEdit(updatedUserToEdit);
  };

  const determineCheckAll = (groupName: string, groupedPermissions: any) => {
    let total: number = 0;
    let checked: number = 0;

    if (groupName && groupedPermissions) {
      groupedPermissions[groupName].forEach(
        (groupedPermission: IPermission) => {
          if (
            _.some(loggedInUserData.userPermissions, [
              'permissionName',
              groupedPermission.permissionName,
            ]) ||
            canDo(['SyncAdmin'], loggedInUserData)
          ) {
            total++;
            if (
              _.some(userToEdit.userPermissions, [
                'permissionName',
                groupedPermission.permissionName,
              ])
            ) {
              checked++;
            }
          }
        }
      );
    }

    if (checked < total) {
      return true;
    } else {
      return false;
    }
  };

  const showConfirmationDetails = () => {
    let URL = API.REACT_APP_API_PHONEVERIFICATIONCODE + userToEdit.id;
    let token = localStorage.getItem('token');

    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    // console.log("lets get", orgId);
    axios
      .get(URL, headers)
      .then(response => {
        console.log('res', response.data.data);
        if (response.data.data) {
          setConfirmationDetails(response.data.data);
        }
      })
      .catch(err => {
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
        console.log('Err fetching phone confirmation code');
      });
  };

  const toggleColumn = (columnToToggle: string) => {
    let userColumns = localStorage.getItem('userColumns');
    if (userColumns) {
      // Storage array exists. Modify it
      let columnsToModify = JSON.parse(
        localStorage.getItem('userColumns') || '[]'
      );
      if (columnsToModify.includes(columnToToggle)) {
        // remove it
        let updatedArr = _.pull(columnsToModify, columnToToggle);
        // console.log("Removed", updatedArr);
        localStorage.setItem('userColumns', JSON.stringify(updatedArr));
        forceUpdate();
      } else {
        // add it
        let updatedArr = [...columnsToModify, columnToToggle];
        // console.log("added", updatedArr);
        localStorage.setItem('userColumns', JSON.stringify(updatedArr));
        forceUpdate();
      }
    } else {
      // Storage array doesnt exist, create it
      let columnArr = JSON.stringify([columnToToggle]);
      localStorage.setItem('userColumns', columnArr);
      forceUpdate();
    }
  };

  const toggleAllColumns = () => {
    let userColumns = JSON.parse(localStorage.getItem('userColumns') || '[]');
    let columns = [
      'Email Status',
      'EHR Provider IDs',
      'Active',
      'Permissions',
      'Provider',
      'Low Battery',
      'Password in Reset',
      'Badge IDs',
      'Service Line',
      'Service Line Subgroup',
      'Email Status',
    ];
    let allColumns = JSON.stringify(columns);
    if (JSON.stringify(userColumns) === allColumns) {
      localStorage.removeItem('userColumns');
      forceUpdate();
    } else {
      localStorage.setItem('userColumns', allColumns);
      forceUpdate();
    }
  };

  const reFetchSingleUser = () => {
    let URL = API.REACT_APP_API_GETSINGLEUSERDATA + userToEdit.id;
    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    console.log('FETCH THE USER');
    axios
      .get(URL, headers)
      .then(response => {
        console.log('FETCHED USER DATA', response.data.data);
        setUserToEdit(response.data.data);
      })
      .catch(err => {
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
        console.log('ERR fetching single user');
      });
  };

  const downloadUsers = (filteredUsers: any) => {
    console.log('Download', filteredUsers);

    let constructedData: any = [];
    filteredUsers.forEach((user: any) => {
      constructedData.push({
        firstName: user.firstName,
        lastName: user.lastName,
        userEmail: user.userEmail,
        appUserBadges: user.appUserBadges
          .map((x: any) => x.badgeString)
          .join(', '),
        serviceLine: user.serviceLine?.name,
        serviceLineSubGroup: user.serviceLineSubGroup?.name,
        isActive: user.isActive.toString(),
        // createdOn: user.createdOn,
        // currentRoomStartTime: user.currentRoomStartTime,
        // ehrProviderIds: user.ehrProviderIds.map((x: any) => x).join(", "),
        // fullName: user.fullName,
        // hasLoggedInBefore: user.hasLoggedInBefore.toString(),
        // hasLowBatteryBadge: user.hasLowBatteryBadge.toString(),
        // hasTutorialStarted: user.hasTutorialStarted.toString(),
        // id: user.id,
        // isAdmin: user.isAdmin.toString(),
        // isPhoneNumberVerified: user.isPhoneNumberVerified.toString(),
        // isViewOnlyFlowstation: user.isViewOnlyFlowstation.toString(),
        // lastLocationIsInside: user.lastLocationIsInside.toString(),
        // phoneNumber: user.phoneNumber ? user.phoneNumber : "",
        // phoneNumberExtension: user.phoneNumberExtension ? user.phoneNumberExtension : "",
        // resetPasswordNextLogin: user.resetPasswordNextLogin.toString(),
        // userImageUrl: user.userImageUrl,
        // userPermissions: user.userPermissions.map((x: any) => x.permissionName).join(", "),
      });
    });

    // console.log("Constructed", constructedData);

    let data = [
      {
        sheet: 'Users',
        columns: [
          { label: 'FirstName', value: 'firstName' },
          { label: 'LastName', value: 'lastName' },
          { label: 'UserEmail', value: 'userEmail' },
          { label: 'AppUserBadges', value: 'appUserBadges' }, // Top level data
          { label: 'ServiceLine', value: 'serviceLine' },
          { label: 'ServiceLineSubGroup', value: 'serviceLineSubGroup' },
          { label: 'IsActive', value: 'isActive' },

          // { label: "FullName", value: "fullName" },
          // { label: "IsAdmin", value: "isAdmin" },
          // { label: "PhoneNumber", value: "phoneNumber" },
          // { label: "CreatedOn", value: "createdOn" },
          // { label: "CurrentRoomStartTime", value: "currentRoomStartTime" },
          // { label: "EhrProviderIds", value: "ehrProviderIds" },
          // { label: "HasLoggedInBefore", value: "hasLoggedInBefore" },
          // { label: "HasLowBatteryBadge", value: "hasLowBatteryBadge" },
          // { label: "HasTutorialStarted", value: "hasTutorialStarted" },
          // { label: "Id", value: "id" },
          // { label: "IsPhoneNumberVerified", value: "isPhoneNumberVerified" },
          // { label: "IsViewOnlyFlowstation", value: "isViewOnlyFlowstation" },
          // { label: "LastLocationIsInside", value: "lastLocationIsInside" },
          // { label: "PhoneNumberExtension", value: "phoneNumberExtension" },
          // { label: "ResetPasswordNextLogin", value: "resetPasswordNextLogin" },
          // { label: "UserImageUrl", value: "userImageUrl" },
          // { label: "UserPermissions", value: "userPermissions" },

          // { label: "Age", value: (  : any) => row.age + " years" }, // Custom format
          // { label: "Phone", value: (row: any) => (row.more ? row.more.phone || "" : "") }, // Run functions
        ],
        content: constructedData,
      },
    ];

    let settings = {
      fileName: 'Users',
    };

    xlsx(data, settings); // Will download the excel file
  };

  let columnsToView = JSON.parse(localStorage.getItem('userColumns') || '[]');

  let allColumnsChecked = false;
  let columns = [
    'Email Status',
    'EHR Provider IDs',
    'Active',
    'Permissions',
    'Provider',
    'Low Battery',
    'Password in Reset',
    'Badge IDs',
    'Service Line',
    'Service Line Subgroup',
    'Email Status',
  ];
  let allColumns = JSON.stringify(columns);
  if (JSON.stringify(columnsToView) === allColumns) {
    allColumnsChecked = true;
  }

  function groupPermissions(arr: any, property: any) {
    return arr.reduce(function (memo: any, x: any) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  let groupedPermissions: any = {};
  let permissionGroups: any = [];

  // console.log("permissions", this.props.permissions);

  if (permissions && userToEdit) {
    groupedPermissions = groupPermissions(permissions, 'permissionGroup');
    permissions.forEach((permission: IPermission) => {
      if (!permissionGroups.includes(permission.permissionGroup)) {
        permissionGroups.push(permission.permissionGroup);
      }
    });
  }
  // Filter Users by Input Field
  let filteredUsers: IUser[] = [];

  if (users) {
    // console.log("user data", usersData);
    if (sortBy === 'badgeIds') {
      // Sort by badgeIds array of objs
      filteredUsers = _.sortBy(users, [
        function (o: any) {
          return o.appUserBadges[0]?.badgeString;
        },
      ]);

      if (sortDescending) {
        filteredUsers.reverse();
      }
    } else if (sortBy === 'ehrProviderIds') {
      // Sort by ehrProviderIds array
      filteredUsers = _.sortBy(users, [
        function (o: any) {
          return o.ehrProviderIds[0];
        },
      ]);

      if (sortDescending) {
        filteredUsers.reverse();
      }
    } else if (sortBy === 'serviceLine') {
      // Sort by serviceLine array
      filteredUsers = _.sortBy(users, [
        function (o: any) {
          return o.serviceLine?.name;
        },
      ]);

      if (sortDescending) {
        filteredUsers.reverse();
      }
    } else if (sortBy === 'serviceLineSubGroup') {
      // Sort by serviceLineSubGroup array
      filteredUsers = _.sortBy(users, [
        function (o: any) {
          return o.serviceLineSubGroup?.name;
        },
      ]);

      if (sortDescending) {
        filteredUsers.reverse();
      }
    } else {
      // Sort By Select
      let filter = sortBy;
      filteredUsers = users.sort((a: any, b: any) => {
        if (sortDescending) {
          // DESCENDING
          // @ts-ignore
          return (
            // @ts-ignore
            (a[filter] === null) - (b[filter] === null) ||
            -(a[filter] > b[filter]) ||
            +(a[filter] < b[filter])
          );
          // if (a[filter as keyof IUser] > b[filter as keyof IUser]) {
          //   return -1;
          // }
          // if (a[filter as keyof IUser] < b[filter as keyof IUser]) {
          //   return 1;
          // }
          // return 0;
        } else {
          // ASCENDING
          // @ts-ignore
          return (
            // @ts-ignore
            (a[filter] === null) - (b[filter] === null) ||
            +(a[filter] > b[filter]) ||
            -(a[filter] < b[filter])
          );
          // if (a[filter as keyof IUser] < b[filter as keyof IUser]) {
          //   return -1;
          // }
          // if (a[filter as keyof IUser] > b[filter as keyof IUser]) {
          //   return 1;
          // }
          // return 0;
        }
      });
    }

    // console.log("check", filteredUsers);
    if (filterString.length > 0) {
      filteredUsers = filterUsers({
        filterString,
        users,
      });
    }

    // Search for Badge
    if (badgeString.length >= 1) {
      let filteredBadgeUsers = [];
      for (let i = 0; i < users.length; i++) {
        // console.log(users[i])
        for (let o = 0; o < users[i].appUserBadges.length; o++) {
          if (
            users[i].appUserBadges[o].badgeString
              .toUpperCase()
              .includes(badgeString.toUpperCase())
          ) {
            filteredBadgeUsers.push(users[i]);
          }
        }
      }
      filteredUsers = _.uniqBy(filteredBadgeUsers, 'id');
    }

    // Hide inactive users
    if (hideInactiveUsers) {
      filteredUsers = filteredUsers.filter(user => {
        return user.isActive;
      });

      // let activeUsers: IUser[] = [];
      // filteredUsers.filter((item: IUser) => {
      //   if (item.isActive) {
      //     activeUsers.push(item);
      //   }
      //   return null;
      // });
      // filteredUsers = activeUsers;
    }
  }

  const steps: any[] = [
    'User Image',
    'User Info',
    'User Type and Settings',
    'Badge Details',
  ];

  let firstNameErrorFound = false;
  let lastNameErrorFound = false;
  let emailErrorFound = false;
  let domainErrorFound = false;
  let importsRemaining = 0;

  importedUsers.forEach(user => {
    if (!user.importSuccess) {
      importsRemaining++;
    }
    if (user.firstNameError) {
      firstNameErrorFound = true;
    }
    if (user.lastNameError) {
      lastNameErrorFound = true;
    }
    if (user.emailError) {
      emailErrorFound = true;
    }
    if (user.domainError) {
      domainErrorFound = true;
    }
  });

  let subGroups: IServiceSubGroup[] = [];

  if (organization && userToEdit) {
    // console.log("org", organization);
    if (userToEdit.serviceLine) {
      organization.serviceLines.forEach(serviceLine => {
        if (serviceLine.id === userToEdit?.serviceLine?.id) {
          // console.log("found it!", serviceLine);
          subGroups = serviceLine.serviceLineSubGroups;
        }
      });
    }
  }
  let selectableServiceLineSubGroups: any[] = [];
  (organization?.serviceLines ?? []).forEach(serviceLine => {
    serviceLine.serviceLineSubGroups.forEach((subGroup: IServiceSubGroup) => {
      selectableServiceLineSubGroups.push({
        ...subGroup,
        parentServiceLine: serviceLine.name,
      });
    });
  });

  subGroups.sort((a: any, b: any) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  });

  // console.log("selectableServiceLineSubGroups", selectableServiceLineSubGroups);

  return (
    <div>
      {canDo(['View Users'], loggedInUserData) ? (
        <div>
          <MuiThemeProvider theme={theme}>
            <Modal
              open={showUserNotifications}
              onClose={() => setShowUserNotifications(false)}
            >
              <div className={classes.newAlertModal}>
                <Typography gutterBottom variant="h4" component="h2">
                  User Notifications
                </Typography>

                <div className="user-edit">
                <Notifications
                  loggedInUserData={userToEdit}
                  modifyingOtherUsers={true}
                  reFetchUserData={reFetchSingleUser}
                  applyUserStyles={true}
                />

                </div>
              </div>
            </Modal>

            <Dialog open={subGroupStarterPermissionsDialog}>
              <DialogTitle>
                Overwrite User Permissions with{' '}
                {organization?.serviceLineSubGroupLabel ?? ''} Permissions?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This will replace all existing user permissions with this{' '}
                  {organization?.serviceLineSubGroupLabel ?? ''} permissions.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    setHasModified(true);
                    setSubGroupStarterPermissionsDialog(false);
                    setUserToEdit({
                      ...userToEdit,
                      userPermissions: subGroupStarterPermissions,
                    });
                  }}
                >
                  Yes
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  onClick={() => {
                    setSubGroupStarterPermissionsDialog(false);
                    setSubGroupStarterPermissions([]);
                  }}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
            {passwordResetResponse ? (
              <Dialog
                open={passwordResetResponse ? true : false}
                onClose={() => setPasswordResetResponse(null)}
              >
                <div
                  onClick={() => setPasswordResetResponse(null)}
                  style={{
                    width: '100%',
                    color: 'red',
                    cursor: 'pointer',
                    paddingTop: 12,
                    paddingRight: 12,
                    fontSize: 24,
                    textAlign: 'right',
                  }}
                >
                  X
                </div>
                <DialogTitle>
                  An email has been sent to{' '}
                  {passwordResetResponse?.userEmail ?? ''} requesting a password
                  reset.
                </DialogTitle>
                <DialogContent
                  style={{ wordBreak: 'break-all', marginBottom: 25 }}
                >
                  <div style={{ color: '#0000008a' }}>
                    <div>
                      The link below may also be used by the user to finish the
                      password reset.
                    </div>
                    <br />
                    URL to send to user:
                    <div style={{ fontWeight: 'bold' }}>
                      {/* {passwordResetResponse.loginUrlToCopy}{" "} */}

                      <Tooltip
                        title="Click to Copy to Clipboard"
                        placement="top"
                      >
                        <TextField
                          onClick={() =>
                            navigator.clipboard.writeText(
                              passwordResetResponse.loginUrlToCopy
                            )
                          }
                          value={passwordResetResponse.loginUrlToCopy}
                          variant="outlined"
                          margin="none"
                          style={{ width: '100%' }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            ) : null}
            {userHasBeenCreated ? (
              <Dialog
                open={userHasBeenCreated ? true : false}
                onClose={() => setPasswordResetResponse(null)}
              >
                <div
                  onClick={() => {
                    setUserHasBeenCreated(false);
                    setNewUserDetails(null);
                  }}
                  style={{
                    width: '100%',
                    color: 'red',
                    cursor: 'pointer',
                    paddingTop: 12,
                    paddingRight: 12,
                    fontSize: 24,
                    textAlign: 'right',
                  }}
                >
                  X
                </div>
                <DialogTitle>
                  An email has been sent to {newUserDetails.userEmail}{' '}
                  requesting a password reset.
                </DialogTitle>
                <DialogContent
                  style={{ wordBreak: 'break-all', marginBottom: 25 }}
                >
                  <div style={{ color: '#0000008a' }}>
                    <div>
                      The link below may also be used for the new user to reset
                      their password.
                    </div>
                    <br />
                    URL to send to user:
                    <div style={{ fontWeight: 'bold' }}>
                      <Tooltip
                        title="Click to Copy to Clipboard"
                        placement="top"
                      >
                        <TextField
                          onClick={() =>
                            navigator.clipboard.writeText(
                              newUserDetails.loginUrlToCopy
                            )
                          }
                          value={newUserDetails.loginUrlToCopy}
                          variant="outlined"
                          margin="none"
                          style={{ width: '100%' }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            ) : null}
            <Dialog open={showNewUserSaveWarning}>
              <DialogTitle>Close importer?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Not all users are imported. Would you like to close?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => onCloseNewUserImport(true)}
                >
                  Yes
                </Button>
                <Button
                  color="primary"
                  // autoFocus
                  onClick={() => setShowNewUserSaveWarning(false)}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={showSaveAlert}>
              <DialogTitle>Unsaved changes!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Would you like to save your changes before exiting?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={() => saveChanges()}>
                  Yes
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  onClick={() => closeWithoutSaving(userToEdit)}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={showDuplicateUserBadgeError}>
              <DialogTitle id="alert-dialog-title">
                This beacon is already assigned to{' '}
                {userWithDuplicateBadge
                  ? userWithDuplicateBadge?.fullName
                  : null}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to continue? This will remove the existing
                  beacon from{' '}
                  {userWithDuplicateBadge
                    ? userWithDuplicateBadge?.fullName
                    : null}
                  test
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    onOverwriteBadge();
                  }}
                >
                  Yes
                </Button>
                <Button
                  color="primary"
                  // autoFocus
                  onClick={() => {
                    setShowDuplicateUserBadgeError(false);
                    setUserWithDuplicateBadge(null);
                    setBeaconNumberObject({
                      ...beaconNumberObject,
                      beacon1: '',
                      beacon2: '',
                      beacon3: '',
                      beacon4: '',
                    });
                  }}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant="h4" gutterBottom component="h2">
                Users
                <Tooltip title="Users Tutorial" placement="right">
                  <IconButton onClick={() => setRun(true)}>
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Typography>

              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div onClick={() => onEmailStaffPatientReport()}>
                  <Tooltip
                    title="Email Me Staff/Patient Report"
                    aria-label="Add"
                  >
                    <Fab className={classes.downloadUsersButton}>
                      <NewReleasesIcon />
                    </Fab>
                  </Tooltip>
                </div>

                {canDo(['SyncAdmin'], loggedInUserData) ? (
                  // <CSVLink style={{ textDecoration: "none" }} filename={"users.csv"} data={filteredUsers}>
                  <div onClick={() => downloadUsers(filteredUsers)}>
                    <Tooltip
                      title="Click here to download filtered users"
                      aria-label="Add"
                    >
                      <Fab className={classes.downloadUsersButton}>
                        <GetAppIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                ) : // </CSVLink>
                null}
                {/* {console.log("width", window.screen.width)} */}
                {canDo(['Create Users'], loggedInUserData) ? (
                  <>
                    <Tooltip
                      title="Click here to view invalid EHR Ids"
                      aria-label="Add"
                      onClick={() => openMissingEhrProviderIdsModal()}
                    >
                      <Fab className={classes.invalidEHRButton}>
                        <AssignmentLateIcon />
                      </Fab>
                    </Tooltip>
                    <Tooltip
                      title="Click here to add a new staff member."
                      aria-label="Add"
                      // onClick={() => (window.screen.width > 1000 ? this.newUserModal() : this.newUserModalMobile())}
                      onClick={() => newUserModalMobile()}
                    >
                      <Fab className={classes.newUserButton}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                    <Tooltip
                      title="Click here to import users via CSV"
                      aria-label="Add"
                      onClick={() => newUserImport()}
                    >
                      <Fab className={classes.newUserImportButton}>
                        <ImportIcon />
                      </Fab>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>

            <TextField
              label="Search"
              className={classes.sortField}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFilter(event)
              }
            />
            <TextField
              label="Badges"
              className={classes.sortField}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleBadgeSearch(event)
              }
            />
            <br />
            <div
              onClick={() => {
                setHideInactiveUsers(!hideInactiveUsers);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Checkbox
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={hideInactiveUsers}
              />
              Hide Inactive Users
            </div>

            <br />
            {/* Section to toggle column to view */}
            {/* <div onClick={() => toggleColumn("Picture")} style={{ cursor: "pointer", display: "inline-block", marginTop: 10 }}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={columnsToView.includes("Picture")}
                />
                Picture
              </div>
              <div onClick={() => toggleColumn("First Name")} style={{ cursor: "pointer", display: "inline-block", marginTop: 10 }}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={columnsToView.includes("First Name")}
                />
                First Name
              </div>
              <div onClick={() => toggleColumn("Last Name")} style={{ cursor: "pointer", display: "inline-block", marginTop: 10 }}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={columnsToView.includes("Last Name")}
                />
                Last Name
              </div>
              <div onClick={() => toggleColumn("Email")} style={{ cursor: "pointer", display: "inline-block", marginTop: 10 }}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={columnsToView.includes("Email")}
                />
                Email
              </div> */}
            <div>
              <div
                onClick={() => toggleAllColumns()}
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  marginTop: 10,
                }}
              >
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={allColumnsChecked}
                />
                Check/Uncheck All
              </div>
            </div>

            <div
              onClick={() => toggleColumn('EHR Provider IDs')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('EHR Provider IDs')}
              />
              EHR Provider ID(s)
            </div>
            <div
              onClick={() => toggleColumn('Active')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Active')}
              />
              Active
            </div>
            <div
              onClick={() => toggleColumn('Permissions')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Permissions')}
              />
              Permissions
            </div>
            <div
              onClick={() => toggleColumn('Provider')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Provider')}
              />
              Provider
            </div>
            <div
              onClick={() => toggleColumn('Low Battery')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Low Battery')}
              />
              Low Battery
            </div>
            <div
              onClick={() => toggleColumn('Password in Reset')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Password in Reset')}
              />
              Password in Reset
            </div>
            <div
              onClick={() => toggleColumn('Badge IDs')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Badge IDs')}
              />
              Badge ID(s)
            </div>

            <div
              onClick={() => toggleColumn('Service Line')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Service Line')}
              />
              {organization?.serviceLineLabel}
            </div>

            <div
              onClick={() => toggleColumn('Service Line Subgroup')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Service Line Subgroup')}
              />
              {organization?.serviceLineSubGroupLabel}
            </div>

            <div
              onClick={() => toggleColumn('Email Status')}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <Checkbox
                style={{ paddingTop: 0, paddingBottom: 0 }}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={columnsToView.includes('Email Status')}
              />
              Email Status
            </div>

            <Paper className={classes.root}>
              <Table className={classes.table + ' third-step users-first-step'}>
                <TableHead className={classes.head}>
                  <TableRow>
                    <TableCell>
                      <div>Picture</div>
                    </TableCell>

                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('firstName')}
                    >
                      {sortBy === 'firstName' ? (
                        <div>First Name {sortDescending ? <>▼</> : <>▲</>}</div>
                      ) : (
                        <div>First Name</div>
                      )}
                    </TableCell>

                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('lastName')}
                    >
                      {sortBy === 'lastName' ? (
                        <div>Last Name {sortDescending ? <>▼</> : <>▲</>}</div>
                      ) : (
                        <div>Last Name</div>
                      )}
                    </TableCell>

                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('userEmail')}
                    >
                      {sortBy === 'userEmail' ? (
                        <div>Email {sortDescending ? <>▼</> : <>▲</>}</div>
                      ) : (
                        <div>Email</div>
                      )}
                    </TableCell>

                    {columnsToView.includes('EHR Provider IDs') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('ehrProviderIds')}
                      >
                        {sortBy === 'ehrProviderIds' ? (
                          <div>
                            EHR Provider ID(s){' '}
                            {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>EHR Provider ID(s)</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Email Status') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('userEmailStatus')}
                      >
                        {sortBy === 'userEmailStatus' ? (
                          <div>
                            Email Status {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Email Status</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Active') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('isActive')}
                      >
                        {sortBy === 'isActive' ? (
                          <div>Active {sortDescending ? <>▼</> : <>▲</>}</div>
                        ) : (
                          <div>Active</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Permissions') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('userPermissions')}
                      >
                        {sortBy === 'userPermissions' ? (
                          <div>
                            Permissions {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Permissions</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Provider') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('userTypeId')}
                      >
                        {sortBy === 'userTypeId' ? (
                          <div>Provider {sortDescending ? <>▼</> : <>▲</>}</div>
                        ) : (
                          <div>Provider</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Low Battery') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('hasLowBatteryBadge')}
                      >
                        {sortBy === 'hasLowBatteryBadge' ? (
                          <div>
                            Low Battery {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Low Battery</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Password in Reset') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('resetPasswordNextLogin')}
                      >
                        {sortBy === 'resetPasswordNextLogin' ? (
                          <div>
                            Password in Reset {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Password in Reset</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Badge IDs') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('badgeIds')}
                      >
                        {sortBy === 'badgeIds' ? (
                          <div>
                            Badge ID(s) {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>Badge ID(s)</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Service Line') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('serviceLine')}
                      >
                        {/* <div>{organization?.serviceLineLabel}</div> */}
                        {sortBy === 'serviceLine' ? (
                          <div>
                            {organization?.serviceLineLabel}{' '}
                            {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>{organization?.serviceLineLabel}</div>
                        )}
                      </TableCell>
                    ) : null}

                    {columnsToView.includes('Service Line Subgroup') ? (
                      <TableCell
                        className={classes.sortSelect}
                        onClick={() => onSortBy('serviceLineSubGroup')}
                      >
                        {/* <div>{organization?.serviceLineSubGroupLabel}</div> */}
                        {sortBy === 'serviceLineSubGroup' ? (
                          <div>
                            {organization?.serviceLineSubGroupLabel}{' '}
                            {sortDescending ? <>▼</> : <>▲</>}
                          </div>
                        ) : (
                          <div>{organization?.serviceLineSubGroupLabel}</div>
                        )}
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user: IUser) => (
                    <TableRow key={user.id} className={classes.tableRow}>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => toggleDrawer(true, user)}
                      >
                        <Avatar alt="" src={user.userImageUrl} />
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        onClick={() => toggleDrawer(true, user)}
                      >
                        {user.firstName}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        onClick={() => toggleDrawer(true, user)}
                      >
                        {user.lastName}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        onClick={() => toggleDrawer(true, user)}
                      >
                        {user.userEmail}
                      </TableCell>

                      {columnsToView.includes('EHR Provider IDs') ? (
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.ehrProviderIds.map((id, index) => (
                            <span key={index}>
                              {index === 0 ? null : <span>, </span>} {id}
                            </span>
                          ))}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Email Status') ? (
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.userEmailStatus}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Active') ? (
                        <TableCell
                          className={classes.tableCell}
                          style={user.isActive ? { textAlign: 'center' } : {}}
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.isActive ? <CheckIcon /> : null}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Permissions') ? (
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: 'center' }}
                          onClick={() => toggleDrawer(true, user)}
                        >
                          <Tooltip
                            title={
                              <React.Fragment>
                                {user.userPermissions.map((permission: any) => (
                                  <div key={permission.id}>
                                    - {permission.permissionName}
                                  </div>
                                ))}
                              </React.Fragment>
                            }
                            placement="right"
                          >
                            <div>{user.userPermissions.length}</div>
                          </Tooltip>
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Provider') ? (
                        <TableCell
                          className={classes.tableCell}
                          style={
                            user.userTypeId === 4 ? { textAlign: 'center' } : {}
                          }
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.userTypeId === 4 ? <CheckIcon /> : null}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Low Battery') ? (
                        <TableCell
                          className={classes.tableCell}
                          style={
                            user.hasLowBatteryBadge
                              ? { textAlign: 'center' }
                              : {}
                          }
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.hasLowBatteryBadge ? <CheckIcon /> : null}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Password in Reset') ? (
                        <TableCell
                          className={classes.tableCell}
                          style={
                            user.resetPasswordNextLogin ? { width: 300 } : {}
                          }
                        >
                          {user.resetPasswordNextLogin ? (
                            <Button
                              style={{ marginLeft: 10 }}
                              onClick={() => resetPassword(user.userEmail)}
                              component="label"
                              color="primary"
                              variant="contained"
                            >
                              Resend Email
                            </Button>
                          ) : null}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Badge IDs') ? (
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.appUserBadges.map((badge, index) => (
                            <span key={index}>
                              {index === 0 ? null : <span>, </span>}{' '}
                              {badge.badgeString}
                            </span>
                          ))}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Service Line') ? (
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.serviceLine ? (
                            <div>{user.serviceLine.name}</div>
                          ) : null}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Service Line Subgroup') ? (
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => toggleDrawer(true, user)}
                        >
                          {user.serviceLineSubGroup ? (
                            <div>{user.serviceLineSubGroup.name}</div>
                          ) : null}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            {/* Missing EHR Provider IDs Modal */}
            <Modal
              open={missingEhrProviderIdsModalOpen}
              onClose={() => closeMissingEhrProviderIds()}
            >
              <div
                className={classes.newUserImportModal}
                style={{ maxHeight: '90vh', overflow: 'scroll' }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <Typography variant="h4" gutterBottom component="h2">
                      Missing EHR Provider ID(s)
                    </Typography>
                  </div>
                  <div style={{ fontWeight: 'bold', color: 'red' }}>
                    <Typography
                      onClick={() => {
                        setMissingEhrProviderIdsModalOpen(false);
                        setMissingEhrProviderIds([]);
                      }}
                      variant="h4"
                      gutterBottom
                      component="h2"
                      style={{ cursor: 'pointer' }}
                    >
                      X
                    </Typography>
                  </div>
                </div>
                <div>
                  {missingEhrProviderIds.length > 0 ? (
                    <Paper className={classes.root}>
                      <Table
                        className={
                          classes.table + ' third-step users-first-step'
                        }
                      >
                        <TableHead className={classes.head}>
                          <TableRow>
                            <TableCell />
                            <TableCell>Provider Name</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Minute(s) Last Seen</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {missingEhrProviderIds.map((p: any, i: number) => (
                            <TableRow key={i}>
                              <TableCell
                                onClick={() => deleteMissingEhrProviderId(p)}
                                style={{
                                  color: 'red',
                                  cursor: 'pointer',
                                  fontSize: 18,
                                  fontWeight: 'bold',
                                  padding: 10,
                                  textAlign: 'center',
                                }}
                              >
                                X
                              </TableCell>
                              <TableCell>{p.ehrProviderName}</TableCell>
                              <TableCell>{p.ehrProviderId}</TableCell>
                              <TableCell>{p.minutesLastSeen}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <div>No missing IDs found</div>
                  )}
                </div>
              </div>
            </Modal>
            {/* New User Import Modal */}

            <Modal
              open={newUserImportModalOpen}
              onClose={() => onCloseNewUserImport(false)}
            >
              <div className={classes.newUserImportModal}>
                <Dialog
                  onClose={() => {
                    setImportReportDialog(false);
                    setLatestSuccesses(0);
                    setLatestFails(0);
                  }}
                  open={importReportDialog}
                >
                  {/* <div style={{width: "100%", color: "red", cursor: "pointer"}}>X</div> */}
                  <DialogTitle>Import Results</DialogTitle>
                  <DialogContent>
                    <div style={{ fontSize: 24, color: '#4FAC48' }}>
                      Success: {latestSuccesses}
                    </div>
                    <div
                      style={{
                        height: 1,
                        width: '100%',
                        backgroundColor: 'lightgray',
                      }}
                    />
                    <div style={{ fontSize: 24, color: 'red' }}>
                      Failure: {latestFails}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => {
                          setImportReportDialog(false);
                          setLatestSuccesses(0);
                          setLatestFails(0);
                        }}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 25 }}
                      >
                        Return
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
                <Typography variant="h4" gutterBottom component="h2">
                  New User(s)
                </Typography>

                <div>
                  {!completedCSVImport ? (
                    <div>
                      <Importer
                        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                        restartable={false} // optional, lets user choose to upload another file when import is complete
                        // onStart={({ file, fields, columns, skipHeaders }) => {
                        //   // optional, invoked when user has mapped columns and started import
                        //   // prepMyAppForIncomingData();
                        // }}
                        processChunk={async (rows, { startIndex }) => {
                          // console.log("Process happen", rows);
                          // this.processUsers(rows);
                          // required, receives a list of parsed objects based on defined fields and user column mapping;
                          // may be called several times if file is large
                          // (if this callback returns a promise, the widget will wait for it before parsing more data)
                          // for (row of rows) {
                          // await myAppMethod(row);
                          // }

                          rows.forEach(row => {
                            // console.log("ROW", row);
                            importedUsers.push(row);
                          });
                        }}
                        onComplete={({
                          file,
                          preview,
                          fields,
                          columnFields,
                        }) => {
                          // console.log("onComplete", importedUsers);
                          processUsers(importedUsers);

                          return new Promise(resolve => {
                            setCompletedCSVImport(true);
                            resolve(null);
                          }).then(() => {
                            // setImportedUsers([]);
                          });

                          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                          // showMyAppToastNotification();
                        }}
                        onClose={({ file, preview, fields, columnFields }) => {
                          // optional, invoked when import is done and user clicked "Finish"
                          // (if this is not specified, the widget lets the user upload another file)
                          // goToMyAppNextPage();
                        }}

                        // CSV options passed directly to PapaParse if specified:
                        // delimiter={...}
                        // newline={...}
                        // quoteChar={...}
                        // escapeChar={...}
                        // comments={...}
                        // skipEmptyLines={...}
                        // delimitersToGuess={...}
                        // chunkSize={...} // defaults to 10000
                      >
                        <ImporterField name="firstName" label="First Name" />
                        <ImporterField name="lastName" label="Last Name" />
                        <ImporterField name="userEmail" label="Email" />
                        <ImporterField
                          name="serviceLine"
                          label="Service Line"
                          optional
                        />
                        <ImporterField
                          name="serviceLineSubGroup"
                          label="Specialty"
                          optional
                        />
                        <ImporterField
                          name="npi"
                          label="EHR Provider ID"
                          optional
                        />
                      </Importer>
                      <br />
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onNewUserModal()}
                      >
                        Manually add Users
                      </Button>
                    </div>
                  ) : (
                    <div>
                      {/* <div>DONE importing!</div>
                    {importedUsers.map((user, index) => (
                      <div key={index}>{user.firstName}</div>
                    ))} */}
                      <TableContainer
                        component={Paper}
                        style={{ maxHeight: '75vh' }}
                      >
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <div>*First Name</div>
                                {firstNameErrorFound ? (
                                  <div style={{ color: 'red', fontSize: 12 }}>
                                    First Name missing
                                  </div>
                                ) : null}
                              </TableCell>
                              <TableCell>
                                <div>*Last Name</div>
                                {lastNameErrorFound ? (
                                  <div style={{ color: 'red', fontSize: 12 }}>
                                    Last Name missing
                                  </div>
                                ) : null}
                              </TableCell>
                              <TableCell>
                                <div>*Email</div>
                                {emailErrorFound ? (
                                  <div style={{ color: 'red', fontSize: 12 }}>
                                    Email is invalid or already used
                                  </div>
                                ) : null}
                                {domainErrorFound ? (
                                  <div style={{ color: 'red', fontSize: 12 }}>
                                    Email Domain is not allowed for this
                                    Organization
                                  </div>
                                ) : null}
                              </TableCell>
                              <TableCell>User Type</TableCell>
                              <TableCell>
                                <div>{organization?.serviceLineLabel}</div>
                                {/* <div style={{ fontSize: 12, color: "gray" }}>(optional)</div> */}
                                <div>
                                  {/* {console.log("Missing service lines", missingServiceLines)}
                                {console.log("Missing sub groups", missingSubGroups)} */}
                                  {missingServiceLines.map(
                                    (missingServiceLine: string, i: number) => (
                                      <div
                                        key={i}
                                        style={{ color: 'red', fontSize: 11 }}
                                      >
                                        {missingServiceLine} does not exist.
                                      </div>
                                    )
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div>
                                  {organization?.serviceLineSubGroupLabel}
                                </div>
                                {/* <div style={{ fontSize: 12, color: "gray" }}>(optional)</div> */}
                                <div>
                                  {missingSubGroups.map(
                                    (missingSubGroup: string, i: number) => (
                                      <div
                                        key={i}
                                        style={{ color: 'red', fontSize: 11 }}
                                      >
                                        {missingSubGroup} does not exist.
                                      </div>
                                    )
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>EHR Provider ID</TableCell>
                              {/* <TableCell align="center">Admin</TableCell> */}
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {importedUsers.map(user =>
                              !user.importSuccess ? (
                                <TableRow
                                  key={user.importId}
                                  style={{ height: 10 }}
                                >
                                  {/* {console.log("imported users", importedUsers)} */}
                                  <TableCell
                                    onClick={() =>
                                      deleteImportedUser(user.importId)
                                    }
                                    style={{
                                      color: 'red',
                                      cursor: 'pointer',
                                      fontSize: 18,
                                      fontWeight: 'bold',
                                      padding: 10,
                                      textAlign: 'center',
                                    }}
                                  >
                                    X
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: 0 }}
                                    component="th"
                                    scope="user"
                                  >
                                    <TextField
                                      error={user.firstNameError}
                                      required
                                      // label="First Name"
                                      className={classes.textField}
                                      value={user.firstName}
                                      onChange={event =>
                                        handleImportedUser({
                                          name: 'firstName',
                                          importId: user.importId,
                                          value: event.target.value,
                                        })
                                      }
                                      margin="dense"
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: 0 }}
                                    component="th"
                                  >
                                    <TextField
                                      error={user.lastNameError}
                                      required
                                      // label="Last Name"
                                      className={classes.textField}
                                      value={user.lastName}
                                      onChange={event =>
                                        handleImportedUser({
                                          name: 'lastName',
                                          importId: user.importId,
                                          value: event.target.value,
                                        })
                                      }
                                      margin="dense"
                                    />
                                  </TableCell>
                                  <TableCell style={{ padding: 0 }}>
                                    <TextField
                                      error={
                                        user.emailError || user.domainError
                                      }
                                      required
                                      // label="User Email"
                                      className={classes.textField}
                                      value={user.userEmail}
                                      onChange={event =>
                                        handleImportedUser({
                                          name: 'userEmail',
                                          importId: user.importId,
                                          value: event.target.value,
                                        })
                                      }
                                      margin="dense"
                                    />
                                  </TableCell>
                                  <TableCell style={{ padding: 0 }}>
                                    <Tooltip
                                      title="If staff member is anything but a provider (MA, Nurse, CA, Manager, etc.) leave type as staff. Otherwise, select provider."
                                      placement="right"
                                    >
                                      <FormControl
                                        className={classes.dropDown}
                                        margin="dense"
                                      >
                                        {/* <InputLabel shrink htmlFor="age-native-label-placeholder">
                                    Type
                                  </InputLabel> */}
                                        <NativeSelect
                                          value={user.userTypeId}
                                          // onChange={(event) => handleChange("userTypeId")}
                                          onChange={event =>
                                            handleImportedUser({
                                              name: 'userTypeId',
                                              importId: user.importId,
                                              value: event.target.value,
                                            })
                                          }
                                        >
                                          <option value={1}>Staff</option>
                                          <option value={4}>Provider</option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    {/* Service Line Input */}
                                    <div>
                                      <Autocomplete
                                        key={serviceLineKey}
                                        autoSelect
                                        value={
                                          user.serviceLine
                                            ? user.serviceLine.name
                                            : null
                                        }
                                        onChange={(e, newValue) =>
                                          selectImportedUserServiceLine(
                                            newValue,
                                            user.importId
                                          )
                                        }
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="autocomplete-serviceline"
                                        options={
                                          organization?.serviceLines ?? []
                                        }
                                        getOptionLabel={option => {
                                          // Value selected with enter, right from the input
                                          if (typeof option === 'string') {
                                            return option;
                                          }
                                          // Add "xxx" option created dynamically
                                          if (option.inputValue) {
                                            return option.inputValue;
                                          }
                                          // Regular option
                                          return option.name;
                                        }}
                                        renderOption={option => option.name}
                                        style={{ width: 200 }}
                                        freeSolo
                                        renderInput={params => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {/* {userToEdit.serviceLine ? ( */}
                                    <div>
                                      {user.serviceLine &&
                                      organization?.serviceLines ? (
                                        <>
                                          {/* {console.log("USER", user)} */}
                                          <Autocomplete
                                            key={serviceLineSubGroupKey}
                                            autoSelect
                                            value={
                                              user.serviceLineSubGroup
                                                ? user.serviceLineSubGroup.name
                                                : null
                                            }
                                            onChange={(event, newValue) => {
                                              autoCompleteSelectImportedUserServiceLineSubGroup(
                                                newValue,
                                                user.importId
                                              );
                                            }}
                                            filterOptions={(
                                              options,
                                              params
                                            ) => {
                                              const filtered = filter(
                                                options,
                                                params
                                              );
                                              const cleanedOptions: any[] = [];

                                              filtered.forEach(
                                                (option: any) => {
                                                  if (user.serviceLine) {
                                                    if (
                                                      option.parentServiceLine ===
                                                      user.serviceLine.name
                                                    ) {
                                                      cleanedOptions.push({
                                                        ...option,
                                                        newSubGroup: false,
                                                      });
                                                    }
                                                  }
                                                }
                                              );

                                              return cleanedOptions;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            options={
                                              selectableServiceLineSubGroups
                                            }
                                            getOptionLabel={option => {
                                              // Value selected with enter, right from the input
                                              if (typeof option === 'string') {
                                                return option;
                                              }
                                              // Add "xxx" option created dynamically
                                              if (option.inputValue) {
                                                return option.inputValue;
                                              }
                                              // Regular option
                                              return option.name;
                                            }}
                                            renderOption={option => option.name}
                                            style={{ width: 200 }}
                                            freeSolo
                                            renderInput={params => (
                                              <TextField {...params} />
                                            )}
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                    {/* ) : null} */}
                                  </TableCell>

                                  <TableCell
                                    style={{ padding: 0 }}
                                    component="th"
                                  >
                                    {user.userTypeId === 4 ? (
                                      <TextField
                                        className={classes.textField}
                                        value={user.npi}
                                        onChange={event =>
                                          handleImportedUser({
                                            name: 'npi',
                                            importId: user.importId,
                                            value: event?.target?.value,
                                          })
                                        }
                                        margin="dense"
                                      />
                                    ) : null}
                                  </TableCell>

                                  <TableCell></TableCell>
                                </TableRow>
                              ) : null
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <div style={{ marginTop: 25, float: 'right' }}>
                        <Button
                          onClick={() => onAddRow()}
                          style={{ marginRight: 25 }}
                          variant="contained"
                        >
                          Add User
                        </Button>
                        {importsRemaining === 0 ? (
                          <Button
                            onClick={() => onCloseNewUserImport(false)}
                            variant="contained"
                            color="primary"
                          >
                            Finish
                          </Button>
                        ) : (
                          <Button
                            onClick={() => onImportUsers()}
                            variant="contained"
                            color="primary"
                          >
                            Create User(s)
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
            {/* New User Modal */}
            <Modal
              open={newUserModalOpen}
              onClose={() => onCloseNewUserModal()}
            >
              <div
                className={classes.newUserModal}
                style={{ maxHeight: '95vh', overflow: 'scroll' }}
              >
                <Typography variant="h4" gutterBottom component="h2">
                  New User
                </Typography>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        {userToEdit ? (
                          <div>
                            {index === 0 ? (
                              <div>
                                {!uploadedFile ? (
                                  <div>
                                    <div className="imageUploadContainer">
                                      <Tooltip
                                        title="Click here to select a picture from your computer for the staff member."
                                        placement="right"
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <label htmlFor="contained-button-file">
                                          <Avatar
                                            className={classes.bigAvatar}
                                            alt=""
                                            src={
                                              userToEdit.userImageUrl
                                                ? userToEdit.userImageUrl
                                                : organization?.orgImageUrl
                                            }
                                          />
                                          <div
                                            className={
                                              userImageError
                                                ? 'imageUploadOverlay imageUploadError'
                                                : 'imageUploadOverlay'
                                            }
                                          >
                                            <AddIcon
                                              className="imageUploadIcon"
                                              style={{ fontSize: 35 }}
                                            />
                                          </div>
                                        </label>
                                      </Tooltip>
                                    </div>

                                    <div>
                                      <span
                                        style={{ color: 'gray', fontSize: 12 }}
                                      >
                                        (Ctrl+V to paste from clipboard)
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div />
                                )}

                                <input
                                  id="contained-button-file"
                                  style={{ display: 'none' }}
                                  type="file"
                                  accept="image/*;capture=camera"
                                  // type="file"
                                  // type="image"
                                  alt=""
                                  // onChange={this.fileSelectedHandler}
                                  onInput={fileSelectedHandler}
                                  onClick={closeEditImage}
                                />
                                {/* Cropper, checks if their is an uploaded file to edit, otherwise allow edit of existing image */}
                                {imageEditMode ? (
                                  <div>
                                    {uploadedFile ? (
                                      <div>
                                        <Cropper
                                          ref={cropperRef}
                                          src={uploadedFile}
                                          style={{ height: 200, width: 200 }}
                                          checkCrossOrigin={false}
                                          aspectRatio={1}
                                          autoCropArea={1}
                                          guides={true}
                                          crop={() => onCrop()}
                                        />
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            width: 200,
                                            padding: 10,
                                          }}
                                        >
                                          <RotateRightIcon
                                            className={classes.rotateImageIcon}
                                            onClick={() => onRotateImage()}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <Cropper
                                          ref={cropperRef}
                                          src={userToEdit.userImageUrl}
                                          style={{ height: 200, width: 200 }}
                                          aspectRatio={1}
                                          autoCropArea={1}
                                          guides={true}
                                          crop={() => onCrop()}
                                        />
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            width: 200,
                                            padding: 10,
                                          }}
                                        >
                                          <RotateRightIcon
                                            className={classes.rotateImageIcon}
                                            onClick={() => onRotateImage()}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div />
                                )}
                                <canvas
                                  style={{ display: 'none' }}
                                  id="imageCanvas"
                                />
                              </div>
                            ) : (
                              <div />
                            )}
                            {index === 1 ? (
                              <div>
                                <TextField
                                  error={firstNameError}
                                  required
                                  label="First Name"
                                  className={classes.textField}
                                  value={userToEdit.firstName}
                                  onChange={event =>
                                    handleChange({
                                      name: 'firstName',
                                      value: event.target.value,
                                    })
                                  }
                                  margin="normal"
                                />
                                <br />
                                <TextField
                                  error={lastNameError}
                                  required
                                  label="Last Name"
                                  className={classes.textField}
                                  value={userToEdit.lastName}
                                  onChange={event =>
                                    handleChange({
                                      name: 'lastName',
                                      value: event.target.value,
                                    })
                                  }
                                  margin="normal"
                                />
                                <br />
                                <Tooltip
                                  title="This will be used to log into the Flowstation when the user accesses it from a computer."
                                  placement="right"
                                >
                                  <TextField
                                    error={userEmailError || domainError}
                                    disabled={!newUser}
                                    required
                                    label="Email"
                                    className={classes.textField}
                                    value={userToEdit.userEmail}
                                    onChange={event =>
                                      handleChange({
                                        name: 'userEmail',
                                        value: event.target.value,
                                      })
                                    }
                                    margin="normal"
                                  />
                                </Tooltip>
                              </div>
                            ) : (
                              <div />
                            )}
                            {index === 2 ? (
                              <div>
                                <Tooltip
                                  title="If staff member is anything but a provider (MA, Nurse, CA, Manager, etc.) leave type as staff. Otherwise, select provider"
                                  placement="right"
                                >
                                  <FormControl
                                    className={classes.dropDown}
                                    margin="normal"
                                  >
                                    <InputLabel
                                      shrink
                                      htmlFor="age-native-label-placeholder"
                                    >
                                      Type
                                    </InputLabel>
                                    <NativeSelect
                                      value={userToEdit.userTypeId}
                                      onChange={event =>
                                        handleChange({
                                          name: 'userTypeId',
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value={1}>Staff</option>
                                      <option value={4}>Provider</option>
                                    </NativeSelect>
                                  </FormControl>
                                </Tooltip>

                                {/* {userToEdit.userTypeId === 4 ? (
                                    <TextField
                                      error={npiError}
                                      label="EHR Provider ID" // Manual
                                      className={classes.textField}
                                      value={userToEdit.npi ? userToEdit.npi : ""}
                                      onChange={(event) => handleChange("npi")}
                                      margin="normal"
                                    />
                                  ) : null} */}
                                {userToEdit.userTypeId === 4 ? (
                                  <div>
                                    <FormControl
                                      className={classes.textField}
                                      margin="normal"
                                    >
                                      <div className={classes.badgesTitle}>
                                        EHR Provider Ids:
                                      </div>
                                      {userToEdit.ehrProviderIds ? (
                                        <div>
                                          {userToEdit.ehrProviderIds.map(
                                            (ehrId: string, i: any) => (
                                              <div
                                                key={i}
                                                className={classes.badgeNumber}
                                              >
                                                <Button
                                                  className={
                                                    classes.deleteButton
                                                  }
                                                  onClick={() =>
                                                    deleteEhrProviderId(ehrId)
                                                  }
                                                >
                                                  x
                                                </Button>{' '}
                                                {ehrId}{' '}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div />
                                      )}
                                    </FormControl>
                                    <br />
                                    <TextField
                                      label="New EHR Provider ID"
                                      style={{ marginTop: 0 }}
                                      className={classes.textField}
                                      value={newEhrProviderId}
                                      onChange={handleNewEhrProviderId}
                                      margin="normal"
                                    />
                                    <br />
                                    <Button
                                      color="primary"
                                      disabled={newEhrProviderId.length === 0}
                                      onClick={addNewEhrProviderId}
                                    >
                                      Add New EHR Provider ID
                                    </Button>
                                  </div>
                                ) : null}

                                <div>
                                  <FormControl
                                    className={classes.dropDown}
                                    margin="normal"
                                    style={{ width: '185px' }}
                                  >
                                    <InputLabel
                                      shrink
                                      htmlFor="age-native-label-placeholder"
                                    >
                                      {organization?.serviceLineLabel}
                                    </InputLabel>
                                    <NativeSelect
                                      value={
                                        userToEdit.serviceLine
                                          ? userToEdit.serviceLine.id
                                          : ''
                                      }
                                      // onChange={(event) => handleChange("serviceLine")}
                                      onChange={e => selectServiceLine(e)}
                                    >
                                      <option value="" />
                                      {(organization?.serviceLines ?? []).map(
                                        serviceLine => {
                                          return (
                                            <option
                                              key={serviceLine.id}
                                              value={serviceLine.id}
                                            >
                                              {serviceLine.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </NativeSelect>
                                  </FormControl>
                                </div>

                                {userToEdit.serviceLine ? (
                                  <div>
                                    <FormControl
                                      className={classes.dropDown}
                                      margin="normal"
                                      style={{ width: '185px' }}
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="age-native-label-placeholder"
                                      >
                                        {organization?.serviceLineSubGroupLabel}
                                      </InputLabel>
                                      <NativeSelect
                                        value={
                                          userToEdit.serviceLineSubGroup
                                            ? userToEdit.serviceLineSubGroup.id
                                            : ''
                                        }
                                        // onChange={(event) => handleChange("serviceLineSubGroup")}
                                        onChange={e =>
                                          selectServiceLineSubGroup(
                                            e,
                                            subGroups
                                          )
                                        }
                                      >
                                        <option value="" />
                                        {subGroups.map(serviceLineSubGroup => {
                                          return (
                                            <option
                                              key={serviceLineSubGroup.id}
                                              value={serviceLineSubGroup.id}
                                            >
                                              {serviceLineSubGroup.name}
                                            </option>
                                          );
                                        })}
                                      </NativeSelect>
                                    </FormControl>
                                  </div>
                                ) : null}

                                <Accordion
                                  className={
                                    classes.advancedSettingsExpansionPanel
                                  }
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                  >
                                    <Typography>Permissions</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div>
                                      {permissionGroups.map(
                                        (groupName: string, index: number) => (
                                          <div key={index}>
                                            {/* Header */}
                                            <div>
                                              {groupName === 'SyncAdmin' ||
                                              groupName === 'Tablets' ? (
                                                <div>
                                                  {canDo(
                                                    ['SyncAdmin'],
                                                    loggedInUserData
                                                  ) ? (
                                                    <div>
                                                      <strong>
                                                        {groupName}{' '}
                                                      </strong>
                                                      (
                                                      {determineCheckAll(
                                                        groupName,
                                                        groupedPermissions
                                                      ) ? (
                                                        <span
                                                          onClick={() =>
                                                            checkAllPermission(
                                                              groupName,
                                                              true
                                                            )
                                                          }
                                                          style={{
                                                            textDecoration:
                                                              'underline',
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          check all
                                                        </span>
                                                      ) : (
                                                        <span
                                                          onClick={() =>
                                                            checkAllPermission(
                                                              groupName,
                                                              false
                                                            )
                                                          }
                                                          style={{
                                                            textDecoration:
                                                              'underline',
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          un-check all
                                                        </span>
                                                      )}
                                                      )
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <div>
                                                  <strong>{groupName} </strong>(
                                                  {determineCheckAll(
                                                    groupName,
                                                    groupedPermissions
                                                  ) ? (
                                                    <span
                                                      onClick={() =>
                                                        checkAllPermission(
                                                          groupName,
                                                          true
                                                        )
                                                      }
                                                      style={{
                                                        textDecoration:
                                                          'underline',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      check all
                                                    </span>
                                                  ) : (
                                                    <span
                                                      onClick={() =>
                                                        checkAllPermission(
                                                          groupName,
                                                          false
                                                        )
                                                      }
                                                      style={{
                                                        textDecoration:
                                                          'underline',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      un-check all
                                                    </span>
                                                  )}
                                                  )
                                                </div>
                                              )}
                                            </div>

                                            {/* Checkboxes */}
                                            {groupedPermissions[groupName].map(
                                              (
                                                groupedPermission: IPermission,
                                                i: number
                                              ) => (
                                                <div key={i}>
                                                  <div>
                                                    {groupName ===
                                                      'SyncAdmin' ||
                                                    groupName === 'Tablets' ? (
                                                      <div>
                                                        {canDo(
                                                          ['SyncAdmin'],
                                                          loggedInUserData
                                                        ) ? (
                                                          <div>
                                                            <Tooltip
                                                              title={
                                                                groupedPermission.permissionDescription
                                                              }
                                                              placement="right"
                                                            >
                                                              <div
                                                                onClick={() =>
                                                                  togglePermission(
                                                                    groupedPermission
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    'pointer',
                                                                }}
                                                              >
                                                                <Checkbox
                                                                  classes={{
                                                                    root: classes.checkBox,
                                                                    checked:
                                                                      classes.checked,
                                                                  }}
                                                                  checked={_.some(
                                                                    userToEdit.userPermissions,
                                                                    [
                                                                      'permissionName',
                                                                      groupedPermission.permissionName,
                                                                    ]
                                                                  )}
                                                                />
                                                                {
                                                                  groupedPermission.permissionName
                                                                }
                                                              </div>
                                                            </Tooltip>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <Tooltip
                                                          title={
                                                            groupedPermission.permissionDescription
                                                          }
                                                          placement="right"
                                                        >
                                                          <div
                                                            onClick={() =>
                                                              togglePermission(
                                                                groupedPermission
                                                              )
                                                            }
                                                            style={{
                                                              cursor: 'pointer',
                                                            }}
                                                          >
                                                            <Checkbox
                                                              classes={{
                                                                root: classes.checkBox,
                                                                checked:
                                                                  classes.checked,
                                                              }}
                                                              checked={_.some(
                                                                userToEdit.userPermissions,
                                                                [
                                                                  'permissionName',
                                                                  groupedPermission.permissionName,
                                                                ]
                                                              )}
                                                            />
                                                            {
                                                              groupedPermission.permissionName
                                                            }
                                                          </div>
                                                        </Tooltip>
                                                      </div>
                                                    )}
                                                  </div>
                                                  {/* ) : null} */}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </AccordionDetails>
                                </Accordion>

                                {/* <br /> */}
                                <div
                                  onClick={() => {
                                    setUserToEdit({
                                      ...userToEdit,
                                      isActive: !userToEdit.isActive,
                                    });
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Checkbox
                                    classes={{
                                      root: classes.checkBox,
                                      checked: classes.checked,
                                    }}
                                    checked={userToEdit.isActive}
                                  />
                                  Is Active
                                </div>
                              </div>
                            ) : (
                              <div />
                            )}
                            {index === 3 &&
                            canDo(['Update Badges'], loggedInUserData) ? (
                              <Beacons
                                beaconNumberObject={beaconNumberObject}
                                setBeaconNumberObject={setBeaconNumberObject}
                                classes={classes}
                                deleteBadge={onDeleteBadge}
                                onAddBeacon={values => {
                                  console.log({ values });
                                }}
                                handleNewBadge={handleNewBadge}
                                displayBadgeExpiration={displayBadgeExpiration}
                                newBadgeExpiration={newBadgeExpiration}
                                restrictedBadgeNumber={restrictedBadgeNumber}
                                handleBadgeExpiration={handleBadgeExpiration}
                                nextFocusObject={nextFocusObject}
                                setNextFocusObject={setNextFocusObject}
                                userBadages={userToEdit?.appUserBadges}
                              />
                            ) : (
                              <div />
                            )}
                          </div>
                        ) : (
                          <div />
                        )}

                        <div>
                          <br />
                          <div
                            className={loading ? classes.loading : classes.gone}
                          >
                            <CircularProgress
                              className={classes.progress}
                              color="secondary"
                            />
                          </div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                          {activeStep === steps.length - 1 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleNewBadge({
                                  overwrite: false,
                                })
                              }
                            >
                              Finish
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          )}
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </Modal>
            {/* Drawer to edit selected user */}
            <Drawer
              anchor="right"
              open={right}
              onClose={() => toggleDrawer(false, userToEdit)}
            >
              {userToEdit ? (
                <div className={classes.drawer}>
                  <div
                    className={classes.closeButton}
                    onClick={() => toggleDrawer(false, userToEdit)}
                  >
                    <CloseButton />
                  </div>
                  {newUser ? <div /> : <h2>Editing {userToEdit.firstName}</h2>}
                  <br />

                  {canDo(['Update Photos'], loggedInUserData) ? (
                    <div>
                      <div className="imageUploadContainer">
                        <Tooltip
                          title="Click here to select a picture from your computer for the staff member."
                          placement="right"
                          style={{ cursor: 'pointer' }}
                        >
                          <label htmlFor="contained-button-file">
                            <Avatar
                              className={classes.bigAvatar}
                              alt=""
                              src={userToEdit.userImageUrl}
                            />
                            <div
                              className={
                                userImageError
                                  ? 'imageUploadOverlay imageUploadError'
                                  : 'imageUploadOverlay'
                              }
                            >
                              <AddIcon
                                className="imageUploadIcon"
                                style={{ fontSize: 35 }}
                              />
                            </div>
                          </label>
                        </Tooltip>
                      </div>

                      <label htmlFor="contained-button-file">
                        {newUser ? (
                          <div style={{ paddingLeft: 22, cursor: 'pointer' }}>
                            Add Image
                          </div>
                        ) : (
                          <div />
                        )}
                      </label>
                      <input
                        id="contained-button-file"
                        style={{ display: 'none' }}
                        type="file"
                        // onChange={this.fileSelectedHandler}
                        onInput={fileSelectedHandler}
                        onClick={closeEditImage}
                      />

                      {newUser || hideEditImageButton ? (
                        <div />
                      ) : (
                        <div>
                          <div>
                            <span style={{ color: 'gray', fontSize: 12 }}>
                              (Ctrl+V to paste from clipboard)
                            </span>
                          </div>
                          <Button onClick={() => editImage()} color="primary">
                            Edit Image
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : null}

                  {/* Cropper, checks if their is an uploaded file to edit, otherwise allow edit of existing image */}
                  {imageEditMode ? (
                    <div>
                      {uploadedFile ? (
                        <div>
                          <Cropper
                            ref={cropperRef}
                            // @ts-ignore
                            src={uploadedFile}
                            style={{ height: 200, width: 200 }}
                            checkCrossOrigin={false}
                            aspectRatio={1}
                            autoCropArea={1}
                            guides={true}
                            crop={() => onCrop()}
                          />
                          <div
                            style={{
                              textAlign: 'center',
                              width: 200,
                              padding: 10,
                            }}
                          >
                            <RotateRightIcon
                              className={classes.rotateImageIcon}
                              onClick={() => onRotateImage()}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Cropper
                            ref={cropperRef}
                            src={userToEdit.userImageUrl}
                            style={{ height: 200, width: 200 }}
                            aspectRatio={1}
                            autoCropArea={1}
                            guides={true}
                            crop={() => onCrop()}
                          />
                          <div
                            style={{
                              textAlign: 'center',
                              width: 200,
                              padding: 10,
                            }}
                          >
                            <RotateRightIcon
                              className={classes.rotateImageIcon}
                              onClick={() => onRotateImage()}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div />
                  )}

                  <canvas style={{ display: 'none' }} id="imageCanvas" />

                  {canDo(['Update Users'], loggedInUserData) ? (
                    <div>
                      <div
                        onClick={() => {
                          setUserToEdit({
                            ...userToEdit,
                            isActive: !userToEdit.isActive,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          checked={userToEdit.isActive}
                        />
                        Is Active
                      </div>

                      {canDo(['SyncAdmin'], loggedInUserData) ? (
                        <div
                          style={{
                            marginLeft: 8,
                            marginRight: 8,
                            marginTop: 16,
                            marginBottom: 8,
                          }}
                        >
                          <span
                            style={{
                              fontSize: 13,
                              color: '#757575',
                              fontWeight: 400,
                            }}
                          >
                            User ID
                          </span>
                          <div>{userToEdit.id}</div>
                        </div>
                      ) : null}

                      {/* <FormControl className={classes.textField} style={{ width: 185 }} margin="normal">
                          <InputLabel shrink htmlFor="age-native-label-placeholder">
                            Default Department
                          </InputLabel>
                          <NativeSelect
                            disabled
                            value={0}
                          >
                          
                            {orgDepartments.map((department: IDepartment) => (
                              <option key={department.id} value={department.id}>
                                {department.siteName + " " + department.deptName}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl> */}

                      <div
                        style={{
                          marginLeft: 8,
                          marginRight: 8,
                          marginTop: 16,
                          marginBottom: 0,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 13,
                            color: '#757575',
                            fontWeight: 400,
                          }}
                        >
                          Assigned Departments
                        </span>
                        {userToEdit.lastAssignedDeptIds?.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              flexDirection: 'column',
                            }}
                          >
                            {orgDepartments.map(
                              (dept: IDepartment, key: number) => (
                                <div key={key}>
                                  {userToEdit?.lastAssignedDeptIds?.includes(
                                    dept.id
                                  ) ? (
                                    <div>
                                      <Chip
                                        disabled={
                                          userToEdit.lockLastAssignedDeptIds
                                        }
                                        label={
                                          dept.siteName + ' ' + dept.deptName
                                        }
                                        style={{ margin: '5px 5px 5px 0px' }}
                                        onDelete={() => {
                                          setUserToEdit({
                                            ...userToEdit,
                                            lastAssignedDeptIds:
                                              userToEdit.lastAssignedDeptIds.filter(
                                                (deptId: string) =>
                                                  deptId !== dept.id
                                              ),
                                          });
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div>None</div>
                        )}
                      </div>

                      {!userToEdit.lockLastAssignedDeptIds ? (
                        <Autocomplete
                          id="department-search"
                          key={departmentSearchKey}
                          autoHighlight
                          // options={athenaDepartments}
                          options={orgDepartments.filter((dept: any) => {
                            // return !athenaConfigToEdit.deptsToInclude.includes(parseInt(dept.departmentid));
                            return !userToEdit?.lastAssignedDeptIds?.includes(
                              dept.id
                            );
                          })}
                          getOptionLabel={dept =>
                            dept.siteName + ' ' + dept.deptName
                          }
                          style={{
                            marginBottom: 10,
                            marginLeft: 8,
                            width: 185,
                          }}
                          onChange={(event: any, department: any) => {
                            if (department) {
                              // this.selectAthenaDept(parseInt(department.departmentid));

                              setDepartmentSearchKey(departmentSearchKey + 1);
                              setUserToEdit({
                                ...userToEdit,
                                lastAssignedDeptIds: [
                                  ...userToEdit.lastAssignedDeptIds,
                                  department.id,
                                ],
                              });
                            }
                          }}
                          renderInput={params => (
                            <TextField {...params} label="Search Department" />
                          )}
                        />
                      ) : null}

                      <div
                        onClick={() => {
                          setUserToEdit({
                            ...userToEdit,
                            lockLastAssignedDeptIds:
                              !userToEdit.lockLastAssignedDeptIds,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          checked={userToEdit.lockLastAssignedDeptIds}
                        />
                        Lock Last Assigned Departments
                      </div>

                      {organization?.allowSsoOnly ? (
                        <div
                          onClick={() =>
                            setUserToEdit({
                              ...userToEdit,
                              overrideSsoOnly: !userToEdit.overrideSsoOnly,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <Checkbox
                            classes={{
                              root: classes.checkBox,
                              checked: classes.checked,
                            }}
                            checked={userToEdit.overrideSsoOnly}
                          />
                          Override SSO Only
                        </div>
                      ) : null}

                      <div
                        onClick={() => {
                          setUserToEdit({
                            ...userToEdit,
                            excludeUserFromData:
                              !userToEdit.excludeUserFromData,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          checked={userToEdit.excludeUserFromData}
                        />
                        Exclude from Reporting
                      </div>

                      {/* <br /> */}
                      <TextField
                        error={firstNameError}
                        required
                        label="First Name"
                        className={classes.textField}
                        value={userToEdit.firstName}
                        onChange={event =>
                          handleChange({
                            name: 'firstName',
                            value: event.target.value,
                          })
                        }
                        margin="normal"
                      />
                      <br />
                      <TextField
                        error={lastNameError}
                        required
                        label="Last Name"
                        className={classes.textField}
                        value={userToEdit.lastName}
                        onChange={event =>
                          handleChange({
                            name: 'lastName',
                            value: event.target.value,
                          })
                        }
                        margin="normal"
                      />
                      <br />
                      <Tooltip
                        title="This will be used to log into the Flowstation when the user accesses it from a computer."
                        placement="right"
                      >
                        <TextField
                          error={userEmailError || domainError}
                          // disabled={!newUser}
                          required
                          label="Email"
                          className={classes.textField}
                          value={userToEdit.userEmail}
                          onChange={event =>
                            handleChange({
                              name: 'userEmail',
                              value: event.target.value,
                            })
                          }
                          margin="normal"
                        />
                      </Tooltip>
                      <br />
                      <Tooltip
                        title="If staff member is anything but a provider (MA, Nurse, CA, Manager, etc.) leave type as staff. Otherwise, select provider"
                        placement="right"
                      >
                        <FormControl
                          className={classes.dropDown}
                          margin="normal"
                          style={{ width: '185px' }}
                        >
                          <InputLabel
                            shrink
                            htmlFor="age-native-label-placeholder"
                          >
                            Type
                          </InputLabel>
                          <NativeSelect
                            value={userToEdit.userTypeId}
                            onChange={event =>
                              handleChange({
                                name: 'userTypeId',
                                value: event.target.value,
                              })
                            }
                          >
                            <option value={1}>Staff</option>
                            <option value={4}>Provider</option>
                          </NativeSelect>
                        </FormControl>
                      </Tooltip>
                      <br />

                      {/* {console.log("user to edit", userToEdit)} */}
                      <div>
                        <FormControl
                          className={classes.dropDown}
                          margin="normal"
                          style={{ width: '185px' }}
                        >
                          <InputLabel
                            shrink
                            htmlFor="age-native-label-placeholder"
                          >
                            {organization?.serviceLineLabel}
                          </InputLabel>
                          <NativeSelect
                            value={
                              userToEdit.serviceLine
                                ? userToEdit.serviceLine.id
                                : ''
                            }
                            // onChange={(event) => handleChange("serviceLine")}
                            onChange={e => selectServiceLine(e)}
                          >
                            <option value="" />
                            {(organization?.serviceLines ?? []).map(
                              serviceLine => {
                                return (
                                  <option
                                    key={serviceLine.id}
                                    value={serviceLine.id}
                                  >
                                    {serviceLine.name}
                                  </option>
                                );
                              }
                            )}
                          </NativeSelect>
                        </FormControl>
                      </div>

                      {userToEdit.serviceLine ? (
                        <div>
                          <FormControl
                            className={classes.dropDown}
                            margin="normal"
                            style={{ width: '185px' }}
                          >
                            <InputLabel
                              shrink
                              htmlFor="age-native-label-placeholder"
                            >
                              {organization?.serviceLineSubGroupLabel}
                            </InputLabel>
                            <NativeSelect
                              value={
                                userToEdit.serviceLineSubGroup
                                  ? userToEdit.serviceLineSubGroup.id
                                  : ''
                              }
                              // onChange={(event) => handleChange("serviceLineSubGroup")}
                              onChange={e =>
                                selectServiceLineSubGroup(e, subGroups)
                              }
                            >
                              <option value="" />
                              {subGroups.map(serviceLineSubGroup => {
                                return (
                                  <option
                                    key={serviceLineSubGroup.id}
                                    value={serviceLineSubGroup.id}
                                  >
                                    {serviceLineSubGroup.name}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </div>
                      ) : null}

                      {/* {userToEdit.userTypeId === 4 ? (
                          <TextField
                            error={npiError}
                            label="EHR Provider ID" // Editing existing
                            className={classes.textField}
                            value={userToEdit.npi ? userToEdit.npi : ""}
                            onChange={(event) => handleChange("npi")}
                            margin="normal"
                          />
                        ) : null}
                        <br /> */}

                      <TextField
                        className={classes.textField}
                        margin="normal"
                        label="EHR Name"
                        value={userToEdit.ehrName ? userToEdit.ehrName : ''}
                        onChange={e => {
                          setUserToEdit({
                            ...userToEdit,
                            ehrName: e.target.value,
                          });
                        }}
                      />
                      {userToEdit.userTypeId === 4 ? (
                        <div>
                          <FormControl
                            className={classes.textField}
                            margin="normal"
                          >
                            <div className={classes.badgesTitle}>
                              EHR Provider Ids:
                            </div>
                            {userToEdit.ehrProviderIds ? (
                              <div>
                                {userToEdit.ehrProviderIds.map(
                                  (ehrId: string, i: any) => (
                                    <div
                                      key={i}
                                      className={classes.badgeNumber}
                                    >
                                      <Button
                                        className={classes.deleteButton}
                                        onClick={() =>
                                          deleteEhrProviderId(ehrId)
                                        }
                                      >
                                        x
                                      </Button>{' '}
                                      {ehrId}{' '}
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div />
                            )}
                          </FormControl>
                          <br />
                          <TextField
                            label="New EHR Provider ID"
                            style={{ marginTop: 0 }}
                            className={classes.textField}
                            value={newEhrProviderId}
                            onChange={handleNewEhrProviderId}
                            margin="normal"
                          />
                          <br />
                          <Button
                            color="primary"
                            disabled={newEhrProviderId.length === 0}
                            onClick={addNewEhrProviderId}
                          >
                            Add New EHR Provider ID
                          </Button>
                        </div>
                      ) : null}

                      <br />
                      <div>
                        {!newUser ? (
                          <div>
                            {(!organization?.allowSsoOnly ||
                              userToEdit.overrideSsoOnly) && (
                              <Button
                                className={classes.resetPasswordButton}
                                onClick={() =>
                                  resetPassword(userToEdit.userEmail)
                                }
                                component="label"
                                color="primary"
                              >
                                Reset Password
                              </Button>
                            )}
                            <br />
                            <br />
                            <Button
                              variant="contained"
                              onClick={() => {
                                setShowUserNotifications(true);
                              }}
                            >
                              Manage User Notifications
                            </Button>
                            <br />
                            <br />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={showConfirmationDetails}
                            >
                              Show Confirmation details
                            </Button>

                            {confirmationDetails ? (
                              <div style={{ marginTop: 10 }}>
                                <div
                                  style={{
                                    color: 'gray',
                                    border: '1px solid gray',
                                    padding: 5,
                                    borderRadius: 5,
                                  }}
                                >
                                  {confirmationDetails.isPhoneNumberVerified ? (
                                    <div style={{ color: '#4CB848' }}>
                                      Phone Number Verified
                                    </div>
                                  ) : (
                                    <div>
                                      <div style={{ color: 'red' }}>
                                        Phone Number NOT Verified
                                      </div>
                                      - Verification Code:{' '}
                                      <strong>
                                        {
                                          confirmationDetails.phoneNumberVerificationCode
                                        }
                                      </strong>
                                    </div>
                                  )}
                                  <div />- Phone Number:{' '}
                                  {confirmationDetails.phoneNumber}
                                  <div />- Ext:{' '}
                                  {confirmationDetails.phoneNumberExtension}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>

                      <Accordion
                        className={classes.advancedSettingsExpansionPanel}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            Permissions ({userToEdit.userPermissions.length})
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {permissionGroups.map(
                              (groupName: string, index: number) => (
                                <div key={index}>
                                  {/* Header */}
                                  <div>
                                    {groupName === 'SyncAdmin' ||
                                    groupName === 'Tablets' ? (
                                      <div>
                                        {canDo(
                                          ['SyncAdmin'],
                                          loggedInUserData
                                        ) ? (
                                          <div>
                                            <strong>{groupName} </strong>(
                                            {determineCheckAll(
                                              groupName,
                                              groupedPermissions
                                            ) ? (
                                              <span
                                                onClick={() =>
                                                  checkAllPermission(
                                                    groupName,
                                                    true
                                                  )
                                                }
                                                style={{
                                                  textDecoration: 'underline',
                                                  cursor: 'pointer',
                                                }}
                                              >
                                                check all
                                              </span>
                                            ) : (
                                              <span
                                                onClick={() =>
                                                  checkAllPermission(
                                                    groupName,
                                                    false
                                                  )
                                                }
                                                style={{
                                                  textDecoration: 'underline',
                                                  cursor: 'pointer',
                                                }}
                                              >
                                                un-check all
                                              </span>
                                            )}
                                            )
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : (
                                      <div>
                                        <strong>{groupName} </strong>(
                                        {determineCheckAll(
                                          groupName,
                                          groupedPermissions
                                        ) ? (
                                          <span
                                            onClick={() =>
                                              checkAllPermission(
                                                groupName,
                                                true
                                              )
                                            }
                                            style={{
                                              textDecoration: 'underline',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            check all
                                          </span>
                                        ) : (
                                          <span
                                            onClick={() =>
                                              checkAllPermission(
                                                groupName,
                                                false
                                              )
                                            }
                                            style={{
                                              textDecoration: 'underline',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            un-check all
                                          </span>
                                        )}
                                        )
                                      </div>
                                    )}
                                  </div>
                                  {/* ) : null} */}

                                  {/* Checkboxes */}
                                  {groupedPermissions[groupName].map(
                                    (
                                      groupedPermission: IPermission,
                                      i: number
                                    ) => (
                                      <div key={i}>
                                        {/* {_.some(loggedInUserData.userPermissions, ["permissionName", groupedPermission.permissionName]) ||
                                  canDo(["SyncAdmin"], loggedInUserData) ? ( */}
                                        <div>
                                          {groupName === 'SyncAdmin' ||
                                          groupName === 'Tablets' ? (
                                            <div>
                                              {canDo(
                                                ['SyncAdmin'],
                                                loggedInUserData
                                              ) ? (
                                                <div>
                                                  <Tooltip
                                                    title={
                                                      groupedPermission.permissionDescription
                                                    }
                                                    placement="right"
                                                  >
                                                    <div
                                                      onClick={() =>
                                                        togglePermission(
                                                          groupedPermission
                                                        )
                                                      }
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <Checkbox
                                                        classes={{
                                                          root: classes.checkBox,
                                                          checked:
                                                            classes.checked,
                                                        }}
                                                        checked={_.some(
                                                          userToEdit.userPermissions,
                                                          [
                                                            'permissionName',
                                                            groupedPermission.permissionName,
                                                          ]
                                                        )}
                                                      />
                                                      {
                                                        groupedPermission.permissionName
                                                      }
                                                    </div>
                                                  </Tooltip>
                                                </div>
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div>
                                              <Tooltip
                                                title={
                                                  groupedPermission.permissionDescription
                                                }
                                                placement="right"
                                              >
                                                <div
                                                  onClick={() =>
                                                    togglePermission(
                                                      groupedPermission
                                                    )
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Checkbox
                                                    classes={{
                                                      root: classes.checkBox,
                                                      checked: classes.checked,
                                                    }}
                                                    checked={_.some(
                                                      userToEdit.userPermissions,
                                                      [
                                                        'permissionName',
                                                        groupedPermission.permissionName,
                                                      ]
                                                    )}
                                                  />
                                                  {
                                                    groupedPermission.permissionName
                                                  }
                                                </div>
                                              </Tooltip>
                                            </div>
                                          )}
                                        </div>
                                        {/* ) : null} */}
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <br />

                      {/* <Button onClick={this.reFetchSingleUser}>Test fetch user</Button> */}
                    </div>
                  ) : null}

                  <br />

                  {canDo(['Update Badges'], loggedInUserData) ? (
                    <Beacons
                      classes={classes}
                      deleteBadge={onDeleteBadge}
                      onAddBeacon={values => {
                        console.log({ values });
                      }}
                      handleNewBadge={handleNewBadge}
                      displayBadgeExpiration={displayBadgeExpiration}
                      newBadgeExpiration={newBadgeExpiration}
                      restrictedBadgeNumber={restrictedBadgeNumber}
                      handleBadgeExpiration={handleBadgeExpiration}
                      beaconNumberObject={beaconNumberObject}
                      setBeaconNumberObject={setBeaconNumberObject}
                      setNextFocusObject={setNextFocusObject}
                      nextFocusObject={nextFocusObject}
                      userBadages={userToEdit?.appUserBadges}
                    />
                  ) : null}
                  <div className={classes.saveArea}>
                    {hasModified ? (
                      <div className={classes.saveWarning}>
                        Unsaved Changes!
                      </div>
                    ) : (
                      <div />
                    )}

                    <div className={classes.container}>
                      <Button
                        className={classes.submitButton}
                        onClick={() => saveChanges()}
                        component="label"
                        color="primary"
                      >
                        {newUser ? (
                          <div>Add User</div>
                        ) : (
                          <div>Save Changes</div>
                        )}
                      </Button>

                      <div className={loading ? classes.loading : classes.gone}>
                        <CircularProgress
                          className={classes.progress}
                          color="secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </Drawer>
            {badgeWarning ? (
              <WarningSnack
                warningSnack={true}
                warningMessage="Note: Added Badge ID Doesn't Start With IR"
              />
            ) : (
              <div />
            )}
            {newUserErrorMessage ? (
              <ErrorSnack
                errorSnack={true}
                errorMessage={newUserErrorMessage}
              />
            ) : (
              <div />
            )}

            {duplicateBadgeError && (
              <ErrorSnack errorSnack={true} errorMessage="Duplicate Badge" />
            )}

            {invalidEmail ? (
              <ErrorSnack
                errorSnack={true}
                errorMessage="Please Provide a Valid Email"
              />
            ) : (
              <div />
            )}
            {userImageError ? (
              <ErrorSnack
                errorSnack={true}
                errorMessage="Please Provide a User Image"
              />
            ) : (
              <div />
            )}
            {fieldError ? (
              <ErrorSnack
                errorSnack={true}
                errorMessage="Please Fill Out All Required Fields"
              />
            ) : (
              <div />
            )}
            {domainError ? (
              <ErrorSnack
                errorSnack={true}
                errorMessage="Email Domain is not allowed for this Organization"
              />
            ) : (
              <div />
            )}
            {/* {npiError ? <ErrorSnack errorSnack={true} errorMessage="Please Provide a Valid EHR Provider ID" /> : <div />} */}
            {passwordReset ? (
              <SuccessSnack
                autoHideDuration={2000}
                successSnack={true}
                successMessage="Password has been reset!"
              />
            ) : (
              <div />
            )}
            {emailSentSuccess ? (
              <SuccessSnack
                autoHideDuration={2000}
                successSnack={true}
                successMessage="An email has been sent to your inbox!"
              />
            ) : (
              <div />
            )}
            {userHasBeenCreated ? (
              <SuccessSnack
                successSnack={true}
                successMessage="User has been created! Have new user check their email!"
              />
            ) : null}
            {/* {serviceLineHasBeenCreated ? (
              <SuccessSnack
                successSnack={true}
                successMessage={`${organization?.serviceLineLabel} created!`}
              />
            ) : null} */}
            {/* {subGroupHasBeenCreated ? (
              <SuccessSnack
                successSnack={true}
                successMessage={`${organization?.serviceLineSubGroupLabel} created!`}
              />
            ) : null} */}

            <Joyride
              run={run}
              steps={USERS_TUTORIAL_STEPS}
              stepIndex={userTutorialStepIndex}
              callback={handleJoyrideCallback}
              disableScrolling
              disableOverlayClose
              showSkipButton
              hideBackButton
              continuous
              spotlightClicks
              disableScrollParentFix
              styles={{
                options: {
                  primaryColor: '#50B848',
                },
              }}
            />
          </MuiThemeProvider>
        </div>
      ) : null}
    </div>
  );
};

// const mapStateToProps = (state: { adminData: any }) => ({
//   loggedInUserData: state.adminData.loggedInUserData,
//   usersData: state.adminData.usersData,
//   organization: state.adminData.organization,
//   instruments: state.adminData.instruments,
//   permissions: state.adminData.permissions,
// });

// export default connect(mapStateToProps, { fetchAppUsersByOrgId, fetchInstrumentsByOrgId, fetchLoggedInUser })(withStyles(styles)(Users));

export default Users;
