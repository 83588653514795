import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { useOrganizations } from '../../../../hooks/useOrganizations';
import { IOrganization, TAdminState } from '../../../../interfaces/interfaces';
import { theme, useStyles } from './styles';
import useToast from '../../../../hooks/useToast';
import { useMockOrganization } from '../../../../hooks/useMockOrganization';
import OrganizationDrawer from './OrganizationDrawer';

const Organizations: React.FC = () => {
  const loggedInUserData = useSelector(
    (state: { adminData: TAdminState }) => state.adminData?.loggedInUserData
  );
  const classes = useStyles();

  const token = localStorage.getItem('token');
  const { mockOrganization } = useMockOrganization(token || '');

  const [organizationToEdit, setOrganizationToEdit] =
    useState<IOrganization | null>(null);
  const [sortBy, setSortBy] = useState('orgName');
  const [sortDescending, setSortDescending] = useState(false);
  const [hideInactiveOrganizations, setHideInactiveOrganizations] =
    useState(true);
  const [filterString, setFilterString] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCreatingNewOrganization, setIsCreatingNewOrganization] =
    useState(false);

  const { organizations, refreshOrganizations } = useOrganizations();
  const { presentToast, ToastComponent } = useToast();

  const [filteredOrganizations, setFilteredOrganizations] = useState<
    IOrganization[]
  >([]);

  const handleMockOrganization = async (orgId: string) => {
    try {
      await mockOrganization(orgId);
      console.log('Organization mocked successfully');
    } catch (error) {
      console.error('Failed to mock organization:', error);
    }
  };

  const handleSaveComplete = useCallback(
    (message: string, isError: boolean) => {
      presentToast(isError ? 'error' : 'success', message);
    },
    [presentToast]
  );

  const handleApplyFilter = useCallback(() => {
    if (organizations) {
      let filtered = [...organizations];

      // Apply keyword filtering
      filtered = filtered.filter(obj =>
        obj.orgName.toUpperCase().includes(filterString.toUpperCase())
      );

      // Apply active/inactive filtering
      if (hideInactiveOrganizations) {
        filtered = filtered.filter(item => item.isActive);
      }

      // Apply sorting
      filtered.sort((a, b) => {
        const aValue = a[sortBy as keyof IOrganization];
        const bValue = b[sortBy as keyof IOrganization];

        if (aValue < bValue) return sortDescending ? 1 : -1;
        if (aValue > bValue) return sortDescending ? -1 : 1;
        return 0;
      });

      setFilteredOrganizations(filtered);
    }
  }, [
    organizations,
    filterString,
    hideInactiveOrganizations,
    sortBy,
    sortDescending,
  ]);

  useEffect(() => {
    handleApplyFilter();
  }, [handleApplyFilter]);

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(column);
      setSortDescending(false);
    }
  };

  const handleEditClicked = (organization: IOrganization) => {
    console.log('Editing organization', organization);
    setOrganizationToEdit(organization);
    setIsDrawerOpen(true);
    setIsCreatingNewOrganization(false);
  };

  const handleNewOrganizationClicked = () => {
    console.log('Creating new organization');
    setIsDrawerOpen(true);
    setIsCreatingNewOrganization(true);
    setOrganizationToEdit(null);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setOrganizationToEdit(null);
    console.log('Closing drawer');
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Typography variant="h4" gutterBottom component="h2">
        Organizations
        <Tooltip title="Add" aria-label="Add">
          <Fab className={classes.newOrganizationButton}>
            <AddIcon onClick={handleNewOrganizationClicked} />
          </Fab>
        </Tooltip>
      </Typography>

      <TextField
        label="Search"
        onChange={e => setFilterString(e.target.value)}
        variant="standard"
      />
      <br />

      <div
        onClick={() => setHideInactiveOrganizations(!hideInactiveOrganizations)}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          checked={hideInactiveOrganizations}
        />
        Hide Inactive Organizations
      </div>

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell>Picture</TableCell>
              <TableCell
                className={classes.sortSelect}
                onClick={() => handleSort('orgName')}
              >
                Organization{' '}
                {sortBy === 'orgName' && (sortDescending ? '▼' : '▲')}
              </TableCell>
              <TableCell
                className={classes.sortSelect}
                onClick={() => handleSort('isActive')}
              >
                Is Active{' '}
                {sortBy === 'isActive' && (sortDescending ? '▼' : '▲')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrganizations.map(organization => (
              <TableRow
                key={organization.id}
                className={
                  organization.id === loggedInUserData.mongoOrganizationId
                    ? classes.mockingRow
                    : classes.tableRow
                }
              >
                <TableCell
                  className={classes.tableCell}
                  onClick={() => handleMockOrganization(organization?.id)}
                >
                  <Avatar alt="" src={organization.orgImageUrl} />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  onClick={() => handleMockOrganization(organization?.id)}
                >
                  {organization.orgName}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  onClick={() => handleMockOrganization(organization?.id)}
                >
                  {organization.isActive && <CheckIcon />}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Button onClick={() => handleEditClicked(organization)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <OrganizationDrawer
        open={isDrawerOpen}
        organizationToEdit={organizationToEdit}
        onClose={() => handleCloseDrawer()}
        isCreatingNewOrganization={isCreatingNewOrganization}
        refreshOrganizations={refreshOrganizations}
        onSaveComplete={handleSaveComplete}
      />
      <ToastComponent />
    </MuiThemeProvider>
  );
};

export default Organizations;
