import { Avatar, Card, CardContent, Grid } from '@material-ui/core';
import { truncate } from 'lodash';
import React, { FC, useState } from 'react';
import ExpandableText from '../../../../common/ExpandableText';
import { useLobbyContext } from '../../LobbyContext';
import { useStyles } from '../../styles';
import { IProviderWaitingRoomItem, TLobbySequenceLanguage } from '../../types';
import ProviderOverrideTimeModal from './ProviderOverrideTimeModal';

type TProps = {
  provider: IProviderWaitingRoomItem;
  language: TLobbySequenceLanguage;
  className?: string;
  cardWidth?: number;
};

const ProviderCard: FC<TProps> = props => {
  const { provider, language } = props;

  const { organization } = useLobbyContext();

  const classes = useStyles();
  const [showOverrideTimeModal, toggleOverrideTimeModal] = useState(false);

  let providerName = provider.providerName;

  const unlinkedText = '(Unlinked)';
  const isUnlinked = provider.providerName.includes(unlinkedText);

  if (isUnlinked) {
    providerName = provider.providerName.replace(unlinkedText, '');
  }

  return (
    <div>
      <React.Fragment>
        <Card className={classes.providerCard} id="patientcard">
          <CardContent>
            <div
              style={{
                textAlign: 'center',
                height: 70,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  lineHeight: '36px',
                  fontSize: '32px',
                }}
              >
                <ExpandableText
                  className="h-64"
                  minFontSize={16}
                  maxFontSize={28}
                >
                  {truncate(providerName, { length: 32 })}
                </ExpandableText>
              </div>
            </div>

            <hr className="border-b-slate-500 my-5 " />
            <Grid container>
              <Grid item xs={8} style={{ margin: 'auto', textAlign: 'center' }}>
                {language === 'Spanish'
                  ? 'Pacientes en espera'
                  : 'Patients Waiting'}
                <div style={{ fontSize: 32 }}>{provider.patientsWaiting}</div>
              </Grid>
              <Grid item xs={4} style={{ margin: 'auto' }}>
                <Avatar
                  className={classes.bigAvatar}
                  src={
                    provider.providerImageUrl
                      ? provider.providerImageUrl
                      : organization.orgImageUrl
                  }
                />
              </Grid>
            </Grid>
            <div style={{ height: 165 }}>
              {/* {console.log("provider", provider)} */}
              <div
                // onClick={() => this.setState({ showTimeOverride: true, providerToOverride: provider })}
                onClick={() => toggleOverrideTimeModal(true)}
                style={{
                  fontSize: 36,
                  textAlign: 'center',
                  marginTop: 25,
                  cursor: 'pointer',
                }}
              >
                {provider.providerStatus === '0' ? (
                  <>{language === 'Spanish' ? 'a tiempo' : 'on time'}</>
                ) : (
                  <>
                    {provider.providerStatus}{' '}
                    {language === 'Spanish' ? 'tiempo de espera' : 'wait time'}
                  </>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </React.Fragment>

      {showOverrideTimeModal && (
        <ProviderOverrideTimeModal
          open={showOverrideTimeModal}
          onClose={() => toggleOverrideTimeModal(false)}
          providerToOverride={provider}
        />
      )}
    </div>
  );
};

export default ProviderCard;
