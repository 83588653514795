import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers";
import LogRocket from "logrocket";

// Custom middleware
const logRocketMiddleware = LogRocket.reduxMiddleware({
  stateSanitizer: (state) => ({
    ...state,
    providerPatientsWaiting: undefined,
    roomPatients: undefined,
  }),
});

// Combine default middleware with custom middleware
const middleware = [thunk, logRocketMiddleware];

// Add redux-immutable-state-invariant in development
if (process.env.NODE_ENV !== "production") {
  const immutableStateInvariantMiddleware = require("redux-immutable-state-invariant").default();
  middleware.unshift(immutableStateInvariantMiddleware);
  console.log("redux-immutable-state-invariant middleware added");
}




// Configure the store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
