import axios from 'axios';
import { useEffect, useState } from 'react';
import { API } from '../../apiconfig';
import { IDepartment } from '../../interfaces/interfaces';
import { checkRefreshToken } from '../../utils/utils';

export const useDepartment = (input: {
  departmentId: string;
  initialFetch?: boolean;
}): {
  department: IDepartment;
  isLoading: boolean;
  refetch: (departmentId: string) => void;
  error: string | null;
  update: (input: {
    departmentId: string;
    data: Partial<IDepartment>;
  }) => Promise<IDepartment>;
  isUpdating: boolean;
  create: (input: { data: Partial<IDepartment> }) => Promise<IDepartment>;
  destroy: (departmentId: string) => void;
} => {
  const { departmentId, initialFetch = true } = input;
  const [department, setDepartment] = useState<IDepartment>({} as IDepartment);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchDepartment = async (
    departmentId: string
  ): Promise<IDepartment> => {
    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    const URL = API.REACT_APP_API_GETDEPARTMENT + departmentId;
    const headers = {
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache',
    };

    return new Promise(async (resolve, reject) => {
      let departmentFromResponse: IDepartment = {} as IDepartment;
      try {
        const response = await axios.get(URL, { headers });

        departmentFromResponse = response?.data?.data;
        setDepartment(departmentFromResponse);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          checkRefreshToken();
        } else {
          console.error('Error fetching department:', err);
          setError('Failed to fetch department. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }

      resolve(departmentFromResponse);
    });
  };

  useEffect(() => {
    if (departmentId && initialFetch) {
      fetchDepartment(departmentId);
    }
    // eslint-disable-next-line
  }, [departmentId]);

  const refetch = (departmentId: string) => {
    return fetchDepartment(departmentId);
  };

  const update = async (input: {
    departmentId: string;
    data: Partial<IDepartment>;
  }): Promise<IDepartment> => {
    const { departmentId, data } = input;

    const token = localStorage.getItem('token');

    let modifiedDepartment = {
      ...department,
      ...data,
      id: departmentId,
    };

    console.log({ modifiedDepartment });

    setIsUpdating(true);
    return fetch(API.REACT_APP_API_UPDATE_DEPARTMENT, {
      method: 'PUT',
      body: JSON.stringify(modifiedDepartment),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        setIsUpdating(false);
        return res.json();
      })
      .then(data => {
        console.log('Success', data);
        return refetch(departmentId);
      })
      .catch(function (err) {
        setIsUpdating(false);
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        return {} as IDepartment;
      });
  };

  const create = async (input: {
    data: Partial<IDepartment>;
  }): Promise<IDepartment> => {
    const { data } = input;

    const token = localStorage.getItem('token');

    return fetch(API.REACT_APP_API_UPDATE_DEPARTMENT, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('Success', data);
        return refetch(departmentId);
      })
      .catch(function (err) {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        return {} as IDepartment;
      });
  };

  const destroy = async (departmentId: string) => {
    // const token = localStorage.getItem('token');
    // fetch(API.REACT_APP_API_DELETE_DEPARTMENT + departmentId, {
    //   method: 'DELETE',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + token,
    //   },
    // })
    //   .then(function (res) {
    //     return res.json();
    //   })
    //   .then(data => {
    //     console.log('Success', data);
    //     refetch();
    //   })
    //   .catch(function (err) {
    //     console.log('Error: ', err);
    //     if (err.response?.status === 401) {
    //       checkRefreshToken();
    //     }
    //   });
  };

  return {
    department,
    refetch,
    isLoading,
    error,
    update,
    isUpdating,
    create,
    destroy,
  };
};
