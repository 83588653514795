import
  {
    Button,
    FormControl,
    Input,
    InputLabel,
    NativeSelect,
    TextField,
  } from '@material-ui/core';

import { isEmpty } from 'lodash';
import { FC } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { usePatientContentList } from '../../../../hooks/partientContent/usePatientContentList';
import { useStyles } from '../../styles';
import { TLobbySequence } from '../../types';
import { getPatientContentOptions } from '../../utils';
import LobbySequences from './LobbySequences';
import { TFormSchema } from './types';

type TProps = {
  formMethods: UseFormReturn<TFormSchema>;
  onSubmit: SubmitHandler<TFormSchema>;
  orgId: string;
  onClose: () => void;
  onDelete: (index: number) => void;
};

const LobbySequenceForm: FC<TProps> = props => {
  const { formMethods, onSubmit, onDelete, orgId } = props;

  const errors = formMethods.formState.errors;
  const { patientContentList } = usePatientContentList({ orgId });

  const classes = useStyles();
  const { watch, setValue, handleSubmit } = formMethods;

  const newPatientEducationToShow = watch('newPatientEducationToShow');
  const newPatientEducationTime = watch('newPatientEducationTime');
  const lobbySequences = (watch('lobbySequences') ?? []) as TLobbySequence[];

  const selectedPatientContent = patientContentList?.find(
    patientContent => patientContent.id === newPatientEducationToShow
  );

  const patientContentOptions = getPatientContentOptions(patientContentList);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LobbySequences
        lobbySequences={lobbySequences}
        patientContentList={patientContentList}
        className="mt-10"
        onDelete={onDelete}
      />

      <div className="mt-10 bg-white rounded-md shadow-md p-7 mb-10">
        <FormControl
          // @ts-ignore
          className={classes.textField}
          style={{ width: '100%' }}
          margin="normal"
        >
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            Content to Show
          </InputLabel>
          <NativeSelect
            value={newPatientEducationToShow ?? ''}
            // onChange={this.handleFilterEhrMappingStatus("ehrMappingStatus")}
            // onChange={this.selectPatientContent}
            onChange={e => {
              setValue('newPatientEducationToShow', e.target.value);

              const newPatientContentOption = patientContentOptions?.find(
                patientContent => patientContent.value === e.target.value
              );

              setValue(
                'newPatientEducationToShowOption',
                newPatientContentOption
              );
            }}
            input={<Input id="patient-content-selection" />}
          >
            {patientContentOptions.map((patientContentOption, index) => {
              return (
                <option key={index} value={patientContentOption?.value}>
                  {patientContentOption.label}
                </option>
              );
            })}
          </NativeSelect>

          {errors.newPatientEducationToShow && (
            <p className="text-red-500 text-sm mt-1">
              {errors.newPatientEducationToShow.message}
            </p>
          )}

          {selectedPatientContent?.patientEducationUrl &&
          selectedPatientContent?.patientEducationUrl?.includes(
            '.mp4'
          ) ? null : (
            <div>
              <TextField
                // style={{ width: 150 }}
                label="Seconds to Show"
                type="number"
                // className={classes.textField}
                value={newPatientEducationTime}
                onChange={e => {
                  setValue('newPatientEducationTime', Number(e.target.value));
                }}
                margin="normal"
              />

              {errors.newPatientEducationTime && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.newPatientEducationTime.message}
                </p>
              )}
            </div>
          )}
        </FormControl>

        <div className="flex justify-end gap-2 mt-5">
          <Button
            disabled={isEmpty(newPatientEducationToShow)}
            onClick={() => {
              formMethods.reset();
            }}
          >
            Cancel
          </Button>

          <Button type="submit" variant="contained" color="primary">
            + Add to Sequence
          </Button>
        </div>
      </div>
    </form>
  );
};

export default LobbySequenceForm;
