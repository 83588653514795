import
  {
    IDepartment,
    IPatientContent,
  } from '../../../../interfaces/interfaces';

export const getInitialValues = (input: {
  department: IDepartment;
  patientContentList: IPatientContent[];
  isNew: boolean;
}) => {
  const { department, patientContentList = [], isNew } = input;

  if(isNew) {
    const newInitialValues: any = {
      actionsList: [],
      ehrDeptIds: [],
      isActive: true,
      isFrontDeskIntegration: false,
      isTotalTimeFromCheckIn: false,
      mongoSiteId: '',
      patientContentIds: [],
      patientEducationUrl: null,
      showNurseCallButton: false,
      showSurvey: false,
      subscribedActionId: null,
    }

    return newInitialValues;
  }

  const patientContentIds = (department.patientContentIds ?? []).map(
    (departmentPatientContent, index) => {
      const patientContent = patientContentList.find(patientContent => {
        return departmentPatientContent.id === patientContent.id;
      });

      return {
        id: departmentPatientContent.id,
        name: departmentPatientContent?.name,
        showBeforeProvider: departmentPatientContent.showBeforeProvider,
        showAfterProvider: departmentPatientContent.showAfterProvider,
        showInResources: departmentPatientContent.showInResources,
        showFullSurvey: departmentPatientContent.showFullSurvey,
        hideQrCode: departmentPatientContent.hideQrCode,
        patientContentType: patientContent?.patientContentType,
        secondsToShow: departmentPatientContent.secondsToShow,
        onlyPlayOnce: departmentPatientContent.onlyPlayOnce,
        index,
      };
    }
  );


  return {
    isActive: department.isActive,
    deptName: department.deptName,
    mongoSiteId: department.mongoSiteId,
    secondsInRoomTimeout: department.secondsInRoomTimeout,
    ehrDeptId: department.ehrDeptId,
    inheritSettingsFromDeptId: department.inheritSettingsFromDeptId,
    isFrontDeskIntegration: department.isFrontDeskIntegration,
    showNurseCallButton: department.showNurseCallButton,
    isTotalTimeFromCheckIn: department.isTotalTimeFromCheckIn,
    clearRoomAssignmentsOnPatientCheckOut:
    department.clearRoomAssignmentsOnPatientCheckOut,
    assignPatientProviderOnPatientRoom:
    department.assignPatientProviderOnPatientRoom,
    deptNotes: department.deptNotes,
    ehrDeptName: department.ehrDeptName,
    athenaPracticeId: department.athenaPracticeId,
    athenaWaitingRoomId: department.athenaWaitingRoomId,
    patientContentIds,
    ehrDeptIdList: department.ehrDeptIdList,
    fsPatientInfo: department.fsPatientInfo,
    outsideRoomPatientInfo: department.outsideRoomPatientInfo,
    inRoomPatientInfo: department.inRoomPatientInfo,
    showProvider: department.showProvider,
    showFirstName: department.showFirstName,
    showLastName: department.showLastName,
    showMRN: department.showMRN,
    showDOB: department.showDOB,
    showApptTime: department.showApptTime,
    showApptType: department.showApptType,
    newEhrDeptIdIsDefault: false,
    newEhrDeptId: '',
    actionsList: department.actionsList,
  };
};
