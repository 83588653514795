import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { z } from 'zod';
import { useAppContext } from '../../../../AppContext';
import { CheckboxField, SelectField, TextField } from '../../../../common';

import { isEmpty } from 'lodash';
import {
  IDepartment,
  IOrganization,
  IPatientContent,
  ISite,
} from '../../../../interfaces/interfaces';
import { canDo } from '../../../../utils/permissionCheck';
import PatientContentListSubform from './PatientContentListSubform';
import ActionsListSubform from './action/ActionsListSubform';
import DepartmentFrontDeskIntegrationSubform from './frontDesk/DepartmentFrontDeskIntegrationSubform';
import { schema } from './schema';
import { useStyles } from './style';

export type TFormSchema = z.infer<typeof schema>;

type TProps = {
  isNew?: boolean;
  department: IDepartment;
  organization: IOrganization;
  sites: ISite[];
  organizationDepartments: IDepartment[];
  patientContentList: IPatientContent[];
  onSubmit: (values: TFormSchema) => Promise<any>;
  formMethods: UseFormReturn<TFormSchema>;
};

const DepartmentForm: FC<TProps> = props => {
  const {
    isNew = false,
    department,
    organization,
    sites,
    organizationDepartments,
    patientContentList,
    onSubmit,
    formMethods,
  } = props;

  const { handleSubmit, control, formState, watch, setValue } = formMethods;

  const { errors, isDirty, isSubmitting } = formState;

  const { loggedInUser } = useAppContext();

  const classes = useStyles();

  console.log('organizationDepartments:', organizationDepartments);

  const organizationDepartmentOptions: {
    label: string;
    value: string;
  }[] = [
      { label: '', value: '' },
      ...organizationDepartments
        .map(dept => ({
          label: `${dept.siteName || ''} ${dept.deptName || ''}`.trim(),
          value: dept?.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];

  const siteOptions = [
    { label: '', value: '' },
    ...sites.map(site => ({
      label: site.siteName,
      value: site.id,
    })),
  ];

  const isFrontDeskIntegration = watch('isFrontDeskIntegration');
  const isActive = watch('isActive');

  console.log({ isActive });
  return (
    <div
      style={{
        marginBottom: '50px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          control={control}
          errors={errors}
          name={'isActive'}
          label={'Is Active'}
        />

        {canDo(['SyncAdmin'], loggedInUser) && (
          <div style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}>
            <span style={{ fontSize: 12, color: '#757575', fontWeight: 400 }}>
              Dept ID
            </span>
            <div>{department?.id}</div>
          </div>
        )}

        <TextField<TFormSchema>
          control={control}
          required
          label="Dept Name"
          className={classes.textField}
          name={'deptName'}
          errors={errors}
        />
        <br />

        <FormControl className={classes.textField} margin="normal">
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            Site *
          </InputLabel>

          <SelectField
            name={'mongoSiteId'}
            control={control}
            options={siteOptions}
            inputId="age-native-label-placeholder"
            errors={errors}
          />
        </FormControl>
        <br />

        {!isNew && canDo(['SyncAdmin'], loggedInUser) && (
          <div>
            <TextField
              control={control}
              required={false}
              label="EHR Dept ID"
              margin="normal"
              className={classes.textField}
              name={'ehrDeptId'}
              errors={errors}
            />
          </div>
        )}

        <br />

        <Typography variant="h4" style={{ marginTop: 25 }}>
          Settings
        </Typography>

        <div>
          <FormControl className={classes.textField}>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
              Inherit Settings from
            </InputLabel>

            <SelectField
              name={'inheritSettingsFromDeptId'}
              control={control}
              options={organizationDepartmentOptions}
              inputId="age-native-label-placeholder"
              errors={errors}
            />
          </FormControl>
        </div>

        {!department.inheritSettingsFromDeptId && (
          <div>

            {canDo(['SyncAdmin'], loggedInUser) && (
              <>
                <TextField
                  label="RTLS Timeout Seconds"
                  type="number"
                  className={classes.textField}
                  name="secondsInRoomTimeout"
                  control={control}
                  errors={errors}
                />
              </>
            )}

            <div>
              <CheckboxField
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                control={control}
                errors={errors}
                name={'isFrontDeskIntegration'}
                label={'Front Desk Integration'}
              />
            </div>

            <div>
              <CheckboxField
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                control={control}
                errors={errors}
                name={'showNurseCallButton'}
                label={'Show Nurse Call Button'}
              />
            </div>

            <div>
              <CheckboxField
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                control={control}
                errors={errors}
                name={'isTotalTimeFromCheckIn'}
                label={'Use Total Time From Check In'}
              />
            </div>

            <div>
              <CheckboxField
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                control={control}
                errors={errors}
                name={'clearRoomAssignmentsOnPatientCheckOut'}
                label={'Clear Room Assignments On Patient Check Out'}
              />
            </div>

            <div>
              <CheckboxField
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                control={control}
                errors={errors}
                name={'assignPatientProviderOnPatientRoom'}
                label={'Assign Patient Provider On Patient Room'}
              />
            </div>

            {canDo(['SyncAdmin'], loggedInUser) && (
              <div className={classes.expansionsContainer}>
                <TextField
                  label="Department Notes"
                  multiline
                  variant="outlined"
                  className={classes.notesTextField}
                  name="deptNotes"
                  control={control}
                  errors={errors}
                />
              </div>
            )}

            <PatientContentListSubform
              control={control}
              watch={watch}
              patientContentList={patientContentList}
              setValue={setValue}
              errors={errors}
            />

            {!isNew && !isEmpty(department) && (
              <ActionsListSubform
                refetchDepartment={() => {
                  console.log('refetchDepartment');
                }}
                control={control}
                watch={watch}
                setValue={setValue}
                errors={errors}
                department={department}
              />
            )}

            {!isNew && isFrontDeskIntegration && (
              <DepartmentFrontDeskIntegrationSubform
                classes={classes}
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                organization={organization}
                department={department}
              />
            )}
          </div>
        )}

        <div
          style={{
            marginTop: 50,
          }}
        >
          <Button
            type="submit"
            className={classes.submitButton}
            color="primary"
          >
            Save
          </Button>
        </div>

        <br />

        <div className={isSubmitting ? classes.loading : classes.gone}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      </form>

      {isDirty && <div className={classes.saveWarning}>Unsaved Changes!</div>}
    </div>
  );
};

export default DepartmentForm;
