import React, { useEffect } from "react";

export interface UsersInOrgFormProps {
  setIsFormReady: (isReady: boolean) => void;
}

export const UsersInOrgForm: React.FC<UsersInOrgFormProps> = ({ setIsFormReady }) => {
  useEffect(() => {
    if (setIsFormReady) {
      setIsFormReady(true);
    }
  }, [setIsFormReady]);

  return <div>Sends a report with all of the last-known locations of staff in an organization.</div>;
};

export default UsersInOrgForm;
