import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import Chart from "react-google-charts";
import { IProvider } from "../../../interfaces/interfaces";

const _ = require("lodash");

const styles = () => ({});

interface PatientTimelineProps {
  providerData: any[];
  timeZone: any;
}

class PatientTimeline extends PureComponent<PatientTimelineProps> {
  render() {
    // console.log("prop data", this.props.providerData);
    // console.log("TEST", new Date(0, 0, 0, 12, 0, 0));

    let data: any[] = [
      [
        { type: "string", id: "Room" },
        { type: "string", id: "Name" },
        { type: "string", role: "tooltip" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },
      ],
      //   ["Casey Wyatt", "", new Date(0, 0, 0, 12, 0, 0), new Date(0, 0, 0, 13, 30, 0)],
      //   ["Casey Wyatt", "", new Date(0, 0, 0, 14, 0, 0), new Date(0, 0, 0, 15, 30, 0)],
      //   ["Casey Wyatt", "", new Date(0, 0, 0, 16, 0, 0), new Date(0, 0, 0, 16, 15, 0)],
      //   ["Casey Wyatt", "", new Date(0, 0, 0, 17, 0, 0), new Date(0, 0, 0, 17, 30, 0)],
      //   ["Jenifer Reynaga", "", new Date(0, 0, 0, 12, 30, 0), new Date(0, 0, 0, 14, 0, 0)],
      //   ["Jenifer Reynaga", "", new Date(0, 0, 0, 14, 30, 0), new Date(0, 0, 0, 16, 0, 0)],
      //   ["Jenifer Reynaga", "", new Date(0, 0, 0, 16, 30, 0), new Date(0, 0, 0, 18, 0, 0)],
    ];

    var usersWithAssignedColor: any[] = [];
    var colors = ["#FD625E"];

    // let uniqProviderData = _.uniqBy(this.props.providerData, ["localStartDateTime", "userId"]); // and userId

    // console.log("providerData", this.props.providerData);

    this.props.providerData.forEach((provider: IProvider) => {
      data.push([
        provider.fullName,
        "",
        provider.userImageUrl
          ? "<div style='min-height: 125px; margin: 10px; text-align: center; background-color: white'><div><img style='width: 75px' src=" +
            provider.userImageUrl +
            " alt='' /></div>" +
            provider.fullName +
            "<div><strong>" +
            moment.utc(provider.localEndDateTime).diff(moment.utc(provider.localStartDateTime), "minutes") +
            " minutes</strong></div><div>" +
            moment.utc(provider.localStartDateTime).format("h:mma") +
            " - " +
            moment.utc(provider.localEndDateTime).format("h:mma") +
            "</div></div>"
          : "<div style='margin: 10px; text-align: center; background-color: white'>" +
            provider.fullName +
            "<div><strong>" +
            moment.utc(provider.localEndDateTime).diff(moment.utc(provider.localStartDateTime), "minutes") +
            " minutes</strong></div><div>" +
            moment.utc(provider.localStartDateTime).format("h:mma") +
            " - " +
            moment.utc(provider.localEndDateTime).format("h:mma") +
            "</div></div>",
        moment(provider.localStartDateTime).milliseconds(0).toDate(),
        moment(provider.localEndDateTime).milliseconds(0).toDate(),
      ]);

      if (usersWithAssignedColor.includes(provider.fullName) === false) {
        if (provider.userType === "Provider") {
          colors.push("#8AD4EB");
          usersWithAssignedColor.push(provider.fullName);
        }

        if (provider.userType === "Staff") {
          colors.push("#00B8AA");
          usersWithAssignedColor.push(provider.fullName);
        }

        if (provider.userType === "Equipment") {
          colors.push("#374649");
          usersWithAssignedColor.push(provider.fullName);
        }

        if (provider.userType === "Action") {
          colors.push("#ebbe8a");
          usersWithAssignedColor.push(provider.fullName);
        }
      }
    });

    // console.log("COLORS", colors);

    // console.log("DATA", data);

    // Get total rows muliplied by standard row height to get dynamic total height of chart.
    let totalHeight = 55;
    let rows = _.uniqBy(this.props.providerData, "fullName");
    totalHeight = totalHeight * rows.length;

    return (
      // <div>
      <Chart
        // width={"100%"}
        // height="255px"
        height={totalHeight}
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          focusTarget: "category",
          allowHtml: true,
          cssClassNames: {
            tableCell: "patient-cell",
          },
          showRowNumber: false,
          colors: colors,
          tooltip: { isHtml: true },
          timeline: {
            colorByRowLabel: true,
          },
          hAxis: {
            format: "hh:mm",
          },
        }}
        rootProps={{ "data-testid": "5" }}
      />
      // </div>
    );
  }
}

export default withStyles(styles)(PatientTimeline);
