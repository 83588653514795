import { Providers, ProviderState } from "@microsoft/mgt-element";
import axios from "axios";
import { useEffect, useState } from "react";
import { API, MSALSSO } from "../apiconfig";

function useIsSignedIn() {
  const [ssoNotAllowed, setSsoNotAllowed] = useState(false);
  const [ssoLoading, setSsoLoading] = useState(false);

  useEffect(() => {
    // console.log('called!!!!!!!!!!!!!!!!')

    // @ts-ignore
    if (window.webkit && window.webkit.messageHandlers) {
      let iosPayload = {
        ssoTest: 1,
      };
      // @ts-ignore
      if (window.webkit.messageHandlers.ssoTest) {
        // @ts-ignore
        window.webkit.messageHandlers.ssoTest.postMessage(iosPayload);
      }
    }

    window.addEventListener("message", (event) => {
      // console.log("Received message from parent of iFrame", event)
      if (event.data) {
        // console.log("DATA!!", event.data)
        let incMessage = event.data;
        if (incMessage.messageType === "msaltoken") {
          setSsoLoading(true);
          console.log("Handling MSAL Token", incMessage);

          fetch(MSALSSO, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + incMessage.token,
            },
          })
            .then((res) => {
              // console.log("RES", res)
              return res.json();
            })
            .then((data) => {
              if (data.message) {
                if (data.message === "sso_not_enabled") {
                  setSsoLoading(false);
                  setSsoNotAllowed(true);
                  localStorage.removeItem("token");
                  localStorage.removeItem("email");
                  setTimeout(() => {
                    setSsoNotAllowed(false);
                  }, 10000);
                }
              }

              if (data.access_token && data.refresh_token) {
                let payload = {
                  useremail: incMessage.username,
                };
                axios({
                  method: "post",
                  url: API.REACT_APP_API_LOGINDETAILS,
                  data: payload,
                  headers: {
                    Authorization: "Bearer " + data.access_token,
                    Pragma: "no-cache",
                  },
                }).then((response) => {
                  // setSsoLoading(false)
                  console.log("response", response);
                  localStorage.setItem("token", data.access_token);
                  localStorage.setItem("refresh_token", data.refresh_token);
                  localStorage.setItem("email", incMessage.username);
                  window.location.replace("/");
                });
                setSsoLoading(false);
              }
            })
            .catch((err) => {
              console.log("Error getting new token", err);
              setSsoLoading(false);
            });
        }
      }
    });

    const updateState = () => {
      if (!localStorage.getItem("token")) {
        const provider = Providers.globalProvider;

        // setIsSignedIn(provider && provider.state === ProviderState.SignedIn);

        // Check if user is logged into Microsoft and to prevent making duplicate requests from Providers.onProviderUpdated
        if (Providers.globalProvider.state === ProviderState.SignedIn) {
          const getToken = async () => {
            const token = await provider.getAccessToken();
            // @ts-ignore
            const acct = await provider.getAccount();
            console.log("TOKEN", token);
            console.log("ACC", acct);
            // use token to hit new endpoint
            // that will return a NEW token.
            // we'll use the NEW token and acct.username

            // THEN user acct.username & returned token to hit login endpoint

            setSsoLoading(true);

            fetch(MSALSSO, {
              method: "POST",
              // body: JSON.stringify({}),
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            })
              .then((res) => {
                // console.log("RES", res)
                return res.json();
              })
              .then((data) => {
                // console.log('DATA', data)
                if (data.message) {
                  if (data.message === "sso_not_enabled") {
                    setSsoLoading(false);
                    setSsoNotAllowed(true);
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    setTimeout(() => {
                      setSsoNotAllowed(false);
                    }, 10000);
                  }
                }
                if (data.access_token && data.refresh_token) {
                  console.log("Attempt login");
                  let payload = {
                    useremail: acct.username,
                  };

                  let autoLogin = async () => {
                    axios({
                      method: "post",
                      url: API.REACT_APP_API_LOGINDETAILS,
                      data: payload,
                      headers: {
                        Authorization: "Bearer " + data.access_token,
                        Pragma: "no-cache",
                      },
                    }).then((response) => {
                      // setSsoLoading(false)
                      console.log("response", response);
                      localStorage.setItem("token", data.access_token);
                      localStorage.setItem("refresh_token", data.refresh_token);
                      localStorage.setItem("email", acct.username);
                      window.location.replace("/");
                    });
                  };
                  autoLogin();
                }
              })
              .catch((err) => {
                console.log("Error getting new token", err);
                setSsoLoading(false);
              });
          };

          if (ProviderState.SignedIn) {
            getToken();
          }
        }
      }
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
      window.removeEventListener("message", () => {});
    };
  }, []);

  // return [isSignedIn];
  return [ssoNotAllowed, ssoLoading];
}

export default useIsSignedIn;
