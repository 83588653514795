import axios from 'axios';
// import { getCookie } from "../utils/utils";
// import { showLoading, hideLoading } from "react-redux-loading-bar";
import { API } from '../apiconfig';
import { checkRefreshToken } from '../utils/utils';
import { lockScreen } from '../utils/LockScreen';

import { Providers, ProviderState } from '@microsoft/mgt-element';

import LogRocket from 'logrocket';

import {
  FETCH_LOGGED_IN_USER,
  FETCH_APP_USERS_BY_ORG_ID,
  FETCH_ORGANIZATIONS,
  FETCH_CAMPUSES,
  FETCH_SITES,
  FETCH_ORGANIZATIONDEPARTMENTS,
  FETCH_DEPARTMENT,
  FETCH_ALLACTIONS,
  FETCH_SOUNDS,
  FETCH_ROOMS_BY_ORG_ID,
  FETCH_ALL_DEVICE_DETAILS,
  FETCH_ALL_DISCONNECTED_DEVICES,
  FETCH_DEVICE_DETAIL,
  UPDATE_DEVICE_DETAIL,
  FETCH_ALL_APPS,
  FETCH_REPORTING,
  FETCH_SECURITYDEPTS,
  UPDATE_SECURITYDEPTS,
  FETCH_SIBLINGDEPTS,
  FETCH_DEVICE_LOCATIONS,
  FETCH_INSTRUMENTS_BY_ORG_ID,
  FETCH_ORG_PROVIDERS,
  FETCH_DISTINCT_ORG_ACTIONS,
  ERROR_LOGGED_IN_USER,
  ERROR_LOGOUT_USER,
  FETCH_TRAINING_DOCUMENTATION,
  FETCH_SITE,
  FETCH_PERMISSIONS,
  FETCH_PATIENT_CONTENT_BY_ORG_ID,
  FETCH_CLINIC_MAPS_BY_ORG_ID,
  SET_ORGANIZATION,
} from './types';

import { cloneDeep } from 'lodash';
import {
  IDepartment,
  IOrganization,
  TDispatch,
} from '../interfaces/interfaces';

export const fetchPermissions = () => (dispatch: TDispatch) => {
  let URL = API.REACT_APP_API_PERMISSIONS;
  let token = localStorage.getItem('token');

  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  // console.log("lets get", orgId);
  axios.get(URL, headers).then(response => {
    // console.log("Permissions", response.data.data);
    if (response.data.data) {
      dispatch({
        type: FETCH_PERMISSIONS,
        payload: response.data.data,
      });
    }
  });

  // axios({
  //   method: "GET",
  //   url: URL,
  //   headers: {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       Pragma: "no-cache",
  //     },
  //   },
  // }).then((response) => {
  //   console.log("Permissions response", response);
  // });
};

export const fetchLoggedInUser =
  (email: string, token: string, mockedOrgId: string | null = null) =>
  (dispatch: any) => {
    // dispatch(showLoading());
    let URL = API.REACT_APP_API_LOGINDETAILS;
    let orgIdToMock: string | null = mockedOrgId;
    let data = {
      useremail: email,
    };

    axios({
      method: 'post',
      url: URL,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
        Pragma: 'no-cache',
      },
    })
      .then(response => {
        if (response.data.data.isUserSiteLocked) {
          console.log('User is locked out of site');
          lockScreen();
        }

        // getLatestRefreshToken(); // DONT USE THIS HERE, WILL CAUSE ISSUES LOGGING IN INITIALLY

        if (response.data.data) {

          if (token && response.data.data.userEmail) {
            let email = response.data.data.userEmail;

            let headers = {
              headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
            };
            let URL = API.REACT_APP_API_HUBSPOTCONVO;

            // console.log("lets get", orgId);
            axios
              .get(URL, headers)
              .then(data => {
                // console.log("GOT BACK HUBSPOT", data.data.data)
                // if (data.data.)

                try {
                  if (data?.data?.data?.token) {
                    let hubspotToken = data.data.data.token;
                    // console.log("Hubspot", hubspotToken)
                    // @ts-ignore
                    window.hsConversationsSettings = {
                      identificationEmail: email,
                      identificationToken: hubspotToken,
                    };

                    // @ts-ignore
                    window.HubSpotConversations.widget.load();
                  }
                } catch (err) {
                  console.log('Error loading hubspot', err);
                }
              })
              .catch(err => {
                console.log('Error fetching hubspot', err);
              });
          }

          if (!response.data.data.isSyncTimesAdmin) {
            orgIdToMock = null;
            localStorage.removeItem('lastMockedOrganizationId');
          }

          // iOS iFrame messageHandler
          // @ts-ignore
          if (window.webkit && window.webkit.messageHandlers) {
            let iosPayload = {
              userId: response.data.data.id,
              token: token,
            };

            // @ts-ignore
            if (window.webkit.messageHandlers.userLogin) {
              // @ts-ignore
              window.webkit.messageHandlers.userLogin.postMessage(iosPayload);
            }
          }

          // console.log("response user", response.data.data);
          // android webview
          var valueToBeReturned = {
            userEmail: response.data.data.userEmail,
            token: token,
          };

          // console.log("sending", JSON.stringify(valueToBeReturned));

          const isWebView = navigator.userAgent.includes('wv');

          if (isWebView) {
            // @ts-ignore
            window.sendDataToAndroidWebview(JSON.stringify(valueToBeReturned));
          }

          // ionic iFrame postMessage

          if (window.self !== window.top) {
            window.parent.postMessage(
              {
                event_id: 'userDataMessage',
                data: {
                  user: response.data.data,
                  token: token,
                },
              },
              '*' //or "www.parentpage.com"
            );
          }

          let URL =
            API.REACT_APP_API_ORGANIZATION +
            response.data.data.mongoOrganizationId;
          if (orgIdToMock) {
            console.log('mock this', orgIdToMock);
            URL = API.REACT_APP_API_ORGANIZATION + orgIdToMock;
          }

          // let URL = API.REACT_APP_API_ORGANIZATION + orgIdToMock;

          // console.log("orgIdToMock", orgIdToMock);

          let headers = {
            headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
          };

          // console.log("lets get", orgId);
          axios.get(URL, headers).then(org => {
            let orgData = org.data.data;

            if (orgData) {
              // write isCustomerOrg to localstorage
              localStorage.setItem('isCustomerOrg', orgData.isCustomerOrg);
            }

            // console.log("orgData", orgData);

            if (orgIdToMock) {
              let mockedUser = {
                ...response.data.data,
                mongoOrganizationId: orgIdToMock,
              };

              dispatch({
                type: FETCH_LOGGED_IN_USER,
                payload: { user: mockedUser, org: orgData },
              });

              // dispatch(hideLoading());
            } else {
              dispatch({
                type: FETCH_LOGGED_IN_USER,
                payload: { user: response.data.data, org: orgData },
              });

              // dispatch(hideLoading());
            }
          });

          // Check if we should mock an Organization (And make sure they're a SyncTimes admin)
        } else {
          // Fetch was good, but no data came back. Force a logout.
          dispatch({
            type: ERROR_LOGOUT_USER,
          });
        }
      })
      .catch(function (err) {
        console.log('Error fetching logged in user', err);

        if (err.response) {
          console.log('ERROR STATUS: ', err.response?.status);
          if (err.response?.status === 401) {
            // FORCE LOGOUT, UNAUTHORIZED
            console.log('UNAUTHORIZED, KICK EM OUT');

            checkRefreshToken();
          } else {
            // Something else broke, no data came back....
            dispatch({
              type: ERROR_LOGGED_IN_USER,
            });
            // dispatch(hideLoading());
          }
        } else {
          console.log('NETWORK ERROR');
          dispatch({
            type: ERROR_LOGGED_IN_USER,
          });
          // dispatch(hideLoading());
        }
      });
  };

export const setOrganization = (payload: IOrganization) => {
  return {
    type: SET_ORGANIZATION,
    payload,
  };
};

export const fetchAppUsersByOrgId =
  (orgId: string) => (dispatch: TDispatch) => {
    // dispatch(showLoading());
    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETAPPUSERSBYORGID + orgId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    axios
      .get(URL, headers)
      .then(response => {
        dispatch({
          type: FETCH_APP_USERS_BY_ORG_ID,
          payload: Array.isArray(response.data.data)
            ? cloneDeep(response.data.data)
            : { ...response.data.data },
        });
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const fetchOrganizations = () => (dispatch: TDispatch) => {
  let URL = API.REACT_APP_API_ORGANIZATIONS;
  let token = localStorage.getItem('token');
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      console.log('fetchOrganizations response', response);
      dispatch({
        type: FETCH_ORGANIZATIONS,
        payload: Array.isArray(response.data)
          ? [...response.data]
          : { ...response.data },
      });
    })
    .catch(err => {
      if (err.response) {
        if (err.response?.status === 401) {
          // FORCE LOGOUT, UNAUTHORIZED

          checkRefreshToken();

          // let refreshToken = localStorage.getItem("refresh_token");
          // if (refreshToken) {
          //   checkRefreshToken();
          // } else {
          //   localStorage.removeItem("token");
          //   localStorage.removeItem("email");

          //   if (getQueryVariable("username") && getQueryVariable("password")) {
          //     // Reload FS with login params
          //     let location = window.location.href;
          //     window.location.replace(location);
          //   } else {
          //     window.location.replace("/");
          //   }
          // }
        }
      }
      console.log('Error: ', err);
    });
};

export const fetchSites = (orgId: string) => (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_SITES + orgId;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_SITES,
        payload: [...response.data.data],
      });
    })
    .catch(err => {
      if (err.response) {
        if (err.response?.status === 401) {
          // FORCE LOGOUT, UNAUTHORIZED
          console.log('UNAUTHORIZED, KICK EM OUT');

          checkRefreshToken();

          // let refreshToken = localStorage.getItem("refresh_token");
          // if (refreshToken) {
          //   checkRefreshToken();
          // } else {
          //   console.log("SOMETHING WEIRD HAPPENED")
          //   // localStorage.removeItem("token");
          //   // localStorage.removeItem("email");

          //   // if (getQueryVariable("username") && getQueryVariable("password")) {
          //   //   // Reload FS with login params
          //   //   let location = window.location.href;
          //   //   window.location.replace(location);
          //   // } else {
          //   //   window.location.replace("/");
          //   // }
          // }
        }
      }
      console.log('Error: ', err);
    });
};

export const fetchCampuses = (orgId: string) => (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_CAMPUSES + orgId;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_CAMPUSES,
        payload: [...response.data.data],
      });
    })
    .catch(err => {
      if (err.response) {
        if (err.response?.status === 401) {
          // FORCE LOGOUT, UNAUTHORIZED
          console.log('UNAUTHORIZED, KICK EM OUT');

          checkRefreshToken();
        }
      }
      console.log('Error: ', err);
    });
};

export const fetchOrganizationDepartments =
  (orgId: string) => (dispatch: TDispatch) => {
    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETORGDEPARTMENT + orgId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("fetchOrganizationDepartments", response);
        dispatch({
          type: FETCH_ORGANIZATIONDEPARTMENTS,
          payload: [...response.data.data],
        });
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const fetchDepartmentAndSite =
  (deptId: string | undefined) => (dispatch: TDispatch) => {
    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };
    let URL = API.REACT_APP_API_GETDEPARTMENT + deptId;

    axios
      .get(URL, headers)
      .then(response => {
        // console.log('doing good', response.data.data)

        if (response.data.data) {
          dispatch({
            type: FETCH_DEPARTMENT,
            payload: [...response.data.data],
          });

          let mongoSiteId = response.data.data.mongoSiteId;

          let siteURL = API.REACT_APP_API_SITE + mongoSiteId;
          // console.log('site url', siteURL)

          let headers = {
            headers: {
              Authorization: 'Bearer ' + token,
              'Org-UID': 'UID1',
              Pragma: 'no-cache',
            },
          };

          axios
            .get(siteURL, headers)
            .then(site => {
              // console.log("site! " + JSON.stringify(response.data.data));

              if (site.data.data) {
                dispatch({
                  type: FETCH_SITE,
                  payload: [...response.data.data],
                });
              }
            })
            .catch(err => {
              console.log('Error fetching site ' + err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      })
      .catch(err => {
        console.log('Error fetching dept ' + err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const fetchDepartment = (groupId: string) => (dispatch: TDispatch) => {
  // console.log("fetchDept", groupId);
  let token = localStorage.getItem('token');

  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  fetchDepartments(groupId);

  function fetchDepartments(mongoDeptId: string) {
    let URL = API.REACT_APP_API_GETDEPARTMENT + mongoDeptId;
    axios
      .get(URL, headers)
      .then(response => {
        dispatch({
          type: FETCH_DEPARTMENT,
          payload: [...response.data.data],
        });

        // console.log("RESPONSE SIBLING", response);
        //=========================================================================
        // THIS IS TO GET SIBLING DEPTS FOR FLOWSTATION (THIS WILL CHANGE LOCATION)
        //=========================================================================

        let siblingDepts: IDepartment[] = response.data.data.siblingDepts;
        // console.log("true sibling depts", siblingDepts);
        let fetchedData: any[] = [];

        if (siblingDepts.length > 0) {
          // console.log("sibs exist", siblingDepts.length);
          let loopReqs = new Promise((resolve, reject) => {
            siblingDepts.forEach((dept, index) => {
              // console.log("fetching each dept", dept);
              // console.log("index", index);

              axios
                .get(API.REACT_APP_API_FLOWSTATIONDATA + dept.id, headers)
                .then(response => {
                  if (response.data.data) {
                    // console.log("DISPATCH");
                    fetchedData.push(response.data.data);
                  }

                  if (index === siblingDepts.length - 1) {
                    resolve(null);
                  }
                })
                .catch(err => {
                  console.log('Error fetching sibling depts: ', err);
                  if (err.response?.status === 401) {
                    checkRefreshToken();
                  }
                });
            });
          });

          loopReqs.then(() => {
            // console.log("ALL DONE FETCHING SIBLINGS", fetchedData);
            dispatch({
              type: FETCH_SIBLINGDEPTS,
              payload: fetchedData,
            });
          });
        } else {
          // THIS IS PRIMARILY USED FOR WHEN A DEPARTMENT IS FORCE UPDATED AND A SIBLING DEPARTMENT IS NO LONGER ACTIVE
          // console.log("no sibs found");
          dispatch({
            type: FETCH_SIBLINGDEPTS,
            payload: [],
          });
        }

        //=========================================================================
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
    // console.log("DONE");
  }
};

export const fetchAllActions = () => (dispatch: any) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_GETALLACTIONS;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_ALLACTIONS,
        payload: [...response.data.data],
      });
    })
    .catch(err => {
      console.log('ERR', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchSounds = () => async (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_GETSOUNDS;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  const response: any = await axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_SOUNDS,
        payload: [...response.data.data],
      });
      return response;
    })
    .catch(err => {
      console.log('Error: ', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });

  return response?.data?.data;
};

export const fetchRoomsByOrgId = (orgId: string) => (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_GETROOMSBYORGID + orgId;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_ROOMS_BY_ORG_ID,
        payload: [...response.data.data],
      });
    })
    .catch(err => {
      console.log('Error: ', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchPatientContent = (orgId: string) => (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_PATIENTCONTENT + orgId;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_PATIENT_CONTENT_BY_ORG_ID,
        payload: [...response.data.data],
      });
    })
    .catch(err => {
      console.log('Error: ', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchAllDeviceDetailsByOrgId =
  (orgId: string) => (dispatch: TDispatch) => {
    // dispatch(showLoading());
    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETORGDEVICEDETAILS + orgId;
    console.log('Device fetch url', URL);
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("ALL devices", response.data);
        dispatch({
          type: FETCH_ALL_DEVICE_DETAILS,
          payload: {
            devices: [...response.data.devices],
            actionTypes: [...response.data.actionTypes],
            apps: [...response.data.apps],
          },
        });
        // dispatch(hideLoading());
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const fetchAllDeviceDetails = () => (dispatch: TDispatch) => {
  // dispatch(showLoading());
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_ALLDEVICEDETAILS;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      // console.log("ALL devices", response.data);
      dispatch({
        type: FETCH_ALL_DEVICE_DETAILS,
        payload: {
          devices: [...response.data.devices],
          actionTypes: [...response.data.actionTypes],
          apps: [...response.data.apps],
        },
      });
      // dispatch(hideLoading());
    })
    .catch(err => {
      console.log('Error: ', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchAllDisconnectedDevices = () => (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_DISCONNECTEDEVICES;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      // console.log("Got disconnected devices", response.data);
      dispatch({
        type: FETCH_ALL_DISCONNECTED_DEVICES,
        payload: {
          devices: [...response.data.devices],
          actionTypes: [...response.data.actionTypes],
          apps: [...response.data.apps],
        },
      });
    })
    .catch(err => {
      console.log('Error: ', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchDeviceDetail =
  (deviceId: string) => (dispatch: TDispatch) => {
    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETDEVICEDETAIL + deviceId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("RESPONSE!", response.data.data);

        // Converting connectedTimes format to human readable
        // if (response.data.connectedTimes.length > 1) {
        //   for (let i = 0; i < response.data.connectedTimes.length; i++) {
        //     response.data.connectedTimes[i].timeStamp = moment.utc(response.data.connectedTimes[i].timeStamp).local().format("LLL");
        //   }
        // }

        // console.log("device detail", response.data);

        dispatch({
          type: FETCH_DEVICE_DETAIL,
          payload: { ...response.data.data },
        });
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const updateDeviceDetails =
  (deviceDetails: any) => (dispatch: TDispatch) => {
    // console.log("Updating store with updated device details", deviceDetails);
    dispatch({
      type: UPDATE_DEVICE_DETAIL,
      payload: deviceDetails,
    });
  };

export const fetchAllApps = () => (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  let URL = API.REACT_APP_API_APK;
  let headers = {
    headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
  };

  axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_ALL_APPS,
        payload: [...response.data],
      });
    })
    .catch(err => {
      console.log('Error: ', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchReporting = (reqData: any) => (dispatch: TDispatch) => {
  let token = localStorage.getItem('token');

  // console.log("FETCH REPORTING", reqData);

  let URL = API.REACT_APP_API_REPORTING;

  // Available Charts
  //------------------------------
  // TrendingProductivity
  // GroupedProductivity
  // TrendingCycleTimes
  // GroupedCycleTimes
  // TrendingProductivityLine
  // TrendingCareTeamPercent
  // TrendingRoomTimes

  let dataTemplate: any = {
    startDate: null,
    endDate: null,
    siteIdArray: null,
    deptIdArray: null,
    providerIdArray: null,
    GraphsToRetrieve: null,
    orgId: null,
    trendingGroupBy: null,
    baseGroupBy: null,
  };

  let payload = {
    ...dataTemplate,
    ...reqData,
  };

  // console.log("payload", payload);

  fetch(URL, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(res => {
      res
        .json()
        .then(data => ({
          data: data,
        }))
        .then(res => {
          // console.log("res", res.data);
          dispatch({
            type: FETCH_REPORTING,
            payload: { ...res.data.data },
          });
        });
    })
    .catch(err => {
      console.log('Error sending post', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchSecurityDepts =
  (depts: IDepartment[]) => (dispatch: TDispatch) => {
    let URL = API.REACT_APP_API_FLOWSTATIONDATA;
    let token = localStorage.getItem('token');

    let headers = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Org-UID': 'UID1',
        Pragma: 'no-cache',
      },
    };

    let securityDepts: IDepartment[] = [];

    let promises: any[] = [];

    depts.forEach(dept => {
      if (dept.isActive) {
        promises.push(axios.get(URL + dept.id, headers));
      }
    });

    Promise.all(promises).then(results => {
      results.forEach(response => {
        securityDepts.push(response.data.data);
      });
      // console.log("DONE")
      dispatch({
        type: FETCH_SECURITYDEPTS,
        payload: [...securityDepts],
      });
    });
  };

export const updateSecurityDepts =
  (depts: IDepartment[]) => (dispatch: TDispatch) => {
    dispatch({
      type: UPDATE_SECURITYDEPTS,
      payload: depts,
    });
  };

export const fetchDeviceLocations = () => (dispatch: TDispatch) => {
  let URL = API.REACT_APP_API_GETDEVICELOCATIONS;
  let token = localStorage.getItem('token');

  let headers = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Org-UID': 'UID1',
      Pragma: 'no-cache',
    },
  };

  axios
    .get(URL, headers)
    .then(response => {
      dispatch({
        type: FETCH_DEVICE_LOCATIONS,
        payload: [...response.data.data],
      });
    })
    .catch(err => {
      console.log('Error fetching device locations ', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchClinicMapsByOrgId =
  (orgId: string) => (dispatch: TDispatch) => {
    const URL = API.REACT_APP_API_CLINICMAP + orgId;
    let token = localStorage.getItem('token');

    let headers = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Org-UID': 'UID1',
        Pragma: 'no-cache',
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        console.log('GOT CLINIC MAPS', response);
        dispatch({
          type: FETCH_CLINIC_MAPS_BY_ORG_ID,
          payload: [...response.data.data],
        });
      })
      .catch(err => {
        console.log('Error fetching clinic maps', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const fetchInstrumentsByOrgId =
  (orgId: string) => (dispatch: TDispatch) => {
    let URL = API.REACT_APP_API_GETINSTRUMENTSBYORGID + orgId;
    let token = localStorage.getItem('token');

    let headers = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Org-UID': 'UID1',
        Pragma: 'no-cache',
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("INSTRUMENTS", response);
        dispatch({
          type: FETCH_INSTRUMENTS_BY_ORG_ID,
          payload: [...response.data.data],
        });
      })
      .catch(err => {
        console.log('Error fetching device locations ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const fetchOrgProviders = (orgId: string) => (dispatch: TDispatch) => {
  let URL = API.REACT_APP_API_GETORGPROVIDERS + orgId;
  let token = localStorage.getItem('token');

  let headers = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Org-UID': 'UID1',
      Pragma: 'no-cache',
    },
  };

  axios
    .get(URL, headers)
    .then(response => {
      // console.log("GOT PROVIDERS", response);
      dispatch({
        type: FETCH_ORG_PROVIDERS,
        payload: [...response.data.data],
      });
    })
    .catch(err => {
      console.log('Error fetching organization providers', err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchDistinctOrgActions =
  (orgId: string) => (dispatch: TDispatch) => {
    let URL = API.REACT_APP_API_DISTINCTORGACTIONS + orgId;

    let token = localStorage.getItem('token');

    let headers = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Org-UID': 'UID1',
        Pragma: 'no-cache',
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("GOT DISTINCT ORG ACTIONS", response);
        dispatch({
          type: FETCH_DISTINCT_ORG_ACTIONS,
          payload: [...response.data.data],
        });
      })
      .catch(err => {
        console.log('Error fetching distinct org actions', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const logAction =
  (actionName: string, actionDetail: string, userId: string) =>
  (dispatch: TDispatch) => {
    console.log('LOG ACTION', actionName, actionDetail, userId);

    LogRocket.track(actionName);

    let token = localStorage.getItem('token');
    let URL = API.REACT_APP_API_LOGACTION;

    if (actionName !== null && actionDetail !== null && userId !== null) {
      let data = {
        actionName: actionName,
        actionDetail: actionDetail,
        userId: userId,
      };
      // console.log("PAYLOAD", data);
      // Dont Log if in development/localhost
      // if (window.location.hostname !== "localhost") {
      axios({
        method: 'post',
        url: URL,
        data,
        headers: {
          Authorization: 'Bearer ' + token,
          Pragma: 'no-cache',
        },
      })
        .then(response => {
          // console.log("RES", response);
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
      // } else {
      //   console.log("LOCALHOST: NOT LOGGING");
      // }
    } else {
      console.log('missing data for log');
    }
  };

export const fetchTrainingDocumentation =
  (userId: string) => (dispatch: TDispatch) => {
    let URL = API.REACT_APP_API_TRAININGDOCUMENTATION + userId;
    let token = localStorage.getItem('token');

    let headers = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Org-UID': 'UID1',
        Pragma: 'no-cache',
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("GOT TRAINING DOCUMENTATION", response);
        dispatch({
          type: FETCH_TRAINING_DOCUMENTATION,
          payload: response.data.data,
        });
      })
      .catch(err => {
        console.log('Error fetching training documentation', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

export const logout = () => (dispatch: TDispatch) => {
  async function sendMobileRemoveTokenMessage() {
    let userBearerToken = localStorage.getItem('token');
    // iOS iFrame messageHandler
    // @ts-ignore
    if (window.webkit && window.webkit.messageHandlers) {
      let iosPayload = {
        token: userBearerToken,
        removeToken: true,
      };

      // @ts-ignore
      if (window.webkit.messageHandlers.userLogin) {
        // @ts-ignore
        window.webkit.messageHandlers.userLogin.postMessage(iosPayload);
      }
    }

    var valueToBeReturned = {
      token: userBearerToken,
      removeToken: true,
    };
    // console.log("sending", JSON.stringify(valueToBeReturned));

    const isWebView = navigator.userAgent.includes('wv');

    if (isWebView) {
      // @ts-ignore
      window.sendDataToAndroidWebview(JSON.stringify(valueToBeReturned));
    }

    // Detected Microsoft Teams App iFrame
    if (
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      localStorage.setItem('SSOTeamsLoggedOut', 'true');
    }

    // ionic iFrame postMessage
    if (window.self !== window.top) {
      // send data through webview
      window.parent.postMessage(
        {
          event_id: 'userDataMessage',
          data: {
            removeToken: true,
          },
        },
        '*' //or "www.parentpage.com"
      );
    }

    // Let Electron know app is ready
    if (window.self !== window.top) {
      window.parent.postMessage(
        {
          event_id: 'logout_user',
          data: {
            success: true,
          },
        },
        '*'
      );
    }
  }

  sendMobileRemoveTokenMessage().then(() => {
    const provider = Providers.globalProvider;
    // Check if they are loggedin through Microsoft
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_expire');
      // @ts-ignore
      provider.logout().then(() => {
        console.log('Now fully logout!');

        window.location.replace('/login');
      });
    } else {
      // Logout regularly
      // Logout user
      // checkRefreshToken();
      console.log('LOGOUT USER');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_expire');
      window.location.reload();
    }

    // console.log("LOGOUT USER");
    // localStorage.removeItem("token");
    // localStorage.removeItem("email");
    // window.location.reload();
  });
};
