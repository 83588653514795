import { Checkbox } from '@material-ui/core';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type TProps = {
  control: Control<any>;
  classes?: any;
  errors: any;
  name: string;
  label: string;
  errorMessage?: string;
  className?: string;
};

const CheckboxField: FC<TProps> = props => {
  const {
    name,
    label,
    errorMessage = '',
    control,
    classes = {},
    className = '',
    errors,
  } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Checkbox
              className={className}
              classes={classes}
              checked={field.value}
              onChange={e => field.onChange(e.target.checked)}
            />
            <span
              className="inline-block cursor-pointer"
              onClick={() => field.onChange(!(field.value ?? false))}
            >
              {label}
            </span>
          </>
        )}
      />
      {errors.isActive && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </>
  );
};

export default CheckboxField;
