import { Drawer, TextField, FormControl, InputLabel, NativeSelect, Input, Checkbox, Button } from "@material-ui/core";
import { canDo } from "../../../../utils/permissionCheck";
import React, { ChangeEvent, useState, useEffect } from "react";
import CloseButton from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ISite, ICampus, IUser } from "../../../../interfaces/interfaces";
import { useStyles } from "./styles";
import { useCampuses } from "../../../../hooks/useCampuses";
import { parse, format, isValid } from "date-fns";
import { useSite } from "../../../../hooks/useSite";
import useToast from "../../../../hooks/useToast";

type TProps = {
  siteToEdit: ISite | null;
  open: boolean;
  onClose: () => void;
  loggedInUserData: IUser;
  isCreatingNewSite: boolean;
};

const SiteDrawer: React.FC<TProps> = ({ siteToEdit: initialSiteToEdit, open, onClose, loggedInUserData, isCreatingNewSite }) => {
  const classes = useStyles();

  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [hasModified, setHasModified] = useState(false);
  const [siteToEdit, setSiteToEdit] = useState<ISite | null>(initialSiteToEdit);

  const { campuses } = useCampuses(loggedInUserData?.mongoOrganizationId);
  const { updateSite, unlockSite } = useSite(loggedInUserData?.mongoOrganizationId);

  const { presentToast, ToastComponent } = useToast();

  useEffect(() => {
    setSiteToEdit(initialSiteToEdit);
    setHasModified(false);
  }, [initialSiteToEdit]);

  const formatDateForInput = (dateString: string | undefined) => {
    if (!dateString) return "";
    const date = parse(dateString, "M/d/yyyy h:mm:ss a", new Date());
    return isValid(date) ? format(date, "yyyy-MM-dd") : "";
  };

  const handleGoLiveDateChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value; // This will be a string in "YYYY-MM-DD" format
    if (val && siteToEdit) {
      const date = parse(val, "yyyy-MM-dd", new Date());
      if (isValid(date)) {
        // Preserve the original time part if it exists
        const originalDate = siteToEdit.goLiveDate ? parse(siteToEdit.goLiveDate, "M/d/yyyy h:mm:ss a", new Date()) : date;

        date.setHours(originalDate.getHours());
        date.setMinutes(originalDate.getMinutes());
        date.setSeconds(originalDate.getSeconds());

        setSiteToEdit((prevSite) => ({
          ...prevSite!,
          goLiveDate: format(date, "M/d/yyyy h:mm:ss a"),
        }));
        setHasModified(true);
      } else {
        console.error("Invalid go-live date format.");
      }
    }
  };

  const handleCloseWithoutSaving = () => {
    if (hasModified) {
      setShowSaveAlert(true);
    } else {
      onClose();
    }
  };

  const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setSiteToEdit((prevSite) => ({
      ...prevSite!,
      [name]: name === "timeZoneId" ? parseInt(newValue, 10) : newValue,
    }));
    setHasModified(true);
  };

  const handleUnlockSite = async () => {
    if (!siteToEdit) return;

    try {
      const unlockedSite = await unlockSite(siteToEdit);
      presentToast("success", "Site unlocked successfully!");
      setSiteToEdit(unlockedSite);
      setHasModified(false);
    } catch (err) {
      presentToast("error", "Failed to unlock site. Please try again.");
    }
  };

  const validateForm = (): string | null => {
    if (!siteToEdit) return "No site data available";
    if (!siteToEdit.siteName || siteToEdit.siteName.trim() === "") return "Site name is required";
    if (!siteToEdit.goLiveDate) return "Go Live Date must be set";
    return null;
  };

  const handleSubmit = async () => {
    const error = validateForm();
    if (error) {
      presentToast("error", error);
      return;
    }

    if (!siteToEdit) return;

    try {
      let siteToSave = { ...siteToEdit };

      if (isCreatingNewSite) {
        siteToSave = {
          ...siteToSave,
          mongoOrgId: loggedInUserData.mongoOrganizationId,
        };
      }

      const updatedSite = await updateSite(siteToSave, isCreatingNewSite);
      setHasModified(false);
      setShowSaveAlert(false);
      setSiteToEdit(updatedSite);
      onClose();
      presentToast("success", `Site ${isCreatingNewSite ? "created" : "updated"} successfully!`);
    } catch (err) {
      presentToast("error", "An error occurred while saving. Please try again.");
    }
  };

  return (
    <>
      <Dialog open={showSaveAlert}>
        <DialogTitle>Unsaved changes!</DialogTitle>
        <DialogContent>
          <DialogContentText>Would you like to save your changes before exiting?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleSubmit()}>
            Yes
          </Button>
          <Button color="primary" autoFocus onClick={() => handleCloseWithoutSaving()}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer anchor="right" open={open} onClose={onClose}>
        {siteToEdit && (
          <div className={classes.drawer}>
            <div className={classes.closeButton} onClick={onClose}>
              <CloseButton />
            </div>
            <h2>Editing {siteToEdit.siteName}</h2>
            <br />
            {canDo(["SyncAdmin"], loggedInUserData) ? (
              <div style={{ marginLeft: 8, marginRight: 8, marginTop: 16, marginBottom: 8 }}>
                <span style={{ fontSize: 13, color: "#757575", fontWeight: 400 }}>Site ID</span>
                <div>{siteToEdit.id}</div>
              </div>
            ) : null}
            <TextField
              required
              label="Site Name"
              className={classes.textField}
              value={siteToEdit.siteName}
              onChange={handleChange("siteName")}
              margin="normal"
            />
            <br />
            <FormControl className={classes.textField} margin="normal">
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Campus
              </InputLabel>
              <NativeSelect
                value={siteToEdit.campusId ? siteToEdit.campusId : ""}
                onChange={handleChange("campusId")}
                input={<Input id="age-native-label-placeholder" />}
              >
                <option value=""></option>
                {campuses.map((campus: ICampus, index: number) => (
                  <option key={campus.id} value={campus.id}>
                    {campus.campusName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <br />
            <TextField
              id="date"
              label="Go Live Date"
              type="date"
              value={formatDateForInput(siteToEdit?.goLiveDate)}
              onChange={handleGoLiveDateChanged}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <FormControl className={classes.textField} margin="normal">
              <InputLabel shrink htmlFor="timeZone-native-label-placeholder">
                Time Zone
              </InputLabel>
              <NativeSelect value={siteToEdit.timeZoneId || 9} onChange={handleChange("timeZoneId")} input={<Input id="timeZone-native-label-placeholder" />}>
                <option value={3}>HST</option>
                <option value={4}>AKST</option>
                <option value={6}>PST</option>
                <option value={7}>MST-AZ</option>
                <option value={9}>MST</option>
                <option value={10}>CST</option>
                <option value={15}>EST</option>
                <option value={16}>AST-PR</option>
              </NativeSelect>
            </FormControl>
            <br />
            <div
              onClick={() => {
                setSiteToEdit((prevSite) => ({
                  ...prevSite!,
                  isActive: !prevSite!.isActive,
                }));
              }}
              style={{ cursor: "pointer" }}
            >
              <Checkbox
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={siteToEdit.isActive}
              />
              Is Active
            </div>
            {siteToEdit.isLocked && canDo(["SyncAdmin"], loggedInUserData) && (
              <Button className={classes.unlockButton} variant="contained" onClick={() => handleUnlockSite()}>
                Unlock Site
              </Button>
            )}
            {hasModified ? <div className={classes.saveWarning}>Unsaved Changes!</div> : <div />}
            <Button className={classes.submitButton} onClick={() => handleSubmit()} color="primary">
              Save
            </Button>
          </div>
        )}
        <ToastComponent />
      </Drawer>
    </>
  );
};

export default SiteDrawer;
