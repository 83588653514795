import { createTheme, makeStyles, Theme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    secondary: {
      main: "#A7D6A4",
    },
  },
});

export const styles: any = (theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  newPatientContentButton: {
    zIndex: 10,
    backgroundColor: "#4FAC48",
    position: "absolute",
    right: theme.spacing(3),
  },
  drawer: {
    padding: 50,
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  closeButton: {
    cursor: "pointer",
    color: "red",
    textAlign: "right",
  },
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
  saveWarning: {
    color: "red",
    paddingBottom: 10,
  },
  newSurveyModal: {
    position: "absolute",
    width: "50%",
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export const useStyles = makeStyles(styles);