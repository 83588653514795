import React, { FC, useEffect } from 'react';
import { IUser, TAdminState } from '../../interfaces/interfaces';
import { isEmpty } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Admin from './Admin';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchLoggedInUser } from '../../actions/adminActions';
import { UnknownAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';


type TProps = {
  user: IUser;
};

const AdminContainer: FC<TProps> = props => {
  const { loggedInUserData } = useSelector(
    (state: { adminData: TAdminState }) => {
      return {
        loggedInUserData: state.adminData.loggedInUserData,
        errorLoggedInUser: state.adminData.errorLoggedInUser,
        organization: state.adminData.organization,
        permissions: state.adminData.permissions,
      };
    },
    shallowEqual
  );

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, UnknownAction>>();

  useEffect(() => {
    let userEmail = localStorage.getItem('email');
    let token = localStorage.getItem('token');

    let lastMockedOrganizationId = null;
    lastMockedOrganizationId = localStorage.getItem('lastMockedOrganizationId');

    if (userEmail && token) {
      dispatch(fetchLoggedInUser(userEmail, token, lastMockedOrganizationId));
    }

    if (!userEmail || !token) {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      console.log('FIRE IT');
      window.location.replace('/login');
    }

    // eslint-disable-next-line
  }, []);


  if(isEmpty(loggedInUserData)) return null;


  // return <PrettyData data={{ loggedInUserData }} />;

  return (
    <DndProvider backend={HTML5Backend}>
      <Admin />
    </DndProvider>
  );
};

export default AdminContainer;
