// create static overlay variable to lock or unlock
let overlay: HTMLDivElement;

export function lockScreen(textToDisplay?: string) {
    const innerText = textToDisplay ? textToDisplay : "Site is locked";
    // show blank white text over everything on page
    overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    // center text on page
    overlay.style.display = "flex";
    overlay.style.justifyContent = "center";
    overlay.style.alignItems = "center";
    overlay.style.fontSize = "50px"; // Replace with your desired font size
    overlay.style.backgroundColor = "white";
    overlay.style.color = "black";
    overlay.style.zIndex = "9999";
    overlay.innerText = innerText;

    document.body.appendChild(overlay);
}