import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, LabelList, Legend } from "recharts";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const styles = (theme) => ({
  cardContainer: {
    fontSize: 12,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 5,
    // marginTop: 5,
    border: "2px solid white",
    // backgroundColor: "transparent"
  },
  cardContainerDark: {
    fontSize: 12,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 5,
    // marginTop: 5,
    border: "2px solid #666666",
    backgroundColor: "#666666",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  cardTitle: {
    textAlign: "center",
    fontSize: 30,
    paddingLeft: 5,
    paddingRight: 5,
  },
  barChart: {
    margin: "auto",
  },
});

class CycleTimesCard extends Component {
  render() {
    const { classes } = this.props;

    let darkMode = false;
    if (localStorage.getItem("darkMode")) {
      let isDark = localStorage.getItem("darkMode");
      if (JSON.parse(isDark)) {
        darkMode = true;
      } else {
        darkMode = false;
      }
    }

    // var results = [
    //   {
    //     id: null,
    //     name: "Rick",
    //     Value: "34343",
    //   },
    //   {
    //     id: 2,
    //     name: null,
    //     Value: "2332",
    //   },
    //   {
    //     id: 2,
    //     name: "mike",
    //     Value: null,
    //   },
    // ];

    // results.forEach(function (object) {
    //   for (key in object) {
    //     if (object[key] == null) object[key] = "";
    //   }
    // });

    // console.log("RESULTS!", results);

    let filteredCycleTimes = this.props.providerReporting.dailyData.sort(function (a, b) {
      if (a["aloneTime"] + a["providerTime"] + a["staffTime"] + a["waitTime"] > 0) {
        if (a["aloneTime"] + a["providerTime"] + a["staffTime"] + a["waitTime"] > b["aloneTime"] + b["providerTime"] + b["staffTime"] + b["waitTime"]) {
          return -1;
        }
        if (a["aloneTime"] + a["providerTime"] + a["staffTime"] + a["waitTime"] < b["aloneTime"] + b["providerTime"] + b["staffTime"] + b["waitTime"]) {
          return 1;
        }
        return 0;
      }
      return null;
    });

    // let topSixProviders = filteredCycleTimes.slice(0, 6);
    let topSixProviders = filteredCycleTimes;

    // Replace 0 values with NULL to prevent from being rendered in chart
    topSixProviders.forEach(function (o) {
      Object.keys(o).forEach(function (key) {
        if (o[key] === 0) {
          o[key] = null;
        }
      });
    });

    // console.log("top six", topSixProviders);

    return (
      // Recharts bug causing Y axis to not update correctly, so we are using a 'key' on the containing div to reference a always changing servertime to force everything to update on a prop change
      <div className={"noselect"} style={{ maxWidth: 257 }}>
        <div style={{ backgroundColor: this.props.roomColor, height: 10, width: "100%", marginBottom: 5 }}></div>

        <div className={classes.flexContainer} key={this.props.providerReporting.serverTime}>
          {/* {console.log("data", this.props.providerReporting)} */}
          {this.props.providerReporting.dailyData.length > 0 ? (
            <Card className={darkMode ? classes.cardContainerDark : classes.cardContainer} style={this.props.largeCards ? { height: 424 } : { height: 330 }}>
              <div style={darkMode ? { backgroundColor: "#333333", border: "2px solid #333333", color: "white" } : { border: "2px solid white" }}>
                <div className={classes.cardTitle}>Cycle Times</div>

                <BarChart
                  className={classes.barChart}
                  width={this.props.largeCards ? 513 : 250}
                  height={this.props.largeCards ? 390 : 290}
                  // data={this.props.providerReporting.dailyData}
                  data={topSixProviders}
                  layout="vertical"
                >
                  <XAxis hide={true} type="number" />
                  <YAxis type="category" dataKey="providerName" />
                  <Legend verticalAlign="top" />

                  <LabelList dataKey="name" position="top" />

                  <Bar name="Provider" dataKey="providerTime" stackId="a" fill="#8AD4EB" isAnimationActive={false}>
                    <LabelList fill="#ffffff" dataKey="providerTime" position="center" fontSize={this.props.largeCards ? 16 : 12} />
                  </Bar>

                  <Bar name="Staff" dataKey="staffTime" stackId="a" fill="#01B8AA" isAnimationActive={false}>
                    <LabelList fill="#ffffff" dataKey="staffTime" position="center" fontSize={this.props.largeCards ? 16 : 12} />
                  </Bar>

                  <Bar name="Alone" dataKey="aloneTime" stackId="a" fill="#FD625E" isAnimationActive={false}>
                    <LabelList fill="#ffffff" dataKey="aloneTime" position="center" fontSize={this.props.largeCards ? 16 : 12} />
                  </Bar>

                  <Bar name="Wait" dataKey="waitTime" stackId="a" fill="#F2C81E" isAnimationActive={false}>
                    <LabelList fill="#ffffff" dataKey="waitTime" position="center" fontSize={this.props.largeCards ? 16 : 12} />
                  </Bar>
                </BarChart>
              </div>
            </Card>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CycleTimesCard);
