import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from '../../../../../apiconfig';

import { isEmpty } from 'lodash';
import { RootState } from '../../../../../store';
import { useStyles } from './styles';

type TProps = {
  deviceId: string;
  onUpdate: (data: any) => void;
  latestImage: string;
};

const DeviceControl: FC<TProps> = props => {
  const { deviceId, latestImage, onUpdate } = props;

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const classes = useStyles();

  const { loggedInUserData } = useSelector((state: RootState) => {
    const adminState = state.adminData;
    return {
      ...adminState,
    };
  });

  const onMouseMove = (e: any) => {
    setX(e.nativeEvent.offsetX);
    setY(e.nativeEvent.offsetY);
  };

  const onSendClick = () => {
    let img: any = document.getElementById('image');

    if (img) {
      // console.log("height", img.naturalHeight);
      // console.log("width", img.naturalWidth);

      let clickData = {
        userId: loggedInUserData.userId,
        xClick: (x / img.width) * 100,
        yClick: (y / img.height) * 100,
        examRooms: [{ deviceId: deviceId }],
      };

      // console.log("click data", JSON.stringify(clickData));

      let token = localStorage.getItem('token');
      // console.log("token", token);
      // console.log(JSON.stringify(clickData));

      fetch(API.REACT_APP_API_SENDCLICK, {
        method: 'POST',
        body: JSON.stringify(clickData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          res.json().then(data => {
            onUpdate(data);
          });
        })
        .catch(err => {
          console.log('Error sending click', err);
        });
    }
  };

  return (
    <div>
      <div className={classes.center}>
        {isEmpty(latestImage) ? (
          <div />
        ) : (
          <div>
            <img
              className={classes.latestImage}
              alt=""
              id="image"
              onClick={() => onSendClick()}
              onMouseMove={event => onMouseMove(event)}
              src={latestImage}
            />
          </div>
        )}
      </div>
      <br />
    </div>
  );
};

export default DeviceControl;
