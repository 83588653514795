import React, { Component } from "react";
import { MdRoom } from "react-icons/md";
// import { getQueryVariable } from "../../utils/utils";
// import { Redirect } from "react-router";
// import { getCookie } from "../../utils/utils";
// import _ from "lodash";

class Flowmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [
        { name: "Inside Room 1", x: 420, y: 340 },
        { name: "Outside Room 1", x: 420, y: 270 },
      ],
    };
  }

  // change = () => {
  //   console.log("change!");
  //   this.setState({
  //     x: this.state.x + 100,
  //   });
  // };

  render() {
    return (
      <div
        style={{
          backgroundColor: "lightpink",
          // resize: "horizontal",
          overflow: "hidden",
          width: "100%",
          height: "auto",
        }}
      >
        <svg width="100%" viewBox="0 0 972 600" preserveAspectRatio="xMidYMid meet">
          <g>
            <image href="https://i.pinimg.com/originals/b5/07/a4/b507a450c131cd1281953e51edc6881d.jpg" />
            {/* <MdRoom size={30} x={this.state.x} y={250} fill={"red"} onClick={() => this.change()} /> */}
            {this.state.locations.map((location, index) => (
              <MdRoom key={index} size={30} x={location.x} y={location.y} fill={"red"} />
            ))}
          </g>
        </svg>
      </div>
    );
  }
}

export default Flowmap;
