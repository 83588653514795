import * as signalR from '@microsoft/signalr';
import { FunctionsEndpoint } from '../../apiconfig';
import { getQueryVariable } from '../../utils/utils';

class SignalRManager {
  private static instance: SignalRManager;
  private connection: signalR.HubConnection | null = null;
  private allowSignalR: boolean = false;
  private es6Ready: boolean = false;

  private constructor() {
    const disableSignalManually = getQueryVariable('disableSignal');
    if (!disableSignalManually) {
      this.buildConnection();
    }
  }

  public static getInstance(): SignalRManager {
    if (!SignalRManager.instance) {
      SignalRManager.instance = new SignalRManager();
    }
    return SignalRManager.instance;
  }

  private getBearerToken(): string {
    return localStorage.getItem('token') || '';
  }

  private buildConnection(): boolean {
    try {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(FunctionsEndpoint + '/api', {
          accessTokenFactory: this.getBearerToken,
        })
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: () => 5000,
        })
        .build();

      this.allowSignalR = true;
      this.es6Ready = true;

      this.setupConnectionHandlers();
      return true;
    } catch (err) {
      console.log('SignalR issue', err);
      return false;
    }
  }

  private setupConnectionHandlers(): void {
    if (this.connection) {
      this.connection.on('consoleLog', (message: string) => {
        console.log('Signal Console!', message);
      });

      this.connection.onclose(async () => {
        this.allowSignalR = false;
        await this.start();
      });
    }
  }

  public async start(): Promise<void> {
    if (!this.connection) return;

    try {
      this.connection.serverTimeoutInMilliseconds = 10000;
      this.connection.keepAliveIntervalInMilliseconds = 5000;

      await this.connection.start();
      console.log('%c SignalR Connected ', 'background: #020F3D; color: #ffffff');
      this.allowSignalR = true;
    } catch (err) {
      console.log('ERR TRYING TO CONNECT TO SIGNALR', err);
      setTimeout(() => this.start(), 5000);
    }
  }

  public getConnection(): signalR.HubConnection | null {
    return this.connection;
  }

  public isAllowSignalR(): boolean {
    return this.allowSignalR;
  }

  public isES6Ready(): boolean {
    return this.es6Ready;
  }
}

const signalRManager = SignalRManager.getInstance();

export default signalRManager;