import {
  Button,
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { IAction, IDepartment } from '../../../../../../interfaces/interfaces';

import AddIcon from '@material-ui/icons/Add';

import { API } from '../../../../../../apiconfig';
import { useDepartment } from '../../../../../../hooks/department/useDepartment';
import { checkRefreshToken } from '../../../../../../utils/utils';
import { DeleteButton } from '../../DeleteButton';

type TProps = {
  department: IDepartment;
  classes: any;
};

const DepartmentAutoClearActions: FC<TProps> = props => {
  const { department, classes } = props;

  const [autoClearUserType, setAutoClearUserType] = useState<number>(1);
  const [newClearActionAnchorEl, setNewClearActionAnchorEl] = useState<
    any | null
  >(null);

  const [newAutoClearActionError, setNewAutoClearActionError] =
    useState<boolean>(false);

  const [clearOnEnter, setClearOnEnter] = useState<boolean>(false);
  const [selectedAutoClearAction, selectAutoClearAction] =
    useState<IAction | null>(null);

  const { update, refetch } = useDepartment({
    departmentId: department.id,
  });

  const handleAutoClearActionPopper = (event: any) => {
    setNewClearActionAnchorEl(event.currentTarget);
  };

  const closeNewClearActionPopper = () => {
    setNewClearActionAnchorEl(null);
  };

  // const assignSelectedActionIcon = (action: IAction) => {
  //   // closeSchedulePopper();
  //   // selectScheduleAction(action);
  //   // setNewScheduledActionError(false);
  //   // console.log("Assigning selecting action", action);
  // };

  const handleAutoClearUserType = (event: any) => {
    setAutoClearUserType(event.target.value);
  };

  const addAutoClearAction = () => {
    if (selectedAutoClearAction) {
      let autoClearActionsToModify: IAction[] = [];

      if (department.autoClearActions) {
        autoClearActionsToModify = department.autoClearActions;
      }

      // "actionId": 0,
      // "mongoActionId": "string",
      // "deptId": 0,
      // "clearOnEnter": true,
      // "userTypeId": 0

      let newAutoClearAction: any = {
        // actionId: selectedAutoClearAction.actionId,
        mongoActionId: selectedAutoClearAction.id,
        // deptId: department.id,
        // deptId: department.deptId,
        clearOnEnter: clearOnEnter,
        userTypeId: autoClearUserType,
      };

      // console.log("NewAutoClearAction", newAutoClearAction);

      autoClearActionsToModify.push(newAutoClearAction);

      let modifiedDepartment = {
        ...department,
        autoClearActions: autoClearActionsToModify,
      };

      let token = localStorage.getItem('token');

      // console.log("ALRIGHT", modifiedDepartment);

      fetch(API.REACT_APP_API_UPDATE_DEPARTMENT, {
        method: 'PUT',
        body: JSON.stringify(modifiedDepartment),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(function (res) {
          return res.json();
        })
        .then(data => {
          console.log('Success', data);
          refetch(department?.id);
          selectAutoClearAction(null);
          setNewClearActionAnchorEl(null);
        })
        .catch(function (err) {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    } else {
      setNewAutoClearActionError(true);
    }
  };

  const assignAutoClearActionIcon = (action: IAction) => {
    closeNewClearActionPopper();
    selectAutoClearAction(action);
    setNewAutoClearActionError(false);
  };

  const deleteAutoClearAction = async (actionId: string) => {
    let autoClearActionsToModify = department?.autoClearActions;
    for (let i = 0; i < autoClearActionsToModify.length; i++) {
      if (autoClearActionsToModify[i].mongoActionId === actionId) {
        autoClearActionsToModify.splice(i, 1);
      }
    }

    let modifiedDepartment = {
      ...department,
      autoClearActions: autoClearActionsToModify,
    };

    return update({
      departmentId: department.id,
      data: modifiedDepartment,
    });
  };

  return (
    <div>
      {department.autoClearActions ? (
        <div>
          {department.autoClearActions.map((action: IAction, index: number) => (
            <div key={index} className={classes.scheduledActionsContainer}>
              <Grid container>
                <div>
                  {department.actionsList.map((imageAction: IAction) => (
                    <div key={imageAction.id}>
                      {action.mongoActionId === imageAction.id ? (
                        <img
                          className={classes.scheduledActionImage}
                          src={imageAction.actionImageUrl}
                          alt="#"
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  ))}
                </div>
                <div>
                  <div style={{ display: 'flex' }}>
                    {action.clearOnEnter ? (
                      <strong>Clear on Enter</strong>
                    ) : (
                      <strong>Clear on Exit</strong>
                    )}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <strong>User Type: </strong>
                    {action.userTypeId === 1 ? <div>Staff</div> : <div />}
                    {action.userTypeId === 4 ? <div>Provider</div> : <div />}
                  </div>
                </div>
              </Grid>
              <div>
                <DeleteButton
                  onClick={() => deleteAutoClearAction(action.mongoActionId)}
                >
                  Delete Action
                </DeleteButton>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div />
      )}
      <br />
      <div>
        <Typography variant="h5">New Auto Clear Action</Typography>
        <div className={classes.imageSelectContainer}>
          <br />
          <Tooltip
            title="Choose an icon to automatically clear"
            placement="right"
          >
            <div>
              {selectedAutoClearAction ? (
                <img
                  onClick={handleAutoClearActionPopper}
                  src={selectedAutoClearAction?.actionImageUrl}
                  className={classes.selectedScheduledActionImage}
                  alt="#"
                />
              ) : (
                <div>
                  <div
                    className={
                      newAutoClearActionError
                        ? classes.errorAddIconSquare
                        : classes.addIconSquare
                    }
                    onClick={handleAutoClearActionPopper}
                  >
                    <AddIcon
                      className={classes.addIconStyle}
                      style={{ fontSize: 35 }}
                    />
                  </div>
                  {newAutoClearActionError ? (
                    <div className={classes.scheduledActionErrorMessage}>
                      You must select an action first!
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              )}
            </div>
          </Tooltip>

          <Popover
            open={Boolean(newClearActionAnchorEl)}
            anchorEl={newClearActionAnchorEl}
            onClose={closeNewClearActionPopper}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.scheduleActionImageContainer}>
              {department.actionsList ? (
                <div>
                  {department.actionsList.map((imageAction: IAction) => (
                    <img
                      key={imageAction.id}
                      className={classes.selectedScheduledActionImage}
                      src={imageAction.actionImageUrl}
                      alt="#"
                      onClick={() => assignAutoClearActionIcon(imageAction)}
                    />
                  ))}
                </div>
              ) : (
                <div />
              )}
            </div>
          </Popover>
        </div>
        <Tooltip
          title="Whether this icon should clear on 'enter' or 'exit'"
          placement="right"
        >
          <div
            onClick={() => {
              setClearOnEnter(!clearOnEnter);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={clearOnEnter}
            />
            Clear on Enter
          </div>
        </Tooltip>

        <br />
        <Tooltip
          title="Whether to clear/exit if it's a Provider or Staff member"
          placement="right"
        >
          <div>
            <InputLabel>User Type</InputLabel>
            <br />
            <Select
              value={autoClearUserType}
              onChange={handleAutoClearUserType}
            >
              <MenuItem value={1}>Staff</MenuItem>
              <MenuItem value={4}>Provider</MenuItem>
            </Select>
          </div>
        </Tooltip>

        <br />
        <Button
          className={classes.addDeviceButton}
          // align="right"
          onClick={() => addAutoClearAction()}
          component="label"
          color="primary"
        >
          Add Auto Clear Action
        </Button>
      </div>
    </div>
  );
};

export default DepartmentAutoClearActions;
