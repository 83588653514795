import { FC, useEffect } from 'react';
import { IDepartment } from '../../../../interfaces/interfaces';
import { connection } from '../../../../socket-manager';

type TProps = {
  department: IDepartment;
};

const DeptSubscription: FC<TProps> = props => {
  const { department } = props;

  useEffect(() => {
    connection.send('subscribeToFlowstationWithRoomUpdatesGzip', department.id);
    connection.send('subscribeToSiteGzip', department.mongoSiteId);
  }, [department]);

  return <div></div>;
};

export default DeptSubscription;
