import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import clinicimage2 from "../images/clinicimage2.jpg"
// import synctimesLogo from "../images/synctimes.png";

// import { API } from "../apiconfig";

const styles = (theme) => ({});


class ClinicMapCreator extends Component {
    constructor() {
        super();
        this.state = {
            coordArray: [],
        };

        if (localStorage.getItem("testClinicCoords")) {
            this.state.coordArray = JSON.parse(localStorage.getItem("testClinicCoords"));
        }
    }

    getImageDetails = (e) => {
        console.log("Image Clicked", e.clientX, e.clientY);

        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.



        this.setState({
            coordArray: [...this.state.coordArray, { x, y, roomName: "" }]
        })
    }

    saveToLocalStorage = () => {
        localStorage.setItem("testClinicCoords", JSON.stringify(this.state.coordArray));

    }

    render() {
        // const { classes } = this.props;

        // get width and height of clinicimage
        let image = new Image();
        image.src = clinicimage2; // <-- CHANGE THIS TO THE IMAGE BEING USED


        image.onload = () => {
            console.log("image width", image.width, "image height", image.height);
        }

        console.log("CoordArray", this.state.coordArray)



        return (
            <div
                style={{ display: "flex" }}
            >
                <div>

                    <div className="img-overlay-wrap" onMouseDown={(e) => this.getImageDetails(e)}>


                        <img src={clinicimage2} alt="clinic" id="clinicmapimage" style={{ minWidth: image.width }}
                        // style={{ width: "75vw" }}
                        // style={{ minWidth: 1000 }}
                        />

                        {/* Notes
                         if we are drawing on the image, WIDTH AND VIEWBOX MUST BE THE SAME
                        if we are just displaying the maping coords, the viewbox must be the same as the original image size
                        */}

                        <svg
                            // SET THE VIEWBOX TO THE SAME SIZE AS THE WIDTH AND HEIGHT OF THE IMAGE WHEN WE WERE SELCTING THE COORDINATES
                            viewBox={
                                `0 0 ${image.width} ${image.height}`
                                // `0 0 1000 ${image.height}`
                            }
                            xmlns="http://www.w3.org/2000/svg"
                        >


                            {this.state.coordArray.map((coord, index) => {
                                return (
                                    <g key={index}>
                                        <circle cx={coord.x} cy={coord.y} r="30" stroke="black" strokeWidth="3" fill="pink" shapeRendering="geometricPrecision" />
                                        <text x={coord.x - 20} y={coord.y} fontSize="12" fill="black" fontWeight="bold">{coord.roomName}</text>
                                    </g>
                                )
                            })}




                        </svg>

                    </div>
                </div>
                <div>
                    CoordArray
                    {this.state.coordArray.map((coord, index) => {
                        return (
                            <div key={index}>X: {coord.x}, Y: {coord.y}
                                <input value={coord.roomName} onChange={(event) => this.setState({
                                    coordArray: this.state.coordArray.map((item, i) => {
                                        if (i === index) {
                                            return { ...item, roomName: event.target.value }
                                        }
                                        return item;
                                    })
                                })}
                                />
                                <span onClick={() =>
                                    // delete the item from the array
                                    this.setState({
                                        coordArray: this.state.coordArray.filter((item, i) => i !== index)
                                    })
                                }>X</span>

                            </div>
                        )
                    })}
                    <br />
                    <button onClick={this.saveToLocalStorage}>Save to LocalStorage</button>
                    <button onClick={
                        () => {
                            localStorage.removeItem("testClinicCoords");
                            this.setState({ coordArray: [] });
                        }
                    }>Delete LocalStorage</button>
                </div>


            </div>
        );
    }
}

export default withStyles(styles)(ClinicMapCreator);
