import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IInstrument } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useInstruments = (
  orgId: string | null
): {
  instruments: IInstrument[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IInstrument[]>;
} => {
  const [instruments, setInstruments] = useState<IInstrument[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchInstruments = useCallback(async (): Promise<IInstrument[]> => {
    if (!orgId) {
      return [] as IInstrument[];
    }

    setIsLoading(true);
    setError(null);

    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETINSTRUMENTSBYORGID + orgId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    return axios
      .get(URL, headers)
      .then(response => {
        setIsLoading(false);
        const instruments: IInstrument[] = response.data.data;
        setInstruments(instruments);

        return instruments;
      })
      .catch(err => {
        console.log('Error: ', err);
        setIsLoading(false);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        return [] as IInstrument[];
      });
  }, [orgId]);

  useEffect(() => {
    fetchInstruments();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IInstrument[]> => {
    return fetchInstruments();
  }, [fetchInstruments]);

  return {
    instruments,
    isLoading,
    error,
    refetch,
  };
};
