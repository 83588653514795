export const FETCH_LOGGED_IN_USER = "FETCH_LOGGED_IN_USER";
export const FETCH_GROUP = "FETCH_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const FETCH_APP_USERS_BY_ORG_ID = "FETCH_APP_USERS_BY_ORG_ID";
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_CAMPUSES = "FETCH_CAMPUSES";
export const FETCH_SITES = "FETCH_SITES";
export const FETCH_ORGANIZATIONDEPARTMENTS = "FETCH_ORGANIZATIONDEPARTMENTS";
export const FETCH_DEPARTMENT = "FETCH_DEPARTMENT";
export const FETCH_ALLACTIONS = "FETCH_ALLACTIONS";
export const FETCH_SOUNDS = "FETCH_SOUNDS";
export const FETCH_ROOMS_BY_ORG_ID = "FETCH_ROOMS_BY_ORG_ID";
export const FETCH_ALL_DEVICE_DETAILS = "FETCH_ALL_DEVICE_DETAILS";
export const FETCH_ALL_DISCONNECTED_DEVICES = "FETCH_ALL_DISCONNECTED_DEVICES";
export const FETCH_DEVICE_DETAIL = "FETCH_DEVICE_DETAIL";
export const UPDATE_DEVICE_DETAIL = "UPDATE_DEVICE_DETAIL";
export const FETCH_ALL_APPS = "FETCH_ALL_APPS";
export const FETCH_REPORTING = "FETCH_REPORTING";
export const FETCH_SECURITYDEPTS = "FETCH_SECURITYDEPTS";
export const UPDATE_SECURITYDEPTS = "UPDATE_SECURITYDEPTS";
export const FETCH_SIBLINGDEPTS = "FETCH_SIBLINGDEPTS";
export const UPDATE_SIBLINGDEPTS = "UPDATE_SIBLINGDEPTS";
export const FETCH_DEVICE_LOCATIONS = "FETCH_DEVICE_LOCATIONS";
export const FETCH_AND_UPDATE_SIBLINGDEPTS = "FETCH_AND_UPDATE_SIBLINGDEPTS";
export const FETCH_STAFF_LOCATIONS = "FETCH_STAFF_LOCATIONS";
export const FETCH_INSTRUMENTS_BY_ORG_ID = "FETCH_INSTRUMENTS_BY_ORG_ID";
export const FETCH_SITE = "FETCH_SITE";
export const FETCH_ORG_PROVIDERS = "FETCH_ORG_PROVIDERS";
export const FETCH_DISTINCT_ORG_ACTIONS = "FETCH_DISTINCT_ORG_ACTIONS";
export const ERROR_LOGGED_IN_USER = "ERROR_LOGGED_IN_USER";
export const ERROR_LOGOUT_USER = "ERROR_LOGOUT_USER";
export const FETCH_TRAINING_DOCUMENTATION = "FETCH_TRAINING_DOCUMENTATION";
export const FETCH_ORGANIZATION = "FETCH_ORGANIZATION";
export const SET_DEPT_HISTORY = "SET_DEPT_HISTORY";
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const FETCH_LOBBYDEPTS = "FETCH_LOBBYDEPTS";
export const UPDATE_LOBBYDEPTS = "UPDATE_LOBBYDEPTS";
export const FETCH_ORGANIZATION_DEPTS = "FETCH_ORGANIZATION_DEPTS";
export const FETCH_PROVIDER_ASSIGNMENT_USERS = "FETCH_PROVIDER_ASSIGNMENT_USERS";
export const FETCH_PATIENT_CONTENT_BY_ORG_ID = "FETCH_PATIENT_CONTENT_BY_ORG_ID";
export const FETCH_CLINIC_MAPS_BY_ORG_ID = "FETCH_CLINIC_MAPS_BY_ORG_ID";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
