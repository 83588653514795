import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IDepartment } from '../../../../interfaces/interfaces';
import { IProviderWaitingRoomItem, TLobbySequenceLanguage } from '../../types';
import ProviderCard from './ProviderCard';

type TProps = {
  providers: IProviderWaitingRoomItem[];
  language: TLobbySequenceLanguage;
  departments: IDepartment[];
};

// const Browser = detect();

const ProviderGrid: React.FC<TProps> = props => {
  const { providers, language } = props;
  const [scale, setScale] = useState<number>(1);

  const prevProvidersLength = useRef(providers.length);

  useEffect(() => {
    resizeLobby();
    window.addEventListener('resize', resizeLobby);
    return () => window.removeEventListener('resize', resizeLobby);

    // eslint-disable-next-line
  }, [providers]);

  const resizeLobby = useCallback(() => {
    if (providers.length > 0) {
      // Ensure JSX elements are rendered prior
      const lobbyNavbar = document.getElementById('lobbynavbar');
      const patientCard = document.getElementById('patientcard');

      if (lobbyNavbar && patientCard) {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let navBarHeight = lobbyNavbar.clientHeight + 10;
        let zoomPercent = 1;
        let isIncreasing = false;
        let isEscapeLoop = false;
        let incrementAmount = 0.02;

        // total card container height
        windowHeight = windowHeight - navBarHeight;

        let visibleCards = providers.length;

        let cardWidth = 256;
        let cardHeight = 336;

        while (!isEscapeLoop) {
          let cardsHorizontal = Math.floor(
            windowWidth / (zoomPercent * cardWidth)
          );
          let currentVerticalRows = Math.ceil(visibleCards / cardsHorizontal);
          let totalHeight = zoomPercent * cardHeight * currentVerticalRows;

          if (totalHeight > windowHeight) {
            // zoom goes down
            if (isIncreasing === null || !isIncreasing) {
              isIncreasing = false;
              zoomPercent = zoomPercent - incrementAmount;
            } else {
              isEscapeLoop = true;
            }
          } else {
            // zoom goes up
            isIncreasing = true;
            zoomPercent = zoomPercent + incrementAmount;
          }
        }

        // setZoom(zoomPercent * 95);
        setScale(zoomPercent * 0.95);
      }
    }
  }, [providers]);

  useEffect(() => {
    const handleResize = () => {
      resizeLobby();
    };

    resizeLobby();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [resizeLobby]);

  useEffect(() => {
    if (providers.length !== prevProvidersLength.current) {
      prevProvidersLength.current = providers.length;
      resizeLobby();
    }
  }, [providers, resizeLobby]);

  useEffect(() => {
    if (window.innerWidth < 500) {
      console.log('Small screen detected, setting zoom to 45%');
      // setZoom(45);
      setScale(0.45);
    }
  }, []);

  // Force a resize calculation after a short delay
  useEffect(() => {
    const timer = setTimeout(() => {
      resizeLobby();
    }, 500);

    return () => clearTimeout(timer);
  }, [resizeLobby]);

  return (
    <div>
      <div style={{ height: '100vh', backgroundSize: 'cover' }}>
        <div id="lobbynavbar">
          <div
            id="lobbyCardContainer"
            // style={{ zoom: Browser.name !== 'ie' ? `${zoom}%` : '' }}
            style={{
              transform: `scale(${scale})`,
              transformOrigin: 'top left',
            }}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {providers.map((provider, index) => (
                <ProviderCard
                  key={index}
                  provider={provider}
                  language={language}
                  cardWidth={256}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderGrid;