import { alpha, makeStyles, Theme } from '@material-ui/core';

const styles: any = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },

  customBadge: {
    backgroundColor: '#4CB946',
    color: 'white',
  },
  newAlertModal: {
    outline: 'none',
    position: 'absolute',
    width: '650px',
    backgroundColor: '#FFFFFF',
    color: 'black',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
  },
  formControlRoot: {
    borderWidth: '1px',
    borderColor: 'white',
    color: '#ffffff',
    '& label.MuiInputLabel-outlined': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
  inputLabelRoot: {
    color: '#ffffff',
    fontFamily: 'Roboto Mono',
    '&.focused': {
      color: '#ffffff',
    },
  },

  paper: {
    backgroundColor: 'transparent',
    // boxShadow: "none",
    overflow: 'hidden',
  },

  grow: {
    flexGrow: 1,
  },
  smallSyncLogo: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  orgName: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    border: '1px solid #E8E8E8',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    borderRadius: 4,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    marginLeft: 16,
    display: 'flex',
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    boxShadow: '0px 0px 5px 0px #707070',
    // color: "#000000",
    marginBottom: 10,
    backgroundColor: '#ffffff',
  },
  headerImage: {
    maxheight: 45,
    paddingTop: 5,
    maxWidth: 200,
  },
  siteName: {
    fontSize: 25,
    paddingLeft: 20,
    // color: "#7e7e7e",
  },
  siteTime: {
    fontSize: 16,
    paddingLeft: 20,
    // color: "#7e7e7e",
  },
  // siteTime: {
  //   position: "fixed",
  //   bottom: 0,
  //   left: 0,
  //   fontSize: 20,
  //   paddingLeft: 20,
  //   color: "#7e7e7e",
  // },
  drawerTitle: {
    textAlign: 'center',
    fontSize: 25,
    padding: 20,
  },
  rightToolBar: {
    marginLeft: 'auto',
    marginRight: '-12px',
    display: 'flex',
    alignItems: 'center',
  },
  sortCheckbox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
  zoomContainer: {
    textAlign: 'center',
    marginLeft: '-40px',
  },
  zoomInput: {
    width: '33%',
  },
  zoomButtons: {
    padding: 0,
    fontSize: 31,
  },
  drawerSupport: {
    paddingTop: 10,
    color: 'gray',
    fontSize: 15,
    textAlign: 'center',
  },
  logoutButton: {
    width: '100%',
    backgroundColor: '#EBEBEB',
  },
  loginButton: {
    marginTop: 25,
    width: '100%',
    backgroundColor: '#50b848',
    color: '#FFFFFF',
  },
  sortByProviderSwitch: {
    marginLeft: 0,
  },
  homeButton: {
    width: '100%',
    marginTop: 50,
    marginBottom: 25,
  },
  emptyParamText: {
    position: 'absolute',
    top: '35%',
    width: '100%',
    textAlign: 'center',
    fontSize: 24,
    verticalAlign: 'middle',
  },
  closeButton: {
    padding: 10,
    cursor: 'pointer',
    color: 'red',
    textAlign: 'right',
  },
  headerImageLarge: {
    width: 300,
    paddingBottom: 20,
  },
  loginTextButton: {
    textDecoration: 'none',
    color: '#4CB946',
  },
  fullHeight: {
    width: '100%',
    height: 'auto',
    bottom: 0,
    top: 0,
    left: 0,
    position: 'absolute',
  },
  frontDeskSideBar: {
    marginTop: 35,
    backgroundColor: '#FCFCFC',
    border: '1px solid lightgray',
    // minHeight: "90vh",
    // maxHeight: "90vh",
    float: 'right',
    width: 250,
  },
  colorSwitchBase: {
    color: '#4FAC48',
    '&$colorChecked': {
      color: '#4FAC48',
      '& + $colorBar': {
        backgroundColor: '#4FAC48',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  expansionPanel: {
    marginLeft: 10,
    marginRight: 10,
  },
  expansionPanelDetails: {
    display: 'block',
  },
  updateSnack: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  updateMessage: {
    fontSize: 24,
    // display: "flex",
    // alignItems: "center",
  },
  updateIcon: {
    color: '#4CB946',
    fontSize: 34,
    opacity: 0.9,
  },
  flowstationContainer: {
    height: '100vh',
    backgroundSize: 'cover',
  },
  updateMoreDetails: {
    fontSize: 16,
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: 24,
    paddingLeft: 5,
    paddingRight: 5,
    color: 'red',
  },
  socketDisconnected: {
    zIndex: 10,
    padding: '10px 20px',
    margin: 10,
    borderRadius: 10,
    // backgroundColor: "orange",
    backgroundColor: 'rgba(255, 165, 0, 0.6)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    color: 'white',
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
  },
  deptName: {
    fontSize: 48,
    paddingLeft: 10,
    margin: '30px 0px 10px 0px',
    borderBottom: '1px solid lightgray',
  },
  locateStaffPopover: {
    maxHeight: '50vh',
    overflow: 'scroll',
    overflowX: 'hidden',
    top: 35,
    right: 0,
    marginRight: 20,
    padding: '10px 20px 20px 20px',
    position: 'absolute',
    marginTop: 75,
    zIndex: 10,
    boxShadow: '0px 2px 6px -2px #707070',
    backgroundColor: 'white',
  },
  searchPopover: {
    // maxHeight: "50vh",
    // overflow: "scroll",
    // overflowX: "hidden",
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: 0,
    right: 0,
    // marginRight: 20,
    // padding: 20,
    position: 'absolute',
    marginTop: 72,
    backgroundColor: 'white',
    zIndex: 15,
    boxShadow: '0px 2px 6px -2px #707070',
  },
  browserAlertSnackbar: {
    backgroundColor: 'white',
    color: 'black',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  hide: {
    display: false,
  },
  homeIconContainer: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  colorPickModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  fadedAction: {
    opacity: 0.2,
  },
  popOverActions: {
    // height: 50,
    // width: 50,
    height: 55,
    width: 55,
    padding: 5,
    cursor: 'pointer',
  },
  popOverActionsGray: {
    // height: 50,
    // width: 50,
    height: 55,
    width: 55,
    padding: 5,
    filter: 'grayscale(100%)',
    opacity: '0.4',
  },
  twoStep: {
    position: 'fixed',
    left: 0,
    top: '40%',
    width: '100%',
    outline: 'none',
    // height: "100%",
    overflow: 'auto',
    // backgroundColor: "rgb(0,0,0)",
    // backgroundColor: "rgba(0,0,0,0.4)",
  },
  twoStepContent: {
    borderRadius: 10,
    textAlign: 'center',
    fontSize: 22,
    backgroundColor: '#fefefe',
    margin: 'auto',
    padding: 20,
    border: '1px solid #888',
    width: 250,
    maxWidth: '70%',
  },
  orgItem: {
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
});

export const useStyles = makeStyles(styles);
