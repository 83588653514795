import { Input, NativeSelect } from '@material-ui/core';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

type TProps = {
  classes?: any;
  name: string;
  control: any;
  required?: boolean;
  options: { label: string; value: string | number | undefined; model?: any }[];
  inputId?: string;
  onSelect?: (value: string) => void;
  errorMessage?: string;
  errors: any;
};

const SelectField: FC<TProps> = props => {
  const {
    classes = null,
    name = '',
    control,
    required,
    options = [],
    inputId = '',
    onSelect,
    errors,
    errorMessage
  } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <NativeSelect
            classes={classes}
            value={field.value}
            onChange={e => {
              field.onChange(e.target.value);
              if (onSelect) {
                onSelect(e.target.value);
              }
            }}
            input={<Input id={inputId} />}
            required={required}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </NativeSelect>
        )}
      />
      {errors.isActive && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </>
  );
};

export default SelectField;
