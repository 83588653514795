import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { useDeviceSwitchDetails } from '../../../../../hooks/useDeviceSwitchDetails';

// Interfaces
import { IAction, IDevice } from '../../../../../interfaces/interfaces';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// hooks
import { useDeviceDetails } from './useDeviceDetails';
import { useDeviceLocations } from '../../../../../hooks/useDeviceLocations';

interface TProps {
  deviceToEdit: IDevice;
  onClose: () => void;
  loggedInUserData: { userId: string };
}

const DeviceDetailsInfo: FC<TProps> = ({
  deviceToEdit,
  onClose,
  loggedInUserData,
}) => {
  const classes = useStyles();

  const { deviceSwitchDetails } = useDeviceSwitchDetails(deviceToEdit.deviceId);
  const { deviceLocations } = useDeviceLocations();

  const { onActionSend } = useDeviceDetails(
    deviceToEdit.deviceId,
    loggedInUserData.userId,
    onClose
  );

  const onPoeReboot = () => {
    console.log('POE REBOOT');

    const action = {
      actionTypeId: 15,
      actionTypeName: 'POE Reboot (Cisco/Aruba/Unifi)',
    } as IAction;

    onActionSend(action);
  };

  return (
    <div>
      {/* Device Details */}
      <Grid container>
        <Grid item xs={10}>
          <h2>Device Details</h2>
        </Grid>
        <Grid item xs={2}>
          <h2
            onClick={onClose}
            className={classes.closeButton}
            style={{ cursor: 'pointer' }}
          >
            X
          </h2>
        </Grid>
      </Grid>
      {/* App and Watchdog */}
      <div className={classes.textDetails}>
        <Grid container>
          <Grid item xs={12} sm={deviceToEdit.isAndroidTablet ? 4 : 12}>
            <strong>DeviceId:</strong>
            <br />
            {deviceToEdit?.deviceId}
          </Grid>
          <Grid item xs={12} sm={4}>
            {deviceToEdit.isAndroidTablet && (
              <div>
                <strong>Assigned Watchdog:</strong>
                <br />
                {deviceToEdit?.mongoWatchdogAppId}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            {deviceToEdit.isAndroidTablet && (
              <div>
                <strong>Assigned App:</strong>
                <br />
                {deviceToEdit.mongoAppId}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={deviceToEdit.isAndroidTablet ? 4 : 12}>
            <strong>Location:</strong>
            <br />
            {deviceToEdit.deviceLocation}
          </Grid>
          <Grid item xs={12} sm={4}>
            {deviceToEdit.isAndroidTablet && (
              <div>
                <strong>Current Watchdog:</strong>
                <br />
                {deviceToEdit.mongoLastLoggedWatchdogAppId}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            {deviceToEdit.isAndroidTablet && (
              <div>
                <strong>Current App:</strong>
                <br />
                <div
                  style={
                    deviceToEdit.mongoLastLoggedAppId ===
                    deviceToEdit.mongoAppId
                      ? {}
                      : { color: 'red' }
                  }
                >
                  {deviceToEdit.mongoLastLoggedAppId}
                </div>
              </div>
            )}
          </Grid>
        </Grid>

        {/* Last Seen */}
        <Grid container>
          <Grid item xs={12} sm={deviceToEdit.isAndroidTablet ? 4 : 12}>
            <strong>Last Seen:</strong>
            <br />
            {deviceToEdit.minutesSinceLastConnection} minutes ago
          </Grid>
          <Grid item xs={12} sm={4}>
            {deviceToEdit.isAndroidTablet && (
              <div>
                <strong>OS:</strong>
                <br />
                {deviceToEdit.osVersion}
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>MAC:</strong>
              <br />
              {deviceToEdit.macAddress}
            </div>
          </Grid>
        </Grid>

        {/* Is Gateway */}
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>Is Gateway: </strong>
              {deviceToEdit.isGateway ? 'True' : 'False'}
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>Override Room: </strong>
              {deviceToEdit.overrideRoomId ? (
                <div>
                  {deviceLocations.map(
                    (room: any, index: any) =>
                      room.roomId === deviceToEdit.overrideRoomId && (
                        <div key={room.roomId}>
                          {room.deviceLocation}{' '}
                          {deviceToEdit.overridenIsInside ? '(IN)' : '(OUT)'}
                        </div>
                      )
                  )}
                </div>
              ) : (
                'False'
              )}
            </div>
          </Grid>
        </Grid>

        {/* Loading Switch Details */}
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={9} sm={9}>
            {deviceSwitchDetails ? (
              <div>
                <TextField
                  multiline
                  variant="outlined"
                  label="Port Status"
                  className={classes.textField}
                  value={
                    deviceSwitchDetails.portStatus
                      ? deviceSwitchDetails.portStatus
                      : ''
                  }
                  // onChange={this.handleLogInput()}
                  margin="normal"
                />
              </div>
            ) : (
              <div>Loading Switch Details...</div>
            )}
          </Grid>
          <Grid item xs={3} sm={3} style={{ textAlign: 'center' }}>
            <Button variant="outlined" onClick={onPoeReboot}>
              POE Reboot
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

function areEqual(prevProps: TProps, nextProps: TProps) {
  // Custom comparison function
  return (
    prevProps.onClose === nextProps.onClose &&
    prevProps.loggedInUserData.userId === nextProps.loggedInUserData.userId &&
    Object.keys(prevProps.deviceToEdit).every(
      key =>
        prevProps.deviceToEdit[key as keyof IDevice] ===
        nextProps.deviceToEdit[key as keyof IDevice]
    )
  );
}
export default React.memo(DeviceDetailsInfo, areEqual);
