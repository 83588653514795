import React, { useState, useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';

type ToastType = 'success' | 'info' | 'warning' | 'error';

interface ToastState {
  open: boolean;
  message: string;
  type: ToastType;
}

const useToast = () => {
  const [toast, setToast] = useState<ToastState>({
    open: false,
    message: '',
    type: 'info',
  });

  const presentToast = useCallback((type: ToastType, message: string) => {
    setToast({ open: true, type, message });
  }, []);

  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast((prev) => ({ ...prev, open: false }));
  }, []);

  const ToastComponent = () => (
    <Snackbar
      open={toast.open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={toast.type as AlertProps['severity']}>
        {toast.message}
      </Alert>
    </Snackbar>
  );

  return { presentToast, ToastComponent };
};

export default useToast;