import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../../apiconfig';
import { IUser } from '../../interfaces/interfaces';
import { checkRefreshToken } from '../../utils/utils';

export const useProviderAssignmentUsers = (orgId: string | null): {
  providerAssignmentUsers: any;
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IUser[]>;
} => {
  const [providerAssignmentUsers, setProviderAssignmentUsers] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProviderAssignmentUsers = useCallback(async (): Promise<IUser[]> => {
    if (!orgId) {
      return [] as IUser[];
    }

    setIsLoading(true);
    setError(null);

    let token = localStorage.getItem("token");

  let URL = API.REACT_APP_API_PROVIDERASSIGNMENTUSERS + orgId;
  let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

  axios
    .get(URL, headers)
    .then((response) => {
      // dispatch({
      //   type: FETCH_PROVIDER_ASSIGNMENT_USERS,
      //   payload: response.data.data,
      // });

      setIsLoading(false);
      const providerAssignmentUsers: IUser[] = response.data.data;
      setProviderAssignmentUsers(providerAssignmentUsers);
    })
    .catch((err) => {
      console.log("Err fetching provider assignment users", err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
  }, [orgId]);

  useEffect(() => {
    fetchProviderAssignmentUsers();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IUser[]> => {
    return fetchProviderAssignmentUsers();
  }, [fetchProviderAssignmentUsers]);

  return {
    providerAssignmentUsers,
    isLoading,
    error,
    refetch,
  };
};
