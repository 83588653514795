import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useEffect, useRef, useState } from 'react';
import signalRManager from './SignalRManager';

import { defaultOptions } from './globals';
import { Options } from './types';

export const useSignalR = (options?: Options) => {
  const [signalRHub, setSignalRHub] = useState<HubConnection | null>(null);
  const optionsRef = useRef<Options>({ ...defaultOptions, ...options });

  useEffect(() => {
    optionsRef.current = { ...defaultOptions, ...options };
  }, [options]);

  useEffect(() => {
    if (!optionsRef.current.enabled) return;

    let isCanceled = false;

    const hubConnection = signalRManager.getConnection();

    const bootstrap = () => {
      if (isCanceled) return hubConnection.stop();

      if (optionsRef.current.onConnected)
        optionsRef.current.onConnected(hubConnection);

      if (optionsRef.current.onDisconnected)
        hubConnection.onclose(optionsRef.current.onDisconnected);

      if (optionsRef.current.onReconnecting)
        hubConnection.onreconnecting(optionsRef.current.onReconnecting);

      if (optionsRef.current.onReconnected)
        hubConnection.onreconnected(optionsRef.current.onReconnected);

      setSignalRHub(hubConnection);
    };

    if (hubConnection.state === HubConnectionState.Connected) {
      bootstrap();
    } else {
      hubConnection
        .start()
        .then(() => {
          bootstrap();
        })
        .catch((error: any) => {
          if (isCanceled) return;

          if (optionsRef.current.onError) optionsRef.current.onError(error);
        });
    }

    return () => {
      if (optionsRef.current.onUnmount) {
        optionsRef.current.onUnmount(hubConnection);
      }

      // isCanceled = true;

      // if (hubConnection.state === HubConnectionState.Connected)
      //   hubConnection.stop();

      // setSignalRHub(null);
    };
  }, [optionsRef.current.enabled]);

  return signalRHub;
};

export default useSignalR;
