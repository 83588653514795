import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import { ISound } from '../../../../../interfaces/interfaces';

const style: {} = {
  margin: '.2rem',
  backgroundColor: 'white',
  cursor: 'move',
  border: '1px solid lightgray',
};

const phoneIcon: {} = {
  color: 'white',
  position: 'absolute',
  backgroundColor: '#4FAC48',
  width: 15,
  height: 15,
  textAlign: 'center',
  fontSize: 10,
  marginLeft: 20,
};

const deleteButton: {} = {
  color: 'white',
  position: 'absolute',
  backgroundColor: 'red',
  width: 15,
  height: 15,
  textAlign: 'center',
  cursor: 'pointer',
  fontSize: 10,
};

const soundIcon: {} = {
  margin: '0.2rem',
  maxWidth: 76,
  minWidth: 76,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  backgroundColor: '#F8F8F8',
  position: 'absolute',
  bottom: 0,
};

const DndAction = ({
  id,
  text,
  index,
  moveCard,
  card,
  modifySound,
  department,
  allSounds,
  deleteAction,
}: any) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'card',
    hover: (item: { id: any; index: number }, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, id);

      // Note: we're mutating the item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'card',
    item: { id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div>
      {/* {text} */}
      <div style={{ position: 'relative' }}>
        <div
          onClick={() => deleteAction(card.actionId)}
          style={{ ...deleteButton }}
        >
          X
        </div>
        {department.phoneNumbers
          ? department.phoneNumbers.map(
              (phone: { actionId: string }, index: number) => (
                <div key={index}>
                  {phone.actionId === card.actionId ? (
                    <div
                      style={{
                        ...phoneIcon,
                      }}
                    >
                      ☎
                    </div>
                  ) : null}
                </div>
              )
            )
          : null}

        <div
          style={{
            ...soundIcon,
          }}
        >
          {allSounds.map((sound: ISound, i: number) => (
            <div key={i}>
              {sound.id === card.mongoSoundId && card.mongoSoundId !== 28
                ? sound.soundName
                : null}
            </div>
          ))}
        </div>
        {/* {console.log("individual", card)} */}

        <Tooltip title="Click to modify" placement="top">
          <img
            onClick={() => modifySound(card)}
            ref={ref}
            src={card.actionImageUrl}
            alt=""
            style={{ ...style, opacity, width: 75, height: 75, padding: 2 }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default DndAction;
