import { makeStyles } from "@material-ui/core";

const styles: any = (theme: any) => ({
  checkbox: {
    padding: 5,
  },
  actionPopover: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    width: 265,
    // padding: 10,
    // position: "absolute",
    // marginTop: -100,
    backgroundColor: "white",
    zIndex: 10,
    // boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
  reportingButton: {
    backgroundColor: "#50b848",
    color: "#FFFFFF",
    marginTop: 10,
    // marginBottom: 10,
    textDecoration: "none",
    // height: 40,
  },
  popOverActions: {
    height: 45,
    width: 45,
    margin: 3,
    padding: 4,
    cursor: "pointer",
  },
  selectedPopOverActions: {
    height: 50,
    width: 50,
    padding: 2,
    margin: 3,
    cursor: "pointer",
    border: "2px solid grey",
  },
  addIconStyle: {
    fontSize: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  addIconSquare: {
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "center",
    cursor: "pointer",
    color: "#bdbdbd",
    border: "1px dashed #bdbdbd",
    height: 48,
    width: 48,
  },
  actionIcon: {
    height: 50,
    width: 50,
    cursor: "pointer",
    borderRadius: 0,
  },
  iconFlex: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  newAlertModal: {
    outline: "none",
    position: "absolute",
    width: "450px",
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
  },
  verifyButton: {
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: 16,
    margin: "4px 2px",
    cursor: "pointer",
  },
});

export const useStyles = makeStyles(styles);