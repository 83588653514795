import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-google-charts";

const _ = require("lodash");

interface SiteConnectivityChartProps {
  devices: any;
}

interface SiteConnectivityChartState {}

const styles: {} = (theme: any) => ({});

class SiteConnectivityChart extends Component<SiteConnectivityChartProps, SiteConnectivityChartState> {
  constructor(props: SiteConnectivityChartProps) {
    super(props);
    this.state = {};
  }

  render() {
    let dataPoints: any[] = [];

    // console.log("Devices", this.props.devices);

    this.props.devices.forEach((device: any) => {
      if (device.orgSiteName !== null && device.isConnectedToHub === false) {
        // orgSiteName doesn't exist in dataPoints, add it.
        if (!_.some(dataPoints, { site: device.orgSiteName })) {
          //   console.log("push site", device.orgSiteName);
          dataPoints.push({ site: device.orgSiteName, count: 1 });
        } else {
          dataPoints[_.findIndex(dataPoints, { site: device.orgSiteName })].count++;
        }
      }
    });

    // Loop through device list and increment count for each site.
    // this.props.devices.forEach((device: any) => {
    //   dataPoints.forEach((dataPoint) => {
    //     if (device.orgSiteName === dataPoint.site) {
    //       dataPoint.count++;
    //     }
    //   });
    // });


    let data: any = [["Site", "Count"]];

    dataPoints.forEach((dataPoint) => {
      data.push([dataPoint.site + ": " + dataPoint.count, dataPoint.count]);
    });

    const pieOptions = {
      title: "Device Status",
      sliceVisibilityThreshold: 0,
      pieHole: 0.6,
      pieSliceText: "value",
      slices: [
        {
          color: "#4FAC48",
        },
        {
          color: "#0495DA",
        },
      ],
      // legend: "none",
      legend: {
        // position: "none",
        // position: "bottom",
        // alignment: "center",
        // labeledValueText: "both",
        // position: "labeled",
        textStyle: {
          color: "233238",
          fontSize: 14,
        },
      },
      tooltip: {
        showColorCode: true,
      },
      chartArea: {
        left: 0,
        top: 10,
        width: "100%",
        height: "85%",
      },
      fontName: "Roboto",
    };

    return (
      <div style={{ marginTop: 15, width: 300 }}>
        <div style={{ display: "flex", alignItems: "baseline", marginLeft: 15 }}>Disconnected Devices By Site</div>
        {data.length > 1 ? (
          <div>
            {/* {console.log("Chart Data", data)} */}
            <Chart
              chartType="PieChart"
              options={pieOptions}
              width="300px"
              // height="180px"
              data={data}
            />
          </div>
        ) : (
          <div>Loading...</div>
        )}

        <div style={{ textAlign: "center", display: "flex", alignItems: "baseline", justifyContent: "center" }}>
          <div
            style={{
              height: 10,
              width: 10,
              borderRadius: 10,
              // backgroundColor: "#4FAC48", marginRight: 10
            }}
          />
          {/* Connected: {connectedDevices} */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SiteConnectivityChart);
