import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { API } from '../apiconfig';
import { IDepartment } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useSiblingDepartments = (orgId: string | null) => {
  const [siblingDepartments, setSiblingDepartments] = useState<IDepartment[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSiblingDepartments = useCallback(async (): Promise<
    IDepartment[]
  > => {
    if (!orgId) {
      return [];
    }

    setIsLoading(true);
    setError(null);

    try {
      let token = localStorage.getItem('token');
      // Get Sibling depts for all flowstation urls
      let orgHeaders = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };
      axios
        .get(API.REACT_APP_API_GETORGDEPARTMENT + orgId, orgHeaders)
        .then(response => {
          // console.log("depts", response.data.data);

          setSiblingDepartments(response.data.data);
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    } catch (err) {
      handleError(err);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    fetchSiblingDepartments();
  }, [fetchSiblingDepartments]);

  const refresh = useCallback((): Promise<IDepartment[]> => {
    return fetchSiblingDepartments();
  }, [fetchSiblingDepartments]);

  const handleError = (err: any) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      console.log('UNAUTHORIZED, KICK EM OUT');
      setError('Unauthorized. Please log in again.');
      checkRefreshToken();
    } else {
      console.error('Error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return {
    siblingDepartments,
    isLoading,
    error,
    refresh,
  };
};
