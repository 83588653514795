import axios, { AxiosError, AxiosResponse } from "axios";
import { API } from "../../../../apiconfig";
import { checkRefreshToken } from "../../../../utils/utils";
import { LocalStorageToken, OnDemandEmailReport, ScheduledEmailReport, ActionFormField } from "./emailReportingTypes";

// Remove this once Axios is upgraded to the latest version.
function isAxiosError(error: any): error is AxiosError {
  return (error as AxiosError).response !== undefined && (error as AxiosError).config !== undefined;
}

// Send an on-demand email report.
export const sendOnDemandEmailReport = async (token: LocalStorageToken, report: OnDemandEmailReport) => {
  try {
    const response = await axios.post(API.REACT_APP_API_SENDEMAILREPORT, report, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      success: response.status === 200,
      data: response.data,
    };
  } catch (err) {
    console.error("Error sending on-demand email report:", err);
    if (isAxiosError(err) && err.response?.status === 401) {
      checkRefreshToken();
    }
    throw err;
  }
};

// Retrieve the email reports available for the organization.
export const getScheduledReports = async (token: string, orgId: string, userId: string): Promise<ScheduledEmailReport[]> => {
  try {
    const options = {
      method: "GET",
      url: `${API.REACT_APP_API_GETSCHEDULEDREPORTS}${userId}/orgId=${orgId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response: AxiosResponse<{ data: ScheduledEmailReport[] }> = await axios.request(options);
    console.log("Get email reports from service::", response.data.data);

    // Reverse the order of the objects
    const reversedReports: ScheduledEmailReport[] = response.data.data.reverse();

    return reversedReports;
  } catch (error) {
    console.error("Reports Error", error);
    if (isAxiosError(error) && error.response?.status === 401) {
      checkRefreshToken();
    }
    throw error;
  }
};

// Saves a recurring email report.
export const scheduleRecurringReport = async (token: LocalStorageToken, payload: ScheduledEmailReport) => {
  console.log("Scheduling report with payload: ", payload);
  try {
    const response = await axios.post(API.REACT_APP_API_CREATEDSCHEDULEDREPORT, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      success: response.status === 200,
      data: response.data,
    };
  } catch (err) {
    console.error("Error sending email report:", err);
    if (isAxiosError(err) && err.response?.status === 401) {
      checkRefreshToken();
    }
    throw err;
  }
};

// Get the action fields for action usage report.
export const getActionFormFields = async (token: string, orgId: string) => {
  try {
    const headers = {
      headers: {
        Authorization: "Bearer " + token,
        "Org-UID": "UID1",
        Pragma: "no-cache",
      },
    };

    const response = await axios.get<{ data: ActionFormField[] }>(`${API.REACT_APP_API_GETACTIONFORMFIELDS}/${orgId}`, headers);
    return response.data.data;
  } catch (err) {
    const error = err as AxiosError;
    console.log("Report Form Fields Error", err);
    if (error.response?.status === 401) {
      checkRefreshToken();
    }
    throw err;
  }
};

// Delete a scheduled report for the user.
export const deleteScheduledReport = async (token: string, reportId: string): Promise<void> => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = `${API.REACT_APP_API_DELETESCHEDULEDREPORT}/${reportId}`;

    await axios.delete(url, { headers });
    console.log(`Successfully deleted report with id: ${reportId}`);
  } catch (error) {
    console.error("Error deleting scheduled report:", error);
    if (isAxiosError(error) && error.response?.status === 401) {
      checkRefreshToken();
    }
    throw error;
  }
};

// Unsubscribe from a scheduled report.
export const unsubscribeScheduledReport = async (token: string, reportId: string, userId: string): Promise<void> => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = `${API.REACT_APP_API_UNSUBSCRIBESCHEDULEDREPORT}/${reportId}/users/${userId}/unsubscribe`;

    await axios.post(url, {}, { headers });
    console.log(`Successfully unsubscribed user ${userId} from report with id: ${reportId}`);
  } catch (error) {
    console.error("Error unsubscribing from scheduled report:", error);
    if (isAxiosError(error) && error.response?.status === 401) {
      checkRefreshToken();
    }
    throw error;
  }
};


// Update a scheduled report
export const updateScheduledReport = async (token: string, updatedReport: ScheduledEmailReport) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    if (!updatedReport.id) {
      throw new Error("Report ID is missing");
    }

    const apiUrl = `https://api-dev.synctimes.com/api/v3/scheduledreports/${updatedReport.id}`;

    console.log("Updating scheduled report with payload: ", updatedReport);
    const response = await axios.put(apiUrl, updatedReport, { headers });
    return response.data;
  } catch (err) {
    console.error("Error updating scheduled report:", err);
    const error = err as AxiosError;
    if (error.response?.status === 401) {
      checkRefreshToken();
    }
    throw err;
  }
};
