import { API } from '../apiconfig';
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

import { IIntegration } from '../interfaces/interfaces';

interface useIntegrationResult {
  integrations: IIntegration[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IIntegration[]>;
}

export const useIntegrations = (orgId: string): useIntegrationResult => {
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchIntegrations = useCallback((): Promise<IIntegration[]> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);

      console.log('fetching init');
      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };
      // fetch initial integrations
      let getIntegrationsUrl =
        API.REACT_APP_API_INTEGRATION + '/orgId=' + orgId;

      axios
        .get(getIntegrationsUrl, headers)
        .then(response => {
          // console.log("response", response.data.data)
          const organizationIntegrations = response.data.data;
          if (organizationIntegrations) {
            setIntegrations(organizationIntegrations);
            resolve(organizationIntegrations);
          }

          resolve([]);
        })
        .catch(error => {
          console.error('Error fetching integrations:', error);
          setError(
            'An error occurred while fetching integrations. Please try again.'
          );
          setIsLoading(false);
          reject(error);
        });
    });
  }, [orgId]);

  useEffect(() => {
    fetchIntegrations();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IIntegration[]> => {
    return fetchIntegrations();
  }, [fetchIntegrations]);

  return {
    integrations,
    isLoading,
    error,
    refetch,
  };
};
