import { isEmpty } from 'lodash';
import { TLobbySequence, TLobbySettings } from './types';
import { LOBBY_SEQUENCE_PREFIX, LOBBY_SETTINGS_PREFIX } from './constants';

export const getLobbySequences = (orgId: string): TLobbySequence[] => {
  const lobbySequences = localStorage.getItem(LOBBY_SEQUENCE_PREFIX + orgId);

  if (lobbySequences) {
    return JSON.parse(lobbySequences) as TLobbySequence[];
  }

  if (isEmpty(lobbySequences)) {
    return setDefaultSequences(orgId);
  }

  return [];
};

export const setDefaultSequences = (orgId: string): TLobbySequence[] => {
  const lobbySequences: TLobbySequence[] = [];

  return saveLobbySequences({ orgId, lobbySequences });
};

export const saveLobbySequences = (input: {
  orgId: string;
  lobbySequences: TLobbySequence[];
}): TLobbySequence[] => {
  const { orgId, lobbySequences } = input;

  localStorage.setItem(
    LOBBY_SEQUENCE_PREFIX + orgId,
    JSON.stringify(lobbySequences)
  );

  return lobbySequences;
};

export const getLobbySettings = (orgId: string) => {
  const lobbySettings = localStorage.getItem(LOBBY_SETTINGS_PREFIX + orgId);

  if (lobbySettings) {
    return JSON.parse(lobbySettings) as TLobbySettings;
  }

  if (lobbySettings === null) {
    return saveDefaultLobbySettings(orgId);
  }
};

export const saveDefaultLobbySettings = (orgId: string) => {
  const lobbySettings: TLobbySettings = {
    departmentsToShow: [],
    patientEducationToShow: '',
    lobbyWaitingTime: 0,
    patientEducationTime: 0,
  };

  localStorage.setItem(
    LOBBY_SETTINGS_PREFIX + orgId,
    JSON.stringify(lobbySettings)
  );

  return lobbySettings;
};

export const saveLobbySettings = (
  input: TLobbySettings & { orgId: string }
) => {
  const {
    orgId,
    departmentsToShow,
    patientEducationToShow,
    lobbyWaitingTime,
    patientEducationTime,
  } = input;

  const lobbySettings: TLobbySettings = {
    patientEducationToShow,
    lobbyWaitingTime,
    patientEducationTime,
    departmentsToShow,
  };

  localStorage.setItem(
    LOBBY_SETTINGS_PREFIX + orgId,
    JSON.stringify(lobbySettings)
  );

  return lobbySettings;
};
