import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchPermissions } from "./queries";
import { IPermission } from "../../interfaces/interfaces";
import { FETCH_PERMISSIONS } from "../../actions/types";

export const usePermissions = (): {
  permissions: IPermission[];
  isLoading: boolean;
  error: string | null;
  refetch: () => void;
} => {
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const fetchAndSetPermissions = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchedPermissions = await fetchPermissions();
      setPermissions(fetchedPermissions);
      dispatch({
        type: FETCH_PERMISSIONS,
        payload: fetchedPermissions,
      });
    } catch (err) {
      setError("Failed to fetch permissions. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchAndSetPermissions();
  }, [fetchAndSetPermissions]);

  return { permissions, isLoading, error, refetch: fetchAndSetPermissions };
};
