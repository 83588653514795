import { makeStyles } from '@material-ui/core';

export const styles: {
  nav: any;
  providerCard: any;
  bigAvatar: any;
  root: any;
  sortCheckbox: any;
  checked: any;
  timeOption: any;
  textField: any;
} = {
  nav: {
    margin: '10px 0px 25px 0px',
  },
  providerCard: {
    margin: 4,
    border: '2px solid #ffffff',
    width: 250,
    height: 330,
  },
  bigAvatar: {
    backgroundColor: '#e0e0e0',
    width: 70,
    height: 70,
  },
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
  sortCheckbox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
  timeOption: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  textField: {},
};

export const useStyles = makeStyles(styles);
