import { FC, useEffect, useState } from 'react';

import { IProviderWaitingRoomItem, TLobbySequenceLanguage } from '../../types';

import { isEmpty } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { useLobbyDepartments } from '../../../../hooks/useLobbyDepartments';
import { IDepartment } from '../../../../interfaces/interfaces';
import { allowSignalR, connection } from '../../../../socket-manager';
import { RootState } from '../../../../store';
import { getObjectFromGzipEncodedData } from '../../../../utils/Compression';
import Loading from '../../../Loading';
import { buildProviderPatientItems } from '../../utils';
import DeptSubscription from './DepartmentSubscription';
import ProviderGrid from './ProviderGrid';
import WaitingRoomWelcome from './welcome/WaitingRoomWelcome';

type TProps = {
  language: TLobbySequenceLanguage;
  onEnd: () => void;
  duration: number;
  id: string;
  departmentIds: string[];
};

const WaitingRoom: FC<TProps> = props => {
  const { language, onEnd, duration, id, departmentIds } = props;

  const [departments, setDepartments] = useState<IDepartment[]>([]);

  const [providers, setProviders] = useState<IProviderWaitingRoomItem[]>([]);

  const { loggedInUserData, organization } = useSelector((state: RootState) => {
    const adminState = state?.adminData;

    return {
      ...adminState,
    };
  }, shallowEqual);

  const { refetch: refetchDepartments, isLoading } = useLobbyDepartments({
    departmentIds,
    onCompleted: departments => {
      setDepartments(departments);

      const providers = buildProviderPatientItems({
        departmentIds,
        departments,
      });

      setProviders(providers);

      if (isEmpty(providers)) {
        onEnd();
      }
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      onEnd();
    }, duration * 1000);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, id]);

  useEffect(() => {
    if (allowSignalR) {
      // connection.on("updateRoomGzip", (roomDataEncoded) => {
      //   console.log("%c Lobby Update Room", "background: #4CB946; color: #ffffff");
      //   let updateData = getObjectFromGzipEncodedData(roomDataEncoded);
      //   console.log("Update data", updateData);
      // });

      connection.on('updateFsGzip', (updateDataEncoded: any) => {
        console.log(
          'signalR',
          '%c Lobby Update FS',
          'background: #4CB946; color: #ffffff'
        );
        let updateData = getObjectFromGzipEncodedData(updateDataEncoded);

        if (updateData.fsData) {
          console.log('signalR updateFsGzip', { updateData });
          // console.log("Update data", updateData.fsData);
          onUpdateProviders(updateData.fsData);
        }
      });

      connection.on('updateWaitingRoomGzip', (updateDataEncoded: any) => {
        console.log(
          'signalR',
          '%c Updating Waiting Room ',
          'background: #4CB946; color: #ffffff'
        );
        let updateData = getObjectFromGzipEncodedData(updateDataEncoded);
        // console.log("Updated waiting room data", updateData)

        for (const department of departments) {
          if (updateData.deptId === department.id) {
            let updatedDept = {
              ...department,
              providerPatientsWaiting: updateData.providerPatientsWaiting,
            };

            console.log('signalR', { updatedDept });
            onUpdateProviders(updatedDept);
          }
        }
      });
    }

    return () => {
      if (allowSignalR && connection) {
        connection.off('updateFsGzip');
        connection.off('updateWaitingRoomGzip');
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowSignalR, departments]);

  useEffect(() => {
    refetchDepartments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentIds]);

  const onUpdateProviders = (updateData: any) => {
    const updatedDepartments = departments.map(department => {
      if (department.id === updateData.id) {
        return updateData;
      }

      return department;
    });

    setDepartments(updatedDepartments);

    const providers = buildProviderPatientItems({
      departmentIds,
      departments: updatedDepartments,
    });

    setProviders(providers);

    // update
    // setProviders(providers);
  };

  const showSpanish = language === 'Spanish';

  return (
    <div>
      {allowSignalR &&
        loggedInUserData.id &&
        connection.state === 'Connected' && (
          <>
            {departments.map(department => {
              return (
                <DeptSubscription
                  key={department?.id}
                  department={department}
                />
              );
            })}
          </>
        )}

      {isLoading && (
        <div className="h-[calc(80vh-85px)] flex items-center justify-center">
          <Loading />
        </div>
      )}

      {!isLoading && !isEmpty(providers) && (
        <div>
          <ProviderGrid
            departments={departments}
            providers={providers}
            language={language}
          />
        </div>
      )}

      {!isLoading && isEmpty(providers) && (
        <WaitingRoomWelcome
          language={language}
          organization={organization}
          size="5xl"
        />
      )}

      <div
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          padding: 20,
          color: 'lightgray',
        }}
      >
        {showSpanish ? (
          <>
            Los tiempos de espera estimados se basan en la hora de la cita
            programada
          </>
        ) : (
          <>Estimated wait times are based on scheduled appointment time</>
        )}
      </div>
    </div>
  );
};

export default WaitingRoom;
