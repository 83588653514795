import React, { useState, useEffect, useMemo } from "react";

// Material UI Imports
import Button from "@material-ui/core/Button";
import { Grid, Typography } from "@material-ui/core";
import { Snackbar, SnackbarContent } from "@material-ui/core";

// Components
import OnDemandReportDialog from "./emailReporting/components/OnDemandReportDialog";
import RecurringReportDialog from "./emailReporting/components/RecurringReportDialog";
import ScheduledEmailsList from "./emailReporting/components/ScheduledEmailsList";

// Redux
import { useSelector } from "react-redux";
import { fetchAppUsersByOrgId } from "../../../actions/adminActions";

// Services and Types
import { getScheduledReports } from "./emailReporting/emailReportsService";
import { ScheduledEmailReport } from "./emailReporting/emailReportingTypes";
import { getActionFormFields } from "./emailReporting/emailReportsService";
import { ActionFormField } from "./emailReporting/emailReportingTypes";

import { useDispatch } from "react-redux";
import { IUser } from "../../../interfaces/interfaces";
// import { ActionUsageFields, DateRangeOnlyFields, ReportsAccessFields } from "./emailReporting/emailReportingTypes";

const EmailReports: React.FC = () => {
  // Redux and local state
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state: any) => state.adminData.loggedInUserData);

  const token = useMemo(() => localStorage.getItem("token"), []);

  console.log("Logged in user", loggedInUser);
  console.log("Logged in user org id", loggedInUser.mongoOrganizationId);
  console.log("Logged in user user id", loggedInUser.id);
  console.log("Token", token);

  // Popup initially closed
  const [onDemandOpen, setOnDemandOpen] = useState(false);
  const [recurringOpen, setRecurringOpen] = useState(false);

  // Existing scheduled reports
  const [scheduledReports, setScheduledReports] = useState<ScheduledEmailReport[]>([]);

  // Form fields for action report. Initially empty
  const [formFields, setFormFields] = useState<ActionFormField[]>([]);

  // Get available users for the org.
  const usersData = useSelector((state: any) => state.adminData.usersData);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Update snackbar message
  const [isSnackbarError, setIsSnackbarError] = useState(false);

  const [availableUsers, setAvailableUsers] = useState<IUser[]>([]);
  const [reportToEdit, setReportToEdit] = useState<ScheduledEmailReport | null>(null);

  // Sync available users
  useEffect(() => {
    // console.log("Available Users", usersData);
    const activeUsers = usersData.filter((user: IUser) => user.isActive);
    setAvailableUsers(activeUsers);
  }, [usersData]);

  // Use Effects

  // Fetch users by org id
  // TODO: Add types.
  useEffect(() => {
    dispatch(fetchAppUsersByOrgId(loggedInUser.mongoOrganizationId) as any);
  }, [dispatch, loggedInUser.mongoOrganizationId]);

  // Fetch scheduled reports
  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (token && loggedInUser && loggedInUser.id && loggedInUser.mongoOrganizationId) {
          const reports = await getScheduledReports(token, loggedInUser.mongoOrganizationId, loggedInUser.id);
          console.log("Scheduled Reports initial fetch", reports);
          setScheduledReports(reports);
        }
      } catch (err) {
        console.log("Scheduled Reports Error", err);
      }
    };

    if (token && loggedInUser?.mongoOrganizationId) {
      fetchReports();
    }
  }, [token, loggedInUser]);

  // Fetch action form fields
  useEffect(() => {
    const fetchFormFields = async () => {
      try {
        if (!token || !loggedInUser.mongoOrganizationId || !loggedInUser.id) {
          return;
        }
        const fields = await getActionFormFields(token, loggedInUser.mongoOrganizationId);
        console.log("Action usage form fields received", fields);
        setFormFields(fields);
      } catch (error) {
        console.error("Failed to fetch form fields:", error);
      }
    };

    if (token && loggedInUser.mongoOrganizationId) {
      fetchFormFields();
    }
  }, [token, loggedInUser.id, loggedInUser.mongoOrganizationId]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle cancel pressed
  const handleOnDemandCancelPressed = () => {
    console.log("Cancel Pressed");
    // setReport(createNewEmailReport(false, loggedInUser));
    setOnDemandOpen(false);
  };

  // Event Handlers

  // Refreshes the scheduled reports after save or delete
  const refreshScheduledReports = async () => {
    try {
      if (token && loggedInUser.mongoOrganizationId) {
        const reports = await getScheduledReports(token, loggedInUser.mongoOrganizationId, loggedInUser.id);
        console.log("Refreshed reports", reports);
        setScheduledReports(reports);
      }
    } catch (err) {
      setSnackbarMessage("Scheduled Reports Error " + err);
      setSnackbarOpen(true);
    }
  };

  const handleEditReport = (report: ScheduledEmailReport) => {
    console.log("Edit Report", report);
    if (!report) {
      return;
    }
    setReportToEdit(report);
    setRecurringOpen(true);
  };

  const handleSendOnDemandReport = () => {
    console.log("Send on demand report");
    setOnDemandOpen(true);
  };

  const handleCreateRecurringReport = () => {
    console.log("Create recurring report");
    setReportToEdit(null);
    setRecurringOpen(true);
  };

  const showSnackbar = (message: string, isError: boolean) => {
    setSnackbarMessage(message);
    setIsSnackbarError(isError);
    setSnackbarOpen(true);
  };

  // Render
  return (
    <div style={{ maxWidth: 1200, margin: "auto", padding: 20 }}>
      {token ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Quickly send or schedule email reports for you and for your team.
            </Typography>
            <Typography variant="body1" paragraph>
              Just need a snapshot? Click below for a one-time report now.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleSendOnDemandReport} style={{ marginBottom: 20 }}>
              Send a Report
            </Button>
            <Typography variant="body1" paragraph>
              Want a report on an ongoing basis? We've got you covered. Click below to schedule a recurring report to be sent out.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleCreateRecurringReport}>
              Schedule a report
            </Button>
            <OnDemandReportDialog
              open={onDemandOpen}
              formFields={formFields}
              token={token}
              availableUsers={availableUsers}
              user={loggedInUser}
              handleCancelPressed={handleOnDemandCancelPressed}
              showSnackbar={showSnackbar}
            />
            <RecurringReportDialog
              open={recurringOpen}
              user={loggedInUser}
              formFields={formFields}
              token={token}
              availableUsers={availableUsers}
              incomingReport={reportToEdit}
              onClose={() => setRecurringOpen(false)}
              refreshScheduledReports={refreshScheduledReports}
              showSnackbar={showSnackbar}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScheduledEmailsList
              userId={loggedInUser.id}
              scheduledReports={scheduledReports}
              onEditReport={handleEditReport}
              onRemoveReport={refreshScheduledReports}
            />
          </Grid>
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={snackbarMessage}>
            <SnackbarContent style={{ backgroundColor: isSnackbarError ? "#D32F2F" : "#4FAC48" }} message={snackbarMessage} />
          </Snackbar>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default React.memo(EmailReports);
