import { DepartmentTemplate } from './types';


export const DRAWER_WIDTH = "600px"

export const DEPARTMENT_TEMPLATE: DepartmentTemplate = {
  deptName: '',
  isActive: true,
  subscribedActionId: null,
  showSurvey: false,
  patientEducationUrl: null,
  isFrontDeskIntegration: false,
  actionsList: [],
  showNurseCallButton: false,
  ehrDeptIds: [],
  isTotalTimeFromCheckIn: false,
  patientContentIds: [],
};

export const patientInfoTypeOptions = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'J Smith',
    value: 1,
  },
  {
    label: 'JS',
    value: 2,
  },
  {
    label: 'John S',
    value: 3,
  },
  {
    label: 'John Smith',
    value: 4,
  },
];
