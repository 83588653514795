import { Grid, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import axios from 'axios';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSites } from '../../../hooks/useSites';
import { IOrganization } from '../../../interfaces/interfaces';
import LobbySettingsModal from '../settings/LobbySettingsModal';
import { useStyles } from '../styles';
import { getTimeZone } from '../utils';

type TProps = {
  orgId: string;
  organization: IOrganization;
  onRefresh: () => void;
};

const LobbyHeader: FC<TProps> = props => {
  const { organization, orgId, onRefresh } = props;
  const [serverTimeOffset, setServerTimeOffset] = useState(0);
  const { sites = [] } = useSites(orgId);

  const [showLobbySettingsModal, toggleLobbySettingsModal] = useState(false);

  const [timeZone, setTimeZone] = useState('America/Denver');

  const classes = useStyles();

  useEffect(() => {
    axios.get('https://api.synctimes.com/api/v3/fs/utctime').then(response => {
      // serverTimeOffset = moment("2022-09-22T02:10:36.2894436Z").diff(new Date());
      setServerTimeOffset(moment(response.data.data).diff(new Date()));
    });

    const timeZone = getTimeZone(sites);

    setTimeZone(timeZone);
  }, [sites]);

  return (
    <div className={classes.nav}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          {/* <img src={SynctimesLogo} alt="" style={{ maxHeight: 40 }} /> */}
          <img
            src={organization.orgImageUrl}
            onClick={() => window.location.replace('/')}
            alt=""
            style={{
              height: 40,
              minHeight: 40,
              maxHeight: 40,
              cursor: 'pointer',
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center', margin: 'auto' }}>
          {/* {moment().tz(timeZone).format("MM/DD/YY, h:mm a")} */}
          <div className="text-xl text-gray-600 w-full">
            {/* {console.log("timezone", timeZone)} */}
            {/* {moment()
              .add('milliseconds', serverTimeOffset)
              .tz(timeZone)
              .format('MM/DD/YY, h:mm a')} */}
            {moment()
              .add('milliseconds', serverTimeOffset)
              .tz(timeZone)
              .format('ddd, MM/DD/YY h:mm A')}
              {/* {moment().calendar()} */}
          </div>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <IconButton
            onClick={() => {
              toggleLobbySettingsModal(true);
            }}
          >
            <SettingsIcon />
          </IconButton>

          {showLobbySettingsModal && (
            <LobbySettingsModal
              open={showLobbySettingsModal}
              onClose={() => {
                toggleLobbySettingsModal(false);
                onRefresh();
              }}
              orgId={orgId}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LobbyHeader;
