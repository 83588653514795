import { FC } from 'react';
import { IPatientContent } from '../../../../interfaces/interfaces';

import DeleteIcon from '@material-ui/icons/Delete';
import { TLobbySequence } from '../../types';
import { checkIfIsWaitingRoom } from '../../utils';
import LobbySequenceText from './LobySequenceText';

type TProps = {
  lobbySequences: TLobbySequence[];
  className?: string;
  patientContentList: IPatientContent[];
  onDelete: (index: number) => void;
};

const LobbySequences: FC<TProps> = props => {
  const {
    lobbySequences,
    patientContentList,
    className = '',
    onDelete,
  } = props;

  return (
    <div className={`${className}`}>
      <div className="pb-5">
        <strong>Lobby Sequence</strong>
      </div>

      <div style={{ marginBottom: 10 }}>
        {lobbySequences.length === 0 && (
          <div>
            <div style={{ textAlign: 'center', color: 'gray' }}>
              No Sequence Items...
            </div>
          </div>
        )}

        <ul className="bg-white shadow-md overflow-hidden divide-y divide-gray-100">
          {lobbySequences.map((lobbySequence, index) => {
            const isWaitingRoom = checkIfIsWaitingRoom(lobbySequence);

            let currentPatientContent: Partial<IPatientContent>;

            if (isWaitingRoom) {
              currentPatientContent = {
                patientContentName: lobbySequence.patientEducationToShow,
                patientEducationUrl: '',
              };
            } else {
              currentPatientContent = patientContentList.find(
                patientContent =>
                  patientContent.id === lobbySequence.patientEducationToShow
              );
            }

            return (
              <li
                key={index}
                className="px-4 py-3 text-gray-700 hover:bg-gray-50 transition-colors duration-200 flex items-center"
              >
                <span className="w-8 text-gray-500 font-medium">
                  {index + 1}.
                </span>

                {/* {lobbySequence.patientEducationToShow ===
                  WAITING_ROOM_ENGLISH ||
                  (lobbySequence.patientEducationToShow ===
                    WAITING_ROOM_SPANISH && (
                    <span className="flex-grow">
                      {lobbySequence.patientEducationToShow} for{' '}
                      {lobbySequence.patientEducationTime} secs
                    </span>
                  ))} */}

                {currentPatientContent && (
                  <span className="flex-grow">
                    <LobbySequenceText
                      lobbySequence={lobbySequence}
                      patientContent={currentPatientContent}
                    />
                  </span>
                )}

                <button
                  onClick={() => onDelete(index)}
                  className="text-gray-400 hover:text-red-500 transition-colors duration-200"
                  aria-label={`Delete ${currentPatientContent?.patientContentName}`}
                >
                  <DeleteIcon />
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LobbySequences;
