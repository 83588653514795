import axios from "axios";
import { isEmpty } from "lodash";
import LogRocket from "logrocket";
import { FC, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { UnknownAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { API } from "../../apiconfig";
import { checkRefreshToken, getQueryVariable } from "../../utils/utils";

import { fetchLoggedInUser, fetchOrganizationDepartments, fetchSites } from "../../actions/adminActions";
import DepartmentContainer from "./components/DepartmentContainer";

import { TAdminState, TGroupState } from "../../interfaces/interfaces";

import { RootState } from "../../store";

let gaInitialized = false;

type TProps = {
  authenticated: boolean;
};

const Alerts: FC<TProps> = (props) => {
  const { authenticated } = props;
  const [fireLogin, setFireLogin] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, UnknownAction>>();

  const { organizationDepartments, loggedInUserData } = useSelector((state: { adminData: TAdminState; groupData: TGroupState }) => {
    return {
      groupData: state.groupData.groupData,
      loggedInUserData: state.adminData.loggedInUserData,
      department: state.adminData.department,
      organizationDepartments: state.adminData.organizationDepartments,
      errorLoggedInUser: state.adminData.errorLoggedInUser,
    };
  });

  useEffect(() => {
    // Check if Authenticated
    if (authenticated === false) {
      setFireLogin(true);
    }

    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");
    if (email && token) {
      dispatch(fetchLoggedInUser(email, token));
    }
  }, [authenticated, dispatch]);

  if (loggedInUserData.mongoOrganizationId && isEmpty(organizationDepartments)) {
    fetchOrganizationDepartments(loggedInUserData.mongoOrganizationId);
    fetchSites(loggedInUserData.mongoOrganizationId);
  }

  if (loggedInUserData.id && gaInitialized === false) {
    console.log("%c Setting GA userId ", "background: #F9AB00; color: #ffffff");
    // ReactGA.set({ userId: 123 });
    ReactGA.set({ userId: loggedInUserData.id });
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (
      window.location.hostname === "app.synctimes.com" ||
      window.location.hostname === "cesium-dev.azurewebsites.net" ||
      window.location.hostname === "cesium.azurewebsites.net"
    ) {
      if (loggedInUserData.mongoOrganizationId) {
        let URL = API.REACT_APP_API_ORGANIZATION + loggedInUserData.mongoOrganizationId;
        let token = localStorage.getItem("token");
        let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };
        axios
          .get(URL, headers)
          .then((response) => {
            if (response.data.data) {
              LogRocket.identify(loggedInUserData.id, {
                name: loggedInUserData.fullName,
                email: loggedInUserData.userEmail,
                orgName: response.data.data.orgName,
                userType: loggedInUserData.userTypeId,
                admin: loggedInUserData.isAdmin,
              });
            }
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    }

    gaInitialized = true;
  }
  return (
    <div>
      {fireLogin ? (
        <Navigate
          to={{
            pathname: "/login",
            // @ts-ignore
            url: {
              cameFrom: window.location.href,
              username: getQueryVariable("username"),
              password: getQueryVariable("password"),
            },
          }}
        />
      ) : null}

      {isEmpty(organizationDepartments) ? <DepartmentContainer /> : null}
    </div>
  );
};

export default Alerts;
