import React, { Component } from 'react';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { logAction } from '../../../actions/adminActions';
import PatientHistory from './PatientHistory';
import loadingIcon from '../../../images/synctimesicon.png';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import SuccessSnack from '../../../utils/snack/SuccessSnack';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import BackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { API } from '../../../apiconfig';
import axios from 'axios';

import synctimesLogo from '../../../images/synctimes.png';

import { withStyles } from '@material-ui/core/styles';
import ClinicMapLoader from './ClinicMapLoader';
import EmailReports from './EmailReports';

const styles = theme => ({
  appBar: {
    boxShadow: '0px 0px 5px 0px #707070',
    color: '#000000',
    marginBottom: 20,
    backgroundColor: '#ffffff',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  headerImage: {
    paddingTop: 2,
    maxWidth: 200,
    cursor: 'pointer',
  },
  smallHeaderImage: {
    paddingTop: 2,
    maxWidth: 50,
    cursor: 'pointer',
  },
  appBarTitle: {
    fontSize: 25,
    paddingLeft: 20,
    color: '#7e7e7e',
  },
  rightToolBar: {
    marginLeft: 'auto',
    marginRight: '-12px',
    display: 'flex',
    alignItems: 'center',
  },
});

class PowerReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,
      pages: [],
      pagesEl: null,
      viewPatientHistory: false,
      viewClinicMap: false,
      loadingReport: true,
      saveViewDialog: false,
      bookmarkToSave: null,
      newViewName: '',
      savedViewsEl: null,
      savedViews: [],
      savedViewSuccess: false,
      currentSavedReport: null,
      standardReportName: null,
      intervalId: 0,
      closeIntervalId: 0,
      hasSetSlicer: false,
      hidePrintIcon: false,
    };
  }

  componentDidMount() {
    this.getUserReports();

    let reassignToken = () => {
      console.log('reassign token');
      this.refetchToken();
    };

    let close = () => {
      // console.log("tick");
      this.props.closeReport();
    };

    let autoCloseInterval = setInterval(close, 3600000);

    let intervalId = setInterval(reassignToken, 3000000);
    this.setState({ intervalId: intervalId });
    this.setState({ closeIntervalId: autoCloseInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    clearInterval(this.state.closeIntervalId);
  }

  refetchToken = () => {
    if (this.report) {
      let URL = API.REACT_APP_API_EMBEDDEDREPORTS;
      let token = localStorage.getItem('token');
      let headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      };

      axios
        .get(URL, headers)
        .then(response => {
          let data = response.data.data;
          // console.log("reassign!", data);

          this.report.setAccessToken(data.accessToken);

          // this.setState({ viewPowerReport: true });
        })
        .catch(err => {
          console.log('Error fetching report tokens: ', err);
        });
    }
  };

  beginDataFetch = async () => {
    try {
      const pages = await this.report.getPages();
      // console.log("Pages", pages);
      this.setState({
        pages: pages,
      });
    } catch {
      console.log('Err fetching pages...');
    }
  };

  showSavedViews = event => {
    this.setState({
      savedViewsEl: event.currentTarget,
    });
  };

  handlePage = async page => {
    console.log('handle page', page);
    if (this.report) {
      // let orgId = this.props.org.id;

      // const settings = {
      //   panes: {
      //     filters: {
      //       visible: false,
      //       target: {
      //         table: "Orgs",
      //         column: "Id",
      //       },
      //       operator: "In",
      //       values: [orgId],
      //     },
      //     pageNavigation: {
      //       visible: false,
      //     },
      //   },
      // };

      // this.report.updateSettings(settings);

      // if (this.state.currentSavedReport) {
      //   await this.report.reload();
      //   const myTimeout = setTimeout(() => {
      //     this.report.setPage(page.name);
      //   }, 1000);
      // } else {
      //   this.report.setPage(page.name);
      // }

      this.report.setPage(page.name);

      this.setState({
        viewPatientHistory: false,
        viewClinicMap: false,
        // loadingReport: true,
        savedViewsEl: null,
        standardReportName: page.displayName,
        currentSavedReport: null,
        hidePrintIcon: false,
        viewEmailReports: false,
      });
      if (!this.state.hasSetSlicer) {
        console.log('Check', this.props.org);

        // https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/control-report-slicers#hierarchy-slicer
        let children =
          this.props.org.id === '5f43f0a7e0a5d550acd74af3'
            ? [
                {
                  operator: 'Selected',
                  value: 'Benchmark 2f09714b',
                },
                {
                  operator: 'Selected',
                  value: 'Benchmark acd74b02',
                },
              ]
            : [
                {
                  operator: 'Selected',
                  value: this.props.org.orgName,
                },
              ];

        const filter = {
          $schema: 'http://powerbi.com/product/schema#hierarchy',
          target: [
            {
              table: 'OrgBenchmarks',
              column: 'BenchmarkGroups',
            },
            {
              table: 'Orgs',
              column: 'OrgName',
            },
          ],
          filterType: 9,
          hierarchyData: [
            {
              operator: 'Inherited',
              value: 'All',
              children: children,
              // [{
              //   "operator": "Selected", "value": this.props.org.orgName
              // }]
            },
          ],
        };

        const dateFilter = {
          $schema: 'http://powerbi.com/product/schema#advanced',
          target: {
            table: 'DateSliceTable',
            column: 'Date',
          },
          logicalOperator: 'And',
          conditions: [
            {
              operator: 'GreaterThanOrEqual',
              value: this.props.org.earliestSiteStartDate,
            },
          ],
          filterType: models.FilterType.AdvancedFilter,
        };

        const isDuringVisitFilter = {
          $schema: 'http://powerbi.com/product/schema#basic',
          target: {
            table: 'RoomUsers',
            column: 'C_IsDuringVisit',
          },
          operator: 'All',
          values: [],
        };

        console.log('org', this.props.org);

        try {
          const pages = await this.report.getPages();

          // Retrieve the active page.
          let pageWithSlicer = pages.filter(function (page) {
            return page.isActive;
          })[0];

          const visuals = await pageWithSlicer.getVisuals();

          // Retrieve all visuals with the type "slicer".
          let slicers = visuals.filter(function (visual) {
            return visual.type === 'slicer';
          });

          console.log('Slicers', slicers);
          slicers.forEach(async (slicer, i) => {
            // Get the slicer state.
            const state = await slicer.getSlicerState();
            let isOrgSlicer = false;
            // loop through state.targets, because there can be multiple targets in hierarchical slicers
            state.targets.forEach(target => {
              if (target.table === 'Orgs' && target.column === 'OrgName') {
                isOrgSlicer = true;
              }
            });

            if (isOrgSlicer) {
              await slicer.setSlicerState({
                filters: [],
              });
              await slicer.setSlicerState({ filters: [filter] });

              this.setState({ hasSetSlicer: true });

              console.log(
                'Slicer name: "' + slicer.name + '"\nSlicer state:\n',
                state
              );
            }

            if (this.props.org.orgName === 'CVCH') {
              if (
                state.targets[0].table === 'UserTypes' &&
                state.targets[0].column === 'UserTypeName'
              ) {
                await slicer.setSlicerState({
                  filters: [],
                });
              }
            }
          });

          await this.report.updateFilters(models.FiltersOperations.Add, [
            dateFilter,
          ]);

          if (this.props.org.orgName === 'CVCH') {
            await this.report.updateFilters(models.FiltersOperations.Replace, [
              isDuringVisitFilter,
            ]);
          }
        } catch (errors) {
          console.log(errors);
        }
      }

      console.log(
        'LOG',
        'Viewed Report',
        page.name,
        this.props.loggedInUserData.id
      );
      this.props.logAction(
        'Viewed Report',
        page.displayName,
        this.props.loggedInUserData.id
      );
    }
  };

  captureBookmark = async () => {
    try {
      const capturedBookmark = await this.report.bookmarksManager.capture();
      // let log = "Captured bookmark state: " + capturedBookmark.state;
      // console.log(log);
      if (capturedBookmark.state) {
        this.setState({
          saveViewDialog: true,
          bookmarkToSave: capturedBookmark.state,
          savedViewsEl: null,
        });
      }
    } catch {
      console.log('error saving bookmark');
    }
  };

  loadView = async view => {
    try {
      await this.report.bookmarksManager.applyState(view.reportHashString);
      this.setState({
        savedViewsEl: null,
        currentSavedReport: view,
        viewPatientHistory: false,
        viewClinicMap: false,
        standardReportName: null,
      });
    } catch {
      console.log('error loading bookmark');
    }
  };

  printReport = () => {
    if (this.state.viewPatientHistory) {
      // window.print();
      var divContents = document.getElementById('testreport').innerHTML;
      var a = window.open('', '', 'height=500, width=500');
      a.document.write('<html>');
      a.document.write('<body>');
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();
      a.print();
    } else {
      if (this.report) {
        this.report.print();
      }
    }
  };

  openPatientHistory = () => {
    this.props.logAction(
      'Viewed Patient History',
      '',
      this.props.loggedInUserData.id
    );

    this.setState({
      viewPatientHistory: true,
      viewClinicMap: false,
      savedViewsEl: null,
      currentSavedReport: null,
      viewEmailReports: false,
      hidePrintIcon: true,
      standardReportName: 'Patient History',
    });
  };

  openClinicMap = () => {
    this.props.logAction(
      'Viewed Clinic Map',
      '',
      this.props.loggedInUserData.id
    );

    this.setState({
      viewClinicMap: true,
      viewPatientHistory: false,
      savedViewsEl: null,
      currentSavedReport: null,
      viewEmailReports: false,
      hidePrintIcon: true,
      standardReportName: 'Traffic Patterns',
    });
  };

  openEmailReports = () => {
    this.setState({
      viewEmailReports: true,
      viewClinicMap: false,
      viewPatientHistory: false,
      savedViewsEl: null,
      currentSavedReport: null,
      hidePrintIcon: true,
      standardReportName: 'Email Reports',
    });
  };

  getUserReports = () => {
    console.log('Fetch user reports');
    let URL =
      API.REACT_APP_API_USERREPORTVIEWS + this.props.loggedInUserData.id;
    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };
    // console.log("Mounted", URL);
    axios
      .get(URL, headers)
      .then(res => {
        // console.log("Res!", res.data.data);
        let response = res.data.data;
        this.setState({
          savedViews: response.reportViews,
        });
      })
      .catch(err => {
        console.log('Error fetching user saved reports', err);
      });
  };

  updateView = () => { };

  saveNewView = () => {
    // console.log("Save!", this.state.newViewName, this.state.bookmarkToSave);
    let newReportView = {
      reportName: this.state.newViewName,
      reportHashString: this.state.bookmarkToSave,
    };

    let modifiedReports = [...this.state.savedViews, newReportView];
    let constructedData = {
      reportViews: modifiedReports,
      userId: this.props.loggedInUserData.id,
    };

    // console.log("SEND THIS", constructedData);

    let payload = JSON.stringify(constructedData);
    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SAVEREPORTVIEWS, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(() => {
        console.log('Good!');
        this.getUserReports();

        this.setState({
          saveViewDialog: false,
          savedViewSuccess: true,
          standardReportName: null,
          currentSavedReport: newReportView,
          savedViewsEl: null,
        });
        setTimeout(() => {
          this.setState({
            savedViewSuccess: false,
          });
        }, 5000);
      })
      .catch(err => {
        console.log('Error posting', err);
      });
  };

  updateExistingView = async () => {
    try {
      // console.log("Editing", this.state.currentSavedReport);
      // console.log("Current views", this.state.savedViews);
      const capturedBookmark = await this.report.bookmarksManager.capture();

      let viewsToUpdate = [...this.state.savedViews];
      viewsToUpdate.forEach(view => {
        if (view.reportName === this.state.currentSavedReport.reportName) {
          view.reportHashString = capturedBookmark.state;
        }
      });

      let constructedData = {
        reportViews: viewsToUpdate,
        userId: this.props.loggedInUserData.id,
      };

      let payload = JSON.stringify(constructedData);
      let token = localStorage.getItem('token');
      fetch(API.REACT_APP_API_SAVEREPORTVIEWS, {
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          return res.json();
        })
        .then(() => {
          console.log('Good!');
          this.getUserReports();

          this.setState({
            saveViewDialog: false,
            savedViewSuccess: true,
          });
          setTimeout(() => {
            this.setState({
              savedViewSuccess: false,
            });
          }, 5000);
        })
        .catch(err => {
          console.log('Error posting', err);
        });
    } catch {
      console.log('error updating existing view');
    }
  };

  deleteView = viewToDelete => {
    console.log('Delete', viewToDelete);

    let viewsToUpdate = [...this.state.savedViews];

    viewsToUpdate = viewsToUpdate.filter(savedView => {
      return savedView.reportName !== viewToDelete.reportName;
    });

    let constructedData = {
      reportViews: viewsToUpdate,
      userId: this.props.loggedInUserData.id,
    };

    let payload = JSON.stringify(constructedData);
    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SAVEREPORTVIEWS, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(() => {
        console.log('Good!');

        this.getUserReports();
      })
      .catch(err => {
        console.log('Error posting', err);
      });
  };

  render() {
    const { classes } = this.props;

    let windowWidth = window.innerWidth;

    return (
      <div>
        <Toolbar className={classes.appBar}>
          {windowWidth < 800 ? (
            <img
              className={classes.smallHeaderImage}
              onClick={this.props.closeReport}
              src={loadingIcon}
              alt="SyncTimes"
            />
          ) : (
            <img
              className={classes.headerImage}
              onClick={this.props.closeReport}
              src={synctimesLogo}
              alt="SyncTimes"
            />
          )}

          <div style={{ cursor: 'pointer' }} onClick={this.showSavedViews}>
            {!this.state.currentSavedReport ? (
              <Typography className={classes.appBarTitle}>
                {this.state.standardReportName ? (
                  <>{this.state.standardReportName}</>
                ) : (
                  <>Select Report</>
                )}
                <ExpandMoreIcon />
              </Typography>
            ) : (
              <Typography className={classes.appBarTitle}>
                {this.state.standardReportName ? (
                  <>{this.state.standardReportName}</>
                ) : (
                  <>{this.state.currentSavedReport.reportName}</>
                )}

                <ExpandMoreIcon />
              </Typography>
            )}
          </div>

          {this.state.pages.length > 0 && this.report ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.currentSavedReport ? (
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    onClick={this.updateExistingView}
                  >
                    Update This View
                  </Button>
                ) : null}

                {this.state.hidePrintIcon ? null : (
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    onClick={this.captureBookmark}
                  >
                    Save as New View
                  </Button>
                )}
              </div>
            </div>
          ) : null}

          <section className={classes.rightToolBar}>
            {this.state.hidePrintIcon === false ? (
              <IconButton onClick={this.printReport}>
                <PrintIcon style={{ color: 'black' }} />
              </IconButton>
            ) : null}
            <IconButton onClick={this.props.closeReport}>
              <BackIcon style={{ color: 'black' }} />
            </IconButton>
          </section>
        </Toolbar>

        {this.state.savedViewSuccess ? (
          <SuccessSnack successSnack={true} successMessage="View Saved!" />
        ) : null}

        <Dialog
          open={this.state.saveViewDialog}
          onClose={() => this.setState({ saveViewDialog: false })}
          BackdropProps={{
            classes: {
              root: classes.root,
            },
          }}
          transitionDuration={{ enter: 0, exit: 0 }}
        >
          <div style={{ backgroundColor: 'white' }}>
            <DialogContent>
              <div>New Report View Name</div>
              <br />
              <TextField
                autoFocus
                label="Name"
                value={this.state.newViewName}
                onChange={e => this.setState({ newViewName: e.target.value })}
              />
              <br />
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                onClick={this.saveNewView}
              >
                Save
              </Button>
            </DialogContent>
          </div>
        </Dialog>

        <Menu
          anchorEl={this.state.savedViewsEl}
          open={Boolean(this.state.savedViewsEl)}
          onClose={() => this.setState({ savedViewsEl: null })}
        >
          <div>
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                fontSize: 24,
                paddingTop: 10,
              }}
            >
              Reports
            </div>
            <div
              style={{
                backgroundColor: 'lightgray',
                height: 1,
                width: '90%',
                margin: 'auto',
                marginTop: 5,
                marginBottom: 10,
              }}
            />
          </div>

          {this.state.pages.map((page, i) =>
            page.visibility === 0 ? (
              <MenuItem key={i} onClick={() => this.handlePage(page)}>
                {page.displayName}
              </MenuItem>
            ) : null
          )}
          {/* {this.props.isFrontDeskIntegration ? <MenuItem onClick={this.openPatientHistory}>Patient History</MenuItem> : null} */}
          <MenuItem onClick={this.openPatientHistory}>Patient History</MenuItem>
          <MenuItem onClick={this.openClinicMap}>Traffic Patterns</MenuItem>
          <MenuItem onClick={this.openEmailReports}>
            Email Reports
            <span style={{ marginLeft: '10px', color: 'blue' }}>New</span>
          </MenuItem>

          <div>
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                fontSize: 24,
                paddingTop: 10,
              }}
            >
              Your Views
            </div>
            <div
              style={{
                backgroundColor: 'lightgray',
                height: 1,
                width: '90%',
                margin: 'auto',
                marginTop: 5,
                marginBottom: 10,
              }}
            />
          </div>

          {this.state.savedViews.map((view, i) => (
            <div
              key={i}
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: 32,
              }}
            >
              <MenuItem
                style={{ width: '100%' }}
                onClick={() => this.loadView(view)}
              >
                {view.reportName}
              </MenuItem>
              <div style={{ position: 'absolute', right: 0 }}>
                {this.state.currentSavedReport?.reportName !==
                view.reportName ? (
                  <div
                    onClick={() => this.deleteView(view)}
                    style={{
                      margin: 'auto 10px auto 10px',
                      color: 'red',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    X
                  </div>
                ) : null}
              </div>
            </div>
          ))}
          <MenuItem onClick={this.captureBookmark}>+ Create New View</MenuItem>
        </Menu>

        {this.state.viewPatientHistory ? (
          <div>
            <PatientHistory
              isFrontDeskIntegration={this.props.isFrontDeskIntegration}
              timeZone={this.props.timeZone}
              organization={this.props.org}
            />
          </div>
        ) : null}

        {this.state.viewClinicMap ? (
          <div>
            <ClinicMapLoader />
          </div>
        ) : null}

        {this.state.viewEmailReports ? (
          <div>
            <EmailReports />
          </div>
        ) : null}

        <div
          style={
            this.state.viewPatientHistory ||
            this.state.viewClinicMap ||
            this.state.viewEmailReports
              ? { display: 'none' }
              : {}
          }
        >
          {this.state.loadingReport ? (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                textAlign: 'center',
                transform: 'translate(-50%, -50%)',
                WebkitTransform: 'translate(-50%, -50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                className="flashing"
                src={loadingIcon}
                style={{ width: '50%' }}
                alt="#"
              />
            </div>
          ) : null}
          <div
            style={
              this.state.loadingReport
                ? { visibility: 'hidden', border: '2px solid red' }
                : {}
            }
          >
            <PowerBIEmbed
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual and qna
                id: this.props.embedData.reportId,
                embedUrl: this.props.embedData.embedUrl,
                accessToken: this.props.embedData.accessToken,
                tokenType: models.TokenType.Embed,
                // settings: {
                //   panes: {
                //     filters: {
                //       expanded: false,
                //       visible: false,
                //     },
                //   },
                //   background: models.BackgroundType.Transparent,
                // },
                settings: {
                  panes: {
                    filters: {
                      visible: false,
                      target: {
                        table: 'Orgs',
                        column: 'Id',
                      },
                      operator: 'In',
                      values: [this.props.org.id],
                    },
                    pageNavigation: {
                      visible: false,
                    },
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    () => {
                      // console.log("Report loaded");
                      this.beginDataFetch();
                    },
                  ],
                  [
                    'rendered',
                    () => {
                      // console.log("Report rendered");
                      this.setState({
                        loadingReport: false,
                      });
                    },
                  ],
                  // ['error', function (event) {console.log(event.detail);}]
                ])
              }
              cssClassName={'report-embed'}
              getEmbeddedComponent={embeddedReport => {
                // this.report = embeddedReport as Report;
                this.report = embeddedReport;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { logAction })(
  withStyles(styles)(PowerReport)
);
