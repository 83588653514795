import React, { useState, useEffect, useMemo, useCallback } from "react";

// Material UI Imports
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Services & Types
import { IUser } from "../../../../../interfaces/interfaces";
import {
  LocalStorageToken,
  RecurrenceSchedule,
  ActionFormField,
  ActionUsageFields,
  DateRangeOnlyFields,
  ReportsAccessFields,
  ScheduledEmailReport,
} from "../emailReportingTypes";
import { availableReports, ReportData } from "../reportData";
import { scheduleRecurringReport, updateScheduledReport } from "../emailReportsService";

// Components
import EmailFieldManager from "./EmailFieldManager";
import RecurringConfigurator from "./RecurringConfigurator";
import { ActionRequestsForm } from "./ActionRequestsForm";
import { FirstPatientForm } from "./FirstPatientForm";
import { KeptApptsForm } from "./KeptApptsForm";
import { PTVisitsBreakdownForm } from "./PTVisitsBreakdownForm";
import { ReportsAccessedForm } from "./ReportsAccessedForm";
import { UsersInOrgForm } from "./UsersInOrgForm";

interface ReportFormProps {
  user: IUser;
  selectedReportType: ReportData | null;
  formFields: ActionFormField[];
  setIsFormReady: (isReady: boolean) => void;
  handleSelectOptionsChange: (key: string, value: string | boolean) => void;
  incomingSettings?: ActionUsageFields | DateRangeOnlyFields | ReportsAccessFields | null | undefined;
}

const ReportForm: React.FC<ReportFormProps> = ({ selectedReportType, handleSelectOptionsChange, user, setIsFormReady, incomingSettings, formFields }) => {
  const renderForm = () => {
    if (!selectedReportType) {
      return null;
    }

    switch (selectedReportType.reportType) {
      case "ActionUsage":
        return (
          <ActionRequestsForm
            handleSelectChange={handleSelectOptionsChange}
            formFields={formFields}
            orgId={user.mongoOrganizationId}
            setIsFormReady={setIsFormReady}
            isRecurring={true}
            incomingSettings={incomingSettings as ActionUsageFields}
          />
        );
      case "FirstPatient":
        return (
          <FirstPatientForm
            handleSelectChange={handleSelectOptionsChange}
            setIsFormReady={setIsFormReady}
            isRecurring={true}
            incomingSettings={incomingSettings as DateRangeOnlyFields}
          />
        );
      case "KeptAppointments":
        return (
          <KeptApptsForm
            handleSelectChange={handleSelectOptionsChange}
            setIsFormReady={setIsFormReady}
            isRecurring={true}
            incomingSettings={incomingSettings as DateRangeOnlyFields}
          />
        );
      case "PatientVisitBreakdown":
        return (
          <PTVisitsBreakdownForm
            handleSelectChange={handleSelectOptionsChange}
            setIsFormReady={setIsFormReady}
            isRecurring={true}
            incomingSettings={incomingSettings as DateRangeOnlyFields}
          />
        );
      case "ReportsAccess":
        return (
          <ReportsAccessedForm
            handleSelectChange={handleSelectOptionsChange}
            setIsFormReady={setIsFormReady}
            isSyncTimesAdmin={user.isSyncTimesAdmin} // Pass the user's isSyncTimesAdmin property
            isRecurring={true}
            incomingSettings={incomingSettings as ReportsAccessFields}
          />
        );
      case "UsersInOrganization":
        return <UsersInOrgForm setIsFormReady={setIsFormReady} />;
      default:
        return null;
    }
  };

  return renderForm();
};

const initializeReportState = (user: IUser, availableUsers: IUser[]): ScheduledEmailReport => ({
  reportType: "",
  orgId: user.mongoOrganizationId,
  reportName: "",
  description: "",
  emailSettings: {
    reportType: "",
    orgId: user.mongoOrganizationId,
    to: [],
    cc: [],
    customFields: {
      orgId: user.mongoOrganizationId,
      dateRangeName: "",
      reportAllOrgs: false,
      customStartDate: undefined,
      customEndDate: undefined,
    } as DateRangeOnlyFields | ActionUsageFields | ReportsAccessFields,
  },
  recurrenceSchedule: {
    scheduledTime: "00:00",
    tzIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
    recurrencePattern: "Day",
    daysOfWeek: [],
  },
});

interface RecurringReportDialogProps {
  open: boolean;
  user: IUser;
  formFields: ActionFormField[];
  token: LocalStorageToken;
  availableUsers: IUser[];
  incomingReport?: ScheduledEmailReport | null; // Change this line
  onClose: () => void;
  refreshScheduledReports: () => void;
  showSnackbar: (message: string, isError: boolean) => void;
}

const RecurringReportDialog: React.FC<RecurringReportDialogProps> = ({
  open,
  user,
  formFields,
  token,
  availableUsers,
  incomingReport,
  refreshScheduledReports,
  onClose,
  showSnackbar,
}) => {
  const [selectedReportType, setSelectedReportType] = useState<ReportData | null>(null);
  const [isFormReady, setIsFormReady] = useState(false);
  const [availableRecipients, setAvailableRecipients] = useState<IUser[]>(availableUsers);
  const [toRecipients, setToRecipients] = useState<IUser[]>([]);
  const [ccRecipients, setCcRecipients] = useState<IUser[]>([]);
  const [report, setReport] = useState<ScheduledEmailReport | undefined>(undefined);
  const [hasDaysSelected, setHasDaysSelected] = useState(true);

  const handleSelectOptionsChange = useCallback(
    (key: string, value: string | boolean) => {
      setReport((prevReport) => {
        if (prevReport && selectedReportType) {
          const { emailSettings } = prevReport;
          let updatedCustomFields: ActionUsageFields | DateRangeOnlyFields | ReportsAccessFields | null;

          switch (selectedReportType.reportType) {
            case "ActionUsage":
              console.log("ActionUsage custom fields:", emailSettings.customFields);
              updatedCustomFields = {
                ...emailSettings.customFields,
                [key]: value,
                orgId: user.mongoOrganizationId,
              } as ActionUsageFields;
              break;
            case "FirstPatient":
            case "KeptAppointments":
            case "PatientVisitBreakdown":
              updatedCustomFields = {
                ...emailSettings.customFields,
                [key]: value,
              } as DateRangeOnlyFields;
              break;
            case "ReportsAccess":
              console.log("All orgs change here:", key, value);
              updatedCustomFields = {
                ...emailSettings.customFields,
                [key]: value,
                orgId: user.mongoOrganizationId,
                reportAllOrgs: key === "reportAllOrgs" ? (value as boolean) : (emailSettings.customFields as ReportsAccessFields)?.reportAllOrgs || false,
              } as ReportsAccessFields;
              break;
            default:
              updatedCustomFields = null;
          }

          return {
            ...prevReport,
            emailSettings: {
              ...emailSettings,
              customFields: updatedCustomFields,
            },
          };
        }
        return prevReport;
      });
    },
    [selectedReportType, user]
  );

  const initializeState = useCallback(() => {
    console.log("Initializing state for recurring report dialog");
    const initialReport = initializeReportState(user, availableUsers);

    // Check if the current user is in the list of available users
    const isCurrentUserInAvailableUsers = availableUsers.some((u) => u.id === user.id);

    // If not, create a new array with the current user added
    const updatedAvailableUsers = isCurrentUserInAvailableUsers ? availableUsers : [...availableUsers, user];

    if (incomingReport) {
      const toEmails = incomingReport.emailSettings.to;
      const ccEmails = incomingReport.emailSettings.cc;

      const toUsers = updatedAvailableUsers.filter((u) => toEmails.includes(u.userEmail));
      const ccUsers = updatedAvailableUsers.filter((u) => ccEmails.includes(u.userEmail));

      setToRecipients(toUsers);
      setCcRecipients(ccUsers);

      // Remove the user from availableRecipients only if they're in the to or cc list
      const isUserInToOrCc = toEmails.includes(user.userEmail) || ccEmails.includes(user.userEmail);
      setAvailableRecipients(
        updatedAvailableUsers.filter((u) => !toEmails.includes(u.userEmail) && !ccEmails.includes(u.userEmail) && (u.id !== user.id || !isUserInToOrCc))
      );

      const reportType = incomingReport.reportType;
      const selectedReport = availableReports.find((report) => report.reportType === reportType);
      setSelectedReportType(selectedReport || null);

      // Set the report state with the incoming report's properties
      setReport({
        ...incomingReport,
        description: incomingReport.description || "",
      });
    } else {
      setReport(initialReport);
      setToRecipients([user]);
      setCcRecipients([]);
      setAvailableRecipients(updatedAvailableUsers.filter((u) => u.id !== user.id));
      setSelectedReportType(null);
    }
    setIsFormReady(false);
  }, [user, availableUsers, incomingReport]);

  useEffect(() => {
    if (incomingReport) {
      setReport(incomingReport);
      initializeState();
    }
  }, [incomingReport, initializeState]);

  useEffect(() => {
    if (open) {
      initializeState();
    }
  }, [open, initializeState]);

  // Type guard function
  const isReportsAccessFields = (customFields: DateRangeOnlyFields | ActionUsageFields | ReportsAccessFields | null): customFields is ReportsAccessFields => {
    return customFields !== null && (customFields as ReportsAccessFields).reportAllOrgs !== undefined;
  };

  const isSaveEnabled = useMemo(
    () => toRecipients?.length > 0 && selectedReportType !== null && isFormReady && hasDaysSelected,
    [toRecipients, selectedReportType, isFormReady, hasDaysSelected]
  );

  const handleRecurrenceScheduleChange = useCallback((schedule: RecurrenceSchedule) => {
    console.log("Recurrence schedule changed:", schedule);
    if (schedule.daysOfWeek.length === 0) {
      console.log("No days selected");
      setHasDaysSelected(false);
    } else {
      console.log("Days selected");
      setHasDaysSelected(true);
    }
    setReport((prevReport) => {
      if (prevReport) {
        return {
          ...prevReport,
          recurrenceSchedule: schedule,
        };
      }
      return prevReport;
    });
  }, []);

  const handleReportChange = (selectedReportType: ReportData | string) => {
    setSelectedReportType(selectedReportType as ReportData);
    if (selectedReportType && typeof selectedReportType !== "string") {
      setReport((prevReport) => {
        if (prevReport) {
          return {
            ...prevReport,
            reportType: selectedReportType.reportType,
            emailSettings: {
              ...prevReport.emailSettings,
              customFields: null,
            },
          };
        }
        console.log("Creating new empty report");
        return {
          id: "",
          orgId: user.mongoOrganizationId,
          reportType: selectedReportType.reportType,
          reportName: selectedReportType.displayLabel,
          description: "",
          isActive: true,
          isOwner: true,
          emailSettings: {
            reportType: selectedReportType.reportType,
            orgId: user.mongoOrganizationId,
            to: toRecipients.map((recipient) => recipient.userEmail),
            cc: ccRecipients.map((recipient) => recipient.userEmail),
            customFields: null,
          },
          recurrenceSchedule: {
            scheduledTime: "00:00",
            tzIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
            recurrencePattern: "",
            daysOfWeek: [],
            interval: 1,
            occurrences: 0,
          },
          subscribedUsers: [],
        };
      });
    } else {
      setReport(undefined);
    }
  };

  const handleCancel = () => {
    setSelectedReportType(null);
    onClose();
  };

  const handleSave = async () => {
    console.log("Saving recurring email report");
    if (report) {
      const updatedReport: ScheduledEmailReport = {
        ...report,
        emailSettings: {
          ...report.emailSettings,
          reportType: selectedReportType?.reportType || "",
          to: toRecipients.map((recipient) => recipient.userEmail),
          cc: ccRecipients.map((recipient) => recipient.userEmail),
          customFields: report.emailSettings.customFields
            ? {
                ...report.emailSettings.customFields,
                reportAllOrgs:
                  selectedReportType?.reportType === "ReportsAccess" && isReportsAccessFields(report.emailSettings.customFields)
                    ? report.emailSettings.customFields.reportAllOrgs
                    : false,
              }
            : null,
        },
        recurrenceSchedule: {
          ...report.recurrenceSchedule,
          scheduledTime: report.recurrenceSchedule.scheduledTime,
          tzIdentifier: report.recurrenceSchedule.tzIdentifier || Intl.DateTimeFormat().resolvedOptions().timeZone,
          startDate: report.recurrenceSchedule.startDate,
          endDate: report.recurrenceSchedule.endDate,
          daysOfWeek: report.recurrenceSchedule.daysOfWeek.length > 0 ? report.recurrenceSchedule.daysOfWeek : [0],
          interval: report.recurrenceSchedule.interval || 1,
          occurrences: report.recurrenceSchedule.occurrences || 0,
          recurrencePattern: report.recurrenceSchedule.recurrencePattern || "Day",
        },
      };

      // Close the dialog box and reset the form
      onClose();
      setSelectedReportType(null);
      setToRecipients([]);
      setCcRecipients([]);
      setAvailableRecipients([...availableUsers]);
      setReport(undefined);
      setIsFormReady(false);
      setHasDaysSelected(true);

      try {
        if (incomingReport) {
          console.log("Updating scheduled report:", updatedReport);
          await updateScheduledReport(token, updatedReport);
        } else {
          console.log("Scheduling recurring email report:", updatedReport);
          await scheduleRecurringReport(token, updatedReport);
        }

        console.log("Recurring email report saved successfully");
        showSnackbar("Recurring email report saved successfully", false);
        refreshScheduledReports();
      } catch (error) {
        console.error("Error saving recurring email report:", error);
        showSnackbar("Error saving recurring email report", true);
      }
    }
  };

  const onAddRecipient = (recipient: IUser, field: "to" | "cc") => {
    if (field === "to") {
      setToRecipients((prevRecipients) => [...prevRecipients, recipient]);
    } else {
      setCcRecipients((prevRecipients) => [...prevRecipients, recipient]);
    }
    setAvailableRecipients((prevAvailableRecipients) => prevAvailableRecipients.filter((r) => r.id !== recipient.id));
  };

  const onRemoveRecipient = (recipient: IUser, field: "to" | "cc") => {
    if (field === "to") {
      setToRecipients((prevRecipients) => prevRecipients.filter((r) => r.id !== recipient.id));
    } else {
      setCcRecipients((prevRecipients) => prevRecipients.filter((r) => r.id !== recipient.id));
    }
    setAvailableRecipients((prevAvailableRecipients) => {
      // Check if the recipient is already in the availableRecipients list
      const recipientExists = prevAvailableRecipients.some((r) => r.id === recipient.id);

      // Only add the recipient back if they don't already exist in the list
      if (!recipientExists) {
        return [...prevAvailableRecipients, recipient];
      }
      return prevAvailableRecipients;
    });
  };

  // const handleScheduleChange = (key: string, value: string) => {

  //   setReport((prevReport) => {
  //     if (prevReport) {
  //       return {
  //         ...prevReport,
  //         [key]: value,
  //       };
  //     }
  //     return prevReport;
  //   });
  // };

  return (
    <Dialog open={open} onClose={handleCancel} onEnter={initializeState} aria-labelledby="form-dialog-title" PaperProps={{ style: { width: "600px" } }}>
      <DialogTitle id="form-dialog-title">Email Report</DialogTitle>
      <DialogContent>
        <Paper style={{ padding: 25, display: "flex", flexDirection: "column", gap: "16px" }}>
          <Autocomplete
            id="report-search"
            options={availableReports}
            getOptionLabel={(option) => option.displayLabel}
            getOptionSelected={(option, value) => option.reportType === value.reportType}
            onChange={(_, value) => handleReportChange(value)}
            value={selectedReportType}
            renderInput={(params) => <TextField {...params} label="Reports" variant="outlined" />}
          />
          {report && (
            <div>
              <EmailFieldManager
                toRecipients={toRecipients}
                ccRecipients={ccRecipients}
                availableUsers={availableRecipients}
                onAddRecipient={onAddRecipient}
                onRemoveRecipient={onRemoveRecipient}
              />
              {/* <p style={{ backgroundColor: "red", color: "white" }}> Report form: {selectedReportType?.reportType}</p> */}
              <ReportForm
                selectedReportType={selectedReportType}
                formFields={formFields}
                handleSelectOptionsChange={handleSelectOptionsChange}
                user={user}
                setIsFormReady={setIsFormReady}
                incomingSettings={incomingReport?.emailSettings.customFields}
              />

              {/* <TextField
                label="Enter a name for this recurring report (optional)"
                value={report.description || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleScheduleChange("description", event.target.value)}
                fullWidth
                multiline
                rowsMax={4}
                style={{ marginBottom: 20 }}
              /> */}
              <div style={{ paddingTop: 10 }}>
                <RecurringConfigurator recurrenceSchedule={incomingReport?.recurrenceSchedule} setRecurrenceSchedule={handleRecurrenceScheduleChange} />
              </div>
            </div>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isSaveEnabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecurringReportDialog;
