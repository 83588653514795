import { makeStyles } from "@material-ui/core";

const styles: any = {
  center: {
    textAlign: "center",
  },
  latestImage: {
    width: "100%",
  },
};


export const useStyles = makeStyles(styles);