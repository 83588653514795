import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IRoom } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useRooms = (orgId: string | null): {
  rooms: IRoom[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IRoom[]>;
} => {
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchRooms = useCallback(async (): Promise<IRoom[]> => {
    if (!orgId) {
      return [] as IRoom[];
    }

    setIsLoading(true);
    setError(null);

    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETROOMSBYORGID + orgId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    return axios
      .get(URL, headers)
      .then(response => {
        setIsLoading(false);
        const rooms: IRoom[] = response.data.data;
        setRooms(rooms);

        return rooms;
      })
      .catch(err => {
        console.log('Error: ', err);
        setIsLoading(false);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        return [] as IRoom[];
      });
  }, [orgId]);

  useEffect(() => {
    fetchRooms();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IRoom[]> => {
    return fetchRooms();
  }, [fetchRooms]);

  return {
    rooms,
    isLoading,
    error,
    refetch,
  };
};
