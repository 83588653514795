import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import clinicimage from "../images/clinicimage.png";
// import synctimesLogo from "../images/synctimes.png";

// import { API } from "../apiconfig";

const styles = (theme) => ({});


class ClinicMap extends Component {
    constructor() {
        super();
        this.state = {
            coordArray: [],

            clinicRooms: [
                {
                    name: "Office",
                    x: 223,
                    y: 350,
                    busyLevel: 10, // maybe 10-100?
                },
                {
                    name: "Exam 1",
                    x: 123,
                    y: 661,
                    busyLevel: 70,
                },
                {
                    name: "Exam 2",
                    x: 529,
                    y: 661,
                    busyLevel: 40,
                },
                {
                    name: "Waiting Room",
                    x: 550,
                    y: 350,
                    busyLevel: 25,
                },
                {
                    name: "Recovery",
                    x: 819,
                    y: 661,
                    busyLevel: 25,
                }
            ],


            clinicPathways: [
                {
                    provider: "Dr. Smith",
                    path: ["Office", "Exam 1", "Waiting Room", "Recovery"],
                    color: "red",
                    lineWidth: 6
                },
                {
                    provider: "Dr. Johnson",
                    path: ["Office", "Exam 2"],
                    color: "blue",
                    lineWidth: 3
                }
            ]
        };
    }

    getImageDetails = (e, image) => {
        console.log("Image Clicked", e.clientX, e.clientY);

        // let image = document.getElementById("clinicmapimage");

        // let x = e.clientX;
        // let y = e.clientY;

        // let x = e.clientX - image.getBoundingClientRect().left;
        // let y = e.clientY - image.getBoundingClientRect().top;

        let x = e.clientX - image.getBoundingClientRect().left;
        let y = e.clientY - image.getBoundingClientRect().top;



        this.setState({
            coordArray: [...this.state.coordArray, { x, y }]
        })
    }

    render() {
        // const { classes } = this.props;


        let xyPathways = [];
        this.state.clinicPathways.forEach((pathway, index) => {
            let xyPath = [];
            pathway.path.forEach((roomName) => {
                let room = this.state.clinicRooms.find(room => room.name === roomName);
                xyPath.push({ x: room.x, y: room.y, color: pathway.color, lineWidth: pathway.lineWidth });
            })
            xyPathways.push(xyPath);
        })


        console.log("pathways", xyPathways)


        // get width and height of clinicimage
        let image = new Image();
        image.src = clinicimage;
        image.onload = () => {
            console.log("image width", image.width, "image height", image.height);
            // imageX = image.width;
            // imageY = image.height;
        }



        return (
            <div
            // style={{ textAlign: "center" }}
            >


                <div className="img-overlay-wrap" onMouseDown={(e) => this.getImageDetails(e, image)}>


                    <img src={clinicimage} alt="clinic" id="clinicmapimage"
                        style={{ width: 500 }}
                    />

                    {/* Notes

                   if we are drawing on the image, WIDTH AND VIEWBOX MUST BE THE SAME

                   if we are just displaying the maping coords, the viewbox must be the same as the original image size
                    
                    */}

                    <svg
                        // viewBox="0 0 1000 1000" // <-- SET THE VIEWBOX TO THE SAME SIZE AS THE WIDTH AND HEIGHT OF THE IMAGE WHEN WE WERE SELCTING THE COORDINATES
                        viewBox={`0 0 1000 1000`}

                        // viewBox={`0 0 ${image.width} ${image.height}`}
                        // viewBox={`0 0 500 500`}
                        xmlns="http://www.w3.org/2000/svg"
                    >


                        {/* <line x1="0" y1="0" x2="500" y2="500" stroke="black" />
                        <line x1="50" y1="30" x2="20" y2="20" stroke="red" strokeWidth={4} />
                        <circle cx="50" cy="50" r="10" shapeRendering="geometricPrecision" /> */}



                        {/* Example drawing path with lines and circles connecting */}
                        {this.state.coordArray.map((coord, index) => {
                            return <circle key={index} cx={coord.x} cy={coord.y} r="10" stroke="black" strokeWidth="3" fill="pink" shapeRendering="geometricPrecision" />
                        })}
                        {this.state.coordArray.map((coord, index) => {
                            return (
                                <line key={index}
                                    x1={this.state.coordArray[index - 1]?.x ? this.state.coordArray[index - 1].x : coord.x}
                                    y1={this.state.coordArray[index - 1]?.y ? this.state.coordArray[index - 1].y : coord.y}
                                    x2={coord.x}
                                    y2={coord.y}
                                    stroke="black"
                                    strokeWidth="4"
                                />
                            )
                        })}





                        {/* Draws Pathways from xyPathways */}
                        {xyPathways.map((path, index) => {
                            return (
                                <g key={index}>

                                    {path.map((coord, index) => {
                                        return (
                                            <line key={index}
                                                x1={path[index - 1]?.x ? path[index - 1].x : coord.x}
                                                y1={path[index - 1]?.y ? path[index - 1].y : coord.y}
                                                x2={coord.x}
                                                y2={coord.y}
                                                stroke={coord.color}
                                                strokeWidth={coord.lineWidth}
                                            // stroke="black"
                                            // strokeWidth="4"
                                            />
                                        )
                                    })}
                                </g>
                            )
                        })}


                        {/* Draws Room circles and their details */}
                        {this.state.clinicRooms.map((room, index) => {
                            return (
                                <g key={index}>
                                    <circle cx={room.x} cy={room.y} r={room.busyLevel} stroke="black" strokeWidth="3" fill="pink" shapeRendering="geometricPrecision" />
                                    <text x={room.x - 10} y={room.y - 5} fontSize="12" fill="black">{room.name}</text>
                                    <text x={room.x - 10} y={room.y + 5} fontSize="12" fill="black">Busy Level: {room.busyLevel}</text>
                                </g>
                            )
                        })}




                    </svg>

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ClinicMap);
