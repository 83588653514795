import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import
  {
    ERROR_LOGGED_IN_USER,
    ERROR_LOGOUT_USER,
    FETCH_LOGGED_IN_USER,
  } from '../actions/types';
import { API } from '../apiconfig';
import { IUser } from '../interfaces/interfaces';
import { lockScreen } from '../utils/LockScreen';
import { checkRefreshToken } from '../utils/utils';

export const useLoggedInUser = (): {
  user: IUser | null;
  fetchUser: (input: {
    email: string;
    token: string;
    mockedOrgId?: string | null;
  }) => void;
  isLoading: boolean;
} => {
  const [user, setUser] = useState<IUser | null>(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = (input: {
    email: string;
    token: string;
    mockedOrgId?: string | null;
  }) => {
    const { email, token, mockedOrgId = null } = input;

    let URL = API.REACT_APP_API_LOGINDETAILS;
    let orgIdToMock: string | null = mockedOrgId;
    let data = {
      useremail: email,
    };

    setIsLoading(true);
    axios({
      method: 'post',
      url: URL,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
        Pragma: 'no-cache',
      },
    })
      .then(response => {
        setIsLoading(false);
        if (response.data.data.isUserSiteLocked) {
          console.log('User is locked out of site');
          lockScreen();
        }

        // getLatestRefreshToken(); // DONT USE THIS HERE, WILL CAUSE ISSUES LOGGING IN INITIALLY

        if (response.data.data) {
          const user: IUser = response.data.data;

          if (token && user.userEmail) {
            let email = user.userEmail;

            let headers = {
              headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
            };
            let URL = API.REACT_APP_API_HUBSPOTCONVO;

            // console.log("lets get", orgId);
            axios
              .get(URL, headers)
              .then(data => {
                // console.log("GOT BACK HUBSPOT", data.data.data)
                // if (data.data.)

                try {
                  if (data?.data?.data?.token) {
                    let hubspotToken = data.data.data.token;
                    // console.log("Hubspot", hubspotToken)
                    // @ts-ignore
                    window.hsConversationsSettings = {
                      identificationEmail: email,
                      identificationToken: hubspotToken,
                    };

                    // @ts-ignore
                    window.HubSpotConversations.widget.load();
                  }
                } catch (err) {
                  console.log('Error loading hubspot', err);
                }
              })
              .catch(err => {
                console.log('Error fetching hubspot', err);
              });
          }

          if (!user.isSyncTimesAdmin) {
            orgIdToMock = null;
            localStorage.removeItem('lastMockedOrganizationId');
          }

          // iOS iFrame messageHandler
          // @ts-ignore
          if (window.webkit && window.webkit.messageHandlers) {
            let iosPayload = {
              userId: user.id,
              token: token,
            };

            // @ts-ignore
            if (window.webkit.messageHandlers.userLogin) {
              // @ts-ignore
              window.webkit.messageHandlers.userLogin.postMessage(iosPayload);
            }
          }

          // console.log("response user", response.data.data);
          // android webview
          var valueToBeReturned = {
            userEmail: user.userEmail,
            token: token,
          };

          // console.log("sending", JSON.stringify(valueToBeReturned));

          const isWebView = navigator.userAgent.includes('wv');

          if (isWebView) {
            // @ts-ignore
            window.sendDataToAndroidWebview(JSON.stringify(valueToBeReturned));
          }

          // ionic iFrame postMessage

          if (window.self !== window.top) {
            window.parent.postMessage(
              {
                event_id: 'userDataMessage',
                data: {
                  user,
                  token: token,
                },
              },
              '*' //or "www.parentpage.com"
            );
          }

          let URL =
            API.REACT_APP_API_ORGANIZATION +
            response.data.data.mongoOrganizationId;
          if (orgIdToMock) {
            URL = API.REACT_APP_API_ORGANIZATION + orgIdToMock;
          }

          // let URL = API.REACT_APP_API_ORGANIZATION + orgIdToMock;

          // console.log("orgIdToMock", orgIdToMock);

          let headers = {
            headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
          };

          // console.log("lets get", orgId);
          axios.get(URL, headers).then(org => {
            let orgData = org.data.data;

            // console.log("orgData", orgData);

            if (orgIdToMock) {
              let mockedUser = {
                ...user,
                mongoOrganizationId: orgIdToMock,
              };

              dispatch({
                type: FETCH_LOGGED_IN_USER,
                payload: { user: mockedUser, org: orgData },
              });

              // dispatch(hideLoading());
            } else {
              dispatch({
                type: FETCH_LOGGED_IN_USER,
                payload: { user: user, org: orgData },
              });

              setUser(user);

              // dispatch(hideLoading());
            }
          });

          // Check if we should mock an Organization (And make sure they're a SyncTimes admin)
        } else {
          // Fetch was good, but no data came back. Force a logout.
          dispatch({
            type: ERROR_LOGOUT_USER,
          });
        }
      })
      .catch(function (err) {
        setIsLoading(false);
        console.log('Error fetching logged in user', err);

        if (err.response) {
          console.log('ERROR STATUS: ', err.response?.status);
          if (err.response?.status === 401) {
            // FORCE LOGOUT, UNAUTHORIZED
            console.log('UNAUTHORIZED, KICK EM OUT');

            checkRefreshToken();
          } else {
            // Something else broke, no data came back....
            dispatch({
              type: ERROR_LOGGED_IN_USER,
            });
            // dispatch(hideLoading());
          }
        } else {
          console.log('NETWORK ERROR');
          dispatch({
            type: ERROR_LOGGED_IN_USER,
          });
          // dispatch(hideLoading());
        }
      });
  };

  // useEffect(() => {
  //   fetchUser();

  //   // eslint-disable-next-line
  // }, []);

  return {
    user,
    fetchUser,
    isLoading,
  };
};
