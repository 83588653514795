import React, { FC } from "react";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";

type TProps = {};

const AppContainer: FC<TProps> = (props) => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppContainer;
