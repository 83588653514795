import { createTheme, makeStyles, Theme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#A7D6A4',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
});

const styles: any = (theme: Theme) => ({
  newServiceLineButton: {
    zIndex: 10,
    backgroundColor: '#4FAC48',
    position: 'absolute',
    right: theme.spacing(3),
  },
  deleteButton: {
    color: 'red',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
  },
  drawer: {
    padding: 50,
  },
  textField: {
    width: 180,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  closeButton: {
    cursor: 'pointer',
    color: 'red',
    textAlign: 'right',
  },
  submitButton: {
    backgroundColor: '#F0F1F9',
  },
  badgesTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  dialogStyle: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
  table: {
    minWidth: 700,
  },
  head: {
    backgroundColor: '#fafafa',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  checkBox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
  imageSelectContainer: {
    marginBottom: 10,
  },
  scheduledActionImage: {
    marginRight: 10,
    maxWidth: 50,
    maxHeight: 50,
  },
  scheduledActionsContainer: {
    marginBottom: 20,
  },
  selectedScheduledActionImage: {
    width: 50,
    height: 50,
    cursor: 'pointer',
  },
  addIconSquare: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#bdbdbd',
    border: '1px dashed #bdbdbd',
    height: 48,
    width: 48,
  },
  errorAddIconSquare: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    cursor: 'pointer',
    color: 'red',
    border: '1px dashed red',
    height: 48,
    width: 48,
  },
  addIconStyle: {
    paddingTop: 12,
  },
  scheduledActionErrorMessage: {
    color: 'red',
    fontSize: 12,
    marginTop: 10,
  },
  scheduleActionImageContainer: {
    width: 200,
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const useStyles = makeStyles(styles);
