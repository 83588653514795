import { FC, useEffect, useRef } from 'react';
import { IUser } from '../../interfaces/interfaces';
import { connection } from '../../socket-manager';

type TProps = {
  userInfo: IUser;
};

const UserSubscription: FC<TProps> = ({ userInfo }) => {
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    const sendUserPing = () => {
      if (connection.state === 'Connected') {
        connection.send('userPing', userInfo.id);
      }
    };

    // Initial ping
    sendUserPing();

    // Set up interval
    intervalRef.current = window.setInterval(() => {
      console.log('9 Minutes, resend DeviceId');
      sendUserPing();
    }, 540000);

    // Cleanup function
    return () => {
      console.log('Kill UserPing Interval');
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [userInfo.id]); // Only depend on userInfo.id

  return null;
};

export default UserSubscription;
