import { API } from '../apiconfig';
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

import { IOrganization } from '../interfaces/interfaces';

interface UseOrganizationsResult {
  organizations: IOrganization[];
  isLoading: boolean;
  error: string | null;
  fetchOrganizations: () => Promise<IOrganization[]>;
  refreshOrganizations: () => Promise<IOrganization[]>;
}

export const useOrganizations = (input? : {canFetch : boolean}): UseOrganizationsResult => {
  const canFetch = input?.canFetch ?? true;
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchOrganizations = useCallback((): Promise<IOrganization[]> => {
    if (!canFetch){
      return new Promise((resolve, reject) => {
        resolve(organizations);
      });
    }
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);

      const token = localStorage.getItem('token');
      axios
        .request<IOrganization[]>({
          method: 'GET',
          url: API.REACT_APP_API_ORGANIZATIONS,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
          setOrganizations(data);
          setIsLoading(false);
          resolve(data);
        })
        .catch(error => {
          console.error('Error fetching organizations:', error);
          setError(
            'An error occurred while fetching organizations. Please try again.'
          );
          setIsLoading(false);
          reject(error);
        });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchOrganizations();

    // eslint-disable-next-line
  }, []);

  const refreshOrganizations = useCallback((): Promise<IOrganization[]> => {
    return fetchOrganizations();
  }, [fetchOrganizations]);

  return {
    organizations,
    isLoading,
    error,
    fetchOrganizations,
    refreshOrganizations,
  };
};
