import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { API } from '../apiconfig';
import { IPatientContent } from '../interfaces/interfaces';

export const useFetchPatientContentList = (
  orgId: string | undefined
): {
  patientContentList: IPatientContent[];
  isLoading: boolean;
  error: string | null;
  refetch: () => void;
} => {
  const [patientContentList, setPatientContentList] = useState<
    IPatientContent[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const prevOrgIdRef = useRef<string | undefined>();

  const fetchPatientContentList = useCallback(async () => {
    if (!orgId) return;

    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    const URL = API.REACT_APP_API_PATIENTCONTENT + orgId;
    const headers = {
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache',
    };

    try {
      const response = await axios.get(URL, { headers });

      const patientContentList = response.data.data;

      setPatientContentList(patientContentList);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        console.log('Unauthorized, attempting to refresh token');
        // You might want to implement the checkRefreshToken function
        // and call it here, or handle the unauthorized case differently
        setError('Unauthorized. Please log in again.');
      } else {
        console.error('Error fetching patient content:', err);
        setError('Failed to fetch patient content. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId && orgId !== prevOrgIdRef.current) {
      prevOrgIdRef.current = orgId;
      fetchPatientContentList();
    }
  }, [orgId, fetchPatientContentList]);

  const refetch = useCallback(() => {
    if (orgId) {
      fetchPatientContentList();
    }
  }, [orgId, fetchPatientContentList]);

  return { patientContentList, isLoading, error, refetch };
};
