import { truncate } from 'lodash';
import { useRef, useState, useEffect, FC } from 'react';

type TProps = {
  name: string;
  maxWidth?: number;
  maxFontSize?: number;
  minFontSize?: number;
  truncateLength?: number;
};

const RoomName: FC<TProps> = props => {
  const { name, maxWidth = 200, maxFontSize = 30, minFontSize = 12, truncateLength = 30 } = props;

  const truncatedName = truncate(name, { length: truncateLength, omission: '...' });

  const nameRef = useRef(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const shrinkText = () => {
      if (nameRef.current) {
        let currentSize = maxFontSize;
        nameRef.current.style.fontSize = `${currentSize}px`;

        while (
          nameRef.current.scrollWidth > maxWidth &&
          currentSize > minFontSize
        ) {
          currentSize--;
          nameRef.current.style.fontSize = `${currentSize}px`;
        }

        setFontSize(currentSize);
      }
    };

    shrinkText();
    window.addEventListener('resize', shrinkText);
    return () => window.removeEventListener('resize', shrinkText);
  }, [truncatedName, maxWidth, maxFontSize, minFontSize]);

  return (
    <div
      className="text-[30px] py-1"
      style={{ width: maxWidth, lineHeight: '38px' }}
    >
      <h1
        ref={nameRef}
        className="whitespace-nowrap overflow-hidden"
        style={{ fontSize: `${fontSize}px` }}
      >
        {truncatedName}
      </h1>
    </div>
  );
};

export default RoomName;
