import { FC } from 'react';
import { CheckboxField } from '../../../../../common';
import { useStyles } from '../style';

type TProps = {
  control: any;
  errors: any;
};

const DepartmentFrontDeskInputFieldsSubform: FC<TProps> = props => {
  const { control, errors } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.frontDeskInputsContainer} mb-4`}>
      <div>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          name="showProvider"
          control={control}
          errors={errors}
          label={'Show Provider'}
        />
      </div>

      <div>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          name="showFirstName"
          control={control}
          errors={errors}
          label={'Show First Name'}
        />
      </div>

      <div>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          name="showLastName"
          control={control}
          errors={errors}
          label={'Show Last Name'}
        />
      </div>

      <div>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          name="showMRN"
          control={control}
          errors={errors}
          label={'Show EHR Patient ID'}
        />
      </div>

      <div>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          name="showDOB"
          control={control}
          errors={errors}
          label={'Show Date of Birth'}
        />
      </div>

      <div>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          name="showApptTime"
          control={control}
          errors={errors}
          label={'Show Appointment Time'}
        />
      </div>

      <div>
        <CheckboxField
          classes={{
            root: classes.checkBox,
            checked: classes.checked,
          }}
          name="showApptType"
          control={control}
          errors={errors}
          label={'Show Appointment Type'}
        />
      </div>
    </div>
  );
};

export default DepartmentFrontDeskInputFieldsSubform;
