import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    spinner: {
      color: theme.palette.primary.main,
    },
  })
);

const Loading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fade in={true} style={{ transitionDelay: "200ms" }} unmountOnExit>
        <CircularProgress className={classes.spinner} />
      </Fade>
    </div>
  );
};

export default Loading;
