import React, { FC } from 'react';
import { IDevice } from '../../../../../interfaces/interfaces';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import DeviceControl from '../deviceControl/DeviceControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';

type TProps = {
  device: IDevice;
  onTakeScreenshot: () => void;
  onHome: () => void;
  onBack: () => void;
  onSetDevice: (data: any) => void;
};

const DeviceScreenshot: FC<TProps> = props => {
  const { device, onTakeScreenshot, onHome, onBack, onSetDevice } = props;

  const deviceId = device.deviceId;
  const classes = useStyles();

  return (
    <>
      {device.isAndroidTablet ? (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {device.screenshotTimestamp ? (
              <Typography>
                Screenshot (
                <strong>
                  {Math.abs(
                    moment
                      .utc(device.screenshotTimestamp)
                      .diff(moment(), 'minutes')
                  )}{' '}
                  minutes ago
                </strong>
                )
              </Typography>
            ) : (
              <div />
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.deviceControlContainer}>
            <DeviceControl
              deviceId={deviceId}
              latestImage={device.screenshotUrl}
              onUpdate={onSetDevice}
            />
            <div>
              <Button
                style={{ marginLeft: 5, marginRight: 5 }}
                onClick={() => onTakeScreenshot()}
                variant="outlined"
              >
                Take Screenshot
              </Button>
              <Button
                style={{ marginLeft: 5, marginRight: 5 }}
                onClick={() => onHome()}
                variant="outlined"
              >
                Home
              </Button>
              <Button
                style={{ marginLeft: 5, marginRight: 5 }}
                onClick={() => onBack()}
                variant="outlined"
              >
                Back
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
};

export default DeviceScreenshot;
