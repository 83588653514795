import { FETCH_LOBBYDEPTS, UPDATE_LOBBYDEPTS } from "../actions/types";

const initialState: any = {
  lobbyDepartments: [],
};

export default function lobbyReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case FETCH_LOBBYDEPTS:
      return {
        ...state,
        lobbyDepartments: action.payload,
      };
    case UPDATE_LOBBYDEPTS:
      return {
        ...state,
        lobbyDepartments: state.lobbyDepartments.map((dept: { id: string }) => {
          if (dept.id === action.payload.id) {
            return action.payload;
          }
          return dept;
        }),
      };
    default:
      return state;
  }
}
