import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Fade from "@material-ui/core/Fade";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const styles = (theme) => ({
  snackbar: {
    backgroundColor: "#0096DB",
  },
  icon: {
    fontSize: 22,
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

class InfoSnack extends Component {
  handleClose = (event, reason) => {
    return;
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          onClose={this.handleClose}
          className={classes.snackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.props.successSnack}
          TransitionComponent={Fade}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classes.snackbar}
            message={
              <span id="message-id" className={classes.message}>
                <CheckCircleIcon className={classes.icon} />
                {this.props.successMessage}
              </span>
            }
          />
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(InfoSnack);
