import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { API } from '../apiconfig';

export const useFetchSounds = () => {
  const [sounds, setSounds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSounds = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    const URL = API.REACT_APP_API_GETSOUNDS;
    const headers = {
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache',
    };

    try {
      const response = await axios.get(URL, { headers });
      setSounds(response.data.data);
      return response.data.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        console.log('Unauthorized, attempting to refresh token');
        setError('Unauthorized. Please log in again.');
      } else {
        console.error('Error fetching sounds:', err);
        setError('Failed to fetch sounds. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSounds();
  }, [fetchSounds]); // This effect runs once on mount

  const refetch = useCallback(() => {
    fetchSounds();
  }, [fetchSounds]);

  return { sounds, isLoading, error, refetch };
};
