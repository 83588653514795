import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FC } from 'react';
import { useStyles } from './styles';

type TProps = {
  successMessage: string;
  successSnack: boolean;
  autoHideDuration?: number;
};
const SuccessSnack: FC<TProps> = props => {
  const handleClose = (event: any, reason: any) => {
    return;
  };

  const { successSnack, successMessage, autoHideDuration = 6000 } = props;

  const classes = useStyles();

  return (
    <div>
      <Snackbar
        onClose={handleClose}
        className={classes.snackbar}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successSnack}
        TransitionComponent={Fade}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          className={classes.snackbar}
          message={
            <span id="message-id" className={classes.message}>
              <CheckCircleIcon className={classes.icon} />
              {successMessage}
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};

export default SuccessSnack;
