import { FC, useEffect } from 'react';
import FlowStationUrlGeneratorForm from './FlowStationUrlGeneratorForm';
import { schema, TFormSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { IDevice } from '../../../../../../interfaces/interfaces';
import queryString, { ParsedUrl } from 'query-string';
import { useOrg } from '../../../../../../hooks/useOrg';

type TProps = {
  className?: string;
  device: IDevice;
  orgId: string;
};

const FlowStationUrlGenerator: FC<TProps> = props => {
  const { className = '', device, orgId } = props;

  const { organization, refetch: refectchOrg } = useOrg(orgId);

  const parsedUrl: ParsedUrl & {
    query: {
      groupId?: string;
      flowStationToken?: string;
    };
  } = queryString.parseUrl(device?.fsUrl || '');

  const formMethods = useForm<TFormSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      fsName: device?.fsName || '',
      fsUrl: device?.fsUrl || '',
      flowStationToken: '',
      departmentId: parsedUrl?.query?.groupId,
      organizationId: device?.orgId || '',
    },
  });

  useEffect(() => {
    formMethods.setValue('organizationId', orgId);
  }, [orgId, formMethods]);

  useEffect(() => {
    refectchOrg(orgId);
  }, [orgId, refectchOrg]);

  useEffect(() => {
    if (organization) {
      formMethods.setValue(
        'flowStationToken',
        organization.flowStationToken || ''
      );
    }
  }, [organization, formMethods]);

  return (
    <div
      className={`${className} border border-[#e5e7eb] hover:border-black p-4 rounded-md`}
    >
      <FlowStationUrlGeneratorForm
        formMethods={formMethods}
        orgId={orgId}
        deviceId={device?.deviceId}
      />
    </div>
  );
};

export default FlowStationUrlGenerator;
