import { createTheme, makeStyles } from "@material-ui/core";

const styles: {
  [key: string]: any;
} = (theme: any) => ({
  actionsList: {
    paddingRight: 5,
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: "auto",
    position: "relative",
    float: "right",
  },
  actionIcon: {
    height: 50,
    width: 50,
    cursor: "pointer",
    borderRadius: 0,
  },
  actionResponseSelection: {
    width: 35,
    margin: "0px 5px 0px 5px",
    cursor: "pointer",
  },
  tooltiptext: {
    // display: "flex",
    borderRadius: 4,
    // textAlign: "center",
    color: "white",
    padding: 10,
    // position: "absolute",
    position: "fixed",
    // marginTop: 5,
    backgroundColor: "#696969",
    zIndex: 10,
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    // marginLeft: -10,
    // right: 0,
    direction: "ltr",
  },
  actionDetails: {
    margin: "auto",
    fontSize: 18,
  },
  avatarLarge: {
    // backgroundColor: "#e0e0e0",
    width: 100,
    height: 100,
    marginRight: 10,
  },
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
  disabledCheckBox: {
    color: "#808080",
    "&$checked": {
      color: "#808080",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  disabledChecked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
});


export const useStyles = makeStyles(styles);


export const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      },
    },
  },
});


