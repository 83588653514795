import 'date-fns';
import React, { PureComponent } from 'react';
import axios from 'axios';
import { API } from '../../../apiconfig';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { checkRefreshToken } from '../../../utils/utils';

import PatientTimeline from './PatientTimeline';

import { fetchDistinctOrgActions } from '../../../actions/adminActions';

// @ts-ignore
import { PieChart, Pie, Cell } from 'recharts';

//Material
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BallotIcon from '@material-ui/icons/Ballot';
import Tooltip from '@material-ui/core/Tooltip';

// CSS
import '../css/Flowstation.css';
import {
  IAction,
  IDepartment,
  IOrganization,
  IUser,
  IRoom,
} from '../../../interfaces/interfaces';
import { Checkbox, Typography } from '@material-ui/core';

const _ = require('lodash');

let mockedOrgId: string | null = null;

if (localStorage.getItem('lastMockedOrganizationId')) {
  mockedOrgId = localStorage.getItem('lastMockedOrganizationId');
}

let searchTimer: any = null;

let renderLabel = ({ x, y, name, value, percentage, midAngle }: any) => {
  const RADIAN = Math.PI / 180;
  const cos = Math.cos(-RADIAN * midAngle);

  return (
    <>
      <text
        x={x + (cos >= 0 ? 1 : -1) * 30}
        y={y - 5}
        fill="rgba(0, 0, 0, 0.87)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan
          alignmentBaseline="middle"
          // fontSize="31.5px"
          fontFamily="Roboto"
        >
          {name}
        </tspan>
      </text>
      <text
        x={x + (cos >= 0 ? 1 : -1) * 30}
        y={y + 10}
        fill="rgba(0, 0, 0, 0.54)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="12.3px" fontFamily="Roboto">
          {value} ({percentage}%)
        </tspan>
      </text>
    </>
  );
};

const COLORS = ['#8AD4EB', '#FD625E', '#00B8AA'];

const theme = createTheme({
  //   overrides: {
  //     MuiAvatar: {
  //       img: {
  //         height: "auto",
  //       },
  //     },
  //   },
});

const styles: {} = {
  patientHistoryContainer: {
    margin: '0px 20px 0px 20px',
  },
  tableCell: {
    // padding: "0px 10px 0px 10px",
    padding: 7,
  },
  actionPopover: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: 265,
    padding: 10,
  },
  popoverActions: {
    height: 50,
    width: 50,
    margin: 3,
    padding: 4,
    cursor: 'pointer',
  },
  selectedAction: {
    height: 50,
    width: 50,
    cursor: 'pointer',
    border: '1px dashed #e0e0e0',
  },
  checkBox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

interface PatientHistoryProps {
  loggedInUserData: IUser;
  department: IDepartment;
  classes: any;
  isFrontDeskIntegration: boolean;
  organization: IOrganization;
  distinctOrgActions: any;
  timeZone: any;
  fetchDistinctOrgActions: (orgId: string) => void;
  preSelectedRoom: any;
}

interface PatientHistoryState {
  patientHistoryData: any[];
  rowVisitIdToView: any;
  selectedVisitData: any;
  page: number;
  rowsPerPage: number;
  users: any[];
  isValidData: boolean;
  selectedUser: any;
  selectedUserId: any;
  sortDescending: boolean;
  sortBy: string;
  searchByProvider: boolean;
  ehrPatientId: any;
  mrnNumber: any;
  loading: boolean;
  searchBy: string;
  anchorEl: any;
  selectedAction: any;
  rooms: any[];
  selectedRoom: any;
  selectedRoomId: any;
  showOnlySurveyed: boolean;
  allTime: boolean;
  selectedStartDate: any;
  selectedEndDate: any;
}

class PatientHistory extends PureComponent<
  PatientHistoryProps,
  PatientHistoryState
> {
  constructor(props: PatientHistoryProps) {
    super(props);
    this.state = {
      patientHistoryData: [],
      rowVisitIdToView: null,
      selectedVisitData: null,
      isValidData: false,
      page: 0,
      rowsPerPage: 10,
      users: [],
      selectedUser: '',
      selectedUserId: this.props.loggedInUserData.id,
      sortDescending: true,
      sortBy: 'firstVisitToPatient',
      searchByProvider: true,
      ehrPatientId: null,
      mrnNumber: null,
      loading: false,
      searchBy: this.props.preSelectedRoom ? 'Room' : 'Provider',
      anchorEl: null,
      selectedAction: null,
      rooms: [],
      selectedRoom: '',
      selectedRoomId: '',
      showOnlySurveyed: false,
      allTime: false,
      selectedStartDate: moment().local().format('YYYY-MM-DD'),
      selectedEndDate: moment().local().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    console.log('org', this.props.organization);

    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    let USERURL = API.REACT_APP_API_ORGUSERS + this.props.organization.id;
    if (mockedOrgId) {
      USERURL = API.REACT_APP_API_ORGUSERS + mockedOrgId;
    }

    axios
      .get(USERURL, headers)
      .then(response => {
        // console.log("axios res", response);
        if (response.data.data) {
          // console.log("mounted res users", response.data.data);

          this.setState({
            users: response.data.data,
          });
        }
      })
      .catch(err => {
        console.log('Error fetching users for patient history: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    // console.log("check here", this.props.preSelectedRoom);
    let RoomURL = null;
    if (this.props.preSelectedRoom) {
      console.log('pre selected room', this.props.preSelectedRoom.orgId);
      RoomURL =
        API.REACT_APP_API_GETROOMSBYORGID + this.props.preSelectedRoom.orgId;
    } else {
      console.log('rooms by org prop', this.props.organization.id);
      RoomURL = API.REACT_APP_API_GETROOMSBYORGID + this.props.organization.id;
      if (mockedOrgId) {
        RoomURL = API.REACT_APP_API_GETROOMSBYORGID + mockedOrgId;
      }
    }

    axios
      .get(RoomURL, headers)
      .then(response => {
        if (response.data.data) {
          // console.log("mounted res rooms", response.data.data);

          let rooms = response.data.data;
          let preSelectedRoom: any = null;

          if (this.props.preSelectedRoom) {
            // Assign preselected room
            rooms.forEach((room: any) => {
              if (room.id === this.props.preSelectedRoom?.id) {
                preSelectedRoom = room;
              }
            });
          }

          this.setState(
            {
              rooms: rooms,
              selectedRoom: preSelectedRoom ? preSelectedRoom : '',
              selectedRoomId: preSelectedRoom ? preSelectedRoom.id : '',
            },
            () => {
              // Fetch preSelected room data
              if (preSelectedRoom) {
                this.selectRoom(preSelectedRoom);
              }
            }
          );
        }
      })
      .catch(err => {
        console.log('Error fetching rooms for patient history: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    // Fetch initial today
    let URL =
      API.REACT_APP_API_PATIENTVISIT +
      this.props.loggedInUserData.id +
      '/' +
      moment().local().format('YYYY-MM-DD') +
      '/' +
      this.props.organization.id;
    if (mockedOrgId) {
      URL =
        API.REACT_APP_API_PATIENTVISIT +
        this.props.loggedInUserData.id +
        '/' +
        moment().local().format('YYYY-MM-DD') +
        '/' +
        mockedOrgId;
    }

    this.setState({
      loading: true,
    });

    axios
      .get(URL, headers)
      .then(response => {
        if (response.data.data) {
          this.setState({
            patientHistoryData: response.data.data,
            loading: false,
          });
        }
      })
      .catch(err => {
        console.log('Error fetching inital today: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  }

  showTable = () => {
    this.setState({isValidData: true});
  }

  hideTable = () => {
    this.setState({isValidData: false});
  }

  searchByMrnNumber = () => (event: any) => {
    console.log('mrnNumber', event.target.value);
    this.setState({
      mrnNumber: event.target.value,
    });

    let mrnNumber = event.target.value;
    clearTimeout(searchTimer);
    searchTimer = null;

    let searchVisit = () => {
      console.log('Search MRN');
      let URL: string =
        API.REACT_APP_API_EHRHISTORY +
        'orgId=' +
        this.props.organization.id +
        '/mrn=' +
        mrnNumber;
      console.log('testUrl', URL);

      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      if (mrnNumber) {
        this.setState({
          loading: true,
          page: 0,
        });

        this.showTable();

        axios
          .get(URL, headers)
          .then(response => {
            console.log('MRN ehr history', response);
            if (response.data.data) {
              this.setState({
                patientHistoryData: response.data.data,
                loading: false,
              });
            }
          })
          .catch(err => {
            console.log('Error fetching MRN ehr history', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      } else {
        this.setState({
          patientHistoryData: [],
        });
      }
    };
    if (searchTimer === null) {
      searchTimer = setTimeout(function () {
        searchVisit();
      }, 1000);
    }
  };

  searchByPatientId = () => (event: any) => {
    this.setState({
      ehrPatientId: event.target.value,
      patientHistoryData: [],
    });

    let ehrPatientId = event.target.value;

    clearTimeout(searchTimer);
    searchTimer = null;

    let searchVisit = () => {
      console.log('SEARCH VISIT');

      let URL =
        API.REACT_APP_API_PATIENTVISIT +
        'orgId=' +
        this.props.organization.id +
        '/mrn=' +
        ehrPatientId;
      if (mockedOrgId) {
        URL =
          API.REACT_APP_API_PATIENTVISIT +
          'orgId=' +
          mockedOrgId +
          '/mrn=' +
          ehrPatientId;
      }

      console.log('URL', URL);
      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      if (ehrPatientId) {
        this.setState({
          loading: true,
          page: 0,
        });

        this.showTable();
        axios
          .get(URL, headers)
          .then(response => {
            console.log('axios res', response);
            if (response.data.data) {
              this.setState({
                patientHistoryData: response.data.data,
                loading: false,
              });
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
            });
            console.log('Error searching by Patient ID: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      } else {
        this.setState({
          patientHistoryData: [],
        });
      }
    };

    if (searchTimer === null) {
      searchTimer = setTimeout(function () {
        searchVisit();
      }, 1000);
    }
  };

  searchByProvider = () => {
    if (this.state.searchBy !== 'Provider') {
      this.hideTable();
      this.setState({
        searchBy: 'Provider',
        selectedAction: null,
        patientHistoryData: [],
      });
    }
  };

  searchByRoom = () => {
    this.hideTable();
    this.setState({
      searchBy: 'Room',
      patientHistoryData: [],
    });
  };

  searchBySurveys = () => {
    this.hideTable();
    this.setState({
      searchBy: 'Surveys',
      patientHistoryData: [],
    });
  };

  searchByMRN = () => {
    this.hideTable();
    this.setState({
      searchBy: 'MRN',
      patientHistoryData: [],
    });
  };

  searchByPatient = () => {
    this.hideTable();
    this.setState({
      searchBy: 'Patient',
      patientHistoryData: [],
    });
  
  };

  searchByAction = () => {
    if (this.state.searchBy !== 'Action' && this.props.organization.id) {
      if (mockedOrgId) {
        this.props.fetchDistinctOrgActions(mockedOrgId);
      } else {
        this.props.fetchDistinctOrgActions(this.props.organization.id);
      }

      this.hideTable();
      this.setState({
        searchBy: 'Action',
        patientHistoryData: [],
      });
    }
  };

  handleCheckBoxChange = () => (event: any) => {
    this.handleDateChange();

    console.log('before', this.state.allTime);

    this.setState({ allTime: !this.state.allTime }, () => {
      console.log('after', this.state.allTime);
      let event = { target: { value: 'ALLTIME' } }; // Create a new event object

      if (this.state.allTime) {
        this.setState({
          selectedStartDate: 'ALLTIME',
          selectedEndDate: 'ALLTIME',
        });
        // hide container for startDate and endDate textFields
        let startDate = document.getElementById('date');
        if (startDate) {
          if (startDate.parentElement) {
            startDate.parentElement.style.display = 'none';
            if (startDate.parentElement.parentElement) {
              startDate.parentElement.parentElement.style.display = 'none';
            }
          }
        }
        event.target.value = 'ALLTIME';
      } else {
        let currentDate = moment().local().format('YYYY-MM-DD');
        this.setState({
          selectedStartDate: currentDate,
          selectedEndDate: currentDate,
        });

        // show container for startDate and endDate textFields
        let startDate = document.getElementById('date');
        if (startDate) {
          if (startDate.parentElement) {
            startDate.parentElement.style.display = 'block';
            if (startDate.parentElement.parentElement) {
              startDate.parentElement.parentElement.style.display = 'flex';
            }
          }
        }

        event.target.value = currentDate;
      }
      const dateChangeHandler = this.handleDateChange();
      dateChangeHandler(event);
    });
  };

  handleDateChange = () => (event: any) => {
    console.log('event', event.target.value);
    let URL: any;
    if (this.state.searchBy === 'Provider' && this.state.selectedUser) {
      URL =
        API.REACT_APP_API_PATIENTVISIT +
        this.state.selectedUserId +
        '/' +
        event.target.value +
        '/' +
        this.props.organization.id;
      if (mockedOrgId) {
        URL =
          API.REACT_APP_API_PATIENTVISIT +
          this.state.selectedUserId +
          '/' +
          event.target.value +
          '/' +
          mockedOrgId;
      }
    }

    if (this.state.searchBy === 'Action' && this.state.selectedAction) {
      URL =
        API.REACT_APP_API_ACTIONHISTORY +
        this.state.selectedAction.id +
        '/' +
        event.target.value +
        '/' +
        this.props.organization.id;
      if (mockedOrgId) {
        URL =
          API.REACT_APP_API_ACTIONHISTORY +
          this.state.selectedAction.id +
          '/' +
          event.target.value +
          '/' +
          mockedOrgId;
      }
      // console.log("Date change for ACTION", URL);
    }

    if (this.state.searchBy === 'Room' && this.state.selectedRoomId) {
      console.log('ROOM', this.state.selectedRoomId);
      console.log('EVENT', event);
      URL =
        API.REACT_APP_API_PATIENTVISIT +
        'room/' +
        this.props.organization.id +
        '/' +
        this.state.selectedRoomId +
        '/' +
        event.target.value;
      console.log(URL);
      if (mockedOrgId) {
        URL =
          API.REACT_APP_API_PATIENTVISIT +
          'room/' +
          mockedOrgId +
          '/' +
          this.state.selectedRoomId +
          '/' +
          event.target.value;
      }
    }

    if (URL) {
      console.log('Fetching data for date change', URL);
      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      let selectedStartDate = event.target.value;

      // console.log("selectedDate", selectedDate.split("-")[0]);
      if (
        selectedStartDate === 'ALLTIME' ||
        (selectedStartDate.split('-')[0] > 2000 && selectedStartDate.split('-')[0] < 2200)
      ) {
        this.setState({
          loading: true,
          page: 0,
        });
        this.showTable();

        axios
          .get(URL, headers)
          .then(response => {
            // console.log("axios res", response);
            if (response.data.data) {
              // console.log("dataChange res", response.data.data);

              this.setState({
                selectedStartDate: selectedStartDate,
                patientHistoryData: response.data.data,
                loading: false,
              });
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
            });
            console.log('Error searching from date change: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    } else {
      console.log('Dont fetch');
      this.setState({
        patientHistoryData: [],
      });
    }
  };

  openRow = (visitId: any) => {
    let selectedVisit = visitId;

    console.log('Opening visit', visitId);

    if (visitId === this.state.rowVisitIdToView) {
      this.setState({
        rowVisitIdToView: null,
      });
    } else {
      let URL =
        API.REACT_APP_API_PATIENTVISIT +
        visitId +
        '/' +
        this.props.organization.id;
      if (mockedOrgId) {
        URL = API.REACT_APP_API_PATIENTVISIT + visitId + '/' + mockedOrgId;
      }
      // let URL = API.REACT_APP_API_PATIENTVISIT + visitId + "/" + "5f43f0a7e0a5d550acd74ae6";

      // console.log("openRow URL", URL);

      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      this.showTable();
      axios
        .get(URL, headers)
        .then(response => {
          // console.log("axios res", response);
          if (response.data.data) {
            console.log('open row res', response.data.data);
            // console.log("from", URL);

            this.setState({
              rowVisitIdToView: selectedVisit,
              selectedVisitData: response.data.data,
            });
          }
        })
        .catch(err => {
          console.log('Error when opening row: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  handleChangePage = (event: any, newPage: number) => {
    console.log('e', event);
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    // console.log("handleChangeRowsPerPage", event.target.value);
    this.setState({
      rowsPerPage: event.target.value,
      page: 0,
    });
  };

  selectUser = (user: IUser) => {
    if (user) {
      // console.log("selected user", user);
      this.setState({
        selectedUser: user,
        selectedUserId: user.id,
      });

      let URL =
        API.REACT_APP_API_PATIENTVISIT +
        user.id +
        '/' +
        this.state.selectedStartDate +
        '/' +
        this.props.organization.id;
      if (mockedOrgId) {
        URL =
          API.REACT_APP_API_PATIENTVISIT +
          user.id +
          '/' +
          this.state.selectedStartDate +
          '/' +
          mockedOrgId;
      }

      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      this.setState({
        loading: true,
        page: 0,
      });

      this.showTable();
      axios
        .get(URL, headers)
        .then(response => {
          if (response.data.data) {
            this.setState({
              patientHistoryData: response.data.data,
              loading: false,
            });
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
          });
          console.log('Error selecting user: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  selectRoom = (room: IRoom) => {
    // https://cesiumapi-dev.azurewebsites.net/api/v3/patienthistory/room/{orgId}/{roomId}/{dateTime}

    // console.log("Selected Room", room);
    if (room) {
      this.setState({
        selectedRoom: room,
        selectedRoomId: room.id,
      });

      let URL =
        API.REACT_APP_API_PATIENTVISIT +
        'room/' +
        this.props.organization.id +
        '/' +
        room.id +
        '/' +
        this.state.selectedStartDate;
      if (mockedOrgId) {
        URL =
          API.REACT_APP_API_PATIENTVISIT +
          'room/' +
          mockedOrgId +
          '/' +
          room.id +
          '/' +
          this.state.selectedStartDate;
      }
      let token = localStorage.getItem('token');
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      this.setState({
        loading: true,
        page: 0,
      });

      this.showTable();
      axios
        .get(URL, headers)
        .then(response => {
          console.log('axios res', response);
          if (response.data.data) {
            console.log('selected room res', response.data.data);

            this.setState({
              patientHistoryData: response.data.data,
              loading: false,
            });
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
          });
          console.log('Error selecting room: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  sortBy = (sortValue: string) => {
    // console.log("sort by", sortValue);
    if (sortValue === this.state.sortBy) {
      this.setState({
        sortDescending: !this.state.sortDescending,
      });
    } else {
      this.setState({
        sortBy: sortValue,
        sortDescending: false,
      });
    }
  };

  openActionPopper = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  selectAction = (action: IAction) => {
    let URL: any;
    if (this.state.searchBy === 'Action') {
      // console.log("organization", this.props.organization);
      URL =
        API.REACT_APP_API_ACTIONHISTORY +
        action.id +
        '/' +
        this.state.selectedStartDate +
        '/' +
        this.props.organization.id;
      if (mockedOrgId) {
        URL =
          API.REACT_APP_API_ACTIONHISTORY +
          action.id +
          '/' +
          this.state.selectedStartDate +
          '/' +
          mockedOrgId;
      }
      console.log('Date change for ACTION', URL);
    }

    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    this.setState({
      loading: true,
      page: 0,
    });
    this.showTable();

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("axios res", response);
        if (response.data.data) {
          // console.log("dataChange res", response.data.data);

          this.setState({
            patientHistoryData: response.data.data,
            loading: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        console.log('Error selecting action: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.setState({
      selectedAction: action,
      anchorEl: null,
    });
  };

  searchSurveys = () => {
    let URL =
      API.REACT_APP_API_SURVEYRESPONSE +
      '/' +
      this.state.selectedStartDate +
      '/' +
      this.state.selectedEndDate +
      '/' +
      this.props.organization.id;
    if (mockedOrgId) {
      URL =
        API.REACT_APP_API_SURVEYRESPONSE +
        '/' +
        this.state.selectedStartDate +
        '/' +
        this.state.selectedEndDate +
        '/' +
        mockedOrgId;
    }

    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    this.setState({
      loading: true,
      page: 0,
    });

    this.showTable();
    axios
      .get(URL, headers)
      .then(response => {
        if (response.data.data) {
          // console.log("org Surveys", response.data.data);
          this.setState({
            patientHistoryData: response.data.data,
            loading: false,
          });
        }
      })
      .catch(err => {
        console.log('Error fetching org surveys: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  handleSurveyStartDateChange = () => (event: any) => {
    // /2023-01-1/2023-01-20/5f43f0a7e0a5d550acd74b01
    // console.log("URL", URL)
    this.setState(
      {
        selectedStartDate: event.target.value,
      },
      () => {
        this.searchSurveys();
      }
    );
  };

  handleSurveyEndDateChange = () => (event: any) => {
    this.setState(
      {
        selectedEndDate: event.target.value,
      },
      () => {
        this.searchSurveys();
      }
    );
  };

  render() {
    const { classes } = this.props;

    // console.log("tick");

    // console.log("STATE", this.state);

    let timeZone: string;

    switch (this.props.organization.timeZoneId) {
      case 3:
        timeZone = 'Pacific/Honolulu';
        break;
      case 4:
        timeZone = 'America/Anchorage';
        break;
      case 6:
        timeZone = 'America/Los_Angeles';
        break;
      case 7:
        timeZone = 'America/Phoenix';
        break;
      case 9:
        timeZone = 'America/Denver';
        break;
      case 10:
        timeZone = 'America/Chicago';
        break;
      case 15:
        timeZone = 'America/New_York';
        break;
      case 16:
        timeZone = 'America/Puerto_Rico';
        break;
      default:
        timeZone = 'America/Denver';
    }

    let pieChartData: any = null;
    let filteredData: any[] = [];

    let historicalRoomUsers = {};

    if (this.state.selectedVisitData) {
      let d = this.state.selectedVisitData;
      let totalTime = d.providerMinutes + d.aloneMinutes + d.userMinutes;

      pieChartData = [
        {
          name: 'Provider',
          value: this.state.selectedVisitData.providerMinutes,
          percentage: Math.round((d.providerMinutes / totalTime) * 100),
        },
        {
          name: 'Alone',
          value: this.state.selectedVisitData.aloneMinutes,
          percentage: Math.round((d.aloneMinutes / totalTime) * 100),
        },
        {
          name: 'Staff',
          value: this.state.selectedVisitData.userMinutes,
          percentage: Math.round((d.userMinutes / totalTime) * 100),
        },
      ];

      historicalRoomUsers = _.groupBy(
        this.state.selectedVisitData.historicalRoomUsers,
        'fullName'
      );
    }

    // console.log("Historical room users", historicalRoomUsers);

    let filter = this.state.sortBy;
    if (this.state.patientHistoryData?.length > 0) {
      filteredData = this.state.patientHistoryData?.sort((a, b) => {
        if (this.state.sortDescending) {
          // DESCENDING
          if (a[filter] > b[filter]) {
            return -1;
          }
          if (a[filter] < b[filter]) {
            return 1;
          }
          return 0;
        } else {
          // ASCENDING
          if (a[filter] < b[filter]) {
            return -1;
          }
          if (a[filter] > b[filter]) {
            return 1;
          }
          return 0;
        }
      });
    }

    let totalTimeSeries: any[] = [];
    let combinedTimeSeriesArrayData: any[] = [];
    if (this.state.selectedVisitData) {
      totalTimeSeries.push([
        {
          fullName: 'Patient',
          localStartDateTime: this.state.selectedVisitData.localRoomStartTime,
          localEndDateTime: this.state.selectedVisitData.localRoomEndTime,
        },
      ]);
      for (var key in historicalRoomUsers) {
        if (historicalRoomUsers.hasOwnProperty(key)) {
          // @ts-ignore
          totalTimeSeries.push(historicalRoomUsers[key]);
          // console.log(key + " -> " + historicalRoomUsers[key]);
        }
      }

      combinedTimeSeriesArrayData = [].concat.apply([], totalTimeSeries);
    }

    // console.log("Filterd data", filteredData);
    // filter filteredData if filteredData.surveyResponses array is not empty
    if (this.state.showOnlySurveyed) {
      filteredData = filteredData.filter(item => {
        return item.surveyResponses.length > 0;
      });
    }

    // console.log("PROPS!", this.props);

    let alphabetizedRooms = _.sortBy(this.state.rooms, 'siteDeptName');
    // alphabetize room by siteDeptName + roomName
    alphabetizedRooms = alphabetizedRooms.sort((a: any, b: any) => {
      if (
        a.siteDeptName + ' ' + a.roomName <
        b.siteDeptName + ' ' + b.roomName
      ) {
        return -1;
      }
      if (
        a.siteDeptName + ' ' + a.roomName >
        b.siteDeptName + ' ' + b.roomName
      ) {
        return 1;
      }
      return 0;
    });

    console.log('filtered data', filteredData);

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root} style={{ paddingTop: 20 }}>
            {/* Hide option to search by MRN if no departments/sibling departments has front desk integration */}
            <div style={{ margin: '0px 20px 0px 20px' }}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: 15 }}
                  component="legend"
                >
                  Search By
                </FormLabel>
                <RadioGroup row>
                  <FormControlLabel
                    style={{ height: 15, marginBottom: 15 }}
                    control={
                      <Radio
                        color="primary"
                        checked={this.state.searchBy === 'Provider'}
                        onChange={() => this.searchByProvider()}
                      />
                    }
                    label="Staff/Provider"
                  />
                  <FormControlLabel
                    style={{ height: 15, marginBottom: 15 }}
                    control={
                      <Radio
                        color="primary"
                        checked={this.state.searchBy === 'Action'}
                        onChange={() => this.searchByAction()}
                      />
                    }
                    label="Action"
                  />
                  {this.props.isFrontDeskIntegration ? (
                    <FormControlLabel
                      style={{ height: 15, marginBottom: 15 }}
                      control={
                        <Radio
                          color="primary"
                          checked={this.state.searchBy === 'Patient'}
                          onChange={() => this.searchByPatient()}
                        />
                      }
                      label="EHR Patient ID"
                    />
                  ) : null}

                  {this.props.isFrontDeskIntegration ? (
                    <FormControlLabel
                      style={{ height: 15, marginBottom: 15 }}
                      control={
                        <Radio
                          color="primary"
                          checked={this.state.searchBy === 'MRN'}
                          onChange={() => this.searchByMRN()}
                        />
                      }
                      label="EHR Message History"
                    />
                  ) : null}

                  <FormControlLabel
                    style={{ height: 15, marginBottom: 15 }}
                    control={
                      <Radio
                        color="primary"
                        checked={this.state.searchBy === 'Room'}
                        onChange={() => this.searchByRoom()}
                      />
                    }
                    label="Room (Includes Visits in Progress)"
                  />

                  <FormControlLabel
                    style={{ height: 15, marginBottom: 15 }}
                    control={
                      <Radio
                        color="primary"
                        checked={this.state.searchBy === 'Surveys'}
                        onChange={() => this.searchBySurveys()}
                      />
                    }
                    label="View Organization Surveys"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {/* <Checkbox
              // classes={{
              //   root: classes.checkBox,
              //   checked: classes.checked,
              // }}
              style={{ marginLeft: 10 }}
              color="primary"
              checked={true}
            /> */}

            {this.state.searchBy !== 'Surveys' && (
              <div>
                <Grid container>
                  <FormControlLabel
                    style={{ marginLeft: 10, marginTop: 10, width: '40%' }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.showOnlySurveyed}
                      />
                    }
                    onChange={() =>
                      this.setState({
                        showOnlySurveyed: !this.state.showOnlySurveyed,
                      })
                    }
                    label="Only Visits with Survey Responses"
                  />

                  {this.state.searchBy === 'Patient' ||
                  this.state.searchBy === 'MRN' ? null : (
                    <FormControlLabel
                      style={{ marginLeft: 10, marginTop: 10, width: '40%' }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.state.allTime}
                        />
                      }
                      onChange={this.handleCheckBoxChange()}
                      label="All Time Visits"
                    />
                  )}
                </Grid>
              </div>
            )}

            <Grid container>
              {this.state.searchBy === 'Surveys' && (
                <>
                  <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                    <TextField
                      style={{ width: '90%' }}
                      id="date"
                      label="Start Date"
                      type="date"
                      defaultValue={this.state.selectedStartDate}
                      onChange={this.handleSurveyStartDateChange()}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                    <TextField
                      style={{ width: '90%' }}
                      id="date"
                      label="End Date"
                      type="date"
                      defaultValue={this.state.selectedEndDate}
                      onChange={this.handleSurveyEndDateChange()}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                style={{ textAlign: 'center', margin: 'auto' }}
              >
                {this.state.searchBy === 'Provider' && (
                  <Autocomplete
                    style={{ marginLeft: 20, marginRight: 20 }}
                    id="combo-box"
                    autoHighlight
                    options={this.state.users}
                    getOptionLabel={provider => provider.fullName}
                    onChange={(event, provider) => {
                      this.selectUser(provider);
                    }}
                    // value={this.state.selectedRoom}
                    renderInput={params => (
                      <TextField {...params} label="Staff/Provider" />
                    )}
                  />
                )}

                {this.state.searchBy === 'Room' && (
                  <Autocomplete
                    style={{ marginLeft: 20, marginRight: 20 }}
                    value={
                      this.state.selectedRoom ? this.state.selectedRoom : null
                    }
                    id="combo-box"
                    autoHighlight
                    // options={this.state.rooms}
                    options={alphabetizedRooms}
                    getOptionLabel={room => {
                      // console.log("ROOM", room);
                      return room.siteDeptName + ' ' + room.roomName;
                    }}
                    onChange={(event, room) => {
                      this.selectRoom(room);
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Room" />
                    )}
                  />
                )}

                {this.state.searchBy === 'Action' && (
                  <div>
                    {!this.state.selectedAction ? (
                      <div>
                        <Button
                          onClick={this.openActionPopper}
                          color="primary"
                          variant="contained"
                        >
                          Select Action
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <img
                          onClick={this.openActionPopper}
                          alt=""
                          className={classes.selectedAction}
                          src={this.state.selectedAction.actionImageUrl}
                        />
                      </div>
                    )}
                    <Popover
                      id="action-popper"
                      open={Boolean(this.state.anchorEl)}
                      anchorEl={this.state.anchorEl}
                      onClose={() => this.setState({ anchorEl: null })}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className={classes.actionPopover}>
                        {this.props.distinctOrgActions.map(
                          (action: IAction) => (
                            <div key={action.id}>
                              <img
                                onClick={() => this.selectAction(action)}
                                className={classes.popoverActions}
                                alt=""
                                src={action.actionImageUrl}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </Popover>
                  </div>
                )}

                {this.state.searchBy === 'Patient' && (
                  <TextField
                    style={{ width: '90%' }}
                    id="standard-number"
                    label="EHR Patient ID"
                    // type="number"
                    onChange={this.searchByPatientId()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}

                {this.state.searchBy === 'MRN' && (
                  <TextField
                    style={{ width: '90%' }}
                    id="standard-number"
                    label="EHR Patient ID"
                    // type="number"
                    onChange={this.searchByMrnNumber()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </Grid>

              {(this.state.searchBy === 'Provider' ||
                this.state.searchBy === 'Action' ||
                this.state.searchBy === 'Room') && (
                  <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                    <TextField
                      style={{ width: '90%' }}
                      id="date"
                      label="Date"
                      type="date"
                      defaultValue={this.state.selectedStartDate}
                      onChange={this.handleDateChange()}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
            </Grid>
          </div>
          {this.state.isValidData && (
          <div className={classes.patientHistoryContainer} id="testreport">
            <div
              style={{
                border: '1px solid lightgray',
                borderRadius: 5,
                marginTop: 10,
              }}
            >
              {this.state.searchBy === 'MRN' ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Appt Status Log Note</TableCell>
                        <TableCell style={{ cursor: 'pointer' }} align="center">
                          Appt Time
                        </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} align="center">
                          Timestamp
                        </TableCell>
                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrAppointmentId')}
                        >
                          {this.state.sortBy === 'ehrAppointmentId' ? (
                            <div>
                              EHR Appt ID{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Appt ID</div>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrAppointmentStatus')}
                        >
                          {this.state.sortBy === 'ehrAppointmentStatus' ? (
                            <div>
                              EHR Appt Status{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Appt Status</div>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrAppointmentTypeDesc')}
                        >
                          {this.state.sortBy === 'ehrAppointmentTypeDesc' ? (
                            <div>
                              EHR Appt Desc{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Appt Desc</div>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrDeptId')}
                        >
                          {this.state.sortBy === 'ehrDeptId' ? (
                            <div>
                              EHR Dept Id{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Dept Id</div>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrDeptNameHint')}
                        >
                          {this.state.sortBy === 'ehrDeptNameHint' ? (
                            <div>
                              EHR Dept Name Hint{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Dept Name Hint</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrProviderId')}
                        >
                          {this.state.sortBy === 'ehrDeptNameHint' ? (
                            <div>
                              EHR Provider Id{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Provider Id</div>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrProviderNameHint')}
                        >
                          {this.state.sortBy === 'ehrDeptNameHint' ? (
                            <div>
                              EHR Provider Name Hint{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Provider Name Hint</div>
                          )}
                        </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} align="center">
                          Custom EHR Data
                        </TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.loading ? (
                        <TableRow>
                          <TableCell
                            style={{ padding: 10, textAlign: 'center' }}
                            colSpan={12}
                          >
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {filteredData.length === 0 ? (
                            <TableRow>
                              <TableCell
                                style={{ padding: 10, textAlign: 'center' }}
                                colSpan={12}
                              >
                                No visits found for the given inputs.
                              </TableCell>
                            </TableRow>
                          ) : null}
                          {filteredData
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            )
                            .map((message, i) => (
                              <React.Fragment key={i}>
                                <TableRow>
                                  <TableCell style={{ cursor: 'default' }}>
                                    {message.appointmentStatusLogNote
                                      ? message.appointmentStatusLogNote
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {moment(message.apptTime).format(
                                      'MM/DD/YYYY LT'
                                    ) !== 'Invalid date'
                                      ? moment(message.apptTime).format(
                                          'MM/DD/YYYY LT'
                                        )
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {moment(message.timeStamp)
                                      .tz(timeZone)
                                      .format('MM/DD/YYYY LT') !==
                                    'Invalid date'
                                      ? moment(message.timeStamp)
                                          .tz(timeZone)
                                          .format('MM/DD/YYYY LT')
                                      : 'NULL'}{' '}
                                    {timeZone}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.ehrAppointmentId
                                      ? message.ehrAppointmentId
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.ehrAppointmentStatus
                                      ? message.ehrAppointmentStatus
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.ehrAppointmentTypeDesc
                                      ? message.ehrAppointmentTypeDesc
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.ehrDeptId
                                      ? message.ehrDeptId
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.ehrDeptNameHint
                                      ? message.ehrDeptNameHint
                                      : 'NULL'}
                                  </TableCell>

                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.ehrProviderId
                                      ? message.ehrProviderId
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.ehrProviderNameHint
                                      ? message.ehrProviderNameHint
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {message.customEhrData
                                      ? JSON.stringify(message.customEhrData)
                                      : 'NULL'}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Survey</TableCell>
                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrAppointmentId')}
                        >
                          {this.state.sortBy === 'ehrAppointmentId' ? (
                            <div>
                              EHR Appointment ID{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Appointment ID</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('ehrPatientId')}
                        >
                          {this.state.sortBy === 'ehrPatientId' ? (
                            <div>
                              EHR Patient ID{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>EHR Patient ID</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('assignedProviderName')}
                        >
                          {this.state.sortBy === 'assignedProviderName' ? (
                            <div>
                              Assigned Provider{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>Assigned Provider</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('apptType')}
                        >
                          {this.state.sortBy === 'apptType' ? (
                            <div>
                              Appointment Type{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>Appointment Type</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('apptTime')}
                        >
                          {this.state.sortBy === 'apptTime' ? (
                            <div>
                              Appointment Time{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>Appointment Time</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('patientArrivalTime')}
                        >
                          {this.state.sortBy === 'patientArrivalTime' ? (
                            <div>
                              Arrival Time{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>Arrival Time</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('roomName')}
                        >
                          {this.state.sortBy === 'roomName' ? (
                            <div>
                              Room Name
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>Room Name</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('localRoomStartTime')}
                        >
                          {this.state.sortBy === 'localRoomStartTime' ? (
                            <div>
                              Room Start Time{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>Room Start Time</div>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: 'pointer' }}
                          align="center"
                          onClick={() => this.sortBy('localRoomEndTime')}
                        >
                          {this.state.sortBy === 'localRoomEndTime' ? (
                            <div>
                              Room End Time{' '}
                              {this.state.sortDescending ? <>▼</> : <>▲</>}
                            </div>
                          ) : (
                            <div>Room End Time</div>
                          )}
                        </TableCell>

                        {/*  */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.loading ? (
                        <TableRow>
                          <TableCell
                            style={{ padding: 10, textAlign: 'center' }}
                            colSpan={10}
                          >
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {filteredData.length === 0 ? (
                            <TableRow>
                              <TableCell
                                style={{ padding: 10, textAlign: 'center' }}
                                colSpan={10}
                              >
                                No visits found for the given inputs.
                              </TableCell>
                            </TableRow>
                          ) : null}
                          {filteredData
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            )
                            .map((patient, i) => (
                              <React.Fragment key={i}>
                                <TableRow
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => this.openRow(patient.visitId)}
                                >
                                  <TableCell style={{ cursor: 'default' }}>
                                    {patient?.surveyResponses?.length > 0 ? (
                                      // @ts-ignore
                                      <Tooltip
                                        placement="right"
                                        title={
                                          <div
                                            style={{
                                              whiteSpace: 'normal',
                                              textAlign: 'left',
                                              fontSize: 12,
                                              margin: 10,
                                              wordWrap: 'break-word',
                                            }}
                                          >
                                            {patient?.surveyResponses?.map(
                                              (response: any, index: any) => (
                                                <div key={index}>
                                                  <div
                                                    style={
                                                      index !== 0
                                                        ? { marginTop: 10 }
                                                        : {}
                                                    }
                                                  >
                                                    <strong>
                                                      {
                                                        response.surveyQuestionName
                                                      }
                                                    </strong>
                                                    <div>
                                                      -{' '}
                                                      {
                                                        response.surveyQuestionResponse
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        }
                                      >
                                        <BallotIcon />
                                      </Tooltip>
                                    ) : null}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {patient.ehrAppointmentId
                                      ? patient.ehrAppointmentId
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {patient.ehrPatientId
                                      ? patient.ehrPatientId
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {patient.assignedProviderName
                                      ? patient.assignedProviderName
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {patient.apptType
                                      ? patient.apptType
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {moment(patient.apptTime).format(
                                      'MM/DD/YYYY LT'
                                    ) !== 'Invalid date'
                                      ? moment(patient.apptTime).format(
                                          'MM/DD/YYYY LT'
                                        )
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {moment(patient.patientArrivalTime).format(
                                      'MM/DD/YYYY LT'
                                    ) !== 'Invalid date'
                                      ? moment(
                                          patient.patientArrivalTime
                                        ).format('MM/DD/YYYY LT')
                                      : 'NULL'}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {patient.roomName}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {moment(patient.localRoomStartTime).format(
                                      'MM/DD/YYYY LT'
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {moment(patient.localRoomEndTime).format(
                                      'MM/DD/YYYY LT'
                                    )}
                                  </TableCell>
                                  {/*  */}

                                  {/* ============ */}
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {patient.visitId ===
                                    this.state.rowVisitIdToView
                                      ? '-'
                                      : '+'}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                      borderBottom: 0,
                                    }}
                                    colSpan={10}
                                  >
                                    <Collapse
                                      in={
                                        patient.visitId ===
                                        this.state.rowVisitIdToView
                                      }
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box margin={1}>
                                        {this.state.selectedVisitData ? (
                                          <Table
                                            size="small"
                                            aria-label="purchases"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell align="left">
                                                  Name
                                                </TableCell>
                                                <TableCell align="center">
                                                  Minutes
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {this.state.selectedVisitData.minutesInRoomByUser.map(
                                                (user: IUser, index: any) => (
                                                  <TableRow key={index}>
                                                    <TableCell align="left">
                                                      {user.fullName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {Math.round(
                                                        user.minutesInRoom
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        ) : null}
                                        <div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              flexWrap: 'wrap',
                                              marginBottom: 10,
                                            }}
                                          >
                                            {this.state.selectedVisitData
                                              ?.historicalPatientVisitUpdates
                                              ?.length > 0 ? (
                                              <div
                                                style={{
                                                  fontSize: 14,
                                                  marginTop: 10,
                                                  marginRight: 10,
                                                }}
                                              >
                                                Historical Patient Visit Updates
                                                <div
                                                  style={{
                                                    height: 200,
                                                    maxWidth: 500,
                                                    border:
                                                      '1px solid lightgray',
                                                    borderRadius: 5,
                                                    overflowY: 'scroll',
                                                    padding: 10,
                                                  }}
                                                >
                                                  {console.log(
                                                    'CHECK',
                                                    this.state.selectedVisitData
                                                      .historicalPatientVisitUpdates
                                                  )}
                                                  {this.state.selectedVisitData.historicalPatientVisitUpdates.map(
                                                    (
                                                      update: any,
                                                      index: any
                                                    ) => (
                                                      <div key={index}>
                                                        <div
                                                          style={
                                                            index !== 0
                                                              ? {
                                                                  marginTop: 10,
                                                                }
                                                              : {}
                                                          }
                                                        >
                                                          <div>
                                                            Appt Status:{' '}
                                                            {
                                                              update.ehrAppointmentStatus
                                                            }
                                                          </div>
                                                          <div>
                                                            UTC:{' '}
                                                            {moment
                                                              .utc(
                                                                update.timeStamp
                                                              )
                                                              .format()}
                                                          </div>
                                                          <div>
                                                            Dept ID:{' '}
                                                            {update.ehrDeptId}
                                                          </div>
                                                          <div>
                                                            Provider ID:{' '}
                                                            {
                                                              update.ehrProviderId
                                                            }
                                                          </div>
                                                          <div>
                                                            Dept Name Hint:{' '}
                                                            {
                                                              update.ehrDeptNameHint
                                                            }
                                                          </div>
                                                          <div>
                                                            Provider Name Hint:{' '}
                                                            {
                                                              update.ehrProviderNameHint
                                                            }
                                                          </div>
                                                          <div>
                                                            Log Note:{' '}
                                                            {
                                                              update.appointmentStatusLogNote
                                                            }
                                                          </div>
                                                          <hr />
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            ) : null}

                                            {this.state.selectedVisitData
                                              ?.surveyResponses?.length > 0 ? (
                                              <div
                                                style={{
                                                  fontSize: 14,
                                                  marginTop: 10,
                                                }}
                                              >
                                                Survey Responses
                                                <div
                                                  style={{
                                                    height: 200,
                                                    maxWidth: 500,
                                                    border:
                                                      '1px solid lightgray',
                                                    borderRadius: 5,
                                                    overflowY: 'scroll',
                                                    padding: 10,
                                                  }}
                                                >
                                                  {/* Loop responses here */}
                                                  {this.state.selectedVisitData.surveyResponses.map(
                                                    (
                                                      response: any,
                                                      index: any
                                                    ) => (
                                                      <div key={index}>
                                                        <div
                                                          style={
                                                            index !== 0
                                                              ? {
                                                                  marginTop: 10,
                                                                }
                                                              : {}
                                                          }
                                                        >
                                                          <strong>
                                                            {
                                                              response.surveyQuestionName
                                                            }
                                                          </strong>
                                                          <div>
                                                            -{' '}
                                                            {
                                                              response.surveyQuestionResponse
                                                            }
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            ) : null}

                                            <div>
                                              {pieChartData ? (
                                                <PieChart
                                                  width={400}
                                                  height={250}
                                                  style={{ margin: 'auto' }}
                                                >
                                                  <Pie
                                                    isAnimationActive={false}
                                                    // data={data}
                                                    data={pieChartData}
                                                    cx="50%"
                                                    cy="50%"
                                                    // labelLine={false}
                                                    // label={renderCustomizedLabel}
                                                    label={renderLabel}
                                                    innerRadius={40}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                  >
                                                    {pieChartData.map(
                                                      (
                                                        entry: any,
                                                        index: any
                                                      ) => (
                                                        <Cell
                                                          key={`cell-${index}`}
                                                          fill={
                                                            COLORS[
                                                              index %
                                                                COLORS.length
                                                            ]
                                                          }
                                                        />
                                                      )
                                                    )}
                                                  </Pie>
                                                </PieChart>
                                              ) : null}
                                            </div>
                                          </div>

                                          {/* Patient Timeline Chart */}
                                          <div>
                                            <PatientTimeline
                                              providerData={
                                                combinedTimeSeriesArrayData
                                              }
                                              timeZone={this.props.timeZone}
                                            />
                                          </div>
                                        </div>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {/* @ts-ignore */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={this.state.patientHistoryData.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
            )}
            {!this.state.isValidData && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 500,
                  }}
                >
                  <Typography variant="h6">
                    No valid data, please fill in the required fields above.
                  </Typography>
                </div>
              </div>
              
              )}
          <div style={{ height: '150px' }}></div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  groupData: state.groupData.groupData,
  loggedInUserData: state.adminData.loggedInUserData,
  department: state.adminData.department,
  siblingDepts: state.groupData.siblingDepts,
  staffLocations: state.groupData.staffLocations,
  site: state.adminData.site,
  errorLoggedInUser: state.adminData.errorLoggedInUser,
  distinctOrgActions: state.adminData.distinctOrgActions,
});

export default connect(mapStateToProps, { fetchDistinctOrgActions })(
  withStyles(styles)(PatientHistory)
);
