import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getLobbySequences, saveLobbySequences } from '../../lobbyDB';
import { TLobbySequence } from '../../types';
import { checkIfIsWaitingRoom } from '../../utils';
import LobbySequenceForm from './LobbySequenceForm';
import { schema } from './schema';
import { TFormSchema } from './types';
import { getInitialState } from './utils';

type TProps = {
  orgId: string;
  onClose: () => void;
};

const LobbySequenceFormContainer: FC<TProps> = props => {
  const { orgId, onClose } = props;

  const [lobbySequences, setLobbySequence] = useState<TLobbySequence[]>(
    getLobbySequences(orgId)
  );

  const defaultValues: TFormSchema = getInitialState({
    lobbySequences,
  });

  const formMethods = useForm<TFormSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<TFormSchema> = (values: TFormSchema) => {
    const {
      newPatientEducationTime,
      newPatientEducationToShow,
      newPatientEducationToShowOption,
    } = values;

    console.log({ values });

    const newLobbySequence: TLobbySequence = {
      patientEducationTime: newPatientEducationTime,
      patientEducationToShow: newPatientEducationToShow,
    };

    if (newPatientEducationToShowOption?.patientContent) {
      newLobbySequence.patientContentUrl =
        newPatientEducationToShowOption.patientContent?.patientEducationUrl;
      newLobbySequence.isWaitingRoom = checkIfIsWaitingRoom(newLobbySequence);
    }

    const updatedLobbySequences = lobbySequences.concat(newLobbySequence);

    onSave(updatedLobbySequences);
  };

  const onSave = (lobbySequences: TLobbySequence[]) => {
    setLobbySequence(lobbySequences);
    saveLobbySequences({ orgId, lobbySequences });
    formMethods.reset(
      getInitialState({
        lobbySequences,
      })
    );
  };

  const onDelete = (index: number) => {
    const updatedLobbySequences = lobbySequences.filter(
      (sequence, i) => i !== index
    );

    onSave(updatedLobbySequences);
  };

  return (
    <div>
      <LobbySequenceForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        orgId={orgId}
        onClose={onClose}
        onDelete={onDelete}
      />
    </div>
  );
};

export default LobbySequenceFormContainer;
