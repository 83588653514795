export function canDo(permissionNames: any, user: any) {
  if (!user?.userPermissions || !Array.isArray(user?.userPermissions)) {
    return false;
  }

  const permissionSet = new Set(user?.userPermissions.map((permission: any) => permission.permissionName));

  if (permissionSet.has("SyncAdmin")) {
    return true;
  }

  for (const permName of permissionNames) {
    if (permissionSet.has(permName)) {
      return true;
    }
  }

  return false;
}
