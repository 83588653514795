export interface ReportData {
  reportType: string;
  displayLabel: string;
  description: string;
}

export interface DateRangeOption {
  key: string;
  label: string;
  imageUrl: string | null;
}

export const availableReports: ReportData[] = [
  {
    reportType: "ActionUsage",
    displayLabel: "Action Requests",
    description: "Reports how many times an action was used for a given date range.",
  },
  {
    reportType: "FirstPatient",
    displayLabel: "First Patient of the Day",
    description: "This report shows the first patient of the day for each provider.",
  },
  {
    reportType: "KeptAppointments",
    displayLabel: "Kept Appointments",
    description: "Appointment statistics for historical dates and sc…ppointment for today (if included in date range).",
  },
  {
    reportType: "PatientVisitBreakdown",
    displayLabel: "Patient Visit Breakdown",
    description: "Report that breaks down patient visit times by dep… provider) and the total number of patients seen.",
  },
  {
    reportType: "ReportsAccess",
    displayLabel: "Reports Accessed",
    description: "Report of all reports accessed for a given date range.",
  },
  {
    reportType: "UsersInOrg",
    displayLabel: "Staff/Patient Locations",
    description: "This report shows the last known location for all staff/patients currently on-site.",
  },
];

export const dateRangeOptions: DateRangeOption[] = [
  { key: "Today", label: "Today", imageUrl: null },
  { key: "Yesterday", label: "Yesterday", imageUrl: null },
  { key: "ThisWeek", label: "This Week", imageUrl: null },
  { key: "LastWeek", label: "Last Week", imageUrl: null },
  { key: "ThisMonth", label: "This Month", imageUrl: null },
  { key: "LastMonth", label: "Last Month", imageUrl: null },
  { key: "Last7Days", label: "Last 7 Days (excluding today)", imageUrl: null },
  { key: "Last14Days", label: "Last 14 Days (excluding today)", imageUrl: null },
  { key: "Last30Days", label: "Last 30 Days (excluding today)", imageUrl: null },
  { key: "Last60Days", label: "Last 60 Days (excluding today)", imageUrl: null },
  { key: "ThisYear", label: "This Year", imageUrl: null },
  { key: "LastYear", label: "Last Year", imageUrl: null },
  { key: "Custom", label: "Custom date range...", imageUrl: null}
];
