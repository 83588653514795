import React, { useState, useRef, useEffect } from "react";
import { ActionFormField } from "../emailReportingTypes";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { dateRangeOptions } from "../reportData";
import TextField from "@material-ui/core/TextField";

export interface ActionOption {
  key: string;
  label: string;
  imageUrl: string;
}

interface ActionRequestsFormProps {
  handleSelectChange: (key: string, value: string) => void;
  formFields: ActionFormField[];
  orgId: string;
  setIsFormReady?: (isReady: boolean) => void;
  isRecurring: boolean;
  incomingSettings?: {
    actionId?: string;
    dateRangeName?: string;
    customStartDate?: Date | undefined;
    customEndDate?: Date | undefined;
  };
}

export const ActionRequestsForm: React.FC<ActionRequestsFormProps> = ({
  handleSelectChange,
  formFields,
  orgId,
  setIsFormReady,
  isRecurring,
  incomingSettings,
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState(incomingSettings?.dateRangeName || "");
  const [selectedActionId, setSelectedActionId] = useState(incomingSettings?.actionId || "");
  const [customStartDate, setCustomStartDate] = useState(incomingSettings?.customStartDate || "");
  const [customEndDate, setCustomEndDate] = useState(incomingSettings?.customEndDate || "");
  const [dateError, setDateError] = useState("");
  const orgIdisSet = useRef(false);

  const getDateRangeOptions = () => {
    if (isRecurring) {
      return dateRangeOptions.filter((option) => option.key !== "Custom");
    }
    return dateRangeOptions;
  };

  const filteredDateRangeOptions = getDateRangeOptions();

  const getActionOptions = (formFields: ActionFormField[]): ActionOption[] => {
    const actionField = formFields.find((field) => field.key === "actionId");
    return actionField?.options || [];
  };

  useEffect(() => {
    let isReady = selectedActionId !== undefined && selectedActionId !== "";

    if (selectedDateRange === "Custom") {
      isReady = isReady && customStartDate !== "" && customEndDate !== "" && dateError === "";
    } else {
      isReady = isReady && selectedDateRange !== undefined && selectedDateRange !== "";
    }

    if (setIsFormReady) {
      setIsFormReady(isReady);
    }
  }, [selectedActionId, selectedDateRange, customStartDate, customEndDate, dateError, setIsFormReady]);

  const actionOptions = getActionOptions(formFields);

  const handleFormChange = (key: string, value: string) => {
    if (orgIdisSet.current === false) {
      handleSelectChange("orgId", orgId);
      orgIdisSet.current = true;
    }
    if (key === "dateRangeName") {
      setSelectedDateRange(value);
    } else if (key === "actionId") {
      setSelectedActionId(value);
    }
    handleSelectChange(key, value);
  };

  const handleDateChange = (key: string, value: string) => {
    if (key === "startDate") {
      setCustomStartDate(value);
      if (value && customEndDate && new Date(value) > new Date(customEndDate)) {
        setDateError("Start date must be before or equal to the end date");
      } else {
        setDateError("");
        handleSelectChange("customStartDate", value);
      }
    } else if (key === "endDate") {
      setCustomEndDate(value);
      if (customStartDate && value && new Date(customStartDate) > new Date(value)) {
        setDateError("End date must be after or equal to the start date");
      } else {
        setDateError("");
        handleSelectChange("customEndDate", value);
      }
    }
  };

  return (
    <div>
      {/* Action List */}
      <div>
        <InputLabel style={{ fontSize: 12 }}>Action List</InputLabel>
        <Select value={selectedActionId} onChange={(e) => handleFormChange("actionId", e.target.value as string)} style={{ width: 250 }}>
          {actionOptions.map((option, j) => (
            <MenuItem key={j} value={option.key}>
              {option.imageUrl && <img src={option.imageUrl} style={{ height: 20, marginRight: 10 }} alt="" />}
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <br />
        <br />
      </div>

      {/* Date Range */}
      <div>
        <InputLabel style={{ fontSize: 12 }}>Date Range</InputLabel>
        <Select value={selectedDateRange} onChange={(e) => handleFormChange("dateRangeName", e.target.value as string)} style={{ width: 250 }}>
          {filteredDateRangeOptions.map((option, j) => (
            <MenuItem key={j} value={option.key}>
              {option.imageUrl && <img src={option.imageUrl} style={{ height: 20, marginRight: 10 }} alt="" />}
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <br />

        {/* Start and end date for custom date range */}
        {selectedDateRange === "Custom" && (
          <>
            <TextField
              label="Start Date"
              type="date"
              value={customStartDate}
              onChange={(e) => handleDateChange("startDate", e.target.value)}
              style={{ marginTop: 10 }}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(dateError)}
            />
            <TextField
              label="End Date"
              type="date"
              value={customEndDate}
              onChange={(e) => handleDateChange("endDate", e.target.value)}
              style={{ marginTop: 10, marginLeft: 10 }}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(dateError)}
            />
            {dateError && <div style={{ color: "red", marginTop: 5 }}>{dateError}</div>}
          </>
        )}
        <br />
      </div>
    </div>
  );
};

export default ActionRequestsForm;
