import axios from "axios";
import { checkRefreshToken } from "../utils/utils";
// import { getCookie } from "../utils/utils";
// import {
//   // showLoading,
//   hideLoading,
// } from "react-redux-loading-bar";
import { API } from "../apiconfig";

import {
  FETCH_GROUP,
  UPDATE_GROUP,
  UPDATE_SIBLINGDEPTS,
  FETCH_AND_UPDATE_SIBLINGDEPTS,
  FETCH_STAFF_LOCATIONS,
  FETCH_SITE,
  FETCH_ORGANIZATION,
  SET_DEPT_HISTORY,
  FETCH_SIBLINGDEPTS,
  FETCH_ORGANIZATION_DEPTS,
  FETCH_PROVIDER_ASSIGNMENT_USERS,
  // FETCH_DEPT_HISTORY,
} from "./types";

export const fetchVisibleFsDepartments = (primaryDeptId: string, fsDepartmentsToShow: any[]) => (dispatch: any) => {
  let token = localStorage.getItem("token");
  let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

  // console.log("fsDepartmentsToShow", fsDepartmentsToShow);

  // console.log("fetch # ", fsDepartmentsToShow.length);

  if (fsDepartmentsToShow) {
    // 1. Fetching primary dept
    axios.get(API.REACT_APP_API_FLOWSTATIONDATA + primaryDeptId, headers).then((response) => {
      dispatch({
        type: FETCH_GROUP,
        payload: response.data.data,
      });

      // FETCH ORG DEPTS =========================================
      let orgDeptsUrl = API.REACT_APP_API_GETORGDEPARTMENT + response.data.data.orgId;
      // console.log("GOT URL", orgDeptsUrl);
      axios.get(orgDeptsUrl, headers).then((response) => {
        if (response.data.data) {
          // console.log("got it!", response.data.data);
          dispatch({
            type: FETCH_ORGANIZATION_DEPTS,
            payload: response.data.data,
          });
        }
      });
      // END FETCH ORG DEPTS =========================================
      // FETCH SITE =========================================
      // let siteURL = API.REACT_APP_API_SITE + response.data.data.mongoSiteId;
      // axios
      //   .get(siteURL, headers)
      //   .then((response) => {
      //     if (response.data.data) {
      //       dispatch({
      //         type: FETCH_SITE,
      //         payload: response.data.data,
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("Error fetching Site " + error);
      //   });
      // END FETCH SITE =========================================
    });

    let fetchedSiblingDepts: any[] = [];

    let endpoints: string[] = [];
    fsDepartmentsToShow.forEach((dept) => {
      if (dept !== primaryDeptId) {
        endpoints.push(API.REACT_APP_API_FLOWSTATIONDATA + dept);
      }
    });

    axios.all(endpoints.map((endpoint) => axios.get(endpoint, headers))).then((data) => {
      // Looped and fetched all endpoints
      data.forEach((deptData) => {
        fetchedSiblingDepts.push(deptData.data.data);
      });

      // console.log("Dispatch this", fetchedSiblingDepts);
      dispatch({
        type: FETCH_SIBLINGDEPTS,
        payload: fetchedSiblingDepts,
      });
    });
  }
};

export const fetchAllFsDepartments = (primaryDeptId: string) => (dispatch: any) => {
  let token = localStorage.getItem("token");
  let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

  // 1. Fetching primary dept
  axios.get(API.REACT_APP_API_FLOWSTATIONDATA + primaryDeptId, headers).then((response) => {
    dispatch({
      type: FETCH_GROUP,
      payload: response.data.data,
    });

    // FETCH ORG DEPTS
    let orgDeptsUrl = API.REACT_APP_API_GETORGDEPARTMENT + response.data.data.orgId;
    axios.get(orgDeptsUrl, headers).then((response) => {
      if (response.data.data) {
        dispatch({
          type: FETCH_ORGANIZATION_DEPTS,
          payload: response.data.data,
        });
      }
    });
    // END FETCH ORG DEPTS

    // FETCH SITE
    let siteURL = API.REACT_APP_API_SITE + response.data.data.mongoSiteId;
    axios
      .get(siteURL, headers)
      .then((response) => {
        if (response.data.data) {
          dispatch({
            type: FETCH_SITE,
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        console.log("Error fetching Site " + err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
    // END FETCH SITE
  });
};

export const fetchGroup = (groupId: string) => (dispatch: any) => {
  // console.log("Fetch dept", groupId);

  // let token = localStorage.getItem("token");
  let token = localStorage.getItem("token");

  if (token) {
    // console.log("Token", token);
    let headers = {
      headers: {
        Authorization: "Bearer " + token,
        "Org-UID": "UID1",
        Pragma: "no-cache",
      },
    };

    fetchFsData(groupId);

    function fetchFsData(deptId: string) {
      // console.log("DeptId", deptId);
      // console.log("Location", window.location.href);
      let URL = API.REACT_APP_API_FLOWSTATIONDATA + deptId;
      // dispatch(showLoading());
      // console.log("Fetch parent dept");
      axios
        .get(URL, headers)
        .then((response) => {
          if (response.data.data) {
            // console.log("Got parent dept", response.data.data);
            // FETCH ORGNIAZTION DEPT BELONGS TO
            let URL = API.REACT_APP_API_ORGANIZATION + response.data.data.orgId;
            let token = localStorage.getItem("token");
            let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };
            axios
              .get(URL, headers)
              .then((response) => {
                if (response.data.data) {
                  // console.log("GOT ORG", response.data.data);
                  dispatch({
                    type: FETCH_ORGANIZATION,
                    payload: response.data.data,
                  });
                }
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response?.status === 401) {
                    // FORCE LOGOUT, UNAUTHORIZED
                    console.log("UNAUTHORIZED, KICK EM OUT");

                    checkRefreshToken();

                    // localStorage.removeItem("token");
                    // localStorage.removeItem("email");

                    // if (getQueryVariable("username") && getQueryVariable("password")) {
                    //   // Reload FS with login params
                    //   let location = window.location.href;
                    //   window.location.replace(location);
                    // } else {
                    //   window.location.replace("/");
                    // }
                  }
                }
                console.log("Error: ", err);
              });

            dispatch({
              type: FETCH_GROUP,
              payload: response.data.data,
            });

            if (response.data.data.mongoSiteId) {
              let siteURL = API.REACT_APP_API_SITE + response.data.data.mongoSiteId;
              axios
                .get(siteURL, headers)
                .then((response) => {
                  if (response.data.data) {
                    dispatch({
                      type: FETCH_SITE,
                      payload: response.data.data,
                    });
                  }
                })
                .catch((err) => {
                  console.log("Error fetching Site " + err);
                  if (err.response?.status === 401) {
                    checkRefreshToken();
                  }
                });
            }
          }
        })
        .catch((err) => {
          console.log("Error fetching group " + err);
          // Handle FS reload
          if (err.response) {
            if (err.response?.status === 401) {
              // FORCE LOGOUT, UNAUTHORIZED
              console.log("UNAUTHORIZED, KICK EM OUT");
              checkRefreshToken();
              // localStorage.removeItem("token");
              // localStorage.removeItem("email");

              // if (getQueryVariable("username") && getQueryVariable("password")) {
              //   // Reload FS with login params
              //   let location = window.location.href;
              //   window.location.replace(location);
              // } else {
              //   window.location.replace("/");
              // }
            }
          }
        });

      // dispatch(hideLoading());
    }
  }
};

// export const fetchDeptHistory = () => (dispatch) => {};

export const setDeptHistory = (deptData: any) => (dispatch: any) => {
  // console.log("hist data", deptData);
  dispatch({
    type: SET_DEPT_HISTORY,
    payload: deptData,
  });
};

export const fetchSite = (siteId: string) => (dispatch: any) => {
  // console.log("DING");
  
  //TODO: REACT_APP_API_FETCHSITE doesn't exist
  // @ts-ignore
  let URL = API.REACT_APP_API_FETCHSITE + siteId;
  let token = localStorage.getItem("token");

  let headers = {
    headers: {
      Authorization: "Bearer " + token,
      "Org-UID": "UID1",
      Pragma: "no-cache",
    },
  };

  axios
    .get(URL, headers)
    .then((response) => {
      // console.log("site! " + JSON.stringify(response.data.data));

      if (response.data.data) {
        dispatch({
          type: FETCH_SITE,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      console.log("Error fetching group " + err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const updateGroup = (groupData: any) => (dispatch: any) => {
  // console.log("Updated room by dispatch", groupData);
  dispatch({
    type: UPDATE_GROUP,
    payload: groupData,
  });
};

export const updateSiblingDepts = (groupData: any) => (dispatch: any) => {
  // console.log("Update sibling depts with", groupData);
  dispatch({
    type: UPDATE_SIBLINGDEPTS,
    payload: groupData,
  });
};

export const fetchSiblingDepts = (depts: any) => (dispatch: any) => {
  // console.log("Fetching sibling depts");
  // console.log(depts);
  let token = localStorage.getItem("token");
  let headers = {
    headers: {
      Authorization: "Bearer " + token,
      "Org-UID": "UID1",
      Pragma: "no-cache",
    },
  };

  depts.forEach((dept: any) => {
    console.log("fetching", dept.id);
    axios
      .get(API.REACT_APP_API_FLOWSTATIONDATA + dept.id, headers)
      .then((response) => {
        // console.log("RES!", response);
        if (response.data.data) {
          dispatch({
            type: FETCH_AND_UPDATE_SIBLINGDEPTS,
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        // Handle FS reload
        console.log("Error fetching sibling depts: ", err);
        if (err.response) {
          if (err.response?.status === 401) {
            // FORCE LOGOUT, UNAUTHORIZED
            console.log("UNAUTHORIZED, KICK EM OUT");
            checkRefreshToken();
            // localStorage.removeItem("token");
            // localStorage.removeItem("email");

            // if (getQueryVariable("username") && getQueryVariable("password")) {
            //   // Reload FS with login params
            //   let location = window.location.href;
            //   window.location.replace(location);
            // } else {
            //   window.location.replace("/");
            // }
          }
        }
      });
  });
};

export const fetchProviderAssignmentUsers = (orgId: string) => (dispatch: any) => {
  let token = localStorage.getItem("token");

  let URL = API.REACT_APP_API_PROVIDERASSIGNMENTUSERS + orgId;
  let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

  axios
    .get(URL, headers)
    .then((response) => {
      // console.log("response", response);
      dispatch({
        type: FETCH_PROVIDER_ASSIGNMENT_USERS,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      console.log("Err fetching provider assignment users", err);
      if (err.response?.status === 401) {
        checkRefreshToken();
      }
    });
};

export const fetchStaffLocations = (userId: string, orgId: string) => (dispatch: any) => {
  let URL = API.REACT_APP_API_LOCATESTAFF + userId + "/orgId=" + orgId;
  // let URL = "https://api.synctimes.com/api/v3/fs/findusers/userid=5f49ca01e0a5dc5f705b1a5e/orgId=5f43f0a7e0a5d550acd74b02";
  console.log("url", URL);
  let token = localStorage.getItem("token");

  let headers = {
    headers: {
      Authorization: "Bearer " + token,
      "Org-UID": "UID1",
      Pragma: "no-cache",
    },
  };

  axios
    .get(URL, headers)
    .then((response) => {
      console.log("staff locations", response.data.data);
      dispatch({
        type: FETCH_STAFF_LOCATIONS,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      console.log("Err fetching staff locations", err);
      if (err.response) {
        if (err.response?.status === 401) {
          // FORCE LOGOUT, UNAUTHORIZED
          checkRefreshToken();
          // localStorage.removeItem("token");
          // localStorage.removeItem("email");

          // if (getQueryVariable("username") && getQueryVariable("password")) {
          //   // Reload FS with login params
          //   let location = window.location.href;
          //   window.location.replace(location);
          // } else {
          //   window.location.replace("/");
          // }
        }
      }
    });
};
