import React, { Component } from 'react';
import { API } from '../../../apiconfig';
import axios from 'axios';
import ReactGA from 'react-ga';
import { fetchLoggedInUser } from '../../../actions/adminActions';
import { connect } from 'react-redux';

import { IRoom, IProvider } from '../../../interfaces/interfaces';

import { allowSignalR } from '../../../socket-manager';

import { checkRefreshToken } from '../../../utils/utils';

// Material-UI
import Dialog from '@material-ui/core/Dialog';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const _ = require('lodash');

const styles: {} = (theme: any) => ({
  providerDialog: {
    textAlign: 'center',
  },
  providerItem: {
    cursor: 'pointer',
    marginBottom: 10,
    fontSize: 22,
  },
  roomName: {
    backgroundColor: '#4CB946',
    color: 'white',
    borderBottom: '1px solid #BDBDBD',
  },
  textHeader: {
    padding: '24px 24px 20px',
    fontSize: 32,
    fontWeight: 500,
  },
  assignedProvider: {
    backgroundColor: '#E8E8E8',
  },
});

interface ProviderDialogProps {
  cardData: IRoom;
  onClose: () => void;
  refetchFsData: () => void;
  loggedInUserData: any;
  fetchLoggedInUser: (userEmail: string, token: string) => void;
  classes: any;
  allProviders: IProvider[];
  open: boolean;
  commonProviders: IProvider[];
}

interface ProviderDialogState {
  userSearchValue: any;
  userSearchKey: any;
}

class ProviderDialog extends Component<
  ProviderDialogProps,
  ProviderDialogState
> {
  constructor(props: ProviderDialogProps) {
    super(props);
    this.state = {
      userSearchValue: '',
      userSearchKey: 0,
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  clearAll = () => {
    let data: any = {
      roomId: this.props.cardData.id,
      roomAssignments: [],
    };

    let token = localStorage.getItem('token');

    axios({
      method: 'post',
      url: API.REACT_APP_API_UPDATEPROVIDER,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then(response => {
      console.log('res', response.data.data);
      if (!allowSignalR) {
        this.props.refetchFsData();
      }
    });
  };

  removeProvider = (providerId: string) => {
    console.log('remove', providerId);
    let roomAssignmentsToModify = [...this.props.cardData.roomAssignments];
    if (_.some(this.props.cardData.roomAssignments, { userId: providerId })) {
      roomAssignmentsToModify = roomAssignmentsToModify.filter(function (obj) {
        return obj.userId !== providerId;
      });
    }

    let data = {
      roomId: this.props.cardData.id,
      roomAssignments: roomAssignmentsToModify,
    };
    let token = localStorage.getItem('token');

    console.log('payload', data);

    axios({
      method: 'post',
      url: API.REACT_APP_API_UPDATEPROVIDER,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then(response => {
      console.log('res', response.data.data);
      if (!allowSignalR) {
        this.props.refetchFsData();
      }
    });
  };

  selectProvider = (providerId: any) => {
    console.log('selected', providerId);
    let roomAssignmentsToModify = [...this.props.cardData.roomAssignments];
    if (_.some(this.props.cardData.roomAssignments, { userId: providerId })) {
      // don't do anything
    } else {
      roomAssignmentsToModify.push({
        userId: providerId,
      });
    }

    let data = {
      roomId: this.props.cardData.id,
      roomAssignments: roomAssignmentsToModify,
    };
    let token = localStorage.getItem('token');

    console.log('payload', data);

    axios({
      method: 'post',
      url: API.REACT_APP_API_UPDATEPROVIDER,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then(response => {
      console.log('res', response.data.data);
      if (!allowSignalR) {
        this.props.refetchFsData();
      }
    });
  };

  toggleProvider = (providerId: any) => {
    console.log('selected', providerId);
    let roomAssignmentsToModify = [...this.props.cardData.roomAssignments];
    if (_.some(this.props.cardData.roomAssignments, { userId: providerId })) {
      roomAssignmentsToModify = roomAssignmentsToModify.filter(function (obj) {
        return obj.userId !== providerId;
      });
    } else {
      roomAssignmentsToModify.push({
        userId: providerId,
      });
    }

    let data = {
      roomId: this.props.cardData.id,
      roomAssignments: roomAssignmentsToModify,
    };
    let token = localStorage.getItem('token');

    console.log('payload', data);

    axios({
      method: 'post',
      url: API.REACT_APP_API_UPDATEPROVIDER,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then(response => {
      console.log('res', response.data.data);
      if (!allowSignalR) {
        this.props.refetchFsData();
      }
    });
  };

  assignProvider = (id: any, oldProviderId: any) => {
    console.log('existing', this.props.cardData);
    // console.log("Old provider id", oldProviderId);
    let data = {
      roomId: this.props.cardData.id,
      providerId: id,
    };

    // Assign token to cookie
    let token = localStorage.getItem('token');

    axios({
      method: 'post',
      url: API.REACT_APP_API_UPDATEPROVIDER,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('RES!!!!');
        if (!allowSignalR) {
          this.props.refetchFsData();
        }

        ReactGA.event({
          category: 'Flowstation',
          action: 'Assigned provider to room',
          label: 'Provider Assigned',
        });

        // console.log("Success!", response);
        let currentArr: any[] = [];
        if (localStorage.getItem('providersToShow')) {
          currentArr = JSON.parse(
            localStorage.getItem('providersToShow') || ''
          );
        }
        let index = currentArr.indexOf(oldProviderId);
        if (index !== -1) {
          currentArr.splice(index, 1);
          // console.log("current arr", currentArr);
          localStorage.setItem('providersToShow', JSON.stringify(currentArr));
        }

        // localStorage.setItem("providersToShow", JSON.stringify(this.state.providersToShow));
      })
      .catch(function (err) {
        // console.log("Error updating provider", err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    this.handleClose();
  };

  updateSettings = (userSettings: any) => {
    console.log('post this', userSettings);

    let payload = {
      ...this.props.loggedInUserData,
      userSettings: JSON.stringify(userSettings),
    };

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SETUSERSETTINGS, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        // console.log("RES!", res.json());
        return res.json();
      })
      .then(data => {
        console.log('res', data);
        let userEmail = localStorage.getItem('email');
        let token = localStorage.getItem('token');
        if (userEmail && token) {
          this.props.fetchLoggedInUser(userEmail, token);
        }
        if (!allowSignalR) {
          this.props.refetchFsData();
        }
      })
      .catch(err => {
        console.error('err favoriting provider', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  favoriteProvider = (provider: IProvider) => {
    // console.log("fav this prov", provider);
    let settingsToUpdate: any = {};

    // Prep settings
    if (this.props.loggedInUserData.userSettings) {
      // console.log("settings exist");
      settingsToUpdate = JSON.parse(this.props.loggedInUserData.userSettings);
      if (!settingsToUpdate.favoriteProviders) {
        settingsToUpdate.favoriteProviders = [];
      }
    } else {
      // console.log("no settings found");
      settingsToUpdate = { favoriteProviders: [] };
    }

    if (settingsToUpdate.favoriteProviders.includes(provider.id)) {
      // Remove them
      settingsToUpdate.favoriteProviders = _.reject(
        settingsToUpdate.favoriteProviders,
        (favProv: any) => {
          return favProv === provider.id;
        }
      );
    } else {
      // Add them
      settingsToUpdate.favoriteProviders.push(provider.id);
    }

    // console.log("settingsToUpdate", settingsToUpdate);
    this.updateSettings(settingsToUpdate);
  };

  render() {
    // console.log(this.props.cardData.roomAssignments);

    // console.log("check", this.state.updatedRoomAssignments);

    // Get favorite providers
    // let favoriteProviders: any[] = [];

    // if (this.props.loggedInUserData.userSettings) {
    // console.log("settings exist");
    // let userSettings = JSON.parse(this.props.loggedInUserData.userSettings);
    // if (userSettings.favoriteProviders) {
    //   favoriteProviders = userSettings.favoriteProviders;
    // }
    // }

    // console.log("favorite providers", favoriteProviders);

    const { classes } = this.props;
    return (
      <Dialog
        maxWidth="xs"
        open={this.props.open}
        onClose={this.handleClose}
        className={classes.providerDialog}
      >
        <div className={classes.textHeader + ' ' + classes.roomName}>
          {this.props.cardData.roomName}
        </div>

        <div style={{ margin: 10 }}>
          {this.props.allProviders ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {this.props.allProviders.map((provider, index) => (
                  <div key={index}>
                    {_.some(this.props.cardData.roomAssignments, {
                      userId: provider.id,
                    }) ? (
                      <div>
                        {/* {console.log("FOUND", provider)} */}
                        <Chip
                          style={{ margin: '5px 5px 5px 0px' }}
                          label={provider.fullName}
                          avatar={<Avatar src={provider.userImageUrl} />}
                          onDelete={() => this.removeProvider(provider.id)}
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>

              <div style={{ margin: '10px 10px 50px 10px' }}>
                <Autocomplete
                  id="user-search"
                  key={this.state.userSearchKey}
                  // value={this.state.userSearchValue}
                  autoHighlight
                  options={
                    this.props.allProviders
                      ? this.props.allProviders.filter(provider => {
                          return !_.some(this.props.cardData.roomAssignments, {
                            userId: provider.id,
                          });
                        })
                      : []
                  }
                  getOptionLabel={(provider: any) => provider.fullName}
                  onChange={(event: any, provider: any) => {
                    if (provider) {
                      // this.toggleProvider(provider.id);
                      this.selectProvider(provider.id);
                      this.setState({
                        userSearchKey: this.state.userSearchKey + 1,
                      });
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} autoFocus label="Search User" />
                  )}
                />
              </div>

              <div style={{ float: 'right', margin: 10 }}>
                <Button onClick={this.clearAll} variant="contained">
                  Clear All
                </Button>
                <Button
                  onClick={this.handleClose}
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 10 }}
                >
                  Done
                </Button>
              </div>

              {/* {favoriteProviders.length > 0 ? <div className={classes.textHeader}>Favorite Providers</div> : null}
              {this.props.allProviders.map((provider, index) => (
                <div key={index} className={provider.id === this.props.cardData.mongoAssignedProvider ? classes.assignedProvider : null}>
                  {favoriteProviders.includes(provider.id) ? (
                    <div className={classes.providerItem}>
                      <StarIcon style={{ float: "left", cursor: "pointer" }} onClick={() => this.favoriteProvider(provider)} />
                      <span onClick={() => this.toggleProvider(provider.id)}>{provider.fullName}</span>
                    </div>
                  ) : null}
                </div>
              ))}
              <div className={classes.textHeader}>Providers in Building</div>

              {this.props.commonProviders.map((provider, index) => (
                <span key={index}>
                  {favoriteProviders.includes(provider.id) ? (
                    <StarIcon style={{ float: "left", cursor: "pointer" }} onClick={() => this.favoriteProvider(provider)} />
                  ) : (
                    <StarBorderIcon style={{ float: "left", cursor: "pointer" }} onClick={() => this.favoriteProvider(provider)} />
                  )}
                   <div onClick={() => this.toggleProvider(provider.id)} className={classes.providerItem}>
                    {_.some(this.props.cardData.roomAssignments, { userId: provider.id }) ? (
                      <div className={classes.assignedProvider}>
                        <strong className={classes.assignedProvider}>{provider.fullName}</strong>
                      </div>
                    ) : (
                      <div>{provider.fullName}</div>
                    )}
                  </div>
                </span>
              ))}
              <div className={classes.textHeader}>Provider List</div>
              {this.props.allProviders.map((provider, index) => (
                <div key={index}>
                  {favoriteProviders.includes(provider.id) ? (
                    <StarIcon style={{ float: "left", cursor: "pointer" }} onClick={() => this.favoriteProvider(provider)} />
                  ) : (
                    <StarBorderIcon style={{ float: "left", cursor: "pointer" }} onClick={() => this.favoriteProvider(provider)} />
                  )}
                  <div onClick={() => this.toggleProvider(provider.id)} className={classes.providerItem}>
                   {_.some(this.props.cardData.roomAssignments, { userId: provider.id }) ? (
                      <div className={classes.assignedProvider}>
                        <strong>{provider.fullName}</strong>
                      </div>
                    ) : (
                      <div>{provider.fullName}</div>
                    )}
                  </div>
                </div>
              ))} */}
            </div>
          ) : (
            <div />
          )}
        </div>
      </Dialog>
    );
  }
}

// export default connect(mapwithStyles(styles)(ProviderDialog);

const mapStateToProps = (state: any) => ({
  loggedInUserData: state.adminData.loggedInUserData,
});

export default connect(mapStateToProps, {
  fetchLoggedInUser,
})(withStyles(styles)(ProviderDialog));
