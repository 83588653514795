import axios from 'axios';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { API } from '../apiconfig';
import { IDepartment } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useLobbyDepartments = (input: {
  departmentIds: string[];
  onCompleted?: (departments: IDepartment[]) => void;
}): {
  departments: IDepartment[];
  isLoading: boolean;
  error: string | null;
  refetch: () => void;
} => {
  const {
    onCompleted = () => {
      return [] as IDepartment[];
    },
    departmentIds,
  } = input;
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const prevDepartmentIdsRef = useRef<string[]>([]);

  const fetchLobbyDepts = useCallback(async () => {
    if (isEmpty(departmentIds)) {
      setDepartments([]);
      return;
    }

    // Check if departmentIds have changed
    if (isEqual(departmentIds, prevDepartmentIdsRef.current)) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Org-UID': 'UID1',
      Pragma: 'no-cache',
    };

    try {
      const promises = departmentIds.map(departmentId =>
        axios.get<{ data: IDepartment }>(
          API.REACT_APP_API_FLOWSTATIONDATA + departmentId,
          { headers }
        )
      );

      const responses = await Promise.all(promises);
      const fetchedDepartments = responses
        .map(response => response.data.data)
        .filter(Boolean);

      setDepartments(fetchedDepartments);
      onCompleted(fetchedDepartments);
      prevDepartmentIdsRef.current = departmentIds;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        checkRefreshToken();
      }
      setError('Failed to fetch departments');
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentIds]);

  useEffect(() => {
    fetchLobbyDepts();
  }, [fetchLobbyDepts]);

  const refetch = useCallback(() => {
    prevDepartmentIdsRef.current = []; // Reset prev departmentIds to force a refetch
    fetchLobbyDepts();
  }, [fetchLobbyDepts]);

  return { departments, isLoading, error, refetch };
};
