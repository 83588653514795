import { FC } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import DepartmentMultiSelectField from '../../../../common/fields/department/DepartmentMultiSelectField';
import { IDepartment } from '../../../../interfaces/interfaces';
import { TFormSchema } from './types';

type TProps = {
  formMethods: UseFormReturn<TFormSchema>;
  onSubmit: SubmitHandler<TFormSchema>;
  orgId: string;
  onClose: () => void;
};

const LobbySettingsForm: FC<TProps> = props => {
  const { formMethods, onSubmit, orgId } = props;

  const { watch, setValue, handleSubmit } = formMethods;

  const departmentIds = watch('departmentIds');

  const onSelectDepartment = (selectedDepartments: IDepartment[]) => {
    const selectedDepartmentIds = selectedDepartments.map(
      department => department?.id
    );

    setValue('departmentIds', selectedDepartmentIds);

    onAutoSubmit();
  };

  const onAutoSubmit = () => {
    setTimeout(() => {
      handleSubmit(onSubmit)();
    }, 0);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-5">
        <strong>Departments</strong>
      </div>
      <div className="bg-white shadow-md overflow-hidden divide-y divide-gray-100 p-5">
        <div>
          <DepartmentMultiSelectField
            orgId={orgId}
            onSelect={onSelectDepartment}
            selectedDepartmentIds={departmentIds}
            showSite={true}
          />
        </div>
      </div>
    </form>
  );
};

export default LobbySettingsForm;
