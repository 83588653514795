import { TLobbySequence } from '../../types';
import { TFormSchema } from './types';

export const getInitialState = (input: {
  lobbySequences: TLobbySequence[];
}): TFormSchema => {
  const { lobbySequences } = input;

  return {
    lobbySequences,
    newPatientEducationTime: 10,
    newPatientEducationToShow: '',
    newPatientEducationToShowOption: null,
  };
};

export const checkIfIsVideo = (lobbySequence: TLobbySequence) => {
  return lobbySequence.patientContentUrl?.includes('.mp4');
};
