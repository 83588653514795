import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IUser } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';
import { cloneDeep } from 'lodash';

export const useUsers = (orgId: string | null): {
  users: IUser[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IUser[]>;
} => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = useCallback(async (): Promise<IUser[]> => {
    if (!orgId) {
      return [] as IUser[];
    }

    setIsLoading(true);
    setError(null);

    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETAPPUSERSBYORGID + orgId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    return axios
      .get(URL, headers)
      .then(response => {
        setIsLoading(false);
        const users: IUser[] = Array.isArray(response.data.data) ? cloneDeep(response.data.data) : { ...response.data.data }
        setUsers(users);

        return users;
      })
      .catch(err => {
        console.log('Error: ', err);
        setIsLoading(false);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        return [] as IUser[];
      });
  }, [orgId]);

  useEffect(() => {
    fetchUsers();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IUser[]> => {
    return fetchUsers();
  }, [fetchUsers]);

  return {
    users,
    isLoading,
    error,
    refetch,
  };
};
