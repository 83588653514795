import React, { Component } from "react";
import { API } from "../../../apiconfig";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import synctimesIcon from "../../../images/synctimesicon.png";

const styles: {} = () => ({
  // passwordfieldContainer: {
  //   textAlign: 'center'
  // },
  passwordDialog: {
    margin: 25,
    textAlign: "center",
  },
  submitButton: {
    marginTop: 25,
  },
  loginIcon: {
    margin: "auto",
    height: 150,
  },
  errorText: {
    paddingTop: 10,
    color: "red",
  },
});

interface ResetPasswordDialogProps {
  email: string;
  classes: any;
  open: boolean;
}

interface ResetPasswordDialogState {
  showError: boolean;
  passwordObject: any;
  showCalendarInvite: boolean;
}

class ResetPasswordDialog extends Component<ResetPasswordDialogProps, ResetPasswordDialogState> {
  constructor(props: ResetPasswordDialogProps) {
    super(props);
    this.state = {
      showError: false,
      passwordObject: null,
      showCalendarInvite: false,
    };
  }

  handleSubmit = () => {
    // console.log('New password object to send', this.state.passwordObject);
    let token = localStorage.getItem("token");

    if (this.state.passwordObject.password === this.state.passwordObject.confirmPassword) {
      console.log("Make request");

      let payload = {
        userEmail: this.props.email,
        password: this.state.passwordObject.password,
      };

      let jsonPayload = JSON.stringify(payload);
      // console.log('send', jsonPayload)

      fetch(API.REACT_APP_API_UPDATEPASSWORD, {
        method: "POST",
        body: jsonPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log("res", data);
          // console.log("Should reload, lets show invite instead");

          if (data.data.showCalendarInvite) {
            this.setState({
              showCalendarInvite: true,
            });
          } else {
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    } else {
      this.setState({
        showError: true,
      });
    }
  };

  handleChange = (name: any) => (event: any) => {
    let newValue = event.target.value;

    this.setState((prevState) => ({
      passwordObject: {
        ...prevState.passwordObject,
        [name]: newValue,
      },
    }));

    // console.log(this.state)
  };

  handleEnter = () => (event: any) => {
    console.log("echedk", event.keyCode);
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog open={this.props.open} aria-labelledby="Reset Password">
          <div className={classes.passwordDialog}>
            <img className={classes.loginIcon} src={synctimesIcon} alt="SyncTimes" />
            <Typography variant="h5" component="h3">
              Set a New Password
            </Typography>
            <div className={classes.passwordfieldContainer}>
              <TextField
                type="password"
                required
                label="New Password"
                onChange={this.handleChange("password")}
                // onKeyPress={(ev) => {
                //   console.log(`Pressed keyCode ${ev.key}`);
                //   if (ev.key === 9) {
                //     // Do code here
                //     console.log("TAB CLICKED");
                //     ev.preventDefault();
                //   }
                // }}
                margin="normal"
              />
              <br />
              <TextField
                type="password"
                required
                label="Confirm Password"
                //  onKeyPress={this.handleEnter()}
                onKeyPress={(event) => {
                  if (event.charCode === 13 || event.keyCode === 13) {
                    this.handleSubmit();
                  }
                }}
                onChange={this.handleChange("confirmPassword")}
                margin="normal"
              />
            </div>
            {this.state.showError ? <div className={classes.errorText}>Passwords Must Match</div> : <div></div>}
            <Button className={classes.submitButton} onClick={() => this.handleSubmit()} fullWidth variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </Dialog>
        <Dialog open={this.state.showCalendarInvite}>
          <div className={classes.passwordDialog}>
            Welcome to SyncTimes! Watch your email for a calendar invite to our new User Foundation training held on the first Tuesday of the month. We'll see
            you then!
            <div>
              <br />
              <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                Finish
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPasswordDialog);
