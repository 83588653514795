import { FC, useState, ChangeEvent } from 'react';
// Interfaces
import {
  IAction,
  IDevice,
  IActionLog,
} from '../../../../../interfaces/interfaces';
// Material UI
import TextField from '@material-ui/core/TextField';
// Hooks
import { useStyles } from './styles';
import { useDeviceAction } from '../../../../../hooks/useDeviceAction';
// Components
import DeviceActions from '../deviceActions/DeviceActions';
import DeviceHistoryTable from './DeviceHistoryTable';
// Utils
import moment from 'moment';

interface TProps {
  deviceToEdit: IDevice;
  actionTypes: IAction[];
  onDeviceUpdate: (field: keyof IDevice, value: any) => void;
}

const DeviceActionsPod: FC<TProps> = ({
  deviceToEdit,
  actionTypes,
  onDeviceUpdate,
}) => {
  const [areaToView, setAreaToView] = useState('log');

  const { deleteDeviceAction } = useDeviceAction();

  const classes = useStyles();

  const onDeleteAction = (actionQueueId: any) => {
    deleteDeviceAction({
      deviceId: deviceToEdit.deviceId,
      actionQueueId: actionQueueId,
      onSuccess: () => {
        console.log('Action deleted successfully');
      },
      onFail: error => {
        console.error('Failed to delete action', error);
      },
    });
  };

  const onViewArea = (area: 'actions' | 'log' | 'history') => {
    setAreaToView(area);
  };

  const onHandleLogInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onDeviceUpdate('deviceNotes', event.target.value);
  };

  const onHandleHostName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onDeviceUpdate('hostName', event.target.value);
  };

  const onHandleSwitchPort = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onDeviceUpdate('switchPort', event.target.value);
  };

  const onHandleHostPort = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onDeviceUpdate('hostPort', event.target.value);
  };

  return (
    <div>
      {/* Actions Log History Tabs */}
      <div>
        {deviceToEdit.isAndroidTablet && (
          <h2
            onClick={() => onViewArea('actions')}
            className={classes.displayInline}
            style={
              areaToView === 'actions'
                ? { borderBottom: '5px solid black' }
                : {}
            }
          >
            Actions/Queue
          </h2>
        )}

        <h2
          onClick={() => onViewArea('log')}
          className={classes.displayInline}
          style={
            areaToView === 'log' ? { borderBottom: '5px solid black' } : {}
          }
        >
          Log
        </h2>

        {deviceToEdit.isAndroidTablet && (
          <h2
            onClick={() => onViewArea('history')}
            className={classes.displayInline}
            style={
              areaToView === 'history'
                ? { borderBottom: '5px solid black' }
                : {}
            }
          >
            History
          </h2>
        )}
      </div>

      {/* Host,switch port log message */}
      {areaToView === 'log' && (
        <div className={classes.textDetails}>
          <div>
            <TextField
              label="Host Name"
              className={classes.textField}
              value={deviceToEdit.hostName || ''}
              onChange={onHandleHostName}
              margin="none"
            />

            <TextField
              label="Switch Brand"
              className={classes.textField}
              value={deviceToEdit.hostPort || ''}
              onChange={onHandleHostPort}
              margin="none"
            />

            <TextField
              label="Switch Port"
              className={classes.textField}
              value={deviceToEdit.switchPort || ''}
              onChange={onHandleSwitchPort}
              margin="none"
            />
          </div>
          <form>
            <TextField
              multiline
              variant="outlined"
              label="Log Message"
              className={classes.textField}
              value={deviceToEdit.deviceNotes || ''}
              onChange={onHandleLogInput}
              margin="normal"
            />
          </form>
        </div>
      )}

      {/* App Install */}
      {areaToView === 'actions' && (
        <div>
          <div>
            <DeviceActions
              filteredDevices={[deviceToEdit]}
              actionTypes={actionTypes}
            />
            {deviceToEdit.mdmActionQueue.map((actionLog: IActionLog) => (
              <div key={actionLog.actionQueueId}>
                <div className={classes.timeStamp}>
                  {moment.utc(actionLog.startTime).local().format('LLL')}
                </div>
                <div>
                  {actionTypes.map((action: IAction) => (
                    <div key={action.actionTypeId}>
                      {action.actionTypeId === actionLog.actionTypeId && (
                        <div>
                          <strong>{action.actionTypeName}</strong>
                          <div
                            className={classes.deleteAction}
                            onClick={() =>
                              onDeleteAction(actionLog.actionQueueId)
                            }
                          >
                            X
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  <hr />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* History */}
      {areaToView === 'history' && (
        <div>
          <DeviceHistoryTable deviceId={deviceToEdit.deviceId} />
        </div>
      )}
    </div>
  );
};
export default DeviceActionsPod;
