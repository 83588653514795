import MatTextField from '@material-ui/core/TextField';
import {
  Control,
  FieldErrors,
  FieldPath,
  useController,
} from 'react-hook-form';

// Update the props type to be more flexible and handle nested fields
interface TProps<TFieldValues extends Record<string, any>> {
  className?: string;
  label: string;
  type?: string;
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  margin?: 'none' | 'dense' | 'normal';
  required?: boolean;
  multiline?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  style?: React.CSSProperties;
  errors?: FieldErrors<TFieldValues>;
  disabled?: boolean;
}

// The component is now generic, but with a default type
const TextField = <TFieldValues extends Record<string, any>>(
  props: TProps<TFieldValues>
) => {
  const {
    type = 'text',
    className = '',
    name,
    label = '',
    control,
    margin = 'none',
    required = false,
    multiline = false,
    variant = 'standard',
    disabled = false
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  console.log({ className });

  return (
    <div className="w-full">
      <MatTextField
        multiline={multiline}
        type={type}
        value={field.value as string | number | readonly string[]}
        required={required}
        label={label}
        variant={variant}
        disabled={disabled}
        onChange={event => field.onChange(event.target.value)}
        margin={margin}
        fullWidth
        InputProps={{
          classes: {
            root: `${className}`,
          },
        }}
      />

      {error && <div style={{ color: 'red' }}>{error.message}</div>}
    </div>
  );
};

export default TextField;
