import React, { ChangeEvent, FC, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { API } from '../../../../apiconfig';
import { checkRefreshToken } from '../../../../utils/utils';

import { canDo } from '../../../../utils/permissionCheck';

import { fetchDepartment } from '../../../../actions/adminActions';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorSnack from '../../../../utils/ErrorSnack';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';
// import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CreateIcon from '@material-ui/icons/Create';

import { isEmpty } from 'lodash';
import { useDepartments } from '../../../../hooks/department/useDepartments';
import { useSites } from '../../../../hooks/useSites';
import
  {
    IAction,
    IDepartment,
    ISite,
    TAdminState,
  } from '../../../../interfaces/interfaces';
import ActionModifyDialog from './action/modify/ActionModifyDialog';
import { DEPARTMENT_TEMPLATE } from './constants';
import DepartmentDrawer from './DepartmentDrawer';
import { getLiveExample } from './frontDesk/utils';
import { useStyles } from './style';
import { theme } from './Theme';
import { DepartmentTemplate } from './types';
// import Autocomplete from "@material-ui/lab/Autocomplete";

type TProps = {};
const Departments: FC<TProps> = () => {
  const { loggedInUserData, department } = useSelector(
    (state: { adminData: TAdminState }) => {
      const adminState = state?.adminData ?? ({} as TAdminState);
      return {
        ...adminState,
      };
    },
    shallowEqual
  );

  const orgId = loggedInUserData?.mongoOrganizationId;
  const { sites } = useSites(orgId);
  const { departments, refetch: fetchDepartments } = useDepartments({orgId});

  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<boolean>(false);
  // const [newDevice, setNewDevice] = useState<{
  //   name: string;
  //   id: string;
  //   url: string;
  // } | null>(null);

  const [newDialogSuccess, setNewDialogSuccess] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState<string>('deptName');
  const [showNewDepartmentModal, toggleNewDepartmentModal] =
    useState<boolean>(false);

  const [selectedDepartment, selectDepartment] = useState<IDepartment | null>(
    null
  );

  const [sortDescending, toggleSortDescending] = useState<boolean>(false);

  const [hasModified, setHasModified] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');
  const [copyDepartmentSuccess, setCopyDepartmentSuccess] =
    useState<boolean>(false);
  const [pasteDepartmentSuccess, setPasteDepartmentSuccess] =
    useState<boolean>(false);
  const [showCopyWarning, toggleCopyWarning] = useState<boolean>(false);
  const [showDeptComparisonTable, toggleDeptComparisonTable] =
    useState<boolean>(false);
  const [departmentsToEdit, setDepartmentsToEdit] = useState<IDepartment[]>([]);
  const [updatedDepartmentsSuccess, setUpdatedDepartmentsSuccess] =
    useState<boolean>(false);
  const [testShowActionDialog, setTestShowActionDialog] =
    useState<boolean>(false);
  const [testShowVisibleActions, setTestShowVisibleActions] =
    useState<boolean>(false);

  const [hideInactiveDepartments, setHideInactiveDepartments] =
    useState<boolean>(true);

  // const dispatch = useDispatch<ThunkDispatch<RootState, unknown, UnknownAction>>();

  // const [state, setState] = useState<DepartmentsState>({
  //   athenaPatients: [],
  //   athenaConfigList: [],
  //   testShowActionDialog: false,
  //   testShowVisibleActions: false,
  //   showDeptComparisonTable: false,
  //   deptToCompare: null,
  //   departmentsToEdit: [],
  //   updatedDepartmentsSuccess: false,
  //   newEhrDepartmentId: "",
  //   newEhrDepartmentIdIsDefaultDept: false,
  //   loading: false,
  //   hideInactiveDepartments: true,
  //   filterString: "",
  //   sortDescending: false,
  //   sortBy: "deptName",
  //   right: false,
  //   newDepartment: false,
  //   fieldError: false,
  //   departmentTemplate: {
  //     deptName: "",
  //     isActive: true,
  //     subscribedActionId: null,
  //     showSurvey: false,
  //     patientEducationUrl: null,
  //     isFrontDeskIntegration: false,
  //     actionsList: [],
  //     showNurseCallButton: false,
  //     ehrDeptIds: [],
  //     isTotalTimeFromCheckIn: false,
  //     patientContentIds: [],
  //   },
  //   departmentToEdit: null,
  //   newDeviceName: "",
  //   newDeviceId: "",
  //   newDeviceUrl: "",
  //   minutesToWait: 15,
  //   isFlashing: false,
  //   clearOnEnter: false,
  //   anchorEl: null,
  //   newClearActionAnchorEl: null,
  //   selectedScheduleAction: null,
  //   selectedAutoClearAction: null,
  //   scheduledTouchStatus: 1,
  //   autoClearUserType: 1,
  //   newScheduledActionError: false,
  //   newAutoClearActionError: false,
  //   showActionDialog: false,
  //   showVisibleActions: false,
  //   newDialogSuccess: false,
  //   showCopyWarning: false,
  //   copyDepartmentSuccess: false,
  //   pasteDepartmentSuccess: false,
  //   hasModified: false,
  //   showSaveAlert: false,
  // });

  // constructor(props: DepartmentsProps) {
  //   super(props);
  //   state = {
  //     athenaPatients: [],
  //     athenaConfigList: [],
  //     testShowActionDialog: false,
  //     testShowVisibleActions: false,
  //     showDeptComparisonTable: false,
  //     deptToCompare: null,
  //     departmentsToEdit: [],
  //     updatedDepartmentsSuccess: false,
  //     newEhrDepartmentId: "",
  //     newEhrDepartmentIdIsDefaultDept: false,
  //     loading: false,
  //     hideInactiveDepartments: true,
  //     filterString: "",
  //     sortDescending: false,
  //     sortBy: "deptName",
  //     right: false,
  //     newDepartment: false,
  //     fieldError: false,
  //     departmentTemplate: {
  //       deptName: "",
  //       isActive: true,
  //       subscribedActionId: null,
  //       showSurvey: false,
  //       patientEducationUrl: null,
  //       isFrontDeskIntegration: false,
  //       actionsList: [],
  //       showNurseCallButton: false,
  //       ehrDeptIds: [],
  //       isTotalTimeFromCheckIn: false,
  //       patientContentIds: [],
  //     },
  //     departmentToEdit: null,
  //     newDeviceName: "",
  //     newDeviceId: "",
  //     newDeviceUrl: "",
  //     minutesToWait: 15,
  //     isFlashing: false,
  //     clearOnEnter: false,
  //     anchorEl: null,
  //     newClearActionAnchorEl: null,
  //     selectedScheduleAction: null,
  //     selectedAutoClearAction: null,
  //     scheduledTouchStatus: 1,
  //     autoClearUserType: 1,
  //     newScheduledActionError: false,
  //     newAutoClearActionError: false,
  //     showActionDialog: false,
  //     showVisibleActions: false,
  //     newDialogSuccess: false,
  //     showCopyWarning: false,
  //     copyDepartmentSuccess: false,
  //     pasteDepartmentSuccess: false,
  //     hasModified: false,
  //     showSaveAlert: false,
  //   };
  // }

  // useEffect(() => {
  //   if (loggedInUserData?.mongoOrganizationId) {
  // const promises =[]
  // promises.push(dispatch(fetchAllActions()));
  // promises.push(dispatch(fetchdepartments(loggedInUserData.mongoOrganizationId)));
  // promises.push(dispatch(fetchSites(loggedInUserData.mongoOrganizationId)));
  // promises.push(dispatch(fetchSounds()));
  // promises.push(dispatch(fetchPatientContent(loggedInUserData.mongoOrganizationId)));
  // Promise.all(promises).then(() => {
  //   console.log("All promises resolved");
  // });
  // dispatch(fetchAllActions());
  // dispatch(fetchdepartments(loggedInUserData.mongoOrganizationId));
  // dispatch(fetchSites(loggedInUserData.mongoOrganizationId));
  // dispatch(fetchSounds());
  // dispatch(fetchPatientContent(loggedInUserData.mongoOrganizationId));
  //   }
  // }, [dispatch]);

  // componentDidMount() {
  //   if (loggedInUserData.mongoOrganizationId) {
  //     fetchAllActions();
  //     fetchdepartments(loggedInUserData.mongoOrganizationId);
  //     fetchSites(loggedInUserData.mongoOrganizationId);
  //     fetchSounds();
  // fetchPatientContent(loggedInUserData.mongoOrganizationId);
  //   }
  // }

  const handleChange = (name: string) => (event: any) => {
    // let newValue = event.target.value;
    // selectDepartment({
    //   ...selectedDepartment,
    //   [name]: newValue,
    // } as IDepartment);
    // setHasModified(true);
  };

  //   useEffect(() => {
  //     if (canDo(["Update Departments"], loggedInUserData)) {
  //     if (hasModified) {
  //       toggleSaveAlert(true);
  //     } else {
  //       if (!selectedDepartment) {
  //         fetchDepartment(deptId);
  //       }

  //       toggleNewDepartmentModal(false);

  //       setLoading(false);
  //       setRight(open);
  //       toggleNewDepartmentModal(false);
  //       selectDepartment(department);
  //       setFieldError(false);
  //       // setNewDevice({
  //       //   id: "",
  //       //   name: "",
  //       //   url: "",
  //       // });
  //       setNewDialogSuccess(false);
  //       setAthenaPatients([]);
  //     }

  //     if (organization.orgUsesAthena) {
  //       let URL = API.REACT_APP_API_ATHENAPRACTICECONFIG + organization.id;
  //       let token = localStorage.getItem("token");
  //       let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

  //       axios.get(URL, headers).then((res) => {
  //         console.log("athena res", res);
  //         if (res.data.data) {
  //           // console.log("Grab first and load it", res.data.data[0]);

  //           setAthenaConfigList(res.data.data);

  //           res.data.data.forEach((config: any) => {
  //             if (config.athenaPracticeId === department.athenaPracticeId) {
  //               selectAthenaConfig(config);
  //             }
  //           });
  //         }
  //       });
  //     }
  //   }
  // }, [selectDepartment])

  // const toggleDrawer = (open: boolean, deptId: string, department: IDepartment) => () => {
  //   if (canDo(["Update Departments"], loggedInUserData)) {
  //     if (hasModified) {
  //       toggleSaveAlert(true);
  //     } else {
  //       if (!right) {
  //         fetchDepartment(deptId);
  //       }

  //       toggleNewDepartmentModal(false);

  //       setLoading(false);
  //       setRight(open);
  //       toggleNewDepartmentModal(false);
  //       selectDepartment(department);
  //       setFieldError(false);
  //       // setNewDevice({
  //       //   id: "",
  //       //   name: "",
  //       //   url: "",
  //       // });
  //       setNewDialogSuccess(false);
  //       setAthenaPatients([]);
  //     }

  //     if (organization.orgUsesAthena) {
  //       let URL = API.REACT_APP_API_ATHENAPRACTICECONFIG + organization.id;
  //       let token = localStorage.getItem("token");
  //       let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

  //       axios.get(URL, headers).then((res) => {
  //         console.log("athena res", res);
  //         if (res.data.data) {
  //           // console.log("Grab first and load it", res.data.data[0]);

  //           setAthenaConfigList(res.data.data);

  //           res.data.data.forEach((config: any) => {
  //             if (config.athenaPracticeId === department.athenaPracticeId) {
  //               selectAthenaConfig(config);
  //             }
  //           });
  //         }
  //       });
  //     }
  //   }
  // };

  const newDepartment = (department: DepartmentTemplate) => {
    toggleNewDepartmentModal(true);

    // @ts-ignore
    const newDepartment: IDepartment = {
      ...department,
      mongoSiteId: '',
    } as IDepartment;

    console.log({ newDepartment });

    selectDepartment(newDepartment);

    // setState({
    //   right: true,
    //   newDepartment: true,
    //   departmentToEdit: {
    //     ...department,
    //     mongoSiteId: "",
    //   },
    // });
  };

  const handlePasteCopiedDepartment = () => {
    if (localStorage.getItem('copiedDepartment')) {
      let copiedDepartment = JSON.parse(
        localStorage.getItem('copiedDepartment') || ''
      );
      if (copiedDepartment) {
        let preppedData = {
          ...department,
          ...copiedDepartment,
        };

        let payload = {
          ...preppedData,
          id: department?.id,
          deptId: department?.deptId,
          isActive: department?.isActive,
          orgId: department?.orgId,
          ehrDeptId: department?.ehrDeptId,
          deptNotes: department?.deptNotes,
          mongoSiteId: department?.mongoSiteId,
          deptName: department?.deptName,
          athenaWaitingRoomId: department?.athenaWaitingRoomId,
          athenaPracticeId: department?.athenaPracticeId,
          ehrDeptIdList: department?.ehrDeptIdList,
        };

        // console.log("payload", payload);
        let token = localStorage.getItem('token');
        fetch(API.REACT_APP_API_UPDATE_DEPARTMENT, {
          method: 'PUT',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            // console.log(data);
            fetchDepartment(department.id);
            setPasteDepartmentSuccess(true);
            toggleCopyWarning(false);
            selectDepartment(data.data);

            // setState({
            //   pasteDepartmentSuccess: true,
            //   showCopyWarning: false,
            //   // departmentToEdit: { ...preppedData, deptName: department.deptName },
            //   departmentToEdit: data.data,
            // });
            setTimeout(() => setPasteDepartmentSuccess(false), 3000);
          })
          .catch(function (err) {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    }
  };

  const copyDepartment = () => {
    console.log('copy department', department);
    if (department) {
      localStorage.setItem('copiedDepartment', JSON.stringify(department));
      setCopyDepartmentSuccess(true);

      setTimeout(() => setCopyDepartmentSuccess(false), 3000);
    }
  };

  const handleSubmit = () => {
    let token = localStorage.getItem('token');

    console.log({ selectedDepartment });
    if (
      (selectedDepartment && selectedDepartment.deptName.length < 1) ||
      (selectedDepartment && selectedDepartment.mongoSiteId.length < 1)
    ) {
      setFieldError(true);
    } else {
      if (showNewDepartmentModal) {
        // If creating a new department
        let newDepartment = {
          ...selectedDepartment,
          // AdminUserId: loggedInUserData.userId,
        };
        // console.log("Submit this", newDepartment);
        fetch(API.REACT_APP_API_UPDATE_DEPARTMENT, {
          method: 'POST',
          body: JSON.stringify(newDepartment),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(data => {
            // console.log("res", data);
            // fetchdepartments(loggedInUserData.mongoOrganizationId);
            selectDepartment(null);
            fetchDepartments(orgId);
            setFieldError(false);
            setHasModified(false);
          })
          .catch(function (err) {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      } else {
        setLoading(true);
        // Else, department is being modified
        let modifiedDepartment = {
          ...selectedDepartment,
          actionsList: department.actionsList,
          // AdminUserId: loggedInUserData.userId,
          scheduledActions: department.scheduledActions,
          autoClearActions: department.autoClearActions,
          phoneNumbers: department.phoneNumbers,
        };
        console.log('Sending updated department', modifiedDepartment);
        fetch(API.REACT_APP_API_UPDATE_DEPARTMENT, {
          method: 'PUT',
          body: JSON.stringify(modifiedDepartment),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(function (res) {
            // console.log("good", res);
            return res.json();
          })
          .then(data => {
            console.log('Successfully updated Dept', data);
            // fetchdepartments(loggedInUserData.mongoOrganizationId);
            selectDepartment(null);
            fetchDepartments(orgId);
            setLoading(false);
            setFieldError(false);
            setHasModified(false);
          })
          .catch(function (err) {
            console.log('Error: ', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }
    }
  };

  const handleSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      toggleSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      toggleSortDescending(false);
      // setState({
      //   sortBy: sortValue,
      //   sortDescending: false,
      // });
    }
  };

  const handleFilter = () => (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  // sendToFlowstation = (deptId) => {
  //   let url = "/?groupId=" + deptId;
  //   window.open(url, "_blank");
  // };

  const openComparisonTable = (
    // deptToCompare: IDepartment,
    filteredDepartments: IDepartment[]
  ) => {
    console.log('filteredDepartments', filteredDepartments);

    let departmentsToEdit = JSON.parse(
      JSON.stringify([...filteredDepartments])
    );

    toggleDeptComparisonTable(true);
    setDepartmentsToEdit(departmentsToEdit);
  };

  const handleNewDialogSuccess = () => {
    setNewDialogSuccess(true);
    setTimeout(() => setNewDialogSuccess(false), 3000);
  };

  const updateDepartmentField = (deptId: string, field: string, value: any) => {
    console.log('UPDATE', deptId, field, value);

    // const updatedDepartmentsToEdit = departmentsToEdit?.map(department =>
    //   department.id === deptId ? { ...department, [field]: value } : department
    // );

    // setDepartmentToEdit(updatedDepartmentsToEdit);

    console.log('Departments Updated', departmentsToEdit);
  };

  const saveDepartments = async () => {
    console.log('SAVE', departmentsToEdit);
    let token = localStorage.getItem('token');

    let promiseArray: any = [];

    // use

    if (departmentsToEdit) {
      departmentsToEdit.forEach((department: IDepartment) => {
        promiseArray.push(
          new Promise((resolve, reject) => {
            console.log('Saving', department.id);
            fetch(API.REACT_APP_API_UPDATE_DEPARTMENT, {
              method: 'PUT',
              body: JSON.stringify(department),
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
            })
              .then(res => {
                return res.json();
              })
              .then(data => {
                console.log('Successfully updated Dept', data);
                resolve(data);
              })
              .catch(err => {
                console.log('Error: ', err);
                reject(err);
                if (err.response?.status === 401) {
                  checkRefreshToken();
                }
              });
          })
        );
      });

      // need to wait for resolve
      // fetchdepartments(loggedInUserData.mongoOrganizationId);

      await Promise.all(promiseArray).then(values => {
        console.log('DONE', values);
        fetchDepartments(loggedInUserData.mongoOrganizationId);
        toggleDeptComparisonTable(false);
        setDepartmentsToEdit([]);
        setUpdatedDepartmentsSuccess(true);

        setTimeout(() => setUpdatedDepartmentsSuccess(false), 3000);
      });
    }
  };

  const openModifyActions = (department: any, showVisible: boolean) => {
    console.log('open', department);
    fetchDepartment(department.id);
    setTestShowActionDialog(true);
    setTestShowVisibleActions(showVisible);
  };

  const updateLocalDeptActions = (department: IDepartment) => {
    const updatedDepartments = departmentsToEdit.map(dept =>
      dept.id === department.id
        ? {
            ...dept,
            actionsList: department.actionsList,
            scheduledActions: department.scheduledActions,
            autoClearActions: department.autoClearActions,
          }
        : dept
    );

    setDepartmentsToEdit(updatedDepartments);
  };

  // console.log("toEdit", selectedDepartment);

  let filteredDepartments: IDepartment[] = [];

  if (departments) {
    let filter = sortBy;

    // Sort By Select
    filteredDepartments = departments.sort((a: IDepartment, b: IDepartment) => {
      if (sortDescending) {
        // DESCENDING
        if (a[filter as keyof IDepartment] > b[filter as keyof IDepartment]) {
          return -1;
        }
        if (a[filter as keyof IDepartment] < b[filter as keyof IDepartment]) {
          return 1;
        }
        return 0;
      } else {
        // ASCENDING
        if (a[filter as keyof IDepartment] < b[filter as keyof IDepartment]) {
          return -1;
        }
        if (a[filter as keyof IDepartment] > b[filter as keyof IDepartment]) {
          return 1;
        }
        return 0;
      }
    });

    let keyword = filterString;
    // console.log("CHECK", departments);
    filteredDepartments = departments.filter((obj: IDepartment) => {
      return obj['deptName'].toUpperCase().includes(keyword.toUpperCase());
      // || obj["siteName"].toUpperCase().includes(keyword.toUpperCase());
    });
  }

  if (hideInactiveDepartments) {
    let activeDepartments: IDepartment[] = [];
    filteredDepartments.filter((item: IDepartment) => {
      if (item.isActive) {
        activeDepartments.push(item);
      }
      return null;
    });
    filteredDepartments = activeDepartments;
  }

  let siteOptions = sites.map((site: ISite) => (
    <option key={site.id} value={site.id}>
      {site.siteName}
    </option>
  ));

  // let organizationDepartmentOptions = departments.map(
  //   (currentDepartment, index: number) =>
  //     currentDepartment.isActive &&
  //     currentDepartment.id !== department.id &&
  //     !currentDepartment.inheritSettingsFromDeptId ? (
  //       <option key={index} value={currentDepartment.id}>
  //         {currentDepartment.siteName} {currentDepartment.deptName}
  //       </option>
  //     ) : null
  // );

  let departmentItems: any = [];
  if (departmentsToEdit) {
    departmentsToEdit.forEach((department: IDepartment, i: number) => {
      // Front Desk Input Field Count
      let frontDeskInputFieldsTrueCount = 0;
      if (department.showProvider) frontDeskInputFieldsTrueCount++;
      if (department.showFirstName) frontDeskInputFieldsTrueCount++;
      if (department.showLastName) frontDeskInputFieldsTrueCount++;
      if (department.showMRN) frontDeskInputFieldsTrueCount++;
      if (department.showDOB) frontDeskInputFieldsTrueCount++;
      if (department.showApptTime) frontDeskInputFieldsTrueCount++;
      if (department.showApptType) frontDeskInputFieldsTrueCount++;

      const fsPatientInfoIdentifier = getLiveExample({
        patientInfo: department.fsPatientInfo,
      });

      const inRoomPatientInfoIdentifier = getLiveExample({
        patientInfo: department.inRoomPatientInfo,
      });
      const outsideRoomPatientInfoIdentifier = getLiveExample({
        patientInfo: department.outsideRoomPatientInfo,
      });

      departmentItems.push(
        <TableRow key={i}>
          <TableCell>
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={department.isActive}
              onChange={() =>
                updateDepartmentField(
                  department.id,
                  'isActive',
                  !department.isActive
                )
              }
            />
          </TableCell>
          <TableCell>
            <TextField
              value={department.deptName}
              onChange={e =>
                updateDepartmentField(department.id, 'deptName', e.target.value)
              }
            />
          </TableCell>
          <TableCell>
            <FormControl style={{ width: 125 }}>
              <NativeSelect
                value={department.mongoSiteId}
                onChange={e =>
                  updateDepartmentField(
                    department.id,
                    'mongoSiteId',
                    e.target.value
                  )
                }
                input={<Input id="age-native-label-placeholder" />}
              >
                {siteOptions}
              </NativeSelect>
            </FormControl>
          </TableCell>
          <TableCell>
            <FormControl style={{ width: 125 }}>
              <NativeSelect
                value={
                  department.inheritSettingsFromDeptId
                    ? department.inheritSettingsFromDeptId
                    : ''
                }
                onChange={e =>
                  updateDepartmentField(
                    department.id,
                    'inheritSettingsFromDeptId',
                    e.target.value
                  )
                }
                input={<Input id="age-native-label-placeholder" />}
              >
                <option value=""></option>
                {departments.map((orgDept: any, index: number) =>
                  orgDept.isActive &&
                  orgDept.id !== department.id &&
                  !orgDept.inheritSettingsFromDeptId ? (
                    <option key={index} value={orgDept.id}>
                      {orgDept.siteName} {orgDept.deptName}
                    </option>
                  ) : null
                )}
              </NativeSelect>
            </FormControl>
          </TableCell>
          <TableCell>
            <TextField
              value={department.secondsInRoomTimeout}
              onChange={e =>
                updateDepartmentField(
                  department.id,
                  'secondsInRoomTimeout',
                  e.target.value
                )
              }
            />
          </TableCell>

          <TableCell>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={department.isFrontDeskIntegration}
                  onChange={() =>
                    updateDepartmentField(
                      department.id,
                      'isFrontDeskIntegration',
                      !department.isFrontDeskIntegration
                    )
                  }
                />
              </>
            )}
          </TableCell>

          <TableCell>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={department.showNurseCallButton}
                  onChange={() =>
                    updateDepartmentField(
                      department.id,
                      'showNurseCallButton',
                      !department.showNurseCallButton
                    )
                  }
                />
              </>
            )}
          </TableCell>

          <TableCell>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={department.isTotalTimeFromCheckIn}
                  onChange={() =>
                    updateDepartmentField(
                      department.id,
                      'isTotalTimeFromCheckIn',
                      !department.isTotalTimeFromCheckIn
                    )
                  }
                />
              </>
            )}
          </TableCell>

          <TableCell>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={department.clearRoomAssignmentsOnPatientCheckOut}
                  onChange={() =>
                    updateDepartmentField(
                      department.id,
                      'clearRoomAssignmentsOnPatientCheckOut',
                      !department.clearRoomAssignmentsOnPatientCheckOut
                    )
                  }
                />
              </>
            )}
          </TableCell>

          <TableCell>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                <Checkbox
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={department.assignPatientProviderOnPatientRoom}
                  onChange={() =>
                    updateDepartmentField(
                      department.id,
                      'assignPatientProviderOnPatientRoom',
                      !department.assignPatientProviderOnPatientRoom
                    )
                  }
                />
              </>
            )}
          </TableCell>

          <TableCell style={{ minWidth: 313, padding: 0 }}>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                <div style={{ backgroundColor: 'lightgray' }}>Visible</div>
                <div style={{ display: 'flex', gap: 2 }}>
                  {department.actionsList?.map((action: IAction, i: number) =>
                    action.isVisible ? (
                      <Tooltip
                        key={i}
                        title={
                          <React.Fragment>
                            <div>{action.actionName}</div>
                            {/* <div>Add for new visit: {action.addForNewVisit ? <CheckIcon style={{ fontSize: 12 }} /> : null}</div>
                      <div>Add for finished visit: {action.addForFinishedVisit ? <CheckIcon style={{ fontSize: 12 }} /> : null}</div>
                      <div>Can add without patient: {action.canAddWithoutPatient ? <CheckIcon style={{ fontSize: 12 }} /> : null}</div> */}
                          </React.Fragment>
                        }
                        placement="right"
                      >
                        <img
                          onClick={() => openModifyActions(department, true)}
                          alt=""
                          src={action.actionImageUrl}
                          style={{ width: 20, cursor: 'pointer' }}
                        />
                      </Tooltip>
                    ) : null
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: 'lightgray',
                  }}
                >
                  Hidden
                </div>
                <div style={{ display: 'flex', gap: 2 }}>
                  {department.actionsList?.map((action: IAction, i: number) =>
                    action.isVisible === false ? (
                      <div key={i}>
                        <Tooltip
                          title={
                            <React.Fragment>{action.actionName}</React.Fragment>
                          }
                          placement="right"
                        >
                          <img
                            onClick={() => openModifyActions(department, false)}
                            alt=""
                            src={action.actionImageUrl}
                            style={{ width: 20, cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </div>
                    ) : null
                  )}
                </div>
              </>
            )}
          </TableCell>

          {/* FRONT DESK INPUT FIELDS */}
          <TableCell style={{ minWidth: 275 }}>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                {' '}
                {department.isFrontDeskIntegration ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>
                        ({frontDeskInputFieldsTrueCount}) Selected
                      </Typography>
                    </AccordionSummary>
                    <div className={classes.patientIdentifierDropdown}>
                      <div>
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'showProvider',
                              !department.showProvider
                            )
                          }
                          checked={department.showProvider}
                        />
                        Show Provider
                      </div>

                      <div>
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'showFirstName',
                              !department.showFirstName
                            )
                          }
                          checked={department.showFirstName}
                        />
                        Show First name
                      </div>

                      <div>
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'showLastName',
                              !department.showLastName
                            )
                          }
                          checked={department.showLastName}
                        />
                        Show Last Name
                      </div>

                      <div>
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'showMRN',
                              !department.showMRN
                            )
                          }
                          checked={department.showMRN}
                        />
                        Show EHR Patient ID
                      </div>

                      <div>
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'showDOB',
                              !department.showDOB
                            )
                          }
                          checked={department.showDOB}
                        />
                        Show Date of Birth
                      </div>

                      <div>
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'showApptTime',
                              !department.showApptTime
                            )
                          }
                          checked={department.showApptTime}
                        />
                        Show Appointment Time
                      </div>

                      <div>
                        <Checkbox
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'showApptType',
                              !department.showApptType
                            )
                          }
                          checked={department.showApptType}
                        />
                        Show Appointment Type
                      </div>
                    </div>
                  </Accordion>
                ) : null}
              </>
            )}
          </TableCell>

          {/* FLOWSTATION PATIENT IDENTIFIER */}
          <TableCell style={{ minWidth: 310 }}>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                {department.isFrontDeskIntegration ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{fsPatientInfoIdentifier}</Typography>
                    </AccordionSummary>

                    <div className={classes.patientIdentifierDropdown}>
                      <div className={classes.patientIdentifierExampleText}>
                        <strong>Identifier 1 (choose 1)</strong>
                      </div>
                      <div>
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          // onClick={() => modifyFlowstationPatientIdentifier(0)}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                patientInfoTypeId: 0,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.patientInfoTypeId === 0
                              ? true
                              : false
                          }
                        />
                        None
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          // onClick={() => modifyFlowstationPatientIdentifier(1)}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                patientInfoTypeId: 1,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.patientInfoTypeId === 1
                              ? true
                              : false
                          }
                        />
                        J Smith
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          // onClick={() => modifyFlowstationPatientIdentifier(2)}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                patientInfoTypeId: 2,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.patientInfoTypeId === 2
                              ? true
                              : false
                          }
                        />
                        JS
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                patientInfoTypeId: 3,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.patientInfoTypeId === 3
                              ? true
                              : false
                          }
                        />
                        John S
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                patientInfoTypeId: 4,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.patientInfoTypeId === 4
                              ? true
                              : false
                          }
                        />
                        John Smith
                        <br />
                        <div className={classes.patientIdentifierExampleText}>
                          <strong>Identifier 2</strong>
                        </div>
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                appendAge: !department.fsPatientInfo.appendAge,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.appendAge ? true : false
                          }
                        />
                        Show Age
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                appendMrn: !department.fsPatientInfo.appendMrn,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.appendMrn ? true : false
                          }
                        />
                        Show EHR Patient ID
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                appendApptTime:
                                  !department.fsPatientInfo.appendApptTime,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.appendApptTime
                              ? true
                              : false
                          }
                        />
                        Show Appointment Time
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                appendApptType:
                                  !department.fsPatientInfo.appendApptType,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.appendApptType
                              ? true
                              : false
                          }
                        />
                        Show Appointment Type
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                appendDob: !department.fsPatientInfo.appendDob,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.appendDob ? true : false
                          }
                        />
                        Show Date of Birth
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'fsPatientInfo',
                              {
                                ...department.fsPatientInfo,
                                appendLanguage:
                                  !department.fsPatientInfo.appendLanguage,
                              }
                            )
                          }
                          checked={
                            department.fsPatientInfo.appendLanguage
                              ? true
                              : false
                          }
                        />
                        Show Language
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(department.id, 'isAge21Max', {
                              ...department.fsPatientInfo,
                              isAge21Max: !department.fsPatientInfo.isAge21Max,
                            })
                          }
                          checked={
                            department.fsPatientInfo.isAge21Max ? true : false
                          }
                        />
                        Age 21 Max
                        <br />
                      </div>
                    </div>
                  </Accordion>
                ) : null}
              </>
            )}
          </TableCell>

          {/* INSIDE EXAM ROOM PATIENT IDENTIFIER */}
          <TableCell style={{ minWidth: 360 }}>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                {department.isFrontDeskIntegration ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{inRoomPatientInfoIdentifier}</Typography>
                    </AccordionSummary>
                    <div className={classes.patientIdentifierDropdown}>
                      <div className={classes.patientIdentifierExampleText}>
                        <strong>Identifier 1 (choose 1)</strong>
                      </div>
                      <div>
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                patientInfoTypeId: 0,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.patientInfoTypeId === 0
                              ? true
                              : false
                          }
                        />
                        None
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                patientInfoTypeId: 1,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.patientInfoTypeId === 1
                              ? true
                              : false
                          }
                        />
                        J Smith
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                patientInfoTypeId: 2,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.patientInfoTypeId === 2
                              ? true
                              : false
                          }
                        />
                        JS
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                patientInfoTypeId: 3,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.patientInfoTypeId === 3
                              ? true
                              : false
                          }
                        />
                        John S
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                patientInfoTypeId: 4,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.patientInfoTypeId === 4
                              ? true
                              : false
                          }
                        />
                        John Smith
                        <br />
                        <div className={classes.patientIdentifierExampleText}>
                          <strong>Identifier 2</strong>
                        </div>
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                appendAge:
                                  !department.inRoomPatientInfo.appendAge,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.appendAge
                              ? true
                              : false
                          }
                        />
                        Show Age
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                appendMrn:
                                  !department.inRoomPatientInfo.appendMrn,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.appendMrn
                              ? true
                              : false
                          }
                        />
                        Show EHR Patient Id
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                appendApptTime:
                                  !department.inRoomPatientInfo.appendApptTime,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.appendApptTime
                              ? true
                              : false
                          }
                        />
                        Show Appointment Time
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                appendApptType:
                                  !department.inRoomPatientInfo.appendApptType,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.appendApptType
                              ? true
                              : false
                          }
                        />
                        Show Appointment Type
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                appendDob:
                                  !department.inRoomPatientInfo.appendDob,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.appendDob
                              ? true
                              : false
                          }
                        />
                        Show Date of Birth
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                appendLanguage:
                                  !department.inRoomPatientInfo.appendLanguage,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.appendLanguage
                              ? true
                              : false
                          }
                        />
                        Show Language
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'inRoomPatientInfo',
                              {
                                ...department.inRoomPatientInfo,
                                isAge21Max:
                                  !department.inRoomPatientInfo.isAge21Max,
                              }
                            )
                          }
                          checked={
                            department.inRoomPatientInfo.isAge21Max
                              ? true
                              : false
                          }
                        />
                        Age 21 Max
                        <br />
                      </div>
                    </div>
                  </Accordion>
                ) : null}
              </>
            )}
          </TableCell>

          {/* OUTSIDE EXAM ROOM PATIENT IDENTIFIER */}
          <TableCell style={{ minWidth: 360 }}>
            {department.inheritSettingsFromDeptId ? null : (
              <>
                {department.isFrontDeskIntegration ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>
                        {outsideRoomPatientInfoIdentifier}
                      </Typography>
                    </AccordionSummary>

                    <div className={classes.patientIdentifierDropdown}>
                      <div className={classes.patientIdentifierExampleText}>
                        <strong>Identifier 1 (choose 1)</strong>
                      </div>
                      <div>
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                patientInfoTypeId: 0,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo
                              .patientInfoTypeId === 0
                              ? true
                              : false
                          }
                        />
                        None
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                patientInfoTypeId: 1,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo
                              .patientInfoTypeId === 1
                              ? true
                              : false
                          }
                        />
                        J Smith
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                patientInfoTypeId: 2,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo
                              .patientInfoTypeId === 2
                              ? true
                              : false
                          }
                        />
                        JS
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                patientInfoTypeId: 3,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo
                              .patientInfoTypeId === 3
                              ? true
                              : false
                          }
                        />
                        John S
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                patientInfoTypeId: 4,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo
                              .patientInfoTypeId === 4
                              ? true
                              : false
                          }
                        />
                        John Smith
                        <br />
                        <div className={classes.patientIdentifierExampleText}>
                          <strong>Identifier 2</strong>
                        </div>
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                appendAge:
                                  !department.outsideRoomPatientInfo.appendAge,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo.appendAge
                              ? true
                              : false
                          }
                        />
                        Show Age
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                appendMrn:
                                  !department.outsideRoomPatientInfo.appendMrn,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo.appendMrn
                              ? true
                              : false
                          }
                        />
                        Show EHR Patient ID
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                appendApptTime:
                                  !department.outsideRoomPatientInfo
                                    .appendApptTime,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo.appendApptTime
                              ? true
                              : false
                          }
                        />
                        Show Appointment Time
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                appendApptType:
                                  !department.outsideRoomPatientInfo
                                    .appendApptType,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo.appendApptType
                              ? true
                              : false
                          }
                        />
                        Show Appointment Type
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                appendDob:
                                  !department.outsideRoomPatientInfo.appendDob,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo.appendDob
                              ? true
                              : false
                          }
                        />
                        Show Date of Birth
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                appendLanguage:
                                  !department.outsideRoomPatientInfo
                                    .appendLanguage,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo.appendLanguage
                              ? true
                              : false
                          }
                        />
                        Show Language
                        <br />
                        <Checkbox
                          classes={{
                            root: classes.identifierCheckbox,
                            checked: classes.checked,
                          }}
                          onClick={() =>
                            updateDepartmentField(
                              department.id,
                              'outsideRoomPatientInfo',
                              {
                                ...department.outsideRoomPatientInfo,
                                isAge21Max:
                                  !department.outsideRoomPatientInfo.isAge21Max,
                              }
                            )
                          }
                          checked={
                            department.outsideRoomPatientInfo.isAge21Max
                              ? true
                              : false
                          }
                        />
                        Age 21 Max
                        <br />
                      </div>
                    </div>
                  </Accordion>
                ) : null}
              </>
            )}
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        {department && (
          <ActionModifyDialog
            open={testShowActionDialog}
            onClose={() => setTestShowActionDialog(false)}
            onSuccess={handleNewDialogSuccess}
            department={department}
            showVisibleActions={testShowVisibleActions}
            updateLocalDeptActions={updateLocalDeptActions}
            modifyingMultipleDepts={true}
          />
        )}

        <Dialog
          open={showDeptComparisonTable}
          onClose={() => toggleDeptComparisonTable(false)}
          maxWidth="xl"
        >
          {/* <DialogTitle>Department Comparison Table</DialogTitle> */}
          <DialogContent style={{ height: '90vh' }}>
            {/* <DialogContentText>Context text here...</DialogContentText> */}

            {/* <Paper className={classes.root} style={{ maxWidth: "90vw" }}> */}
            <Table className={classes.table}>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell>Active</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Site</TableCell>
                  <TableCell>Inherit Settings</TableCell>
                  <TableCell>RTLS Timeout Seconds</TableCell>
                  <TableCell>Front Desk Integration</TableCell>
                  <TableCell style={{ minWidth: 100 }}>
                    Show Nurse Call Button
                  </TableCell>
                  <TableCell style={{ minWidth: 110 }}>
                    Total Time From Check In
                  </TableCell>
                  <TableCell style={{ minWidth: 150 }}>
                    Clear Room Assignments on Patient Check Out
                  </TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    Assign Patient Provider On Patient Room
                  </TableCell>

                  <TableCell>Actions</TableCell>

                  <TableCell>Front Desk Input Fields</TableCell>
                  <TableCell>Flowstation Patient Identifier</TableCell>
                  <TableCell>Inside Exam Room Patient Identifier</TableCell>
                  <TableCell>Outside Exam Room Patient Identifier</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>{departmentItems}</TableBody>
            </Table>
            {/* </Paper> */}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              // onClick={() => handleSubmit()}
              onClick={() => saveDepartments()}
            >
              Save
            </Button>
            <Button
              color="primary"
              // autoFocus
              // onClick={() => closeWithoutSaving(selectedDepartment)}
              onClick={() => {
                toggleDeptComparisonTable(false);
                setDepartmentsToEdit([]);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Dialog open={showSaveAlert}>
          <DialogTitle>Unsaved changes!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to save your changes before exiting?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => handleSubmit()}>
              Yes
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => closeWithoutSaving(selectedDepartment)}
            >
              No
            </Button>
          </DialogActions>
        </Dialog> */}

        <Typography variant="h4" gutterBottom component="h2">
          Departments
          {canDo(['SyncAdmin'], loggedInUserData) ? (
            <Tooltip
              title="Add"
              aria-label="Add"
              onClick={() => newDepartment(DEPARTMENT_TEMPLATE)}
            >
              <Fab className={classes.newDepartmentButton}>
                <AddIcon />
              </Fab>
            </Tooltip>
          ) : (
            <div />
          )}
          {canDo(['SyncAdmin'], loggedInUserData) ? (
            <Tooltip
              title="Edit All Departments"
              aria-label="Add"
              onClick={() => openComparisonTable(filteredDepartments)}
            >
              <Fab
                className={classes.newDepartmentButton}
                style={{ marginRight: 75 }}
              >
                <CreateIcon />
              </Fab>
            </Tooltip>
          ) : (
            <div />
          )}
        </Typography>

        <TextField
          label="Search"
          className={classes.textBadgeField}
          onChange={handleFilter()}
        />
        <br />

        <div
          onClick={() => setHideInactiveDepartments(!hideInactiveDepartments)}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={hideInactiveDepartments}
          />
          Hide Inactive Departments
        </div>

        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => handleSortBy('deptName')}
                >
                  {sortBy === 'deptName' ? (
                    <div>Department {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Department</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => handleSortBy('siteName')}
                >
                  {sortBy === 'siteName' ? (
                    <div>Site {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Site</div>
                  )}
                </TableCell>
                <TableCell
                  className={classes.sortSelect}
                  onClick={() => handleSortBy('isActive')}
                >
                  {sortBy === 'isActive' ? (
                    <div>Is Active {sortDescending ? <>▼</> : <>▲</>}</div>
                  ) : (
                    <div>Is Active</div>
                  )}
                </TableCell>
                <TableCell className={classes.sortSelect}>
                  Inheriting Settings From
                </TableCell>
                <TableCell className={classes.sortSelect} />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDepartments.map(department => (
                <TableRow key={department.id} className={classes.tableRow}>
                  {/* <TableCell style={{ width: 0 }} onClick={() => openComparisonTable(filteredDepartments)}><CompareArrowsIcon style={{ color: "gray" }} /></TableCell> */}
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => {
                      selectDepartment(department);
                    }}
                  >
                    {department.deptName}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => {
                      selectDepartment(department);
                    }}
                  >
                    {department.siteName}
                  </TableCell>
                  {department.isActive ? (
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        selectDepartment(department);
                      }}
                    >
                      <CheckIcon />
                    </TableCell>
                  ) : (
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        selectDepartment(department);
                      }}
                    ></TableCell>
                  )}

                  <TableCell
                    className={classes.tableCell}
                    onClick={() => {
                      selectDepartment(department);
                    }}
                  >
                    {/* loop through all departments and find deptName from department.inheritSettingsFromDeptId */}
                    {departments.map((dept: any) => {
                      if (dept.id === department.inheritSettingsFromDeptId) {
                        return dept.siteName + ' ' + dept.deptName;
                      }
                      return null;
                    })}
                  </TableCell>
                  <Tooltip
                    title="Click here to view the Flowstation for this department"
                    placement="right"
                  >
                    <TableCell className={classes.tableCell}>
                      <a
                        href={'/?groupId=' + department.id}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button variant="contained" color="primary">
                          Flowstation
                        </Button>
                      </a>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {selectedDepartment && (
          <DepartmentDrawer
            isNew={isEmpty(selectedDepartment?.id)}
            departmentId={selectedDepartment.id}
            open={!isEmpty(selectedDepartment)}
            onHide={() => {
              selectDepartment(null);
            }}
            sites={sites}
            organizationDepartments={departments}
            showNewDepartmentModal={showNewDepartmentModal}
            toggleCopyWarning={toggleCopyWarning}
            showCopyWarning={showCopyWarning}
            handleNewDialogSuccess={handleNewDialogSuccess}
            selectDepartment={selectDepartment}
            handleChange={handleChange}
            siteOptions={siteOptions}
            handleCopyDepartment={copyDepartment}
            handlePasteDepartment={handlePasteCopiedDepartment}
            hasModified={hasModified}
            loading={loading}
            handleSubmit={handleSubmit}
            onSuccess={() => {
              fetchDepartments(orgId);
            }}
          />
        )}

        {fieldError ? (
          <ErrorSnack
            errorSnack={true}
            errorMessage="Please Fill Out All Required Fields"
          />
        ) : (
          <div />
        )}
        {newDialogSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Department actions have been updated!"
          />
        ) : (
          <div />
        )}
        {copyDepartmentSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Department copied!"
          />
        ) : (
          <div />
        )}
        {pasteDepartmentSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Department updated with pasted department!"
          />
        ) : (
          <div />
        )}
        {updatedDepartmentsSuccess ? (
          <SuccessSnack
            successSnack={true}
            successMessage="Departments updated!"
          />
        ) : (
          <div />
        )}
      </MuiThemeProvider>
    </div>
  );
};

export default Departments;
