import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import 'cropperjs/dist/cropper.css';

// Material UI & Styles
import { useStyles } from './styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseButton from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  IBenchMarkGroup,
  IOrganization,
  IWorkflow,
} from '../../../../interfaces/interfaces';

import { useOrganization } from '../../../../hooks/useOrganization';
import { useBenchmarkGroups } from '../../../../hooks/useBenchmarkGroups';
import AvatarPod from './AvatarPod';
import { ORG_TEMPLATE } from './constants';

type TProps = {
  organizationToEdit: IOrganization | null;
  open: boolean;
  onClose: () => void;
  isCreatingNewOrganization: boolean;
  refreshOrganizations: () => void;
  onSaveComplete: (message: string, isError: boolean) => void;
};

const OrganizationDrawer: React.FC<TProps> = ({
  organizationToEdit,
  open,
  onClose,
  isCreatingNewOrganization,
  refreshOrganizations,
  onSaveComplete,
}) => {
  // Hooks
  const classes = useStyles();
  const { submitOrganization } = useOrganization();
  const { benchmarkGroups: availableBenchmarkGroups } = useBenchmarkGroups();

  // State
  const [organization, setOrganization] = useState<IOrganization>(ORG_TEMPLATE);
  const [hasModified, setHasModified] = useState(false);
  const [newWorkflowUrl, setNewWorkflowUrl] = useState<string>('');
  const [showSaveAlert] = useState<boolean>(false);
  const [newEmailDomain, setNewEmailDomain] = useState<string>('');
  const [newWorkflowName, setNewWorkflowName] = useState<string>('');

  useEffect(() => {
    if (isCreatingNewOrganization) {
      setOrganization(ORG_TEMPLATE);
      setHasModified(true);
    } else if (organizationToEdit) {
      setOrganization(organizationToEdit);
      setHasModified(false);
    }
  }, [organizationToEdit, isCreatingNewOrganization]);

  // Main Org Object Handler
  const handleOrgDataChange = (field: keyof IOrganization, value: any) => {
    setOrganization(prevData => ({
      ...prevData,
      [field]: value ?? '', // Use nullish coalescing to ensure a defined value
    }));
    setHasModified(true);
  };

  // Event Handlers
  const handleOrgNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleOrgDataChange('orgName', event.target.value);
    },
    []
  );

  const handleServiceLineLabelChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleOrgDataChange('serviceLineLabel', event.target.value);
    },
    []
  );

  const handlesubGroupLabelChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleOrgDataChange('serviceLineSubGroupLabel', event.target.value);
    },
    []
  );

  const handleGoalChange = useCallback(
    (
        field: keyof Pick<
          IOrganization,
          | 'goal_PatientMinutesInRoom'
          | 'goal_PercentTimeWithStaff'
          | 'goal_VisitsPerProviderHour'
          | 'goal_PatientAloneTime'
        >
      ) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setOrganization(prevData => {
          if (!prevData) return null;
          return {
            ...prevData,
            [field]:
              inputValue === ''
                ? ''
                : isNaN(parseFloat(inputValue))
                ? prevData[field]
                : parseFloat(inputValue),
          };
        });
        setHasModified(true);
      },
    []
  );

  // Toggle functions

  const toggleIsActive = useCallback(() => {
    handleOrgDataChange('isActive', !organization?.isActive);
  }, [organization]);

  const toggleIsCustomerOrg = useCallback(() => {
    handleOrgDataChange('isCustomerOrg', !organization?.isCustomerOrg);
  }, [organization]);

  const toggleAllowSsoOnly = useCallback(() => {
    handleOrgDataChange('allowSsoOnly', !organization?.allowSsoOnly);
  }, [organization]);

  const toggleOrgUsesAthena = useCallback(() => {
    handleOrgDataChange('orgUsesAthena', !organization?.orgUsesAthena);
  }, [organization]);

  const toggleIsBenchmarkOrg = useCallback(() => {
    handleOrgDataChange('isBenchmarkOrg', !organization?.isBenchmarkOrg);
  }, [organization]);

  // Domain functions

  const handleNewEmailDomain = (event: ChangeEvent<HTMLInputElement>) => {
    setNewEmailDomain(event.target.value);
  };

  const handleAddDomain = useCallback(() => {
    if (
      organization &&
      newEmailDomain &&
      !organization.allowedEmailDomains.includes(newEmailDomain)
    ) {
      setOrganization(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          allowedEmailDomains: [
            ...prevData.allowedEmailDomains,
            newEmailDomain,
          ],
        };
      });
      setNewEmailDomain('');
      setHasModified(true);
    }
  }, [newEmailDomain, organization]);

  const handleDeleteDomain = useCallback((domain: string) => {
    setOrganization(prevData => {
      if (!prevData) return null;
      return {
        ...prevData,
        allowedEmailDomains: prevData.allowedEmailDomains.filter(
          d => d !== domain
        ),
      };
    });
    setHasModified(true);
  }, []);

  const handleTimeZoneChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      handleOrgDataChange('timeZoneId', event.target.value as number);
    },
    []
  );

  const handleOrgImgUrlChanged = useCallback(
    (newImageData: { orgImageUrl?: string; orgImage?: string }) => {
      setOrganization(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          orgImageUrl: newImageData.orgImageUrl,
          orgImage: newImageData.orgImage,
        };
      });
      setHasModified(true);
    },
    [setOrganization, setHasModified]
  );

  // Benchmark functions

  const handleAddBenchmark = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (!organization?.benchmarkGroups) return;

      const selectedId = event.target.value as string;
      const selectedBenchmark = availableBenchmarkGroups.find(
        bg => bg.id === selectedId
      );
      if (
        selectedBenchmark &&
        organization &&
        !organization.benchmarkGroups.some(bg => bg.id === selectedId)
      ) {
        setOrganization(prevData => {
          if (!prevData) return null;
          return {
            ...prevData,
            benchmarkGroups: [
              ...(prevData.benchmarkGroups ?? []),
              selectedBenchmark,
            ],
          };
        });
        setHasModified(true);
      }
    },
    [availableBenchmarkGroups, organization]
  );

  const handleRemoveBenchmark = useCallback(
    (benchmarkToRemove: IBenchMarkGroup) => {
      setOrganization(prevData => {
        if (!prevData || !prevData.benchmarkGroups) return null;
        return {
          ...prevData,
          benchmarkGroups: prevData.benchmarkGroups.filter(
            bg => bg.id !== benchmarkToRemove.id
          ),
        };
      });
      setHasModified(true);
    },
    []
  );

  // Workflow functions
  const renameWorkflow = useCallback(
    (index: number, field: 'name' | 'url') =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setOrganization(prevData => {
          if (!prevData || !prevData.orgDocumentation) return prevData;
          const newDocs = [...prevData.orgDocumentation];
          newDocs[index] = { ...newDocs[index], [field]: event.target.value };
          return { ...prevData, orgDocumentation: newDocs };
        });
        setHasModified(true);
      },
    []
  );

  const addWorkflow = useCallback(() => {
    if (newWorkflowName && newWorkflowUrl && organization) {
      const newWorkflow: IWorkflow = {
        name: newWorkflowName,
        url: newWorkflowUrl,
        index: organization.orgDocumentation?.length ?? 0,
      };
      setOrganization(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          orgDocumentation: [...(prevData.orgDocumentation ?? []), newWorkflow],
        };
      });
      setNewWorkflowName('');
      setNewWorkflowUrl('');
      setHasModified(true);
    }
  }, [newWorkflowName, newWorkflowUrl, organization]);

  const deleteWorkflow = useCallback((index: number) => {
    setOrganization(prevData => {
      if (!prevData || !prevData.orgDocumentation) return prevData;
      return {
        ...prevData,
        orgDocumentation: prevData.orgDocumentation.filter(
          (_, i) => i !== index
        ),
      };
    });
    setHasModified(true);
  }, []);

  const moveWorkflow = useCallback((from: number, to: number) => {
    setOrganization(prevData => {
      if (!prevData || !prevData.orgDocumentation) return prevData;
      if (to >= 0 && to < prevData.orgDocumentation.length) {
        const newDocs = [...prevData.orgDocumentation];
        const [removed] = newDocs.splice(from, 1);
        newDocs.splice(to, 0, removed);
        return { ...prevData, orgDocumentation: newDocs };
      }
      return prevData;
    });
    setHasModified(true);
  }, []);

  const handleSavePressed = useCallback(async () => {
    if (!organization) return;

    try {
      await submitOrganization(organization, isCreatingNewOrganization);
      refreshOrganizations();
      onClose();
      onSaveComplete('Organization saved successfully', false);
    } catch (error) {
      console.error('Error saving organization:', error);
      onSaveComplete('Error saving organization', true);
    }
  }, [
    organization,
    isCreatingNewOrganization,
    submitOrganization,
    refreshOrganizations,
    onClose,
    onSaveComplete,
  ]);

  return (
    <>
      {/* Save Alert Dialog */}
      <Dialog open={showSaveAlert}>
        <DialogTitle>Unsaved changes!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to save your changes before exiting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleSavePressed}>
            Yes
          </Button>
          <Button color="primary" autoFocus onClick={() => onClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Drawer Component */}
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className={classes.drawer}>
          <div className={classes.closeButton} onClick={onClose}>
            <CloseButton />
          </div>
          <h2>
            {isCreatingNewOrganization
              ? 'Create New Organization'
              : `Editing ${organization?.orgName || ''}`}
          </h2>
          <br />
          <AvatarPod
            initialImageUrl={organization.orgImageUrl ?? ''}
            onImageChange={handleOrgImgUrlChanged}
          />

          {!isCreatingNewOrganization && (
            <div
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginTop: 16,
                marginBottom: 8,
              }}
            >
              <span style={{ fontSize: 13, color: '#757575', fontWeight: 400 }}>
                Organization ID
              </span>
              <div>{organization?.id}</div>
            </div>
          )}

          {/* Organization Name */}
          <TextField
            style={{ width: '100%' }}
            required
            label="Organization Name"
            className={classes.textField}
            value={organization.orgName ?? ''}
            onChange={handleOrgNameChange}
            margin="normal"
          />
          <br />

          {/* Service Line Label */}
          <TextField
            style={{ width: '100%' }}
            required
            label="Service Line Label"
            className={classes.textField}
            value={organization.serviceLineLabel ?? ''}
            onChange={handleServiceLineLabelChange}
            margin="normal"
          />
          <br />

          {/* Service Line Sub Group Label */}
          <TextField
            style={{ width: '100%' }}
            required
            label="Service Line Sub Group Label"
            className={classes.textField}
            value={organization.serviceLineSubGroupLabel ?? ''}
            onChange={handlesubGroupLabelChange}
            margin="normal"
          />
          <br />

          {/* Timezone Select */}
          <FormControl className={classes.textField} margin="normal">
            <InputLabel shrink htmlFor="time-zone-select">
              Time Zone
            </InputLabel>
            <NativeSelect
              value={organization.timeZoneId || 0}
              onChange={handleTimeZoneChange}
              input={<Input id="time-zone-select" />}
            >
              <option value={0}>Empty</option>
              <option value={3}>HST</option>
              <option value={4}>AKST</option>
              <option value={6}>PST</option>
              <option value={7}>MST-AZ</option>
              <option value={9}>MST</option>
              <option value={10}>CST</option>
              <option value={15}>EST</option>
              <option value={16}>AST-PR</option>
            </NativeSelect>
          </FormControl>

          <br />

          {/* Is Active  */}
          <div onClick={toggleIsActive} style={{ cursor: 'pointer' }}>
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={organization.isActive ?? false}
            />
            Is Active
          </div>

          {/* Customer Organization */}
          <div onClick={toggleIsCustomerOrg} style={{ cursor: 'pointer' }}>
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={organization.isCustomerOrg}
            />
            Customer Organization
          </div>

          {/* Allow SSO Only */}
          <div onClick={toggleAllowSsoOnly} style={{ cursor: 'pointer' }}>
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={!organization.allowSsoOnly}
            />
            Allow Login without SSO
          </div>

          {/* Benchmark Checkbox */}
          <div onClick={toggleIsBenchmarkOrg} style={{ cursor: 'pointer' }}>
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={organization.isBenchmarkOrg || false}
            />
            Benchmark
          </div>

          {/* Benchmark Groups */}
          {organization.isBenchmarkOrg && (
            <div>
              <br />
              <div>Benchmark Groups:</div>
              {organization.benchmarkGroups &&
              organization.benchmarkGroups.length > 0 ? (
                organization.benchmarkGroups.map(benchmarkGroup => (
                  <div key={benchmarkGroup.id}>
                    <Button
                      className={classes.deleteButton}
                      onClick={() => handleRemoveBenchmark(benchmarkGroup)}
                    >
                      x
                    </Button>
                    {benchmarkGroup.groupName}
                  </div>
                ))
              ) : (
                <div>No benchmark groups added yet.</div>
              )}
              <FormControl className={classes.textField} margin="normal">
                <InputLabel shrink htmlFor="benchmark-group-select">
                  Available Benchmark Groups
                </InputLabel>
                <NativeSelect
                  value=""
                  onChange={handleAddBenchmark}
                  input={<Input id="benchmark-group-select" />}
                >
                  <option value=""></option>
                  {availableBenchmarkGroups
                    .filter(
                      bg =>
                        !organization.benchmarkGroups?.some(
                          selected => selected.id === bg.id
                        )
                    )
                    .map(benchmarkGroup => (
                      <option key={benchmarkGroup.id} value={benchmarkGroup.id}>
                        {benchmarkGroup.groupName}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
              <br />
              <br />
            </div>
          )}

          {/* Oraganization uses Athena */}
          <div onClick={toggleOrgUsesAthena} style={{ cursor: 'pointer' }}>
            <Checkbox
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={organization.orgUsesAthena || false}
            />
            Organization Uses Athena
          </div>

          {/* Only for exsiting organizations - not displayed on creation */}
          {!isCreatingNewOrganization && (
            <div>
              {/* Goals */}
              <div>
                <TextField
                  label="Patient Minutes In Room"
                  className={classes.textField}
                  value={organization.goal_PatientMinutesInRoom}
                  onChange={handleGoalChange('goal_PatientMinutesInRoom')}
                  margin="normal"
                  type="number"
                  inputProps={{ step: 'any' }}
                />
                <br />
                <TextField
                  label="Percent Time With Staff"
                  className={classes.textField}
                  value={organization.goal_PercentTimeWithStaff}
                  onChange={handleGoalChange('goal_PercentTimeWithStaff')}
                  margin="normal"
                  type="number"
                  inputProps={{ step: 'any' }}
                />
                <br />
                <TextField
                  label="Visits Per Provider Hour"
                  className={classes.textField}
                  value={organization.goal_VisitsPerProviderHour}
                  onChange={handleGoalChange('goal_VisitsPerProviderHour')}
                  margin="normal"
                  type="number"
                  inputProps={{ step: 'any' }}
                />
                <br />
                <TextField
                  label="Patient Alone Time"
                  className={classes.textField}
                  value={organization.goal_PatientAloneTime}
                  onChange={handleGoalChange('goal_PatientAloneTime')}
                  margin="normal"
                  type="number"
                  inputProps={{ step: 'any' }}
                />
                <br />
              </div>

              {/* Domains */}
              <div>
                <div
                  style={{
                    marginLeft: 8,
                    marginRight: 8,
                    marginTop: 16,
                    marginBottom: 8,
                  }}
                >
                  <span
                    style={{
                      fontSize: 13,
                      color: '#757575',
                      fontWeight: 400,
                    }}
                  >
                    Allowed Email Domains
                  </span>
                  {organization.allowedEmailDomains?.length > 0 ? (
                    <div>
                      {organization.allowedEmailDomains.map(
                        (domain: string, index: number) => (
                          <div key={index}>
                            {domain}
                            <strong
                              onClick={() => handleDeleteDomain(domain)}
                              style={{
                                margin: 'auto',
                                color: 'red',
                                float: 'right',
                                cursor: 'pointer',
                              }}
                            >
                              X
                            </strong>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div>All domains are currently allowed.</div>
                  )}
                </div>
                <TextField
                  label="New Email Domain"
                  className={classes.textField}
                  value={newEmailDomain}
                  onChange={handleNewEmailDomain}
                  margin="none"
                />
                <br />
                <div>
                  <Button
                    style={{ marginTop: 10 }}
                    onClick={handleAddDomain}
                    variant="contained"
                  >
                    Add Allowed Domain
                  </Button>
                </div>
              </div>

              <br />

              {/* Workflows */}
              <div>
                <div
                  style={{
                    marginLeft: 8,
                    marginRight: 8,
                    marginTop: 16,
                    marginBottom: 8,
                  }}
                >
                  <span style={{ fontSize: 13, color: '#757575' }}>
                    Workflows
                  </span>
                  <div>
                    {organization.orgDocumentation && (
                      <div>
                        {organization.orgDocumentation.length === 0 && (
                          <div>No workflows to show...</div>
                        )}
                        {organization.orgDocumentation.map(
                          (workflow: IWorkflow, index: number) => (
                            <div
                              key={index}
                              style={index !== 0 ? { marginTop: 10 } : {}}
                            >
                              {index !== 0 && <hr />}
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                }}
                              >
                                <IconButton
                                  onClick={() => moveWorkflow(index, index - 1)}
                                  size="small"
                                >
                                  <ArrowUp fontSize="inherit" />
                                </IconButton>
                                <TextField
                                  label="Name"
                                  margin="none"
                                  value={workflow.name}
                                  onChange={renameWorkflow(index, 'name')}
                                />
                                <IconButton
                                  onClick={() => deleteWorkflow(index)}
                                  style={{ color: 'red' }}
                                >
                                  <CloseButton />
                                </IconButton>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  width: '17em',
                                  overflowY: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <IconButton
                                  onClick={() => moveWorkflow(index, index + 1)}
                                  size="small"
                                >
                                  <ArrowDown fontSize="inherit" />
                                </IconButton>
                                <TextField
                                  label="URL"
                                  style={{ marginTop: 10 }}
                                  margin="none"
                                  value={workflow.url}
                                  onChange={renameWorkflow(index, 'url')}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <TextField
                  label="Workflow Name"
                  className={classes.textField}
                  value={newWorkflowName}
                  onChange={e => setNewWorkflowName(e.target.value)}
                  margin="none"
                />
                <br />
                <TextField
                  label="Workflow URL"
                  className={classes.textField}
                  value={newWorkflowUrl}
                  onChange={e => setNewWorkflowUrl(e.target.value)}
                  margin="none"
                />
                <br />
                <Button
                  style={{ marginTop: 10 }}
                  onClick={addWorkflow}
                  variant="contained"
                >
                  Add Workflow
                </Button>
              </div>
            </div>
          )}

          {hasModified && (
            <div className={classes.saveWarning}>Unsaved Changes!</div>
          )}
          <Button
            className={classes.submitButton}
            onClick={() => handleSavePressed()}
            color="primary"
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default OrganizationDrawer;
