import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../apiconfig';
import { IDevice } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useDeviceDetail = (input: {
  deviceId: string
  options?: {
    shouldFetch?: boolean;
  };
  onCompleted?: (device: IDevice) => void;
}): {
  device: IDevice | null;
  loading: boolean;
  error: string | null;
  refetch: (deviceId: string) => Promise<IDevice>;
} => {
  const { deviceId, options, onCompleted = () => {} } = input;

  const [device, setDevice] = useState<IDevice | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const shouldFetch = options?.shouldFetch ?? true;

  const fetchDevice = useCallback((deviceId: string): Promise<IDevice> => {

    if (!deviceId) {
      console.log('Device ID is empty. Skipping fetch.');
      return Promise.resolve(null);
    }

    return new Promise((resolve, reject) => {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('token');

      let URL = API.REACT_APP_API_GETDEVICEDETAIL + deviceId;

      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      axios
        .get(URL, headers)
        .then(response => {
          setLoading(false);

          const device: IDevice = response?.data?.data;

          setDevice(device);
          onCompleted(device);
          resolve(device);
        })
        .catch(err => {
          handleError(err);
          setLoading(false);
          reject(err);
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      fetchDevice(deviceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  const handleError = (err: any) => {
    console.error('Error:', err);
    setError(
      'An error occurred while fetching device details. Please try again.'
    );
    setLoading(false);
    if (err.response?.status === 401) {
      checkRefreshToken();
    }
  };

  return {
    refetch: fetchDevice,
    device,
    loading,
    error,
  };
};
