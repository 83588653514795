import { ChangeEvent, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { canDo } from "../../../../utils/permissionCheck";

import { ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";

import { ISite, TAdminState } from "../../../../interfaces/interfaces";
import { useStyles, theme } from "./styles";
import { SITE_TEMPLATE } from "./constants";
import { useSites } from "../../../../hooks/useSites";
import SiteDrawer from "./SiteDrawer";

const Sites: React.FC = () => {
  const loggedInUserData = useSelector((state: { adminData: TAdminState }) => state.adminData?.loggedInUserData);

  const [hideInactiveSites, setHideInactiveSites] = useState(true);
  const [filterString, setFilterString] = useState("");
  const [sortDescending, setSortDescending] = useState(false);
  const [sortBy, setSortBy] = useState("siteName");
  const [siteToEdit, setSiteToEdit] = useState<ISite | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCreatingNewSite, setIsCreatingNewSite] = useState(false);

  const classes = useStyles();

  const { sites, refreshSites } = useSites(loggedInUserData.mongoOrganizationId);

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  const toggleHideInactiveSites = () => {
    setHideInactiveSites((prevState) => !prevState);
  };

  const handleSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending((prevSortDescending) => !prevSortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const handleCreateNewSite = () => {
    setSiteToEdit({ ...SITE_TEMPLATE });
    setIsCreatingNewSite(true);
    setIsDrawerOpen(true);
  };

  const handleEditSite = (site: ISite) => {
    setSiteToEdit({ ...site });
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setIsCreatingNewSite(false);
    refreshSites(loggedInUserData.mongoOrganizationId);
  };

  const filteredAndSortedSites = useMemo(() => {
    if (!sites) return [];

    return sites
      .filter((site) => {
        const matchesFilter = site.siteName.toUpperCase().includes(filterString.toUpperCase());
        const isActiveOrShowAll = !hideInactiveSites || site.isActive;
        return matchesFilter && isActiveOrShowAll;
      })
      .sort((a, b) => {
        const aValue = a[sortBy as keyof ISite];
        const bValue = b[sortBy as keyof ISite];

        // Handle undefined cases
        if (aValue === undefined && bValue === undefined) return 0;
        if (aValue === undefined) return sortDescending ? 1 : -1;
        if (bValue === undefined) return sortDescending ? -1 : 1;

        // Sort strings case-insensitively
        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortDescending ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        }

        // Sort numbers and booleans
        if (aValue < bValue) return sortDescending ? 1 : -1;
        if (aValue > bValue) return sortDescending ? -1 : 1;
        return 0;
      });
  }, [sites, filterString, hideInactiveSites, sortBy, sortDescending]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="h4" gutterBottom component="h2">
          Sites
          {canDo(["SyncAdmin"], loggedInUserData) && (
            <Tooltip title="Add" aria-label="Add">
              <Fab className={classes.newSiteButton} onClick={handleCreateNewSite}>
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
        </Typography>

        <TextField label="Search" onChange={handleFilter} />
        <br />
        <div onClick={toggleHideInactiveSites} style={{ cursor: "pointer" }}>
          <Checkbox
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={hideInactiveSites}
          />
          Hide Inactive Sites
        </div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={classes.sortSelect} onClick={() => handleSortBy("siteName")}>
                  {sortBy === "siteName" ? <div>Site {sortDescending ? <>▼</> : <>▲</>}</div> : <div>Site</div>}
                </TableCell>
                <TableCell className={classes.sortSelect} onClick={() => handleSortBy("isActive")}>
                  {sortBy === "isActive" ? <div>Is Active {sortDescending ? <>▼</> : <>▲</>}</div> : <div>Is Active</div>}
                </TableCell>
                <TableCell className={classes.sortSelect} onClick={() => handleSortBy("isLocked")}>
                  {sortBy === "isLocked" ? <div>Is Locked {sortDescending ? <>▼</> : <>▲</>}</div> : <div>Is Locked</div>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedSites.map((site) => (
                <TableRow key={site.id} className={classes.tableRow} onClick={() => handleEditSite(site)}>
                  <TableCell className={classes.tableCell}>{site.siteName}</TableCell>
                  <TableCell className={classes.tableCell}>{site.isActive && <CheckIcon />}</TableCell>
                  <TableCell className={classes.tableCell}>{site.isLocked && <CheckIcon />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <SiteDrawer loggedInUserData={loggedInUserData} siteToEdit={siteToEdit} open={isDrawerOpen} onClose={handleCloseDrawer} isCreatingNewSite={isCreatingNewSite} />
      </ThemeProvider>
    </div>
  );
};

export default Sites;
