import axios from 'axios';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { API } from '../apiconfig';
import { IApp } from '../interfaces/interfaces';
import { checkRefreshToken } from '../utils/utils';

export const useApps = (): {
  apps: IApp[];
  activeApps: IApp[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<IApp[]>;
} => {
  const [apps, setApps] = useState<IApp[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchApps = useCallback(async (): Promise<IApp[]> => {
    setIsLoading(true);
    setError(null);

    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_APK;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    return axios
      .get(URL, headers)
      .then(response => {
        setIsLoading(false);
        const apps: IApp[] = response.data ?? [];
        setApps(apps);

        return apps;
      })
      .catch(err => {
        console.log('Error: ', err);
        setIsLoading(false);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }

        return [] as IApp[];
      });
  }, []);

  useEffect(() => {
    fetchApps();

    // eslint-disable-next-line
  }, []);

  const refetch = useCallback((): Promise<IApp[]> => {
    return fetchApps();
  }, [fetchApps]);

  const activeApps = useMemo(() => {
    return apps.filter(app => app.isActive);
  }, [apps]);

  return {
    apps,
    activeApps,
    isLoading,
    error,
    refetch,
  };
};
