import { Modal, Typography } from '@material-ui/core';

import { FC } from 'react';
import { useNotify } from '../../../../../common/notify/useNotify';
import { useSubscription } from '../../../../../hooks/useSubscription';
import
  {
    IAction,
    IDepartment,
    IProvider,
    IUser,
  } from '../../../../../interfaces/interfaces';
import { useStyles } from '../styles';
import NotificationNewForm, { TFormSchema } from './NotificationNewForm';

type TProps = {
  open: boolean;
  onClose: () => void;
  orgId: string;
  distinctOrgActions: IAction[];
  onSuccess?: () => void;
  loggedInUser: IUser;
};

const NotificationNewModal: FC<TProps> = props => {
  const {
    open,
    onClose,
    orgId,
    distinctOrgActions = [],
    onSuccess = () => {},
    loggedInUser,
  } = props;

  const { notify } = useNotify();

  const { update, updating: submitting } = useSubscription({ orgId });
  const classes = useStyles();

  const onSubmit = async (values: Partial<TFormSchema>) => {
    // let userCopy = JSON.parse(JSON.stringify(loggedInUserData));
    // let user = userCopy;

    console.log('onSubmit', { values });
    const { selectedActions, notificationFor } = values;

    const selectedProviders = values.selectedProviders as IProvider[];
    const selectedDepartments = values.selectedDepartments as IDepartment[];

    const isForProvider = notificationFor === 'provider';

    let userToModify = { ...loggedInUser };
    console.log('userToModify', userToModify);

    let newActions: {
      actionId: string;
      deptId: string;
      providerId: string;
      messageType: string;
    }[] = [];

    selectedActions.forEach((action, index) => {
      // todo: add if preppedProviders
      // this.state.preppedProviders.forEach

      if (isForProvider) {
        // Provider

        for (const selectedProvider of selectedProviders) {
          newActions.push({
            actionId: action.id,
            deptId: null,
            providerId: selectedProvider.id,
            messageType: '',
          });
        }
      } else {
        for (const selectedDepartment of selectedDepartments) {
          newActions.push({
            actionId: action.id,
            deptId: selectedDepartment.id,
            providerId: null,
            messageType: '',
          });
        }
      }
    });

    console.log('new actions', newActions);
    newActions.forEach((newAction: any) => {
      userToModify.subscribedActions.push(newAction);
    });

    console.log('updated user', userToModify);

    // console.log("Send", user);

    return update(userToModify).then(() => {
      onSuccess();
      onClose();
      notify({
        message: 'Successfully created and saved alert!',
        variant: 'success',
      });
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.newAlertModal}>
        <Typography variant="h5" gutterBottom component="h2">
          New Notification
        </Typography>

        <NotificationNewForm
          submitting={submitting}
          onSubmit={onSubmit}
          distinctOrgActions={distinctOrgActions}
          initialValues={{
            activeStep: 0,
            selectedProviders: [],
            selectedDepartments: [],
            selectedActions: [],
            orgId,
            notificationFor: 'provider',
          }}
        />

        {/* <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <div>
                    {index === 0 ? (
                      <div>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="Notification for Rooms"
                            name="Notification for Rooms"
                            value={providerAlertValue}
                            onChange={onHandleRadioCheck}
                          >
                            <FormControlLabel
                              value="Assigned to a specific provider"
                              control={<Radio color="primary" />}
                              label="User"
                            />
                            <FormControlLabel
                              value="Assigned to a specific department"
                              control={<Radio color="primary" />}
                              label="Departments"
                            />
                          </RadioGroup>
                        </FormControl>
                        {providerAlert ? (
                          <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {preppedProviders.map((provider: IProvider) => (
                                <div key={provider.id}>
                                  <Chip
                                    style={{ margin: '5px 5px 5px 0px' }}
                                    label={provider.fullName}
                                    onDelete={() =>
                                      onRemovePreppedProvider(provider)
                                    }
                                  />
                                </div>
                              ))}
                            </div>

                            <Autocomplete
                              id="providerInput"
                              key={providerSearchKey}
                              autoHighlight
                              options={
                                providerAssignmentUsers?.allProviders
                                  ? providerAssignmentUsers?.allProviders.filter(
                                      (provider: IProvider) => {
                                        let found = false;
                                        preppedProviders.forEach(
                                          (preppedProvider: IProvider) => {
                                            if (
                                              provider.id === preppedProvider.id
                                            ) {
                                              found = true;
                                            }
                                          }
                                        );
                                        return !found;
                                      }
                                    )
                                  : []
                              }
                              getOptionLabel={(provider: any) =>
                                provider.fullName
                              }
                              onChange={(event: any, provider: any) => {
                                onSelectProvider(provider);

                                new Promise(resolve => {
                                  setProviderSearchKey(providerSearchKey + 1);

                                  resolve(null);
                                }).then(() => {
                                  providerInputRef.focus();
                                });
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  autoFocus
                                  inputRef={input => {
                                    providerInputRef = input;
                                  }}
                                  label="Select User"
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                maxHeight: '25vh',
                              }}
                            >
                              {preppedDepartments.map(
                                (department: IDepartment) => (
                                  <div key={department.id}>
                                    <Chip
                                      style={{ margin: '5px 5px 5px 0px' }}
                                      label={
                                        department.siteName +
                                        ' ' +
                                        department.deptName
                                      }
                                      onDelete={() =>
                                        onRemovePreppedDepartment(department)
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </div>

                            <Autocomplete
                              id="site-search"
                              options={activeSites}
                              getOptionLabel={(site: any) => site.siteName}
                              onChange={(event: any, site: any) => {
                                if (site) {
                                  onSelectSite(site);
                                }
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  autoFocus
                                  label="Search Site"
                                />
                              )}
                            />

                            {selectedSiteFilter ? (
                              <Autocomplete
                                id="dept-search"
                                inputref={this.departmentInput}
                                key={deptSearchKey}
                                options={
                                  activeDepartments.filter(
                                    (department: IDepartment) => {
                                      let found = false;
                                      preppedDepartments.forEach(
                                        (preppedDepartment: IDepartment) => {
                                          if (
                                            department.id ===
                                            preppedDepartment.id
                                          ) {
                                            found = true;
                                          }
                                        }
                                      );
                                      return !found;
                                    }
                                  )
                                }
                                getOptionLabel={(department: any) => {
                                  return (
                                    department.siteName +
                                    ' ' +
                                    department.deptName
                                  );
                                }}
                                onChange={(event: any, dept: any) => {
                                  if (dept) {
                                    onSelectDepartment(dept);

                                    new Promise(resolve => {
                                      setDeptSearchKey(deptSearchKey + 1);

                                      resolve(null);
                                    }).then(() => {
                                      departmentInputRef.focus();
                                    });
                                  }
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    inputRef={input => {
                                      departmentInputRef = input;
                                    }}
                                    label="Search Department"
                                  />
                                )}
                              />
                            ) : null}

                            <Button
                              disabled={!selectedSiteFilter}
                              onClick={() =>
                                onSelectAllDepartments(activeDepartments)
                              }
                              color="primary"
                              variant="outlined"
                              style={{ marginTop: 10, marginBottom: 20 }}
                            >
                              Select All
                            </Button>
                          </>
                        )}
                        <div style={{ marginTop: 20 }}>
                          <Button
                            disabled={
                              preppedDepartments.length === 0 &&
                              preppedProviders.length === 0
                            }
                            variant="contained"
                            color="primary"
                            onClick={onHandleNext}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    ) : null}

                    {index === 1 ? (
                      <div>
                        <div
                          className={classes.actionPopover}
                          style={{
                            maxHeight: '50vh',
                            overflow: 'scroll',
                            overflowX: 'hidden',
                          }}
                        >
                          {selectedDeptForSMS
                            ? selectedDeptForSMS.actionsList.map(
                                (action: IAction) => (
                                  <div key={action.id}>
                                    {action.id !==
                                    '5f6fb35be0a5f22bf0d6a122' ? (
                                      <img
                                        className={
                                          selectedActions.indexOf(action) !== -1
                                            ? classes.selectedPopOverActions
                                            : classes.popOverActions
                                        }
                                        src={action.actionImageUrl}
                                        
                                        onClick={() => onSelectAction(action)}
                                        alt=""
                                      />
                                    ) : null}
                                  </div>
                                )
                              )
                            : distinctOrgActions.map((action: IAction) => (
                                <div key={action.id}>
                                  <img
                                    className={
                                      selectedActions.indexOf(action) !== -1
                                        ? classes.selectedPopOverActions
                                        : classes.popOverActions
                                    }
                                    src={action.actionImageUrl}
                                    onClick={() => onSelectAction(action)}
                                    alt=""
                                  />
                                </div>
                              ))}
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <Button
                            variant="contained"
                            onClick={onHandleBack}
                            style={{ marginRight: 20 }}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onCreateAlert}
                            disabled={selectedActions.length <= 0}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper> */}

      </div>
    </Modal>
  );
};

export default NotificationNewModal;
