import LogRocket from "logrocket";
import ReactGA from "react-ga";
let metaData = require("./metadata.json");


if (
  // window.location.hostname === "localhost" ||
  window.location.hostname === "app.synctimes.com" ||
  window.location.hostname === "dev.synctimes.com" ||
  window.location.hostname === "cesium-beta.azurewebsites.net" ||
  window.location.hostname === "cesium.azurewebsites.net" || true
) {
  // Don't init logRocket if /splash or /patientcontent
  let runLogRocket = true;

  if (window.location.pathname.includes("splash")) {
    runLogRocket = false;
  }

  if (window.location.pathname.includes("patientcontent")) {
    runLogRocket = false;
  }

  // if localstorage for isdemoorg is true, don't run logrocket
  const isCustomerOrgString = localStorage.getItem("isCustomerOrg");
  const isUnknownOrg = isCustomerOrgString === null;
  console.log("DEMO ORG isUnknownOrg", isUnknownOrg);
  const isDemoOrg = isCustomerOrgString === "false";
  const email = localStorage.getItem("email");
  const isSyncTimesEmail = email?.includes("synctimes.com") ?? false;

  if (!isUnknownOrg && ( isDemoOrg || isSyncTimesEmail )) {
    runLogRocket = false;
    console.log("DEMO ORG, NOT RUNNING LR");
  } else {
    console.log("NOT DEMO ORG, RUNNING LR");
  }

  if (runLogRocket) {
    console.log("RUNNING LR");
    LogRocket.init("fpydn3/synctimes", {
      release: metaData.build,
      console: {
        shouldAggregateConsoleErrors: true,
      },
      network: {
        requestSanitizer: (request) => {
          if (request.body) {
            // console.log("req body", request.body);
            if (request.body.includes("password") || request.body.includes("surveyQuestionResponse")) {
              // console.log("EXCLUDE REQUEST BODY");
              request.body = "";
            }
            return request;
          }
          return request;
        },
      },
    });
  }
}

//Initialize Google Analytics
ReactGA.initialize("UA-141028460-2", {
  // debug: true,
  titleCase: true,
});
ReactGA.pageview(window.location.pathname + window.location.search);