import React, { Component } from 'react';
import { API } from '../../../apiconfig';
import { connect } from 'react-redux';
import { canDo } from '../../../utils/permissionCheck';

import {
  IUser,
  IOrganization,
  IDepartment,
} from '../../../interfaces/interfaces';
import { fetchOrganizationDepartments } from '../../../actions/adminActions';

import Fuse from 'fuse.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TvIcon from '@material-ui/icons/Tv';
import BarChartIcon from '@material-ui/icons/BarChart';

// import CampusIcon from '@material-ui/icons/Business';
// import SiteIcon from "@material-ui/icons/LocationCity";
// import RoomsIcon from "@material-ui/icons/MeetingRoom";
// import DevicesIcon from "@material-ui/icons/Devices";
// import AndroidIcon from "@material-ui/icons/Android";
// import ScannerIcon from "@material-ui/icons/Scanner";
// import PeopleIcon from "@material-ui/icons/People";
// import ListIcon from "@material-ui/icons/List";
// import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
// import AssignmentIcon from "@material-ui/icons/Assignment";
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import PermMediaIcon from "@material-ui/icons/PermMedia";

const styles: {} = (theme: any) => ({
  searchResultItem: {
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
});

interface AppSearchProps {
  showSearch: boolean;
  closeSearch: any;
  classes: any;
  searchData: any;
  loggedInUserData: IUser;
  organization: IOrganization;
  openPowerReport: any;
  // @ts-ignore
  fetchOrganizationDepartments: (orgId: string) => void;
}

interface AppSearchState {
  searchString: string;
}

class AppSearch extends Component<AppSearchProps, AppSearchState> {
  constructor(props: AppSearchProps) {
    super(props);
    this.state = {
      searchString: '',
    };
  }

  componentDidUpdate(
    prevProps: Readonly<AppSearchProps>,
    prevState: Readonly<AppSearchState>,
    snapshot?: any
  ): void {
    if (prevProps.organization.id !== this.props.organization.id) {
      // console.log("fetching org depts!")
      this.props.fetchOrganizationDepartments(this.props.organization.id);
    }
  }

  selectSearchResult = (searchItem: any) => {
    let token = localStorage.getItem('token');

    let payload = {
      userId: this.props.loggedInUserData.id,
      orgId: this.props.organization.id,
      toStr: searchItem.url,
      fromStr: window.location.href,
      searchStr: [this.state.searchString],
      actionName: searchItem.name,
    };

    console.log('PAYLOAD', payload);

    let URL = API.REACT_APP_API_LOGSEARCHREQUEST;

    fetch(URL, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('response', response);
      })
      .catch(err => {
        console.log('err', err);
      });

    // console.log("search item", searchItem);
    if (searchItem.url) {
      window.location.replace(searchItem.url);
      // window.open(searchItem.url, '_blank');
    }

    if (searchItem.type === 'Report') {
      this.props.openPowerReport();
    }

    this.setState({
      searchString: '',
    });

    this.props.closeSearch();
  };

  handleCloseSearch = () => {
    let token = localStorage.getItem('token');

    let payload = {
      userId: this.props.loggedInUserData.id,
      orgId: this.props.organization.id,
      toStr: '',
      fromStr: window.location.href,
      searchStr: [this.state.searchString],
      actionName: '',
    };

    console.log('PAYLOAD', payload);

    let URL = API.REACT_APP_API_LOGSEARCHREQUEST;

    fetch(URL, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        console.log('response', response);
      })
      .catch(err => {
        console.log('err', err);
      });

    this.setState({
      searchString: '',
    });

    this.props.closeSearch();
  };

  checkKeyDown = (e: any, searchResult: any) => {
    if (e.keyCode === 13 && searchResult.length > 0) {
      // console.log("enter hit", searchResult[0])
      this.selectSearchResult(searchResult[0]);
    }
    // if (e.keycode === 40 && searchResult.length > 0) {
    //     // console.log("down arrow hit")
    // }
  };

  render() {
    const { classes } = this.props;

    let searchIndex = [
      ...this.props.searchData,
      {
        name: 'Getting Started',
        type: 'Question',
        url: 'https://help.synctimes.com/user-guide/how-tos/getting-started',
        description: 'help getting started',
      },
      {
        name: 'How to - Admin Console',
        type: 'Question',
        url: 'https://help.synctimes.com/user-guide/how-tos/admin-console',
        description: 'help admin console',
      },
      {
        name: 'How to - Flowstation',
        type: 'Question',
        url: 'https://help.synctimes.com/user-guide/how-tos/flowstations',
        description: 'help flowstation',
      },
      {
        name: 'How to - Notifications',
        type: 'Question',
        url: 'https://help.synctimes.com/user-guide/how-tos/notifications',
        description: 'help notifications',
      },
      {
        name: 'How to - Analytics',
        type: 'Question',
        url: 'https://help.synctimes.com/user-guide/how-tos/analytics',
        description: 'help analytics',
      },
      {
        name: 'How to - Training',
        type: 'Question',
        url: 'https://help.synctimes.com/user-guide/how-tos/training',
        description: 'training help',
      },
      {
        name: 'How to - Mobile Apps',
        type: 'Question',
        url: 'https://help.synctimes.com/user-guide/how-tos/mobile-apps',
        description: 'Install mobile app android ios',
      },
      {
        name: 'Virtual/Live Training',
        type: 'Question',
        url: 'https://www.synctimes.com/training',
        description: 'Reporting How To Training Live Virtual',
      },
      {
        name: 'Self Pace Training',
        type: 'Question',
        url: 'https://help.synctimes.com',
        description: 'Self Reporting How To Training Live Virtual',
      },
      {
        name: 'Open Reporting',
        type: 'Report',
        url: null,
        description: 'Power BI Reporting Utilization Productivity Patient Flow',
      },
    ];

    if (canDo(['View Sites'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - Sites',
        type: 'Link',
        icon: 'Site',
        url: '/admin?sites',
        description: 'Create Sites Manage Sites',
      });
    }

    if (canDo(['View Rooms'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - Rooms',
        type: 'Link',
        icon: 'Room',
        url: '/admin?rooms',
        description:
          'Create Rooms View Rooms Edit Rooms Delete Rooms Manage Rooms',
      });
    }

    if (canDo(['View Users'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - Users',
        type: 'Link',
        icon: 'User',
        url: '/admin?users',
        description:
          'Create Users View Users Edit Users Delete Users Manage Users',
      });
    }

    if (canDo(['View EHR Mapping'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - EHR Mapping',
        type: 'Link',
        icon: 'EHR',
        url: '/admin?ehrmapping',
        description: 'Athena EHR Mapping',
      });
    }

    if (canDo(['View Equipment'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - Equipment',
        type: 'Link',
        icon: 'Equipment',
        url: '/admin?equipment',
        description:
          'Badges Equipment Create Equipment View Equipment Edit Equipment Delete Equipment Manage Equipment',
      });
    }

    if (canDo(['Tablets'], this.props.loggedInUserData)) {
      searchIndex.push(
        {
          name: 'Admin - Devices',
          type: 'Link',
          icon: 'Device',
          url: '/admin?devices',
          description:
            'Devices Create Devices View Devices Edit Devices Delete Devices Manage Devices',
        },
        {
          name: 'Admin - Applications',
          type: 'Link',
          icon: 'Application',
          url: '/admin?applications',
          description: 'Applications Android Apps Tablet Apps',
        }
      );
    }

    if (canDo(['View Displays'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - Displays',
        type: 'Link',
        icon: 'Display',
        url: '/admin?displays',
        description: 'Tablet Displays Manage Tablets Displays',
      });
    }

    if (canDo(['View Service Lines'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - Service Lines',
        type: 'Link',
        icon: 'ServiceLine',
        url: '/admin?servicelines',
        description:
          'User Roles Manage Roles Create Roles Edit Roles Delete Roles',
      });
    }

    if (canDo(['View Integrations'], this.props.loggedInUserData)) {
      searchIndex.push({
        name: 'Admin - Integrations',
        type: 'Link',
        icon: 'Integration',
        url: '/admin?integrations',
        description:
          'Integrations Manage Integrations Create Integrations Add Integrations Edit Integrations Delete Integrations',
      });
    }

    if (canDo(['SyncAdmin'], this.props.loggedInUserData)) {
      searchIndex.push(
        {
          name: 'Admin - Organizations',
          type: 'Link',
          icon: 'Organization',
          url: '/admin?organizations',
          description:
            'Create Organizations Manage Organizations View Organizations Edit Organizations Delete Organizations',
        },
        {
          name: 'Admin - Campuses',
          type: 'Link',
          icon: 'Campus',
          url: '/admin?campuses',
          description: 'Campuses Create Campus',
        },
        {
          name: 'Admin - Media',
          type: 'Link',
          icon: 'Media',
          url: '/admin?media',
          description: 'New Action Media Sounds New Sound',
        },
        {
          name: 'Admin - Patient Content',
          type: 'Link',
          icon: 'PatientContent',
          url: '/admin?surveys',
          description:
            'Patient Content Surveys Create Survey View Survey Edit Survey Delete Survey Manage Survey',
        }
      );
    }

    let preFilterDeptOptions: IDepartment[] = [];
    //@ts-ignore
    this.props.organizationDepartments?.forEach((dept: IDepartment) => {
      if (dept.isActive) {
        preFilterDeptOptions.push(dept);
      }
    });
    // alphabetize options
    preFilterDeptOptions.sort((a, b) => {
      if (a.siteName + a.deptName < b.siteName + b.deptName) {
        return -1;
      }
      if (a.siteName + a.deptName > b.siteName + b.deptName) {
        return 1;
      }
      return 0;
    });

    let deptSearchIndex: any = [];

    preFilterDeptOptions.forEach(dept => {
      if (dept.isActive) {
        deptSearchIndex.push({
          name: 'Flowstation - ' + dept.siteName + ' ' + dept.deptName,
          type: 'Flowstation',
          url: '/?groupId=' + dept.id,
          description: '',
        });
      }
    });

    // console.log("Dept search index", deptSearchIndex)

    searchIndex = [...searchIndex, ...deptSearchIndex];

    let fuseOptions = {
      shouldSort: true,
      keys: ['name', 'description'],
    };
    let fuse = new Fuse(searchIndex, fuseOptions);
    let searchResult = fuse.search(this.state.searchString) ?? [];
    searchResult = searchResult.map(result => result.item);

    return (
      <div>
        {this.props.showSearch ? (
          <ClickAwayListener onClickAway={() => this.handleCloseSearch()}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 1000,
                transform: 'translate(-50%, -50%)',
              }}
            >
              <div style={{ width: 400 }}>
                <input
                  autoFocus
                  onKeyDown={e => this.checkKeyDown(e, searchResult)}
                  placeholder="What are you searching for?"
                  style={{
                    width: '100%',
                    backgroundColor: '#282c33',
                    color: '#a9afb8',
                    height: 50,
                    borderRadius: 10,
                    fontSize: 18,
                    padding: '0px 16px',
                  }}
                  value={this.state.searchString}
                  onChange={e =>
                    this.setState({ searchString: e.target.value })
                  }
                />
              </div>
              <div
                style={{
                  marginBottom: 10,
                  position: 'absolute',
                  backgroundColor: 'white',
                  width: 400,
                  marginLeft: 16,
                  borderRadius: 10,
                  color: '#282C33',
                  border: '1px solid #f1f1f1',
                  boxShadow: '0px 0px 5px lightgray',
                  maxHeight: '40vh',
                  overflow: 'auto',
                }}
              >
                {searchResult.map((item: any, i: number) => (
                  <div
                    key={i}
                    className={classes.searchResultItem}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {item.type === 'Question' ? (
                      <HelpIcon style={{ paddingLeft: 10 }} />
                    ) : null}
                    {item.type === 'Link' ? (
                      <ArrowForwardIcon style={{ paddingLeft: 10 }} />
                    ) : null}
                    {item.type === 'Flowstation' ? (
                      <TvIcon style={{ paddingLeft: 10 }} />
                    ) : null}
                    {item.type === 'Report' ? (
                      <BarChartIcon style={{ paddingLeft: 10 }} />
                    ) : null}

                    {/* {item.icon === "Question" ? <HelpIcon /> : null}
                                    {item.icon === "Link" ? <ArrowForwardIcon /> : null}
                                    {item.icon === "Flowstation" ? <TvIcon /> : null}
                                    {item.icon === "Site" ? <SiteIcon /> : null}
                                    {item.icon === "Room" ? <RoomsIcon /> : null}
                                    {item.icon === "User" ? <PeopleIcon /> : null}
                                    {item.icon === "EHR" ? <ListIcon /> : null}
                                    {item.icon === "Equipment" ? <ScannerIcon /> : null}
                                    {item.icon === "Device" ? <DevicesIcon /> : null}
                                    {item.icon === "Application" ? <AndroidIcon /> : null}
                                    {item.icon === "Display" ? <DevicesIcon /> : null}
                                    {item.icon === "ServiceLine" ? <AssignmentIndIcon /> : null}
                                    {item.icon === "Integration" ? <AssignmentIndIcon /> : null}
                                    {item.icon === "Organization" ? <DashboardIcon /> : null}
                                    {item.icon === "Campus" ? <CampusIcon /> : null}
                                    {item.icon === "Media" ? <PermMediaIcon /> : null}
                                    {item.icon === "PatientContent" ? <AssignmentIcon /> : null} */}
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '8px 10px',
                        width: '100%',
                      }}
                      onClick={() => this.selectSearchResult(item)}
                    >
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ClickAwayListener>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: { adminData: any }) => ({
  loggedInUserData: state.adminData.loggedInUserData,
  organization: state.adminData.organization,
  organizationDepartments: state.adminData.organizationDepartments,
});

export default connect(mapStateToProps, { fetchOrganizationDepartments })(
  withStyles(styles)(AppSearch)
);
