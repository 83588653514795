import React, { Component } from "react";
import { API } from "../../../apiconfig";
import axios from "axios";
import moment from "moment-timezone";

import { checkRefreshToken } from "../../../utils/utils";

import { canDo } from "../../../utils/permissionCheck";

// Material
import { withStyles, createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import BatteryAlert from "@material-ui/icons/BatteryAlert";
import Avatar from "@material-ui/core/Avatar";
import { IDepartment, IUser } from "../../../interfaces/interfaces";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      },
    },
  },
});

const styles: {} = (theme: any) => ({
  occupantsList: {
    alignSelf: "center",
    marginBottom: "auto",
    marginTop: "auto",
  },
  loginText: {
    height: 40,
    color: "#0096DB",
    cursor: "pointer",
  },
  tooltiptext: {
    display: "flex",
    borderRadius: 4,
    // textAlign: "center",
    color: "white",
    padding: 10,
    position: "absolute",
    marginTop: 5,
    backgroundColor: "#696969",
    zIndex: 10,
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
  providerIconContainer: {
    position: "relative",
  },
  lowBattery: {
    // backgroundColor: "red",
    right: 0,
    bottom: 0,
    color: "red",
    position: "absolute",
    width: 25,
    height: 30,
    // padding: 1,
    textAlign: "center",
    // fontSize: 20,
    zIndex: 9,
  },
  avatar: {
    backgroundColor: "#e0e0e0",
    width: 50,
    height: 50,
    marginRight: 5,
    cursor: "pointer",
  },
  avatarLarge: {
    // backgroundColor: "#e0e0e0",
    width: 100,
    height: 100,
    marginRight: 10,
  },
  userDetails: {
    margin: "auto",
    fontSize: 18,
  },
});

interface OccupantsListProps {
  disableCards: boolean;
  loggedInUserData: IUser;
  occupantData: any;
  groupData: IDepartment;
  classes: any;
  timeZone: any;
  highlightMember: any;
  serverTimeOffset: any;
}

interface OccupantsListState {
  showAlert: boolean;
  hover: boolean;
}

class OccupantsList extends Component<OccupantsListProps, OccupantsListState> {
  constructor(props: OccupantsListProps) {
    super(props);
    this.state = {
      showAlert: false,
      hover: false,
    };
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  clearUser = () => {
    if (!this.props.disableCards && canDo(["Update Flowstation"], this.props.loggedInUserData)) {
      if (this.props.groupData.orgId === this.props.loggedInUserData.mongoOrganizationId || canDo(["SyncAdmin"], this.props.loggedInUserData)) {
        // console.log("Clear Occupant!", this.props.occupantData);

        let token = localStorage.getItem("token");

        let data = {
          roomId: this.props.occupantData.mongoCurrentRoomId,
          userId: this.props.occupantData.id,
        };

        axios({
          method: "post",
          url: API.REACT_APP_API_CLEARUSER,
          data,
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then(function (response) {
            // console.log(response);
          })
          .catch(function (err) {
            console.log("Error removing occupant", err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      } else {
        this.setState({
          showAlert: true,
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    // console.log("occupant data", this.props.occupantData);

    let occupantTime = moment()
      .add("milliseconds", this.props.serverTimeOffset)
      .local()
      .diff(moment.utc(this.props.occupantData.currentRoomStartTime).local(), "minutes");

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.occupantsList}>
          {/* {this.state.fireLogin ? <Redirect to={{ pathname: "/login", url: { cameFrom: window.location.href } }} /> : <div />} */}

          <div>
            <div className={classes.providerIconContainer}>
              {/* {console.log("provider", this.props.occupantData.userTypeId === 4)} */}
              {this.props.occupantData.hasLowBatteryBadge ? <BatteryAlert className={classes.lowBattery + " flashing"} /> : null}
              <div className={this.props.highlightMember === this.props.occupantData.id ? "flashing" : ""}>
                <Avatar
                  style={
                    this.props.occupantData.userTypeId === 4
                      ? // Provider
                      { border: "5px solid #0096DB" }
                      : this.props.occupantData.userTypeId === 1
                        ? // Staff
                        { border: "5px solid #4CB946" }
                        : this.props.occupantData.userTypeId === 2
                          ? // Equipment
                          { border: "5px solid #db4500" }
                          : {}
                  }
                  className={classes.avatar}
                  onClick={this.clearUser}
                  src={this.props.occupantData.userImageUrl}
                  onMouseEnter={this.toggleHover}
                  onMouseLeave={this.toggleHover}
                />
              </div>
            </div>
            {this.state.hover ? (
              <div className={classes.tooltiptext}>
                <div>
                  <Avatar
                    style={
                      this.props.occupantData.userTypeId === 4
                        ? // Provider
                        { border: "5px solid #0096DB" }
                        : this.props.occupantData.userTypeId === 1
                          ? // Staff
                          { border: "5px solid #4CB946" }
                          : this.props.occupantData.userTypeId === 2
                            ? // Equipment
                            { border: "5px solid #db4500" }
                            : {}
                    }
                    onClick={this.clearUser}
                    className={classes.avatarLarge}
                    src={this.props.occupantData.userImageUrl}
                  />
                </div>
                <div className={classes.userDetails}>
                  {/* {console.log("OCCUPMANT", this.props.occupantData)} */}
                  <div style={{ fontSize: 24 }}>
                    <strong>{this.props.occupantData.fullName}</strong>
                  </div>
                  <hr />
                  <div>{this.props.occupantData.userEmail}</div>
                  {/* <br /> */}
                  In room for: {occupantTime > 99 ? "99+" : occupantTime} min
                  <br />
                  Last seen:{" "}
                  {moment()
                    .add("milliseconds", this.props.serverTimeOffset)
                    .tz(this.props.timeZone)
                    .local()
                    .diff(moment.utc(this.props.occupantData.lastLocationUTCTimeStamp).local(), "minutes")}{" "}
                  min
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(OccupantsList);
