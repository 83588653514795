import { PureComponent } from 'react';

import
  {
    IDepartment,
    IProvider,
    IRoom,
    IUser,
  } from '../../../../interfaces/interfaces';

import { checkRefreshToken } from '../../../../utils/utils';

import axios from 'axios';
import Fuse from 'fuse.js';
import moment from 'moment-timezone';
import { API } from '../../../../apiconfig';

import { allowSignalR } from '../../../../socket-manager';

import ErrorSnack from '../../../../utils/ErrorSnack';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';

//Material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import
  {
    createTheme,
    MuiThemeProvider,
    withStyles,
  } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import Paper from "@material-ui/core/Paper";
// import Popover from "@material-ui/core/Popover";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FakePatientGenerator from '../../../../common/FakePatientGenerator';
import { canDo } from '../../../../utils/permissionCheck';

const theme = createTheme({});

const styles: {} = (theme: any) => ({
  dialogPaper: { maxWidth: '350px' },
  cardHead: {
    color: 'black',
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 32,
    paddingLeft: 5,
    paddingRight: 5,
    borderBottom: '1px solid #ededed',
  },
  cardContent: {
    height: '100%',
    backgroundColor: 'white',
    paddingTop: 0,
  },
  deletePatient: {
    cursor: 'pointer',
    color: 'red',
    marginRight: 5,
  },
  textField: {
    // maxWidth: 230,
    maxWidth: 298,
    width: '100%',
    margin: 10,
  },
  dobExpireField: {
    marginTop: 0,
    // width: 182,
    width: '100%',
    maxWidth: 298,
    margin: 10,
  },
  submitButtonContainer: {
    width: '100%',
    textAlign: 'center',
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 10,
    width: '80%',
    backgroundColor: '#50b848',
    color: '#FFFFFF',
  },
  patientCard: {
    overflow: 'hidden',
    border: '1px solid lightgray',
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  waitingListTitle: {
    textAlign: 'center',
    fontSize: 24,
    margin: '20px 10px 10px 10px',
  },
  waitingListContent: {
    padding: 10,
  },
  providerSelectOption: {
    cursor: 'pointer',
    padding: '5px 10px 5px 10px',
  },
  dobTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    marginLeft: 8,
    marginRight: 8,
    paddingTop: 15,
    textAlign: 'left',
  },
  firstProvider: {
    fontWeight: 'bold',
  },
  providerPopper: {
    position: 'absolute',
    zIndex: 10,
    width: 250,
  },
});

interface NewPatientDialogProps {
  department: IDepartment;
  refetchFsData: () => void;
  closeDialog: () => void;
  autoAddPatientToRoom: boolean;
  cardData: IRoom | null;
  classes: any;
  open: boolean;
  loggedInUserData: IUser;
}

interface NewPatientDialogState {
  openPatientDialog: boolean;
  providerSearchResults: any[];
  providerPopper: boolean;
  providerSearch: string;
  firstNameError: boolean;
  lastNameError: boolean;
  mrnError: boolean;
  apptError: boolean;
  dateError: boolean;
  fieldError: boolean;
  hideFrontDesk: boolean;
  openAddPatientPopover: boolean;
  anchorEl: any;
  actionEl: any;
  patient: {
    firstName: string;
    lastName: string;
    MRN: string;
    provider: string;
    DOB: string;
    apptTime: string;
    ehrAppointmentTypeDesc: string;
  };
  allProviders: any[];
  patientHasBeenCreated: false;
}

class NewPatientDialog extends PureComponent<
  NewPatientDialogProps,
  NewPatientDialogState
> {
  constructor(props: NewPatientDialogProps) {
    super(props);
    this.state = {
      openPatientDialog: false,
      providerSearchResults: [],
      providerPopper: false,
      providerSearch: '',
      firstNameError: false,
      lastNameError: false,
      mrnError: false,
      apptError: false,
      dateError: false,
      hideFrontDesk: false,
      fieldError: false,
      anchorEl: null,
      actionEl: null,
      openAddPatientPopover: false,
      patient: {
        firstName: '',
        lastName: '',
        MRN: '',
        provider: 'empty',
        DOB: '',
        apptTime: '',
        ehrAppointmentTypeDesc: '',
      },
      allProviders: [],
      patientHasBeenCreated: false,
    };
  }

  componentDidMount() {
    let token = localStorage.getItem('token');
    let URL = API.REACT_APP_API_GETPROVIDERS + this.props.department.id;
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        // console.log("RESPONSE GET PROVIDERS", response);
        this.setState({
          allProviders: response.data.data.allProviders,
        });

        if (!allowSignalR) {
          this.props.refetchFsData();
        }

        // console.log('new state', this.state);
      })
      .catch(err => {
        console.log('Error fetching providers: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  }

  getPatientWaitTime(patientWaitTime: any) {
    if (
      moment().local().diff(moment.utc(patientWaitTime).local(), 'minutes') > 99
    ) {
      return '99+';
    } else {
      return moment()
        .local()
        .diff(moment.utc(patientWaitTime).local(), 'minutes');
    }
  }

  deletePatient = (patientId: string) => {
    let data = {
      id: patientId,
      deptId: this.props.department.id,
    };

    let token = localStorage.getItem('token');
    axios({
      method: 'post',
      url: API.REACT_APP_API_DELETEPATIENT,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        // console.log("Deleted Patient");
        if (!allowSignalR) {
          this.props.refetchFsData();
        }
      })
      .catch(err => {
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
        console.log('Error deleting patient');
      });
  };

  openPatientDialog = () => {
    // console.log("open patient dialog");
    this.setState({
      openPatientDialog: true,
    });
  };

  handleChange = (name: string) => (event: any) => {
    let newValue = event.target.value;
    // console.log("Check it", newValue);
    this.setState(prevState => ({
      providerPopper: false,
      patient: {
        ...prevState.patient,
        [name]: newValue,
      },
    }));
  };

  handleEnter = (input: any) => (event: any) => {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);

    let lastItem = index + 1 === form.elements.length;

    if (event.shiftKey && event.keyCode === 9) {
      // Make input field reverse. No idea its working. All I have is this if statement checking if Shift+Tab is inputted. Somehow it reverses when this is here...
    }

    if (lastItem) {
      // If tab OR enter key is pressed on last item, then submit
      if (event.keyCode === 13 || event.keyCode === 9) {
        this.addPatient();
        // Then send cursor back to provider input.
        form.elements[0].focus();
        event.preventDefault();
      }
    } else {
      if (event.keyCode === 13) {
        // If enter key is press while on provider search field.
        if (
          input === 'providerSearchInput' &&
          this.state.providerSearch.length > 0 &&
          this.state.providerSearchResults.length > 0
        ) {
          this.setState(prevState => ({
            patient: {
              ...prevState.patient,
              provider: this.state.providerSearchResults[0].id,
            },
            providerPopper: false,
            providerSearch: this.state.providerSearchResults[0].fullName,
          }));
        } else if (input === 'providerSearchInput') {
          this.setState({
            providerPopper: false,
            providerSearch: 'Unassigned',
          });
        }

        // If enter key is pressed while on date of birth field, automatically call add patient function.

        if (lastItem) {
          // // Call add patient
          // this.addPatient();
          // // Then send cursor back to provider input.
          // form.elements[0].focus();
          // event.preventDefault();
        } else {
          // Move cursor to next element after each enter key
          form.elements[index + 1].focus();
          event.preventDefault();
        }
      }
    }

    // if 'Enter' key (keyCode 13) is pressed
    // else if (event.keyCode === 13) {
    //   // If enter key is press while on provider search field.
    //   if (input === "providerSearchInput" && this.state.providerSearch.length > 0 && this.state.providerSearchResults.length > 0) {
    //     this.setState((prevState) => ({
    //       patient: {
    //         ...prevState.patient,
    //         provider: this.state.providerSearchResults[0].id,
    //       },
    //       providerPopper: false,
    //       providerSearch: this.state.providerSearchResults[0].fullName,
    //     }));
    //   } else if (input === "providerSearchInput") {
    //     this.setState({
    //       providerPopper: false,
    //       providerSearch: "Unassigned",
    //     });
    //   }

    //   // If enter key is pressed while on date of birth field, automatically call add patient function.

    //   if (lastItem) {
    //     // Call add patient
    //     this.addPatient();

    //     // Then send cursor back to provider input.
    //     form.elements[0].focus();
    //     event.preventDefault();
    //   } else {
    //     // Move cursor to next element after each enter key
    //     form.elements[index + 1].focus();
    //     event.preventDefault();
    //   }
    // }
  };

  addPatient() {
    let patientDate = '';

    console.log('department', this.props.department);

    let fieldRequirements = {
      providerRequired: this.props.department.showProvider,
      firstNameRequired: this.props.department.showFirstName,
      lastNameRequired: this.props.department.showLastName,
      mrnRequired: this.props.department.showMRN,
      apptRequired: this.props.department.showApptTime,
      dobRequired: this.props.department.showDOB,
    };

    // console.log("Requireements", fieldRequirments);

    // console.log("DOB", this.state.patient.DOB);
    if (this.state.patient.DOB) {
      // Make Date of Birth checks
      let inputtedDate = moment.utc(this.state.patient.DOB).format();
      let differenceInYears = moment()
        .local()
        .diff(moment.utc(inputtedDate).local(), 'years');

      //   console.log(differenceInYears);

      if (
        new Date(this.state.patient.DOB).toString() === 'Invalid Date' ||
        differenceInYears > 119
      ) {
        // console.log("throw error");
        this.setState({
          dateError: true,
        });
      } else {
        this.setState({
          dateError: false,
        });
        // console.log("DATE CHECK", new Date(this.state.patient.DOB).toISOString());
        patientDate = new Date(this.state.patient.DOB).toISOString();
      }
    }

    let appointmentTime = '';

    if (this.state.patient.apptTime) {
      appointmentTime = moment
        .utc(this.state.patient.apptTime, 'h:mm')
        .format();
    }

    let data = {
      FirstName: this.state.patient.firstName,
      LastName: this.state.patient.lastName,
      DOB: patientDate,
      MRNnumber: this.state.patient.MRN,
      providerId: this.state.patient.provider,
      deptId: this.props.department.id,
      apptTime: this.state.patient.apptTime,
      ehrAppointmentTypeDesc: this.state.patient.ehrAppointmentTypeDesc,
    };

    if (appointmentTime.length > 1) {
      // console.log("apptTime is present, add to payload", appointmentTime);
      data.apptTime = appointmentTime;
    }

    if (patientDate.length > 1) {
      data.DOB = patientDate;
    }

    if (this.state.patient.provider === 'empty') {
      console.log('Provider was not selected, assigning to id of 0');
      data.providerId = '';
    }

    let throwError = false;

    let firstNameError = false;
    let lastNameError = false;
    let mrnError = false;
    let apptError = false;
    let dateError = false;

    if (fieldRequirements.firstNameRequired && data.FirstName.length < 1) {
      throwError = true;
      firstNameError = true;
    }

    if (fieldRequirements.lastNameRequired && data.LastName.length < 1) {
      throwError = true;
      lastNameError = true;
    }

    if (fieldRequirements.mrnRequired && data.MRNnumber.length < 1) {
      throwError = true;
      mrnError = true;
    }

    if (fieldRequirements.apptRequired && !data.apptTime) {
      apptError = true;
    }

    if (fieldRequirements.dobRequired && !data.DOB) {
      dateError = true;
    }

    if (throwError) {
      console.log('THIS IS THROWING ERROR');
      this.setState({
        fieldError: true,
        firstNameError: firstNameError,
        lastNameError: lastNameError,
        mrnError: mrnError,
        apptError: apptError,
        dateError: dateError,
      });
    } else if (dateError) {
      this.setState({
        fieldError: false,
        firstNameError: firstNameError,
        lastNameError: lastNameError,
        mrnError: mrnError,
        apptError: apptError,
        dateError: dateError,
      });
    } else {
      this.setState({
        fieldError: false,
        firstNameError: firstNameError,
        lastNameError: lastNameError,
        mrnError: mrnError,
        apptError: apptError,
        dateError: dateError,
      });
      //   console.log("sending this", data);

      let token = localStorage.getItem('token');

      axios({
        method: 'post',
        url: API.REACT_APP_API_CREATEPATIENT,
        data,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          //   console.log("Successfully added Patient!", response);
          let finished = () => {
            this.props.closeDialog();
          };

          if (this.props.autoAddPatientToRoom && this.props.cardData) {
            // Make another post to add patient to the room they were created from.
            let token = localStorage.getItem('token');
            let patient = response.data.data;

            let data: any = {
              roomId: this.props.cardData.id,
              patientIds: [{ Id: patient.id }],
              deviceId: null,
              deptId: this.props.department.id,
            };

            this.props.cardData.roomPatients.forEach(patient => {
              data.patientIds.push({
                Id: patient.id,
              });
            });
            // console.log("send", data);

            if (this.props.department.providerPatientsWaiting.length === 1) {
              this.setState({
                openAddPatientPopover: false,
              });
            }

            console.log('PAYLOAD', data);

            axios({
              method: 'post',
              url: API.REACT_APP_API_ROOMPATIENTS,
              data,
              headers: {
                Authorization: 'Bearer ' + token,
              },
            })
              .then(response => {
                console.log(response);
                finished();
              })
              .catch(function (err) {
                console.log('Error adding patient to room', err);
                if (err.response?.status === 401) {
                  checkRefreshToken();
                }
              });
          } else {
            finished();
          }

          // finished();

          // setTimeout(() => this.setState({ patientHasBeenCreated: false }), 3000);
        })
        .catch(err => {
          console.log('Error adding Patient', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  }

  searchProvider = () => (event: any) => {
    // console.log("all providers", this.state.allProviders);
    // console.log(event)

    this.setState({
      providerPopper: true,
      providerSearch: event.target.value,
      actionEl: event.currentTarget,
    });

    let fuseOptions = {
      shouldSort: true,
      threshold: 0.5,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 0,
      keys: ['fullName'],
    };
    let fuse = new Fuse(this.state.allProviders, fuseOptions);

    let result = fuse.search(event.target.value) ?? [];
    result = result.map(r => r.item);

    // console.log("Provider Search Result", result);
    this.setState({
      providerSearchResults: result,
    });
  };

  showPoppover = () => (event: any) => {
    // console.log("showing poppover");
    this.setState({
      anchorEl: event.currentTarget,
      providerPopper: true,
    });
  };

  adminAutoFill = () => {
    const fakePatientGenerator = new FakePatientGenerator();

    this.setState({
      patient: {
        firstName: fakePatientGenerator.firstName(),
        lastName: fakePatientGenerator.lastName(),
        MRN: String(fakePatientGenerator.randomNumber(10000, 99999)),
        provider: '',
        DOB: moment(fakePatientGenerator.dateOfBirth()).format('YYYY-MM-DD'),
        apptTime: moment().format('HH:mm'),
        ehrAppointmentTypeDesc: fakePatientGenerator.visitReason(),
      },
    });

    // let token = localStorage.getItem('token');

    // axios({
    //   method: 'post',
    //   url: API.REACT_APP_API_CREATEPATIENT,
    //   data,
    //   headers: {
    //     Authorization: 'Bearer ' + token,
    //   },
    // }).then(response => {
    //   console.log('res!');

    //   let finished = () => {
    //     this.props.closeDialog();
    //     if (!allowSignalR) {
    //       this.props.refetchFsData();
    //     }
    //   };

    //   if (this.props.autoAddPatientToRoom && this.props.cardData) {
    //     let token = localStorage.getItem('token');
    //     let patient = response.data.data;

    //     let data: any = {
    //       roomId: this.props.cardData.id,
    //       patientIds: [{ Id: patient.id }],
    //       deviceId: null,
    //       deptId: this.props.department.id,
    //     };

    //     this.props.cardData.roomPatients.forEach(patient => {
    //       data.patientIds.push({
    //         Id: patient.id,
    //       });
    //     });
    //     // console.log("send", data);

    //     if (this.props.department.providerPatientsWaiting.length === 1) {
    //       this.setState({
    //         openAddPatientPopover: false,
    //       });
    //     }

    //     console.log('PAYLOAD', data);

    //     axios({
    //       method: 'post',
    //       url: API.REACT_APP_API_ROOMPATIENTS,
    //       data,
    //       headers: {
    //         Authorization: 'Bearer ' + token,
    //       },
    //     })
    //       .then(response => {
    //         console.log(response);
    //         finished();
    //       })
    //       .catch(function (err) {
    //         console.log('Error adding patient to room', err);
    //         if (err.response?.status === 401) {
    //           checkRefreshToken();
    //         }
    //       });
    //   } else {
    //     finished();
    //   }

    //   // finished();
    // });
  };

  handleProviderAutoComplete = (selectedProvider: IProvider) => {
    if (selectedProvider) {
      console.log('Done', selectedProvider);
      this.setState(prevState => ({
        patient: {
          ...prevState.patient,
          provider: selectedProvider.id,
        },
      }));
    }
  };

  render() {
    const { classes } = this.props;

    let autoCompleteProviders: any[] = [];
    this.state.allProviders.forEach(provider => {
      if (provider.isActive) {
        autoCompleteProviders.push(provider);
      }
    });

    return (
      <div data-private>
        <MuiThemeProvider theme={theme}>
          <Dialog
            // maxWidth="xs"
            classes={{ paper: classes.dialogPaper }}
            //   open={this.state.openPatientDialog}
            open={this.props.open}
            onClose={() =>
              this.setState(
                {
                  openPatientDialog: false,
                  fieldError: false,
                  providerSearch: '',
                  patient: {
                    firstName: '',
                    lastName: '',
                    MRN: '',
                    provider: 'empty',
                    DOB: '',
                    apptTime: '',
                    ehrAppointmentTypeDesc: '',
                  },
                },
                () => this.props.closeDialog()
              )
            }
            BackdropProps={{
              classes: {
                root: classes.root,
              },
            }}
            PaperProps={{
              classes: {
                root: classes.paper,
              },
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '2px solid lightgray',
              }}
            >
              <DialogTitle style={{ paddingBottom: 0, textAlign: 'center' }}>
                Add New Patient
              </DialogTitle>
              {canDo(['SyncAdmin'], this.props.loggedInUserData) ? (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.adminAutoFill}
                  >
                    Admin Auto Fill
                  </Button>
                </div>
              ) : null}

              <DialogContent>
                <form data-private style={{ textAlign: 'center' }}>
                  {this.props.department.showProvider ? (
                    <div>
                      <Autocomplete
                        autoHighlight
                        className={classes.textField}
                        id="combo-box"
                        options={autoCompleteProviders}
                        getOptionLabel={provider => provider.fullName}
                        onChange={(event, provider) => {
                          this.handleProviderAutoComplete(provider);
                        }}
                        renderInput={params => (
                          <TextField {...params} label="Select Provider" />
                        )}
                      />
                    </div>
                  ) : null}
                  {this.props.department.showFirstName ? (
                    <TextField
                      error={this.state.firstNameError}
                      // ref="firstname"
                      name="firstname"
                      onKeyDown={this.handleEnter('')}
                      label="First Name *"
                      margin="normal"
                      value={this.state.patient.firstName}
                      className={classes.textField}
                      onChange={this.handleChange('firstName')}
                    />
                  ) : (
                    <div />
                  )}
                  {this.props.department.showLastName ? (
                    <TextField
                      error={this.state.lastNameError}
                      name="lastname"
                      onKeyDown={this.handleEnter('')}
                      label="Last Name *"
                      margin="normal"
                      value={this.state.patient.lastName}
                      className={classes.textField}
                      onChange={this.handleChange('lastName')}
                    />
                  ) : (
                    <div />
                  )}
                  {this.props.department.showMRN ? (
                    <TextField
                      error={this.state.mrnError}
                      name="mrn"
                      onKeyDown={this.handleEnter('')}
                      label="EHR Patient ID *"
                      margin="normal"
                      value={this.state.patient.MRN}
                      className={classes.textField}
                      onChange={this.handleChange('MRN')}
                    />
                  ) : (
                    <div />
                  )}

                  {this.props.department.showApptType ? (
                    <div>
                      <TextField
                        error={this.state.mrnError}
                        name="mrn"
                        onKeyDown={this.handleEnter('')}
                        label="Appointment Type"
                        margin="normal"
                        value={this.state.patient.ehrAppointmentTypeDesc}
                        className={classes.textField}
                        onChange={this.handleChange('ehrAppointmentTypeDesc')}
                      />
                    </div>
                  ) : null}

                  {this.props.department.showApptTime ? (
                    <div>
                      <div className={classes.dobTitle}>
                        Appointment Time (ex: 2:30PM)*
                      </div>
                      <TextField
                        error={this.state.apptError}
                        name="apptTime"
                        onKeyDown={this.handleEnter('')}
                        type="time"
                        value={
                          this.state.patient.apptTime
                            ? this.state.patient.apptTime
                            : ''
                        }
                        margin="normal"
                        className={classes.dobExpireField}
                        onChange={this.handleChange('apptTime')}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                  {this.props.department.showDOB ? (
                    <div>
                      <div className={classes.dobTitle}>
                        Date of Birth (mm/dd/yyyy)*
                      </div>
                      <TextField
                        error={this.state.dateError}
                        name="dob"
                        onKeyDown={this.handleEnter('callAddPatient')}
                        type="date"
                        value={this.state.patient.DOB}
                        margin="normal"
                        className={classes.dobExpireField}
                        onChange={this.handleChange('DOB')}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                </form>
                <div className={classes.submitButtonContainer}>
                  <Button
                    className={classes.submitButton}
                    onClick={() => this.addPatient()}
                  >
                    Add Patient
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/* {this.state.dateError ? <ErrorSnack errorSnack={true} errorMessage="Invalid Date" /> : <div />} */}
          {this.state.fieldError ? (
            <ErrorSnack
              errorSnack={true}
              errorMessage="Please Fill Out All Required Fields"
            />
          ) : (
            <div />
          )}

          {this.state.patientHasBeenCreated ? (
            <SuccessSnack
              successSnack={true}
              successMessage="Patient has been created!"
            />
          ) : (
            <div />
          )}
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(NewPatientDialog);
