import { FC } from 'react';
import { IPatientContent } from '../../../../interfaces/interfaces';
import { checkIfIsVideo } from './utils';

type TProps = {
  lobbySequence: any;
  patientContent: Partial<IPatientContent>;
};

const LobbySequenceText: FC<TProps> = props => {
  const { lobbySequence, patientContent } = props;

  const isVideo = checkIfIsVideo(lobbySequence);

  return (
    <div>
      {patientContent?.patientContentName}&nbsp;
      {isVideo ? (
        <span>(Video)</span>
      ) : (
        <span>for {lobbySequence.patientEducationTime} secs</span>
      )}
    </div>
  );
};

export default LobbySequenceText;
