import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { getQueryVariable } from '../../utils/utils';
import moment from 'moment';
import EmailValidator from 'email-validator';
// import { Helmet } from "react-helmet";

import { API, MSALSSO } from '../../apiconfig';

import { Providers } from '@microsoft/mgt-element';
import loginwithmicrosoft from '../../images/loginwithmicrosoft.svg';
import { authentication } from '@microsoft/teams-js';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import GooglePlay from '../../images/googleplay.png';
import AppStore from '../../images/appstore.png';

import WarningSnack from '../../utils/WarningSnack';

// import synctimesIcon from './synctimesicon.png';
import synctimesIcon from '../../images/synctimesicon.png';

// const Helmet = require("react-helmet");

let metaData = require('../../metadata.json');

console.log('Build: ' + metaData.build);

const styles: {} = () => ({
  loginError: {
    color: 'red',
    paddingTop: 10,
    fontSize: 12,
  },
  resetPasswordText: {
    fontSize: 12,
    fontWeight: 400,
    color: 'gray',
    marginBottom: 0,
    marginTop: 10,
    cursor: 'pointer',
  },
  loginIcon: {
    height: 150,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginPaper: {
    top: '50%',
    left: '50%',
    padding: 30,
    position: 'absolute',
    maxWidth: 310,
    minWidth: 310,
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  loginButton: {
    marginTop: 25,
  },
});

interface LoginProps {
  location: any;
  classes: any;
}

interface LoginState {
  username: string;
  password: string;
  email: string;
  fireRedirect: boolean;
  showLoginError: boolean;
  resetPasswordDialog: boolean;
  resetSuccess: boolean;
  ssoNotAllowed: boolean;
}

class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
      email: '',
      fireRedirect: false,
      showLoginError: false,
      resetPasswordDialog: false,
      resetSuccess: false,
      ssoNotAllowed: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();

    this.setState({
      showLoginError: false,
    });

    let reqData = {
      username: this.state.username.toString(),
      password: this.state.password.toString(),
    };

    console.log('handleSubmit reqData', reqData);

    await axios({
      method: 'post',
      url: API.REACT_APP_API_LOGIN,
      data: {
        ...reqData
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        let now = moment.utc();
        let expireDate =
          now
            .add(response.data.expires_in, 'seconds')
            .format('ddd, D MMM Y HH:mm:ss') + ' UTC';

        console.log('(Manual Login from Inputs) Expire In: ' + expireDate);

        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('email', reqData.username);
        localStorage.setItem("token_expire", response.data.utc_expiry)


        // if they came from a url, send them back to the url
        if (this.props.location.url) {
          window.location.replace(this.props.location.url.cameFrom);
        } else {
          window.location.replace('/');
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          showLoginError: true,
        });
      });
  };

  componentDidMount() {
    console.log('Url Check', API.REACT_APP_API_LOGIN);
    console.log('Props in componentDidMount:', this.props);
    console.log('Location in componentDidMount:', this.props.location);
    // This is auto login from reset password emails
    let username = getQueryVariable('username');
    let password = getQueryVariable('password');
    if (username && password) {
      let reqData: any = {
        // grant_type: 'password',
        username: username,
        password: password,
      };

      axios({
        method: 'post',
        url: API.REACT_APP_API_LOGIN,
        data: {
          ...reqData
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          let now = moment.utc();
          let expireDate =
            now
              .add(response.data.expires_in, 'seconds')
              .format('ddd, D MMM Y HH:mm:ss') + ' UTC';

          console.log(
            '(Auto login from reset password) Expire In: ' + expireDate
          );

          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          localStorage.setItem('email', reqData.username);
          localStorage.setItem("token_expire", response.data.utc_expiry)


          // if they came from a url, send them back to the url
          if (this.props.location.url) {
            window.location.replace(this.props.location.url.cameFrom);
          } else {
            window.location.replace('/');
          }
          // otherwise, just redirect them to '/'
          // this.setState({ fireRedirect: true });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            showLoginError: true,
          });
        });
    }

    // check if cookie exists, redirect them to home
    // console.log("Test here", this.props);
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');

    if (token && email) {
      this.setState({ fireRedirect: true });
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('refresh_token');
      // window.location.reload();
    }

    if (this.props.location.url) {
      let redirectLocation = this.props.location.url;
      if (
        this.props.location.url.username &&
        this.props.location.url.password
      ) {
        console.log('handle auto login');
        let reqData: any = {
          grant_type: 'password',
          username: this.props.location.url.username,
          password: this.props.location.url.password,
        };

        let autoLogin = async () => {
          await axios({
            method: 'post',
            url: API.REACT_APP_API_LOGIN,
            data:{
              ...reqData
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(response => {
              // console.log('Good!', response)
              let now = moment.utc();
              let expireDate =
                now
                  .add(response.data.expires_in, 'seconds')
                  .format('ddd, D MMM Y HH:mm:ss') + ' UTC';

              console.log('(Auto login via url (FS)) Expire In: ' + expireDate);

              localStorage.setItem('token', response.data.access_token);
              localStorage.setItem(
                'refresh_token',
                response.data.refresh_token
              );
              localStorage.setItem('email', reqData.username);
              localStorage.setItem("token_expire", response.data.utc_expiry)

              // if they came from a url, send them back to the url
              if (redirectLocation.cameFrom) {
                console.log('Sending them back to ', redirectLocation.cameFrom);
                window.location.replace(redirectLocation.cameFrom);
              } else {
                // otherwise, just redirect them to '/'
                this.setState({ fireRedirect: true });
              }
            })
            .catch(err => {
              console.log('Something broke handling auto login', err);
            });
        };

        autoLogin();
      }
    }
    // Check if we are in Teams App & auto log in if so
    if (
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      console.log('Teams App');
      if (!localStorage.getItem('token')) {
        console.log('Teams App init login');
        this.msTeamsLogin();
      }
    }
  }

  handleInputChange(event: any) {
    // @ts-ignore
    this.setState({
      [event.target.name]: event.target.value,
      showLoginError: false,
    });
  }

  resetPasswordButton = () => {
    this.setState({
      resetPasswordDialog: true,
    });
  };

  handleResetPassword = () => {
    let beforePayload = {
      userEmail: this.state.username,
    };

    let payload = JSON.stringify(beforePayload);

    fetch(API.REACT_APP_API_RESETPASSWORD, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(() => {
        this.setState({
          resetSuccess: true,
        });
      })
      .catch(err => {
        console.log('Error: ', err);
      });
  };

  returnToLogin = () => {
    this.setState({
      resetPasswordDialog: false,
    });
  };

  msTeamsLogin = () => {
    authentication
      .getAuthToken()
      .catch(e => {
        console.log('Teams err', e);
        localStorage.setItem('SSOTeamsLoggedOut', 'true');
      })
      .then(token => {
        console.log('Teams token!', token);

        if (token) {
          fetch(MSALSSO, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              console.log('RES', res);
              return res.json();
            })
            .then(data => {
              if (data.message) {
                if (data.message === 'sso_not_enabled') {
                  this.setState({
                    ssoNotAllowed: true,
                  });
                  localStorage.removeItem('token');
                  localStorage.removeItem('email');
                  localStorage.removeItem('refresh_token');
                  setTimeout(() => {
                    this.setState({ ssoNotAllowed: false });
                  }, 10000);
                }
              }

              if (data.access_token && data.refresh_token) {
                let payload = {
                  useremail: data.user_email,
                };

                let autoLogin = async () => {
                  axios({
                    method: 'post',
                    url: API.REACT_APP_API_LOGINDETAILS,
                    data: payload,
                    headers: {
                      Authorization: 'Bearer ' + data.access_token,
                      Pragma: 'no-cache',
                    },
                  }).then(response => {
                    console.log('response', response);
                    localStorage.setItem('token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    localStorage.setItem('email', data.user_email);
                    window.location.replace('/');
                  });
                };
                autoLogin();
              }
            })
            .catch(err => {
              console.log('Error getting new token', err);
            });
        }
      });
  };

  loginWithMS = () => {
    let appType = localStorage.getItem('appType');
    // Check if iOS webview
    // let isIos: boolean = false;
    if (
      navigator.platform === 'iPad' ||
      navigator.platform === 'iPhone' ||
      navigator.platform === 'iPod' ||
      appType === 'ios' ||
      navigator.platform === 'MacIntel'
    ) {
      // isIos = true;
      // @ts-ignore
      if (window.webkit && window.webkit.messageHandlers) {
        let iosPayload = {
          startMsalLogin: true,
        };

        // @ts-ignore
        if (window.webkit.messageHandlers.ssoMsalLogin) {
          // @ts-ignore
          window.webkit.messageHandlers.ssoMsalLogin.postMessage(iosPayload);
          return;
        }
      }
    }

    // Check if android webview
    let isWebView = navigator.userAgent.includes('wv');
    if (isWebView || appType === 'android') {
      //@ts-ignore
      window.sendMessageToAndroidWebview('Hello world!!!');
      return;
    }

    // Check if we are in Teams App
    if (
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      console.log('Teams App');
      if (!localStorage.getItem('token')) {
        console.log('Teams App init login');
        this.msTeamsLogin();
        return;
      }
    } else {
      // Check if using Electron to post message rather than start login process...
      if (window.self !== window.top) {
        console.log('iFrame, post message to parent');

        window.parent.postMessage(
          {
            event_id: 'login_with_ms',
            data: {
              success: true,
            },
          },
          '*'
        );
        return;
      } else {
        const provider = Providers.globalProvider;
        if (provider.state) {
          //@ts-ignore
          provider.login();
        }
      }
    }
  };

  render() {
    const { classes } = this.props;

    // console.log("Props!", this.props)

    // console.log("check", this.state.username);

    let OSName = 'Unknown';
    if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1)
      OSName = 'Windows 10';
    if (window.navigator.userAgent.indexOf('Windows NT 6.3') !== -1)
      OSName = 'Windows 8.1';
    if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1)
      OSName = 'Windows 8';
    if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1)
      OSName = 'Windows 7';
    if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1)
      OSName = 'Windows Vista';
    if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1)
      OSName = 'Windows XP';

    let validEmail = true;

    if (this.state.username) {
      if (EmailValidator.validate(this.state.username) === false) {
        validEmail = false;
      }
    }

    return (
      <div>
        {/* <Helmet> */}
        <meta charSet="utf-8" />
        <title>SyncTimes</title>
        <meta name="description" content="Log into SyncTimes web application" />
        {/* </Helmet> */}
        {this.state.fireRedirect ? <Navigate to="/" /> : null}
        <Paper className={classes.loginPaper} elevation={1}>
          <img
            className={classes.loginIcon}
            src={synctimesIcon}
            alt="SyncTimes"
          />

          {!this.state.resetPasswordDialog ? (
            <div>
              <Typography variant="h5" component="h3">
                Sign In
              </Typography>
              {this.state.showLoginError ? (
                <div className={classes.loginError}>
                  Incorrect email or password
                </div>
              ) : (
                <div />
              )}
              {!validEmail ? (
                <div
                  style={{
                    color: 'red',
                    fontSize: 12,
                    height: 20,
                    maxHeight: 20,
                  }}
                >
                  Please input a valid email address
                </div>
              ) : (
                <div style={{ marginTop: 20 }} />
              )}
              <form onSubmit={this.handleSubmit.bind(this)}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">User Email</InputLabel>
                  <Input
                    onChange={this.handleInputChange}
                    name="username"
                    id="username"
                    autoComplete="email"
                    autoFocus
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    onChange={this.handleInputChange}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </FormControl>

                <Button
                  type="submit"
                  onClick={this.handleSubmit.bind(this)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.loginButton}
                >
                  Login
                </Button>
                {/* <LoginComponent /> */}

                <img
                  onClick={this.loginWithMS}
                  style={{ width: 250, marginTop: 15, cursor: 'pointer' }}
                  src={loginwithmicrosoft}
                  alt="Login with Microsoft"
                />
                {/* <button onClick={() => {
                  console.log("cleared")
                  localStorage.removeItem("SSOTeamsLoggedOut")
                }}>Clear SSOTeamsLoggedOut</button> */}
                <h5
                  className={classes.resetPasswordText}
                  onClick={() => this.resetPasswordButton()}
                >
                  Forgot your password?
                </h5>
              </form>
            </div>
          ) : (
            <div>
              {!this.state.resetSuccess ? (
                <div>
                  <Typography variant="h5" component="h3">
                    Reset Password
                  </Typography>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <Input
                      onChange={this.handleInputChange}
                      name="username"
                      id="email"
                      autoComplete="email"
                      autoFocus
                      value={this.state.username}
                    />
                  </FormControl>
                  <Button
                    style={{ marginTop: 20 }}
                    type="submit"
                    onClick={this.handleResetPassword.bind(this)}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="loginButton"
                  >
                    Reset Password
                  </Button>
                </div>
              ) : (
                <div>
                  <Typography
                    variant="h5"
                    component="h3"
                    style={{ paddingBottom: 20 }}
                  >
                    Password Reset!
                  </Typography>
                  <Typography style={{ paddingBottom: 20 }}>
                    If email is assigned to a SyncTimes user, password will be
                    reset and emailed.
                  </Typography>
                  <Button
                    onClick={() => this.returnToLogin()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="loginButton"
                  >
                    Return to Login
                  </Button>
                </div>
              )}
            </div>
          )}
          {localStorage.getItem('appType') ? null : (
            <div>
              <div
                style={{ textAlign: 'center', display: 'flex', marginTop: 20 }}
              >
                <img
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=io.ionic.synctimes'
                    )
                  }
                  style={{ width: '45%', margin: 'auto', cursor: 'pointer' }}
                  src={GooglePlay}
                  alt=""
                />

                <img
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/us/app/synctimes/id1546265625'
                    )
                  }
                  style={{ width: '45%', margin: 'auto', cursor: 'pointer' }}
                  src={AppStore}
                  alt=""
                />
              </div>
              {OSName !== 'Unknown' ? (
                <h5 className={classes.resetPasswordText}>
                  Windows users can download SyncTimes desktop application{' '}
                  <a
                    style={{ color: '#4DB848' }}
                    href="https://cesiumstorage.blob.core.windows.net/desktopapplications/SyncTimes Setup.exe"
                  >
                    here!
                  </a>
                </h5>
              ) : null}
            </div>
          )}
        </Paper>
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            width: '100%',
            position: 'fixed',
            bottom: 0,
            textAlign: 'right',
            fontSize: 12,
          }}
        >
          <div style={{ marginRight: 10 }}>Version: {metaData.build}</div>
        </div>
        {this.state.ssoNotAllowed ? (
          <WarningSnack
            warningSnack={true}
            warningMessage="Microsoft Single Sign On is not enabled in your Organization. Please reach out to your IT team."
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Login);
