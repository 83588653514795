import React, { FC } from "react";

type TProps = any

const JoyrideTooltip: FC<TProps> = (props) => {
  const { continuous, step, primaryProps, tooltipProps, skipProps, isLastStep } = props;

  return (
    <div
      {...tooltipProps}
      style={{
        backgroundColor: "white",
        borderRadius: 5,
        boxSizing: "border-box",
        color: "rgb(51, 51, 51)",
        fontSize: 16,
        maxWidth: "100%",
        padding: 15,
        position: "relative",
        width: 550,
        lineHeight: "1.4",
        zIndex: 1500,
      }}
    >
      {step.title && <div>{step.title}</div>}
      <div style={{ textAlign: "center" }}>
        <div style={{ padding: "20px 10px" }}>{step.content}</div>

        {step.image ? <img style={{ maxWidth: "100%" }} src={step.image} alt="" /> : null}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
        <div style={{ flex: "1 1 0%" }}>
          {/* {index > 0 && (
        <button {...backProps}>
          <div id="back">Back</div>
        </button>
      )} */}
          {!isLastStep && (
            <button
              {...skipProps}
              style={{
                backgroundColor: "transparent",
                border: 0,
                borderRadius: 0,
                cursor: "pointer",
                color: "rgb(51, 51, 51)",
                fontSize: 14,
                lineHeight: 1,
                padding: 8,
                appearance: "none",
              }}
            >
              <div id="skip">Skip</div>
            </button>
          )}
        </div>
        {continuous && (
          <button
            {...primaryProps}
            style={{
              backgroundColor: "rgb(80, 184, 72)",
              border: 0,
              borderRadius: 4,
              color: "rgb(255, 255, 255)",
              cursor: "pointer",
              fontSize: 16,
              lineHeight: 1,
              padding: 8,
              appearance: "none",
            }}
          >
            <div id="next">Next</div>
          </button>
        )}
        {continuous && (
          <div
            {...skipProps}
            id="skip"
            style={{
              border: 0,
              borderRadius: 0,
              cursor: "pointer",
              fontSize: 16,
              lineHeight: 1,
              padding: 15,
              appearance: "none",
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            X
          </div>
        )}
      </div>
    </div>
  );
};

export default JoyrideTooltip;
